import React, { useState } from "react";
import "./Reports.css";
import { Card } from "react-bootstrap";
import { isProjectRole, isTPORole, isLenderRole } from "../../redux/actions/userAction";
import { IoMdDownload } from "react-icons/io";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { Environments , ReportByEnviroment} from "../shared/constants/environment";

export const Reports = () => {
  let psCards = [];
  let lenderCards = [];
  let tpoCards = [];

  const lowerEnvironments = [ Environments.local,
                              Environments.devl,Environments.devlExternal,
                              Environments.test,Environments.testExternal,
                              Environments.acpt,Environments.acptExternal]
  const higherEnvironments = [Environments.clve,Environments.clveExternal,
                              Environments.prod,Environments.prodExternal]

  if (lowerEnvironments.includes(window.location.hostname)) {
      psCards = ReportByEnviroment.lowerEnv.psReports;
      lenderCards = ReportByEnviroment.lowerEnv.lenderReports;
      tpoCards = ReportByEnviroment.lowerEnv.tpoReports;
  } else if (higherEnvironments.includes(window.location.hostname)) {
      psCards = ReportByEnviroment.higherEnv.psReports;
      lenderCards = ReportByEnviroment.higherEnv.lenderReports;
      tpoCards = ReportByEnviroment.higherEnv.tpoReports;
  }

  const goToReports = () => {
    window.location.href = `https://connect.fanniemae.com/#/report-center/report-detail/rawdata?reportId=5138`;
  };

  const RenderReportsCard = (content, key) => {
    const { title, body } = content;
    const [loading, setLoading] = useState(false);
    console.log("start date", new Date());
    const downloadReport = (title, event) => {
      event.target.disabled = true;
      event.target.setAttribute("disabled", "disabled");
      setLoading(true);

      const passData = JSON.parse(sessionStorage.getItem("passData"));
      const lenderId =
        sessionStorage.getItem("lenderId") ||
        passData.sellerServicerCoNo ||
        "12346";
      let uri = "";
      switch (title) {
        case "Unavailable Projects & Phases":
          uri = isProjectRole()
            ? "/reports/exportUnavailableProjectsAndPhases/" + passData.user
            : "/reports/exportUnavailableProjectsAndPhases/" +
              lenderId +
              "/lender";
          break;
        case "Approved Projects & Phases":
          uri = isProjectRole()
            ? "/reports/exportApprovedProjectsAndPhases/" + passData.user
            : "/reports/exportApprovedProjectsAndPhases/" +
              lenderId +
              "/lender";
          break;
        case "All Projects & Phases":
          uri = "/reports/exportAllProjectAndPhaseDataToExcel/" + passData.user;
          break;
        case "Lender Certification Activity":
          uri = isProjectRole()
            ? "/reports/exportAllCertificationActivity/" + passData.user
            : "/reports/exportAllCertificationActivityForLender/" +
              passData.user +
              "/" +
              lenderId;
          break;
        case "Lender Certification Details":
          uri = "/reports/exportAllCertificationDetails/" + passData.user;
          break;
        default:
          uri = "";
          break;
      }
      axios({
        url: global.REACT_APP_API_ENDPOINT + uri,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          if(response.headers["content-type"].includes("csv")) {
            link.setAttribute("download", "Condo " + title + " Report.csv");
          } else {
            link.setAttribute("download", "Condo " + title + " Report.xlsx");
          }
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        }
      });
    };

    return (
      <Card className="report-card" key={key}>
        <div className="report-card-body">
          <h3 className="report-card-body-header">{title}</h3>
          <div className="report-card-content">
            <p className="report-body-content">{body}</p>
            <div className="report-body-btn">
              {!loading && (
                <button
                  className="download-btn"
                  onClick={(e) => downloadReport(title, e)}
                >
                  <IoMdDownload className="download-icon" /> Download
                </button>
              )}

              {loading && (
                <div>
                  <button className="download-btn" disabled>
                    <CircularProgress
                      style={{
                        display: "inline-flex",
                        padding: "10px",
                        color: "#1176b5",
                      }}
                    />
                    Download
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <div className="report-banner">
        <p className="report-center">Reports</p>
        <p className="report-desc">Data Extracts available in XLS</p>
        <div className="report-disclosure">
          The Reports on this page are Fannie Mae Confidential and Proprietary -
          Subject to Non-Disclosure Obligations
        </div>
      </div>
      <div className="report-body">
        {isProjectRole()
          ? psCards.map((card, index) => {
              return RenderReportsCard(card, index);
            })
          : (isTPORole() ? tpoCards.map((card, index) => {
              return RenderReportsCard(card, index);
            }) 
            : lenderCards.map((card, index) => {
              return RenderReportsCard(card, index);
            }))}
      </div>
      {/* {false && <div className="fnma-connect-body">
        <Card className="report-card">
          <div className="report-card-body">
            <h3 className="report-card-body-header">
              {" "}
              Lender Certifications from Legacy CPM{" "}
            </h3>
            <div className="report-card-content">
              <p className="report-body-content">
                Project and Phase records with certification activity over the
                12 months prior to the retirement of legacy CPM on March 4,
                2022. Includes certification results of Guide Ineligible and
                Certified by Lender. This report is available on Fannie Mae
                Connect. Please contact your Technology Administrator if you
                require access.
              </p>
              <button className="view-report-btn" onClick={goToReports}>
                View Report on Fannie Mae Connect
              </button>
              <div className="report-body-btn"></div>
            </div>
          </div>
        </Card>
      </div>
      } */}
    </div>
  );
};

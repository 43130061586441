import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card, Button } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";
import {
  fetchType1026,
  fetchRights1026,
  fetchStories1026,
  fetchStructures1026,
  fetchPhases1026,
  fetchConstructions1026,
  fetchConversions1026,
  fetchUnits1026
} from "../../redux/actions/filterActions";

import { Field, DatePicker, PhaseList } from "../Pers-1071";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function Pers1071({
  collapseKey,
  sectionData,
  persId,
  projectId
}) {
  const [isEdit, setEdit] = useState(false);
  const [formState, setFormState] = useState({});
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const dispatch = useDispatch();

  const types1026 = useSelector(
    type1026Reducer => type1026Reducer.type1026Reducer.types1026
  );

  const rights1026 = useSelector(
    rights1026Reducer => rights1026Reducer.rights1026Reducer.rights1026
  );

  const stories1026 = useSelector(
    stories1026Reducer => stories1026Reducer.stories1026Reducer.stories1026
  );

  const structures1026 = useSelector(
    structures1026Reducer =>
      structures1026Reducer.structures1026Reducer.structures1026
  );
  const phases1026 = useSelector(
    phases1026Reducer => phases1026Reducer.phases1026Reducer.phases1026
  );
  const constructions1026 = useSelector(
    constructions1026Reducer =>
      constructions1026Reducer.constructions1026Reducer.constructions1026
  );
  const conversions1026 = useSelector(
    conversions1026Reducer =>
      conversions1026Reducer.conversions1026Reducer.conversions1026
  );
  const units1026 = useSelector(
    units1026Reducer => units1026Reducer.units1026Reducer.units1026
  );
  useEffect(() => {
    dispatch(fetchType1026());
    dispatch(fetchRights1026());
    dispatch(fetchStories1026());
    dispatch(fetchStructures1026());
    dispatch(fetchPhases1026());
    dispatch(fetchConstructions1026());
    dispatch(fetchConversions1026());
    dispatch(fetchUnits1026());
  }, []);

  const yesno = [
    {
      key: true,
      value: "Yes"
    },
    {
      key: false,
      value: "No"
    }
  ];

  const salestrategy = [
    {
      key: "Sale of 100% of units",
      value: "Sale of 100% of units"
    },
    {
      key: "Other",
      value: "Other"
    }
  ];

  useEffect(() => {
    setFormState({ ...sectionData });
  }, [sectionData]);

  const handleSaveEdit = async () => {
    setApiErr("");
    setInternalErr(false);
    try {
      const body = {
        persId,
        projectId,
        phaseId: null,
        pers1026: { ...formState }
      };
      console.log("formState", formState);
      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT + `/pers/updatePersProjectAndForms/1026`,
        body
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setFormState({ ...sectionData });
    }
  };

  const handleChange = event => {
    let _formData = { ...formState };
    _formData[event.target.name] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleDatePickerChange = event => {
    let _formData = { ...formState };
    let value = event.target.value;
    if (value === null || value === '') {
      value = null;
    } else if (new Date(event.target.value) < new Date("1776-01-01")){
      value = null;
    }
    _formData[event.target.name] = value;
    setFormState({ ..._formData });
  };

  const handleProjectTypeChange = event => {
    let _formData = { ...formState };
    _formData["projectType"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handlePropertyRightsChange = event => {
    let _formData = { ...formState };
    _formData["propertyRights"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleProjectStoriesCountsChange = event => {
    let _formData = { ...formState };
    _formData["projectStoriesCounts"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleProjectStructureTypeChange = event => {
    let _formData = { ...formState };
    _formData["projectStructureType"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handlePhaseTypeChange = event => {
    let _formData = { ...formState };
    _formData["phaseType"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleMasterHOAIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["masterHOAIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleComplete1051IndicatorChange = event => {
    let _formData = { ...formState };
    _formData["complete1051Indicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleBuilderOwnershipIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["builderOwnershipIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleBuilderRentingUnitsIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["builderRentingUnitsIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleRecreationServicesSharedUserAgreementsIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["recreationServicesSharedUserAgreementsIndicator"] =
      event.target.value;
    setFormState({ ..._formData });
  };

  const handleUnitOwnerSoleOwnershipAmenitiesIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["unitOwnerSoleOwnershipAmenitiesIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleMoreThanOnePhaseIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["moreThanOnePhaseIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleSalesStrategyChange = event => {
    let _formData = { ...formState };
    _formData["salesStrategy"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleConstructionStatusChange = event => {
    let _formData = { ...formState };
    _formData["constructionStatus"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleConversionGutRehabNonGutRehabTypeChange = event => {
    let _formData = { ...formState };
    _formData["conversionGutRehabNonGutRehabType"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleCommercialUnitsSoldLeasedRetainedChange = event => {
    let _formData = { ...formState };
    _formData["commercialUnitsSoldLeasedRetained"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleLitigationFiledIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["litigationFiledIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleEnvironProblemsIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["environProblemsIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleResaleRestrictionIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["resaleRestrictionIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleHealthSafetyIssuesIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["healthSafetyIssuesIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleCommercialUnitsInclusionIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["commercialUnitsInclusionIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleCommercialSpaceCompleteIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["commercialSpaceCompleteIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleNonIncidentalBusiOperationsOwnedHOAIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["nonIncidentalBusiOperationsOwnedHOAIndicator"] =
      event.target.value;
    setFormState({ ..._formData });
  };

  console.log(formState.phase1026DataList);

  return (
    <Accordion defaultActiveKey="1" id="section4">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">
              Application for Project Approval (1026)
            </p>
          </ContextAwareToggle>

          {!isReadonlyRole() ? (
            isEdit ? (
              <div>
                <button
                  className="cancel-button"
                  onClick={() => {
                    toggleEdit(false);
                    setApiErr("");
                  }}
                >
                  Cancel
                </button>
                <button className="add-phase-button" onClick={handleSaveEdit}>
                  <FaSave className="edit-profile-icon" /> Save Edits
                </button>
              </div>
            ) : (
              <button
                className="add-phase-button"
                onClick={() => {
                  toggleEdit(true);
                  setInternalErr(false);
                  setApiErr("");
                }}
              >
                <FaPen className="edit-profile-icon" /> Edit Section
              </button>
            )
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Overview</div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Project Name"
                      name="projectName"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Project Address"
                      name="projectAddress"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="City"
                      name="projectCity"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Zip Code"
                      name="projectZipCode"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Legal Description"
                      name="legalDescription"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Project Type"
                      name="projectType"
                      formData={formState}
                      dropdown={true}
                      states={types1026}
                      handleChange={handleProjectTypeChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Property Rights"
                      name="propertyRights"
                      formData={formState}
                      dropdown={true}
                      states={rights1026}
                      handleChange={handlePropertyRightsChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Is there a Master Homeowners Association?"
                      name="masterHOAIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleMasterHOAIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.masterHOAIndicator === true ||
                    formState.masterHOAIndicator === "true" ? (
                      <Field
                        label="1051 Form Included?"
                        name="complete1051Indicator"
                        formData={formState}
                        dropdown={true}
                        states={yesno}
                        handleChange={handleComplete1051IndicatorChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="This Project Has"
                      name="projectStoriesCount"
                      formData={formState}
                      dropdown={true}
                      states={stories1026}
                      handleChange={handleProjectStoriesCountsChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="This Project Consists of"
                      name="projectStructureType"
                      formData={formState}
                      dropdown={true}
                      states={structures1026}
                      handleChange={handleProjectStructureTypeChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Will the developer retain ownership of any units, common
                                elements or other project facilities (other than during
                                the initial marketing period for the project)?"
                      name="builderOwnershipIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleBuilderOwnershipIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.builderOwnershipIndicator === true ||
                    formState.builderOwnershipIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="builderOwnershipRetainComments"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Are any units rented by the developer?"
                      name="builderRentingUnitsIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleBuilderRentingUnitsIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.builderRentingUnitsIndicator === true ||
                    formState.builderRentingUnitsIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="builderRentingUnitsComments"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Are there any shared-use agreements for recreational
                                amenities or services?"
                      name="recreationServicesSharedUserAgreementsIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleRecreationServicesSharedUserAgreementsIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Do the unit owners have sole ownership in and exclusive
                                rights to use amenities?"
                      name="unitOwnerSoleOwnershipAmenitiesIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleUnitOwnerSoleOwnershipAmenitiesIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    {formState.unitOwnerSoleOwnershipAmenitiesIndicator ===
                      false ||
                    formState.unitOwnerSoleOwnershipAmenitiesIndicator ===
                      "false" ? (
                      <Field
                        label="Comment"
                        name="unitOwnerSoleOwnershipAmenitiesComments"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Will the project consist of more than one phase?"
                      name="moreThanOnePhaseIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleMoreThanOnePhaseIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.moreThanOnePhaseIndicator === true ||
                    formState.moreThanOnePhaseIndicator === "true" ? (
                      <Field
                        label="The Phases Are"
                        name="phaseType"
                        formData={formState}
                        dropdown={true}
                        states={phases1026}
                        handleChange={handlePhaseTypeChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    {formState.moreThanOnePhaseIndicator === true ||
                    formState.moreThanOnePhaseIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="phaseTypeMarketingComments"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Describe Sales Strategy"
                      name="salesStrategy"
                      formData={formState}
                      dropdown={true}
                      states={salestrategy}
                      handleChange={handleSalesStrategyChange}
                      isEdit={isEdit}
                    />
                    {formState.salesStrategy === "Other" ? (
                      <Field
                        label="Other Explanation"
                        name="salesStrategyExplaination"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Status of Construction"
                      name="constructionStatus"
                      formData={formState}
                      dropdown={true}
                      states={constructions1026}
                      handleChange={handleConstructionStatusChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Year Completed"
                      name="yearCompleted"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Proposed Start Date"
                      name="proposedStartDate"
                      formData={formState}
                      handleChange={handleDatePickerChange}
                      min="1776-01-01"
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Date Construction Started"
                      name="constructionStartedDate"
                      formData={formState}
                      handleChange={handleDatePickerChange}
                      min="1776-01-01"
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Anticipated Completion Date"
                      name="anticipatedCompletionDate"
                      formData={formState}
                      handleChange={handleDatePickerChange}
                      min="1776-01-01"
                      isEdit={isEdit}
                    />
                    <Field
                      label="Conversion, Gut or Non-Gut Rehab? (If non-gut, indicate
                                    additional exhibits in Form 1030)"
                      name="conversionGutRehabNonGutRehabType"
                      formData={formState}
                      dropdown={true}
                      states={conversions1026}
                      handleChange={
                        handleConversionGutRehabNonGutRehabTypeChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Are there any mechanic’s liens, complaints, or
                                litigation filed against the property?"
                      name="litigationFiledIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleLitigationFiledIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.litigationFiledIndicator === true ||
                    formState.litigationFiledIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="litigationFiledExplaination"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Has the lender identified or become aware of any
                                environmental problem(s)?"
                      name="environProblemsIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleEnvironProblemsIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.environProblemsIndicator === true ||
                    formState.environProblemsIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="environProblemsExplanation"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Is the project subject to resale restrictions?"
                      name="resaleRestrictionIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleResaleRestrictionIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.resaleRestrictionIndicator === true ||
                    formState.resaleRestrictionIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="resaleRestrictionExplaination"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Does the project have any health or safety issues?"
                      name="healthSafetyIssuesIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleHealthSafetyIssuesIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.healthSafetyIssuesIndicator === true ||
                    formState.healthSafetyIssuesIndicator === "true" ? (
                      <Field
                        label="Comment"
                        name="healthSafetyIssuesExplaination"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Does the project include nonresidential/commercial
                                units?"
                      name="commercialUnitsInclusionIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleCommercialUnitsInclusionIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="The units are to be"
                      name="commercialUnitsSoldLeasedRetained"
                      formData={formState}
                      dropdown={true}
                      states={units1026}
                      handleChange={
                        handleCommercialUnitsSoldLeasedRetainedChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of non-residential/commercial units"
                      name="commercialUnitsCount"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Percent of nonresidential/commercial space"
                      name="commercialSpacePercent"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Percent of residential space"
                      name="residentialSpacePercent"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Describe intended purpose and use of
                                non-residential/commercial space."
                      name="commercialSpacePurpose"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Is the commercial space complete?"
                      name="commercialSpaceCompleteIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleCommercialSpaceCompleteIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Are there any non-incidental business operations owned
                                or operated by the HOA?"
                      name="nonIncidentalBusiOperationsOwnedHOAIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleNonIncidentalBusiOperationsOwnedHOAIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    {formState.nonIncidentalBusiOperationsOwnedHOAIndicator ===
                      true ||
                    formState.nonIncidentalBusiOperationsOwnedHOAIndicator ===
                      "true" ? (
                      <Field
                        label="Comment"
                        name="nonIncidentalBusiOperationsOwnedHOAComments"
                        formData={formState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="table-header">
                  Legal/Construction/Marketing phase in the entire project:
                </div>
                <div className="list-container">
                  <div className="list-header">
                    <div className="list-header-name">Phase Designation</div>
                    <div className="list-header-name">Building Designation</div>
                    <div className="list-header-name">Phase Unit Count</div>
                    <div className="list-header-name">Included Application</div>
                  </div>
                  <div className="list-body">
                    {formState.phase1026DataList &&
                      formState.phase1026DataList.map((item, index) => (
                        <div key={index} item={item}>
                          <div className="card-row-list">
                            <div className="card-name-list">
                              {item.phaseDesignation}
                            </div>
                            <div className="card-name-list">
                              {item.buildingDesignation}
                            </div>
                            <div className="card-name-list">
                              {item.phaseUnitsCount}
                            </div>
                            {item.includedinApplicationIndicator === true ? (
                              <div className="card-name-list">Yes</div>
                            ) : (
                              <div className="card-name-list">No</div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div> */}
                  {/* <input
                            type="text"
                            value={this.state.value}
                            onChange={this.onChangeValue}
                        />
                        <button
                            type="button"
                            onClick={this.onAddItem}
                            disabled={!this.state.value}
                        >
                            Add
                        </button> */}
                {/* </div> */}
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
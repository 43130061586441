import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../../AddEstablishedPers/AddEstablishedPers.css";
import { DatePicker } from "../../Pers-atoms";
import ReactSelect from "react-select";
import ReasonCodeTooltip from "../../SharedComponents/ReasonCodeTooltip/ReasonCodeTooltip";

export const PersStatus = ({
  formData,
  setFormData,
  nextStep,
  nextStep2,
  nextStep3,
  nextStep4,
  prevStep,
  step,
  persId,
  projectId,
  selectedPhaseList,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (formData.isSingle) {
      selectedPhaseList[0].isSingle = true;
    }
  }, []);

  let [persDecisionData, setPersDecisionData] = React.useState("");
  let [reasonCodes, setReasonCodes] = React.useState([]);
  let [persDecisionStatus, setPersDecisionStatus] = React.useState("");
  let [expirationList, setExpirationList] = React.useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [singleExp, setSingleExp] = useState(false);
  const [singleReason, setSingleReason] = useState(false);
  const [phasePersStatus, setPhasePersStatus] = useState([]);
  const [phasePersExpiration, setPhasePersExpiration] = useState([]);
  const [dtSpecificDate, setDtSpecificDate] = useState([]);
  let [phaseExpirationList, setPhaseExpirationList] = React.useState([]);
  const [phaseSingleExpirationList, setPhaseSingleExpirationList] = React.useState([]);
  const [suspensionExpiry, setSuspensionExpiry] = useState();
  const [isOnlySuspIneli, setIsOnlySuspIneli] = useState(false);
  const [phasePersReasonCodes, setPhasePersReasonCodes] = useState([]);
  const [persReasonCodeValidation, setPersReasonCodeValidation] = React.useState([]);
  const [singleReasonIndex, setSingleReasonIndex] = React.useState(0);
  const [apiErr, setApiErr] = useState("");
  const [emptyErr, setEmptyErr] = useState("");

  const [statusErr, setStatusErr] = useState("");
  const [expErr, setExpErr] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [reasonCodeList, setReasonCodeList] = useState([]);
  const fetchPersDecisionData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/decision/getPersDecisionAndExpiration`
      )
      .then((response) => {
        setPersDecisionData(response.data);
        persDecisionData = response.data;

        setTimeout(() => {
          setPhaseSingleExpirationList(response.data["Final Project Approval"]);

          setExpirationData(formData.persDecisionStatus, response.data);
          if (selectedPhaseList && selectedPhaseList[0]) {
            isSingleSelected(selectedPhaseList[0].isSingle);
          }
          if (formData.phaseDecisions) {
            let i = 0;
            for (let ex of formData.phaseDecisions) {
              setPhaseExpirationData(ex.persDecison, i, false);
              i++;
            }
          }
        }, 500);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);

  const fetchReasonCodes = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/reasonCodes/getReasonCodes`
      )
      .then((response) => {
        if (response && response.data.length > 0) {
          const resonCodes = [];
          response.data.map(r => {
            resonCodes.push({ 'label': r.reasonCode, 'value': r.reasonId })
          })
          resonCodes.sort((a, b) => a.label.localeCompare(b.label));
          setReasonCodes(resonCodes);
          setReasonCodeList( response.data.sort((a, b) => a.reasonCode.localeCompare(b.reasonCode)));
          intializeReasonCode();
        }
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);

  React.useEffect(() => {
    fetchPersDecisionData();
    fetchReasonCodes();
  }, [fetchPersDecisionData, fetchReasonCodes]);

  const persStatusList = Object.keys(persDecisionData).map((key) => key);

  function checkExpirationValidation(data) {
    setApiErr("");
    if (!data.persExpiration) {
      setStatusErr("");
      setDateErr("");
      setExpErr("Required");
    } else {
      setStatusErr("");
      setExpErr("");
      setDateErr("");
    }
  }

  function getDateError(date) {
    if (!date) {
      return "Required";
    }
    
    const inputDate = new Date(date);
    
    if (inputDate.toString() === "Invalid Date") {
      return "Invalid date format";
    }

    const minDate = new Date("1776-01-01");
    
    if (inputDate < minDate) {
      return "Date cannot be before Jan 1, 1776.";
    }
    
    return "";
  }

  function checkSpecificDateValidation(data) {
    setApiErr("");
    setStatusErr("");
    setExpErr("");
    setDateErr(getDateError(data.dtSpecificDate));
  }

  function setExpirationData(value, persDecisionData) {
    setExpErr("");
    setDateErr("");
    setApiErr("");

    setPersDecisionStatus(value);
    setSuspensionExpiry(persDecisionData["Suspension of the Application"][0]);

    if (value == "Suspension of the Application") {
      formData.persExpiration =
        persDecisionData["Suspension of the Application"][0];
    }

    if (value != "") {
      Object.keys(persDecisionData).map((key) => {
        if (key == value) {
          setExpirationList(persDecisionData[key]);
        }
      });
    } else {
      setExpirationList([]);
    }
  }


  function setPhaseExpirationData(value, index, changed) {
    setApiErr("");

    if (
      formData.phaseDecisions &&
      formData.phaseDecisions[index] &&
      formData.phaseDecisions[index].persDecison
    ) {
      formData.phaseDecisions[index].persDecison = value;
      phasePersExpiration[index] =
        formData.phaseDecisions[index].persExpiration;

      if (changed) {
        formData.phaseDecisions[index].persExpiration = "";
        if (selectedPhaseList[0].isSingle) {
          formData.persExpiration = "";
        }
      }
    }

    phasePersStatus[index] = value;

    let isFinal = phasePersStatus.indexOf("Final Project Approval");
    let isAvailable = phasePersStatus.indexOf("Conditional/Available");
    let isUnavailable = phasePersStatus.indexOf("Conditional/Unavailable");

    if (isFinal >= 0 || isAvailable >= 0 || isUnavailable >= 0) {
      setIsOnlySuspIneli(false);
    } else {
      setIsOnlySuspIneli(true);
    }

    setPhasePersExpiration(phasePersExpiration);

    setSuspensionExpiry(persDecisionData["Suspension of the Application"][0]);
    if (value !== "") {
      Object.keys(persDecisionData).map((key) => {
        if (key == value) {
          phaseExpirationList[index] = persDecisionData[key];
          let newArray = [...phaseExpirationList];
          setPhaseExpirationList(newArray);
        }
      });
    } else {
      phaseExpirationList[index] = [];
      setPhaseExpirationList((prevExpiry) => [
        ...prevExpiry,
        phaseExpirationList,
      ]);
    }
    setFormData(formData);
  }

  function savePersDecision(data) {
    setIsSubmitted(true);
    if (!persDecisionStatus && !data.persExpiration) {
      setStatusErr("Required");
      setExpErr("Required");
    } else if (!persDecisionStatus) {
      setStatusErr("Required");
      setExpErr("");
    } else if (!data.persExpiration) {
      setStatusErr("");
      setDateErr("");
      setExpErr("Required");
    } else {
      setStatusErr("");
      setExpErr("");
      setDateErr(getDateError(data.dtSpecificDate));
      if (!dateErr && formData.projectReasonError === 'valid' &&  (formData.persDecisionStatus === "Ineligible" || formData.persDecisionStatus === "Conditional/Unavailable")) {
        checkRedirection(data);
      } else if (!dateErr && formData.persDecisionStatus !== "Ineligible" && formData.persDecisionStatus !== "Conditional/Unavailable") {
        checkRedirection(data);
      }
    }
  }

  function resetConditions() {
    for (let condition of formData.perCondtnsRef) {
      condition.isSelected = false;
    }

    if (formData.addedConditions) {
      for (let addCondition of formData.addedConditions) {
        addCondition.isSelected = false;
      }
    }
  }

  function resetRestrictions() {
    for (let restriction of formData.restrictions) {
      restriction.isSelected = false;
    }

    if (formData.addedRestrictions) {
      for (let addRestriction of formData.addedRestrictions) {
        addRestriction.isSelected = false;
      }
    }
  }

  function checkRedirection(data) {
    if (data.persDecisionStatus == "Final Project Approval") {
      nextStep2();
      resetConditions();
    } else if (data.persDecisionStatus == "Conditional/Available") {
      nextStep();
    } else if (data.persDecisionStatus == "Conditional/Unavailable") {
      nextStep();
    } else if (data.persDecisionStatus == "Suspension of the Application") {
      nextStep3();
      resetConditions();
      resetRestrictions();
    } else if (data.persDecisionStatus == "Ineligible") {
      nextStep4();
      resetConditions();
      resetRestrictions();
    }
  }

  function setFalseAllConditions(phase) {
    for (let condition of phase.perCondtnsRef) {
      if (condition.isSelected) {
        condition.isSelected = false;
      }
    }
  }

  function resetPhaseCondition() {
    if (formData.phaseDecisions) {
      for (let phase of formData.phaseDecisions) {
        if (
          phase.persDecison == "Final Project Approval" ||
          phase.persDecison == "Suspension of the Application" ||
          phase.persDecison == "Ineligible"
        ) {
          for (let condition of phase.perCondtnsRef) {
            if (condition.isSelected) {
              condition.isSelected = false;
            }
          }

          for (let addedCond of phase.addedConditions) {
            if (addedCond.isSelected) {
              addedCond.isSelected = false;
            }
          }
        }

        if (
          phase.persDecison == "Suspension of the Application" ||
          phase.persDecison == "Ineligible" ||
          phase.persDecison == "Conditional/Unavailable"
        ) {
          for (let restric of phase.restrictions) {
            if (restric.isSelected) {
              restric.isSelected = false;
            }
          }

          for (let addedRestric of phase.addedRestrictions) {
            if (addedRestric.isSelected) {
              addedRestric.isSelected = false;
            }
          }
        }
      }
    }
    setFormData(formData);
  }

  function checkPhaseRedirection(data) {
    formData = data;
    setFormData(data);

    let IsConditionAvail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Available";
    });

    let IsConditionUnavail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Unavailable";
    });

    let IsFinalApproval = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Final Project Approval";
    });

    let IsSuspention = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Suspension of the Application";
    });

    let IsIneligible = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    // resetPhaseCondition();
    if (IsConditionAvail) {
      nextStep();
    } else if (IsConditionUnavail) {
      nextStep();
    } else if (IsFinalApproval) {
      nextStep2();
    } else if (IsSuspention) {
      nextStep3();
    } else if (IsIneligible) {
      nextStep4();
    }
  }

  function checkPhaseExpiryValidation(data, index) {
    let arr = [];
    let i = 0;

    setApiErr("");
    setEmptyErr("");

    if (phasePersStatus[index] == "Ineligible") {
      phasePersExpiration[index] = "NA";
    }

    if (phasePersStatus[index] == "Suspension of the Application") {
      phasePersExpiration[index] = suspensionExpiry;
    }

    for (let phase of selectedPhaseList) {
      if (
        (phasePersStatus[i] && !phasePersExpiration[i]) ||
        (phasePersStatus[i] !== "" && phasePersExpiration[i] === "")
      ) {
        if (
          phasePersStatus[i] !== "Ineligible" ||
          phasePersStatus[i] !== "Suspension of the Application"
        ) {
          phase.expError = "Please select expiration date";
        }
      } else {
        phase.expError = "";
      }

      let obj = {
        persDecison: phasePersStatus[i],
        persExpiration: dtSpecificDate[i]
          ? dtSpecificDate[i]
          : phasePersExpiration[i],
        phaseId: phase.value,
      };

      arr.push(obj);
      i++;
    }

    data.phaseDecisions = arr;
  }

  function checkPhaseDtValidation(data, index) {
    let arr = [];
    let i = 0;
    setApiErr("");

    for (let phase of selectedPhaseList) {
      if (phasePersExpiration[i] == "Enter Specific date") {
        if (!dtSpecificDate[i]) {
          phase.dtError = "Please select specific date";
        } else {
          phase.dtError = getDateError(dtSpecificDate[i]);
        }
      } else {
        dtSpecificDate[i] = "";
      }

      let obj = {
        persDecison: phasePersStatus[i],
        persExpiration: dtSpecificDate[i]
          ? dtSpecificDate[i]
          : phasePersExpiration[i],
        phaseId: phase.value,
      };

      arr.push(obj);
      i++;
    }

    data.phaseDecisions = arr;
  }

  function savePhaseDecision(data, direction) {
    setIsSubmitted(true);
    let arr = [];
    let i = 0;
    setApiErr("");
    if (phasePersStatus.length <= 0 && direction == "next") {
      setEmptyErr("Please select atleast one phase decision");
    } else {
      let f = 0;
      for (let decision of phasePersStatus) {
        if (decision == "") {
          f++;
        }
      }

      if (
        phasePersStatus.filter((e) => e !== undefined).length == f &&
        direction == "next"
      ) {
        setEmptyErr("Please select atleast one phase decision");
      } else {
        setEmptyErr("");
        for (let phase of selectedPhaseList) {
          if (selectedPhaseList[0].isSingle) {
            formData.isSingle = true;
            if (phasePersStatus[i] == "Ineligible") {
              phasePersExpiration[i] = "NA";
            } else if (phasePersStatus[i] == "Suspension of the Application") {
              phasePersExpiration[i] = suspensionExpiry;
            } else {
              if (phasePersStatus[i]) {
                phasePersExpiration[i] = formData.dtSpecificDate
                  ? formData.dtSpecificDate
                  : formData.persExpiration;
              }
            }
          } else {
            formData.isSingle = false;
            if (
              (phasePersStatus[i] && !phasePersExpiration[i]) ||
              (phasePersStatus[i] !== "" && phasePersExpiration[i] === "")
            ) {
              if (
                phasePersStatus[i] !== "Ineligible" ||
                phasePersStatus[i] !== "Suspension of the Application"
              ) {
                phase.expError = "Please select expiration date";
              }
            } else {
              if (phasePersExpiration[i] == "Enter Specific date") {
                if (!dtSpecificDate[i]) {
                  phase.dtError = "Please select specific date";
                } else {
                  phase.dtError = getDateError(dtSpecificDate[i]);
                }
              } else {
                dtSpecificDate[i] = "";
                phase.expError = "";
              }
            }
          }

          let obj = {
            persDecison: phasePersStatus[i],
            persExpiration: dtSpecificDate[i]
              ? dtSpecificDate[i]
              : phasePersExpiration[i],
            phaseId: phase.value,
            phaseName: phase.name,
            phaseUnits: phase.unit,
            selectedTab: "pre",
          };

          if (
            formData.phaseDecisions &&
            formData.phaseDecisions[i] &&
            formData.phaseDecisions[i].perCondtnsRef
          ) {
            obj.perCondtnsRef = formData.phaseDecisions[i].perCondtnsRef;
          }

          if (
            formData.phaseDecisions &&
            formData.phaseDecisions[i] &&
            formData.phaseDecisions[i].addedConditions
          ) {
            obj.addedConditions = formData.phaseDecisions[i].addedConditions;
          }

          if (
            formData.phaseDecisions &&
            formData.phaseDecisions[i] &&
            formData.phaseDecisions[i].restrictions
          ) {
            obj.restrictions = formData.phaseDecisions[i].restrictions;
          }

          if (
            formData.phaseDecisions &&
            formData.phaseDecisions[i] &&
            formData.phaseDecisions[i].addedRestrictions
          ) {
            obj.addedRestrictions =
              formData.phaseDecisions[i].addedRestrictions;
          }

          arr.push(obj);
          i++;
        }

        formData.phaseDecisions = arr;

        let flag = false;
        for (let phase of selectedPhaseList) {
          if (phase.expError || phase.dtError) {
            flag = true;
          }
        }

        if (flag == false) {
          let i = 0;
          let tempPhaseDecision = formData.phaseDecisions
            ? formData.phaseDecisions
            : data.phaseDecisions;

          for (let phase of tempPhaseDecision) {
            if (
              phase.persDecison == "Conditional/Available" ||
              phase.persDecison == "Conditional/Unavailable"
            ) {
              phase.perCondtnsRef = phase.perCondtnsRef
                ? phase.perCondtnsRef
                : formData.perCondtnsRef;
              phase.addedConditions = phase.addedConditions
                ? phase.addedConditions
                : [];
            }

            if (
              phase.persDecison == "Conditional/Available" ||
              phase.persDecison == "Final Project Approval"
            ) {
              phase.isChecked = false;
              phase.restrictions = phase.restrictions
                ? phase.restrictions
                : formData.restrictions;
              phase.addedRestrictions = phase.addedRestrictions
                ? phase.addedRestrictions
                : [];
            }

            if (!phase.persDecison) {
              formData.phaseDecisions.splice(i, 1);
            }

            i++;
          }

          if (isReasonCodeValid() && !dateErr) {
            if (direction == "next") {
              if (singleExp) {
                if (formData.persExpiration.length) {
                  checkPhaseRedirection(formData);
                }
              } else {
                checkPhaseRedirection(formData);
              }
            } else {
              prevStep();
            }
          }
        }
      }

      if (
        formData.phaseDecisions &&
        formData.phaseDecisions.length > 0
      ) {
        formData.phaseDecisions.map((phase, index) => {
          if (phase) {
            if (phase.persDecison === 'Ineligible' || phase.persDecison === 'Conditional/Unavailable') {
              if (formData.isSingleReason) {
                phase['reasonIds'] = formData.singleReasonCodes ? formData.singleReasonCodes.map(r => r.value) : [];
              } else {
                phase['reasonIds'] = formData.phaseReasonCodes ? formData.phaseReasonCodes[index].map(r => r.value) : [];
              }
            }  
          }
        })
       
      }
    }
  }

  function isSingleSelected(e) {
    setSingleExp(e);
    if (e == true) {
      selectedPhaseList[0].isSingle = true;
    } else {
      selectedPhaseList[0].isSingle = false;
    }
  }

  function intializeReasonCode() {
    let index = 0;

    if (formData.phaseIds?.length > 0) {
      for (let ex of formData.phaseIds) {
        if (phasePersReasonCodes.length < 0) {
          phasePersReasonCodes[index] = formData.phaseReasonCodes[index];
          persReasonCodeValidation.push(formData.phaseReasonCodes[index].length > 0 ? 'valid' : 'invalid');
        } else {
          phasePersReasonCodes.push([]);
          persReasonCodeValidation.push('init');
        }
        if (formData.phaseReasonCodes) {
          persReasonCodeValidation.push(formData.phaseReasonCodes[index].length > 0 ? 'valid' : 'invalid');
          formData.phaseReasonCodes[index] = formData.phaseReasonCodes[index] ? formData.phaseReasonCodes[index] : [];
        } else {
          formData.phaseReasonCodes = phasePersReasonCodes;
          persReasonCodeValidation.push('init');
        }
        index++;
      }

      if (formData.hasOwnProperty('isSingleReason')) {
        formData.isSingleReason = formData.isSingleReason;
        formData.singleReasonCodes = formData.singleReasonCodes;
        formData.singleReasonValidation = formData.phaseReasonCodes[0].length > 0 ? 'valid' : 'invalid';
      } else {
        formData.isSingleReason = false;
        formData.singleReasonCodes = [];
        formData.singleReasonValidation = 'init';
      }
      formData.persReasonCodeValidation = persReasonCodeValidation;
    } else {
      if (formData.reasonCodes) {
        formData.projectReasonError = 'valid';
      } else {
        formData.projectReasonError = 'init';
      }
    }
    setFormData(formData);
  }

  function setPhaseReasonCodesData(value, index, changed) {
    if (
      formData.phaseReasonCodes &&
      formData.phaseReasonCodes[index] 
    ) {
      if (changed) {
        formData.persReasonCodeValidation[index] = value.length > 0 ? 'valid' : 'error';
        formData.phaseReasonCodes[index] = value;
      }

      setFormData(formData);
    }
  }

  function isReasonCodeValid() {
    if (formData.isSingleReason) {
      return formData.singleReasonValidation === 'error' || formData.singleReasonValidation === 'init' ? false : true
    } else {
      const invalidList = [];
      formData.persReasonCodeValidation.map((v, index) => {
        if (phasePersStatus[index] == "Ineligible" || phasePersStatus[index] == "Conditional/Unavailable") {
          invalidList.push((v === 'error' || v === 'init' ? 'error' : 'valid'));
        }
      });
      return invalidList.filter(v => v === 'error').length > 0 ? false : true
    }
  }

  return (
    <>
      <Formik initialValues={formData} onSubmit={(values) => { }}>
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="second-field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Decision</div>
                <div className="small-title">For PERS ID: {persId}</div>
              </div>
              {formData.decisionLevel == "Entire project" ||
                formData.decisionLevel == "Entire Project" ? (
                <div className="pers-field-lower-container">
                  <div className="section-title">
                    Step 2 : Issue PERS Decision & Expiration
                  </div>
                  <div className="attribute-title">
                    PERS Decision<span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="attribute-select">
                    <select
                      name="persDecisionStatus"
                      id={"id_persDecisionStatus"}
                      className="attribute-select-dropdown select-decision"
                      aria-label='Pers Decision Status'
                      value={
                        persDecisionStatus
                          ? persDecisionStatus
                          : formData.persDecisionStatus
                      }
                      onChange={(event) => {
                        values.persDecisionStatus = event.target.value;
                        values.persExpiration = "NA";

                        formData.persDecisionStatus = event.target.value;
                        formData.persExpiration = "NA";
                        setExpirationData(event.target.value, persDecisionData);
                      }}
                    >
                      <option value="">Select PERS Status</option>
                      {persStatusList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {statusErr ? (
                      <div style={{ color: "red" }}>{statusErr}</div>
                    ) : null}
                  </div>
                  <div className="attribute-title">
                    Expiration<span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="attribute-select">
                    {persDecisionStatus == "Ineligible" ? (
                      <div>{(values.persExpiration = "NA")}</div>
                    ) : persDecisionStatus ==
                      "Suspension of the Application" ? (
                      <div>{(values.persExpiration = suspensionExpiry)}</div>
                    ) : (
                      <select
                        name="persExpiration"
                        id={"id"}
                        className="attribute-select-dropdown select-decision"
                        aria-label='Pers Expiration'
                        value={
                          values.persExpiration
                            ? values.persExpiration
                            : formData.persExpiration
                        }
                        onChange={(event) => {
                          values.persExpiration = event.target.value;
                          values.dtSpecificDate = "";
                          formData.persExpiration = event.target.value;
                          formData.dtSpecificDate = "";
                          setFormData((prevExpiry) => ({
                            ...prevExpiry,
                            values,
                          }));
                          checkExpirationValidation(values);
                        }}
                      >
                        <option value="">Expiration</option>
                        {expirationList
                          ? expirationList.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))
                          : null}
                      </select>
                    )}

                    {expErr ? (
                      <div style={{ color: "red" }}>{expErr}</div>
                    ) : null}
                  </div>

                  <div className="attribute-select">
                    {values.persExpiration === "Enter Specific date" && (
                      <div className="field">
                        <DatePicker
                          label=""
                          name="dtSpecificDate"
                          formData={formData}
                          handleChange={(e) => {
                            values.dtSpecificDate = e.target.value;
                            formData.dtSpecificDate = e.target.value;
                            setFormData((prevExpiry) => ({
                              ...prevExpiry,
                              values,
                            }));

                            checkSpecificDateValidation(values);
                          }}
                          isEdit
                        />
                      </div>
                    )}
                    {dateErr ? (
                      <div style={{ color: "red" }}>{dateErr}</div>
                    ) : null}
                  </div>

                  {
                    persDecisionStatus == "Ineligible" || persDecisionStatus == "Conditional/Unavailable" ? (
                      <>
                        <div className="attribute-title">
                          Reason Codes<span style={{ color: "red" }}>*</span> <ReasonCodeTooltip reasonCodes={reasonCodeList}/>
                        </div>
                        <div className="reason-attribute-select">
                          <ReactSelect
                            name="reasonCodes"
                            value={values.reasonCodes
                              ? values.reasonCodes
                              : formData.reasonCodes}
                            className="pers-reason-codes"
                            options={reasonCodes}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            onChange={(value) => {
                              values.reasonCodes = value;
                              formData.reasonCodes = value;
                              values.projectReasonError = value.length > 0 ? 'valid' : 'error';
                              formData.projectReasonError = value.length > 0 ? 'valid' : 'error';
                              setFormData((prevExpiry) => ({
                                ...prevExpiry,
                                values,
                              }));
                            }}
                            allowSelectAll={false}
                          />
                          {
                            values.projectReasonError === 'error' ||
                            isSubmitted && values.projectReasonError === 'init' ? 
                            <>
                            <div
                            style={{
                              color: "#ff0000",
                            }}>
                            Please select atleast one reason code.
                          </div>
                            </>:
                            <></>
                          }
                        </div></>
                    ) : (<></>)}
                  <div
                    style={{
                      textAlign: "right",
                      color: "#ff0000",
                      padding: 15,
                    }}
                  >
                    {apiErr ? apiErr : null}
                  </div>

                  <div className="pers-bottom-button-container">
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      onClick={() => {
                        savePersDecision(values);
                      }}
                    >
                      Next Section
                    </Button>

                    <Button
                      type="submit"
                      className="previous-button"
                      onClick={() => prevStep()}
                    >
                      Previous Section
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="pers-field-lower-container">
                  <div className="section-title">
                    Step 2 : Issue PERS Decision & Expiration
                  </div>

                  <div className="phase-data-container">
                    <div className="phase-data-sub-container">
                      <div className="phase-data-title">Phases Selected</div>
                    </div>
                    <div className="phase-data-sub-container">
                      <div className="phase-data-title">PERS Decision</div>
                    </div>
                    <div
                      className="phase-data-sub-container-expiration"
                      style={{ display: "flex", flexDirection: "row", flex: 1 }}
                    >
                      <div
                        className="phase-data-title"
                        style={{ marginRight: 10 }}
                      >
                        Expiration
                      </div>
                      <input
                        type="checkbox"
                        className={"checkboxfield"}
                        id={"singleExp"}
                        name={"singleExp"}
                        checked={
                          selectedPhaseList[0] && selectedPhaseList[0].isSingle
                            ? selectedPhaseList[0].isSingle
                            : singleExp
                        }
                        value={singleExp}
                        onChange={(event) => {
                          setIsSubmitted(false);
                          isSingleSelected(!singleExp);
                        }}
                      />
                      <div style={{ fontSize: 14, marginLeft: 7 }}>
                        Apply single expiration date
                      </div>
                    </div>
                    {phasePersStatus?.filter(c => c == "Ineligible" || c == "Conditional/Unavailable").length > 0 ?
                     <>
                                         <div
                      className="phase-data-sub-container-expiration"
                      style={{ display: "flex", flexDirection: "row", flex: 1 }}
                    >
                      <div
                        className="phase-data-title"
                        style={{ marginRight: 5 , marginLeft: "-12px"}}
                      >
                        Reason Codes<ReasonCodeTooltip reasonCodes={reasonCodeList}/>
                      </div>
                      <input
                        type="checkbox"
                        className={"checkboxfield"}
                        id={"singleReason"}
                        name={"singleReason"}
                        checked={
                          values.isSingleReason
                          ? values.isSingleReason
                          : singleReason
                        }
                        value={values.isSingleReason
                          ? values.isSingleReason
                          : singleReason}
                        onChange={(event) => {
                          const singleIndex = phasePersStatus.findIndex(s =>  s == "Ineligible" || s == "Conditional/Unavailable");
                          const isSingle = !singleReason;
                          values.isSingleReason = isSingle;
                          formData.isSingleReason = isSingle;
                          if (!isSingle) {
                            values.singleReasonCodes = [];
                            formData.singleReasonCodes = [];
                          }

                          setFormData((prevExpiry) => ({
                          ...prevExpiry,
                          values,
                          }));
                          setSingleReason(isSingle);
                          setSingleReasonIndex(singleIndex);
                        }}
                      />
                      <div style={{ fontSize: 14, marginLeft: 7 }}>
                        Apply single reason codes.
                      </div>
                    </div>
                    </> 
                     : <></>}
                  </div>


                  {selectedPhaseList.map((option, index) => (
                    <div>
                      <div className="phase-data-container">
                        <div className="phase-data-sub-container">
                          <div className="phase-data-title">{option.name}</div>
                        </div>
                        <div className="phase-data-sub-container">
                          <div className="phase-data-title">
                            <select
                              name="persDecisionStatus"
                              id={index}
                              className="attribute-select-dropdown"
                              value={
                                formData.phaseDecisions &&
                                  formData.phaseDecisions[index] &&
                                  formData.phaseDecisions[index].persDecison
                                  ? formData.phaseDecisions[index].persDecison
                                  : phasePersStatus[index]
                              }
                              onChange={(event) => {
                                
                                setPhaseExpirationData(
                                  event.target.value,
                                  index,
                                  true
                                );
                                formData.persExpiration = "";
                                option.expError = "";
                                setFormData((prevExpiry) => ({
                                  ...prevExpiry,
                                  values,
                                }));
                              }}
                            >
                              <option value="">Select PERS Status</option>
                              {persStatusList.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="phase-data-sub-container-expiration-dropdown">
                          <div className="phase-data-title">
                            {singleExp ? (
                              selectedPhaseList[index].isSingle &&
                                !isOnlySuspIneli ? (
                                <div>
                                  {phasePersStatus[index] == "Ineligible" ? (
                                    <div>
                                      {(phasePersExpiration[index] = "NA")}
                                    </div>
                                  ) : phasePersStatus[index] ==
                                    "Suspension of the Application" ? (
                                    <div>
                                      {
                                        (phasePersExpiration[index] =
                                          suspensionExpiry)
                                      }
                                    </div>
                                  ) : null}
                                  <select
                                    name="persExpiration"
                                    id={"id"}
                                    className="attribute-select-dropdown select-decision"
                                    value={formData.persExpiration}
                                    aria-label="Pers Expiration"
                                    onChange={(event) => {
                                      formData.persExpiration =
                                        event.target.value;
                                      formData.dtSpecificDate = "";

                                      setFormData((prevExpiry) => ({
                                        ...prevExpiry,
                                        values,
                                      }));

                                      checkExpirationValidation(values);
                                    }}
                                  >
                                    <option value="">Expiration</option>
                                    {phaseSingleExpirationList
                                      ? phaseSingleExpirationList.map(
                                        (option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )
                                      : null}
                                  </select>
                                  {/* {expErr ? (
                                    <div style={{ color: "red" }}>{expErr}</div>
                                  ) : null} */}
                                  {singleExp &&
                                    isSubmitted &&
                                    phasePersStatus.length &&
                                    !formData.persExpiration.length ? (
                                    <div
                                      style={{
                                        color: "red",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Please select expiration date
                                    </div>
                                  ) : null}

                                  {/* {isSubmitted && checkPersExpiration() !formData.persExpiration.length ? <p>checking...1</p> : null}   */}
                                </div>
                              ) : phasePersStatus[index] == "Ineligible" ? (
                                <div>{(phasePersExpiration[index] = "NA")}</div>
                              ) : phasePersStatus[index] ==
                                "Suspension of the Application" ? (
                                <div>
                                  {
                                    (phasePersExpiration[index] =
                                      suspensionExpiry)
                                  }
                                </div>
                              ) : null
                            ) : phasePersStatus[index] == "Ineligible" ? (
                              <div>{(phasePersExpiration[index] = "NA")}</div>
                            ) : phasePersStatus[index] ==
                              "Suspension of the Application" ? (
                              <div>
                                {
                                  (phasePersExpiration[index] =
                                    suspensionExpiry)
                                }
                              </div>
                            ) : (
                              <div>
                                <select
                                  // required
                                  name="phasePersExpiration"
                                  id={index}
                                  aria-label="Pers Expiration"
                                  className="attribute-select-dropdown"
                                  value={
                                    formData.phaseDecisions &&
                                      formData.phaseDecisions[index] &&
                                      formData.phaseDecisions[index]
                                        .persExpiration
                                      ? formData.phaseDecisions[index]
                                        .persExpiration
                                      : phasePersExpiration[index]
                                  }
                                  // value={phasePersExpiration[index]}
                                  onChange={(event) => {
                                    phasePersExpiration[index] =
                                      event.target.value;
                                    option.dtError = "";

                                    setPhasePersExpiration((prevExpiry) => ({
                                      ...prevExpiry,
                                      phasePersExpiration,
                                    }));

                                    checkPhaseExpiryValidation(values, index);
                                  }}
                                >
                                  <option value="">Expiration</option>
                                  {phaseExpirationList[index]
                                    ? phaseExpirationList[index].map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )
                                    : null}
                                </select>
                                {option.expError ? (
                                  <div
                                    style={{
                                      textAlign: "left",
                                      color: "#ff0000",
                                      fontWeight: "normal",
                                      // paddingRight: 20
                                    }}
                                  >
                                    {option.expError}
                                  </div>
                                ) : null}
                              </div>
                            )}
                            {/* </Field> */}
                          </div>
                          <div>
                          {singleExp ? (
                            selectedPhaseList[index].isSingle ? (
                              <div className="attribute-select">
                                {formData.persExpiration ===
                                  "Enter Specific date" && (
                                    <div className="field">
                                      <DatePicker
                                        label=""
                                        name="dtSpecificDate"
                                        formData={formData}
                                        handleChange={(e) => {
                                          values.dtSpecificDate = e.target.value;
                                          formData.dtSpecificDate =
                                            e.target.value;

                                          setFormData((prevExpiry) => ({
                                            ...prevExpiry,
                                            values,
                                          }));

                                          checkSpecificDateValidation(values);
                                        }}
                                        isEdit
                                      />
                                    </div>
                                  )}
                                {dateErr ? (
                                  <div style={{ color: "red" }}>{dateErr}</div>
                                ) : null}
                              </div>
                            ) : null
                          ) : (
                            <div>
                              <div className="">
                                {phasePersExpiration[index] ===
                                  "Enter Specific date" && (
                                    <div className="">
                                      <DatePicker
                                        label=""
                                        name="dtSpecificDate"
                                        formData={formData}
                                        handleChange={(event) => {
                                          // values.dtSpecificDate = e.target.value;
                                          dtSpecificDate[index] =
                                            event.target.value;

                                          setDtSpecificDate((prevExpiry) => ({
                                            ...prevExpiry,
                                            dtSpecificDate,
                                          }));

                                          checkPhaseDtValidation(values, index);
                                        }}
                                        isEdit
                                      />
                                    </div>
                                  )}
                              </div>
                              {option.dtError ? (
                                <div
                                  style={{
                                    textAlign: "left",
                                    color: "#ff0000",
                                    // paddingRight: 20
                                  }}
                                >
                                  {option.dtError}
                                </div>
                              ) : null}
                            </div>
                          )}
                          </div>
                        </div>

                        {
                          (phasePersStatus[index] == "Ineligible" || phasePersStatus[index] == "Conditional/Unavailable") ? (
                            values.isSingleReason ?
                              <>
                                {
                                  singleReasonIndex === index ?
                                    <>
                                      <div className="phase-data-sub-container">
                                        <ReactSelect
                                          name="reasonCodes"
                                          className="pers-reason-codes"
                                          options={reasonCodes}
                                          isMulti
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={false}
                                          value={
                                            formData.singleReasonCodes
                                          }
                                          onChange={(value) => {
                                            values.singleReasonCodes = value;
                                            values.singleReasonValidation = value.length > 0 ? 'valid' : 'error';
                                            formData.singleReasonCodes = value
                                            formData.singleReasonValidation = value.length > 0 ? 'valid' : 'error';
                                            setFormData((prevExpiry) => ({
                                              ...prevExpiry,
                                              values,
                                            }));
                                          }}
                                          allowSelectAll={false}
                                        />
                                    {values.singleReasonValidation === 'error' ||
                                    isSubmitted && values.singleReasonValidation === 'init'? (
                                    <div
                                      style={{
                                        textAlign: "left",
                                        color: "#ff0000",
                                        // paddingRight: 20
                                      }}
                                >
                                   Please select atleast one reason code.
                                </div>
                              ) : null}
                                      </div>
                                    </> :
                                    null
                                }
                              </> :
                              <>
                                <div className="phase-data-sub-container">
                                  <ReactSelect
                                    name="reasonCodes"
                                    className="pers-reason-codes"
                                    options={reasonCodes}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    value={
                                      formData.phaseReasonCodes &&
                                        formData.phaseReasonCodes[index]
                                        ? formData.phaseReasonCodes[index]
                                        : phasePersReasonCodes[index]
                                    }
                                    onChange={(value) => {
                                      phasePersReasonCodes[index] = value;
                                      setPhaseReasonCodesData(
                                        value,
                                        index,
                                        true
                                      );
                                      setPhasePersReasonCodes((prevExpiry) => ({
                                        ...prevExpiry,
                                        value,
                                      }));
                                    }}
                                    allowSelectAll={false}
                                  />
                                  {formData.persReasonCodeValidation[index] === 'error' ||
                                  (isSubmitted && formData.persReasonCodeValidation[index] === 'init')? (
                                  <div
                                  style={{
                                    textAlign: "left",
                                    color: "#ff0000",
                                  }}
                                  >
                                    Please select atleast one reason code.
                                </div>
                              ) : null}
                                </div>
                              </>
                          ) : (<></>)}
                        
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      textAlign: "right",
                      color: "#ff0000",
                      padding: 15,
                    }}
                  >
                    {apiErr ? apiErr : null}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      color: "#ff0000",
                      padding: 15,
                    }}
                  >
                    {emptyErr ? emptyErr : null}
                  </div>
                  <div className="pers-bottom-button-container">
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      onClick={() => {
                        savePhaseDecision(formData, "next");
                      }}
                    >
                      Next Section
                    </Button>

                    <Button
                      type="submit"
                      className="previous-button"
                      onClick={() => {
                        savePhaseDecision(formData, "previous");
                      }}
                    >
                      Previous Section
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="right-cancel-container"></div>
          </Form>
        )}
      </Formik>
    </>
  );
};

PersStatus.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

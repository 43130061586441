import React, { useState, useEffect, useCallback } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdFormatLineSpacing } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import axios from "axios";

import { fetchStates } from "../../redux/actions/filterActions";
import GetAppIcon from "@material-ui/icons/GetApp";
import $ from "jquery";
import "../Pers/Pers.css";
import "./index.css";
import Section1 from "./section-1";
import Section2 from "./section-2";
import Section3 from "./section-3";
import Section4 from "./section-4";
import Section5 from "./section-5";
import PersStatus from "../Pers/PersStatus";
import Section6 from "./section-6";
import Section7 from "./section-7";
import Section8 from "./section-8";
import PersDropdown from "../Pers/PersDropdown";
import Section9 from "./section-9";
import EstablishedIcon from "../../images/established-image.png";
import Group17Icon from "../../images/Group 17.svg";
import Group18Icon from "../../images/Group 18.svg";
import Group33Icon from "../../images/Group 33.svg";
import Group34Icon from "../../images/Group-34.svg";
const PersEstablish = (props) => {
  const persId = props.match.params.project_id;
  const [collapseKey, setCollapseKey] = useState("1");
  const [persDetails, setPersDetails] = useState({});
  const [projectDetails, setProjectDetails] = useState({});
  const [projectId, setProjectId] = useState(0);
  const [projectName, setProjectName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectCity, setProjectCity] = useState("");
  const [projectState, setProjectState] = useState("");
  const [projectZip, setProjectZip] = useState("");
  const [persStatus, setPersStatus] = useState("");
  const [dtPersStatusChanged, setDateStatusChanged] = useState("");
  const [decisionHeader, setDecisionHeader] = useState("");
  const [persProjectType, setPersProjectType] = useState("");

  const [reasonCodes, setReasonCodes] = useState([]);
  const dispatch = useDispatch();

  const states = useSelector(
    (dialogueReducer) => dialogueReducer.dialogueReducer.states
  );

  const getPersDetails = async () => {
    try {
      const { data } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/pers/findEstbProjectPersDetailsByPersId/` +
          persId
      );
      console.log("data", data);
      setPersDetails(data);
      setDateCreated(data.perslen.dateCreated);
      setProjectId(data.perslen.projectId);
      setProjectName(data.pers1076.projLegalName);
      setProjectAddress(data.pers1091.projectAddress);
      setProjectCity(data.pers1091.projectCity);
      setProjectState(data.pers1091.projectState);
      setProjectZip(data.pers1091.projectZip);
      setPersStatus(data.perslen.persStatus);
      setDateStatusChanged(data.perslen.dtPersStatusChanged);
      setPersProjectType(data.perslen.persProjectType);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  useEffect(() => {
    getPersDetails();
  }, [persId]);

  useEffect(() => {
    getDecisionDetails();
  }, [persId]);

  useEffect(() => {
    if (projectId != 0) {
      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/condoMgr/getProjectDetailsByProjectId/` +
            projectId
        )
        .then((response) => {
          if(response.data === `Cannot find project:${projectId}`){
            console.warn(response.data);
            window.location.href = "/error-page";
          }
          setProjectDetails(response.data.projectProfile);
          setProjectType(response.data.projectProfile.newProjectType);
        });
        getReasonCodes();
    }
  }, [projectId]);

  const collapseAll = useCallback(() => {
    if (collapseKey === "1") {
      setCollapseKey("0");
    } else if (collapseKey === "0") {
      setCollapseKey("1");
    }
  }, [collapseKey]);

  const { perslen, pers1076, pers1091, pers1073, pers1051, persBudget } =
    persDetails;

  // window.onscroll = function() {
  //   scrollFunction();
  // };

  const getDecisionDetails = async () => {
    try {
      const { data } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/decision/getPersDecisionProfile/` +
          persId
      );
      console.log("data:::::::::::::::::::::::::::::::::", data);
      setDecisionHeader(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getReasonCodes = async () => {
    try {
      const { data } = await axios.get(global.REACT_APP_API_ENDPOINT + `/reasonCodes/getAllReasonCodes`);
      console.log(data);
      if ( data ) {
        const sortCodes = data.sort((a, b) => a.reasonCode.localeCompare(b.reasonCode));
        setReasonCodes(sortCodes);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // function scrollFunction() {
  //   if (document.documentElement.scrollTop >= 201) {
  //     document.getElementById("project-profile-top").classList.add("sticky");
  //     document.getElementById("item-a").style.display = "none";
  //     document.getElementById("item-b").style.display = "none";
  //     document.getElementById("item-c").style.display = "none";
  //     document.getElementById("item-d").style.display = "none";
  //     document.getElementById("item-a-sticky").style.display = "block";
  //     document.getElementById("item-b-sticky").style.display = "flex";
  //   } else {
  //     document.getElementById("project-profile-top").classList.remove("sticky");
  //     document.getElementById("item-a").style.display = "block";
  //     document.getElementById("item-b").style.display = "block";
  //     document.getElementById("item-c").style.display = "block";
  //     document.getElementById("item-d").style.display = "block";
  //     document.getElementById("item-a-sticky").style.display = "none";
  //     document.getElementById("item-b-sticky").style.display = "none";
  //   }
  // }

  const downloadFile = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href =
      global.REACT_APP_API_ENDPOINT +
      `/condoMgr/projectProfile/export/excel/${projectId}`;
    $(a).attr("download", true);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <div
        className="header-container sticky-top"
        style={{
          marginLeft: "0px",
          paddingLeft: "78px",
          paddingBottom: "25px",
          paddingRight: "66px",
          marginRight: "0px",
        }}
      >
        <div>
          <a className="cookie-dash" href={"/"}>
            Home
          </a>{" "}
          /{" "}
          <a className="cookie-dash" href={`/project/${projectId}`}>
            {projectDetails != null ? projectDetails.projectName : ""}
          </a>{" "}
          / <b className="cookie-title">PERS ID: {persId}</b>
        </div>
        <div className="pers-title-header">
          <p className="project-title">
            {projectDetails != null ? projectDetails.projectName : ""}
          </p>
          <div className="title-header-button-container">
            <PersDropdown
              project_id={projectId}
              pers_id={persId}
              pers_status={persStatus}
              pers_type={persProjectType}
              pers_decision={decisionHeader.letterDecisions}
            />
            <div className="button-divider" />
            <button className="download-record-button" onClick={downloadFile}>
              <GetAppIcon className="get-app-icon" />
              Download Record
            </button>
          </div>
        </div>
      </div>
      <div id="project-profile-top" className="project-profile-top">
        <div id="item-a" className="item-a">
          <div className="image-pill-established">
            <div className="image-pill-date">
              {projectName}
              {/* Date Created: {dateCreated.slice(0, 10)} */}
            </div>
            <div className="image-pill-type">ESTABLISHED CONDO PROJECT</div>
          </div>
          <img
            alt="Established Condo Icon"
            className="center-image"
            src={EstablishedIcon}
          />
        </div>
        <div id="item-b" className="item-b">
          <div className="item-b-pers-container">
            <div className="item-b-pers-container-top">
              {/* <img src={Group17Icon}  /> */}
              <img
                alt="Map Icon"
                src={Group17Icon}
                height="47px"
                style={{ height: "47px" }}
              />
            </div>
            <div className="item-b-pers-container-bottom">
              <div className="top">PERS Decision:</div>
              <div className="bottom">
                {decisionHeader != "" ? (
                  <>
                    <div>{decisionHeader.persDecisionHeader}</div>
                  </>
                ) : (
                  <div>Not Available</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="item-c" className="item-c">
          <div className="item-c-container">
            <div className="item-c-container-top">
              <img alt="Identification Card Icon" src={Group18Icon} />
            </div>
            <div className="item-c-container-bottom">
              <div className="top">PERS ID Number:</div>
              <div className="bottom">
                <div>{persId}</div>
              </div>
            </div>
          </div>
        </div>
        <div id="item-d" className="item-d">
          <div className="item-d-container">
            <div className="item-d-container-top">
              <img alt="Timer Icon" src={Group33Icon} />
              <PersStatus
                project_id={projectId}
                pers_id={persId}
                pers_status={persStatus}
                pers_type={projectType}
              />
            </div>
            <div className="item-d-container-bottom">
              <div className="top">PERS Review Status:</div>
              <div className="bottom">
                Status Changed:
                <div className="value">
                  {dtPersStatusChanged === null ? (
                    <>{dateCreated.slice(0, 10)}</>
                  ) : (
                    <>{dtPersStatusChanged}</>
                  )}
                </div>
              </div>
              <div className="bottom">
                Status: <div className="value">{persStatus}</div>
              </div>
            </div>
          </div>
        </div>
        <div id="item-a-sticky" className="item-a-sticky">
          <div className="pill-established">
            <div className="date">Date Created: {dateCreated.slice(0, 10)}</div>
            <div className="type">ESTABLISHED CONDO PROJECT</div>
          </div>
        </div>
        <div id="item-b-sticky" className="item-b-sticky">
          <img alt="Orange Timer Icon" src={Group34Icon} />
          <div className="status">PERS Review Status:</div>
          <div className="prompt">Status Changed:</div>
          <div className="value">
            {dtPersStatusChanged === null ? (
              <>{dateCreated.slice(0, 10)}</>
            ) : (
              <>{dtPersStatusChanged}</>
            )}
          </div>
          <div className="prompt">Status:</div>
          <div className="value">{persStatus}</div>
          <div className="button-container">
            <PersStatus
              project_id={projectId}
              pers_id={persId}
              pers_status={persStatus}
              pers_type={projectType}
            />
          </div>
        </div>
      </div>
      <Navbar className="project-nav" sticky="top">
        <Nav className="container-fluid">
          <Nav.Link href="#section0" className="profile-nav-button">
            Lender Questions
          </Nav.Link>
          <Nav.Link href="#section1" className="profile-nav-button">
            PERS Decision
          </Nav.Link>
          <Nav.Link href="#section2" className="profile-nav-button">
            Letter Details
          </Nav.Link>
          <Nav.Link href="#section3" className="profile-nav-button">
            PERS Exhibit
          </Nav.Link>
          <Nav.Link href="#section4" className="profile-nav-button">
            Lender Forms
          </Nav.Link>
          <Nav.Link href="#section5" className="profile-nav-button">
            Comments
          </Nav.Link>
          <div className="ml-auto"></div>
        </Nav>
      </Navbar>
      <button className="collapse-all" onClick={collapseAll}>
        <MdFormatLineSpacing className="collapse-all-icon" />
        Collapse All
      </button>
      <Section1 collapseKey={collapseKey} lender={perslen} states={states} />
      <Section8
        collapseKey={collapseKey}
        persId={persId}
        projectId={projectId}
        persType={persProjectType}
        reasonCodes={reasonCodes}
      />
      <Section9
        collapseKey={collapseKey}
        persId={persId}
        projectId={projectId}
      />
      <Section6
        collapseKey={collapseKey}
        phaseId={null}
        persId={persId}
        projectId={projectId}
      />
      <Section2
        collapseKey={collapseKey}
        lender={pers1076}
        persId={persId}
        projectId={projectId}
      />
      <Section3
        collapseKey={collapseKey}
        lender={pers1091}
        states={states}
        persId={persId}
        projectId={projectId}
      />
      <Section4
        collapseKey={collapseKey}
        lender={pers1073}
        states={states}
        persId={persId}
        projectId={projectId}
      />
      <Section5
        collapseKey={collapseKey}
        persId={persId}
        projectId={projectId}
        pers1051={pers1051}
        persBudget={persBudget}
      />
      <Section7
        collapseKey={collapseKey}
        persId={persId}
        projectId={projectId}
      />
    </div>
  );
};
export default PersEstablish;

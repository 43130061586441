import { USER_DETAIL_RESULT, ROLE_DETAIL_RESULT } from "../actions/ActionType";

export const initialState = {
  userDetails: []
};

export const getUserDetailsReducer = (userInfo = initialState, action) => {
  switch (action.type) {
    case USER_DETAIL_RESULT:
    userInfo = {
        ...userInfo,
        userDetails: action.payload
      };
      break;
      case ROLE_DETAIL_RESULT:
    userInfo = {
        ...userInfo,
        roleDetails: action.payload
      };
      break;
    default:
  }
  return userInfo;
};

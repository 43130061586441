import { RIGHTS1026_RESULTS } from "../actions/ActionType";

export const initialState = {
  types: []
};
export const rights1026Reducer = (type = initialState, action) => {
  switch (action.type) {
    case RIGHTS1026_RESULTS:
      return {
        rights1026: action.payload
      };
    default:
      return type;
  }
};

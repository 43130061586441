import React from "react";
export const NewProjectEligibility = ({ data }) => {
    return (
        <div style={{ padding: "18px 24px" }}>
        <div className="question">
          <div className="number">1.</div>{" "}
          <div>Is there a master association?</div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.newProjectEligibilitySt !==
          "undefined" ? (
            <>
              {data.newProjectEligibilitySt
                .npeMasterAsscIndSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.newProjectEligibilitySt.npeMasterAsscInd}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.newProjectEligibilitySt.npeMasterAsscInd}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        {data.newProjectEligibilitySt.npeMasterAsscInd === "Yes" ? (
          <>
            <div className="question">
              <div
                className="number"
                style={{
                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                  borderBottom:
                    "1px solid rgba(0,0,0,0.4)",
                  marginLeft: "37px",
                  marginBottom: "10px",
                }}
              ></div>{" "}
              <div style={{ paddingTop: "10px" }}>
                If the unit owners are required to pay
                monthly assessments $50 to a separate
                master association, has the lender
                reviewed the complete development plan for
                the master, and confirmed the
                acceptability of the project?(if not
                applicable, select Yes).
              </div>
            </div>
            <div
              className="summary-answer"
              style={{ paddingLeft: "86px" }}
            >
              <div className="label">Answer:&nbsp;</div>
              {typeof data.newProjectEligibilitySt !==
              "undefined" ? (
                <>
                  {data.newProjectEligibilitySt
                    .npePayMnthyAssesLndrRevwPlanIndSt ===
                  "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {
                          data.newProjectEligibilitySt.npePayMnthyAssesLndrRevwPlanInd
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {
                          data.newProjectEligibilitySt.npePayMnthyAssesLndrRevwPlanInd
                        }
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="question">
          <div className="number">2.</div>{" "}
          <div>
            Do the project’s legal documents comply with
            each of the requirements set forth in the
            <a
              target="_blank"
              href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
            >
              {" "}
              Selling Guide
            </a>
            ?
          </div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.newProjectEligibilitySt !==
          "undefined" ? (
            <>
              {data.newProjectEligibilitySt
                .npeLglDocsComplyIndSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.newProjectEligibilitySt.npeLglDocsComplyInd}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.newProjectEligibilitySt.npeLglDocsComplyInd}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}

import React, { useState } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import axios from "axios";
import moment from "moment";
import { Grid, Button, Tooltip, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ReasonCodeDetails from "../ReasonCodeDetails/ReasonCodeDetails";
import { Add } from "@material-ui/icons";
import { FaPen } from "react-icons/fa";
import './ReasonCodeList.css';

const customDataColumnStyle = {
  wordWrap: "break-word",
  maxWidth: "70px"
};

const customJSONHeadColumnStyle = {
  wordWrap: "break-word",
  backgroundColor: "rgba(17, 118, 181, 0.05)",
  fontWeight: 600
};

const customJSONColumnStyle = {
  wordWrap: "break-word",
  maxWidth: "120px"
};

const getTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="reasonCode"
          align="left"
          style={customJSONHeadColumnStyle}>
          Reason Code
        </TableCell>
        <TableCell
          key="reasonDefinitionInternal"
          align="left"
          style={customJSONHeadColumnStyle}>
          Internal Definition
        </TableCell>
        <TableCell
          key="reasonDefinitionExternal"
          align="left"
          style={customJSONHeadColumnStyle}>
          External Definition
        </TableCell>
        <TableCell
          key="severity"
          align="left"
          style={customJSONHeadColumnStyle}>
          Severity
        </TableCell>
        <TableCell
          key="active"
          align="left"
          style={customJSONHeadColumnStyle}>
          Is Active
        </TableCell>
        <TableCell
          key="updatedOn"
          align="left"
          style={customJSONHeadColumnStyle}>
          Updated on
        </TableCell>
        <TableCell
          key="operation"
          align="left"
          style={customJSONHeadColumnStyle}>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const PayloadTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#f7f7f7",
    border: "1px solid #E6E8ED",
    pointerEvents: "auto!important",
    maxHeight: "350px",
    maxWidth: "500px",
    overflow: "scroll",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "2px solid #E6E8ED",
    },
  },
})(Tooltip);

export default function ReasonCodeList() {
  const [reasonCodeList, setReasonCodeList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showModal, setShowModal] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});

  React.useEffect(() => {
    getReasonCodeList(true);
  }, []);

  const getReasonCodeList = async (resetPage) => {
    try {
      const { data } = await axios.get(
        `${global.REACT_APP_API_ENDPOINT}/reasonCodes/getAllReasonCodes`, {});
      setReasonCodeList(data);
      if (resetPage) {
        setPage(0);
      }
    } catch (err) {
      console.log(err);
    } 3
  };

  const handleAddClick = () => {
    setSelectedData({});
    setEdit(false);
    setShowModal(true);
  };

  const handleEditClick = (data) => {
    setSelectedData(data);
    setEdit(true);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedData({});
    setEdit(false);
    setShowModal(false);
  };

  const handleSave = () => {
    handleModalClose();
    getReasonCodeList(false);
  };

  const validationOnSave = (data, errorData) => {
    let isValid = true;
    [{ key: 'severity', label: 'Severity' }, { key: 'reasonCode', label: 'Reason Code' }].forEach(attr => {
      if (reasonCodeList.filter(ele => ele[attr.key] == data[attr.key]).length == 0) {
        isValid = isValid && true;
      } else if (isEdit && data.reasonId == reasonCodeList.filter(ele => ele[attr.key] == data[attr.key])[0].reasonId) {
        isValid = isValid && true;
      } else {
        errorData[`${attr.key}Err`] = `${attr.label} already exist`;
        isValid = false;
      }
    });
    return isValid;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  const visibleRows = React.useMemo(
    () =>
      reasonCodeList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [reasonCodeList, page, rowsPerPage],
  );

  const renderTooltipData = (data) => {
    let tooltipData;
    if (isValidJson(data)) {
      tooltipData = JSON.stringify(JSON.parse(data), null, 2)
      return (
        <div style={{
          lineHeight: 1.5,
          fontSize: 14,
          maxHeight: "max-content",
        }}><pre>{tooltipData}</pre></div>
      );
    } else {
      tooltipData = data;
      return (
        <div style={{
          lineHeight: 1.5,
          fontSize: 14,
          maxHeight: "max-content",
        }}>{tooltipData}</div>
      );
    }

  };

  const isValidJson = (str) => {
    try {
      if (JSON.parse(str)) {
        return true;
      }
    } catch {
      return false;
    }
  }
  const renderDottedValue = (value = '') => {
    if (value && value.length > 25) {
      return `${value.substring(0, 100)}...`
    }
    return value
  }

  return (
    <div style={{ minHeight: "742px" }}>
      <Grid container xs={12} className="add-button-container" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleAddClick}
          style={{
            backgroundColor: "rgb(17, 118, 181)",
            textTransform: "none"
          }}
          color="primary"
        >
          <Add />
          Add Reason
        </Button>
      </Grid>
      {visibleRows &&
        visibleRows.length > 0 ? (
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                {getTableHeader()}
                <TableBody>
                  {visibleRows?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="left" component="th" scope="row" style={customDataColumnStyle}>{item.reasonCode}</TableCell>
                      <TableCell align="left" style={customJSONColumnStyle}>
                        {item.reasonDefinitionInternal ? (
                          <PayloadTooltip
                            style={{ backgroundColor: "white" }}
                            title={renderTooltipData(item.reasonDefinitionInternal)}
                            placement="right"
                            arrow
                            disableFocusListener
                            disableTouchListener
                            interactive
                          >
                            <span>
                              {" "}
                              {renderDottedValue(item.reasonDefinitionInternal)}{" "}
                            </span>
                          </PayloadTooltip>
                        ) : (<span>{item.reasonDefinitionInternal}</span>)}
                      </TableCell>
                      <TableCell align="left" style={customJSONColumnStyle}>
                        {item.reasonDefinitionExternal ? (
                          <PayloadTooltip
                            style={{ backgroundColor: "white" }}
                            title={renderTooltipData(item.reasonDefinitionExternal)}
                            placement="right"
                            arrow
                            disableFocusListener
                            disableTouchListener
                            interactive
                          >
                            <span>
                              {" "}
                              {renderDottedValue(item.reasonDefinitionExternal)}{" "}
                            </span>
                          </PayloadTooltip>
                        ) : (<span>{item.reasonDefinitionExternal}</span>)}
                      </TableCell>
                      <TableCell align="left" style={customDataColumnStyle} >{item.severity}</TableCell>
                      <TableCell align="left" style={customDataColumnStyle} >
                        <Checkbox disabled checked={item.active} /></TableCell>
                      <TableCell align="left" style={customDataColumnStyle} >{moment(item.updatedDate).format("MM/DD/YYYY HH:mm:ss A")}</TableCell>
                      <TableCell align="left" style={customDataColumnStyle} >
                        <button className="feed-button" onClick={() => handleEditClick(item)}>
                          <FaPen className="feed-icon" />
                          Edit
                        </button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={reasonCodeList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  marginBottom: "0px"
                },
                ".MuiTablePagination-selectLabel": {
                  marginBottom: "0px"
                },
              }}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
          <ReasonCodeDetails
            show={showModal}
            isEdit={isEdit}
            handleClose={handleModalClose}
            handleSave={handleSave}
            data={selectedData}
            handleValidation={validationOnSave}
          />
        </Box>
      ) : (
        <>
          <p class="error"> No records found. </p>
        </>)}
    </div>
  );
}

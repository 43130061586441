import React, { useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import Dropzone from "./Dropzone";
import "./Dropzone.css";
import { isProjectStandardRole } from "../../redux/actions/userAction";

const DropzoneModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleRefresh = () => window.location.reload(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {isProjectStandardRole() ? (
        <button className="upload-attachment-button" onClick={handleShow}>
          <MdFileUpload className="edit-profile-icon" />
          Upload New Attachment
        </button>
      ) : null}

      <Modal aria-label="upload new attachment modal" show={show} onHide={handleClose} style={{paddingTop: "7.5%"}}  backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Upload New Attachment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Dropzone
            projectvalue={props.projectvalue}
            phasevalue={props.phasevalue}
            persvalue={props.persvalue}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DropzoneModal;

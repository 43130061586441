import React from 'react';
import './Eligibility.css';


const IndividualComment = ({ item }) => {

    return (
    <div className="accordion-body-card-attributes">
        <div className="accordion-body-card-attributes-status">
            {item.status}
        </div>
        <div className="accordion-body-card-attributes-name">
           {item.subRuleCode}
        </div>
        <div className="accordion-body-card-attributes-name">
            {item.subRuleDescription}
        </div>
    </div>
    );
  };
  
  
  export default IndividualComment;
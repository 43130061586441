import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import axios from "axios";

import ContextAwareToggle from "../Profile/ContextAwareToggle";
import DropzoneModal from "../Dropzone/DropzoneModal";
import Pdf from "../Pdf/Pdf";

export default function Section6({ collapseKey, persId, projectId, phaseId }) {
  const [getPDF, setPDF] = useState(null);

  const fetchPDFData = async () => {
    const { data } = await axios.get(
      `${global.REACT_APP_API_ENDPOINT}/docStorage/listAllDocsByProjectAndPersId/${projectId}/${persId}`
    );
    setPDF(data);
  };
  useEffect(() => {
    if (projectId) {
      fetchPDFData();
    }
  }, [projectId]);

  const renderPdf = () => {
    let list = <h1>Loading...</h1>;
    if (getPDF) {
      list = <Pdf list={getPDF} />;
    }
    return list;
  };

  return (
    <Accordion defaultActiveKey="1" id="section3">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">PERS Exhibit</p>
          </ContextAwareToggle>
          <DropzoneModal
            projectvalue={projectId}
            phasevalue={phaseId}
            persvalue={persId}
          />
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">{renderPdf()}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

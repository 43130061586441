import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./PersList.css";

const PersSummaryTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#f7f7f7",
    border: "1px solid #E6E8ED",
    height: "max-content",
    pointerEvents: "auto!important",
    maxHeight: "300px",
    maxWidth: "300px",
    overflow: "scroll",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "2px solid #E6E8ED",
    },
  },
})(Tooltip);

const renderDottedValue = (value='') => {
  if(value && value.length>25){
    return `${value.substring(0, 25)}...`
  }
  return value
}
const IndividualPers = ({ item }) => {
  const renderTooltipData = (list) => {
    return (
      <div
        style={{
          lineHeight: 1.5,
          fontSize: 14,
          maxHeight: "max-content",
        }}
      >
        {list && list.length > 0 ? (
          list.map((l) => (
            <div
              style={{
                fontSize: 16,
                fontFamily: "Source Sans Pro Regular",
                lineHeight: 2,
              }}
            >
              {" "}
              {l}{";"}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <a
      href={
        item.persProjectType === "Established"
          ? `/project/pers-establish/${item.persId}`
          : `/project/pers/${item.persId}`
      }
    >
      <div className="card-row-pers">
        <div className="card-project-name-pers">{item.persId}</div>
        <div className="card-project-name-pers">{item.dateReceived}</div>
        <div className="card-project-name-pers">{item.persApprovalStatus}</div>
        <div className="card-project-name-pers">{item.lenderName}</div>
        <div className="card-project-name-pers">
          {item.initialSelectionMap && item.initialSelectionMap.length > 0 ? (
            <PersSummaryTooltip
              style={{ backgroundColor: "white" }}
              title={renderTooltipData(item.initialSelectionMap)}
              placement="right"
              arrow
              disableFocusListener 
              disableTouchListener
              interactive
            >
              <span>
                {" "}
                {renderDottedValue(item.initialSelectionMap.toString())}
              </span>
            </PersSummaryTooltip>
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="card-project-name-pers">
          {item.decisionedMap && item.decisionedMap.length > 0 ? (
            <PersSummaryTooltip
              style={{ backgroundColor: "white" }}
              title={renderTooltipData(item.decisionedMap)}
              placement="right"
              arrow
              disableFocusListener 
              disableTouchListener
              interactive
            >
              <span>
                {" "}
                {renderDottedValue(item.decisionedMap.toString())}{" "}
              </span>
            </PersSummaryTooltip>
          ) : (
            <span>-</span>
          )}
        </div>
      </div>
    </a>
  );
};

export default IndividualPers;

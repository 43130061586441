import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'

export const Page4 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  convResult,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";

  function saveApi() {
    formData = page7tMultiSelectOptions(formData)
    let requestObject = certificationFormatter(formData);
    requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
        ? ""
        : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/lndrCertification/saveLenderCertificationFormsPhase`,
        requestObject
      )
      .then((response) => {
        if (response.status == 200) {
          //setDocId(response.data);
          setTimeout(() => {
            if (formData.phaseId === "proj") {
              window.location.href = "/project/" + formData.projectId;
            } else {
              window.location.href = "/project/phase/" + formData.phaseId;
            }
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            console.log(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("certification phase error", error);
      });
  }

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateNumNoDec(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();
  const [occError, setOccError] = useState("");
  const [secError, setSecError] = useState("");
  const [compError, setCompError] = useState("");
  const [addError, setAddError] = useState("");
  const [soldError, setSoldError] = useState("");

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        var numTotal = parseInt(values.phpUnits, 10);
        var numComp = parseInt(values.phpUnitsComplete, 10);
        var numOO = parseInt(values.phpOwnerOccupiedUnits, 10);
        var numSec = parseInt(values.phpSecondHomes, 10);
        var numCurr = parseInt(values.phpUnitsCurrPhaseAndPrPhase, 10);
        var numSold = parseInt(values.phpUnitsSoldOwnerOccpSecondHomeRsdn, 10);

        const units = numOO + numSec;
        if (numTotal < numComp) {
          setCompError("Cannot be greater than # of Units");
        } else if (numTotal < numOO) {
          setOccError("Cannot be greater than # of Units");
        } else if (numTotal < numSec) {
          setSecError("Cannot be greater than # of Units");
        } else if (numCurr < numSold) {
          setSoldError("Cannot be greater than # of Units in current phase");
        } else if (numTotal < units) {
          setAddError(
            "# of Owner-occupied Units + # of Second Homes cannot be greater than # of Units"
          );
        } else {
          nextStep();
        }
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
            (convResult >= 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={step} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={step} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits &&
                values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={3} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={3} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  <div className="subheader">Section 4 OF 9</div>
                  <div className="title">
                    Presale and Investor Concentration
                  </div>
                  <div className="subtitle"></div>
                </div>
                <div className="form-body">
                  <div className="additional-q-container">
                    <div className="title">Additional Project Questions</div>
                    <div className="subtitle">Applies to New Project Only</div>
                    <div className="question">
                      <div className="number">1.</div>
                      <div># of Expected Phases at the time of Review</div>
                    </div>
                    <div className="answer">
                      <Field
                        name="phpPhases"
                        as={TextField}
                        inputProps={{
                          className: classes.input,
                        }}
                        color="#FCFCFC"
                        variant="outlined"
                        validate={validateNumNoDec}
                        error={touched.phpPhases && errors.phpPhases}
                        helperText={touched.phpPhases && errors.phpPhases}
                      />
                    </div>
                    <div className="question">
                      <div className="number">2.</div>
                      <div>
                        # of Phases that have been annexed into the project
                      </div>
                    </div>
                    <div className="answer">
                      <Field
                        name="phpPhasesAnnexedToProject"
                        as={TextField}
                        inputProps={{
                          className: classes.input,
                        }}
                        color="#FCFCFC"
                        variant="outlined"
                        validate={validateNumNoDec}
                        error={
                          touched.phpPhasesAnnexedToProject &&
                          errors.phpPhasesAnnexedToProject
                        }
                        helperText={
                          touched.phpPhasesAnnexedToProject &&
                          errors.phpPhasesAnnexedToProject
                        }
                      />
                    </div>
                  </div>
                  <div className="form-title" style={{ paddingBottom: "0px" }}>
                    Subject Legal Phase
                  </div>
                  <div className="form-subtitle">
                    Provide unit detail about the <u>Subject Legal Phase</u>
                  </div>
                  <div className="question">
                    <div className="number">1.</div>
                    <div># of Units</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnits"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={touched.phpUnits && errors.phpUnits}
                      helperText={touched.phpUnits && errors.phpUnits}
                    />
                    <div className="form-error">{addError}</div>
                  </div>
                  <div className="question">
                    <div className="number">2.</div>
                    <div>
                      # of Units Complete (include substantially completed units
                      as defined in{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                      >
                        {" "}
                        B4-2.2-03
                      </a>
                      )
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnitsComplete"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={
                        touched.phpUnitsComplete && errors.phpUnitsComplete
                      }
                      helperText={
                        touched.phpUnitsComplete && errors.phpUnitsComplete
                      }
                    />
                    <div className="form-error">{compError}</div>
                  </div>
                  <div className="question">
                    <div className="number">3.</div>
                    <div># of Units for Sale (Developer Unsold Units)</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnitsForSale"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={touched.phpUnitsForSale && errors.phpUnitsForSale}
                      helperText={
                        touched.phpUnitsForSale && errors.phpUnitsForSale
                      }
                    />
                  </div>
                  <div className="question">
                    <div className="number">4.</div>
                    <div># of Units Sold (Developer Sales Only)</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnitsSold"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={touched.phpUnitsSold && errors.phpUnitsSold}
                      helperText={touched.phpUnitsSold && errors.phpUnitsSold}
                    />
                  </div>
                  <div className="question">
                    <div className="number">5.</div>
                    <div># of Owner-Occupied Units</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpOwnerOccupiedUnits"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={
                        touched.phpOwnerOccupiedUnits &&
                        errors.phpOwnerOccupiedUnits
                      }
                      helperText={
                        touched.phpOwnerOccupiedUnits &&
                        errors.phpOwnerOccupiedUnits
                      }
                    />
                    <div className="form-error">{occError}</div>
                  </div>
                  <div className="question">
                    <div className="number">6.</div>
                    <div># of Second Homes</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpSecondHomes"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={touched.phpSecondHomes && errors.phpSecondHomes}
                      helperText={
                        touched.phpSecondHomes && errors.phpSecondHomes
                      }
                    />
                    <div className="form-error">{secError}</div>
                  </div>
                  <div className="question">
                    <div className="number">7.</div>
                    <div># of Units Rented (Investor)</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnitsRented"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={touched.phpUnitsRented && errors.phpUnitsRented}
                      helperText={
                        touched.phpUnitsRented && errors.phpUnitsRented
                      }
                    />
                  </div>
                  <div className="question">
                    <div className="number">8.</div>
                    <div>
                      # of units in current phase plus # of units in all prior
                      legal phases (for example, if project has 5 phases of 100
                      units each, and the current phase is 3rd phase, then the
                      cumulative number of units would be 300).
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnitsCurrPhaseAndPrPhase"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={
                        touched.phpUnitsCurrPhaseAndPrPhase &&
                        errors.phpUnitsCurrPhaseAndPrPhase
                      }
                      helperText={
                        touched.phpUnitsCurrPhaseAndPrPhase &&
                        errors.phpUnitsCurrPhaseAndPrPhase
                      }
                    />
                  </div>
                  <div className="question">
                    <div className="number">9.</div>
                    <div>
                      # of units sold to owner occupied and second home
                      residences in the current and all prior legal phases?
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="phpUnitsSoldOwnerOccpSecondHomeRsdn"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={
                        touched.phpUnitsSoldOwnerOccpSecondHomeRsdn &&
                        errors.phpUnitsSoldOwnerOccpSecondHomeRsdn
                      }
                      helperText={
                        touched.phpUnitsSoldOwnerOccpSecondHomeRsdn &&
                        errors.phpUnitsSoldOwnerOccpSecondHomeRsdn
                      }
                    />
                    <div className="form-error">{soldError}</div>
                  </div>
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button className="save-exit-btn" onClick={saveApi}>
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>
                  <button
                    className="next-button"
                    type="submit"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import React, { Component, useState } from "react";
import { Nav, Navbar, Form, FormControl, Button } from "react-bootstrap";

const ErrorPage = props => {
  return (
    <>
      <div
        style={{
          fontSize: 20,
          textAlign: "center",
          padding: 40,
          fontWeight: "bold",
          color: '#ff0000'
        }}
      >
        Internal server error while processing request, please contact Fannie Mae technology support
      </div>
    </>
  );
};

export default ErrorPage;

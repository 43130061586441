import React from "react";
const ProjectErrorPage = () => {
  return (
    <>
      <div
        style={{
          fontSize: 20,
          textAlign: "center",
          padding: 40,
          fontWeight: "bold",
          color: "#212529",
        }}
      >
        This project record has been deleted. Please contact{" "}
        <span className="card-text card-text-body">
          <a href="mailto:cpm_manage@fanniemae.com">cpm_manage@fanniemae.com</a>
        </span>{" "}
        with any questions or concerns.
      </div>
    </>
  );
};

export default ProjectErrorPage;

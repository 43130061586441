import React, { Component, useState, useEffect } from "react";
import { Nav, Navbar, Form, FormControl, Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import "./NavigationBar.css";
import { MdSettings } from "react-icons/md";
import { MdAccountBox } from "react-icons/md";
import Logo from "../../images/logo.svg";
import ProfileIcon from "../../images/Profile Icon.svg";
import FMLogo from "../../images/FMLogo.svg";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Button as MatButton } from "@material-ui/core";
import {
  getUserDetail,
  isProjectRole,
  isReadonlyRole,
  getTranslatedRoleMap,
  isProjectStandardRole,
  isLenderRole,
  isTPORole,
  isAdminRole
} from "../../redux/actions/userAction";

// class NavigationBar extends Component {

import CancelModal from "./cancel-modal";

const logoutUrls = {
  external: {
    dev: "https://fmsso-devl.fanniemae.com/idp/startSLO.ping?TargetResource=https://singlefamily.fanniemae.com/applications-technology/condo-project-manager",
    acpt: "https://fmsso-acpt.fanniemae.com/idp/startSLO.ping?TargetResource=https://singlefamily.fanniemae.com/applications-technology/condo-project-manager",
    prod: "https://fmsso.fanniemae.com/idp/startSLO.ping?TargetResource=https://singlefamily.fanniemae.com/applications-technology/condo-project-manager",
  },
  internal: {
    dev: "https://fmsso-devl.fanniemae.com/idp/startSLO.ping?TargetResource=https://singlefamily.fanniemae.com/applications-technology/condo-project-manager",
    acpt: "https://fmsso-acpt.fanniemae.com/idp/startSLO.ping?TargetResource=https://singlefamily.fanniemae.com/applications-technology/condo-project-manager",
    prod: "https://fmsso.fanniemae.com/idp/startSLO.ping?TargetResource=https://singlefamily.fanniemae.com/applications-technology/condo-project-manager",
  },
};
const NavigationBar = (props) => {
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [lenderName, setLenderName] = useState("");
  const history = useHistory();
  const [forceCount, setForceCount] = useState(false); //to update states for add new project case
  const dispatch = useDispatch();
  const [isHideHeader, setIsHideHeader] = useState(false);
  const [intitalState, setInitialState] = useState(true);
  React.useEffect(() => {
    setIsHideHeader(
      window.location.pathname === "/session-error-page" ? true : false
    );
    if (
      intitalState === true &&
      window.location.pathname !== "/session-error-page"
    ) {
      dispatch(getTranslatedRoleMap());
      setTimeout(() => {
        getUserRole();
      }, 1000);
      setInitialState(false);
    }
  }, []);

  const userDetails = useSelector(
    (store) => store.getUserDetailsReducer.userDetails
  );

  const roleDetails = useSelector(
    (store) => store.getUserDetailsReducer.roleDetails
  );
  const [showCancel, setShowCancel] = useState(false);
  const [link, setLink] = useState("/");
  const [isLogo, setIsLogo] = useState(false);
  const roleData = JSON.parse(sessionStorage.getItem("roleData"));

  function getUserRole() {
    let role = sessionStorage.getItem("role");
    if (role) {
      setUserRole(role);
      sessionStorage.setItem("role", role);
    } else {
      if (roleDetails && roleDetails.selectedRole) {
        setUserRole(roleDetails[roleDetails.selectedRole]);
        sessionStorage.setItem("role", roleDetails[roleDetails.selectedRole]);
      }
    }
    setInitialState(true);
  }

  function changeRole(e) {
    setUserRole(e.target.value);
    props.dialogueCallback(e.target.value);
    sessionStorage.setItem("role", e.target.value);
    window.location.reload();
    let path = window.location.pathname;
    let splitArr = path.split("/");
    let pathParams = splitArr[1];
    let selectedRole = e.target.value;

    if (
      selectedRole &&
      selectedRole.substring(selectedRole.length - 3, selectedRole.length) ===
      "-RO"
    ) {
      if (path === "/addNewProject") {
        // window.location.href = "/";
        history.replace("/");
      }
    } else {
      if (path !== "/error-page") {
        // window.location.reload();
      }
    }

    // if (pathParams == "addNewProject") {
    // }
  }

  function handleCancelCancel() {
    setShowCancel(false);
  }

  function leaveLose() {
    setShowCancel(false);
    if (link == "/addNewProject") {
      window.location.reload();
    }
  }

  function setLender(e) {
    setLenderName(e.target.value);
    setInitialState(true);
    //setting it to session now to leverage later
    sessionStorage.setItem("lenderId", e.target.value);
  }

  let path = window.location.pathname;
  let splitArr = path.split("/");
  let isFinalApproval = splitArr[1];

  if (userDetails.roles) {
    // dispatch(getTranslatedRoleMap(userDetails.roles));
  }

  const roleKeys =
    roleDetails &&
    roleDetails.hashedRoleMap &&
    Object.keys(roleDetails.hashedRoleMap);
  const lenderKeys =
    roleDetails &&
    roleDetails.associatedLenderMap &&
    Object.keys(roleDetails.associatedLenderMap);

  const clearSessionStorage = () => {
    Object.keys(sessionStorage).forEach((key) =>
      sessionStorage.removeItem(key)
    );
  };

  const handleLogout = () => {
    const hostName = window.location.hostname;

    const getLogoutUrl = () => {
      if (
        userDetails.hasOwnProperty("isInternalUser") &&
        userDetails.isInternalUser
      ) {
        if (
          ["eitcg.cdxms.devl-etss.aws.fanniemae.com", "localhost"].includes(
            hostName
          )
        ) {
          return logoutUrls.internal.dev;
        } else if (hostName.includes(".acpt-") || hostName.includes("-acpt.")) {
          return logoutUrls.internal.acpt;
        } else {
          return logoutUrls.internal.prod;
        }
      }
      if (
        userDetails.hasOwnProperty("isExternalUser") &&
        userDetails.isExternalUser
      ) {
        if (
          ["eitcg.cdxms.devl-etss.aws.fanniemae.com", "localhost"].includes(
            hostName
          )
        ) {
          return logoutUrls.external.dev;
        } else if (hostName.includes(".acpt") || hostName.includes("acpt.")) {
          //  else if (hostName === "eitcg.cdxms.acpt-etss.aws.fanniemae.com") {
          return logoutUrls.external.acpt;
        } else {
          return logoutUrls.external.prod;
        }
      }
    };
    window.location.replace(getLogoutUrl());
    clearSessionStorage();
  };

  //for displaying a shorter name in the option
  const displayShortenedLenderName = (lenderName = "") => {
    // shortening it for over 35 characters for now, we can maintain a config if needed to decide the length
    if (lenderName.length < 35) return lenderName;
    return `${lenderName.substring(0, 35)}...`;
  };

  const renderLenderDropdown = () => {
    return isLenderRole() || isTPORole() ? (
      <>
        <Nav.Link className="lender">
          {isTPORole() ? (<span>Correspondent:</span>) : (<span>Lender:</span>)}
        </Nav.Link>
        <span>
          {" "}
          {lenderKeys && lenderKeys.length > 0 ? (
            <select
              required
              aria-label="lender name and id"
              name="lender"
              id={"lender-id"}
              style={{
                backgroundColor: "transparent",
                color: "#ffffff",
                border: "none",
                fontSize: 14,
                paddingLeft: 0,
                width: 200,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={"Select for Lender"}
              className="lender-select form-control"
              value={lenderName}
              onChange={(event) => {
                setLender(event);
              }}
            >
              {lenderKeys &&
                lenderKeys.map((lender, idx) => (
                  <option
                    className="styled-option"
                    style={{
                      color: "#000",
                    }}
                    value={lender}
                    key={lender - idx}
                  >
                    {displayShortenedLenderName(
                      roleDetails["associatedLenderMap"][lender]
                    )}
                  </option>
                ))}
            </select>
          ) : (
            <></>
          )}
        </span>
      </>
    ) : (
      ""
    );
  };

  return (
    <>
      <a className="skip-main" href="#main">
        Skip to main content
      </a>
      {isHideHeader ? (
        <></>
      ) : (
        <>
          <Navbar className="navbar-top">
            <Nav className="container-fluid nav-container">
              {renderLenderDropdown()}
              <Nav.Link
                className={
                  isLenderRole() || isTPORole() ? "user-profile-lender-view" : "user-profile"
                }
              >
                <img className="user-icon" src={ProfileIcon} alt="profile" />
                {userDetails.firstName} {userDetails.lastName}
              </Nav.Link>
              <Nav.Link className="user-role">
                <span>User Roles : </span>
              </Nav.Link>
              {roleDetails && roleKeys && roleKeys.length === 1 ? (
                <>
                  <span className="user-role">
                    {" "}
                    {roleDetails.hashedRoleMap[roleKeys[0]]}{" "}
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  <select
                    required
                    aria-label="User Role"
                    name="state"
                    id={"stateid"}
                    style={{
                      backgroundColor: "transparent",
                      color: "#ffffff",
                      border: "none",
                      fontSize: 14,
                      paddingLeft: 0,
                      width: "auto",
                    }}
                    className="form-control"
                    value={userRole}
                    onChange={(event) => {
                      changeRole(event);
                      // setErrState(getRequiredErrorMsg(stateRef));
                    }}
                  >
                    {roleDetails &&
                      roleDetails.hashedRoleMap &&
                      roleKeys &&
                      roleKeys.map((eachRole, idx) => (
                        <option
                          value={eachRole}
                          key={idx}
                          style={{ color: "#000" }}
                        >
                          {roleDetails.hashedRoleMap[eachRole]}
                        </option>
                      ))}
                  </select>
                </>
              )}
              <Button
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "16px",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Nav>
          </Navbar>
        </>
      )}
      <CancelModal
        showCancel={showCancel}
        handleCancelCancel={handleCancelCancel}
        leaveLose={leaveLose}
        link={link}
        isLogo={isLogo}
      />
      {/* {isFinalApproval == "final-approval" ||
      isFinalApproval == "conditional-approval" ||
      isFinalApproval == "suspended" ||
      isFinalApproval == "ineligible-letter" ||
      isFinalApproval == "addNewProject" ? (
        <Navbar className="navbar-bottom">
          <Button
            className="brand-logo"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              margin: 0,
              padding: 0,
              width: 40,
            }}
            onClick={() => {
              setLink("https://www.fanniemae.com/");
              setIsLogo(true);
              setShowCancel(true);
            }}
          >
            <img src={Logo} alt="Fannie Mae Logo" width="40" height="40" />
          </Button>
          <Button
            className="brand-button"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              margin: 0,
              width: 180,
            }}
            onClick={() => {
              setLink("/");
              setShowCancel(true);
            }}
          >
            <div
              style={{
                height: 40,
                lineHeight: "175%",
                borderLeft: "2px solid #ffffff",
                paddingLeft: 10,
              }}
            >
              CPM
            </div>
          </Button>

          <Nav className="container-fluid">
            <Button
              className="dashboard-button"
              activeClassName="dashboard-button--active"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              onClick={() => {
                setLink("/");
                setShowCancel(true);
              }}
            >
              Home
            </Button>
            {!isLenderRole() ? (
              <Button
                className="eligibility-list-button"
                activeClassName="eligibility-list-button--active"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                onClick={() => {
                  setLink("/projects-list");
                  setShowCancel(true);
                }}
              >
                Projects List
              </Button>
            ) : (
              ""
            )}

            {isProjectStandardRole() ? (
              <MatButton
                className="add-new-project-button"
                onClick={() => {
                  setLink("/addNewProject");
                  setShowCancel(true);
                }}
              >
                Add New Project
              </MatButton>
            ) : null}
          </Nav>
        </Navbar>
      ) : ( */}
      <Navbar className="navbar-bottom">
        <Navbar.Brand
          className="brand-logo move-left"
          href="https://www.fanniemae.com/"
        >
          <img src={FMLogo} alt="CPM Logo" height="40" />
        </Navbar.Brand>
        <Nav className="container-fluid">
          <NavLink
            exact
            to={"/"}
            className="dashboard-button"
            activeClassName="dashboard-button--active"
          >
            Home
          </NavLink>
          <NavLink
            to="/reports"
            className="reports-button"
            activeClassName="reports-button--active"
          >
            Reports
          </NavLink>
          
          {isProjectStandardRole() ? (
            <NavLink
              to="/banner-history"
              className="reports-button"
              activeClassName="reports-button--active"
            >
              Banners
            </NavLink>
          ) : (
            <></>
          )}

           {isAdminRole() ? (
            <NavLink
              to="/admin"
              className="reports-button"
              activeClassName="reports-button--active"
            >
              Admin
            </NavLink>
           ) : (
            <></>
          )}

          <NavLink
            to="/help-center"
            className={
              isProjectStandardRole() ? "help-button" : "help-button move-right"
            }
            activeClassName="help-button--active"
          >
            Help Center
          </NavLink>
          {isProjectStandardRole() ? (
            <MatButton
              className="add-new-project-button"
              onClick={() => {
                setForceCount((old) => !old);
                setInitialState(true);
                // setLink("/addNewProject");
                // window.location.href = "/addNewProject";
                history.replace("/addNewProject");
              }}
            >
              Add New Project
            </MatButton>
          ) : null}
        </Nav>
      </Navbar>
      {/* // )} */}
    </>
  );
  // }
};

//
// export default withRouter(NavigationBar);
export default NavigationBar;
import React from "react";
import Row from "./Row";

const List = ({ list }) => {
  let cards = <h3>Loading...</h3>;

  if (list) {
    cards = list.map((m, i) => <Row key={i} project={m} />);
  }

  return <div className="list-card-display">{cards}</div>;
};

export default List;

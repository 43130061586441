import React from "react";
import "./projectrestrictions.css";
import ApartmentBlackIcon from "../../../images/apartment_black_24dp@2x.svg";

const ProjectRestrictions = ({ item }) => {
  return (
    <div>
      {typeof item !== "undefined" ? (
        <div className="phase-restrictions-text">
          <div className="phase-restrictions-image">
            <img
              alt="Apartment Icon"
              className="restrictions-image"
              src={ApartmentBlackIcon}
            />
          </div>
          {item.isAddedRestrictions === false ? (
            <div className="text">{item.restrictionsDescription}</div>
          ) : (
            <div className="text">
              {item.restrictionsName}
              :&nbsp;
              {item.restrictionsDescription}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ProjectRestrictions;

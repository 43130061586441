import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'

export const Page8 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  convResult,
  midRuleType,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";



  function saveApi(values) {
    formData = page7tMultiSelectOptions(formData)
    let requestObject = certificationFormatter(formData);
    requestObject.lcLitigation = {
      updatedBy: formData.createdBy,
      litigation: {
        lcommerclSpaceInd: values.lcommerclSpaceInd,
        linsurancCvgInd: values.linsurancCvgInd,
        llitigationInd: values.llitigationInd,
        lminrMttrLitgtnInd: values.lminrMttrLitgtnInd,
        lnonResSqFtg: values.lnonResSqFtg,
        lsafetyLitgtnInd: values.lsafetyLitgtnInd,
      },
    };
    requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
        ? ""
        : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/lndrCertification/saveLenderCertificationFormsPhase`,
        requestObject
      )
      .then((response) => {
        if (response.status == 200) {
          //setDocId(response.data);
          setTimeout(() => {
            if (formData.phaseId === "proj") {
              window.location.href = "/project/" + formData.projectId;
            } else {
              window.location.href = "/project/phase/" + formData.phaseId;
            }
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            console.log(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("certification phase error", error);
      });
  }

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validatePercent(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (
      !/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i.test(value)
    ) {
      error = "Invalid Percentage";
    }
    return error;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();

  const saveAndNext = (data) => {
    let values = data;
    if (values.llitigationInd === "No") {
      values.lsafetyLitgtnInd = "";
      values.lminrMttrLitgtnInd = "";
    }
    if (values.lcommerclSpaceInd === "No") {
      values.lnonResSqFtg = 0;
    }
    setFormData(values);
    nextStep();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
            (convResult >= 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={5} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={6} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits &&
                values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={6} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={7} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  {values.phaseId === "proj" ? (
                    <div
                      className="info-container"
                      style={{ padding: "0px 0px 0px 134px" }}
                    >
                      <>
                        <div className="type">Project Type</div>
                        <div className="attributes">
                          {status === "est" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectNew" ? (
                                <div>
                                  New
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>Established</div>
                              )}
                            </>
                          ) : status === "new" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectEst" ? (
                                <div>
                                  Established
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>New</div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {midRuleType === "projectNew" ? (
                        <div className="subheader">Section 7 OF 8</div>
                      ) : (
                        <div className="subheader">Section 6 OF 7</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 8 OF 9</div>
                    </>
                  )}
                  <div className="title">Litigation, Usage & Insurance</div>
                  <div className="subtitle"></div>
                </div>
                <div className="form-body">
                  <div className="question">
                    <div className="number">1.</div>{" "}
                    <div>
                      Is there any pre-litigation or litigation associated with
                      the project, and naming either the HOA or Developer as a
                      party?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="llitigationInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="llitigationInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.llitigationInd && touched.llitigationInd && (
                      <div className="form-error">{errors.llitigationInd}</div>
                    )}
                  </div>

                  {values.llitigationInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Is the project currently in litigation related to the
                          safety, structural soundness, habitability, or
                          functional use of the project?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="lsafetyLitgtnInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="lsafetyLitgtnInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.lsafetyLitgtnInd &&
                          touched.lsafetyLitgtnInd && (
                            <div className="form-error">
                              {errors.lsafetyLitgtnInd}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {values.llitigationInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Is the project currently in litigation involving minor
                          matters as defined in the{" "}
                          <a
                            target="_blank"
                            href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                          >
                            {" "}
                            Selling Guide
                          </a>
                          ?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="lminrMttrLitgtnInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="lminrMttrLitgtnInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.lminrMttrLitgtnInd &&
                          touched.lminrMttrLitgtnInd && (
                            <div className="form-error">
                              {errors.lminrMttrLitgtnInd}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="question">
                    <div className="number">2.</div>{" "}
                    <div>
                      Does the project or building in which the project is
                      located contain commercial or mixed-use space?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="lcommerclSpaceInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="lcommerclSpaceInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.lcommerclSpaceInd && touched.lcommerclSpaceInd && (
                      <div className="form-error">
                        {errors.lcommerclSpaceInd}
                      </div>
                    )}
                  </div>
                  {values.lcommerclSpaceInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          What is the total percentage used for non-residential
                          purposes as defined by the{" "}
                          <a
                            target="_blank"
                            href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
                          >
                            {" "}
                            Selling Guide
                          </a>
                          ?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <Field
                            name="lnonResSqFtg"
                            as={TextField}
                            style={{ backgroundColor: "#FCFCFC" }}
                            InputProps={{
                              classes: {
                                input: classes.inputPercent,
                              },
                              endAdornment: (
                                <InputAdornment
                                  variant="outlined"
                                  position="end"
                                >
                                  %
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 9,
                            }}
                            variant="outlined"
                            validate={validatePercent}
                            error={touched.lnonResSqFtg && errors.lnonResSqFtg}
                            helperText={
                              touched.lnonResSqFtg && errors.lnonResSqFtg
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="question">
                    <div className="number">3.</div>{" "}
                    <div>
                      Does the project’s insurance coverage (including property,
                      flood, liability, and fidelity/crime, as applicable) meet
                      the Selling Guide requirements in Section{" "}
                      <a
                        target="_blank"
                        href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B7-Insurance/Chapter-B7-3-Property-and-Flood-Insurance/"
                      >
                        {" "}
                        B7-3
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B7-Insurance/Chapter-B7-4-Liability-and-Fidelity-Crime-Insurance/"
                      >
                        {" "}
                        B7-4
                      </a>
                      ?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="linsurancCvgInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="linsurancCvgInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.linsurancCvgInd && touched.linsurancCvgInd && (
                      <div className="form-error">{errors.linsurancCvgInd}</div>
                    )}
                  </div>
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button
                      className="save-exit-btn"
                      onClick={(event) => {
                        saveApi(values);
                      }}
                    >
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>
                  <button
                    className="next-button"
                    type="submit"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Modal from "react-bootstrap/Modal";

const AddModalPhase = ({
  handleAddPhaseClose,
  handleAddPhaseClick,
  showAddPhase,
  phaseIndex,
}) => {
  const [conditionName, setConditionName] = useState("");
  const [conditionDesc, setConditionDesc] = useState("");
  const [conditionNameErr, setConditionNameErr] = useState("");
  const [conditionDescErr, setConditionDescErr] = useState("");

  const handleSubmit = () => {
    if (!conditionName) {
      setConditionNameErr("Required");
    } else if (!conditionDesc) {
      setConditionDescErr("Required");
    } else {
      let tab = RegExp("\\t|\\n", "g");
      let name = conditionName.toString().replace(tab, " ");
      let desc = conditionDesc.toString().replace(tab, " ");
      handleAddPhaseClick(
        {
          conditionName: name,
          conditionDesc: desc,
        },
        phaseIndex
      );

      setConditionName("");
      setConditionDesc("");
    }
  };

  return (
    <Modal
      show={showAddPhase}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleAddPhaseClose}
    >
      <Modal.Header className="the-modal-header" closeButton>
        <Add
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "25px",
            width: "25px",
          }}
        />
        <Modal.Title className="the-modal-title">
          Add New Condition{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
        Enter Information below to add a new condition to your decision letter.
        <div className="field">
          <div className="field-label">
            Name of Condition <span className="mendatory-sign">*</span>
          </div>
          <Grid item xs={12}>
            <input
              required
              type="text"
              className="form-control"
              name="conditionName"
              onChange={(event) => setConditionName(event.target.value)}
            />
            <div className="mendatory-sign">{conditionNameErr}</div>
          </Grid>
        </div>
        <div className="field">
          <div className="field-label">
            Condition Content <span className="mendatory-sign">*</span>
          </div>
          <Grid item xs={12}>
            <textarea
              style={{
                width: "100%",
                minHeight: "100px",
              }}
              onChange={(event) => setConditionDesc(event.target.value)}
            ></textarea>
            <div className="mendatory-sign">{conditionNameErr}</div>
          </Grid>
        </div>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer">
        <Button className="go-back-button" onClick={handleAddPhaseClose}>
          Cancel
        </Button>
        <Button className="cancel-modal-button" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModalPhase;

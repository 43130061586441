import React, { Component, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetail } from "../../redux/actions/userAction";


const UserName = props => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  const userDetails = useSelector(
    store => store.getUserDetailsReducer.userDetails
  );

  return <span>{userDetails.firstName ? ' ' + userDetails.firstName : ' User'} {userDetails.lastName ? ' ' + userDetails.lastName : ' Name'}</span>;
};

export default UserName;

import { CONVERSIONS1026_RESULTS } from "../actions/ActionType";

export const initialState = {
  types: []
};
export const conversions1026Reducer = (type = initialState, action) => {
  switch (action.type) {
    case CONVERSIONS1026_RESULTS:
      return {
        conversions1026: action.payload
      };
    default:
      return type;
  }
};

import React from 'react';
import './Comments.css';
import {  isProjectRole  } from "../../redux/actions/userAction";


const IndividualComment = ({ item }) => {

  return (
    <div className="comment-full-container">
      <div className="comment-image"></div>
      <div className="comment-container">
        <div className="comment-name-time">
          {isProjectRole() ? (
            <div className="comment-name">{item.userName} </div>) :
            <></>}
          <div className="comment-time">{item.timestamp}</div>
        </div>
        <div className="comment-comment">{item.comments}</div>
      </div>
    </div>
  );
};


export default IndividualComment;
import React, { useState, useEffect } from "react";
import { Page1 } from "./pages/page-1";
import { Page2 } from "./pages/page-2";
import { Page3 } from "./pages/page-3";
import axios from "axios";

export const SuspensionLetter = props => {
    window.onscroll = function() {
        effects();
    };
    
    function effects() {}

    const projectId = props.match.params.project_id;
    const persId = props.match.params.pers_id;
    const persType = props.match.params.pers_type;
    const [formState, setFormState] = useState({});
    const [phaseArray, setPhaseArray] = useState([]);
    const [projectInfo, setProjectInfo] = useState({});
    const [projectArray, setProjectArray] = useState([]);
    const [docId, setDocId] = useState(null);
    
    const getLetterDetails = async () => {
        try {
    
          const { data } = await axios.get(
            `${global.REACT_APP_API_ENDPOINT}/persLetter/getSuspensionLetterGenerationDetails/${projectId}/${persId}`
          );
          console.log("DATA DATA DATA:", data)
          setFormState(data);
          setProjectInfo(data.projectAndLenderInfo)
          setPhaseArray(data.phaseDecisions)
          setProjectArray(data.projectDecision)
        } catch (err) {
          console.log(err);
        }
      };

    useEffect(() => {
        getLetterDetails();
    }, []);

    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        entireProjectInd: "",
        phaseDecisionInd: "",
        checked: [],
        projectId: props.match.params.project_id,
        persId: props.match.params.pers_id,
        persType: props.match.params.pers_type,
    });

    const nextStep = () => setStep(prev => prev + 1);
    const prevStep = () => setStep(prev => prev - 1);

    switch (step) {
        // switch (6) {
            case 0:
                return (
                <Page1
                    formData={formData}
                    projectInfo={projectInfo}
                    phaseArray={phaseArray}
                    projectArray={projectArray}
                    setFormData={setFormData}
                    nextStep={nextStep}
                    step={step}
                />
            );
            case 1:
                return (
                <Page2
                    formData={formData}
                    projectInfo={projectInfo}
                    phaseArray={phaseArray}
                    projectArray={projectArray}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    setDocId={setDocId}
                    step={step}
                />
            );
            case 2:
              return (
                <Page3
                  formData={formData}
                  projectArray={projectArray}
                  docId={docId}
                  step={step}
                />
            );
            default:
            return <Page3 formData={formData}  step={step} />;
        }


}
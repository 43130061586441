import React from "react";
export const PhasePresaleAndInvestorConcentration = ({ data })  => {
    return (
      <div style={{ padding: "18px 24px" }}>
      <div className="question">
        <div className="number">1.</div>
        <div>
          # of Expected Phases at the time of Review
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpPhases}
        </div>
      </div>
      <div className="question">
        <div className="number">2.</div>
        <div>
          # of Phases that have been annexed into the
          project
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpPhasesAnnexedToProject}
        </div>
      </div>
      <div className="question">
        <div className="number">1.</div>
        <div># of Units</div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpUnits}
        </div>
      </div>
      <div className="question">
        <div className="number">2.</div>
        <div>
          # of Units Complete (include substantially
          completed units as defined in{" "}
          <a
            target="_blank"
            href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
          >
            {" "}
            B4-2.2-03
          </a>
          )
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        {typeof data.phasePresaleSt !==
        "undefined" ? (
          <>
            {data.phasePresaleSt
              .phpUnitsCompleteSt === "Fail" ? (
              <>
                <div
                  className="attribute"
                  style={{ color: "#D0021B" }}
                >
                  {data.phasePresaleSt.phpUnitsComplete}
                </div>
              </>
            ) : (
              <>
                <div className="attribute">
                  {data.phasePresaleSt.phpUnitsComplete}
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="question">
        <div className="number">3.</div>
        <div>
          # of Units for Sale (Developer Unsold Units)
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpUnitsForSale}
        </div>
      </div>
      <div className="question">
        <div className="number">4.</div>
        <div>
          # of Units Sold (Developer Sales Only)
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpUnitsSold}
        </div>
      </div>
      <div className="question">
        <div className="number">5.</div>
        <div># of Owner-Occupied Units</div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        {typeof data.phasePresaleSt !==
        "undefined" ? (
          <>
            {data.phasePresaleSt
              .phpOwnerOccupiedUnitsSt === "Fail" ? (
              <>
                <div
                  className="attribute"
                  style={{ color: "#D0021B" }}
                >
                  {data.phasePresaleSt.phpOwnerOccupiedUnits}
                </div>
              </>
            ) : (
              <>
                <div className="attribute">
                  {data.phasePresaleSt.phpOwnerOccupiedUnits}
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="question">
        <div className="number">6.</div>
        <div># of Second Homes</div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        {typeof data.phasePresaleSt !==
        "undefined" ? (
          <>
            {data.phasePresaleSt.phpSecondHomesSt ===
            "Fail" ? (
              <>
                <div
                  className="attribute"
                  style={{ color: "#D0021B" }}
                >
                  {data.phasePresaleSt.phpSecondHomes}
                </div>
              </>
            ) : (
              <>
                <div className="attribute">
                  {data.phasePresaleSt.phpSecondHomes}
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="question">
        <div className="number">7.</div>
        <div># of Units Rented (Investor)</div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpUnitsRented}
        </div>
      </div>
      <div className="question">
        <div className="number">8.</div>
        <div>
          # of units in current phase plus # of units
          in all prior legal phases (for example, if
          project has 5 phases of 100 units each, and
          the current phase is 3rd phase, then the
          cumulative number of units would be 300).
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.phasePresaleSt.phpUnitsCurrPhaseAndPrPhase}
        </div>
      </div>
      <div className="question">
        <div className="number">9.</div>
        <div>
          # of units sold to owner occupied and second
          home residences in the current and all prior
          legal phases?
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        {typeof data.phasePresaleSt !==
        "undefined" ? (
          <>
            {data.phasePresaleSt
              .phpUnitsSoldOwnerOccpSecondHomeRsdnSt ===
            "Fail" ? (
              <>
                <div
                  className="attribute"
                  style={{ color: "#D0021B" }}
                >
                  {
                    data.phasePresaleSt.phpUnitsSoldOwnerOccpSecondHomeRsdn
                  }
                </div>
              </>
            ) : (
              <>
                <div className="attribute">
                  {
                    data.phasePresaleSt.phpUnitsSoldOwnerOccpSecondHomeRsdn
                  }
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
    );
}

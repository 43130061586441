import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "./Certification.css";

export default function FormStepper(props) {
  const { step } = props;
  function getSteps() {
    if (props.type === "proj" && props.class === "est") {
      return [
        "Guide Eligibility",
        "Project Information",
        "Project: Presale and Investor Concentration",
        "Project Ownership",
        "Financials",
        "Litigation",
        "Summary"
      ];
    } 
    else if (props.type === "proj" && props.class === "new") {
      return [
        "Guide Eligibility",
        "Project Information",
        "Project: Presale and Investor Concentration",
        "New Project Eligibility",
        "Project Ownership",
        "Financials",
        "Litigation",
        "Summary"
      ];
    }
    else if (props.type !== "proj" && props.class === "est") {
      return [
        "Guide Eligibility",
        "Project Information",
        "Project: Presale and Investor Concentration",
        "Phase: Presale and Investor Concentration",
        "Project Ownership",
        "Financials",
        "Litigation",
        "Summary"
      ];
    }
    else if (props.type !== "proj" && props.class === "new") {
      return [
        "Guide Eligibility",
        "Project Information",
        "Project: Presale and Investor Concentration",
        "Phase: Presale and Investor Concentration",
        "New Project Eligibility",
        "Project Ownership",
        "Financials",
        "Litigation",
        "Summary"
      ];
    }
    if (props.type === "proj" ) {
      return [
        "Guide Eligibility",
        "Project Information",
        "Project: Presale and Investor Concentration",
        "New Project Eligibility",
        "Project Ownership",
        "Financials",
        "Litigation",
        "Summary",
      ];
    }
    else {
      return [
        "Guide Eligibility",
        "Project Information",
        "Project: Presale and Investor Concentration",
        "Phase: Presale and Investor Concentration",
        "New Project Eligibility",
        "Project Ownership",
        "Financials",
        "Litigation",
        "Summary"
      ];
    }
  }
  const steps = getSteps();
  return (
    <div className="side">
      <div className="sticky-stepper">
        <Stepper
          className="side-container"
          activeStep={step}
          orientation="vertical"
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
}

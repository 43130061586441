import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./Edit.css";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.streetInput = React.createRef();
    this.cityInput = React.createRef();
    this.stateInput = React.createRef();
    this.zipInput = React.createRef();
    this.clubhouseInput = React.createRef();
    this.developerUnitsInput = React.createRef();
    this.elevatorInput = React.createRef();
    this.exerciseFacilityInput = React.createRef();
    this.hoaCityInput = React.createRef();
    this.hoaNameInput = React.createRef();
    this.hoaStAddressInput = React.createRef();
    this.hoaStateInput = React.createRef();
    this.hoaTaxIdInput = React.createRef();
    this.hoaZipInput = React.createRef();
    this.masterAssociationNameInput = React.createRef();
    this.otherRecreationalFacilityInput = React.createRef();
    this.parkingFacilityInput = React.createRef();
    this.parkingFacilityCodeInput = React.createRef();
    this.swmmingInput = React.createRef();
    this.tennisInput = React.createRef();
    this.phaseBuildingTypeInput = React.createRef();
    this.phaseBuildingTypeCodeInput = React.createRef();
    this.playGroundFieldInput = React.createRef();
    this.projectTypeCondoIndicatorInput = React.createRef();
    this.projectTypeCoopIndicatorInput = React.createRef();
    this.projectTypePUDIndicatorInput = React.createRef();
    this.unitsSoldInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.state = {
      project: []
    };
  }

  async componentDidMount() {
    try {
      let id = this.props.match.params.edit_id;

      const { data: project } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/findProjectPhaseDetailsByPhaseId/` +
          id
      );
      this.setState({
        project
      });
      console.log(project.street);
    } catch (err) {
      console.log(err);
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    try {
      const data = await axios.put(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/updateProjectProfile/`,
        {
          phaseId: this.props.match.params.edit_id,
          parkingFacilityCode: this.state.project.parkingFacilityCode,
          phaseBuildingTypeCode: this.state.project.phaseBuildingTypeCode,
          projectName: this.state.project.projectName,
          projectUnitCount: this.state.project.projectUnitCount,
          projectId: this.state.project.projectId,
          street: this.state.street,
          city: this.state.city,
          state: this.state.state,
          zipcode: this.state.zipcode,
          masterAssociationName: this.state.masterAssociationName,
          hoaName: this.state.hoaName,
          hoaStAddress: this.state.hoaStAddress,
          hoaCity: this.state.hoaCity,
          hoaState: this.state.hoaState,
          hoaZip: this.state.hoaZip,
          developerControlDates: this.state.project.developerControlDates,
          phaseName: this.state.project.phaseName,
          phaseOtherName: this.state.project.phaseOtherName,
          projectCompletedDate: this.state.project.projectCompletedDate,
          unitOccupancy: this.state.project.unitOccupancy,
          projectTypeCondoIndicator: this.state.projectTypeCondoIndicator,
          projectTypePUDIndicator: this.state.projectTypePUDIndicator,
          projectTypeCoopIndicator: this.state.projectTypeCoopIndicator,
          hoaTaxId: this.state.hoaTaxId,
          phaseBuildingType: this.state.phaseBuildingType,
          unitsSold: this.state.unitsSold,
          developerUnits: this.state.developerUnits,
          parkingFacility: this.state.parkingFacility,
          elevator: this.state.elevator,
          swimming: this.state.swimming,
          tennis: this.state.tennis,
          clubhouse: this.state.clubhouse,
          exerciseFacility: this.state.exerciseFacility,
          playGroundField: this.state.playGroundField,
          otherRecreationalFacility: this.state.otherRecreationalFacility
        }
      );
      this.props.history.push("/project/" + this.state.project.phaseId);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = event => {
    console.log(event.target.name);
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  focusTextInput() {
    this.streetInput.current.focus();
    this.cityInput.current.focus();
    this.stateInput.current.focus();
    this.zipInput.current.focus();
    this.clubhouseInput.current.focus();
    this.developerUnitsInput.current.focus();
    this.elevatorInput.current.focus();
    this.exerciseFacilityInput.current.focus();
    this.hoaCityInput.current.focus();
    this.hoaNameInput.current.focus();
    this.hoaStAddressInput.current.focus();
    this.hoaStateInput.current.focus();
    this.hoaTaxIdInput.current.focus();
    this.hoaZipInput.current.focus();
    this.masterAssociationNameInput.current.focus();
    this.otherRecreationalFacilityInput.current.focus();
    this.parkingFacilityInput.current.focus();
    this.parkingFacilityCodeInput.current.focus();
    this.swmmingInput.current.focus();
    this.tennisInput.current.focus();
    this.phaseBuildingTypeInput.current.focus();
    this.phaseBuildingTypeCodeInput.current.focus();
    this.playGroundFieldInput.current.focus();
    this.projectTypeCondoIndicatorInput.current.focus();
    this.projectTypeCoopIndicatorInput.current.focus();
    this.projectTypePUDIndicatorInput.current.focus();
    this.unitsSoldInput.current.focus();
  }

  render() {
    const {
      elevator = this.state.project.elevator,
      swimming = this.state.project.swimming,
      street = this.state.project.street,
      city = this.state.project.city,
      state = this.state.project.state,
      zipcode = this.state.project.zipcode,
      clubhouse = this.state.project.clubhouse,
      developerUnits = this.state.project.developerUnits,
      exerciseFacility = this.state.project.exerciseFacility,
      hoaCity = this.state.project.hoaCity,
      hoaName = this.state.project.hoaName,
      hoaStAddress = this.state.project.hoaStAddress,
      hoaState = this.state.project.hoaState,
      hoaTaxId = this.state.project.hoaTaxId,
      hoaZip = this.state.project.hoaZip,
      masterAssociationName = this.state.project.masterAssociationName,
      otherRecreationalFacility = this.state.project.otherRecreationalFacility,
      parkingFacility = this.state.project.parkingFacility,
      parkingFacilityCode = this.state.project.parkingFacilityCode,
      tennis = this.state.project.tennis,
      phaseBuildingType = this.state.project.phaseBuildingType,
      phaseBuildingTypeCode = this.state.project.phaseBuildingTypeCode,
      playGroundField = this.state.project.playGroundField,
      projectTypeCondoIndicator = this.state.project.projectTypeCondoIndicator,
      projectTypeCoopIndicator = this.state.project.projectTypeCoopIndicator,
      projectTypePUDIndicator = this.state.project.projectTypePUDIndicator,
      unitsSold = this.state.project.unitsSold
    } = this.state;
    return (
      <div className="container">
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <div className="projects">
            <div className="content">
              <img
                alt="Project Image"
                src={
                  global.REACT_APP_API_ENDPOINT +
                  `/docStorage/serveImage/${this.state.project.mainPicId}`
                }
                alt="Project image"
              />
              <li className="condo-list1">
                <h1 className="condo-name">{this.state.project.projectName}</h1>
                <h4 className="condo-address">
                  <input
                    ref={this.streetInput}
                    type="text"
                    value={street}
                    required
                    name="street"
                    onChange={this.handleChange}
                    onFocus={this.handleChange}
                  />
                  ,
                  <input
                    ref={this.cityInput}
                    type="text"
                    value={city}
                    required
                    placeHolder={this.state.project.city}
                    name="city"
                    onChange={this.handleChange}
                    onFocus={this.handleChange}
                  />
                  ,
                  <select
                    ref={this.stateInput}
                    value={state}
                    name="state"
                    onChange={this.handleChange}
                    onFocus={this.handleChange}
                  >
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DC">DC</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </select>
                  ,
                  <input
                    ref={this.zipInput}
                    type="number"
                    pattern="[0-9]*"
                    value={zipcode}
                    required
                    placeHolder={this.state.project.zipcode}
                    name="zipcode"
                    onChange={this.handleChange}
                    onFocus={this.handleChange}
                  />
                </h4>
                <hr />
                <li className="condo-list2">
                  <p className="condo-detail-top">
                    <span>UNIT</span>
                  </p>
                  <p className="condo-detail-bottom">
                    <span>{this.state.project.projectUnitCount}</span>
                  </p>
                </li>
                <li className="condo-list3">
                  <p className="condo-detail-top">
                    <span>BUILDING NUMBER</span>
                  </p>
                  <p className="condo-detail-bottom">
                    <span>{this.state.project.phaseBldgNumber}&nbsp;</span>
                  </p>
                </li>
                <br />
                <li className="condo-list4">
                  <p className="condo-detail-top">
                    <span>PROJECT ID</span>
                  </p>
                  <p className="condo-detail-bottom">
                    <span>{this.state.project.projectId}</span>
                  </p>
                </li>
                <li className="condo-list5">
                  <p className="condo-detail-top">
                    <span>PHASE ID</span>
                  </p>
                  <p className="condo-detail-bottom">
                    <span>{this.state.project.phaseId}</span>
                  </p>
                </li>
              </li>
            </div>
            <div>
              <hr className="new1" />
              <Accordion defaultActiveKey="0">
                <Card className="card">
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <h2 className="accordion-title">Project Profile</h2>
                  </Accordion.Toggle>
                  <Card.Body>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>CLUBHOUSE</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.clubhouseInput}
                          value={clubhouse}
                          name="clubhouse"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>DEVELOP CONTROL DATES</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <span>{this.state.project.developerControlDates}</span>
                      </p>
                    </li>
                    <li className="condo-list3">
                      <p className="condo-detail-top">
                        <span>DEVELOPER UNITS</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <input
                          ref={this.developerUnitsInput}
                          type="number"
                          pattern="[0-9]*"
                          required
                          value={developerUnits}
                          placeHolder={this.state.project.developerUnits}
                          name="developerUnits"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        />
                      </p>
                    </li>
                    <br />
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>ELEVATOR</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.elevatorInput}
                          value={elevator}
                          name="elevator"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>EXERCISE FACILITY</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.exerciseFacilityInput}
                          value={exerciseFacility}
                          name="exerciseFacility"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list3">
                      <p className="condo-detail-top">
                        <span>HOA CITY</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.hoaCityInput}
                          value={hoaCity}
                          name="hoaCity"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <br />
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>HOA NAME</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.hoaNameInput}
                          value={hoaName}
                          name="hoaName"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>HOA STREET ADDRESS</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.hoaStAddressInput}
                          value={hoaStAddress}
                          name="hoaStAddress"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>HOA STATE</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.hoaStateInput}
                          value={hoaState}
                          name="hoaState"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="AL">AL</option>
                          <option value="AK">AK</option>
                          <option value="AR">AR</option>
                          <option value="AZ">AZ</option>
                          <option value="CA">CA</option>
                          <option value="CO">CO</option>
                          <option value="CT">CT</option>
                          <option value="DC">DC</option>
                          <option value="DE">DE</option>
                          <option value="FL">FL</option>
                          <option value="GA">GA</option>
                          <option value="HI">HI</option>
                          <option value="IA">IA</option>
                          <option value="ID">ID</option>
                          <option value="IL">IL</option>
                          <option value="IN">IN</option>
                          <option value="KS">KS</option>
                          <option value="KY">KY</option>
                          <option value="LA">LA</option>
                          <option value="MA">MA</option>
                          <option value="MD">MD</option>
                          <option value="ME">ME</option>
                          <option value="MI">MI</option>
                          <option value="MN">MN</option>
                          <option value="MO">MO</option>
                          <option value="MS">MS</option>
                          <option value="MT">MT</option>
                          <option value="NC">NC</option>
                          <option value="NE">NE</option>
                          <option value="NH">NH</option>
                          <option value="NJ">NJ</option>
                          <option value="NM">NM</option>
                          <option value="NV">NV</option>
                          <option value="NY">NY</option>
                          <option value="ND">ND</option>
                          <option value="OH">OH</option>
                          <option value="OK">OK</option>
                          <option value="OR">OR</option>
                          <option value="PA">PA</option>
                          <option value="RI">RI</option>
                          <option value="SC">SC</option>
                          <option value="SD">SD</option>
                          <option value="TN">TN</option>
                          <option value="TX">TX</option>
                          <option value="UT">UT</option>
                          <option value="VT">VT</option>
                          <option value="VA">VA</option>
                          <option value="WA">WA</option>
                          <option value="WI">WI</option>
                          <option value="WV">WV</option>
                          <option value="WY">WY</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>HOA TAX ID</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.hoaTaxIdInput}
                          value={hoaTaxId}
                          name="hoaTaxId"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>HOA ZIP</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.hoaZipInput}
                          value={hoaZip}
                          name="hoaZip"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>MASTER ASSOCIATION NAME</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.masterAssociationNameInput}
                          value={masterAssociationName}
                          name="masterAssociationName"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                  </Card.Body>
                </Card>
                <hr className="new1" />
              </Accordion>
              <Accordion defaultActiveKey="1">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    <h2 className="accordion-title">Project Details</h2>
                  </Accordion.Toggle>
                  <Card.Body>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>OTHER RECREATIONAL FACILITY</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.otherRecreationalFacilityInput}
                          value={otherRecreationalFacility}
                          name="otherRecreationalFacility"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PARKING FACILITY</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.parkingFacilityInput}
                          value={parkingFacility}
                          name="parkingFacility"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="1">Open Space</option>
                          <option value="2">Assigned Areas</option>
                          <option value="3">Garages</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PARKING FACILITY CODE</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <input
                          ref={this.parkingFacilityCodeInput}
                          type="number"
                          pattern="[0-9]*"
                          required
                          value={parkingFacilityCode}
                          placeHolder={this.state.project.parkingFacilityCode}
                          name="parkingFacilityCode"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        />
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>SWIMMING</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.swmmingInput}
                          value={swimming}
                          name="swimming"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>TENNIS</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.tennisInput}
                          value={tennis}
                          name="tennis"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PHASE BUILDING TYPE</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.phaseBuildingTypeInput}
                          value={phaseBuildingType}
                          name="phaseBuildingType"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="1">Detached</option>
                          <option value="2">Row or Townhouse</option>
                          <option value="3">Garden</option>
                          <option value="4">Mid-rise</option>
                          <option value="5">High-rise</option>
                          <option value="6">Other</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PHASE BUILDING TYPE CODE</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <input
                          ref={this.phaseBuildingTypeCodeInput}
                          type="number"
                          pattern="[0-9]*"
                          required
                          value={phaseBuildingTypeCode}
                          placeHolder={this.state.project.phaseBuildingTypeCode}
                          name="phaseBuildingTypeCode"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        />
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PHASE NAME</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <span>{this.state.project.phaseName}</span>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PHASE OTHER NAME</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <span>{this.state.project.phaseOtherName}</span>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PLAYGROUND FIELD</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.playGroundFieldInput}
                          value={playGroundField}
                          name="playGroundField"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PROJECT COMPLETE DATE</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <span>{this.state.project.projectCompletedDate}</span>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PROJECT TYPE CONDO INDICATOR</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.projectTypeCondoIndicatorInput}
                          value={projectTypeCondoIndicator}
                          name="projectTypeCondoIndicator"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PROJECT TYPE COOP INDICATOR</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.projectTypeCoopIndicatorInput}
                          value={projectTypeCoopIndicator}
                          name="projectTypeCoopIndicator"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>PROJECT TYPE PUD INDICATOR</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <select
                          ref={this.projectTypePUDIndicatorInput}
                          value={projectTypePUDIndicator}
                          name="projectTypePUDIndicator"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        >
                          <option value="NA">NA</option>
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>UNIT OCCUPANCY</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <span>{this.state.project.unitOccupancy}</span>
                      </p>
                    </li>
                    <li className="condo-list2">
                      <p className="condo-detail-top">
                        <span>UNITS SOLD</span>
                      </p>
                      <p className="condo-detail-bottom">
                        <input
                          ref={this.unitsSoldInput}
                          type="number"
                          pattern="[0-9]*"
                          required
                          value={unitsSold}
                          placeHolder={this.state.project.unitsSold}
                          name="unitsSold"
                          onChange={this.handleChange}
                          onFocus={this.handleChange}
                        />
                      </p>
                    </li>
                  </Card.Body>
                </Card>
              </Accordion>
            </div>
          </div>
          <Button
            className="update-button"
            onClick={this.focusTextInput}
            variant="primary"
            value="Submit"
            type="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
export default Edit;

import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { isTPORole } from "../../../redux/actions/userAction";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import CriticalIcon from "../../../images/Critical@2x.svg";
import { FinancialsSpecialAssessmentsCriticalRepairs } from "../../shared/components/PrintCertificationForms/FinancialsSpecialAssessmentsCriticalRepairs";
import { GuideEligibility } from "../../shared/components/PrintCertificationForms/GuideEligibility";
import { LitigationUsageAndInsurance } from "../../shared/components/PrintCertificationForms/LitigationUsageAndInsurance";
import { NewProjectEligibility } from "../../shared/components/PrintCertificationForms/NewProjectEligibility";
import { PhasePresaleAndInvestorConcentration } from "../../shared/components/PrintCertificationForms/PhasePresaleAndInvestorConcentration";
import { ProjectInformation } from "../../shared/components/PrintCertificationForms/ProjectInformation";
import { ProjectOwnership } from "../../shared/components/PrintCertificationForms/ProjectOwnership";
import { ProjectPresaleAndInvestorConcentration } from "../../shared/components/PrintCertificationForms/ProjectPresaleAndInvestorConcentration";
import { fspAssmtsRuleCheck } from "../../shared/services/SharedService"
export const Page12 = ({
  formData,
  setFormData,
  streetInfo,
  docId,
  step,
  convResult,
  year1,
  year2,
  year3,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function goToProject() {
    window.location.href = `/project/${formData.projectId}`;
  }

  function goToPhase() {
    window.location.href = `/project/phase/${formData.phaseId}`;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            <div className="main" style={{ backgroundColor: "#ffffff" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="subtitle">
                  {values.phaseId === "proj" ? (
                    <>
                      <img
                        alt="Apartment Logo"
                        style={{ paddingRight: "8px", paddingBottom: "4px" }}
                        src={ApartmentIcon}
                      />
                      {streetInfo.projectName}
                    </>
                  ) : (
                    <>
                      <img
                        alt="Phase Logo"
                        style={{ paddingRight: "8px", paddingBottom: "4px" }}
                        src={LayersBlackIcon}
                      />
                      {streetInfo.phaseName}
                    </>
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "150px" }}>
                    <div className="subtitle-certified-title">Certified by</div>
                    <div className="subtitle-certified-attribute">
                      {docId.lenderUserName}
                    </div>
                  </div>
                </div>
              </div>
              {typeof docId.guideEligibilityAndProjectInfoSt !== "undefined" ? (
                <>
                  {docId.certificationStatus === "Certified by Lender" ? (
                    <div
                      className="decline-info"
                      style={{ border: "1px solid #098620" }}
                    >
                      <div
                        className="decline-info-symbol"
                        style={{ backgroundColor: "#098620" }}
                      >
                        <CheckIcon
                          style={{
                            color: "#FFFFFF",
                            width: "30px",
                            height: "30px",
                            margin: "5px 0px",
                          }}
                        />
                      </div>
                      <div className="decline-info-text">
                        {docId.certificationStatus}
                      </div>
                    </div>
                  ) : (
                    <div className="decline-info">
                      <div className="decline-info-symbol">
                        <CloseIcon
                          style={{
                            color: "#FFFFFF",
                            width: "30px",
                            height: "30px",
                            margin: "5px 0px",
                          }}
                        />
                      </div>
                      <div className="decline-info-text">
                        {docId.certificationStatus}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className="info" style={{ margin: "0px" }}>
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    <div className="type">Certification ID</div>
                    <div className="attributes">
                      {docId.certificationId}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "0px", paddingTop: "0px" }}
                  >
                    <div className="status-type">Status</div>
                    {typeof docId.guideEligibilityAndProjectInfoSt !==
                      "undefined" ? (
                      <>
                        {docId.certificationStatus === "Certified by Lender" ? (
                          <>
                            <div
                              className="status-attributes"
                              style={{ color: "#098620", fontWeight: "bold" }}
                            >
                              {docId.certificationStatus}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="status-attributes"
                              style={{ color: "#D0021B", fontWeight: "bold" }}
                            >
                              {docId.certificationStatus}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    <div className="type">Expires</div>
                    <div className="attributes">
                      {docId.dateExpiry ? docId.dateExpiry : "NA"}
                    </div>{" "}
                  </div>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    <div className="type">Project Type</div>
                    <div className="attributes">
                      {docId.certProjectType ? docId.certProjectType : "NA"}
                    </div>{" "}
                  </div>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    <div className="type">
                      Project Type Code to be used at delivery
                    </div>
                    <div className="attributes">
                      {docId.certProjectType === "New"
                        ? "R-Full Review (with or without CPM)—New condo project"
                        : docId.certProjectType === "Established"
                          ? "S-Full Review (with or without CPM)—Established condo project"
                          : ""}
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  <div className="title">Results</div>
                </div>
                <div className="form-body">
                  {docId.delvyRestrictions ? (
                    <div className="certification-result-restriction-container">
                      <div className="restriction-title">
                        <img
                          alt="Critical Warning Logo"
                          style={{ paddingRight: "8px", paddingBottom: "3px" }}
                          src={CriticalIcon}
                        />
                        Delivery Restrictions
                      </div>
                      <div className="restriction-attribute">
                        <img
                          alt="Apartment Logo"
                          style={{
                            marginRight: "8px",
                            marginTop: "3px",
                            width: "17px",
                            height: "17px",
                          }}
                          src={ApartmentIcon}
                        />
                        {docId.delvyRestrictions}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {typeof docId.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                      {(docId.guideEligibilityAndProjectInfoSt
                        .geHotelMotelResortIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geTimeshareCountSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geHouseBoatIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geMulLivingSpacesIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geSplitOwnershipIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geNIncIncomeAbovePmtdIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .gePerTotIncNonIncIncomeSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geUnpaidCommonExpAsmtsIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geCareFacilityIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .gePayMemshpOrLeaseIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .getCmplnHOAOwnshpIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geEnvHazardIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .geRtFtRefslOrReSlRstnsIndSt === "Fail" ||
                        docId.newProjectEligibilitySt.npeFinConExcessIndSt ===
                        "Fail") &&
                        docId.certificationStatus === "Guide Ineligible" ? (
                        <>
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            1. Guide Eligibility
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="print-page-header-title">
                            1. Guide Eligibility
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="print-page-header-title">
                        1. Guide Eligibility
                      </p>
                    </>
                  )}
                  <GuideEligibility data={docId} />
                  {typeof docId.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                      {(docId.guideEligibilityAndProjectInfoSt.piYearBuiltSt ===
                        "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piConvtdProjIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piGutRehabIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piYearConvtdSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piAddnPhsOrAnnxIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piCntlHOATurnOverIndSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piDtTurndOverSt === "Fail" ||
                        docId.guideEligibilityAndProjectInfoSt
                          .piCommnAreasCompltIndSt === "Fail" ||
                        docId.newProjectEligibilitySt
                          .npeAssrArrgReviewedIndSt === "Fail") &&
                        docId.certificationStatus === "Guide Ineligible" ? (
                        <>
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            2. Project Information
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="print-page-header-title">
                            2. Project Information
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="print-page-header-title">
                        2. Project Information
                      </p>
                    </>
                  )}
                  <ProjectInformation data={docId} />

                  {typeof docId.projectPresaleSt !== "undefined" ? (
                    <>
                      {(docId.projectPresaleSt.ppTotalUnitsSt === "Fail" ||
                        docId.projectPresaleSt.ppOwnerOccupiedUnitsSt ===
                        "Fail" ||
                        docId.projectPresaleSt.ppSecondHomesSt === "Fail" ||
                        docId.projectPresaleSt.ppUnitsRentedSt === "Fail" ||
                        docId.projectPresaleSt.ppUnitsCompleteSt === "Fail" ||
                        docId.projectPresaleSt.ppUnitsConveyedSt === "Fail" ||
                        docId.projectPresaleSt.ppPreSaleConveyPercntgeSt ===
                        "Fail" ||
                        docId.projectPresaleSt
                          .ppDevOwndUntsRentalHoldBackIndSt === "Fail" ||
                        docId.projectPresaleSt.ppDevOwnedUnitsSt === "Fail" ||
                        docId.projectPresaleSt
                          .ppMaintFeesDevOwndCurrentIndSt === "Fail" ||
                        docId.projectPresaleSt.ppActivePendingSpAssmntsIndSt ===
                        "Fail") &&
                        docId.certificationStatus === "Guide Ineligible" ? (
                        <>
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            3. Project: Presale and Investor Concentration
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="print-page-header-title">
                            3. Project: Presale and Investor Concentration
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="print-page-header-title">
                        3. Project: Presale and Investor Concentration
                      </p>
                    </>
                  )}
                  <ProjectPresaleAndInvestorConcentration data={docId} convResult={convResult} />
                  {values.phaseId === "proj" ? (
                    <></>
                  ) : (
                    <>
                      {typeof docId.phasePresaleSt !== "undefined" ? (
                        <>
                          {(docId.phasePresaleSt.phpUnitsCompleteSt ===
                            "Fail" ||
                            docId.phasePresaleSt
                              .phpUnitsSoldOwnerOccpSecondHomeRsdnSt ===
                            "Fail" ||
                            docId.phasePresaleSt.phpOwnerOccupiedUnitsSt ===
                            "Fail" ||
                            docId.phasePresaleSt.phpSecondHomesSt === "Fail") &&
                            docId.certificationStatus === "Guide Ineligible" ? (
                            <>
                              <p
                                className="print-page-header-title"
                                style={{ color: "#D0021B" }}
                              >
                                4. Phase: Presale and Investor Concentration
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="print-page-header-title">
                                4. Phase: Presale and Investor Concentration
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p className="print-page-header-title">
                            4. Phase: Presale and Investor Concentration
                          </p>
                        </>
                      )}
                      <PhasePresaleAndInvestorConcentration data={docId} />

                    </>
                  )}

                  <>
                    {" "}
                    {docId.certProjectType === "Established" ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        {typeof docId.newProjectEligibilitySt !==
                          "undefined" ? (
                          <>
                            {(docId.newProjectEligibilitySt
                              .npeMasterAsscIndSt === "Fail" ||
                              docId.newProjectEligibilitySt
                                .npePayMnthyAssesLndrRevwPlanIndSt === "Fail" ||
                              docId.newProjectEligibilitySt
                                .npeLglDocsComplyIndSt === "Fail") &&
                              docId.certificationStatus === "Guide Ineligible" ? (
                              <>
                                {values.phaseId === "proj" ? (
                                  <p
                                    className="print-page-header-title"
                                    style={{ color: "#D0021B" }}
                                  >
                                    4. New Project Eligibility
                                  </p>
                                ) : (
                                  <p
                                    className="print-page-header-title"
                                    style={{ color: "#D0021B" }}
                                  >
                                    5. New Project Eligibility
                                  </p>
                                )}
                              </>
                            ) : (
                              <>
                                {values.phaseId === "proj" ? (
                                  <p className="print-page-header-title">
                                    4. New Project Eligibility
                                  </p>
                                ) : (
                                  <p className="print-page-header-title">
                                    5. New Project Eligibility
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {values.phaseId === "proj" ? (
                              <p className="print-page-header-title">
                                4. New Project Eligibility
                              </p>
                            ) : (
                              <p className="print-page-header-title">
                                5. New Project Eligibility
                              </p>
                            )}
                          </>
                        )}
                        <NewProjectEligibility data={docId} />
                      </>
                    )}
                  </>

                  {typeof docId.projectOwnershipSt !== "undefined" ? (
                    <>
                      {(docId.projectOwnershipSt.poOwnersHaveSoleOwnshpIndSt ===
                        "Fail" ||
                        docId.projectOwnershipSt.poUnitsSnglOwnSt === "Fail" ||
                        docId.projectOwnershipSt.poLeaseComplyIndSt ===
                        "Fail" ||
                        docId.projectOwnershipSt.poSnglOwnMoreThanOneIndSt ===
                        "Fail") &&
                        docId.certificationStatus === "Guide Ineligible" ? (
                        <>
                          {docId.certProjectType === "Established" ? (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              4. Project Ownership
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              5. Project Ownership
                            </p>
                          ) : (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              6. Project Ownership
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {docId.certProjectType === "Established" ? (
                            <p className="print-page-header-title">
                              4. Project Ownership
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p className="print-page-header-title">
                              5. Project Ownership
                            </p>
                          ) : (
                            <p className="print-page-header-title">
                              6. Project Ownership
                            </p>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {docId.certProjectType === "Established" ? (
                        <p className="print-page-header-title">
                          4. Project Ownership
                        </p>
                      ) : values.phaseId === "proj" ? (
                        <p className="print-page-header-title">
                          5. Project Ownership
                        </p>
                      ) : (
                        <p className="print-page-header-title">
                          6. Project Ownership
                        </p>
                      )}
                    </>
                  )}
                  <ProjectOwnership data={docId} />
                  {typeof docId.lcFinancialsSt !== "undefined" ? (
                    <>
                      {(  docId.lcFinancialsSt.famtExclItemsSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fbugtAnlIncomeSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fbugtAnlReservesSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fdefdMntIndSt === 
                          "Fail" ||
                          docId.lcFinancialsSt.finspFailIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fleakRepairIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fmetDefIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fphyDetIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fresStudyReqIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.funfundRepIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.funitsHOAExpnPastDueSt ===
                          "Fail" ||
                        fspAssmtsRuleCheck(docId.lcFinancialsSt.fspAssmts) === "Fail"
                        ) &&
                        docId.certificationStatus === "Guide Ineligible" ? (
                        <>
                          {docId.certProjectType === "Established" ? (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              5. Financials, Special Assessments & Critical Repairs
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              6. Financials, Special Assessments & Critical Repairs
                            </p>
                          ) : (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              7. Financials, Special Assessments & Critical Repairs
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {docId.certProjectType === "Established" ? (
                            <p className="print-page-header-title">
                              5. Financials, Special Assessments & Critical Repairs
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p className="print-page-header-title">
                              6. Financials, Special Assessments & Critical Repairs
                            </p>
                          ) : (
                            <p className="print-page-header-title">
                              7. Financials, Special Assessments & Critical Repairs
                            </p>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {docId.certProjectType === "Established" ? (
                        <p className="print-page-header-title">
                          5. Financials, Special Assessments & Critical Repairs
                        </p>
                      ) : values.phaseId === "proj" ? (
                        <p className="print-page-header-title">
                          6. Financials, Special Assessments & Critical Repairs
                        </p>
                      ) : (
                        <p className="print-page-header-title">
                          7. Financials, Special Assessments & Critical Repairs
                        </p>
                      )}
                    </>
                  )}
                  <FinancialsSpecialAssessmentsCriticalRepairs data={docId} />
                  {typeof docId.lcLitigationSt !== "undefined" ? (
                    <>
                      {(docId.lcLitigationSt.lsafetyLitgtnIndSt === "Fail" ||
                        docId.lcLitigationSt.linsurancCvgIndSt === "Fail" ||
                        docId.lcLitigationSt.lminrMttrLitgtnIndSt === "Fail" ||
                        docId.lcLitigationSt.lnonResSqFtgSt === "Fail") &&
                        docId.certificationStatus === "Guide Ineligible" ? (
                        <>
                          {docId.certProjectType === "Established" ? (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              6. Litigation, Usage & Insurance
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              7. Litigation, Usage & Insurance
                            </p>
                          ) : (
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              8. Litigation, Usage & Insurance
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {docId.certProjectType === "Established" ? (
                            <p className="print-page-header-title">
                              6. Litigation, Usage & Insurance
                            </p>
                          ) : values.phaseId === "proj" ? (
                            <p className="print-page-header-title">
                              7. Litigation, Usage & Insurance
                            </p>
                          ) : (
                            <p className="print-page-header-title">
                              8. Litigation, Usage & Insurance
                            </p>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {docId.certProjectType === "Established" ? (
                        <p className="print-page-header-title">
                          6. Litigation, Usage & Insurance
                        </p>
                      ) : values.phaseId === "proj" ? (
                        <p className="print-page-header-title">
                          7. Litigation, Usage & Insurance
                        </p>
                      ) : (
                        <p className="print-page-header-title">
                          8. Litigation, Usage & Insurance
                        </p>
                      )}
                    </>
                  )}
                  <LitigationUsageAndInsurance data={docId} />
                </div>
                <div className="form-footer" style={{ display: "block" }}>
                  <div className="user-agreement-title">User Agreement</div>
                  {!isTPORole() &&
                    <div className="disclosure">
                      Lender certifies and agrees that (i) the Lender has reviewed
                      and recommends the project for approval by Fannie Mae; (ii)
                      all provisions of the Fannie Mae Selling Guide
                      (collectively, including all Announcements, the{" "}
                      <strong>“Selling Guide”</strong>) pertaining to the Full
                      Review of condo projects using CPM will apply to the subject
                      project and that Lender is responsible for ensuring full
                      compliance with all applicable provisions; (iii) the
                      information and statements contained in this application are
                      true and correct; and (iv) to the best of Lender’s
                      knowledge, there is nothing about the subject property that
                      would make the project ineligible or cause the project to be
                      ineligible for the Full Review process using CPM. Lender
                      agrees that the questions included in CPM are provided to
                      assist the Lender with the Full Review process, that CPM
                      Certifications are based solely on data that the Lender
                      enters in CPM and that additional project eligibility
                      requirements may be included in the Selling Guide. If there
                      is any conflict between the project eligibility requirements
                      in CPM and those in the Selling Guide, the Selling Guide
                      provisions will govern. The Lender must notify the CPM
                      Management team if it becomes aware of any information that
                      could impact the eligibility status reflected in CPM and
                      must provide such notification as soon as practicable but no
                      later than five days after becoming aware of such
                      information. In such instances, Lender must confirm the
                      project’s approved status before selling a loan to Fannie
                      Mae that is secured by one of the project’s units. Fannie
                      Mae reserves the right to change a project’s eligibility
                      status designation after certification if information
                      acquired has an impact on a previously issued eligibility
                      determination.
                    </div>
                  }
                  {isTPORole() &&
                    <div className="disclosure">
                      Licensee certifies and agrees that (i) the Licensee has reviewed
                      and recommends the project for approval by Fannie Mae; (ii)
                      all provisions of the Fannie Mae Selling Guide
                      (collectively, including all Announcements, the{" "}
                      <strong>“Selling Guide”</strong>) pertaining to the Full
                      Review of condo projects using CPM will apply to the subject
                      project and that Licensee is responsible for ensuring full
                      compliance with all applicable provisions; (iii) the
                      information and statements contained in this application are
                      true and correct; and (iv) to the best of Licensee’s
                      knowledge, there is nothing about the subject property that
                      would make the project ineligible or cause the project to be
                      ineligible for the Full Review process using CPM. Licensee
                      agrees that the questions included in CPM are provided to
                      assist the Licensee with the Full Review process, that CPM
                      Certifications are based solely on data that the Licensee
                      enters in CPM and that additional project eligibility
                      requirements may be included in the Selling Guide. If there
                      is any conflict between the project eligibility requirements
                      in CPM and those in the Selling Guide, the Selling Guide
                      provisions will govern. The Licensee must notify the CPM
                      Management team if it becomes aware of any information that
                      could impact the eligibility status reflected in CPM and
                      must provide such notification as soon as practicable but no
                      later than five days after becoming aware of such
                      information. In such instances, Licensee must confirm the
                      project’s approved status before selling a loan to Fannie
                      Mae that is secured by one of the project’s units. Fannie
                      Mae reserves the right to change a project’s eligibility
                      status designation after certification if information
                      acquired has an impact on a previously issued eligibility
                      determination.
                    </div>
                  }
                  <div className="user-information">
                    <div className="contact-phone">
                      Lender Contact Phone{" "}
                      <span style={{ color: "#BD2B2B" }}>*</span>
                    </div>
                    <div className="user-phone">{docId.lenderPhone}</div>
                    <div className="contact-email">
                      Lender Contact Email{" "}
                      <span style={{ color: "#BD2B2B" }}>*</span>
                    </div>
                    <div className="user-email">{docId.lenderEmail}</div>
                    <div className="contact-id">
                      User ID <span style={{ color: "#BD2B2B" }}>*</span>
                    </div>
                    <div className="user-id">{docId.createdBy}</div>
                    <div className="contact-lender">
                      Lender <span style={{ color: "#BD2B2B" }}>*</span>
                    </div>
                    <div className="user-lender">{docId.lenderId}</div>
                  </div>
                  <div
                    className="user-agreement-title"
                    style={{ marginTop: "20px" }}
                  >
                    User Agreement
                  </div>
                  <div className="user-agreement-subtitle">
                    Electronic Signature
                    <span style={{ color: "#BD2B2B" }}>*</span>
                  </div>
                  <div className="user-agreement-attribute">
                    Signed by: {docId.lenderUserName}
                  </div>
                </div>
                <div className="form-footer">
                  <div></div>
                  <div>
                    {values.phaseId === "proj" ? (
                      <>
                        <button
                          className="complete-cert-print-close-btn"
                          onClick={goToProject}
                        >
                          Close
                        </button>
                        <button
                          onClick={window.print}
                          className="complete-cert-print-btn"
                        >
                          <PrintIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#0C77BA",
                              paddingRight: "6px",
                            }}
                          />
                          Print
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="complete-cert-print-close-btn"
                          onClick={goToPhase}
                        >
                          Close
                        </button>
                        <button
                          onClick={window.print}
                          className="complete-cert-print-btn"
                        >
                          <PrintIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#0C77BA",
                              paddingRight: "6px",
                            }}
                          />
                          Print
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import * as React from 'react';
import PropTypes from 'prop-types';
import './TextFieldStyles.css';

const TextField = ({id, label, inputValue, changeHandler, textFieldWidth='170px', placeholder}) => {
    const [ value, setValue ] = React.useState('');

    React.useEffect(() => {
        setValue(inputValue);
    },[inputValue]);

    const onChangeHandler =(event) => {
        setValue(event.target.value);
        changeHandler(event);
    };

    return(
        <div className='textFieldContainer'>
            <div>
                <label htmlFor={id} className={'textFieldLabel'}>{label}</label>
            </div>
            <div className='textFieldWrapper'>
                <input
                    placeholder={placeholder}
                    onChange={(event) =>onChangeHandler(event)}
                    type='text'
                    value={value}
                    id={id}
                    style={{width: textFieldWidth}}
                    className={'textFieldInput'}
                />
            </div>
        </div>
    )
};

TextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    inputValue: PropTypes.string.isRequired,
    changeHandler: PropTypes.func.isRequired,
    textFieldWidth: PropTypes.string
}
export default TextField;
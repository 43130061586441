import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'react-bootstrap/Modal'
import { Button, Radio } from '@material-ui/core';
import FormStepper from '../FormStepper';
import axios from 'axios';
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";


export const Additional = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState('back');
  console.log(formData)
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [show, setShow] = useState(false);

  function validateDecimal8(value) {
    let error;
    if (value && !/^\d{0,6}(\.\d{1,2})?$/i.test(value)) {
      error = 'Invalid Dollar Amount. 6 numbers allowed before decimal and 2 after decimal';
    }
    return error;
  }

  function validateDollar(value) {
    let error;
    if (value && !/^[0-9]+(\.[0-9]{1,2})?$/i.test(value)) {
      error = 'Invalid Dollar Amount';
    }
    return error;
  }

  function validatePercent(value) {
    let error;
    if (value && !/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i.test(value)) {
      error = 'Invalid Percentage';
    }
    return error;
  }
  
  function validateDecimal6(value) {
    let error;
    if (value && !/^\d{0,4}(\.\d{1,2})?$/i.test(value)) {
      error = 'Invalid. 4 numbers allowed before decimal and 2 after decimal';
    }
    return error;
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          direction === 'back' ? prevStep() : nextStep();
        }}
      >
      {({ values, errors, touched }) => (
        <Form className="parent-form">
        <FormStepper step={step} />
        <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add Established PERS Request</div>
                <div className="small-title">For Condo Project: {values.projectId}</div>
              </div>
          
              <div className="field-lower-container">
                <div className="section-title">Section 5 of 9 - Additional Documents (optional)</div>
            <div className="section-title">1051 Questions</div>
            <div className="attribute-title">Name of Master Association</div>
            <Field 
                name="masterAssociationName" 
                as={TextField} 
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 200 }}
                variant="outlined"
                inputProps ={{
                  'aria-label': 'Master association name'
                }}
            />
            <div className="attribute-title">Exceptions</div>
            <Field 
                name="exceptions1051" 
                as={TextField} 
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 2000 }}
                variant="outlined"
                inputProps ={{
                  'aria-label': 'Exceptions'
                }}
            />
            <div className="attribute-title">Master Association Notes</div>
            <Field 
                name="masterAssociationNotes" 
                as={TextField} 
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 2000 }}
                variant="outlined"
                inputProps ={{
                  'aria-label': 'Master association notes'
                }}
            />

            <div className="section-title">Budget Questions</div>
            <div className="attribute-title">Income from Assessments	</div>
            <Field 
                name="incomeFromAssessments" 
                as={TextField}  
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 12 }}
                validate={validateDecimal8}
                variant="outlined"
                error={touched.incomeFromAssessments && errors.incomeFromAssessments}
                helperText={touched.incomeFromAssessments && errors.incomeFromAssessments}
                inputProps ={{
                  'aria-label': 'Income from assessments'
                }}
            />
            <div className="attribute-title">Reserves</div>
            <Field 
                name="reserves" 
                as={TextField}  
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 12 }}
                validate={validateDecimal8}
                variant="outlined"
                error={touched.reserves && errors.reserves}
                helperText={touched.reserves && errors.reserves}
                inputProps ={{
                  'aria-label': 'Resrves'
                }}
            />
            <div className="attribute-title">Percent of Reserves on Hand	</div>
            <Field 
                name="percentageOfReservesOnHand" 
                as={TextField} 
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 12 }}
                validate={validatePercent}
                variant="outlined"
                error={touched.percentageOfReservesOnHand && errors.percentageOfReservesOnHand}
                helperText={touched.percentageOfReservesOnHand && errors.percentageOfReservesOnHand}
                inputProps ={{
                  'aria-label': 'Percent of reserves on hand'
                }}
            />
            <div className="attribute-title">Exceptions</div>
            <Field 
                name="exceptionsBudget" 
                as={TextField} 
                className="attribute-input"
                size="small"
                fullWidth={true}
                inputProps={{ maxLength: 2000 }}
                variant="outlined"
                inputProps ={{
                  'aria-label': 'Exceptions Budget'
                }}
            />

            <div className="bottom-button-container">
            
            <Button
              type="submit"
              className="next-button"
              onClick={() => setDirection('forward')}
            >
                Next Section             
                <ArrowForwardIcon />
            </Button>
            <Button
              type="submit"
              className="previous-button"
              onClick={() => setDirection('back')}
            >
                <ArrowBackIcon />
              Previous Section    
            </Button>
          </div>
        </div>
      </div>
          <div className="right-cancel-container">
                <Button
                  className="cancel-pers-button"
                  onClick={handleShow}
                >
                  <CloseIcon style={{fill:"red"}}/>
                  Cancel PERS Request          
                </Button>
                <Modal show={show} className="my-modal"/*class="modal-dialog modal-dialog-centered"*/ onHide={handleClose}>
                  <Modal.Header className="the-modal-header" closeButton>
                    <Modal.Title className="the-modal-title">Cancel PERS Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="the-modal-body">All of your progress will be lost. Are you sure you want to cancel?</Modal.Body>
                  <Modal.Footer className="the-modal-footer">
                    <Button className="go-back-button" onClick={handleClose}>
                      Go Back
                    </Button>
                    <Button className="cancel-modal-button" 
                        href={`/`}>
                      Cancel PERS Request
                    </Button>
                  </Modal.Footer>
                </Modal>
            </div>
        </Form>
      )}
      </Formik>
    </>
  );
};

Additional.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};
import * as React from 'react';
import { Grid, Button } from '@material-ui/core';
import CustomDialog from '../Dialog/Dialog';
import TextField from '../SharedComponents/TextField/textField';
import CheckBox from '../SharedComponents/CheckBox/CheckBox';
import Select from '../SharedComponents/Select/Select';
import DatePicker from '../SharedComponents/DatePicker/DatePicker'
import './SearchFilterStyles.css';
import { filterReducer,initialState } from '../../redux/reducers/FilterReducer'
import {filterResults} from '../../redux/actions/filterActions'
import { useDispatch,useSelector } from 'react-redux'



const SearchFilter = ({ isOpen, handleClose, title, searchResults, handleFilteredData, filterStates }) => {

    const [filterResults, setFilterResults] = React.useState(null);
    // const [state, dispatch] = React.useReducer(filterReducer)
    const dispatch = useDispatch()
    const state = useSelector(filterData => filterData)
    console.log("....reducerstate",state);

    const handleFilterResult = (value) => {
        setFilterResults(value);
    };

    const handleDialogClose = () => {
        handleFilteredData(filterResults)
        handleClose(state)
    }
    return (
        <React.Fragment>
            <CustomDialog 
            isOpen={isOpen}     
            handleClose={()=>handleClose(state)} 
            title={title} 
            content={<DialogContent filterStates={filterStates} 
            searchResults={searchResults} 
            handleFilterResult={handleFilterResult} />} 
            actions={
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >

                    <Button onClick={handleDialogClose}
                     color="primary" variant="contained" size="large">
                        Show {filterResults && filterResults.length} Results
               </Button>
                </Grid>

            } />
        </React.Fragment>
    )
};

export const DialogContent = ({ searchResults, handleFilterResult, filterStates }) => {
    
    const dispatch = useDispatch()
    const state = useSelector(filterData => filterData)
    console.log("....reducerstate11dialogue",state);
    React.useEffect(() => {
        if (state.filterData.eligible) {

            if (state.filterData.city || state.filterData.states) {
                const filteredData = searchResults.filter((item) => item.city.toLowerCase().includes(state.filterData.city.toLowerCase())).filter((item) => item.state.toLowerCase().includes(state.filterData.states.toLowerCase()));
                handleFilterResult(filteredData)
            } else {
                handleFilterResult(searchResults)
            }


        } else if (!state.filterData.preEligible || state.filterData.preEligible) {
            handleFilterResult([])
        } else {

        }
    }, [searchResults, state.filterData])
    const onChangeHandler = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const id = event.target.id || event.target.name;
        dispatch(filterResults({...state.filterData,[id]:value}))

        // setState({ ...state, [id]: value });
    };
    const developerOptions = [{ key: '', value: 'Select Developer' }]
    const { preEligible, eligible, city, lender, states, projectDeveloper, 
        dateRange, inEligible } = state.filterData;
    return (
        <Grid container spacing={1}>
            <Grid item xs={5} sm={5} md={5}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={12} md={12}>

                        <label className='eligibilityStatus'>Eligibility Status</label>

                        <CheckBox name='preEligible' id='preEligible'
                         label='Pre Eligible' 
                         checked={preEligible} 
                         changeHandler={(value) => onChangeHandler(value)} />
                        <CheckBox name='eligible' id='eligible' label='Eligible' 
                        checked={eligible} 
                        changeHandler={(value) => onChangeHandler(value)} />
                        <CheckBox name='inEligible' id='inEligible' label='Ineligible' 
                        checked={inEligible}
                         changeHandler={(value) => onChangeHandler(value)} />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField id={'city'} label={'City'} placeholder='Enter City'
                         inputValue={city} 
                         changeHandler={(value) => onChangeHandler(value)} />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Select id={'states'} placeholder='states' name={'states'} label={'State'} 
                        selectWidth='170px' 
                        changeHandler={(value) => onChangeHandler(value)} 
                        options={filterStates} value={states} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField id={'lender'} label={'Lender'}
                         placeholder='Enter Lender' textFieldWidth='270px'
                          inputValue={lender} 
                          changeHandler={(value) => onChangeHandler(value)} />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Select id={'projectDeveloper'} 
                        name={'Project Developer'} 
                        placeholder= 'Project Developer'
                        label={'Project Developer'} selectWidth='270px' 
                        changeHandler={(value) => onChangeHandler(value)}
                         options={developerOptions} value={projectDeveloper} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <DatePicker id={"dateRange"} placeholder={'Select Date'} name={'dateRange'} 
                        datePickerWidth={'270px'} label={'Status Date Range'} 
                        changeHandler={(value) => onChangeHandler(value)} 
                        value={dateRange} datePickerWidth='270px' />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}
export default SearchFilter;

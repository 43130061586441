import React from "react";
import { Grid, Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-bootstrap/Modal";

const DeleteModal = ({ handleDeleteCancel, handleDeleteSubmit, showDelete, conditionName }) => {
  return (
    <Modal
      show={showDelete}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)"
      }}
      onHide={handleDeleteCancel}
    >
      <Modal.Header className="the-modal-header" closeButton>
        <Delete
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "25px",
            width: "25px"
          }}
        />
        <Modal.Title className="the-modal-title">
          Delete Added Condition
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
        {`You have choosen to delete "${conditionName}" from yous letter. this
        action cannot be undone.`}
        <div style={{ marginTop: "15px" }}>
          Are you sure you want to delete this condition?
        </div>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer">
        <Button className="go-back-button" onClick={handleDeleteCancel}>
          Cancel
        </Button>
        <Button className="delete-modal-button" onClick={handleDeleteSubmit}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;

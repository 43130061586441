import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import {
  isProjectRole,
  isLenderRole,
  isReadonlyRole
} from "../../redux/actions/userAction";

const SuccessPage = ({ msg, project_id, project_type, userRole }) => {
  console.log("user role infor");
  console.log(userRole);
  console.log(project_type)
  const toNewPersUri = `/add-new-pers/${project_id}`;
  const toEstablishedPersUri = `/add-established-pers/${project_id}`;
  const toProjectUri = `/project/${project_id}`;

  return (
    <Grid
      item
      xs={12}
      style={{
        minHeight: "calc(100vh - 133px)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%"
      }}
    >
      <CheckCircleOutlineOutlinedIcon
        style={{ color: "#5ACA49", fontSize: "64px" }}
      ></CheckCircleOutlineOutlinedIcon>
      <h2 className="mt-2">Success!</h2>
      <h4 className="mt-2">{msg}</h4>
      <div style={{
        display: "flex",
      }}
      >
        {(project_type === "New Condo" && isProjectRole()) ? (
          <Button
            autoFocus
            className="mt-5"
            style={{
              color: "white",
              backgroundColor: "#1176B5",
              borderRadius: "4px",
              boxShadow:
                "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
              height: "40px",
              width: "200px",
              textTransform: "capitalize",
              marginRight: "20px"
            }}
            onClick={() => {
              window.location.href = toNewPersUri;
            }}
          >
            Add PERS Request
          </Button>
        ) : (project_type === "Established Condo" && isProjectRole()) ? (
          <Button
            autoFocus
            className="mt-5"
            style={{
              color: "white",
              backgroundColor: "#1176B5",
              borderRadius: "4px",
              boxShadow:
                "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
              height: "40px",
              width: "200px",
              textTransform: "capitalize",
              marginRight: "20px"
            }}
            onClick={() => {
              window.location.href = toEstablishedPersUri;
            }}
          >
            Add PERS Request
          </Button>
        ) : ("")
        }
        <Button
          autoFocus
          className="mt-5"
          style={{
            color: "white",
            backgroundColor: "#1176B5",
            borderRadius: "4px",
            boxShadow:
              "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
            height: "40px",
            width: "200px",
            textTransform: "capitalize"
          }}
          onClick={() => {
            window.location.href = toProjectUri;
          }}
        >
          Go back to Project Page
        </Button>
      </div>
    </Grid>
  );
};

export default SuccessPage;

import { UNITS1026_RESULTS } from "../actions/ActionType";

export const initialState = {
  types: []
};
export const units1026Reducer = (type = initialState, action) => {
  switch (action.type) {
    case UNITS1026_RESULTS:
      return {
        units1026: action.payload
      };
    default:
      return type;
  }
};

import { PHASE_TYPE_RESULTS } from "../actions/ActionType";

export const initialState = {
  phaseTypes: []
};

export const addNewPhaseReducer = (phaseInfo = initialState, action) => {
  switch (action.type) {
    case PHASE_TYPE_RESULTS:
      phaseInfo = {
        ...phaseInfo,
        phaseTypes: action.payload
      };
      break;
    default:
  }
  return phaseInfo;
};

import {
  FILTER_RESULTS,
  STATE_RESULTS,
  STYLE_RESULTS,
  TYPE_RESULTS,
  TYPE1026_RESULTS,
  RIGHTS1026_RESULTS,
  STORIES1026_RESULTS,
  STRUCTURES1026_RESULTS,
  PHASES1026_RESULTS,
  CONSTRUCTIONS1026_RESULTS,
  CONVERSIONS1026_RESULTS,
  UNITS1026_RESULTS
} from "./ActionType";
import axios from "axios";

export const filterResults = result => ({
  type: FILTER_RESULTS,
  payload: result
});

export const statesService = result => ({
  type: STATE_RESULTS,
  payload: result
});

export const types1026Service = result => ({
  type: TYPE1026_RESULTS,
  payload: result
});

export const rights1026Service = result => ({
  type: RIGHTS1026_RESULTS,
  payload: result
});

export const stories1026Service = result => ({
  type: STORIES1026_RESULTS,
  payload: result
});

export const structures1026Service = result => ({
  type: STRUCTURES1026_RESULTS,
  payload: result
});

export const phases1026Service = result => ({
  type: PHASES1026_RESULTS,
  payload: result
});

export const constructions1026Service = result => ({
  type: CONSTRUCTIONS1026_RESULTS,
  payload: result
});

export const conversions1026Service = result => ({
  type: CONVERSIONS1026_RESULTS,
  payload: result
});

export const units1026Service = result => ({
  type: UNITS1026_RESULTS,
  payload: result
});

export const stylesService = result => ({
  type: STYLE_RESULTS,
  payload: result
});

export const typeService = result => ({
  type: TYPE_RESULTS,
  payload: result
});



export function fetchStates() {
  return function(dispatch) {
    setTimeout(() => {

      return axios
        .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
        .then(response => {
          const statesResponse = response.data;
          const states = [];
          for (const state in statesResponse) {
            states.push({
              key: statesResponse[state],
              value: statesResponse[state]
            });
          }
          states.unshift({ key: "", value: "Select State" });
          dispatch(statesService(states));
        })

        .catch(error => {
          throw Error(error);
        });
    }, 100);
  };
}

export function fetchType1026() {
  return function(dispatch) {
    

    return axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PROJECT_TYPE_1026`)
      .then(response => {
        const types1026Response = response.data;
        const types1026 = [];
        for (const type1026 in types1026Response) {
          types1026.push({
            key: types1026Response[type1026],
            value: types1026Response[type1026]
          });
        }
        types1026.unshift({ key: "", value: "Select Project Type" });
        dispatch(types1026Service(types1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchRights1026() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PROPERTY_RIGHTS_1026`
      )
      .then(response => {
        const rights1026Response = response.data;
        const rights1026 = [];
        for (const right1026 in rights1026Response) {
          rights1026.push({
            key: rights1026Response[right1026],
            value: rights1026Response[right1026]
          });
        }
        rights1026.unshift({ key: "", value: "Select Project Rights" });
        dispatch(rights1026Service(rights1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchStories1026() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PROJECT_STORIES_1026`
      )
      .then(response => {
        const stories1026Response = response.data;
        const stories1026 = [];
        for (const story1026 in stories1026Response) {
          stories1026.push({
            key: stories1026Response[story1026],
            value: stories1026Response[story1026]
          });
        }
        stories1026.unshift({ key: "", value: "Select Project Stories" });
        dispatch(stories1026Service(stories1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchStructures1026() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/PROJECT_STRUCTURE_TYPE_1026`
      )
      .then(response => {
        const structures1026Response = response.data;
        const structures1026 = [];
        for (const structure1026 in structures1026Response) {
          structures1026.push({
            key: structures1026Response[structure1026],
            value: structures1026Response[structure1026]
          });
        }
        structures1026.unshift({
          key: "",
          value: "Select Project Structure"
        });
        dispatch(structures1026Service(structures1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchPhases1026() {
  return function(dispatch) {
   

    return axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PHASE_TYPES_1026`)
      .then(response => {
        const phases1026Response = response.data;
        const phases1026 = [];
        for (const phase1026 in phases1026Response) {
          phases1026.push({
            key: phases1026Response[phase1026],
            value: phases1026Response[phase1026]
          });
        }
        phases1026.unshift({ key: "", value: "Select Phase Type" });
        dispatch(phases1026Service(phases1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchConstructions1026() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/CONSTRUCTION_STATUS_1026`
      )
      .then(response => {
        const constructions1026Response = response.data;
        const constructions1026 = [];
        for (const construction1026 in constructions1026Response) {
          constructions1026.push({
            key: constructions1026Response[construction1026],
            value: constructions1026Response[construction1026]
          });
        }
        constructions1026.unshift({
          key: "",
          value: "Select Construction Status"
        });
        dispatch(constructions1026Service(constructions1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchConversions1026() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/CONVERSION_REHAB_TYPE_1026`
      )
      .then(response => {
        const conversions1026Response = response.data;
        const conversions1026 = [];
        for (const conversion1026 in conversions1026Response) {
          conversions1026.push({
            key: conversions1026Response[conversion1026],
            value: conversions1026Response[conversion1026]
          });
        }
        conversions1026.unshift({ key: "", value: "Select Phase Type" });
        dispatch(conversions1026Service(conversions1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchUnits1026() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/COMMERCIAL_UNITS_RETENT_PLAN_1026`
      )
      .then(response => {
        const units1026Response = response.data;
        const units1026 = [];
        for (const unit1026 in units1026Response) {
          units1026.push({
            key: units1026Response[unit1026],
            value: units1026Response[unit1026]
          });
        }
        units1026.unshift({ key: "", value: "Select Phase Type" });
        dispatch(units1026Service(units1026));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchStyles() {
  return function(dispatch) {

    return axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/BUILDING-TYPES`)
      .then(response => {
        const stylesResponse = response.data;
        const styles = [];
        for (const style in stylesResponse) {
          styles.push({
            key: stylesResponse[style],
            value: stylesResponse[style]
          });
        }
        styles.unshift({ key: "", value: "Select Style" });
        dispatch(stylesService(styles));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchProjectTypes() {
  return function(dispatch) {

    return axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/NEW_PROJECT_TYPE`)
      .then(response => {
        const typesResponse = response.data;
        const types = [];

        for (const type in typesResponse) {
          types.push({
            key: typesResponse[type],
            value: typesResponse[type]
          });
        }
        console.log("type response", types);
        types.unshift({ key: "", value: "Select New Project Type" });
        dispatch(typeService(types));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

import React, { Component } from "react";
import axios from "axios";
import "./BannerHistory.css";
import { Nav, TabContainer, TabContent, TabPane } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import BannerList from "./BannerList/BannerList";

class BannerHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pastBanners: [],
      activeBanners: [],
    };
  }

  async componentDidMount() {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    try {
      const { data: banners } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/banner/getAllBanners`
      );
      this.setState({
        pastBanners: banners,
      });
    } catch (err) {
      console.log(err);
    }

    try {
      const { data: latestBanners } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/banner/getLatestBanner`
      );
      this.setState({
        activeBanners: latestBanners,
      });
    } catch (err) {
      console.log(err);
    }
  }

  getPastBanners() {
    let list = <h1>Loading...</h1>;
    if (this.state.pastBanners) {
      list = <BannerList list={this.state.pastBanners} />;
    } else {
      list = <h1>No Past banners to display</h1>;
    }
    return list;
  }

  getLatestBanners() {
    let list = <h1>Loading...</h1>;
    if (this.state.activeBanners) {
      list = <BannerList list={this.state.activeBanners} activeBanners={true} />;
    } else {
      list = <h1>No lastest banners to display</h1>;
    }
    return list;
  }

  addBanner() {
    window.location.href = `/banner/${"new"}`;
  }

  render() {
    return (
      <div>
        <div className="banners-list-title">Banner List</div>
        <div>
          <TabContainer id="banner-list-tab" defaultActiveKey="first">
            <Nav variant="pills" className="banner-list-nav">
              <Nav.Item>
                <Nav.Link className="banner-list-link" eventKey="first">
                  Active Banners
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="banner-list-link" eventKey="second">
                  Past Banner
                </Nav.Link>
              </Nav.Item>
              <div className="ml-auto">
                <div className="filter-container">
                  <div className="filter-button">
                    <button
                      className="add-banner"
                      onClick={() => this.addBanner()}
                    >
                      {" "}
                      Add New Banner
                    </button>
                  </div>
                </div>
              </div>
            </Nav>
            <TabContent className="banners-content">
              <TabPane eventKey="first">{this.getLatestBanners()}</TabPane>
              <TabPane eventKey="second">{this.getPastBanners()}</TabPane>
            </TabContent>
          </TabContainer>
        </div>
      </div>
    );
  }
}
export default BannerHistory;

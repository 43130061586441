import * as React from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import "./AddNewProject.css";
import {
  fetchStates,
  fetchStyles,
  fetchProjectTypes
} from "../../redux/actions/filterActions";

// Pages
import CondoProjectPage from "./Pages/CondoProjectPage";
import VerticalLinearStepper from "../VerticalLinearStepper/VerticalLinearStepper";
import PERSProjectPage from "./Pages/PERSProjectPage";
import SuccessPage from "../SuccessPage/SuccessPage";
import {
  fetchSubmissionTypes,
  initiateAddNewProject
} from "../../redux/actions/addProjectActions";
import Dropzone from "../Dropzone/Dropzone";
import {
  isProjectRole
} from "../../redux/actions/userAction";
// import './Dropzone.css';

const AddNewProject = () => {
  window.onscroll = function() {
    effects();
  };

  function effects() {}
  const [stepCount, setStepCount] = React.useState(0);

  const incrementCounter = () => setStepCount(stepCount + 1);
  const decrementCounter = () => setStepCount(stepCount - 1);

  const incrementCounter2 = () => setStepCount(stepCount + 2);
  

  const stateData = useSelector(store => store.addProjectReducer.projectId);

  const projectType = useSelector(
    store => store.addProjectReducer.condoProjectInfo.type
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    setStepCount(0);
    dispatch(initiateAddNewProject());
    dispatch(fetchStates());
    dispatch(fetchStyles());
    dispatch(fetchSubmissionTypes());
    dispatch(fetchProjectTypes());
  }, []);

  const userRole = sessionStorage.getItem('role');

  const getPage = () => {
    // switch (1) {
    switch (stepCount) {
      case 0:
        return (
          <CondoProjectPage
            incStepCounter={incrementCounter}
            incrementCounter2={incrementCounter2}
            decStepCounter={decrementCounter}
            userRole={userRole}
          />
        );
      // case 1:
      //   return (
      //     <Grid item xs={12}>
      //       <Grid item xs={12}>
      //         <h1 style={{ justifyContent: "center" }}>Add New Project</h1>
      //       </Grid>
      //       <Grid item xs={12}>
      //         <h6 style={{ justifyContent: "center" }}>
      //           Section 2 of 3 - Attachments
      //         </h6>
      //       </Grid>
      //       <Grid item xs={6}>
      //         <Dropzone
      //           projectvalue={stateData}
      //           persvalue={null}
      //           phasevalue={null}
      //           incStepCounter={incrementCounter}
      //           decStepCounter={decrementCounter}
      //           isAttch={true}
      //         />
      //       </Grid>
      //     </Grid>
      //   );
      case 2:
        if (stateData) {
          return (
            <SuccessPage
              project_id={stateData}
              project_type={projectType}
              userRole={userRole}
              msg={`A new project ${stateData} has been added!`}
            />
          );
        } else {
          return "Empty name";
        }
    }
  };

  return (
    <Grid container>
      <Grid item xs={3} style={{ backgroundColor: "#F2F2F2" }}>
      {isProjectRole() ? (<VerticalLinearStepper
          steps={[
            "Add New Project Details",
            // "Attachments",
            "Final Confirmation"
          ]}
          activeStep={stepCount}
        />) : (<VerticalLinearStepper
          steps={[
            "Add New Project Details",
            "Final Confirmation"
          ]}
          activeStep={stepCount}
        />)
        }
        
      </Grid>
      <Grid
        container
        item
        xs={9}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ padding: "20px", border: "solid 1px black" }}
      >
        {getPage()}
      </Grid>
    </Grid>
  );
};

export default AddNewProject;

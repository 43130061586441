import React from "react";
import { Grid, Button } from "@material-ui/core";;
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

const DuplicateSuccessPage = ({formData}) => {
   const toProjectUri = `/project/${formData.currentProject}`;
   const adminUri = `/admin`;
  return (
    <Grid
      item
      xs={12}
      style={{
        minHeight: "calc(100vh - 133px)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%"
      }}
    >
      <CheckCircleOutlineOutlinedIcon
        style={{ color: "#5ACA49", fontSize: "64px" }}
      ></CheckCircleOutlineOutlinedIcon>
      <h2 className="mt-2">Success!</h2>
      <h4 className="mt-2">Duplicate project has been successfully delete.</h4>
      <div style={{
        display: "flex",
      }}
      >
        <Button
            autoFocus
            className="mt-5"
            style={{
              color: "white",
              backgroundColor: "#1176B5",
              borderRadius: "4px",
              boxShadow:
                "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
              height: "40px",
              width: "200px",
              textTransform: "capitalize",
              marginRight: "20px"
            }}
            onClick={() => {
              window.location.href = toProjectUri;
            }}
          >
            Go back to Project Page
          </Button>
        <Button
          autoFocus
          className="mt-5"
          style={{
            color: "white",
            backgroundColor: "#1176B5",
            borderRadius: "4px",
            boxShadow:
              "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
            height: "40px",
            width: "200px",
            textTransform: "capitalize"
          }}
          onClick={() => {
            window.location.href = adminUri;
          }}
        >
          Admin
        </Button>
      </div>
    </Grid>
  );
};

export default DuplicateSuccessPage;

import React, { useState } from "react";
import "./FmActivityFeed.css";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { GrClose } from "react-icons/gr";
import { FaPen, FaRegSave } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { isProjectStandardRole , isInternal } from "../../redux/actions/userAction";
import axios from "axios";
import SuccessIcon from "../../images/icons/Success@1x.svg";
import WarningIcon from "../../images/icons/Warning@1x.svg";
import TimerIcon from "../../images/icons/timer@1x.svg";
import FeedbackIcon from "../../images/icons/feedback-remove@1x.svg";
import Warning1Icon from "../../images/icons/Warning-1@1x.svg";
import Success1Icon from "../../images/icons/Success-1@1x.svg";
import ReactSelect from "react-select";
import ReasonCodeTooltip from "../SharedComponents/ReasonCodeTooltip/ReasonCodeTooltip";

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);

const FmActivityFeed = ({ ele, index , reasonCodes}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [reason, setReason] = useState("");
  const [codes, setCodes] = useState([]);
  const [show, setShow] = useState(false);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [item, setItem] = useState({});

  const handleEdit = () => {
    setReason(item.reasonForChange);
    setReasonIds(item.reasonIds);
    setIsEdit(true);
  };

  const setReasonIds = (reasonIds) => {
    let newReasonCodes = [];
    if (reasonIds && reasonIds.length > 0 && reasonOptions) {
      reasonIds.map(id =>  {
        const code = reasonOptions.find(reason => reason.value === id);
        if (code) {
          newReasonCodes.push(code);
        }
      });
    }
    newReasonCodes = newReasonCodes.sort((a, b) => a.severity - b.severity);
    setCodes(newReasonCodes);
  }

  const handleSave = () => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let userId = passData.user;
    setIsEdit(false);
    const reasoncodesList = [];
    if (codes && codes.length > 0) {
      codes.map(c => {
        reasoncodesList.push( {
              reasonId:c.value,
              createdBy:userId,
              updatedBy: userId
        })
      })
    }
    axios({
      method: "POST",
      url: global.REACT_APP_API_ENDPOINT + "/activityFeed/saveActivityFeedReasonCodes",
      headers: { "Access-Control-Allow-Origin": "*" },
      data: {
          feedId: ele.feedId,
          notes: reason,
          reasonCodesList: reasoncodesList,
          updatedBy:userId,
          fmReviewStatus: ele.fnmaReviewStatus
      },
    })
      .then((response) => {
        const data = ele;
        setReason(response.data.notes);
        setIsEdit(false);
        if (response.data.reasonCodesList.length > 0) {
          const reasonIds = response.data.reasonCodesList.map(r => r.reasonId)
          data.reasonIds = reasonIds;
          setReasonIds(reasonIds)
        }
        data.reasonForChange = response.data.notes;
        setItem(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReasonCodeVerbiage = (reasonIds) => {
    if (reasonIds && reasonIds.length > 0 && reasonCodes && reasonCodes.length > 0) {
      let sortCodes = [];
      reasonIds.map(id => {
        const code = reasonCodes.find(reason => reason.reasonId === id);
        sortCodes.push(code);
      });
      sortCodes = sortCodes.sort((a, b) => a.severity - b.severity);
      return (
        <ul>
          {sortCodes && sortCodes.length > 0 ? (
            sortCodes.map((code) => (
              <li>{code.reasonDefinitionInternal}</li>
            ))
          ) : <></>}
        </ul>
      );
    } else {
      return (<></>);
    }

  };

  React.useEffect(() => {
    const options = [];
    if (reasonCodes) {
      reasonCodes.map(r => {
        options.push({ 'label': r.reasonCode, 'value': r.reasonId , 'severity': r.severity})
      })
      setReasonOptions(options);
    }
    if (ele) {
      setReason(ele.reasonForChange);
    }
    setItem(ele);
  }, [ele, reasonCodes]);

  return (
    <div
      key={index}
      className="feed-card"
      style={{
        paddingTop: "20px",
        height: "max-content",
      }}
    >
      <div style={{ display: "flex", flex: "0" }} className="feed-col-card">
        {item.fnmaReviewStatus === "Approved By Fannie Mae" ? (
          <>
            <CustomTooltip title="Approved By Fannie Mae" placement="top">
              <img
                alt="Success Check Icon"
                style={{
                  marginTop: "10px",
                  marginRight: "27px",
                  height: "24px",
                }}
                src={SuccessIcon}
              />
            </CustomTooltip>
          </>
        ) : item.fnmaReviewStatus === "Conditional Approval" ? (
          <>
            <CustomTooltip title="Conditional Approval" placement="top">
              <img
                alt="Warning Icon"
                style={{
                  marginTop: "10px",
                  marginRight: "27px",
                  height: "24px",
                }}
                src={WarningIcon}
              />
            </CustomTooltip>
          </>
        ) : item.fnmaReviewStatus === "Pending PERS Review" ? (
          <>
            <CustomTooltip title="Pending PERS Review" placement="top">
              <img
                alt="Timer Icon"
                style={{
                  marginTop: "10px",
                  marginRight: "27px",
                  height: "24px",
                }}
                src={TimerIcon}
              />
            </CustomTooltip>
          </>
        ) : item.fnmaReviewStatus === "Unavailable" ? (
          <>
            <CustomTooltip title="Unavailable" placement="top">
              <img
                alt="Removal Icon"
                style={{
                  marginTop: "10px",
                  marginRight: "27px",
                  height: "24px",
                }}
                src={FeedbackIcon}
              />
            </CustomTooltip>
          </>
        ) : item.fnmaReviewStatus === "Fannie Mae Approval Expired" ? (
          <>
            <CustomTooltip title="Fannie Mae Approval Expired" placement="top">
              <img
                alt="Alternate Success Check Icon"
                style={{
                  marginTop: "10px",
                  marginRight: "27px",
                  height: "24px",
                }}
                src={Success1Icon}
              />
            </CustomTooltip>
          </>
        ) : item.fnmaReviewStatus ===
          "Fannie Mae Conditional Approval Expired" ? (
          <>
            <CustomTooltip
              title="Fannie Mae Conditional Approval Expired"
              placement="top"
            >
              <img
                alt="Alternate Warning Icon"
                style={{
                  marginTop: "10px",
                  marginRight: "27px",
                  height: "24px",
                }}
                src={Warning1Icon}
              />
            </CustomTooltip>
          </>
        ) : (
          <span
            style={{
              marginTop: "10px",
              marginRight: "48px",
            }}
          ></span>
        )}
      </div>
      <div
        className="card-project-name-pers"
        style={{
          display: "flex",
          flex: "0.3",
          flexDirection: "column",
        }}
      >
        <span style={{ color: "#605B57", fontSize: "16px" }}>
          Status Changed To
        </span>
        <span style={{ fontSize: "20px", fontWeight: "600" }}>
          {item.fnmaReviewStatus}
        </span>
      </div>
      <div
        className="card-project-name-pers"
        style={{
          display: "flex",
          flex: "0.2",
          flexDirection: "column",
        }}
      >
        <span style={{ color: "#605B57", fontSize: "16px" }}>Updated</span>
        <span style={{ color: "#121212", fontSize: "18px" }}>
          {item.updatedDt}
        </span>
      </div>
      <div
        className="card-project-name-pers"
        style={{ display: "flex", flex: "1", flexDirection: "column" }}
      >
        <span style={{ color: "#605B57", fontSize: "16px" }}>
          Reason For Change
          {
            item.fnmaReviewStatus === 'Unavailable' ?
            <><ReasonCodeTooltip reasonCodes={reasonCodes}/></> :
            <> {item.hasOwnProperty("persDecisionDetails") && item.persDecisionDetails.decision === 'Conditional/Unavailable' ? <><ReasonCodeTooltip reasonCodes={reasonCodes}/></> : <></>}</>
          }
        </span>
        {isEdit && isInternal() ?
          <>
            {item.fnmaReviewStatus === 'Unavailable' ?
              <>
                <ReactSelect
                  name="reasonCodes"
                  className="pers-reason-codes"
                  options={reasonOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={
                    codes
                  }
                  onChange={(values) => setCodes(values)}
                  allowSelectAll={false}
                />
              </> :
              <>
                {
                  item.hasOwnProperty("persDecisionDetails") && item.persDecisionDetails.decision === 'Conditional/Unavailable' ?
                    <>
                      <ReactSelect
                        name="reasonCodes"
                        className="pers-reason-codes"
                        options={reasonOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        value={
                          codes
                        }
                        onChange={(values) => setCodes(values)}
                        allowSelectAll={false}
                      />
                    </> :
                    <>
                      <div>
                        <GrClose
                          className="position-absolute individual-close"
                          onClick={() => setIsEdit(false)}
                        />
                        <Form.Control
                          as="textarea"
                          value={reason}
                          name="reasonForChange"
                          rows={3}
                          onChange={(e) => setReason(e.target.value)}
                        />
                      </div>
                    </>
                }
              </>
            }
          </> : (
            <div style={{ color: "#121212", fontSize: "18px" }}>
              {
               item.fnmaReviewStatus === 'Unavailable' ?
                <>
                  {getReasonCodeVerbiage(item.reasonIds)}
                </> :
                <>{reason}{" "}</>
              }
            </div>
          )}
      </div>
      { isInternal() ? (
        <>
          {isEdit ? (
            <div>
              <button className="feed-button" onClick={handleSave}>
                <FaRegSave className="feed-icon" />
                Save
              </button>
            </div>
          ) : (
            <button className="feed-button" onClick={handleEdit}>
              <FaPen className="feed-icon" />
              Edit
            </button>
          )}
        </>
      ) : null}
    </div>
  );
};

export default FmActivityFeed;

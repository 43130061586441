import * as React from "react";
import PropTypes from "prop-types";
import "./CheckBoxStyles.css";

const CheckBox = ({ id, name, checked, label, disabled, changeHandler }) => {
  const [checkBoxValue, setCheckBoxValue] = React.useState(false);
  React.useEffect(() => {
    setCheckBoxValue(checked);
  }, [checked]);

  const checckBoxChangeHandler = event => {
    setCheckBoxValue(event.target.checked);
    changeHandler(event);
  };
  return (
    <div className="checkboxContainer">
      <div>
        <input
          type="checkbox"
          className={"checkboxfield"}
          id={id}
          name={name}
          checked={checkBoxValue}
          onChange={event => checckBoxChangeHandler(event)}
          disabled={disabled}
        />
      </div>
      <div>
        <label className={"checkboxLabel"} htmlFor={id}>
          {" "}
          {label}
        </label>
      </div>
    </div>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired
};

export default CheckBox;

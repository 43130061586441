import React, { useState, useEffect } from "react";
import axios from "axios";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import PhaseList from "./PhaseList/PhaseList";
import { isProjectRole, isLenderRole, isTPORole } from "../../redux/actions/userAction";
import LayersBlackIcon from "../../images/layers_black_24dp@1x.svg";

const SearchPhase = ({ projectId, pageStatus, totalPhases }) => {
  const [phase, setPhase] = useState();
  const [status, setStatus] = useState(false);
  const [currentProjectId, setProjectId] = useState();
  function getPhaseData(e) {
    let id = projectId;
    if (isProjectRole()) {
      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/condoMgr/getProjectDetailsByProjectId/` +
            id
        )
        .then((response) => {
          if (response.data === `Cannot find project:${id}`) {
            console.warn(response.data);
            return;
          }
          setPhase(response.data.phaseDataList);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (isLenderRole() || isTPORole()) {
      const passData = JSON.parse(sessionStorage.getItem("passData"));
      const lenderId =
        sessionStorage.getItem("lenderId") ||
        passData.sellerServicerCoNo ||
        "12346";
      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/lender/lenderViewProject/` +
            id +
            "/" +
            lenderId
        )
        .then((response) => {
          setPhase(response.data.phaseDataList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    setStatus(pageStatus);
    if (currentProjectId !== projectId) {
      setProjectId(projectId);
    }
  }, [projectId]);

  return (
    <>
      {totalPhases !== 0 ? (
        <Accordion defaultActiveKey="0" style={{ margin: "0px 56px" }}>
          <Card className="phase-header-card">
            <Card.Header
              className="accordion-header-space"
              onClick={getPhaseData}
              style={{ backgroundColor: "#fff" }}
            >
              <ContextAwareToggle eventKey={projectId}>
                <p className="accordion-header-title">
                  <img
                    alt="Phase Logo"
                    style={{
                      height: "16px",
                      width: "16px",
                      margin: "0px 5px 2px 5px",
                    }}
                    src={LayersBlackIcon}
                  />
                  {totalPhases > 1 ? (
                    <>{totalPhases} Phases</>
                  ) : (
                    <>{totalPhases} Phase</>
                  )}
                </p>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={projectId} id={projectId}>
              <Card.Body className="phase-body-card">
                <div>
                  <PhaseList list={phase} />
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : (
        ""
      )}
    </>
  );
};

export default SearchPhase;

import React from "react";
import { Grid, Button } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const CancelModal = ({ handleCancelCancel, showCancel, link, isLogo, leaveLose }) => {
  return (
    <Modal
      show={showCancel}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)"
      }}
      onHide={handleCancelCancel}
      centered
      backdrop="static"
    >
      <Modal.Header className="the-modal-header" closeButton>
        <FaInfoCircle
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "15px",
            width: "15px"
          }}
        />
        <Modal.Title className="the-modal-title">
          Leave this page
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
        <div style={{ marginTop: "15px" }}>
          All progress within this form will be lost. Are you sure you want to
          leave?
        </div>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer" style={{justifyContent:"space-between"}}>
        <Button className="go-back-button" style={{marginLeft:"15px"}} onClick={handleCancelCancel}>
          Stay on this page
        </Button>

        {isLogo ? (
          <Button
            className="delete-modal-button"
            onClick={() => {
              window.location.href = link;
              leaveLose();
            }}
          >
            Leave and lose progress
          </Button>
        ) : (
          <Link to={link} style={{ margin: "16px" }} replace>
            <Button
              className="delete-modal-button"
              onClick={leaveLose}
            >
              Leave and lose progress
            </Button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;

import React from "react";
import { Button } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

const WarningModal = ({
  handleWarningModalCancel,
  show,
  duplicateProjectData,
  title,
}) => {

  return (
    <Modal
      show={show}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleWarningModalCancel}
      backdrop="static"
      size="lg"
      centered
    >
      <Modal.Header className="the-modal-header" closeButton>
        <FaExclamationTriangle
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "16px",
            width: "16px",
            marginTop:"3px",
            marginLeft:"6px"
          }}
        />
        <Modal.Title className="the-modal-title" style={{alignItems:"center"}}>{title ? title: 'Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
          <>
        <div style={{ marginTop: "15px" }}>
         This entry may be a duplicate of the following record(s) in the system. Before saving your new project, please check the records below to avoid duplication. 
        </div>
        <div style={{ marginTop:"14px", overflow:"auto", height:"100px"}}>
            {
                duplicateProjectData.map(projectData => {
                    const {projectId, state, totalUnits, zipcode, city, projectName} = projectData;
                    return <div key={projectId}>
                       <Link to={`/project/${projectId}`} style={{color:"#1176B5"}} target="_blank">{projectName}</Link>, {totalUnits} Total Units, {city}, {state}, {zipcode}
                    </div>
                }) 
            }
        </div>
        </>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer" style={{justifyContent:"space-between", paddingRight:24}}>
          <div></div>
      <Button className='neutral-modal-button' onClick={() => { handleWarningModalCancel() }}> Done </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;

import React from "react";
import Select from "../SharedComponents/Select/Select";

const Field = ({
  label,
  name,
  formData,
  isEdit,
  handleChange,
  dropdown,
  number,
  states
}) => {
  return (
    <div className="accordion-body-card-attributes">
      <div className="accordion-body-card-attributes-name">{label}</div>
      <div className="accordion-body-card-attributes-semicolon">:</div>
      <div className="accordion-body-card-attributes-value">
        {isEdit ? (
          dropdown ? (
            <Select
              id={"states"}
              placeholder="states"
              name={"states"}
              label={""}
              selectWidth="170px"
              changeHandler={handleChange}
              options={states}
              value={formData[name]}
            />
          ) : number ? (
            <input
              required
              type="number"
              className="form-control"
              name={name}
              value={formData[name]}
              onChange={handleChange}
            />
          ) : (
            <input
              required
              type="text"
              className="form-control"
              name={name}
              value={formData[name]}
              onChange={handleChange}
            />
          )
        ) : (
            formData[name] === true || formData[name] === "true" ? (
              <div>Yes</div>
            ) : 
            formData[name] === false || formData[name] === "false" ? (
              <div>No</div>
            ) :
            (
              formData[name]
            )
        )}
      </div>
    </div>
  );
};

export default Field;
import axios from "axios";
import { PHASE_TYPE_RESULTS } from "./ActionType";

export const setTypeService = types => {
  return {
    type: PHASE_TYPE_RESULTS,
    payload: types
  };
};

export function fetchPhaseTypes() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PHASE_TYPE`
      )
      .then(response => {
        const typesResponse = response.data;
        const types = [];
        for (const state in typesResponse) {
            types.push({
            key: typesResponse[state],
            value: typesResponse[state]
          });
        }
        //types.unshift({ key: "", value: "Select a Phase Type" });
        dispatch(setTypeService(types));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

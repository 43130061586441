import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import CriticalIcon from "../../../images/Critical@2x.svg";
import { FinancialsSpecialAssessmentsCriticalRepairs } from "../../shared/components/PrintCertificationForms/FinancialsSpecialAssessmentsCriticalRepairs";
import { GuideEligibility } from "../../shared/components/PrintCertificationForms/GuideEligibility";
import { LitigationUsageAndInsurance } from "../../shared/components/PrintCertificationForms/LitigationUsageAndInsurance";
import { NewProjectEligibility } from "../../shared/components/PrintCertificationForms/NewProjectEligibility";
import { PhasePresaleAndInvestorConcentration } from "../../shared/components/PrintCertificationForms/PhasePresaleAndInvestorConcentration";
import { ProjectInformation } from "../../shared/components/PrintCertificationForms/ProjectInformation";
import { ProjectOwnership } from "../../shared/components/PrintCertificationForms/ProjectOwnership";
import { ProjectPresaleAndInvestorConcentration } from "../../shared/components/PrintCertificationForms/ProjectPresaleAndInvestorConcentration";
import { fspAssmtsRuleCheck } from "../../shared/services/SharedService"
export const Page11 = ({
  formData,
  setFormData,
  streetInfo,
  docId,
  step,
  convResult,
  year1,
  year2,
  year3,
  skipStep4,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function goToProject() {
    window.location.href = `/project/${formData.projectId}`;
  }

  function goToPhase() {
    window.location.href = `/project/phase/${formData.phaseId}`;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
            (convResult >= 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={7} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={8} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits &&
                values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={8} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={9} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              {typeof docId.guideEligibilityAndProjectInfoSt !== "undefined" ? (
                <>
                  {docId.certificationStatus === "Certified by Lender" ? (
                    <div
                      className="decline-info"
                      style={{ border: "1px solid #098620" }}
                    >
                      <div
                        className="decline-info-symbol"
                        style={{ backgroundColor: "#098620" }}
                      >
                        <CheckIcon
                          style={{
                            color: "#FFFFFF",
                            width: "30px",
                            height: "30px",
                            margin: "5px 0px",
                          }}
                        />
                      </div>
                      <div className="decline-info-text">
                        {docId.certificationStatus}
                      </div>
                    </div>
                  ) : (
                    <div className="decline-info">
                      <div className="decline-info-symbol">
                        <CloseIcon
                          style={{
                            color: "#FFFFFF",
                            width: "30px",
                            height: "30px",
                            margin: "5px 0px",
                          }}
                        />
                      </div>
                      <div className="decline-info-text">
                        {docId.certificationStatus}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className="info" style={{ margin: "0px" }}>
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    <div className="type">Certification ID</div>
                    <div className="attributes">
                      {docId.certificationId} 
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "0px", paddingTop: "0px" }}
                  >
                    <div className="status-type">Status</div>
                    {typeof docId.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                      <>
                        {docId.certificationStatus === "Certified by Lender" ? (
                          <>
                            <div
                              className="status-attributes"
                              style={{ color: "#098620", fontWeight: "bold" }}
                            >
                              {docId.certificationStatus}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="status-attributes"
                              style={{ color: "#D0021B", fontWeight: "bold" }}
                            >
                              {docId.certificationStatus}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    <div className="type">Expires</div>
                    <div className="attributes">
                      {docId.dateExpiry ? docId.dateExpiry : "NA"}
                    </div>{" "}
                  </div>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    <div className="type">Project Type</div>
                    <div className="attributes">
                      {docId.certProjectType ? docId.certProjectType : "NA"}
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  <div className="title">Results</div>
                </div>
                <div className="form-body">
                  {docId.delvyRestrictions ? (
                    <div className="certification-result-restriction-container">
                      <div className="restriction-title">
                        <img
                          alt="Critical Warning Logo"
                          style={{ paddingRight: "8px", paddingBottom: "3px" }}
                          src={CriticalIcon}
                        />
                        Delivery Restrictions
                      </div>
                      <div className="restriction-attribute">
                        <img
                          alt="Apartment Logo"
                          style={{
                            marginRight: "8px",
                            marginTop: "3px",
                            width: "17px",
                            height: "17px",
                          }}
                          src={ApartmentIcon}
                        />
                        {docId.delvyRestrictions}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Accordion defaultActiveKey="1" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {typeof docId.guideEligibilityAndProjectInfoSt !==
                          "undefined" ? (
                            <>
                              {(docId.guideEligibilityAndProjectInfoSt
                                .geHotelMotelResortIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geTimeshareCountSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geHouseBoatIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geMulLivingSpacesIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geSplitOwnershipIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geNIncIncomeAbovePmtdIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .gePerTotIncNonIncIncomeSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geUnpaidCommonExpAsmtsIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geCareFacilityIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .gePayMemshpOrLeaseIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .getCmplnHOAOwnshpIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geEnvHazardIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .geRtFtRefslOrReSlRstnsIndSt === "Fail" ||
                                docId.newProjectEligibilitySt
                                  .npeFinConExcessIndSt === "Fail") &&
                              docId.certificationStatus ===
                                "Guide Ineligible" ? (
                                <>
                                  <p
                                    className="accordion-header-title"
                                    style={{ color: "#D0021B" }}
                                  >
                                    1. Guide Eligibility
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="accordion-header-title">
                                    1. Guide Eligibility
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="accordion-header-title">
                                1. Guide Eligibility
                              </p>
                            </>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                        <GuideEligibility data={docId}/>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {typeof docId.guideEligibilityAndProjectInfoSt !==
                          "undefined" ? (
                            <>
                              {(docId.guideEligibilityAndProjectInfoSt
                                .piYearBuiltSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piConvtdProjIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piGutRehabIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piYearConvtdSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piAddnPhsOrAnnxIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piCntlHOATurnOverIndSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piDtTurndOverSt === "Fail" ||
                                docId.guideEligibilityAndProjectInfoSt
                                  .piCommnAreasCompltIndSt === "Fail" ||
                                docId.newProjectEligibilitySt
                                  .npeAssrArrgReviewedIndSt === "Fail") &&
                              docId.certificationStatus ===
                                "Guide Ineligible" ? (
                                <>
                                  <p
                                    className="accordion-header-title"
                                    style={{ color: "#D0021B" }}
                                  >
                                    2. Project Information
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="accordion-header-title">
                                    2. Project Information
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="accordion-header-title">
                                2. Project Information
                              </p>
                            </>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                        <ProjectInformation data={docId}/>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {typeof docId.projectPresaleSt !== "undefined" ? (
                            <>
                              {(docId.projectPresaleSt.ppTotalUnitsSt ===
                                "Fail" ||
                                docId.projectPresaleSt
                                  .ppOwnerOccupiedUnitsSt === "Fail" ||
                                docId.projectPresaleSt.ppSecondHomesSt ===
                                  "Fail" ||
                                docId.projectPresaleSt.ppUnitsRentedSt ===
                                  "Fail" ||
                                docId.projectPresaleSt.ppUnitsCompleteSt ===
                                  "Fail" ||
                                docId.projectPresaleSt.ppUnitsConveyedSt ===
                                  "Fail" ||
                                docId.projectPresaleSt
                                  .ppPreSaleConveyPercntgeSt === "Fail" ||
                                docId.projectPresaleSt
                                  .ppDevOwndUntsRentalHoldBackIndSt ===
                                  "Fail" ||
                                docId.projectPresaleSt.ppDevOwnedUnitsSt ===
                                  "Fail" ||
                                docId.projectPresaleSt
                                  .ppMaintFeesDevOwndCurrentIndSt === "Fail" ||
                                docId.projectPresaleSt
                                  .ppActivePendingSpAssmntsIndSt === "Fail") &&
                              docId.certificationStatus ===
                                "Guide Ineligible" ? (
                                <>
                                  <p
                                    className="accordion-header-title"
                                    style={{ color: "#D0021B" }}
                                  >
                                    3. Project: Presale and Investor
                                    Concentration
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="accordion-header-title">
                                    3. Project: Presale and Investor
                                    Concentration
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="accordion-header-title">
                                3. Project: Presale and Investor Concentration
                              </p>
                            </>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <ProjectPresaleAndInvestorConcentration data={docId} convResult={convResult}/>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  {values.phaseId === "proj" ? (
                    <></>
                  ) : (
                    <>
                      <Accordion
                        defaultActiveKey="0"
                        style={{ marginTop: "16px" }}
                      >
                        <Card
                          className="accordion-header-card"
                          style={{ margin: "0px" }}
                        >
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              {typeof docId.phasePresaleSt !== "undefined" ? (
                                <>
                                  {(docId.phasePresaleSt.phpUnitsCompleteSt ===
                                    "Fail" ||
                                    docId.phasePresaleSt
                                      .phpUnitsSoldOwnerOccpSecondHomeRsdnSt ===
                                      "Fail" ||
                                    docId.phasePresaleSt
                                      .phpOwnerOccupiedUnitsSt === "Fail" ||
                                    docId.phasePresaleSt.phpSecondHomesSt ===
                                      "Fail") &&
                                  docId.certificationStatus ===
                                    "Guide Ineligible" ? (
                                    <>
                                      <p
                                        className="accordion-header-title"
                                        style={{ color: "#D0021B" }}
                                      >
                                        4. Phase: Presale and Investor
                                        Concentration
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="accordion-header-title">
                                        4. Phase: Presale and Investor
                                        Concentration
                                      </p>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <p className="accordion-header-title">
                                    4. Phase: Presale and Investor Concentration
                                  </p>
                                </>
                              )}
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                            <PhasePresaleAndInvestorConcentration data={docId} />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </>
                  )}

                  {(convResult < 90 &&
                    values.phaseId === "proj" &&
                    values.piAddnPhsOrAnnxInd === "No" &&
                    values.piCntlHOATurnOverInd === "Yes" &&
                    values.piCommnAreasCompltInd === "Yes" &&
                    values.ppUnitsComplete === values.ppTotalUnits &&
                    values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                    values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
                  (convResult >= 90 &&
                    values.phaseId === "proj" &&
                    values.piAddnPhsOrAnnxInd === "No" &&
                    values.piCntlHOATurnOverInd === "Yes" &&
                    values.piCommnAreasCompltInd === "Yes" &&
                    values.ppUnitsComplete === values.ppTotalUnits) ? (
                    <></>
                  ) : (
                    <Accordion
                      defaultActiveKey="0"
                      style={{ marginTop: "16px" }}
                    >
                      <Card
                        className="accordion-header-card"
                        style={{ margin: "0px" }}
                      >
                        <Card.Header className="accordion-header-space">
                          <ContextAwareToggle eventKey="1">
                            {typeof docId.newProjectEligibilitySt !==
                            "undefined" ? (
                              <>
                                {(docId.newProjectEligibilitySt
                                  .npeMasterAsscIndSt === "Fail" ||
                                  docId.newProjectEligibilitySt
                                    .npePayMnthyAssesLndrRevwPlanIndSt ===
                                    "Fail" ||
                                  docId.newProjectEligibilitySt
                                    .npeLglDocsComplyIndSt === "Fail") &&
                                docId.certificationStatus ===
                                  "Guide Ineligible" ? (
                                  <>
                                    {values.phaseId === "proj" ? (
                                      <p
                                        className="accordion-header-title"
                                        style={{ color: "#D0021B" }}
                                      >
                                        4. New Project Eligibility
                                      </p>
                                    ) : (
                                      <p
                                        className="accordion-header-title"
                                        style={{ color: "#D0021B" }}
                                      >
                                        5. New Project Eligibility
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {values.phaseId === "proj" ? (
                                      <p className="accordion-header-title">
                                        4. New Project Eligibility
                                      </p>
                                    ) : (
                                      <p className="accordion-header-title">
                                        5. New Project Eligibility
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {values.phaseId === "proj" ? (
                                  <p className="accordion-header-title">
                                    4. New Project Eligibility
                                  </p>
                                ) : (
                                  <p className="accordion-header-title">
                                    5. New Project Eligibility
                                  </p>
                                )}
                              </>
                            )}
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body className="accordion-body-card">
                             <NewProjectEligibility data={docId} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  )}

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {typeof docId.projectOwnershipSt !== "undefined" ? (
                            <>
                              {(docId.projectOwnershipSt
                                .poOwnersHaveSoleOwnshpIndSt === "Fail" ||
                                docId.projectOwnershipSt.poLeaseComplyIndSt ===
                                  "Fail" ||
                                docId.projectOwnershipSt.poUnitsSnglOwnSt ===
                                  "Fail" ||
                                docId.projectOwnershipSt
                                  .poSnglOwnMoreThanOneIndSt === "Fail") &&
                              docId.certificationStatus ===
                                "Guide Ineligible" ? (
                                <>
                                  {(convResult < 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits &&
                                    values.ppDevOwndUntsRentalHoldBackInd ===
                                      "Yes" &&
                                    values.ppDevOwnedUnits /
                                      values.ppTotalUnits <=
                                      0.2) ||
                                  (convResult >= 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits) ? (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      4. Project Ownership
                                    </p>
                                  ) : values.phaseId === "proj" ? (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      5. Project Ownership
                                    </p>
                                  ) : (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      6. Project Ownership
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {(convResult < 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits &&
                                    values.ppDevOwndUntsRentalHoldBackInd ===
                                      "Yes" &&
                                    values.ppDevOwnedUnits /
                                      values.ppTotalUnits <=
                                      0.2) ||
                                  (convResult >= 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits) ? (
                                    <p className="accordion-header-title">
                                      4. Project Ownership
                                    </p>
                                  ) : values.phaseId === "proj" ? (
                                    <p className="accordion-header-title">
                                      5. Project Ownership
                                    </p>
                                  ) : (
                                    <p className="accordion-header-title">
                                      6. Project Ownership
                                    </p>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {(convResult < 90 &&
                                values.phaseId === "proj" &&
                                values.piAddnPhsOrAnnxInd === "No" &&
                                values.piCntlHOATurnOverInd === "Yes" &&
                                values.piCommnAreasCompltInd === "Yes" &&
                                values.ppUnitsComplete ===
                                  values.ppTotalUnits &&
                                values.ppDevOwndUntsRentalHoldBackInd ===
                                  "Yes" &&
                                values.ppDevOwnedUnits / values.ppTotalUnits <=
                                  0.2) ||
                              (convResult >= 90 &&
                                values.phaseId === "proj" &&
                                values.piAddnPhsOrAnnxInd === "No" &&
                                values.piCntlHOATurnOverInd === "Yes" &&
                                values.piCommnAreasCompltInd === "Yes" &&
                                values.ppUnitsComplete ===
                                  values.ppTotalUnits) ? (
                                <p className="accordion-header-title">
                                  4. Project Ownership
                                </p>
                              ) : values.phaseId === "proj" ? (
                                <p className="accordion-header-title">
                                  5. Project Ownership
                                </p>
                              ) : (
                                <p className="accordion-header-title">
                                  6. Project Ownership
                                </p>
                              )}
                            </>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                        <ProjectOwnership data={docId}/>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {typeof docId.lcFinancialsSt !== "undefined" ? (
                            <>
                              {(docId.lcFinancialsSt.famtExclItemsSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fbugtAnlIncomeSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fbugtAnlReservesSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fdefdMntIndSt === 
                          "Fail" ||
                          docId.lcFinancialsSt.finspFailIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fleakRepairIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fmetDefIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fphyDetIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.fresStudyReqIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.funfundRepIndSt ===
                          "Fail" ||
                          docId.lcFinancialsSt.funitsHOAExpnPastDueSt ===
                          "Fail" ||
                                fspAssmtsRuleCheck(docId.lcFinancialsSt.fspAssmts) === "Fail"
                                ) &&
                              docId.certificationStatus ===
                                "Guide Ineligible" ? (
                                <>
                                  {(convResult < 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits &&
                                    values.ppDevOwndUntsRentalHoldBackInd ===
                                      "Yes" &&
                                    values.ppDevOwnedUnits /
                                      values.ppTotalUnits <=
                                      0.2) ||
                                  (convResult >= 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits) ? (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      5. Financials, Special Assessments & Critical Repairs
                                    </p>
                                  ) : values.phaseId === "proj" ? (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      6. Financials, Special Assessments & Critical Repairs
                                    </p>
                                  ) : (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      7. Financials, Special Assessments & Critical Repairs
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {(convResult < 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits &&
                                    values.ppDevOwndUntsRentalHoldBackInd ===
                                      "Yes" &&
                                    values.ppDevOwnedUnits /
                                      values.ppTotalUnits <=
                                      0.2) ||
                                  (convResult >= 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits) ? (
                                    <p className="accordion-header-title">
                                      5. Financials, Special Assessments & Critical Repairs
                                    </p>
                                  ) : values.phaseId === "proj" ? (
                                    <p className="accordion-header-title">
                                      6. Financials, Special Assessments & Critical Repairs
                                    </p>
                                  ) : (
                                    <p className="accordion-header-title">
                                      7. Financials, Special Assessments & Critical Repairs
                                    </p>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {(convResult < 90 &&
                                values.phaseId === "proj" &&
                                values.piAddnPhsOrAnnxInd === "No" &&
                                values.piCntlHOATurnOverInd === "Yes" &&
                                values.piCommnAreasCompltInd === "Yes" &&
                                values.ppUnitsComplete ===
                                  values.ppTotalUnits &&
                                values.ppDevOwndUntsRentalHoldBackInd ===
                                  "Yes" &&
                                values.ppDevOwnedUnits / values.ppTotalUnits <=
                                  0.2) ||
                              (convResult >= 90 &&
                                values.phaseId === "proj" &&
                                values.piAddnPhsOrAnnxInd === "No" &&
                                values.piCntlHOATurnOverInd === "Yes" &&
                                values.piCommnAreasCompltInd === "Yes" &&
                                values.ppUnitsComplete ===
                                  values.ppTotalUnits) ? (
                                <p className="accordion-header-title">
                                  5. Financials, Special Assessments & Critical Repairs
                                </p>
                              ) : values.phaseId === "proj" ? (
                                <p className="accordion-header-title">
                                  6. Financials, Special Assessments & Critical Repairs
                                </p>
                              ) : (
                                <p className="accordion-header-title">
                                  7. Financials, Special Assessments & Critical Repairs
                                </p>
                              )}
                            </>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                          <FinancialsSpecialAssessmentsCriticalRepairs data = {docId}/>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="0" style={{ marginTop: "16px" }}>
                    <Card
                      className="accordion-header-card"
                      style={{ margin: "0px" }}
                    >
                      <Card.Header className="accordion-header-space">
                        <ContextAwareToggle eventKey="1">
                          {typeof docId.lcLitigationSt !== "undefined" ? (
                            <>
                              {(docId.lcLitigationSt.lsafetyLitgtnIndSt ===
                                "Fail" ||
                                docId.lcLitigationSt.linsurancCvgIndSt ===
                                  "Fail" ||
                                docId.lcLitigationSt.lminrMttrLitgtnIndSt ===
                                  "Fail" ||
                                docId.lcLitigationSt.lnonResSqFtgSt ===
                                  "Fail") &&
                              docId.certificationStatus ===
                                "Guide Ineligible" ? (
                                <>
                                  {(convResult < 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits &&
                                    values.ppDevOwndUntsRentalHoldBackInd ===
                                      "Yes" &&
                                    values.ppDevOwnedUnits /
                                      values.ppTotalUnits <=
                                      0.2) ||
                                  (convResult >= 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits) ? (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      6. Litigation, Usage & Insurance
                                    </p>
                                  ) : values.phaseId === "proj" ? (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      7. Litigation, Usage & Insurance
                                    </p>
                                  ) : (
                                    <p
                                      className="accordion-header-title"
                                      style={{ color: "#D0021B" }}
                                    >
                                      8. Litigation, Usage & Insurance
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {(convResult < 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits &&
                                    values.ppDevOwndUntsRentalHoldBackInd ===
                                      "Yes" &&
                                    values.ppDevOwnedUnits /
                                      values.ppTotalUnits <=
                                      0.2) ||
                                  (convResult >= 90 &&
                                    values.phaseId === "proj" &&
                                    values.piAddnPhsOrAnnxInd === "No" &&
                                    values.piCntlHOATurnOverInd === "Yes" &&
                                    values.piCommnAreasCompltInd === "Yes" &&
                                    values.ppUnitsComplete ===
                                      values.ppTotalUnits) ? (
                                    <p className="accordion-header-title">
                                      6. Litigation, Usage & Insurance
                                    </p>
                                  ) : values.phaseId === "proj" ? (
                                    <p className="accordion-header-title">
                                      7. Litigation, Usage & Insurance
                                    </p>
                                  ) : (
                                    <p className="accordion-header-title">
                                      8. Litigation, Usage & Insurance
                                    </p>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {(convResult < 90 &&
                                values.phaseId === "proj" &&
                                values.piAddnPhsOrAnnxInd === "No" &&
                                values.piCntlHOATurnOverInd === "Yes" &&
                                values.piCommnAreasCompltInd === "Yes" &&
                                values.ppUnitsComplete ===
                                  values.ppTotalUnits &&
                                values.ppDevOwndUntsRentalHoldBackInd ===
                                  "Yes" &&
                                values.ppDevOwnedUnits / values.ppTotalUnits <=
                                  0.2) ||
                              (convResult >= 90 &&
                                values.phaseId === "proj" &&
                                values.piAddnPhsOrAnnxInd === "No" &&
                                values.piCntlHOATurnOverInd === "Yes" &&
                                values.piCommnAreasCompltInd === "Yes" &&
                                values.ppUnitsComplete ===
                                  values.ppTotalUnits) ? (
                                <p className="accordion-header-title">
                                  6. Litigation, Usage & Insurance
                                </p>
                              ) : values.phaseId === "proj" ? (
                                <p className="accordion-header-title">
                                  7. Litigation, Usage & Insurance
                                </p>
                              ) : (
                                <p className="accordion-header-title">
                                  8. Litigation, Usage & Insurance
                                </p>
                              )}
                            </>
                          )}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="accordion-body-card">
                                <LitigationUsageAndInsurance data = {docId}/>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className="form-footer">
                  <div></div>
                  <div>
                    {values.phaseId === "proj" ? (
                      <>
                        <button
                          onClick={() => skipStep4()}
                          className="complete-cert-print-btn"
                        >
                          <PrintIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#0C77BA",
                              paddingRight: "6px",
                            }}
                          />
                          Print
                        </button>
                        <button
                          className="complete-cert-btn"
                          onClick={goToProject}
                          style={{ marginLeft: "10px" }}
                        >
                          Return to Project
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => skipStep4()}
                          className="complete-cert-print-btn"
                        >
                          <PrintIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#0C77BA",
                              paddingRight: "6px",
                            }}
                          />
                          Print
                        </button>
                        <button
                          className="complete-cert-btn"
                          onClick={goToPhase}
                          style={{ marginLeft: "10px" }}
                        >
                          Return to Phase
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

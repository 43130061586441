import React from 'react';

export const IneligibilityList = () => (
  <div className="details">
    <h2>IneligibilityList Page</h2>
    <p>IneligibilityList LIST LINK </p>

  </div>
)

export default IneligibilityList;
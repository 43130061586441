import React from "react";
export const ProjectInformation = ({ data }) => {
    return (
        <div style={{ padding: "18px 24px" }}>
            <div className="question">
                <div className="number">1.</div>
                <div>
                    In what year was the project originally built?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .piYearBuiltSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {data.guideEligibilityAndProjectInfoSt.piYearBuilt}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {data.guideEligibilityAndProjectInfoSt.piYearBuilt}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">2.</div>
                <div>Is this a converted project?</div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .piConvtdProjIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {data.guideEligibilityAndProjectInfoSt.piConvtdProjInd}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {data.guideEligibilityAndProjectInfoSt.piConvtdProjInd}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data.guideEligibilityAndProjectInfoSt.piConvtdProjInd === "Yes" ? (
                <>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                            Is the project a gut rehabilitation
                            conversion as defined in{" "}
                            <a
                                target="_blank"
                                href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                            >
                                {" "}
                                B4-2.2-02
                            </a>{" "}
                            of the Selling Guide, or a non-gut
                            conversion that meets the definition of an
                            Established Project?{" "}
                        </div>
                    </div>
                    <div
                        className="summary-answer"
                        style={{ paddingLeft: "86px" }}
                    >
                        <div className="label">Answer:&nbsp;</div>
                        {typeof data.guideEligibilityAndProjectInfoSt !==
                            "undefined" ? (
                            <>
                                {data.guideEligibilityAndProjectInfoSt
                                    .piGutRehabIndSt === "Fail" ? (
                                    <>
                                        <div
                                            className="attribute"
                                            style={{ color: "#D0021B" }}
                                        >
                                            {data.guideEligibilityAndProjectInfoSt.piGutRehabInd}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="attribute">
                                            {data.guideEligibilityAndProjectInfoSt.piGutRehabInd}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
            {data.guideEligibilityAndProjectInfoSt.piConvtdProjInd === "Yes" ? (
                <>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                            What year was the project converted?
                        </div>
                    </div>
                    <div
                        className="summary-answer"
                        style={{ paddingLeft: "86px" }}
                    >
                        <div className="label">Answer:&nbsp;</div>
                        {typeof data.guideEligibilityAndProjectInfoSt !==
                            "undefined" ? (
                            <>
                                {data.guideEligibilityAndProjectInfoSt
                                    .piYearConvtdSt === "Fail" ? (
                                    <>
                                        <div
                                            className="attribute"
                                            style={{ color: "#D0021B" }}
                                        >
                                            {data.guideEligibilityAndProjectInfoSt.piYearConvtd}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="attribute">
                                            {data.guideEligibilityAndProjectInfoSt.piYearConvtd}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
            <div className="question">
                <div className="number">3.</div>
                <div>
                    Is the project subject to additional legal
                    phasing or annexation?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .piAddnPhsOrAnnxIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {data.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {data.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="question">
                <div className="number">4.</div>
                <div>
                    Has control of the HOA been turned over to the
                    unit purchasers?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .piCntlHOATurnOverIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" ? (
                <>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                            Year Turned Over
                        </div>
                    </div>
                    <div
                        className="summary-answer"
                        style={{ paddingLeft: "86px" }}
                    >
                        <div className="label">Answer:&nbsp;</div>
                        {typeof data.guideEligibilityAndProjectInfoSt !==
                            "undefined" ? (
                            <>
                                {data.guideEligibilityAndProjectInfoSt
                                    .piDtTurndOverSt === "Fail" ? (
                                    <>
                                        <div
                                            className="attribute"
                                            style={{ color: "#D0021B" }}
                                        >
                                            {data.guideEligibilityAndProjectInfoSt.piDtTurndOver}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="attribute">
                                            {data.guideEligibilityAndProjectInfoSt.piDtTurndOver}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
            <div className="question">
                <div className="number">5.</div>
                <div>
                    Are the common areas and amenities 100% complete
                    for the entire project?
                </div>
            </div>
            <div className="summary-answer">
                <div className="label">Answer:&nbsp;</div>
                {typeof data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                        {data.guideEligibilityAndProjectInfoSt
                            .piCommnAreasCompltIndSt === "Fail" ? (
                            <>
                                <div
                                    className="attribute"
                                    style={{ color: "#D0021B" }}
                                >
                                    {data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="attribute">
                                    {data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "No" ? (
                <>
                    <div className="question">
                        <div
                            className="number"
                            style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                            }}
                        ></div>{" "}
                        <div>
                            Have acceptable completion assurance
                            arrangements been reviewed (such as a bond,
                            cash deposit, letter of credit) to guarantee
                            the future completion of all project
                            facilities, common elements and limited
                            common elements, or is the project still
                            under construction and not considered
                            substantially complete?
                        </div>
                    </div>
                    <div className="summary-answer">
                        <div className="label">Answer:&nbsp;</div>
                        {typeof data.newProjectEligibilitySt !==
                            "undefined" ? (
                            <>
                                {data.newProjectEligibilitySt
                                    .npeAssrArrgReviewedIndSt === "Fail" ? (
                                    <>
                                        <div
                                            className="attribute"
                                            style={{ color: "#D0021B" }}
                                        >
                                            {data.newProjectEligibilitySt.npeAssrArrgReviewedInd}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="attribute">
                                            {data.newProjectEligibilitySt.npeAssrArrgReviewedInd}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FaChevronDown } from 'react-icons/fa'
import { FaChevronUp } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const Project = ({match,data}) => {
  var project= data.find(p => p.id === Number(match.params.projectId));
  var mockProject;
  const [open0, setOpen0] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    function createData(individual, developer, units, percentage) {
        return { individual, developer, units, percentage };
    }
      
    const rows = [
        createData('Jared Matthews', 'No', '3 units owned', '8%'),
        createData('Luci Cartwright', 'No', '2 units owned', '4%'),
        createData('Nettie Salgado', 'No', '2 units owned', '4%')
    ];
    
    function createData2(individual, developer) {
        return { individual, developer };
    }
      
    const rows2 = [
        createData2('Total number of units', 38),
        createData2('Total number of units sold and closed', 32),
        createData2('Total number of units under bona-fide sales contracts', 1)
    ];
    
    function createData3(individual, developer) {
        return { individual, developer };
    }
      
    const rows3 = [
        createData3('Unit owners that are 60 or more days delinquent on common expense assemssments', 2),
        createData3('Total number of units sold and closed', 'No')
    ];

    const classes = useStyles();

  if(project)
    mockProject = <div>
                    <div className='content'>
                        <img src={project.imgUrl} alt="Project" />
                        <li className='condo-list1'>
                            <h1 className='condo-name'>
                                {project.projectName}
                            </h1>
                            <h4 className='condo-address'>
                                {project.address}
                            </h4>
                            <hr/>
                            <li className='condo-list2'>
                                <p className='condo-detail-top'>
                                    <span>UNIT</span>
                                </p>
                                <p className='condo-detail-bottom'>
                                    <span>{project.unit}</span>
                                </p>
                            </li>
                            <li className='condo-list3'>
                                <p className='condo-detail-top'>
                                    <span>LAST UPDATED</span>
                                </p>
                                <p className='condo-detail-bottom'>
                                    <span>{project.update}</span>
                                </p>
                            </li>
                            <br/>
                            <li className='condo-list4'>
                                <p className='condo-detail-top'>
                                    <span>YEAR BUILT</span>
                                </p>
                                <p className='condo-detail-bottom'>
                                    <span>{project.yearBuilt}</span>
                                </p>
                            </li>
                            <li className='condo-list5'>
                                <p className='condo-detail-top'>
                                    <span>ELIGIBILITY STATUS</span>
                                </p>
                                <p className='condo-detail-bottom'>
                                    <span>{project.eligibility}</span>
                                </p>
                            </li>
                        </li>
                    </div>
                    <div>
                        <hr className="new1"/>
                        <Accordion defaultActiveKey="0">
                            <Card className='card'>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setOpen0(!open0)}>
                                    <h2 className='accordion-title'>
                                        Project Profile { open0 ? <FaChevronDown /> : <FaChevronUp/> }
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <li className='condo-list2'>
                                            <p className='condo-detail-top'>
                                                <span>PROJECT LEGAL NAME</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.projName}</span>
                                            </p>
                                        </li>
                                        <li className='condo-list2'>
                                            <p className='condo-detail-top'>
                                                <span>CONDO PROJECT ALIAS</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.condoAlias}</span>
                                            </p>
                                        </li>
                                        <li className='condo-list3'>
                                            <p className='condo-detail-top'>
                                                <span>COA NAME</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.projectName}</span>
                                            </p>
                                        </li>
                                        <br/>
                                        <li className='condo-list2'>
                                            <p className='condo-detail-top'>
                                                <span>COA MANAGEMENT ADDRESS</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.coaAddress}</span>
                                            </p>
                                        </li>
                                        <li className='condo-list2'>
                                            <p className='condo-detail-top'>
                                                <span>COA TAX ID #</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.coaTaxId}</span>
                                            </p>
                                        </li>
                                        <li className='condo-list3'>
                                            <p className='condo-detail-top'>
                                                <span>COA COMPANY TAX ID #</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.coaCompanyTaxId}</span>
                                            </p>
                                        </li>
                                        <br/>
                                        <li className='condo-list2'>
                                            <p className='condo-detail-top'>
                                                <span>MASTER OR UMBRELLA ASSOCIATION</span>
                                            </p>
                                            <p className='condo-detail-bottom'>
                                                <span>{project.masterAssociation}</span>
                                            </p>
                                        </li>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <hr className="new1"/>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setOpen1(!open1)}>
                                    <h2 className='accordion-title'>
                                        Project Details { open1 ? <FaChevronUp /> : <FaChevronDown/> }
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <h5>
                                            This section provides detailed information regarding the factors that are considered by 
                                            government sponsored entities when determining if a condo project is eligibile.
                                        </h5>
                                        <div className='box'>
                                            <p className='box-number'>{project.singleEntity}%</p>
                                            <p className='box-text'>Single entity ownership</p>
                                        </div>
                                        <div className='box'>
                                            <p className='box-number'>{project.ownerOccupancy}%</p>
                                            <p className='box-text'>Owner occupancy</p>
                                        </div>
                                        <div className='box'>
                                            <p className='box-number'>{project.deliquentFees}%</p>
                                            <p className='box-text'>Deliniquent in HOA fees</p>
                                        </div>
                                        <div className='box'>
                                            <p className='box-number'>{project.pendingLitigation}</p>
                                            <p className='box-text'>Pending or active litigation</p>
                                        </div>
                                        <div className='box'>
                                            <p className='box-number'>{project.commercialUsage}%</p>
                                            <p className='box-text'>Commercial space usage</p>
                                        </div>
                                        <h4 className='table-title'>Single Entity Ownership</h4>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Individual/Entity</TableCell>
                                                        <TableCell align="left">Developer/Sponsor</TableCell>
                                                        <TableCell align="left">Units owned&nbsp;(g)</TableCell>
                                                        <TableCell align="left">Percentage of total units&nbsp;(g)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">{row.individual}</TableCell>
                                                        <TableCell align="left">{row.developer}</TableCell>
                                                        <TableCell align="left">{row.units}</TableCell>
                                                        <TableCell align="left">{row.percentage}</TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <h4 className='table-title'>Owner Occupancy</h4>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableBody>
                                                {rows2.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">{row.individual}</TableCell>
                                                        <TableCell align="left">{row.developer}</TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <h4 className='table-title'>Deliniquent in HOA Fees</h4>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableBody>
                                                {rows3.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">{row.individual}</TableCell>
                                                        <TableCell align="left">{row.developer}</TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <hr className="new1"/>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => setOpen2(!open2)}>
                                    <h2 className='accordion-title'>
                                        Project Completion { open2 ? <FaChevronUp /> : <FaChevronDown/> }
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>SO MANY WORDS</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <hr className="new1"/>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => setOpen3(!open3)}>
                                    <h2 className='accordion-title'>
                                        Converted/Rehabilitated { open3 ? <FaChevronUp /> : <FaChevronDown/> }
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>SO MANY WORDS</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <hr className="new1"/>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4" onClick={() => setOpen4(!open4)}>
                                    <h2 className='accordion-title'>
                                        Lender/Loan { open4 ? <FaChevronUp /> : <FaChevronDown/> }
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>SO MANY WORDS</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <hr className="new1"/>
                        </Accordion>
                </div> 
            </div>;
  else
    mockProject = <h2> Sorry. Project does not exist </h2>;

  return (
    <div className='container'>
        <div className='projects'>
         {mockProject} 
      </div>
    </div>
  )    
}
      
export default Project;
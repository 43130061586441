import React, { useState } from "react";
import { LenderDetails } from "./pages/LenderDetails";
import { Form1076 } from "./pages/Form1076";
import { Form1091 } from "./pages/Form1091";
import { Form1073 } from "./pages/Form1073";
import { Additional } from "./pages/Additional";
import { Status } from "./pages/Status";
import { Review } from "./pages/Review";
import Dropzone from "../Dropzone/Dropzone";
import { Attachments } from "./pages/Attachments";
import { Success } from "./pages/Success";
import { ConfirmPers } from "./pages/ConfirmPers";


export const AddEstablishedPers = props => {
  window.onscroll = function() {
    effects();
  };

  const passData = JSON.parse(sessionStorage.getItem("passData"));


  function effects() {}
  const [step, setStep] = useState(0);
  const [persId, setPersId] = useState(null);

  const [formData, setFormData] = useState({
    //perslen
    projectId: props.match.params.project_id,
    phaseId: null,
    persStatus: "",
    persStatusJustification: "",
    receivedDate: "",
    lenderName: "",
    assgndRiskAnalyst: "",
    lenderSSN: "",
    lenderAddress: "",
    lenderCity: null,
    lenderState: "",
    lenderZip: null,
    requesterName: "",
    requesterEmail: "",

    //pers1076
    projLegalName: "",
    projPhyAddr: "",
    hoaMgmtAddr: "",
    hoaName: "",
    hoaTaxId: "",
    hoaMgmtCmpnyTaxId: "",
    mstrAssocName: "",
    rentalPoolngArrngmntInd: "",
    deedResaleRestrctnInd: "",
    mfHomeInd: "",
    mndtryMbrshipInd: "",
    nonIncdntlIncomeInd: "",
    supprtCareSeniorsInd: "",
    proj100pctCompltnInd: "",
    subjAddtnlPhasingInd: "",
    legallyPhasedInd: "",
    noOfPhasesCmpltd: "",
    totalPhasesLegallyPlanned: "",
    totalUnitsPlanned: "",
    plannedAmenitiesCompltnInd: "",
    trnsfrControlHoaInd: "",
    dtTrnsfrControlHoa: null,
    convrsnOfExistngStructureInd: "",
    yrBuilt: "",
    yrConvrtd: "",
    fullGutRehabConvrsnInd: "",
    structrllySoundInd: "",
    repairsCompltnInd: "",
    replacemntResrvsAlloctnInd: "",
    resrvsToFundImprovmntsInd: "",
    unitOwnrsDelinqCommnExpnseCnt: "",
    mrtgeeRespnbleDelinqExpnseInd: "",
    mrtgeeRespnbleDelinqExpnseTerm: null,
    hoaInvlvdLitigtnInd: "",
    litigAttrneyName: "",
    litigAttrneyPhone: null,
    totUnitsProj: "",
    totUnitsPhase: "",
    totUnitsSoldClosedProj: "",
    totUnitsSoldClosedPhase: "",
    totUnitsBonafidSalesProj: "",
    totUnitsBonafidSalesPhase: "",
    totUnitsOwnrOccpntProj: "",
    totUnitsOwnrOccpntPhase: "",
    totUnitsSecOwnrProj: "",
    totUnitsSecOwnrPhase: "",
    totUnitsInvstrOwnrProj: "",
    totUnitsInvstrOwnrPhase: "",
    totUnitsRentedProj: "",
    totUnitsRentedPhase: "",
    totUnitsOwnByHoaProj: "",
    totUnitsOwndByHoaPhase: "",
    soleOwnrshipIntrstAmenitiesInd: "",
    soleOwnrshipIntrstAmenitiesExpln: "",
    unitsUsedCommrclPurpseInd: "",
    totSqFootageCommSpace: "",
    floodZoneInd: "",
    floodCvrgInForceDesc: null,
    floodCvrgInForceDescOtherAmt: "",
    hoaAcctsSeparateOpratingReserveFundsInd: "",
    hoaAcctsAcessCntrlsInd: "",
    hoaAcctsMnthlyBankStmtsInd: "",
    hoaAcctsCheckSignedReqdMembersInd: "",
    hoaAcctsMngmtCmpnyAccts_maintnanceInd: "",
    hoaAcctsMngmtCmpnyAuthorityReqmntsInd: "",
    hzrdInsAgentName: "",
    hzrdInsAgentPhone: null,
    hzrdInsPlcyNbr: "",
    liabInsAgentName: "",
    liabInsAgentPhone: null,
    liabInsPlcyNbr: "",
    fidelInsAgentName: "",
    fidelInsAgentPhone: null,
    fidelInsPlcyNbr: "",
    floodInsAgentName: "",
    floodInsAgentPhone: null,
    floodInsPlcyNbr: "",
    preparerName: "",
    preparerTitle: "",
    preparerCmpnyName: "",
    preparerPhone: null,
    preparerEmail: "",
    preparerCmpnyAddr: "",
    dtCmpltd: null,
    commUse1076DataList: [
      {
        commrclUseType: "",
        ownrTenantName: "",
        nbrOfUnitsCnt: "",
        sqFootage: "",
        sqFtPct: "",
        createdBy: passData.user
      }
    ],
    unitOwnrshp1076DataList: [
      {
        indvName: "",
        devlSpnsrInd: "",
        nbrOfUnitsOwndCnt: "",
        unitsOwndPct: "",
        nbrUnitsLeasedMrktRentCnt: "",
        nbrUnitsLeasedRentCntrlCnt: "",
        createdBy: passData.user
      }
    ],

    //pers1091
    lndrName: "",
    sellrServcrNbr: "",
    purposeOfApplicn: "",
    dtExpryPriorApprval: null,
    projectLegalName: "",
    projectAddress: "",
    projectCity: null,
    projectState: "",
    projectZipCode: null,
    hoaFedTaxId: "",
    persReqSummary: "",
    authrzdRepName: "",
    authrzdRepTitle: "",
    authrzdRepEmail: "",
    authrzdRepPhone: null,
    dtAuthrzdRepSignature: null,
    preparerName1091: "",
    preparerTitle1091: "",
    preparerEmail1091: "",
    preparerPhone1091: null,
    dtPreparerSignature: null,

    //pers1073
    propertyAddr: "",
    unitNumber: "",
    propertyCity: null,
    propertyState: "",
    propertyZipCode: null,
    county: "",
    legalDesc: "",
    reTaxes: "",
    projectName: "",
    specialAssmnt: "",
    hoaFees: "",
    femaSplFloodHzrdAreaInd: "",
    femaFloodZone: "",
    femaMapNbr: "",
    femaMapDate: null,
    projectDesc: null,
    nbrStoriesCnt: "",
    yrBuilt1073: "",
    effAge: "",
    nbrUnitsCnt: "",
    nbrUnitsRentedCnt: "",
    nbrUnitsOwnrOccpdCnt: "",
    convExstngBldgInd: "",
    convExstngBldgDesc: "",
    commSpaceInd: "",
    commSpaceDesc: "",
    subjPropAppraisdAddr: "",
    subjPropAppraisdVal: "",

    //pers1051
    masterAssociationName: "",
    exceptions1051: "",
    masterAssociationNotes: "",

    //budget
    incomeFromAssessments: "",
    reserves: "",
    percentageOfReservesOnHand: "",
    exceptionsBudget: ""
  });
  const nextStep = () => setStep(prev => prev + 1);
  const nextStep2 = () => setStep(prev => prev + 2);
  const prevStep = () => setStep(prev => prev - 1);

   switch (step) {
  // switch (6) {
    case 0:
      return (
        <LenderDetails
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          step={step}
        />
      );
    case 1:
      return (
        <Form1076
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 2:
      return (
        <Form1091
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 3:
      return (
        <Form1073
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 4:
      return (
        <Additional
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 5:
      return (
        <Review
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          nextStep2={nextStep2}
          prevStep={prevStep}
          step={step}
          setPersId={setPersId}
        />
      );
    case 6:
      return (
        <ConfirmPers formData={formData} step={step} nextStep={nextStep} persId={persId}/>
        // <Status
        //   formData={formData}
        //   setFormData={setFormData}
        //   nextStep={nextStep}
        //   prevStep={prevStep}
        //   step={step}
        //   persId={persId}
        //   projectId={props.match.params.project_id}
        // />
        
      );
    case 7:
      return (
        <Attachments
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          persId={persId}

        />
      );
    case 8:
      return <Success formData={formData} step={step} persId={persId} />;
    default:
      return <Success formData={formData}  step={step} persId={persId} />;
  }
};

import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field, DatePicker } from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function Section3({
  collapseKey,
  lender,
  states,
  persId,
  projectId
}) {
  const [isEdit, setEdit] = useState(false);
  const [lenderState, setLenderState] = useState({});
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const initializeState = () => {
    setLenderState({ ...lender });
  };
  useEffect(() => {
    initializeState();
  }, [lender]);

  const handleSaveEdit = async () => {
    try {
      setApiErr("");
      setInternalErr(false);

      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updateEstablishedPersProjectAndForms/1091`,
        {
          persId: persId,
          projectId: projectId,
          pers1091: lenderState
        }
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setLenderState({ ...lender });
    }
  };

  const handleChange = event => {
    let _lender = { ...lenderState };
    _lender[event.target.name] = event.target.value;
    setLenderState({ ..._lender });
  };

  return (
    <Accordion defaultActiveKey="1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">Form (1091)</p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => {
                  toggleEdit(false);
                  setApiErr("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                toggleEdit(true);
                setInternalErr(false);
                setApiErr("");
              }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
           ): null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-left">
                    <Field
                      label="Lender Name"
                      name="lndrName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Seller/Service"
                      name="sellrServcrNbr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Purpose of Application"
                      name="purposeOfApplicn"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Expiration Date of Prior Approval (If Applicable)"
                      name="dtExpryPriorApprval"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Project Identification
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Project's Legal Name"
                      name="projectLegalName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="City"
                      name="projectCity"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="ZIP Code"
                      name="projectZipCode"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Why is a PERS being requested? Provide a summary and recommendation for the approval of this project. Include specific information about all project eligibility requirements that are not met. Attach additional pages if needed. Do not leave this section blank. Missing information will cause delays in the review process. If the description is left blank, the form will be returned for completion."
                      name="persReqSummary"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="textarea"
                      rows="5"
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Address"
                      name="projectAddress"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="State"
                      name="projectState"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={states}
                    />
                    <Field
                      label="Homeowners' Association Federal Tax ID Number"
                      name="hoaFedTaxId"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Name of lender's authorized representative"
                      name="authrzdRepName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Email address"
                      name="authrzdRepEmail"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Title"
                      name="authrzdRepTitle"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Phone Number"
                      name="authrzdRepPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Date of Signature"
                      name="dtAuthrzdRepSignature"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    If this submission is completed by someone other than the
                    lender, complete the following:
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Preparer's name"
                      name="preparerName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Email address"
                      name="preparerEmail"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Date of Signature"
                      name="dtPreparerSignature"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Title"
                      name="preparerTitle"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Phone Number"
                      name="preparerPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

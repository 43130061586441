import React, { useState, useEffect } from "react";
import BannerRow from "./BannerRow";
import { FaSort } from "react-icons/fa";

const BannerList = ({ list }) => {
  const [sortType, setSortType] = useState({
    description: "asc",
    publishDate: "asc",
    expDate: "asc",
  });
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    if (list) {
      setBannerList(list);
    }   
  }, [list]);

  const onSort = (column) => {
    const sortOrder = sortType[column] === "asc" ? "desc" : "asc";
    const obj = { ...sortType, [column]: sortOrder };
    setSortType(JSON.parse(JSON.stringify(obj)));
    sortPdfList(column);
  };

  const sortPdfList = (column) => {
    let sortList = [].concat(bannerList);
    if (column === "expDate" || column === "publishDate") {
      sortList
        .sort((a, b) => {
          const item1 = new Date(a.uploadedDate).getTime();
          const item2 = new Date(b.uploadedDate).getTime();
          return sortType[column] === "asc" ? item1 - item2 : item2 - item1;
        })
        .map((banner) => banner);
    } else {
      sortList
        .sort((a, b) => {
          const item1 = a[column].toLowerCase();
          const item2 = b[column].toLowerCase();
          return sortType[column] === "asc"
            ? item1.localeCompare(item2)
            : item2.localeCompare(item1);
        })
        .map((banner) => banner);
    }
    setBannerList(sortList);
  };

  return (
    <div className="banner-container">
      <div className="banner-header">
        <div className="banner-header-desc">
          Description{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("description");
            }}
          />
        </div>
        <div className="banner-header-name">
          Start Date{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("publishDate");
            }}
          />
        </div>
        <div className="banner-header-name">
          End Date{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("expDate");
            }}
          />
        </div>
        <div className="banner-header-name">Created By </div>
        <div className="banner-header-name"></div>
      </div>
      <div className="banner-body">
        {bannerList && bannerList.length > 0 ? (
          bannerList.map((m, i) => <BannerRow key={i} item={m} />)
        ) : (
          <></>
        )}
      </div>{" "}
    </div>
  );
};
export default BannerList;

import React from "react";
import moment from "moment";
import { FaPen } from "react-icons/fa";
import "./BannerList.css";

const BannerRow = ({ item }) => {
  const handleEdit = (id) => {
    window.location.href = `/banner/${id}`;
  };

  return (
    <div
      style={{
        display: "flex",
        width: "auto",
        borderBottom: "1px solid #edebe9",
        paddingBottom: "16px",
      }}
    >
      <div className="card-banner-desc">{item.description}</div>
      <div className="card-banner-name">
        {/* {moment.utc(item.publishDate).format("MM/DD/YYYY HH:mm A")} */}
        {moment.utc(item.publishDate).subtract(new Date().getTimezoneOffset(), 'minutes').format("MM/DD/YYYY HH:mm A")}
      </div>
      <div className="card-banner-name">
        {moment.utc(item.expDate).subtract(new Date().getTimezoneOffset(), 'minutes').format("MM/DD/YYYY HH:mm A")}
      </div>
      <div className="card-banner-name">
        {item.creator && item.creator === "user" ? "" : item.creator}
      </div>
      <div className="card-banner-name">
        <button
          className="edit-button"
          onClick={() => handleEdit(item.bannerId)}
        >
          <FaPen className="edit-icon" />
          Edit
        </button>
      </div>
    </div>
  );
};

export default BannerRow;

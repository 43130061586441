import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "./childphase.css";

function getSteps() {
  return [
    "Add Child Phase Details",
    "Review",
    "Confirmation"
  ];
}

export default function FormStepper(props) {
  const { step } = props;
  const steps = getSteps();
  return (
    <div className="step-container">
      <Stepper className="step" activeStep={step} orientation="vertical">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

import React from "react";
import IndividualLenderCertification from "./IndividualLenderCertification";
import "./LenderCertificationList.css";
import { FaSort } from "react-icons/fa";
import { isLenderRole, isTPORole } from "../../redux/actions/userAction";

const LenderCertificationList = ({ list }) => {
  let phase = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    phase = list.map((m, i) => (
      <IndividualLenderCertification key={i} item={m} />
    ));
  }

  return (
    <div className="pdf-container autoheight">
      <div className="pdf-header">
        <div className="pdf-header-name">
          {isLenderRole() || isTPORole() ? "Project/Phase Name" : "Lender Name"}{" "}
          <FaSort className="sort-icon" />
        </div>
        <div className="pdf-header-name">
          Certification Date <FaSort className="sort-icon" />
        </div>
        <div className="pdf-header-name">
          Expiration Date <FaSort className="sort-icon" />
        </div>
        <div className="pdf-header-name">Certification Result</div>
      </div>
      <div className="pdf-body">{phase}</div>
    </div>
  );
};
export default LenderCertificationList;

import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import axios from "axios";
import { FaPaperPlane } from "react-icons/fa";

import ContextAwareToggle from "../Profile/ContextAwareToggle";
import Comments from "../Comments/Comments";
import ErrorPage from "../../error-page";
import { isReadonlyRole } from "../../redux/actions/userAction";

export default function Section6({ collapseKey, persId, projectId }) {
  const [getComments, setComments] = useState(null);
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);
  const [commentsData, setCommentsData] = useState({
    // userName: "",
    comments: "",
    commentCharCount: 0,
    persId,
    projectId,
  });

  const fetchCommentsData = async () => {
    const { data } = await axios.get(
      `${global.REACT_APP_API_ENDPOINT}/pers/getCommentsByProjectAndPers/${projectId}/${persId}`
    );
    setComments(data);
  };
  useEffect(() => {
    if (projectId) {
      fetchCommentsData();
      const _commentsData = { ...commentsData };
      _commentsData["persId"] = persId;
      _commentsData["projectId"] = projectId;
      _commentsData["showExternal"]= false;
      setCommentsData({ ..._commentsData });
    }
  }, [projectId]);

  const renderComments = () => {
    let list = <h1>Loading...</h1>;
    if (getComments) {
      list = <Comments list={getComments} />;
    }
    return list;
  };

  const changeHandler = ({ target: { name, value } }) => {
    const _commentsData = { ...commentsData };
    _commentsData[name] = value;
    if (name === "comments") {
      _commentsData["commentCharCount"] = value.length;
    }
    setCommentsData({ ..._commentsData });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setApiErr("");
    setInternalErr(false);

    const passData = JSON.parse(sessionStorage.getItem("passData"));
    commentsData.userName = passData.user;

    const response = await axios.post(
      global.REACT_APP_API_ENDPOINT + `/condoMgr/postComments/`,
      commentsData
    );
    if (response) {
      if (response.status == 200) {
        setApiErr("");
        setInternalErr(false);
        window.location.reload(false);
      } else if (response.data && response.data.message) {
        if (response.data.message == "Internal server error") {
          setApiErr("");
          setInternalErr(true);
        } else {
          setApiErr(response.data.message);
        }
      }
    }
  };

  const { comments, commentCharCount } = commentsData;

  return (
    <Accordion defaultActiveKey="1" id="section5">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">Comments</p>
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15,
              }}
            >
              {apiErr ? apiErr : null}
              {internalErr
                ? "Internal server error while processing request, please contact Fannie Mae technology support"
                : null}
            </div>
            {!isReadonlyRole() ? (
              <form className="comment-form" onSubmit={submitHandler}>
                <div style={{ width: "fit-content" }}>
                  {
                    <textarea
                      className="form-comment-input"
                      required
                      maxLength="5000"
                      placeholder="Type your comment here"
                      type="text"
                      name="comments"
                      value={comments}
                      style={{ width: "88.7%" }}
                      cols={40}
                      rows={10}
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                    />
                  }
                  <br />
                  <span
                    style={{
                      display: "inline-block",
                      marginRight: "5px",
                      float: "right",
                      fontSize: "14px",
                      color: "#787979",
                    }}
                  >{`${commentCharCount}/5000`}</span>
                </div>{" "}
                <br />
                <button className="form-submit-icon" type="submit">
                  <FaPaperPlane className="" />
                </button>
              </form>
            ) : null}
            {renderComments()}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

import React, { useState } from "react";
import {
  Grid,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import "./BannerCreator.css";
import InitialBannerPage from "./Pages/InitialBannerPage";
import BannerSuccess from "./Pages/BannerSuccess";
import VerticalLinearStepper from "../VerticalLinearStepper/VerticalLinearStepper";
import axios from "axios";

const BannerCreator = (props) => {
  const [stepCount, setStepCount] = React.useState(0);
  const bannerId = props.match.params.banner_id;
  const [currentBanner, setCurrentBanner] = React.useState("");
  const incrementCounter = () => setStepCount(stepCount + 1);

  React.useEffect(() => {
    setStepCount(0);
  }, []);

  const userRole = sessionStorage.getItem("role");

  const getPage = () => {
    return <InitialBannerPage bannerId={bannerId} />;
  };
  return (
    <>
      <Grid container>
        <Grid item xs={3} style={{ backgroundColor: "#F2F2F2" }}>
          {bannerId === "new" ? (
            <VerticalLinearStepper
              steps={["Create Banner" /*, "Finalize"*/]}
              activeStep={stepCount}
            />
          ) : (
            <VerticalLinearStepper
              steps={["Edit Banner"]}
              activeStep={stepCount}
            />
          )}
          )
        </Grid>
        <Grid
          container
          item
          xs={9}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ padding: "20px", border: "solid 1px black" }}
        >
          {getPage()}
        </Grid>
      </Grid>
    </>
  );
};

export default BannerCreator;

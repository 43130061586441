import React, { useState, useRef } from "react";
import "../../BannerCreator/BannerCreator.css";
import { Accordion, Card } from "react-bootstrap";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import RadioInput from "../../RadioInput/RadioInput";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import ReactSelect from "react-select";
import {
  makeStyles
} from "@material-ui/core";
import { SplAssmtReasons } from "../../shared/constants/SplAssmtReasons";
import CurrencyInput from 'react-currency-input-field';
export const SplAssmt = ({ index, assmt, updateSpecialAssessmentData, totalUnits, onSave }) => {
  const [formData, setFormData] = useState(assmt);
  const [showOtherField, setShowOtherField] = useState(assmt?.freasonsSpAssmts.filter(r => r.label === "Other").length > 0 ? true : false);
  const [showRepairField, setShowRepairField] = useState(assmt?.freasonsSpAssmts.filter(r => r.label === "Remediate Critical Repairs").length > 0 ? true : false);
  const [collapseKey, setCollapseKey] = useState("1");
  const reasonOptions = SplAssmtReasons;
  const [reason, setReason] = useState(assmt.freasonsSpAssmts);
  const [fttlSpAssmts, setFttlSpAssmts] = useState(assmt.fttlSpAssmts);
  const [fotherReasonSpAssmts, setFotherReasonSpAssmts] = useState(assmt.fotherReasonSpAssmts);
  const [fremediateRepairInd, setFremediateRepairInd] = useState(assmt.fremediateRepairInd);
  const [fnoOfUnitsDelinquent, setFnoOfUnitsDelinquent] = useState(assmt.fnoOfUnitsDelinquent);
  const [fttlSpAssmtsError, setFttlSpAssmtsError] = useState('');
  const [freasonsSpAssmtsError, setFreasonsSpAssmtsError] = useState('');
  const [fotherReasonSpAssmtsError, setFotherReasonSpAssmtsError] = useState('');
  const [fremediateRepairIndError, setFremediateRepairIndError] = useState('');
  const [fnoOfUnitsDelinquentError, setFnoOfUnitsDelinquentError] = useState('');
  const radioStyles = makeStyles(theme => ({
    label: {
      fontSize: theme.typography.pxToRem(12),
      letterSpacing: "0.03333em",
      lineHeight: 1.66
    }
  }));
  const radioClasses = radioStyles();
  const isInit = useRef(true);

  React.useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    saveAssesment();
  }, [onSave]);

  const handleChange = (event) => {
    let value = event.target.value;
    let validForm = "false";
    if ((event.target.name === 'fnoOfUnitsDelinquent' || event.target.name === 'fttlSpAssmts') && value) {
      value = value ? parseInt(value) : value;
    }
    fieldValidation(event, value);
    let data = {
      fttlSpAssmts: fttlSpAssmts,
      freasonsSpAssmts: reason,
      fotherReasonSpAssmts: fotherReasonSpAssmts,
      fremediateRepairInd: fremediateRepairInd,
      fnoOfUnitsDelinquent: fnoOfUnitsDelinquent,
    }
    data[event.target.name] = value;
    setFormData(data);
    if (fttlSpAssmtsError == "" && freasonsSpAssmtsError == "" && fotherReasonSpAssmtsError == "" && fremediateRepairIndError == "" && fnoOfUnitsDelinquentError == "") {
      validForm = true;
    } else {
      validForm = false;
    }
    updateSpecialAssessmentData(data, index, validForm);
  };

  const fieldValidation = (event, value) => {
    switch (event.target.name) {
      case "fttlSpAssmts":
        if (!value && value !== 0) {
          setFttlSpAssmtsError("Required");
        } else {
          setFttlSpAssmtsError("");
        }
        break;
      case "freasonsSpAssmts":
        if (!value || value == '') {
          setFreasonsSpAssmtsError("Required");
        } else {
          setFreasonsSpAssmtsError("");
        }
        break;
      case "fotherReasonSpAssmts":
        if (!value || value == '') {
          setFotherReasonSpAssmtsError("Required");
        } else {
          setFotherReasonSpAssmtsError("");
        }
        break;
      case "fremediateRepairInd":
        if (!value || value == '') {
          setFremediateRepairIndError("Required");
        } else {
          setFremediateRepairIndError("");
        }
        break;
      case "fnoOfUnitsDelinquent":
        if (value === "") {
          setFnoOfUnitsDelinquentError("Required");
          errors.push(true);
        } else if((!/^[0-9]*$/i.test(value))) {
          setFnoOfUnitsDelinquentError("Invalid number");
          errors.push(true);
        } else if (parseInt(value) > totalUnits) {
          setFnoOfUnitsDelinquentError("Units Delinquent cannot be greater than Total # of Units");
          errors.push(true);
        } else {
          setFnoOfUnitsDelinquentError("");
        }
        break;
      default:
        break;
    }
  }

  const handleResonChange = (value) => {
    let data = {
      fttlSpAssmts: fttlSpAssmts,
      freasonsSpAssmts: value,
      fotherReasonSpAssmts: fotherReasonSpAssmts,
      fremediateRepairInd: fremediateRepairInd,
      fnoOfUnitsDelinquent: fnoOfUnitsDelinquent,
    }
    let validForm = true;
    if (value.length === 0) {
      setFreasonsSpAssmtsError("Required");
      data.fotherReasonSpAssmts = "";
      data.fremediateRepairInd = "";
    } else {
      setFreasonsSpAssmtsError("")
      if (value.filter(r => r.label === "Other").length === 0) {
        data.fotherReasonSpAssmts = "";
        setFotherReasonSpAssmts("");
      } else if (value.filter(r => r.label === "Remediate Critical Repairs").length === 0) {
        data.fremediateRepairInd = "";
        setFremediateRepairInd("");
      }
    }
    setShowOtherField(value.filter(r => r.label === "Other").length > 0 ? true : false);
    setShowRepairField(value.filter(r => r.label === "Remediate Critical Repairs").length > 0 ? true : false);
    if (fttlSpAssmtsError != "" && freasonsSpAssmtsError != "" && fotherReasonSpAssmtsError != "" && fremediateRepairIndError != "" && fnoOfUnitsDelinquentError != "") {
      validForm = false;
    } else {
      validForm = true;
    }
    updateSpecialAssessmentData(data, index, validForm);
  }

  const saveAssesment = () => {
    let errors = [];
    if (!fttlSpAssmts && fttlSpAssmts !== 0) {
      setFttlSpAssmtsError("Required")
      errors.push(true);
    } else {
      setFttlSpAssmtsError("");
    }
    if (!reason || reason.length === 0) {
      setFreasonsSpAssmtsError("Required")
      errors.push(true);
    } else {
      setFreasonsSpAssmtsError("");
    }
    if (showRepairField) {
      if (!fremediateRepairInd ||fremediateRepairInd == '') {
        setFremediateRepairIndError("Required")
        errors.push(true);

      } else {
        setFremediateRepairIndError("")
      }
    }
    if (showOtherField) {
      if (!fotherReasonSpAssmts || fotherReasonSpAssmts == '') {
        setFotherReasonSpAssmtsError("Required");
        errors.push(true);
      } else {
        setFotherReasonSpAssmtsError("")
      }
    }
    if (fnoOfUnitsDelinquent === "") {
      setFnoOfUnitsDelinquentError("Required");
      errors.push(true);
    } else if((!/^[0-9]*$/i.test(fnoOfUnitsDelinquent))) {
      setFnoOfUnitsDelinquentError("Invalid number");
      errors.push(true);
    } else if (parseInt(fnoOfUnitsDelinquent) > totalUnits) {
      setFnoOfUnitsDelinquentError("Units Delinquent cannot be greater than Total # of Units");
      errors.push(true);
    } else {
      setFnoOfUnitsDelinquentError("");
    }
    let data = {
      fttlSpAssmts: fttlSpAssmts,
      freasonsSpAssmts: reason,
      fotherReasonSpAssmts: fotherReasonSpAssmts,
      fremediateRepairInd: fremediateRepairInd,
      fnoOfUnitsDelinquent: fnoOfUnitsDelinquent,
    };
    updateSpecialAssessmentData(data, index, errors.length > 0 ? false : true);
  }

  const handleCurrencyChange = (name, value) => {
    let validForm = "false";
    value = value ? parseInt(value) : value;
    if (!value) {
      setFttlSpAssmtsError("Required");
    } else {value
      setFttlSpAssmtsError("");
    }
    let data = {
      fttlSpAssmts: fttlSpAssmts,
      freasonsSpAssmts: reason,
      fotherReasonSpAssmts: fotherReasonSpAssmts,
      fremediateRepairInd: fremediateRepairInd,
      fnoOfUnitsDelinquent: fnoOfUnitsDelinquent,
    }
    data[name] = value;
    setFormData(data);
    if (fttlSpAssmtsError == "" && freasonsSpAssmtsError == "" && fotherReasonSpAssmtsError == "" && fremediateRepairIndError == "" && fnoOfUnitsDelinquentError == "") {
      validForm = true;
    } else {
      validForm = false;
    }
    updateSpecialAssessmentData(data, index, validForm);
  };

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputCurrency: {
      backgroundColor: "#FCFCFC!important",
      borderRadius: "4px",
      height: "3px",
    },
  }));
  const classes = useStyles();
  const getIndex = (value) => {
    let index = '';
    switch (value) {
      case 0:
        index = "first";
        break;
      case 1:
        index = 'second';
        break;
      case 2:
        index = 'third';
        break;
      case 3:
        index = 'fourth';
        break;
      case 4:
        index = 'fifth';
        break;
      default:
        index = "";
        break;
    }
    return index;
  };

  return (
    <React.Fragment>
      <Accordion defaultActiveKey="1">
        <Card className="accordion-header-card">
          <Card.Header className="accordion-header-space">
            <ContextAwareToggle eventKey={collapseKey}
              onClick={() => { setCollapseKey(collapseKey === "1" ? "0" : "1") }}>
              <p className="accordion-header-title">{"Special Assessment" + " " + (index + 1)}</p>
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={collapseKey}>
            <Card.Body
              style={{
                height: "auto",
                width: "auto",
                border: "0px solid #dee1e2",
                backgroundColor: "#ffffff",
                overflow: "auto",
                padding: "0px 0px 20px 0px !important"
              }}>

              <div className="question"
                style={{
                  marginTop: "20px"
                }}>
                <div className="number">1.</div>{" "}
                <div> What is the original total amount of the {" " + getIndex(index) + " "} special assessment for the entire project (not per unit) when it was issued?</div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="answer">
                  <CurrencyInput
                    id="fttlSpAssmts"
                    name="fttlSpAssmts"
                    className="form-control"
                    value={fttlSpAssmts}
                    prefix='$'
                    onValueChange={(value, name) => {
                      setFttlSpAssmts(value ? parseInt(value) : value);
                      handleCurrencyChange(name, value);
                    }
                    }
                  />
                  {fttlSpAssmtsError != "" ? (
                    <p style={{ color: "red", margin: "0px", fontSize: "0.75rem" }}>{fttlSpAssmtsError}</p>
                  ) : <></>
                  }
                </div>
              </div>
              <div className="question">
                <div className="number">2.</div>{" "}
                <div>What is the reason for the special assessment?  </div>
              </div>
              <div style={{ display: "flex" }} >
                <div className="answer"
                  style={{
                    width: "600px"
                  }}
                >
                  <ReactSelect
                    name="freasonsSpAssmts"
                    value={reason}
                    options={reasonOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(value) => {
                      setReason(value);
                      handleResonChange(value);
                    }}
                    allowSelectAll={false}
                  />
                  {freasonsSpAssmtsError != "" ? (
                    <p style={{ color: "red", margin: "0px", fontSize: "0.75rem" }}>{freasonsSpAssmtsError}</p>
                  ) : null}
                </div>
              </div>
              {showOtherField === true ? (
                <><div className="question">
                  <div
                    className="number"
                    style={{
                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                      marginLeft: "37px",
                      marginBottom: "10px",
                    }}
                  ></div>{" "}
                  <div style={{ paddingTop: "10px" }}>
                    Other Reason?
                  </div>
                </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <input
                        id="fotherReasonSpAssmts"
                        name="fotherReasonSpAssmts"
                        className="form-control"
                        maxLength="250"
                        value={fotherReasonSpAssmts}
                        onChange={(event) => {
                          setFotherReasonSpAssmts(event.target.value)
                          handleChange(event);
                        }}
                      />
                      {fotherReasonSpAssmtsError != "" ? (
                        <p style={{ color: "red", margin: "0px", fontSize: "0.75rem" }}>{fotherReasonSpAssmtsError}</p>
                      ) : null}
                    </div>
                  </div></>) : <></>}
              {showRepairField === true ? (
                <>
                  <div className="question">
                    <div
                      className="number"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                        marginLeft: "37px",
                        marginBottom: "10px",
                      }}
                    ></div>{" "}
                    <div style={{ paddingTop: "10px" }}>
                      Have all necessary repairs been fully complete to remediate the critical repair issued?
                    </div>
                  </div>
                  <div className="answer" style={{ paddingLeft: "86px" }}>
                    <RadioGroup
                      row
                      value={fremediateRepairInd}
                      aria-label="position"
                      name="fremediateRepairInd"
                      style={{ fontSize: "14px !important" }}
                      onChange={(event) => {
                        setFremediateRepairInd(event.target.value);
                        handleChange(event);
                      }}
                      size="small"
                      className="splAssmt"
                    >
                      <FormControlLabel
                        key="Yes"
                        style={{ fontSize: "14px !important" }}
                        value="Yes"
                        control={<RadioInput />}
                        label="Yes"
                        labelPlacement="end"
                        classes={radioClasses}

                      />
                      <FormControlLabel
                        key="No"
                        style={{ fontSize: "14px !important" }}
                        value="No"
                        control={<RadioInput />}
                        label="No"
                        labelPlacement="end"
                        className="label"
                        classes={radioClasses}

                      />
                    </RadioGroup>
                    {fremediateRepairIndError != "" ? (
                      <p style={{ color: "red", margin: "0px", fontSize: "0.75rem" }}>{fremediateRepairIndError}</p>
                    ) : null}
                  </div>
                </>) : <></>}
              <div className="question">
                <div className="number">3.</div>{" "}
                <div>How many units are ≥ 60 days past due on their special assessment payment as described in                       <a
                  target="_blank"
                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                >
                  {" "}
                  B4-2.2-02
                </a>?</div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="answer">
                  <input
                    id="fnoOfUnitsDelinquent"
                    name="fnoOfUnitsDelinquent"
                    className="form-control"
                    maxLength="250"
                    min="0"
                    type= "number"
                    value={fnoOfUnitsDelinquent}
                    onChange={(event) => {
                      setFnoOfUnitsDelinquent(event.target.value ? parseInt(event.target.value) : event.target.value)
                      handleChange(event);
                    }}
                  />
                  {fnoOfUnitsDelinquentError != "" ? (
                    <p style={{ color: "red", margin: "0px", fontSize: "0.75rem" }}>{fnoOfUnitsDelinquentError}</p>
                  ) : null}
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </React.Fragment>
  );
};

export default SplAssmt;


import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import "./Cards.css";
import axios from "axios";
import NewCard from "../../images/card-new.png";
import EstCard from "../../images/card-est.png";

const IndividualCard = ({ project }) => {
  const {
    projectName,
    street,
    city,
    state,
    phaseId,
    mainPicId,
    projectId,
    newProjectType,
  } = project;
  const [href, setHref] = useState("");
  React.useEffect(() => {
    redirectUrl();
  }, []);

  async function redirectUrl() {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getProjectDetailsByProjectId/${projectId}`
      )
      .then((response) => {
        if (response) {
          if (response.status === 500 || response.status === 502) {
            setHref(`/error-page`);
          } else if (response.data === `Cannot find project:${projectId}`) {
            console.warn(response.data);
            delete localStorage[`project${projectId}`];
          } else {
            setHref(`/project/${projectId}`);
          }
        } else {
          setHref(`/project-error-page`);
        }
      })
      .catch((error) => {
        setHref(`/error-page`);
      });
  }

  return (
    <a href={href}>
      <Card className="individual-card" style={{ borderColor: "#d1d1d1" }}>
        {newProjectType === "New Condo" ? (
          <Card.Img
            alt="New Condo Image"
            className="card-image"
            variant="top"
            src={NewCard}
          />
        ) : newProjectType === "Established Condo" ? (
          <Card.Img
            alt="Established Condo Image"
            className="card-image"
            variant="top"
            src={EstCard}
          />
        ) : (
          <></>
        )}
        <Card.Body className="card-body">
          <div className="grid-container">
            <Card.Text className="grid-item card-project-name">
              {projectName}
            </Card.Text>
            <Card.Text className="grid-item card-street-name">
              {street}
            </Card.Text>
            <Card.Text className="grid-item card-project-id-text">
              Project Id
            </Card.Text>
            <Card.Text className="grid-item card-city-name">
              {city}, {state}
            </Card.Text>
            <Card.Text className="grid-item card-project-id">
              {projectId}
            </Card.Text>
          </div>
          {/* <div className="card-left-body">
            <Card.Text className="card-project-name">{projectName}</Card.Text>
            <Card.Text className="card-street-name">{street}</Card.Text>
            <Card.Text className="card-city-name">{city}, {state}</Card.Text>
          </div>
          <div className="card-right-body">
            <Card.Text className="card-project-id-text">{"Project ID              "}</Card.Text>
            <Card.Text className="card-project-id">{"                                                     "}{projectId}</Card.Text>
          </div> */}
        </Card.Body>
      </Card>
    </a>
  );
};

export default IndividualCard;

import React from 'react';
import ProjectRestrictions from './ProjectRestrictions';


const RestrictionsProject = ({ list }) => {
  let restrictionsList = <h3>Loading...</h3>;

  if (list) {
    restrictionsList = list.map((m, i) => <ProjectRestrictions key={i} item={m} />);
  }

  return (
    <div >
        {restrictionsList}
    </div>
  )
}
export default RestrictionsProject;
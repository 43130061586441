import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Modal from "react-bootstrap/Modal";

const AddModalRest = ({ handleAddClose, handleAddClick, showAdd }) => {
  const [referenceName, setReferenceName] = useState("");
  const [referenceDesc, setReferenceDesc] = useState("");
  const [referenceNameErr, setReferenceNameErr] = useState("");
  const [referenceDescErr, setReferenceDescErr] = useState("");

  const handleSubmit = () => {
    if (!referenceName) {
      setReferenceNameErr("Required");
    } else if (!referenceDesc) {
      setReferenceDescErr("Required");
    } else {
      let tab = RegExp("\\t|\\n", "g");
      let name = referenceName.toString().replace(tab, " ");
      let desc = referenceDesc.toString().replace(tab, " ");
      handleAddClick({
        referenceName: name,
        referenceDesc: desc,
      });

      setReferenceName("");
      setReferenceDesc("");
    }
  };

  return (
    <Modal
      show={showAdd}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleAddClose}
    >
      <Modal.Header className="the-modal-header" closeButton>
        <Add
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "25px",
            width: "25px",
          }}
        />
        <Modal.Title className="the-modal-title">
          Add Custom Restriction
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
        Enter Information below to add a new restriction to your decision
        letter.
        <div className="field">
          <div className="field-label">
            Name of Restriction <span className="mendatory-sign">*</span>
          </div>
          <Grid item xs={12}>
            <input
              required
              type="text"
              className="form-control"
              name="referenceName"
              onChange={(event) => setReferenceName(event.target.value)}
            />
            <div className="mendatory-sign">{referenceNameErr}</div>
          </Grid>
        </div>
        <div className="field">
          <div className="field-label">
            Restriction Content <span className="mendatory-sign">*</span>
          </div>
          <Grid item xs={12}>
            <textarea
              style={{
                width: "100%",
                minHeight: "100px",
              }}
              onChange={(event) => setReferenceDesc(event.target.value)}
            ></textarea>
            <div className="mendatory-sign">{referenceDescErr}</div>
          </Grid>
        </div>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer">
        <Button className="go-back-button" onClick={handleAddClose}>
          Cancel
        </Button>
        <Button className="cancel-modal-button" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModalRest;

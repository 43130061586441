import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaTimes,
} from "react-icons/fa";
import Add from "@material-ui/icons/Add";
import { Formik, Form, Field } from "formik";
import AddModal from "./add-modal";
import FormStepper from "../FormStepper";
import CannedSection from "./canned-section";
import CannedPhase from "./canned-phase";
import AddModalPhase from "./add-modal-phase";
// import CancelModal from "../ProjectStatusFinal/cancel-modal";

export const EnterCondition = ({
  nextStep,
  nextStep2,
  nextStep3,
  nextStep4,
  prevStep,
  formData,
  setFormData,
  persId,
  projectId,
  step,
}) => {
  const [activeTab, setActiveTab] = useState("pre");
  const [showAdd, setShowAdd] = useState(false);
  const [showAddPhase, setShowAddPhase] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [conditionErr, setConditionErr] = useState();
  const [singleCondition, setSingleCondition] = useState(false);

  React.useEffect(() => {
    console.log("phase data", formData);
    setSingleCondition(formData.applySamePhaseConditions);
  }, []);

  const { addedConditions = [], perCondtnsRef = [] } = FormData;
  const [phIndex, setPhIndex] = useState();
  const handleAddPhaseClose = () => setShowAddPhase(false);
  const handleAddPhaseShow = (i) => {
    setPhIndex(i);
    setShowAddPhase(true);
  };
  const handleAddPhaseClick = (newCondition, index) => {
    if (formData.applySamePhaseConditions) {
      formData.phaseDecisions &&
        formData.phaseDecisions.map((phase, index) => {
          if (
            phase.persDecison == "Conditional/Available" ||
            phase.persDecison == "Conditional/Unavailable"
          ) {
            const _formData = {
              ...phase,
              addedConditions: [
                ...(phase.addedConditions || []),
                { ...newCondition },
              ],
            };
            formData.phaseDecisions[index] = _formData;
          }
        });
    } else {
      const _formData = {
        ...formData.phaseDecisions[index],
        addedConditions: [
          ...(formData.phaseDecisions[index].addedConditions || []),
          { ...newCondition },
        ],
      };
      formData.phaseDecisions[index] = _formData;
    }
    setFormData(formData);
    setShowAddPhase(false);
    setSinglePhase();
  };

  const handleAddClose = () => setShowAdd(false);
  const handleAddClick = (newCondition) => {
    const _formData = {
      ...formData,
      addedConditions: [
        ...(formData.addedConditions || []),
        { ...newCondition },
      ],
    };
    setFormData(_formData);
    setShowAdd(false);
  };
  const handleAddShow = () => setShowAdd(true);

  const setConditionsData = (_conditions, name) => {
    const _formData = { ...formData, [name]: [..._conditions] };
    setFormData(_formData);
    if (!_formData.perCondtnsRef.some((c) => c.isEdit === true)) {
      setConditionErr("");
    }
  };

  const setConditionsPhaseData = (_conditions, name, index) => {
    if (formData.applySamePhaseConditions) {
      formData.phaseDecisions &&
        formData.phaseDecisions.map((phase, index) => {
          if (
            phase.persDecison == "Conditional/Available" ||
            phase.persDecison == "Conditional/Unavailable"
          ) {
            const _formData = {
              ...phase,
              [name]: [..._conditions],
            };
            formData.phaseDecisions[index] = _formData;
          }
        });
      setFormData(formData);
    } else {
      const _formData = {
        ...formData.phaseDecisions[index],
        [name]: [..._conditions],
      };
      formData.phaseDecisions[index] = _formData;
      setFormData({ ...formData, perCondtnsRef: _conditions });
    }
    if (!formData.perCondtnsRef.some((c) => c.isEdit === true)) {
      setConditionErr("");
    }
    setSinglePhase();
  };

  function changeTab(params) {
    setActiveTab(params);
  }

  function changeTabPhase(params, index) {
    index = formData.applySamePhaseConditions ? getSinglePhaseIndex() : index;
    formData.phaseDecisions[index].selectedTab = params;
    setFormData((prevExpiry) => ({
      ...prevExpiry,
      formData,
    }));
  }

  function resetRestrictions() {
    for (let restriction of formData.restrictions) {
      restriction.isSelected = false;
    }

    if (formData.addedRestrictions) {
      for (let addRestriction of formData.addedRestrictions) {
        addRestriction.isSelected = false;
      }
    }
  }

  function checkRedirection(data) {
    if (data.persDecisionStatus == "Conditional/Available") {
      nextStep();
    } else if (data.persDecisionStatus == "Conditional/Unavailable") {
      nextStep4();
      resetRestrictions();
    }
  }

  function checkPhaseRedirection(data) {
    let IsConditionAvail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Available";
    });

    let IsConditionUnavail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Unavailable";
    });

    let IsFinalApproval = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Final Project Approval";
    });

    let IsSuspention = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Suspension of the Application";
    });

    let IsIneligible = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    if (IsConditionAvail) {
      nextStep();
    } else if (IsFinalApproval) {
      nextStep();
    } else if (IsSuspention) {
      nextStep2();
    } else if (IsIneligible) {
      nextStep3();
    } else if (IsConditionUnavail) {
      nextStep4();
    }
  }

  const getCheckedPhase = (phase) => {
    let isSelected;
    if (phase.perCondtnsRef) {
      isSelected = phase.perCondtnsRef.find((eachCond) => {
        if (eachCond.isSelected) {
          return true;
        }
      });
    }
    if (phase.addedConditions.length && !isSelected) {
      isSelected = phase.addedConditions.find((e) => e.isSelected === true);
    }
    return Boolean(isSelected);
  };

  const getPhaseConditions = () => {
    const formPhaseDes = formData.phaseDecisions.filter((eachpers) =>
      ["Conditional/Available", "Conditional/Unavailable"].includes(
        eachpers.persDecison
      )
    );
    const getCond = formPhaseDes.map((phase) => {
      return getCheckedPhase(phase);
    });
    return getCond;
  };

  const setSingleConditionalData = () => {
    setSingleCondition(!singleCondition);
    if (singleCondition) {
      formData.phaseDecisions &&
        formData.phaseDecisions.map((phase, index) => {
          if (
            phase.persDecison == "Conditional/Available" ||
            phase.persDecison == "Conditional/Unavailable"
          ) {
            const _formData = {
              ...phase,
              addedConditions: [],
              perCondtnsRef: resetConditions(phase.perCondtnsRef),
            };
            formData.phaseDecisions[index] = _formData;
          }
        });
    }
    formData.singlePhaseCondition = getValidCondition();
    formData.applySamePhaseConditions = singleCondition;
    setFormData(formData);
  };

  const resetConditions = (conditions) => {
    for (let condition of conditions) {
      condition.isSelected = false;
    }
    return conditions;
  };

  const getValidCondition = () => {
    return formData.phaseDecisions.filter(
      (phase) =>
        phase.persDecison == "Conditional/Available" ||
        phase.persDecison == "Conditional/Unavailable"
    )[0];
  };

  const setSinglePhase = () => {
    formData.singlePhaseCondition = getValidCondition();
    setFormData(formData);
  };

  const getSinglePhaseIndex = () => {
    console.log(formData.singlePhaseCondition);

    let i = formData.phaseDecisions.findIndex(
      (phase) => phase.phaseName === formData.singlePhaseCondition.phaseName
    );
    console.log(i);
    return i;
  };

  return (
    <div className="parent-form">
      <FormStepper step={step} />
      <Grid container spacing={2} className="dialoguClass">
        <Grid item xs={9}>
          <Grid item xs={12}>
            <div
              className="top-title-container"
              style={{ marginBlock: 30, padding: 0 }}
            >
              <div className="big-title">Add New PERS Decision</div>
              <div className="small-title">For PERS ID: {persId}</div>
            </div>
          </Grid>
          <div className="section">
            <Grid item xs={12}>
              <div className="section-title">
                Step 3 : Apply Conditions
                {formData.phaseDecisions?.filter(
                  (phase) =>
                    phase.persDecison == "Conditional/Available" ||
                    phase.persDecison == "Conditional/Unavailable"
                ).length > 1 ? (
                  <>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        float: "right",
                      }}
                    >
                      <input
                        type="checkbox"
                        className={"checkboxfield"}
                        id={"setSingleCondition"}
                        name={"setSingleCondition"}
                        checked={formData.applySamePhaseConditions}
                        onChange={(event) => {
                          setSingleConditionalData();
                        }}
                      />
                      <div style={{ fontSize: 14, marginLeft: 7 }}>
                        Apply same Conditions to all phases
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
            <Formik
              initialValues={formData}
              enableReinitialize
              onSubmit={() => {
                if (
                  formData.decisionLevel == "Entire project" ||
                  formData.decisionLevel == "Entire Project"
                ) {
                  let preConSelect = [];
                  if (formData.perCondtnsRef) {
                    preConSelect = formData.perCondtnsRef.filter(
                      (c) => c.isSelected
                    );
                  }

                  let addedConSelect = [];
                  if (formData.addedConditions) {
                    addedConSelect = formData.addedConditions.map(
                      ({ conditionName, conditionDesc }) => ({
                        conditionName,
                        conditionDesc,
                        isAddedCondn: true,
                      })
                    );
                  }

                  if (preConSelect.length == 0 && addedConSelect.length == 0) {
                    setConditionErr("Select at least one condition");
                  } else {
                    if (preConSelect.some((c) => c.isEdit === true)) {
                      setConditionErr("Please save or cancel the condition");
                    } else {
                      setConditionErr("");
                      checkRedirection(formData);
                    }
                  }
                } else {
                  if (getPhaseConditions().includes(false)) {
                    setConditionErr("Select at least one condition");
                  } else {
                    if (formData.perCondtnsRef.some((c) => c.isEdit === true)) {
                      setConditionErr("Please save or cancel the condition");
                    } else {
                      setConditionErr("");
                      checkPhaseRedirection(formData);
                    }
                  }
                }
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  {formData.decisionLevel == "Entire project" ||
                  formData.decisionLevel == "Entire Project" ? (
                    <Grid item xs={12}>
                      <div
                        className="btn-tab-container"
                        style={{ borderTop: 0 }}
                      >
                        <Grid
                          item
                          xs={12}
                          className="condition-container"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "20px",
                          }}
                        >
                          <div
                            className="title-txt"
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <i className="fa fa-info info-icon"></i>
                            <span className="con-txt">Conditions</span>{" "}
                            <div style={{ clear: "both" }}></div>
                          </div>
                          <div>
                            <Button onClick={handleAddShow} className="button">
                              <Add
                                style={{
                                  color: "#0F5AF0",
                                }}
                              />{" "}
                              Add New
                            </Button>
                          </div>
                        </Grid>
                        <div className="tab-view-container">
                          <Button
                            className={
                              activeTab == "pre"
                                ? "tab-title active-tab"
                                : "tab-title"
                            }
                            onClick={() => changeTab("pre")}
                          >
                            Pre-canned ({formData.perCondtnsRef.length})
                          </Button>
                          <Button
                            className={
                              activeTab == "add"
                                ? "tab-title active-tab"
                                : "tab-title"
                            }
                            onClick={() => changeTab("add")}
                          >
                            Added-Conditions ({formData.addedConditions.length})
                          </Button>
                        </div>
                      </div>
                      <div style={{ height: "500px", overflowY: "auto" }}>
                        {activeTab == "pre" ? (
                          <CannedSection
                            formData={formData}
                            name="perCondtnsRef"
                            setConditionsData={setConditionsData}
                            isPre
                          />
                        ) : (
                          <CannedSection
                            formData={formData}
                            name="addedConditions"
                            setConditionsData={setConditionsData}
                          />
                        )}
                      </div>
                      <div className="mendatory-sign">{conditionErr}</div>

                      <AddModal
                        showAdd={showAdd}
                        handleAddClose={handleAddClose}
                        handleAddClick={handleAddClick}
                        formData={formData}
                      />
                    </Grid>
                  ) : formData.applySamePhaseConditions ? (
                    <>
                      {" "}
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: 20 }}
                      >
                        <Grid
                          item
                          xs={2}
                          style={{ padding: 10, marginTop: 180 }}
                        >
                          {formData.phaseDecisions.map((phase) =>
                            phase.persDecison == "Conditional/Available" ||
                            phase.persDecison == "Conditional/Unavailable" ? (
                              <>
                                <div>{phase.phaseName}</div>
                              </>
                            ) : (
                              <></>
                            )
                          )}{" "}
                        </Grid>
                        <Grid item xs={10}>
                          <div
                            className="btn-tab-container"
                            style={{ borderTop: 0 }}
                          >
                            <Grid
                              item
                              xs={12}
                              className="condition-container"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "20px",
                              }}
                            >
                              <div
                                className="title-txt"
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <i className="fa fa-info info-icon"></i>
                                <span className="con-txt">Conditions</span>{" "}
                                <div style={{ clear: "both" }}></div>
                              </div>
                              <div>
                                <Button
                                  onClick={() =>
                                    handleAddPhaseShow(getSinglePhaseIndex)
                                  }
                                  className="button"
                                >
                                  <Add
                                    style={{
                                      color: "#0F5AF0",
                                    }}
                                  />{" "}
                                  Add New
                                </Button>
                              </div>
                            </Grid>
                            <div className="tab-view-container">
                              <Button
                                className={
                                  formData.singlePhaseCondition.selectedTab ==
                                  "pre"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() =>
                                  changeTabPhase("pre", getSinglePhaseIndex)
                                }
                              >
                                Pre-canned (
                                {formData.singlePhaseCondition.perCondtnsRef
                                  ? formData.singlePhaseCondition.perCondtnsRef
                                      .length
                                  : 0}
                                )
                              </Button>
                              <Button
                                className={
                                  formData.singlePhaseCondition.selectedTab ==
                                  "add"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() =>
                                  changeTabPhase("add", getSinglePhaseIndex)
                                }
                              >
                                Added-Conditions (
                                {formData.singlePhaseCondition.addedConditions
                                  ? formData.singlePhaseCondition
                                      .addedConditions.length
                                  : 0}
                                )
                              </Button>
                            </div>
                          </div>
                          <div style={{ height: "500px", overflowY: "auto" }}>
                            {formData.singlePhaseCondition.selectedTab ==
                            "pre" ? (
                              <CannedPhase
                                formData={formData.singlePhaseCondition}
                                name="perCondtnsRef"
                                setConditionsPhaseData={setConditionsPhaseData}
                                phaseIndex={getSinglePhaseIndex}
                                isPre
                              />
                            ) : (
                              <CannedPhase
                                formData={formData.singlePhaseCondition}
                                name="addedConditions"
                                setConditionsPhaseData={setConditionsPhaseData}
                                phaseIndex={getSinglePhaseIndex}
                              />
                            )}
                          </div>
                          <div className="mendatory-sign">{conditionErr}</div>

                          <AddModalPhase
                            showAddPhase={showAddPhase}
                            handleAddPhaseClose={handleAddPhaseClose}
                            handleAddPhaseClick={handleAddPhaseClick}
                            formData={formData}
                            phaseIndex={phIndex}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    formData.phaseDecisions &&
                    formData.phaseDecisions.map((phase, index) =>
                      phase.persDecison == "Conditional/Available" ||
                      phase.persDecison == "Conditional/Unavailable" ? (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", marginBottom: 20 }}
                        >
                          <Grid
                            item
                            xs={2}
                            style={{ padding: 10, marginTop: 180 }}
                          >
                            {phase.phaseName}
                          </Grid>
                          <Grid item xs={10}>
                            <div
                              className="btn-tab-container"
                              style={{ borderTop: 0 }}
                            >
                              <Grid
                                item
                                xs={12}
                                className="condition-container"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "20px",
                                }}
                              >
                                <div
                                  className="title-txt"
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <i className="fa fa-info info-icon"></i>
                                  <span className="con-txt">
                                    Conditions
                                  </span>{" "}
                                  <div style={{ clear: "both" }}></div>
                                </div>
                                <div>
                                  <Button
                                    onClick={() => handleAddPhaseShow(index)}
                                    className="button"
                                  >
                                    <Add
                                      style={{
                                        color: "#0F5AF0",
                                      }}
                                    />{" "}
                                    Add New
                                  </Button>
                                </div>
                              </Grid>
                              <div className="tab-view-container">
                                <Button
                                  className={
                                    phase.selectedTab == "pre"
                                      ? "tab-title active-tab"
                                      : "tab-title"
                                  }
                                  onClick={() => changeTabPhase("pre", index)}
                                >
                                  Pre-canned (
                                  {phase.perCondtnsRef
                                    ? phase.perCondtnsRef.length
                                    : 0}
                                  )
                                </Button>
                                <Button
                                  className={
                                    phase.selectedTab == "add"
                                      ? "tab-title active-tab"
                                      : "tab-title"
                                  }
                                  onClick={() => changeTabPhase("add", index)}
                                >
                                  Added-Conditions (
                                  {phase.addedConditions
                                    ? phase.addedConditions.length
                                    : 0}
                                  )
                                </Button>
                              </div>
                            </div>
                            <div style={{ height: "500px", overflowY: "auto" }}>
                              {phase.selectedTab == "pre" ? (
                                <CannedPhase
                                  formData={phase}
                                  name="perCondtnsRef"
                                  setConditionsPhaseData={
                                    setConditionsPhaseData
                                  }
                                  phaseIndex={index}
                                  isPre
                                />
                              ) : (
                                <CannedPhase
                                  formData={phase}
                                  name="addedConditions"
                                  setConditionsPhaseData={
                                    setConditionsPhaseData
                                  }
                                  phaseIndex={index}
                                />
                              )}
                            </div>
                            <div className="mendatory-sign">{conditionErr}</div>

                            <AddModalPhase
                              showAddPhase={showAddPhase}
                              handleAddPhaseClose={handleAddPhaseClose}
                              handleAddPhaseClick={handleAddPhaseClick}
                              formData={formData}
                              phaseIndex={phIndex}
                            />
                          </Grid>
                        </Grid>
                      ) : null
                    )
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px",
                      width: "100%",
                      marginTop: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <Button
                      className="previous-button"
                      onClick={() => prevStep()}
                    >
                      Previous Section
                    </Button>
                    {/* onClick={incrementCounter} */}
                    <Button type="submit" className="next-button next-btn-save">
                      Next Section
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
        <Grid item xs={3}>
          {/* <button
            className="delete-modal-button"
            style={{
              float: "right",
              border: 0
            }}
            onClick={() => openCancelPopup(true)}
          >
            <FaTimes
              className="edit-profile-icon"
              style={{
                marginRight: 5
              }}
            />{" "}
            Cancel
          </button> */}
        </Grid>
      </Grid>
    </div>
  );
};

// export default EnterCondition;

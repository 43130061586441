
import React from "react";
import FormStepper from "../stepper";
import { Formik, Form, Field } from "formik";
import { Button, Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';





export const Page3 = ({ formData, step }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
        <>
          <Formik
            initialValues={formData}
          >
          {({ values }) => (
            <Form className="parent-form">
                <FormStepper step={step}/>
                <div className="parent-field-container">
                <div className="field-container">
                  <div className="top-title-container">
                    <div className="big-title">Add Child Phase</div>
                    <div className="small-title">Phase ID: {formData.phaseId}</div>
                  </div>
              
                  <div className="success-lower-container">
                    
                    <div>
                        <CheckCircleOutlineRoundedIcon className="the-green-check"/>
                        <div className="success-header">Child Phase Created Successfully!</div>
                        <div className="sub-success-header">Your Child Phase has been added to the Construction/Marketing Phases section of the Phase page.</div>
                        <Grid
                            item
                            xs={12}
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "16px"
                            }}
                        >
                            <Link
                                to={`/project/phase/${formData.phaseId}`}
                                style={{ margin: "16px" }}
                                replace
                            >
                                <Button
                                className="cancel-modal-button"
                                onClick={() => {
                                    setTimeout(() => {
                                      if(window.location.pathname !== "/error-page"){
                                        console.log("page 3")
                                        window.location.reload();
                                      }
                                    }, 500);
                                }}
                                >
                                Close
                                </Button>
                            </Link>
                        </Grid>
                    </div>
                </div>
              </div>
              </div>
              <div className="right-cancel-container"></div>
            </Form>
          )}
          </Formik>
        </>
      );

}
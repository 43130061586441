export const FILTER_RESULTS = "FILTER_RESULTS";
export const STATE_RESULTS = "STATE_RESULTS";
export const TYPE1026_RESULTS = "TYPE1026_RESULTS";
export const RIGHTS1026_RESULTS = "RIGHTS1026_RESULTS";
export const STORIES1026_RESULTS = "STORIES1026_RESULTS";
export const STRUCTURES1026_RESULTS = "STRUCTURES1026_RESULTS";
export const PHASES1026_RESULTS = "PHASES1026_RESULTS";
export const CONSTRUCTIONS1026_RESULTS = "CONSTRUCTIONS1026_RESULTS";
export const CONVERSIONS1026_RESULTS = "CONVERSIONS1026_RESULTS";
export const UNITS1026_RESULTS = "UNITS1026_RESULTS";
export const STYLE_RESULTS = "STYLE_RESULTS";
export const TYPE_RESULTS = "TYPE_RESULTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const ADD_CONDO_INFO = "ADD_CONDO_INFO";
export const ADD_MASTER_ASSC_INFO = "ADD_MASTER_ASSC_INFO";
export const ADD_HOA_ASSC_INFO = "ADD_HOA_ASSC_INFO";
export const ADD_HOA_ASSC_ID = "ADD_HOA_ASSC_ID";
export const ADD_MASTER_ASSC_ID = "ADD_MASTER_ASSC_ID";
export const PHASE_STATUS_RESULTS = "PHASE_STATUS_RESULTS";
export const PHASE_TYPE_RESULTS = "PHASE_TYPE_RESULTS";
export const ADD_PERS_PROJECT_INFO = "ADD_PERS_PROJECT_INFO";
export const SUBMISSION_TYPES_RESULT = "SUBMISSION_TYPES_RESULT";
export const INITIATE_ADD_NEW_PROJECT = "INITIATE_ADD_NEW_PROJECT";

export const EDIT_PERS_INFO = "EDIT_PERS_INFO";
export const USER_DETAIL_RESULT = "USER_DETAIL_RESULT";
export const ROLE_DETAIL_RESULT = "ROLE_DETAIL_RESULT";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

function getSteps() {
  return [
    "Lender and Project Details",
    "Application for Project Approval (1026)",
    "Warranty for Project Presales (1029)",
    "Statement of Insurance and Fidelity Coverage (1071)",
    "Additional Documents",
    "Review PERS input",
    "Confirm PERS creation​",
    // "Issue PERS Decision",
    "Attachments",
    "Confirm Attachment Upload​"
  ];
}
// 'PERS Status',

export default function FormStepper(props) {
  const { step } = props;
  const steps = getSteps();
  return (
    <div className="step-container">
      <Stepper className="step" activeStep={step} orientation="vertical">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

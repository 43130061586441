import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import { List, ListItem, ListItemText } from "@material-ui/core/";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";

export const Review = ({
  formData,
  nextStep,
  nextStep2,
  prevStep,
  step,
  setPersId,
}) => {
  //   const [direction, setDirection] = useState('back');

  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const {
    projectId,
    phaseId,
    persStatus,
    persStatusJustification,
    receivedDate,
    lenderName,
    assgndRiskAnalyst,
    lenderSSN,
    lenderAddress,
    lenderCity,
    lenderState,
    lenderZip,
    requesterName,
    requesterEmail,
    projLegalName,
    projPhyAddr,
    hoaMgmtAddr,
    hoaName,
    hoaTaxId,
    hoaMgmtCmpnyTaxId,
    mstrAssocName,
    rentalPoolngArrngmntInd,
    deedResaleRestrctnInd,
    mfHomeInd,
    mndtryMbrshipInd,
    nonIncdntlIncomeInd,
    supprtCareSeniorsInd,
    proj100pctCompltnInd,
    subjAddtnlPhasingInd,
    legallyPhasedInd,
    noOfPhasesCmpltd,
    totalPhasesLegallyPlanned,
    totalUnitsPlanned,
    plannedAmenitiesCompltnInd,
    trnsfrControlHoaInd,
    dtTrnsfrControlHoa,
    convrsnOfExistngStructureInd,
    yrBuilt,
    yrConvrtd,
    fullGutRehabConvrsnInd,
    structrllySoundInd,
    repairsCompltnInd,
    replacemntResrvsAlloctnInd,
    resrvsToFundImprovmntsInd,
    unitOwnrsDelinqCommnExpnseCnt,
    mrtgeeRespnbleDelinqExpnseInd,
    mrtgeeRespnbleDelinqExpnseTerm,
    hoaInvlvdLitigtnInd,
    litigAttrneyName,
    litigAttrneyPhone,
    totUnitsProj,
    totUnitsPhase,
    totUnitsSoldClosedProj,
    totUnitsSoldClosedPhase,
    totUnitsBonafidSalesProj,
    totUnitsBonafidSalesPhase,
    totUnitsOwnrOccpntProj,
    totUnitsOwnrOccpntPhase,
    totUnitsSecOwnrProj,
    totUnitsSecOwnrPhase,
    totUnitsInvstrOwnrProj,
    totUnitsInvstrOwnrPhase,
    totUnitsRentedProj,
    totUnitsRentedPhase,
    totUnitsOwnByHoaProj,
    totUnitsOwndByHoaPhase,
    soleOwnrshipIntrstAmenitiesInd,
    soleOwnrshipIntrstAmenitiesExpln,
    unitsUsedCommrclPurpseInd,
    totSqFootageCommSpace,
    floodZoneInd,
    floodCvrgInForceDesc,
    floodCvrgInForceDescOtherAmt,
    hoaAcctsSeparateOpratingReserveFundsInd,
    hoaAcctsAcessCntrlsInd,
    hoaAcctsMnthlyBankStmtsInd,
    hoaAcctsCheckSignedReqdMembersInd,
    hoaAcctsMngmtCmpnyAccts_maintnanceInd,
    hoaAcctsMngmtCmpnyAuthorityReqmntsInd,
    hzrdInsAgentName,
    hzrdInsAgentPhone,
    hzrdInsPlcyNbr,
    liabInsAgentName,
    liabInsAgentPhone,
    liabInsPlcyNbr,
    fidelInsAgentName,
    fidelInsAgentPhone,
    fidelInsPlcyNbr,
    floodInsAgentName,
    floodInsAgentPhone,
    floodInsPlcyNbr,
    preparerName,
    preparerTitle,
    preparerCmpnyName,
    preparerPhone,
    preparerEmail,
    preparerCmpnyAddr,
    dtCmpltd,
    commUse1076DataList,
    unitOwnrshp1076DataList,
    lndrName,
    sellrServcrNbr,
    purposeOfApplicn,
    dtExpryPriorApprval,
    projectLegalName,
    projectAddress,
    projectCity,
    projectState,
    projectZipCode,
    hoaFedTaxId,
    persReqSummary,
    authrzdRepName,
    authrzdRepTitle,
    authrzdRepEmail,
    authrzdRepPhone,
    dtAuthrzdRepSignature,
    preparerName1091,
    preparerTitle1091,
    preparerEmail1091,
    preparerPhone1091,
    dtPreparerSignature,
    propertyAddr,
    unitNumber,
    propertyCity,
    propertyState,
    propertyZipCode,
    county,
    legalDesc,
    reTaxes,
    projectName,
    specialAssmnt,
    hoaFees,
    femaSplFloodHzrdAreaInd,
    femaFloodZone,
    femaMapNbr,
    femaMapDate,
    projectDesc,
    nbrStoriesCnt,
    yrBuilt1073,
    effAge,
    nbrUnitsCnt,
    nbrUnitsRentedCnt,
    nbrUnitsOwnrOccpdCnt,
    convExstngBldgInd,
    convExstngBldgDesc,
    commSpaceInd,
    commSpaceDesc,
    subjPropAppraisdAddr,
    subjPropAppraisdVal,
    masterAssociationName,
    exceptions1051,
    masterAssociationNotes,
    incomeFromAssessments,
    reserves,
    percentageOfReservesOnHand,
    exceptionsBudget,
  } = formData;

  const establishedData = {
    perslen: {
      projectId: projectId,
      phaseId: phaseId,
      persStatus: persStatus,
      persStatusJustification: persStatusJustification,
      receivedDate: receivedDate,
      lenderName: lenderName,
      assgndRiskAnalyst: assgndRiskAnalyst,
      lenderSSN: lenderSSN,
      lenderAddress: lenderAddress,
      lenderCity: lenderCity,
      lenderState: lenderState,
      lenderZip: lenderZip,
      requesterName: requesterName,
      requesterEmail: requesterEmail,
      createdBy: passData.user,
    },
    pers1051: {
      masterAssociationName: masterAssociationName,
      exceptions1051: exceptions1051,
      masterAssociationNotes: masterAssociationNotes,
    },
    persBudget: {
      incomeFromAssessments: incomeFromAssessments,
      reserves: reserves,
      percentageOfReservesOnHand: percentageOfReservesOnHand,
      exceptionsBudget: exceptionsBudget,
    },
    pers1076: {
      projLegalName: projLegalName,
      projPhyAddr: projPhyAddr,
      hoaMgmtAddr: hoaMgmtAddr,
      hoaName: hoaName,
      hoaTaxId: hoaTaxId,
      hoaMgmtCmpnyTaxId: hoaMgmtCmpnyTaxId,
      mstrAssocName: mstrAssocName,
      rentalPoolngArrngmntInd: rentalPoolngArrngmntInd,
      deedResaleRestrctnInd: deedResaleRestrctnInd,
      mfHomeInd: mfHomeInd,
      mndtryMbrshipInd: mndtryMbrshipInd,
      nonIncdntlIncomeInd: nonIncdntlIncomeInd,
      supprtCareSeniorsInd: supprtCareSeniorsInd,
      proj100pctCompltnInd: proj100pctCompltnInd,
      subjAddtnlPhasingInd: subjAddtnlPhasingInd,
      legallyPhasedInd: legallyPhasedInd,
      noOfPhasesCmpltd: noOfPhasesCmpltd,
      totalPhasesLegallyPlanned: totalPhasesLegallyPlanned,
      totalUnitsPlanned: totalUnitsPlanned,
      plannedAmenitiesCompltnInd: plannedAmenitiesCompltnInd,
      trnsfrControlHoaInd: trnsfrControlHoaInd,
      dtTrnsfrControlHoa: dtTrnsfrControlHoa,
      convrsnOfExistngStructureInd: convrsnOfExistngStructureInd,
      yrBuilt: yrBuilt,
      yrConvrtd: yrConvrtd,
      fullGutRehabConvrsnInd: fullGutRehabConvrsnInd,
      structrllySoundInd: structrllySoundInd,
      repairsCompltnInd: repairsCompltnInd,
      replacemntResrvsAlloctnInd: replacemntResrvsAlloctnInd,
      resrvsToFundImprovmntsInd: resrvsToFundImprovmntsInd,
      unitOwnrsDelinqCommnExpnseCnt: unitOwnrsDelinqCommnExpnseCnt,
      mrtgeeRespnbleDelinqExpnseInd: mrtgeeRespnbleDelinqExpnseInd,
      mrtgeeRespnbleDelinqExpnseTerm: mrtgeeRespnbleDelinqExpnseTerm,
      hoaInvlvdLitigtnInd: hoaInvlvdLitigtnInd,
      litigAttrneyName: litigAttrneyName,
      litigAttrneyPhone: litigAttrneyPhone,
      totUnitsProj: totUnitsProj,
      totUnitsPhase: totUnitsPhase,
      totUnitsSoldClosedProj: totUnitsSoldClosedProj,
      totUnitsSoldClosedPhase: totUnitsSoldClosedPhase,
      totUnitsBonafidSalesProj: totUnitsBonafidSalesProj,
      totUnitsBonafidSalesPhase: totUnitsBonafidSalesPhase,
      totUnitsOwnrOccpntProj: totUnitsOwnrOccpntProj,
      totUnitsOwnrOccpntPhase: totUnitsOwnrOccpntPhase,
      totUnitsSecOwnrProj: totUnitsSecOwnrProj,
      totUnitsSecOwnrPhase: totUnitsSecOwnrPhase,
      totUnitsInvstrOwnrProj: totUnitsInvstrOwnrProj,
      totUnitsInvstrOwnrPhase: totUnitsInvstrOwnrPhase,
      totUnitsRentedProj: totUnitsRentedProj,
      totUnitsRentedPhase: totUnitsRentedPhase,
      totUnitsOwnByHoaProj: totUnitsOwnByHoaProj,
      totUnitsOwndByHoaPhase: totUnitsOwndByHoaPhase,
      soleOwnrshipIntrstAmenitiesInd: soleOwnrshipIntrstAmenitiesInd,
      soleOwnrshipIntrstAmenitiesExpln: soleOwnrshipIntrstAmenitiesExpln,
      unitsUsedCommrclPurpseInd: unitsUsedCommrclPurpseInd,
      totSqFootageCommSpace: totSqFootageCommSpace,
      floodZoneInd: floodZoneInd,
      floodCvrgInForceDesc: floodCvrgInForceDesc,
      floodCvrgInForceDescOtherAmt: floodCvrgInForceDescOtherAmt,
      hoaAcctsSeparateOpratingReserveFundsInd:
        hoaAcctsSeparateOpratingReserveFundsInd,
      hoaAcctsAcessCntrlsInd: hoaAcctsAcessCntrlsInd,
      hoaAcctsMnthlyBankStmtsInd: hoaAcctsMnthlyBankStmtsInd,
      hoaAcctsCheckSignedReqdMembersInd: hoaAcctsCheckSignedReqdMembersInd,
      hoaAcctsMngmtCmpnyAccts_maintnanceInd:
        hoaAcctsMngmtCmpnyAccts_maintnanceInd,
      hoaAcctsMngmtCmpnyAuthorityReqmntsInd:
        hoaAcctsMngmtCmpnyAuthorityReqmntsInd,
      hzrdInsAgentName: hzrdInsAgentName,
      hzrdInsAgentPhone: hzrdInsAgentPhone,
      hzrdInsPlcyNbr: hzrdInsPlcyNbr,
      liabInsAgentName: liabInsAgentName,
      liabInsAgentPhone: liabInsAgentPhone,
      liabInsPlcyNbr: liabInsPlcyNbr,
      fidelInsAgentName: fidelInsAgentName,
      fidelInsAgentPhone: fidelInsAgentPhone,
      fidelInsPlcyNbr: fidelInsPlcyNbr,
      floodInsAgentName: floodInsAgentName,
      floodInsAgentPhone: floodInsAgentPhone,
      floodInsPlcyNbr: floodInsPlcyNbr,
      preparerName: preparerName,
      preparerTitle: preparerTitle,
      preparerCmpnyName: preparerCmpnyName,
      preparerPhone: preparerPhone,
      preparerEmail: preparerEmail,
      preparerCmpnyAddr: preparerCmpnyAddr,
      dtCmpltd: dtCmpltd,
      commUse1076DataList: commUse1076DataList,
      unitOwnrshp1076DataList: unitOwnrshp1076DataList,
    },
    pers1091: {
      lndrName: lndrName,
      sellrServcrNbr: sellrServcrNbr,
      purposeOfApplicn: purposeOfApplicn,
      dtExpryPriorApprval: dtExpryPriorApprval,
      projectLegalName: projectLegalName,
      projectAddress: projectAddress,
      projectCity: projectCity,
      projectState: projectState,
      projectZipCode: projectZipCode,
      hoaFedTaxId: hoaFedTaxId,
      persReqSummary: persReqSummary,
      authrzdRepName: authrzdRepName,
      authrzdRepTitle: authrzdRepTitle,
      authrzdRepEmail: authrzdRepEmail,
      authrzdRepPhone: authrzdRepPhone,
      dtAuthrzdRepSignature: dtAuthrzdRepSignature,
      preparerName: preparerName,
      preparerTitle: preparerTitle,
      preparerEmail: preparerEmail,
      preparerPhone: preparerPhone,
      dtPreparerSignature: dtPreparerSignature,
    },
    pers1073: {
      propertyAddr: propertyAddr,
      unitNumber: unitNumber,
      propertyCity: propertyCity,
      propertyState: propertyState,
      propertyZipCode: propertyZipCode,
      county: county,
      legalDesc: legalDesc,
      reTaxes: reTaxes,
      projectName: projectName,
      specialAssmnt: specialAssmnt,
      hoaFees: hoaFees,
      femaSplFloodHzrdAreaInd: femaSplFloodHzrdAreaInd,
      femaFloodZone: femaFloodZone,
      femaMapNbr: femaMapNbr,
      femaMapDate: femaMapDate,
      projectDesc: projectDesc,
      nbrStoriesCnt: nbrStoriesCnt,
      yrBuilt: yrBuilt,
      effAge: effAge,
      nbrUnitsCnt: nbrUnitsCnt,
      nbrUnitsRentedCnt: nbrUnitsRentedCnt,
      nbrUnitsOwnrOccpdCnt: nbrUnitsOwnrOccpdCnt,
      convExstngBldgInd: convExstngBldgInd,
      convExstngBldgDesc: convExstngBldgDesc,
      commSpaceInd: commSpaceInd,
      commSpaceDesc: commSpaceDesc,
      subjPropAppraisdAddr: subjPropAppraisdAddr,
      subjPropAppraisdVal: subjPropAppraisdVal,
    },
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disable, setDisable] = useState(false);
  const [apiErr, setApiErr] = useState("");

  function sunbmitPersForm() {
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/saveEstablishedPersProjectAndForms`,
        establishedData
      )
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          setPersId(response.data);
          setTimeout(() => {
            nextStep();
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log("savePersProjectAndForms error", error);
      });
  }

  function submitPersFormDecision() {
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/saveEstablishedPersProjectAndForms`,
        establishedData
      )
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          setPersId(response.data);
          nextStep();
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("savePersProjectAndForms error", error);
      });
  }

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          //   direction === 'back' ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add Established PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">Section 6 of 9 - Review</div>
                <>
                  <div className="page-container">
                    <div className="accordion-top-container">
                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Lending Details
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              {/* <ListItem className="page-select">
                                <ListItemText
                                  primary="PERS Status"
                                  secondary={persStatus}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="PERS Justification"
                                  secondary={persStatusJustification}
                                />
                              </ListItem> */}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Received Date	"
                                  secondary={receivedDate}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Assigned Risk Analyst"
                                  secondary={assgndRiskAnalyst}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender"
                                  secondary={lenderName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Seller/Service Number"
                                  secondary={lenderSSN}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Address"
                                  secondary={lenderAddress}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender City"
                                  secondary={lenderCity}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender State"
                                  secondary={lenderState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Zip Code"
                                  secondary={lenderZip}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Requester"
                                  secondary={requesterName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Requester Email"
                                  secondary={requesterEmail}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <Accordion id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                HOA Questionnaire (1076)
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Legal Name"
                                  secondary={projLegalName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Physical Address"
                                  secondary={projPhyAddr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="HOA Management Address"
                                  secondary={hoaMgmtAddr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="HOA Name "
                                  secondary={hoaName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="HOA Tax ID Number"
                                  secondary={hoaTaxId}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="HOA Management Company Tax ID Number"
                                  secondary={hoaMgmtCmpnyTaxId}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Name of Master or Umbrella Association"
                                  secondary={mstrAssocName}
                                />
                              </ListItem>
                              {values.rentalPoolngArrngmntInd === true ||
                              values.rentalPoolngArrngmntInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Hotel/motel/resort activities, mandatory or voluntary rental- pooling arrangements, or other restrictions on the unitowner’s ability to occupy the unit"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.rentalPoolngArrngmntInd === false ||
                                values.rentalPoolngArrngmntInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Hotel/motel/resort activities, mandatory or voluntary rental- pooling arrangements, or other restrictions on the unitowner’s ability to occupy the unit"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Hotel/motel/resort activities, mandatory or voluntary rental- pooling arrangements, or other restrictions on the unitowner’s ability to occupy the unit"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.deedResaleRestrctnInd === true ||
                              values.deedResaleRestrctnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Deed or resale restrictions"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.deedResaleRestrctnInd === false ||
                                values.deedResaleRestrctnInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Deed or resale restrictions"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Deed or resale restrictions"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.mfHomeInd === true ||
                              values.mfHomeInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Manufactured homes	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.mfHomeInd === false ||
                                values.mfHomeInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Manufactured homes	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Manufactured homes	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.mndtryMbrshipInd === true ||
                              values.mndtryMbrshipInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Mandatory fee-based memberships for use of project amenities or services		"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.mndtryMbrshipInd === false ||
                                values.mndtryMbrshipInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Mandatory fee-based memberships for use of project amenities or services		"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Mandatory fee-based memberships for use of project amenities or services		"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.nonIncdntlIncomeInd === true ||
                              values.nonIncdntlIncomeInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Non-incidental income from business operations			"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.nonIncdntlIncomeInd === false ||
                                values.nonIncdntlIncomeInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Non-incidental income from business operations			"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Non-incidental income from business operations"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.supprtCareSeniorsInd === true ||
                              values.supprtCareSeniorsInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Supportive or continuing care for seniors or for residents with disabilities			"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.supprtCareSeniorsInd === false ||
                                values.supprtCareSeniorsInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Supportive or continuing care for seniors or for residents with disabilities				"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Supportive or continuing care for seniors or for residents with disabilities	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.proj100pctCompltnInd === true ||
                              values.proj100pctCompltnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project 100% complete, including all construction or renovation of units, common elements, and shared amenities for all project phases?				"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.proj100pctCompltnInd === false ||
                                values.proj100pctCompltnInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project 100% complete, including all construction or renovation of units, common elements, and shared amenities for all project phases?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project 100% complete, including all construction or renovation of units, common elements, and shared amenities for all project phases?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.subjAddtnlPhasingInd === true ||
                              values.subjAddtnlPhasingInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project subject to additional phasing or annexation?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.subjAddtnlPhasingInd === false ||
                                values.subjAddtnlPhasingInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project subject to additional phasing or annexation?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project subject to additional phasing or annexation?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.legallyPhasedInd === true ||
                              values.legallyPhasedInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project legally phased?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.legallyPhasedInd === false ||
                                values.legallyPhasedInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project legally phased?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the project legally phased?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="How many phases have been completed?	"
                                  secondary={noOfPhasesCmpltd}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="How many total phases are legally planned for the project?	"
                                  secondary={totalPhasesLegallyPlanned}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="How many total units are planned for the project?	"
                                  secondary={totalUnitsPlanned}
                                />
                              </ListItem>
                              {values.plannedAmenitiesCompltnInd === true ||
                              values.plannedAmenitiesCompltnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are all planned amenities and common facilities fully complete?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.plannedAmenitiesCompltnInd === false ||
                                values.plannedAmenitiesCompltnInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are all planned amenities and common facilities fully complete?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are all planned amenities and common facilities fully complete?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.trnsfrControlHoaInd === true ||
                              values.trnsfrControlHoaInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Has the developer transferred control of the HOA to the unit owners?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.trnsfrControlHoaInd === false ||
                                values.trnsfrControlHoaInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Has the developer transferred control of the HOA to the unit owners?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Has the developer transferred control of the HOA to the unit owners?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Date Transferred"
                                  secondary={dtTrnsfrControlHoa}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is the project a conversion within the past 3 years of an existing structure that was used as an apartment, hotel/resort, retail or professional business, industrial or for other non-residential use?"
                                  secondary={convrsnOfExistngStructureInd}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="In what year was the property built?	"
                                  secondary={yrBuilt}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="In what year was the property converted?	"
                                  secondary={yrConvrtd}
                                />
                              </ListItem>
                              {values.fullGutRehabConvrsnInd === true ||
                              values.fullGutRehabConvrsnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Was the conversion a full gut rehabilitation of the existing structure(s), including replacement of all major mechanical components?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.fullGutRehabConvrsnInd === false ||
                                values.fullGutRehabConvrsnInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Was the conversion a full gut rehabilitation of the existing structure(s), including replacement of all major mechanical components?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Was the conversion a full gut rehabilitation of the existing structure(s), including replacement of all major mechanical components?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.structrllySoundInd === true ||
                              values.structrllySoundInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Does the report from the licensed engineer indicate that the project is structurally sound, and that the condition and remaining useful life of the project’s major components are sufficient?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.structrllySoundInd === false ||
                                values.structrllySoundInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Does the report from the licensed engineer indicate that the project is structurally sound, and that the condition and remaining useful life of the project’s major components are sufficient?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Does the report from the licensed engineer indicate that the project is structurally sound, and that the condition and remaining useful life of the project’s major components are sufficient?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.repairsCompltnInd === true ||
                              values.repairsCompltnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are all repairs affecting safety, soundness, and structural integrity complete?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.repairsCompltnInd === false ||
                                values.repairsCompltnInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are all repairs affecting safety, soundness, and structural integrity complete?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are all repairs affecting safety, soundness, and structural integrity complete?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.replacemntResrvsAlloctnInd === true ||
                              values.replacemntResrvsAlloctnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are replacement reserves allocated for all capital improvements?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.replacemntResrvsAlloctnInd === false ||
                                values.replacemntResrvsAlloctnInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are replacement reserves allocated for all capital improvements?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are replacement reserves allocated for all capital improvements?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.resrvsToFundImprovmntsInd === true ||
                              values.resrvsToFundImprovmntsInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are the project’s reserves sufficient to fund the improvements?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.resrvsToFundImprovmntsInd === false ||
                                values.resrvsToFundImprovmntsInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are the project’s reserves sufficient to fund the improvements?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are the project’s reserves sufficient to fund the improvements?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="How many unit owners are 60 or more days delinquent on common expense assessments?	"
                                  secondary={unitOwnrsDelinqCommnExpnseCnt}
                                />
                              </ListItem>
                              {values.mrtgeeRespnbleDelinqExpnseInd === true ||
                              values.mrtgeeRespnbleDelinqExpnseInd ===
                                "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="In the event a lender acquires a unit due to foreclosure or a deed-in-lieu of foreclosure, is the mortgagee responsible for paying delinquent common expenseassessments?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.mrtgeeRespnbleDelinqExpnseInd ===
                                  false ||
                                values.mrtgeeRespnbleDelinqExpnseInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="In the event a lender acquires a unit due to foreclosure or a deed-in-lieu of foreclosure, is the mortgagee responsible for paying delinquent common expenseassessments?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="In the event a lender acquires a unit due to foreclosure or a deed-in-lieu of foreclosure, is the mortgagee responsible for paying delinquent common expenseassessments?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="For how long is the mortgagee responsible for paying common expense assessments?"
                                  secondary={mrtgeeRespnbleDelinqExpnseTerm}
                                />
                              </ListItem>
                              {values.hoaInvlvdLitigtnInd === true ||
                              values.hoaInvlvdLitigtnInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the HOA involved in any active or pending litigation?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaInvlvdLitigtnInd === false ||
                                values.hoaInvlvdLitigtnInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the HOA involved in any active or pending litigation?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is the HOA involved in any active or pending litigation?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Attorney Name"
                                  secondary={litigAttrneyName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Attorney Phone Number"
                                  secondary={litigAttrneyPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units for Entire Project"
                                  secondary={totUnitsProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units for Subject Legal Phase"
                                  secondary={totUnitsPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed for Entire Project"
                                  secondary={totUnitsSoldClosedProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed for Subject Legal Phase"
                                  secondary={totUnitsSoldClosedPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units under bona-fide sales contracts for Entire Project"
                                  secondary={totUnitsBonafidSalesProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units under bona-fide sales contracts for Subject Legal Phase"
                                  secondary={totUnitsBonafidSalesPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed or under contract to owner-occupants for Entire Project"
                                  secondary={totUnitsOwnrOccpntProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed or under contract to owner-occupants for Subject Legal Phase"
                                  secondary={totUnitsOwnrOccpntPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed or under contract to second home owners for Entire Project"
                                  secondary={totUnitsSecOwnrProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed or under contract to second home owners for Subject Legal Phase"
                                  secondary={totUnitsSecOwnrPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed or under contract to investor owners for Entire Project"
                                  secondary={totUnitsInvstrOwnrProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units sold and closed or under contract to investor owners for Subject Legal Phase"
                                  secondary={totUnitsInvstrOwnrPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units being rented by developer, sponsor, or converter for Entire Project"
                                  secondary={totUnitsRentedProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units being rented by developer, sponsor, or converter for Subject Legal Phase"
                                  secondary={totUnitsRentedPhase}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units owned by the HOA for Entire Project"
                                  secondary={totUnitsOwnByHoaProj}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total number of units owned by the HOA for Subject Legal Phase"
                                  secondary={totUnitsOwndByHoaPhase}
                                />
                              </ListItem>
                              {values.soleOwnrshipIntrstAmenitiesInd === true ||
                              values.soleOwnrshipIntrstAmenitiesInd ===
                                "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Do the unit owners have sole ownership interest in and the right to use the project amenities and commonareas?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.soleOwnrshipIntrstAmenitiesInd ===
                                  false ||
                                values.soleOwnrshipIntrstAmenitiesInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Do the unit owners have sole ownership interest in and the right to use the project amenities and commonareas?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Do the unit owners have sole ownership interest in and the right to use the project amenities and commonareas?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Who has ownership interest in and rights to use the project amenities and common areas"
                                  secondary={soleOwnrshipIntrstAmenitiesExpln}
                                />
                              </ListItem>
                              {values.unitsUsedCommrclPurpseInd === true ||
                              values.unitsUsedCommrclPurpseInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are any units in the project used for commercial or non-residential purposes?		"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.unitsUsedCommrclPurpseInd === false ||
                                values.unitsUsedCommrclPurpseInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are any units in the project used for commercial or non-residential purposes?		"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are any units in the project used for commercial or non-residential purposes?		"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="What is the total square footage of commercial space in the building that is separate from the residentialHOA? Include above and below grade space used for commercial purposes, such as public parking facilities, retail space, apartments, commercial offices, and so on. Total square footage of commercial space"
                                  secondary={totSqFootageCommSpace}
                                />
                              </ListItem>
                              {values.floodZoneInd === true ||
                              values.floodZoneInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are units or common elements located in a flood zone?	"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.floodZoneInd === false ||
                                values.floodZoneInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are units or common elements located in a flood zone?	"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Are units or common elements located in a flood zone?	"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Flood Coverage is in force equaling"
                                  secondary={floodCvrgInForceDesc}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Flood Coverage Other Amount"
                                  secondary={floodCvrgInForceDescOtherAmt}
                                />
                              </ListItem>
                              {values.hoaAcctsSeparateOpratingReserveFundsInd ===
                                true ||
                              values.hoaAcctsSeparateOpratingReserveFundsInd ===
                                "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="HOA maintains separate accounts for operating and reserve funds"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaAcctsSeparateOpratingReserveFundsInd ===
                                  false ||
                                values.hoaAcctsSeparateOpratingReserveFundsInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="HOA maintains separate accounts for operating and reserve funds"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="HOA maintains separate accounts for operating and reserve funds"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.hoaAcctsAcessCntrlsInd === true ||
                              values.hoaAcctsAcessCntrlsInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Appropriate access controls are in place for each account"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaAcctsAcessCntrlsInd === false ||
                                values.hoaAcctsAcessCntrlsInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Appropriate access controls are in place for each account"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Appropriate access controls are in place for each account"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.hoaAcctsMnthlyBankStmtsInd === true ||
                              values.hoaAcctsMnthlyBankStmtsInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The bank sends copies of monthly bank statements directly to the HOA"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaAcctsMnthlyBankStmtsInd === false ||
                                values.hoaAcctsMnthlyBankStmtsInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The bank sends copies of monthly bank statements directly to the HOA"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The bank sends copies of monthly bank statements directly to the HOA"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.hoaAcctsCheckSignedReqdMembersInd ===
                                true ||
                              values.hoaAcctsCheckSignedReqdMembersInd ===
                                "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Two members of the HOA Board of Directors are required to sign any check written on the reserve account"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaAcctsCheckSignedReqdMembersInd ===
                                  false ||
                                values.hoaAcctsCheckSignedReqdMembersInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Two members of the HOA Board of Directors are required to sign any check written on the reserve account"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Two members of the HOA Board of Directors are required to sign any check written on the reserve account"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.hoaAcctsMngmtCmpnyAccts_maintnanceInd ===
                                true ||
                              values.hoaAcctsMngmtCmpnyAccts_maintnanceInd ===
                                "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The Management Company maintains separate records and bank accounts for each HOA that uses its services"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaAcctsMngmtCmpnyAccts_maintnanceInd ===
                                  false ||
                                values.hoaAcctsMngmtCmpnyAccts_maintnanceInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The Management Company maintains separate records and bank accounts for each HOA that uses its services"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The Management Company maintains separate records and bank accounts for each HOA that uses its services"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              {values.hoaAcctsMngmtCmpnyAuthorityReqmntsInd ===
                                true ||
                              values.hoaAcctsMngmtCmpnyAuthorityReqmntsInd ===
                                "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The Management Company does not have the authority to draw checks on, or transfer funds from, the reserve account of the HOA"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.hoaAcctsMngmtCmpnyAuthorityReqmntsInd ===
                                  false ||
                                values.hoaAcctsMngmtCmpnyAuthorityReqmntsInd ===
                                  "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The Management Company does not have the authority to draw checks on, or transfer funds from, the reserve account of the HOA"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="The Management Company does not have the authority to draw checks on, or transfer funds from, the reserve account of the HOA"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Hazard Carrier/Agent Name"
                                  secondary={hzrdInsAgentName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Hazard Carrier/Agent Phone Number"
                                  secondary={hzrdInsAgentPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Hazard Policy Number"
                                  secondary={hzrdInsPlcyNbr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Liability Carrier/Agent Name"
                                  secondary={liabInsAgentName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Liability Carrier/Agent Phone Number"
                                  secondary={liabInsAgentPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Liability Policy Number"
                                  secondary={liabInsPlcyNbr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Fidelity Carrier/Agent Name"
                                  secondary={fidelInsAgentName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Fidelity Carrier/Agent Phone Number"
                                  secondary={fidelInsAgentPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Fidelity Policy Number"
                                  secondary={fidelInsPlcyNbr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Flood Carrier/Agent Name"
                                  secondary={floodInsAgentName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Flood Carrier/Agent Phone Number"
                                  secondary={floodInsAgentPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Flood Policy Number"
                                  secondary={floodInsPlcyNbr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Name of Preparer"
                                  secondary={preparerName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Title of Preparer"
                                  secondary={preparerTitle}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Preparer’s Company Name"
                                  secondary={preparerCmpnyName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Preparer’s Phone"
                                  secondary={preparerPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Preparer’s Email"
                                  secondary={preparerEmail}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Preparer’s Company Address"
                                  secondary={preparerCmpnyAddr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Date Completed"
                                  secondary={dtCmpltd}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <Accordion id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Form 1091
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Name"
                                  secondary={lndrName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Seller/Service Number"
                                  secondary={sellrServcrNbr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Purpose of Application"
                                  secondary={purposeOfApplicn}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Expiration Date of Prior Approval"
                                  secondary={dtExpryPriorApprval}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project's Legal Name"
                                  secondary={projectLegalName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Address"
                                  secondary={projectAddress}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="City"
                                  secondary={projectCity}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="State"
                                  secondary={projectState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="ZIP Code"
                                  secondary={projectZipCode}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Homeowners' Association Federal Tax ID Number"
                                  secondary={hoaFedTaxId}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Why is a PERS being requested? "
                                  secondary={persReqSummary}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Name of lender's authorized representative"
                                  secondary={authrzdRepName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Title"
                                  secondary={authrzdRepTitle}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Email Address"
                                  secondary={authrzdRepEmail}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Phone Number"
                                  secondary={authrzdRepPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Date of Signature"
                                  secondary={dtAuthrzdRepSignature}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Name of lender's authorized representative"
                                  secondary={preparerName1091}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Title"
                                  secondary={preparerTitle1091}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Email Address"
                                  secondary={preparerEmail1091}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Phone Number"
                                  secondary={preparerPhone1091}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Date of Signature"
                                  secondary={dtPreparerSignature}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <Accordion id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Form 1073
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Property Address"
                                  secondary={propertyAddr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Unit Number"
                                  secondary={unitNumber}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="City"
                                  secondary={propertyCity}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="State"
                                  secondary={propertyState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Zip Code"
                                  secondary={propertyZipCode}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="County"
                                  secondary={county}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Legal Description"
                                  secondary={legalDesc}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="R.E. Taxes (Cost)"
                                  secondary={reTaxes}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Name"
                                  secondary={projectName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Special Assessments (Cost)"
                                  secondary={specialAssmnt}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="HOA Fees (Cost)"
                                  secondary={hoaFees}
                                />
                              </ListItem>
                              {values.femaSplFloodHzrdAreaInd === true ||
                              values.femaSplFloodHzrdAreaInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="FEMA Special Flood Hazard Area"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.femaSplFloodHzrdAreaInd === false ||
                                values.femaSplFloodHzrdAreaInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="FEMA Special Flood Hazard Area"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="FEMA Special Flood Hazard Area"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="FEMA Flood Zone"
                                  secondary={femaFloodZone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="FEMA Map Number"
                                  secondary={femaMapNbr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Description"
                                  secondary={projectDesc}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Stories"
                                  secondary={nbrStoriesCnt}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Year Built"
                                  secondary={yrBuilt1073}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Effective Age"
                                  secondary={effAge}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units"
                                  secondary={nbrUnitsCnt}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units Rented"
                                  secondary={nbrUnitsRentedCnt}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Owner Occupied Units"
                                  secondary={nbrUnitsOwnrOccpdCnt}
                                />
                              </ListItem>
                              {values.convExstngBldgInd === true ||
                              values.convExstngBldgInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Was the project created by the conversion of an existing building(s) into a condominium?"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.convExstngBldgInd === false ||
                                values.convExstngBldgInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Was the project created by the conversion of an existing building(s) into a condominium?"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Was the project created by the conversion of an existing building(s) into a condominium?"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Description"
                                  secondary={convExstngBldgDesc}
                                />
                              </ListItem>
                              {values.commSpaceInd === true ||
                              values.commSpaceInd === "true" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is there any commercial space in the project?"
                                    secondary="Yes"
                                  />
                                </ListItem>
                              ) : values.commSpaceInd === false ||
                                values.commSpaceInd === "false" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is there any commercial space in the project?"
                                    secondary="No"
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Is there any commercial space in the project?"
                                    secondary=""
                                  />
                                </ListItem>
                              )}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Description"
                                  secondary={commSpaceDesc}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Address of Property Appraised"
                                  secondary={subjPropAppraisdAddr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Appraised Value of Subject Property"
                                  secondary={subjPropAppraisdVal}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <Accordion id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Additional Documents
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Name of Master Association"
                                  secondary={masterAssociationName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions"
                                  secondary={exceptions1051}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Master Association Notes"
                                  secondary={masterAssociationNotes}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Income from Assessments"
                                  secondary={incomeFromAssessments}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Reserves"
                                  secondary={reserves}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Percent of Reserves on Hand"
                                  secondary={percentageOfReservesOnHand}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions"
                                  secondary={exceptionsBudget}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </>
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {apiErr ? apiErr : null}
                </div>

                <div className="bottom-button-container">
                  <div>
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      disabled={disable}
                      onClick={() => {
                        setDisable(true);
                        sunbmitPersForm();
                      }}
                    >
                      Save
                      {/* <ArrowForwardIcon /> */}
                    </Button>
                    {/* <Button
                      type="submit"
                      className="next-button next-btn-save"
                      onClick={() => {
                        submitPersFormDecision();
                      }}
                      style={{ marginLeft: 15 }}
                    >
                      Save and Issue PERS Decision
                    </Button> */}
                  </div>
                  <Button
                    type="submit"
                    className="previous-button review-preview"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>

            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{ fill: "red" }} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

Review.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import "./AddNewPers.css";
import "./select.css";
import { LendingDetails } from "./pages/LendingDetails";
import { Form1026 } from "./pages/Form1026";
import { Form1029 } from "./pages/Form1029";
import { Form1051 } from "./pages/Form1051";
import { Form1054 } from "./pages/Form1054";
import { Form1071 } from "./pages/Form1071";
import { PersStatus } from "./pages/PersStatus";
import { Review } from "./pages/Review";
import { Attachments } from "./pages/Attachments";
import { Success } from "./pages/Success";
import { ConfirmPers } from "./pages/ConfirmPers";


export const AddNewPers = props => {
  window.onscroll = function() {
    effects();
  };
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const [persId, setPersId] = useState(null);
  const [selectedPhaseSaved, setSelectedSaved] = useState([]);
  const [selectedPhaseList, setSelectedPhaseList] = React.useState([]);

  function effects() {}
  const [step, setStep] = useState(0);

  const [formData, setFormData] = useState({
    //perslen

    projectId: props.match.params.project_id,
    persStatusJustification: "",
    receivedDate: "",
    decisionLevel: "", //"Phases", // "Entire Project",
    lenderName: "",
    assgndRiskAnalyst: "",
    lenderSSN: "",
    lenderAddress: "",
    lenderCity: null,
    lenderState: "",
    lenderZip: null,
    requesterName: "",
    requesterEmail: "",
    builderOrDevelopeName: "",
    builderOrDeveloperPrincipals: "",
    builderOrDeveloperPrimaryContact: "",
    builderOrDeveloperContactPhone: null,
    builderOrDeveloperAddress: "",
    builderOrDeveloperState: "",
    builderOrDeveloperZIPCode: null,

    //pers1026
    projectName: "",
    projectAddress: "",
    projectCity: null,
    projectState: "",
    projectZipCode: null,
    legalDescription: "",
    projectType: null,
    propertyRights: null,
    masterHOAIndicator: "",
    complete1051Indicator: "",
    projectStoriesCount: null,
    projectStructureType: null,
    builderOwnershipIndicator: "",
    builderOwnershipRetainComments: "",
    builderRentingUnitsIndicator: "",
    builderRentingUnitsComments: "",
    recreationServicesSharedUserAgreementsIndicator: "",
    unitOwnerSoleOwnershipAmenitiesIndicator: "",
    unitOwnerSoleOwnershipAmenitiesComments: "",
    moreThanOnePhaseIndicator: "",
    phaseType: null,
    phaseTypeMarketingComments: "",
    salesStrategy: null,
    salesStrategyExplaination: "",
    constructionStatus: null,
    yearCompleted: "",
    proposedStartDate: null,
    constructionStartedDate: null,
    anticipatedCompletionDate: null,
    conversionGutRehabNonGutRehabType: null,
    litigationFiledIndicator: "",
    environProblemsIndicator: "",
    environProblemsExplanation: "",
    resaleRestrictionIndicator: "",
    resaleRestrictionExplaination: "",
    healthSafetyIssuesIndicator: "",
    healthSafetyIssuesExplaination: "",
    commercialUnitsInclusionIndicator: "",
    commercialUnitsSoldLeasedRetained: null,
    commercialUnitsCount: "",
    commercialSpacePercent: "",
    residentialSpacePercent: "",
    commercialSpacePurpose: "",
    commercialSpaceCompleteIndicator: "",
    nonIncidentalBusiOperationsOwnedHOAIndicator: "",
    nonIncidentalBusiOperationsOwnedHOAComments: "",
    phase1026DataList: [
      {
        phaseDesignation: "",
        buildingDesignation: "",
        phaseUnitsCount: "",
        includedinApplicationIndicator: "",
        createdBy: passData.user
      }
    ],

    //pers1029
    totalUnitsCount: "",
    unitsSoldToPrincipalResidencePurchasersCount: "",
    unitsSoldToSecondHomePurchasersCount: "",
    unitsSoldToInvestorPurchasersCount: "",
    priorTotalUnitsCount: "",
    priorUnitsSoldToPrincipalResidencePurchasersCount: "",
    priorUnitsSoldToSecondHomePurchasersCount: "",
    priorUnitsSoldToInvestorPurchasersCount: "",
    salesSourceInformation: "",
    closedSalesUnitsCount: "",

    //pers1051
    masterAssociationName: "",
    exceptions1051: "",
    masterAssociationNotes: "",

    //pers1054
    submittedComplete1054Indicator: "",
    includedAttornerOpnionLetterIndicator: "",
    exceptions1054: "",

    //pers1071
    masterOrBlanketInsuranceExpirationDate: null,
    mbFireAndCoverageInsuranceInForceIndicator: "",
    insuranceEqualsReplacementCostIndicator: "",
    fireAndExtCoverageInsuranceAmount: "",
    specialCondominiumEndorsementIndicator: "",
    standardMortgageClauseIndicator: "",
    agreedAmountEndorsementIndicator: "",
    inflationGuardEndorsementIndicator: "",
    replacementCostEndorsementIndicator: "",
    otherSpecialEndorsementIndicator: "",
    constructionCodeChangeIndicator: "",
    cccExposureProtectedByEndorsementsIndicator: "",
    condoInsuranceTrusteeIndicator: "",
    boilerInsPolicyIndicator: "",
    boilerInsuranceCoverageAmount: "",
    liabiltyInsPolicyIndicator: "",
    liabilityInsuranceCoverageAmount: "",
    floodInsPolicyIndicator: "",
    floodInsuranceCoverageAmount: "",
    fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd: "",
    fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd: "",
    associationCoverageAmount: "",
    managementCoverageAmount: "",
    hoaMaintainInsuranceIndicator: "",
    exceptions1071: "",

    //pers1081
    phasesIncluded: "",
    unitsIncludedCount: "",
    certificationByName: "",
    professionalDesignationOrLicenseNumber: "",
    certficationByRoleEngArchAppr: "",
    exceptions1081: "",

    //persBudget
    incomeFromAssessments: "",
    reserves: "",
    percentageOfReservesOnHand: "",
    exceptionsBudget: "",

    persStatus: "",
    persExpiration: "",
    // reviewedBy: "",
    phaseIds: []
  });

  const nextStep = () => setStep(prev => prev + 1);
  const nextStep2 = () => setStep(prev => prev + 2);
  const prevStep = () => setStep(prev => prev - 1);
  //
  switch (step) {
  // switch (8) {
    case 0:
      return (
        <LendingDetails
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          step={step}
          projectId={props.match.params.project_id}
          setSelectedPhaseList={setSelectedPhaseList}
          setSelectedSaved={setSelectedSaved}
          selectedPhaseSaved={selectedPhaseSaved}
        />
      );
    case 1:
      return (
        <Form1026
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 2:
      return (
        <Form1029
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 3:
      return (
        <Form1071
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 4:
      return (
        <Form1051
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
        />
      );
    case 5:
      return (
        <Review
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          nextStep2={nextStep2}
          prevStep={prevStep}
          step={step}
          setPersId={setPersId}
          selectedPhaseSaved={selectedPhaseSaved}
        />
      );
    case 6:
      return (
        // <Success formData={formData} step={step} />
         <ConfirmPers formData={formData} step={step} nextStep={nextStep} persId={persId}/>
        // <PersStatus
        //   formData={formData}
        //   setFormData={setFormData}
        //   nextStep={nextStep}
        //   prevStep={prevStep}
        //   step={step}
        //   persId={persId}
        //   projectId={props.match.params.project_id}
        //   selectedPhaseList={selectedPhaseList}
        // />
      );
    case 7:
      return (
        <Attachments
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          persId={persId}
          projectId={props.match.params.project_id}
        />
      );
    case 8:
      return <Success formData={formData} step={step} persId={persId}/>;
    default:
      return (
        <Success formData={formData} step={step} persId={persId}/>
      );
  }
};

import React, { useState, useEffect } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field } from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";


export default function PersAdditionalInfo({
  collapseKey,
  pers1051,
  pers1054,
  pers1081,
  budget,
  persId,
  projectId
}) {
  const [isEdit, setEdit] = useState(false);
  const [formState1051, setFormState1051] = useState({});
  const [formState1054, setFormState1054] = useState({});
  const [formState1081, setFormState1081] = useState({});
  const [formStateBudget, setFormStateBudget] = useState({});
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const setInitialState = () => {
    setFormState1051({ ...pers1051 });
    setFormState1054({ ...pers1054 });
    setFormState1081({ ...pers1081 });
    setFormStateBudget({ ...budget });
  };

  useEffect(() => {
    setInitialState();
  }, [pers1051, pers1054, pers1081, budget]);


  const handleSaveEdit = async () => {
    setApiErr("");
    setInternalErr(false);
    try {
      const body = {
        persId,
        projectId,
        phaseId: null,
        pers1051: { ...formState1051 },
        pers1054: { ...formState1054 },
        pers1081: { ...formState1081 },
        persBudget: { ...formStateBudget }
      };
      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updatePersProjectAndForms/addnDocs`,
        body
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setInitialState();
    }
  };

  const handleChange = (event, formState, setFormState) => {
    const { name, type, value, checked } = event.target;
    let _formData = { ...formState };
    _formData[name] = type === "checkbox" ? checked : value;
    setFormState({ ..._formData });
  };

  const handleChange1051 = event =>
    handleChange(event, formState1051, setFormState1051);

  const handleChange1054 = event =>
    handleChange(event, formState1054, setFormState1054);

  const handleChange1081 = event =>
    handleChange(event, formState1081, setFormState1081);

  const handleChangeBudget = event =>
    handleChange(event, formStateBudget, setFormStateBudget);

  return (
    <Accordion defaultActiveKey="1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">
              Additional Documents (1051,1054,1081, Budget)
            </p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => {
                  toggleEdit(false);
                  setApiErr("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                toggleEdit(true);
                setInternalErr(false);
                setApiErr("");
              }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    1051 Questions
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Name of Master Association"
                      name="masterAssociationName"
                      formData={formState1051}
                      handleChange={handleChange1051}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Exceptions"
                      name="exceptions1051"
                      formData={formState1051}
                      handleChange={handleChange1051}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Notes on Master"
                      name="masterAssociationNotes"
                      formData={formState1051}
                      handleChange={handleChange1051}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    1054 Questions
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Has the lender submitted a completed 1054?"
                      name="submittedComplete1054Indicator"
                      formData={formState1054}
                      handleChange={handleChange1054}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Has the lender included the Attorney Opinion Letter?"
                      name="includedAttornerOpnionLetterIndicator"
                      formData={formState1054}
                      handleChange={handleChange1054}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Exceptions"
                      name="exceptions1054"
                      formData={formState1054}
                      handleChange={handleChange1054}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    1081 Questions
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Phases Included"
                      name="phasesIncluded"
                      formData={formState1081}
                      handleChange={handleChange1081}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of Units Included"
                      name="unitsIncludedCount"
                      formData={formState1081}
                      handleChange={handleChange1081}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Certification Name"
                      name="certificationByName"
                      formData={formState1081}
                      handleChange={handleChange1081}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Professional Designation / License Number"
                      name="professionalDesignationOrLicenseNumber"
                      formData={formState1081}
                      handleChange={handleChange1081}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Exceptions"
                      name="exceptions1081"
                      formData={formState1081}
                      handleChange={handleChange1081}
                      isEdit={isEdit}
                    />

                    <Field
                      label="Certification Provided By"
                      name="certficationByRoleEngArchAppr"
                      formData={formState1081}
                      handleChange={handleChange1081}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Budget Questions
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Income from Assessments (HOA dues)"
                      name="incomeFromAssessments"
                      formData={formStateBudget}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Reserves"
                      name="reserves"
                      formData={formStateBudget}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Percent of Reserves on Hand"
                      name="percentageOfReservesOnHand"
                      formData={formStateBudget}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Exceptions"
                      name="exceptionsBudget"
                      formData={formStateBudget}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

import React from "react";
import { isProjectStandardRole } from "../../redux/actions/userAction";
import "./PhaseList.css";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import SuccessIcon from "../../images/icons/Success@1x.svg";
import WarningIcon from "../../images/icons/Warning@1x.svg";
import TimerIcon from "../../images/icons/timer@1x.svg";
import FeedbackIcon from "../../images/icons/feedback-remove@1x.svg";
import Success1Icon from "../../images/icons/Success-1@1x.svg";
import Warning1Icon from "../../images/icons/Warning-1@1x.svg";
import CriticalIcon from "../../images/Critical@2x.svg";
const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
    fontSize: 14,
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);

const IndividualPhase = ({ item }) => {
  return (
    <a href={`/project/phase/${item.phaseId}`}>
      <div className="card-row-pers">
        <div
          style={{ display: "flex", flex: "0.30" }}
          className="card-project-name-pers"
        >
          <div>
            {item.fmReviewStatus === "Approved By Fannie Mae" ? (
              <>
                <CustomTooltip title="Approved By Fannie Mae" placement="top">
                  <img
                    alt="Success Check Icon"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={SuccessIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fmReviewStatus === "Conditional Approval" ? (
              <>
                <CustomTooltip title="Conditional Approval" placement="top">
                  <img
                    alt="Warning Icon"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={WarningIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fmReviewStatus === "Pending PERS Review" ? (
              <>
                <CustomTooltip title="Pending PERS Review" placement="top">
                  <img
                    alt="Timer Icon"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={TimerIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fmReviewStatus === "Unavailable" ? (
              <>
                <CustomTooltip title="Unavailable" placement="top">
                  <img
                    alt="Removal Icon"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={FeedbackIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fmReviewStatus === "Fannie Mae Approval Expired" ? (
              <>
                <CustomTooltip
                  title="Fannie Mae Approval Expired"
                  placement="top"
                >
                  <img
                    alt="Alternate Success Check Icon"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={Success1Icon}
                  />
                </CustomTooltip>
              </>
            ) : item.fmReviewStatus ===
              "Fannie Mae Conditional Approval Expired" ? (
              <>
                <CustomTooltip
                  title="Fannie Mae Conditional Approval Expired"
                  placement="top"
                >
                  <img
                    alt="Alternate Warning Icon"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={Warning1Icon}
                  />
                </CustomTooltip>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div>
              <span className="phase-type-container-1">{item.phaseType}</span>{" "}
              {item.delRestr ? (
                <>
                  {" "}
                  <CustomTooltip
                    title="Delivery Restrictions"
                    placement="right"
                  >
                    <span>
                      <img
                        alt="Critical Warning Icon"
                        className="restrictions-icon"
                        src={CriticalIcon}
                      />
                    </span>
                  </CustomTooltip>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="phase-type-name-1">{item.phaseNumber}</div>
            <div style={{ flexDirection: "row" }}>
              {item.cntConstrPhs ? (
                <>
                  {/* <div className="phase-types"> */}
                  <span className="phase-count">{item.cntConstrPhs}</span>{" "}
                  Construction
                  {/* </div>{" "} */}
                </>
              ) : (
                <></>
              )}
              {item.cntMarketingPhs ? (
                <>
                  {/* <div className="phase-types"> */}
                  <span className="phase-count" style={{ marginLeft: "8px" }}>
                    {item.cntMarketingPhs}
                  </span>{" "}
                  Marketing {/* </div> */}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="card-project-name-pers">
          <div className="phase-list-values" style={{ paddingTop: "24px" }}>
            {item.phaseId}
          </div>
        </div>
        <div className="card-project-name-pers">
          <div className="phase-list-values" style={{ paddingTop: "24px" }}>
            {item.street}
            <br></br>
            {item.city}, {item.state} {item.zip5}
          </div>
        </div>
        <div className="card-project-name-pers">
          <div className="phase-list-values" style={{ paddingTop: "24px" }}>
            {item.unitNbr}
          </div>
        </div>
        <div className="card-project-name-pers">
          <div className="phase-list-values" style={{ paddingTop: "24px" }}>
            {item.phaseUnitCount}
          </div>
        </div>
        <div className="card-project-name-pers">
          <div className="phase-list-values" style={{ paddingTop: "24px" }}>
            {item.bldgNbr}
          </div>
        </div>
        <div className="card-project-name-pers">
          {isProjectStandardRole() ? (
            <>
              {" "}
              {item.certificationCount === 0 ? (
                <>
                  {" "}
                  <div
                    className="phase-list-values"
                    style={{ paddingTop: "24px" }}
                  >
                    No Lender Certifications
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className="phase-list-values"
                    style={{ paddingTop: "24px" }}
                  >
                    {item.certificationCount} Lender Certifications
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="phase-list-values" style={{ paddingTop: "24px" }}>
                <div>{item.certificationStatus}</div>
                {item.certExpiryDate ? (
                  <>
                    <div className="certification-expires">
                      Expired : {item.certExpiryDate}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </a>
  );
};

export default IndividualPhase;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DescriptionIcon from "@material-ui/icons/Description";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DoneIcon from "@material-ui/icons/Done";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import ReportIcon from "@material-ui/icons/Report";
import BlockIcon from "@material-ui/icons/Block";
import { Tooltip } from "@material-ui/core";
import { isReadonlyRole } from "../../redux/actions/userAction";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: `rgba(15,90,240,0.1);`,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: `rgba(0,0,0,0.82)`,
      },
    },
  },
}))(MenuItem);

export default function PersDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("");

  console.log("PERS DECISION::::", props.pers_decision);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (props.pers_decision === null) {
      const newValue = "empty";
      setValue(newValue);
    } else {
      const newValue = props.pers_decision;
      setValue(newValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createLetter = () => {
    window.location =
      "/conditional-available/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const createUnavialableLetter = () => {
    window.location =
      "/conditional-unavailable/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const createFinalLetter = () => {
    window.location =
      "/final-approval/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
    // window.location = "/conditional-approval/" + props.project_id + "/" + props.pers_id + "/" + props.pers_type
  };

  const createSuspendedLetter = () => {
    window.location =
      "/suspended/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const createIneligibleLetter = () => {
    window.location =
      "/ineligible-letter/" +
      props.project_id +
      "/" +
      props.pers_id +
      "/" +
      props.pers_type;
  };

  const [open, setOpen] = React.useState(false);

  const handleTipClose = () => {
    setOpen(false);
  };

  const handleTipOpen = () => {
    if (document.getElementById("cool").disabled === true) {
      setOpen(true);
    }
  };

  return (
    <div>
      {!isReadonlyRole() && props?.pers_decision ? (
        <Tooltip
          open={open}
          onClose={handleTipClose}
          onOpen={handleTipOpen}
          placement="top"
          title="Letter generation will be available when set to corresponding PERS status."
        >
          <span>
            <Button
              id="cool"
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className="pers-dropdown-button"
              disableRipple
              disabled={
                props.pers_status === "Review Pending" && !props.pers_decision
              }
            >
              <DescriptionIcon fontSize="small" className="description-icon" />
              Generate Letter{" "}
              <ArrowDropDownIcon fontSize="large" className="down-arrow" />
            </Button>
          </span>
        </Tooltip>
      ) : null}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          disabled={!value?.includes("Final Project Approval")}
          onClick={createFinalLetter}
        >
          <ListItemIcon className="dropdown-list-icon">
            <DoneIcon style={{ color: "#0F5AF0" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Final Letter" />
        </StyledMenuItem>
        <StyledMenuItem
          //disabled={props.pers_status != "PERS Conditional Approval"}
          disabled={!value?.includes("Conditional/Available")}
          onClick={createLetter}
        >
          <ListItemIcon className="dropdown-list-icon">
            <DoneOutlineIcon style={{ color: "#0F5AF0" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Conditional/Available" />
        </StyledMenuItem>
        <StyledMenuItem
          //disabled={props.pers_status != "PERS Conditional Approval"}
          disabled={!value?.includes("Conditional/Unavailable")}
          onClick={createUnavialableLetter}
        >
          <ListItemIcon className="dropdown-list-icon">
            <DoneOutlineIcon style={{ color: "#0F5AF0" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Conditional/Unavailable" />
        </StyledMenuItem>
        <StyledMenuItem
          //disabled={props.pers_status != "PERS Suspended"}
          disabled={!value.includes("Suspension of the Application")}
          onClick={createSuspendedLetter}
        >
          <ListItemIcon className="dropdown-list-icon">
            <ReportIcon style={{ color: "#0F5AF0" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Suspension Letter" />
        </StyledMenuItem>
        <StyledMenuItem
          //disabled={props.pers_status != "PERS Ineligible"}
          disabled={!value?.includes("Ineligible")}
          onClick={createIneligibleLetter}
        >
          <ListItemIcon className="dropdown-list-icon">
            <BlockIcon style={{ color: "#0F5AF0" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Ineligible Letter" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import IndividualPers from "./IndividualPers";
import "./PersList.css";
import { FaSort } from "react-icons/fa";

const PersList = ({ list, isEstablished }) => {
  const [sortType, setSortType] = useState({
    lenderName: "asc",
    persApprovalStatus: "asc",
    dateReceived: "asc",
  });
  const [persList, setPersList] = useState([]);

  useEffect(() => {
    if (list) {
      setPersList(list);
    }
  }, [list]);

  const onSort = (column) => {
    const sortOrder = sortType[column] === "asc" ? "desc" : "asc";
    const obj = { ...sortType, [column]: sortOrder };
    setSortType(JSON.parse(JSON.stringify(obj)));
    sortPersList(column);
  };

  const sortPersList = (column) => {
    let sortList = [].concat(persList);
    if (column === "dateReceived") {
      sortList
        .sort((a, b) => {
          const item1 = new Date(a.dateReceived).getTime();
          const item2 = new Date(b.dateReceived).getTime();
          console.log(item1, item2);
          return sortType["dateReceived"] === "asc"
            ? item1 - item2
            : item2 - item1;
        })
        .map((pers) => pers);
    } else {
      sortList
        .sort((a, b) => {
          const item1 = a[column].toLowerCase();
          const item2 = b[column].toLowerCase();
          return sortType[column] === "asc"
            ? item1.localeCompare(item2)
            : item2.localeCompare(item1);
        })
        .map((pers) => pers);
    }

    setPersList(sortList);
  };

  return (
    <div className="pdf-container">
      <div className="pdf-header">
        <div className="pdf-header-name">PERS ID</div>
        <div className="pdf-header-name">
          Date Received
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("dateReceived");
            }}
          />
        </div>
        <div className="pdf-header-status">
          PERS Review Status
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("persApprovalStatus");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Lender Name
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("lenderName");
            }}
          />
        </div>
        <div className="pdf-header-name">Selected</div>
        <div className="pdf-header-name">Decisioned</div>
      </div>
      <div className="pdf-body">
        {persList && persList.length > 0 ? (
          persList.map((m, i) => (
            <IndividualPers isEstablished={isEstablished} key={i} item={m} />
          ))
        ) : (
          <h3>Loading...</h3>
        )}
      </div>
    </div>
  );
};
export default PersList;

import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

import ContextAwareToggle from "../Profile/ContextAwareToggle";
import PersLetter from "../PersLetter/PersLetter";

export default function Section9({ collapseKey, persId, projectId }) {
    const [getLetter, setLetter] = useState(null);

    const fetchLetterData = async () => {
    
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/decision/getPersLetterDetailsHistory/${persId}`
        );
        setLetter(data);
      };
      useEffect(() => {
        if (projectId) {
            fetchLetterData();
        }
      }, [projectId]);

      const renderLetter = () => {
        let list = <h1>Loading...</h1>;
        if (getLetter) {
          list = <PersLetter list={getLetter}/>;
        }
        return list;
      }

      return (
        <Accordion defaultActiveKey="1" id="section2">
        <Card className="accordion-header-card">
          <Card.Header className="accordion-header-space">
            <ContextAwareToggle eventKey={collapseKey}>
              <p className="accordion-header-title">Letter Details</p>
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={collapseKey}>
            <Card.Body className="accordion-body-card">
              {renderLetter()}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      )

}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../../AddEstablishedPers/AddEstablishedPers.css";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const Form1051 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState("back");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [
    certficationByRoleEngArchApprData,
    setCertficationByRoleEngArchApprData
  ] = React.useState("");
  const fetchCertficationByRoleEngArchApprData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/CERTIFICATION_BY_ROLE_1081`
      )
      .then(response => {
        setCertficationByRoleEngArchApprData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchCertficationByRoleEngArchApprData();
  }, [fetchCertficationByRoleEngArchApprData]);
  const certficationByRoleEngArchApprResult = Object.keys(
    certficationByRoleEngArchApprData
  ).map(key => certficationByRoleEngArchApprData[key]);
  const certficationByRoleEngArchApprList = certficationByRoleEngArchApprResult.map(
    certficationByRoleEngArchAppr => {
      return {
        value: certficationByRoleEngArchAppr,
        label: certficationByRoleEngArchAppr
      };
    }
  );

  const [radio, setRadio] = React.useState(false);
  const [radioNo, setRadioNo] = React.useState(false);
  function radioCheck(event) {
    if (radio === false) {
      setRadio(true);
      setRadioNo(false);
    } else if (radio === true) {
      setRadio(false);
    }
  }
  function radioCheckNo(event) {
    if (radioNo === false) {
      setRadioNo(true);
      setRadio(false);
    } else if (radioNo === true) {
      setRadioNo(false);
    }
  }

  const [radioDeed, setRadioDeed] = React.useState(false);
  const [radioNoDeed, setRadioNoDeed] = React.useState(false);
  function radioCheckDeed(event) {
    if (radioDeed === false) {
      setRadioDeed(true);
      setRadioNoDeed(false);
    } else if (radioDeed === true) {
      setRadioDeed(false);
    }
  }
  function radioCheckNoDeed(event) {
    if (radioNoDeed === false) {
      setRadioNoDeed(true);
      setRadioDeed(false);
    } else if (radioNoDeed === true) {
      setRadioNoDeed(false);
    }
  }

  function validateDollar(value) {
    let error;
    if (value && !/^[0-9]+(\.[0-9]{1,2})?$/i.test(value)) {
      error = "Invalid Dollar Amount";
    }
    return error;
  }

  function validateDecimal8(value) {
    let error;
    if (value && !/^\d{0,6}(\.\d{1,2})?$/i.test(value)) {
      error =
        "Invalid Dollar Amount. 6 numbers allowed before decimal and 2 after decimal";
    }
    return error;
  }

  function validatePercent(value) {
    let error;
    if (
      value &&
      !/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i.test(value)
    ) {
      error = "Invalid Percentage";
    }
    return error;
  }

  function validateNumGrt1(value) {
    let error;
    if (value && !/^[1-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 5 of 9 - Additional Documents (optional)
                </div>
                <div className="section-title">Form 1051</div>
                <div className="attribute-title">
                  Name of Master Association
                </div>
                <Field
                  name="masterAssociationName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Master Association Name'
                  }}
                />
                <div className="attribute-title">Exceptions</div>
                <Field
                  name="exceptions1051"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Exceptions 1051'
                  }}
                />
                <div className="attribute-title">Notes on Master</div>
                <Field
                  name="masterAssociationNotes"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Notes on master'
                  }}
                />
                <div className="section-title">Form 1054</div>
                <div id="radio-group" className="attribute-title">
                  Has the lender submitted a completed 1054?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="submittedComplete1054Indicator"
                      value="true"
                      checked={radio}
                      onClick={radioCheck}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="submittedComplete1054Indicator"
                      value="false"
                      checked={radioNo}
                      onClick={radioCheckNo}
                    />
                    No
                  </label>
                </div>
                <div id="radio-group-1" className="attribute-title">
                  Has the lender included the Attorney Opinion Letter?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-1"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="includedAttornerOpnionLetterIndicator"
                      value="true"
                      checked={radioDeed}
                      onClick={radioCheckDeed}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="includedAttornerOpnionLetterIndicator"
                      value="false"
                      checked={radioNoDeed}
                      onClick={radioCheckNoDeed}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">Exceptions</div>
                <Field
                  name="exceptions1054"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Exceptions 1054'
                  }}
                />
                <div className="section-title">Form 1081</div>
                <div className="attribute-title">Phases Included</div>
                <Field
                  name="phasesIncluded"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Phases Included'
                  }}
                />
                <div className="attribute-title">Number of Units Included</div>
                <Field
                  name="unitsIncludedCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  validate={validateNumGrt1}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.unitsIncludedCount && errors.unitsIncludedCount
                  }
                  helperText={
                    touched.unitsIncludedCount && errors.unitsIncludedCount
                  }
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Number of units included'
                  }}
                />
                <div className="attribute-title">Certification Provided By</div>
                <Field
                  name="certificationByName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Certification provided by'
                  }}
                />
                <div className="attribute-title">
                  Professional Designation / License Number
                </div>
                <Field
                  name="professionalDesignationOrLicenseNumber"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Professional Designation'
                  }}
                />
                <div className="attribute-title">Certification Provided By</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="certficationByRoleEngArchAppr"
                    as="select"
                    aria-label='certification by role eng arch appr'
                  >
                    <option value=" ">Select Certification</option>
                    {certficationByRoleEngArchApprList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">Exceptions</div>
                <Field
                  name="exceptions1081"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Exceptions 1081'
                  }}
                />
                <div className="section-title">Budget</div>
                <div className="attribute-title">
                  Income from Assessments (HOA dues)
                </div>
                <Field
                  name="incomeFromAssessments"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ maxLength: 12 }}
                  validate={validateDecimal8}
                  error={
                    touched.incomeFromAssessments &&
                    errors.incomeFromAssessments
                  }
                  helperText={
                    touched.incomeFromAssessments &&
                    errors.incomeFromAssessments
                  }
                  inputProps={{
                    'aria-label': 'Income from assessments'
                  }}
                />
                <div className="attribute-title">Reserves</div>
                <Field
                  name="reserves"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 12 }}
                  validate={validateDecimal8}
                  variant="outlined"
                  error={touched.reserves && errors.reserves}
                  helperText={touched.reserves && errors.reserves}
                  inputProps={{
                    'aria-label': 'Reserves'
                  }}
                />
                <div className="attribute-title">
                  Percent of Reserves on Hand
                </div>
                <Field
                  name="percentageOfReservesOnHand"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 12 }}
                  validate={validatePercent}
                  variant="outlined"
                  error={
                    touched.percentageOfReservesOnHand &&
                    errors.percentageOfReservesOnHand
                  }
                  helperText={
                    touched.percentageOfReservesOnHand &&
                    errors.percentageOfReservesOnHand
                  }
                  inputProps={{
                    'aria-label': 'Percentage of reserves on hand'
                  }}
                />
                <div className="attribute-title">Exceptions</div>
                <Field
                  name="exceptionsBudget"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Exceptions budget'
                  }}
                />
                <div className="bottom-button-container">
                  <Button
                    type="submit"
                    className="next-button"
                    onClick={() => setDirection("forward")}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    type="submit"
                    className="previous-button"
                    onClick={() => setDirection("back")}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{fill:"red"}} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import React from "react";
import DatePicker from "../SharedComponents/DatePicker/DatePicker";

const DatePickerPers = ({ label, name, formData, isEdit, handleChange, min, max }) => {
  return (
    <div className="accordion-body-card-attributes">
      <div className="accordion-body-card-attributes-name">{label}</div>
      <div className="accordion-body-card-attributes-semicolon">:</div>
      <div className="accordion-body-card-attributes-value">
        {isEdit ? (
          <DatePicker
            id={`id_${name}`}
            label=""
            placeholder={"Select Date"}
            name={name}
            changeHandler={handleChange}
            value={formData[name]}
            datePickerWidth="150px"
            min={min}
            max={max}
          />
        ) : (
          formData[name]
        )}
      </div>
    </div>
  );
};

export default DatePickerPers;

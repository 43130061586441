import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { isProjectStandardRole } from "../../redux/actions/userAction";
import Add from "@material-ui/icons/Add";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Radio } from "@material-ui/core";
import axios from "axios";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const CommentModal = (props) => {
  const [show, setShow] = useState(false);
  const [showExternal, setShowExternal] = useState("internal");
  const [comment, setComment] = useState("");
  const handleShow = () => setShow(true);
  const [commentError, setCommentError] = useState(false);


  const handleClose = () => {
    setShowExternal('internal');
    setComment('')
    setShow(false);
  }

  const handleSave = (e) => {
    if (comment.length > 0) {
      const passData = JSON.parse(sessionStorage.getItem("passData"));
      const request  = {
        userName: passData.user,
        projectId: props.projectvalue,
        phaseId: props.phasevalue ?? null,
        persId: props.persvalue ?? null,
        comments: comment,
        showExternal: showExternal === "both" ? true : false,
      }
      axios
        .post(
          global.REACT_APP_API_ENDPOINT + `/condoMgr/postComments/`,
          request
        )
        .then((response) => {
          handleClose();
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCommentError(true);
    }
  };

  const radioChecked = (e) => {
    setShowExternal(e.target.value);
  }

  return (
    <>
      {isProjectStandardRole() ? (
        <button className="add-phase-button" onClick={handleShow}>
           <Add className="edit-profile-icon" />
          Add Comment
        </button>
      ) : null}

      <Modal aria-label="upload new attachment modal" show={show} onHide={handleClose} style={{paddingTop: "7.5%"}}  backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <form className="w-75 pl-3 ">
            <div className="form-group">
              <label style={{ marginTop: 8 }} >Show Comments</label>
              <ToggleButtonGroup 
                color="primary"
                value={showExternal}
                exclusive
                onChange={radioChecked}
                aria-label="text alignment"
              >
                <ToggleButton value="internal" aria-label="left aligned">
                  Internal
                </ToggleButton>
                <ToggleButton value="both" aria-label="centered">
                  Internal & External
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            {showExternal === "both" ? (
                <div className="comment-note"> Note: This will allow lenders to view this comment; use standardized language when possible.</div>
              ) : (<></>)}
          <div class="form-group">
            <label className="">Comment</label>
                <textarea
                  className="status-textarea"
                  aria-label="Comment"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  maxLength="5000"
                  value={comment}
                  style={{ paddingLeft: 8, paddingTop: 8 }}
                  onChange={(e) => {
                    setComment(e.target.value);
                    if (e.target.value === '') {
                      setCommentError(true);
                    } else {
                      setCommentError(false);
                    }
                  }}
              />
              <br />
              <span
                style={{
                  position: "absolute",
                  right: "12px",
                  fontSize: "14px",
                  color: "#787979",
                }}
              >{comment.length}/ 5000</span>
              {commentError ? (
                <div style={{color: "#ff0000"}}>  Please enter the comments.  </div>
              ) : (<></>)}

          </div>
        </form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f7f7f7" }}>
        <Button className="comments-button" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommentModal;


import { FILTER_RESULTS } from '../actions/ActionType'

export const initialState = {
        preEligible: false,
        eligible: false,
        city: '',
        lender: '',
        states: '',
        projectDeveloper: '',
        dateRange: '',
        inEligible: false
}
export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_RESULTS:
            return {
           ...initialState,...action.payload
          } 
        default:
            return state
    }
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../AddEstablishedPers.css";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const Form1076 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState("back");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const passData = JSON.parse(sessionStorage.getItem("passData"));

  let [descriptionData, setDescriptionData] = React.useState("");
  const fetchDescriptionData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/MRTGE_RSPB_EXPNS_TERM_1076`
      )
      .then(response => {
        setDescriptionData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchDescriptionData();
  }, [fetchDescriptionData]);
  const descriptionResult = Object.keys(descriptionData).map(
    key => descriptionData[key]
  );
  const descriptionList = descriptionResult.map(description => {
    return { value: description, label: description };
  });

  let [amountData, setAmountData] = React.useState("");
  const fetchAmountData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/FLOOD_CVRG_DESC_1076`
      )
      .then(response => {
        setAmountData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchAmountData();
  }, [fetchAmountData]);
  const amountResult = Object.keys(amountData).map(key => amountData[key]);
  const amountList = amountResult.map(amount => {
    return { value: amount, label: amount };
  });

  const [radio, setRadio] = React.useState(false);
  const [radioNo, setRadioNo] = React.useState(false);
  function radioCheck(event) {
    if (radio === false) {
      setRadio(true);
      setRadioNo(false);
    } else if (radio === true) {
      setRadio(false);
    }
  }
  function radioCheckNo(event) {
    if (radioNo === false) {
      setRadioNo(true);
      setRadio(false);
    } else if (radioNo === true) {
      setRadioNo(false);
    }
  }

  const [radioDeed, setRadioDeed] = React.useState(false);
  const [radioNoDeed, setRadioNoDeed] = React.useState(false);
  function radioCheckDeed(event) {
    if (radioDeed === false) {
      setRadioDeed(true);
      setRadioNoDeed(false);
    } else if (radioDeed === true) {
      setRadioDeed(false);
    }
  }
  function radioCheckNoDeed(event) {
    if (radioNoDeed === false) {
      setRadioNoDeed(true);
      setRadioDeed(false);
    } else if (radioNoDeed === true) {
      setRadioNoDeed(false);
    }
  }

  const [radiomf, setRadiomf] = React.useState(false);
  const [radioNomf, setRadioNomf] = React.useState(false);
  function radioCheckmf(event) {
    if (radiomf === false) {
      setRadiomf(true);
      setRadioNomf(false);
    } else if (radiomf === true) {
      setRadiomf(false);
    }
  }
  function radioCheckNomf(event) {
    if (radioNomf === false) {
      setRadioNomf(true);
      setRadiomf(false);
    } else if (radioNomf === true) {
      setRadioNomf(false);
    }
  }

  const [radiomnd, setRadiomnd] = React.useState(false);
  const [radioNomnd, setRadioNomnd] = React.useState(false);
  function radioCheckmnd(event) {
    if (radiomnd === false) {
      setRadiomnd(true);
      setRadioNomnd(false);
    } else if (radiomnd === true) {
      setRadiomnd(false);
    }
  }
  function radioCheckNomnd(event) {
    if (radioNomnd === false) {
      setRadioNomnd(true);
      setRadiomnd(false);
    } else if (radioNomnd === true) {
      setRadioNomnd(false);
    }
  }

  const [radioincdnt, setRadioincdnt] = React.useState(false);
  const [radioNoincdnt, setRadioNoincdnt] = React.useState(false);
  function radioCheckincdnt(event) {
    if (radioincdnt === false) {
      setRadioincdnt(true);
      setRadioNoincdnt(false);
    } else if (radioincdnt === true) {
      setRadioincdnt(false);
    }
  }
  function radioCheckNoincdnt(event) {
    if (radioNoincdnt === false) {
      setRadioNoincdnt(true);
      setRadioincdnt(false);
    } else if (radioNoincdnt === true) {
      setRadioNoincdnt(false);
    }
  }

  const [radiosupprt, setRadiosupprt] = React.useState(false);
  const [radioNosupprt, setRadioNosupprt] = React.useState(false);
  function radioChecksupprt(event) {
    if (radiosupprt === false) {
      setRadiosupprt(true);
      setRadioNosupprt(false);
    } else if (radiosupprt === true) {
      setRadiosupprt(false);
    }
  }
  function radioCheckNosupprt(event) {
    if (radioNosupprt === false) {
      setRadioNosupprt(true);
      setRadiosupprt(false);
    } else if (radioNosupprt === true) {
      setRadioNosupprt(false);
    }
  }

  const [radioproj100, setRadioproj100] = React.useState(false);
  const [radioNoproj100, setRadioNoproj100] = React.useState(false);
  function radioCheckproj100(event) {
    if (radioproj100 === false) {
      setRadioproj100(true);
      setRadioNoproj100(false);
    } else if (radioproj100 === true) {
      setRadioproj100(false);
    }
  }
  function radioCheckNoproj100(event) {
    if (radioNoproj100 === false) {
      setRadioNoproj100(true);
      setRadioproj100(false);
    } else if (radioNoproj100 === true) {
      setRadioNoproj100(false);
    }
  }

  const [radiosubj, setRadiosubj] = React.useState(false);
  const [radioNosubj, setRadioNosubj] = React.useState(false);
  function radioChecksubj(event) {
    if (radiosubj === false) {
      setRadiosubj(true);
      setRadioNosubj(false);
    } else if (radiosubj === true) {
      setRadiosubj(false);
    }
  }
  function radioCheckNosubj(event) {
    if (radioNosubj === false) {
      setRadioNosubj(true);
      setRadiosubj(false);
    } else if (radioNosubj === true) {
      setRadioNosubj(false);
    }
  }

  const [radiolegally, setRadiolegally] = React.useState(false);
  const [radioNolegally, setRadioNolegally] = React.useState(false);
  function radioChecklegally(event) {
    if (radiolegally === false) {
      setRadiolegally(true);
      setRadioNolegally(false);
    } else if (radiolegally === true) {
      setRadiolegally(false);
    }
  }
  function radioCheckNolegally(event) {
    if (radioNolegally === false) {
      setRadioNolegally(true);
      setRadiolegally(false);
    } else if (radioNolegally === true) {
      setRadioNolegally(false);
    }
  }

  const [radioplanned, setRadioplanned] = React.useState(false);
  const [radioNoplanned, setRadioNoplanned] = React.useState(false);
  function radioCheckplanned(event) {
    if (radioplanned === false) {
      setRadioplanned(true);
      setRadioNoplanned(false);
    } else if (radioplanned === true) {
      setRadioplanned(false);
    }
  }
  function radioCheckNoplanned(event) {
    if (radioNoplanned === false) {
      setRadioNoplanned(true);
      setRadioplanned(false);
    } else if (radioNoplanned === true) {
      setRadioNoplanned(false);
    }
  }

  const [radiotrnsfr, setRadiotrnsfr] = React.useState(false);
  const [radioNotrnsfr, setRadioNotrnsfr] = React.useState(false);
  function radioChecktrnsfr(event) {
    if (radiotrnsfr === false) {
      setRadiotrnsfr(true);
      setRadioNotrnsfr(false);
    } else if (radiotrnsfr === true) {
      setRadiotrnsfr(false);
    }
  }
  function radioCheckNotrnsfr(event) {
    if (radioNotrnsfr === false) {
      setRadioNotrnsfr(true);
      setRadiotrnsfr(false);
    } else if (radioNotrnsfr === true) {
      setRadioNotrnsfr(false);
    }
  }

  const [radioconvrsn, setRadioconvrsn] = React.useState(false);
  const [radioNoconvrsn, setRadioNoconvrsn] = React.useState(false);
  function radioCheckconvrsn(event) {
    if (radioconvrsn === false) {
      setRadioconvrsn(true);
      setRadioNoconvrsn(false);
    } else if (radioconvrsn === true) {
      setRadioconvrsn(false);
    }
  }
  function radioCheckNoconvrsn(event) {
    if (radioNoconvrsn === false) {
      setRadioNoconvrsn(true);
      setRadioconvrsn(false);
    } else if (radioNoconvrsn === true) {
      setRadioNoconvrsn(false);
    }
  }

  const [radiofullGut, setRadiofullGut] = React.useState(false);
  const [radioNofullGut, setRadioNofullGut] = React.useState(false);
  function radioCheckfullGut(event) {
    if (radiofullGut === false) {
      setRadiofullGut(true);
      setRadioNofullGut(false);
    } else if (radiofullGut === true) {
      setRadiofullGut(false);
    }
  }
  function radioCheckNofullGut(event) {
    if (radioNofullGut === false) {
      setRadioNofullGut(true);
      setRadiofullGut(false);
    } else if (radioNofullGut === true) {
      setRadioNofullGut(false);
    }
  }

  const [radiostructrlly, setRadiostructrlly] = React.useState(false);
  const [radioNostructrlly, setRadioNostructrlly] = React.useState(false);
  function radioCheckstructrlly(event) {
    if (radiostructrlly === false) {
      setRadiostructrlly(true);
      setRadioNostructrlly(false);
    } else if (radiostructrlly === true) {
      setRadiostructrlly(false);
    }
  }
  function radioCheckNostructrlly(event) {
    if (radioNostructrlly === false) {
      setRadioNostructrlly(true);
      setRadiostructrlly(false);
    } else if (radioNostructrlly === true) {
      setRadioNostructrlly(false);
    }
  }

  const [radiorepairs, setRadiorepairs] = React.useState(false);
  const [radioNorepairs, setRadioNorepairs] = React.useState(false);
  function radioCheckrepairs(event) {
    if (radiorepairs === false) {
      setRadiorepairs(true);
      setRadioNorepairs(false);
    } else if (radiorepairs === true) {
      setRadiorepairs(false);
    }
  }
  function radioCheckNorepairs(event) {
    if (radioNorepairs === false) {
      setRadioNorepairs(true);
      setRadiorepairs(false);
    } else if (radioNorepairs === true) {
      setRadioNorepairs(false);
    }
  }

  const [radioreplacemnt, setRadioreplacemnt] = React.useState(false);
  const [radioNoreplacemnt, setRadioNoreplacemnt] = React.useState(false);
  function radioCheckreplacemnt(event) {
    if (radioreplacemnt === false) {
      setRadioreplacemnt(true);
      setRadioNoreplacemnt(false);
    } else if (radioreplacemnt === true) {
      setRadioreplacemnt(false);
    }
  }
  function radioCheckNoreplacemnt(event) {
    if (radioNoreplacemnt === false) {
      setRadioNoreplacemnt(true);
      setRadioreplacemnt(false);
    } else if (radioNoreplacemnt === true) {
      setRadioNoreplacemnt(false);
    }
  }

  const [radioresrvsTo, setRadioresrvsTo] = React.useState(false);
  const [radioNoresrvsTo, setRadioNoresrvsTo] = React.useState(false);
  function radioCheckresrvsTo(event) {
    if (radioresrvsTo === false) {
      setRadioresrvsTo(true);
      setRadioNoresrvsTo(false);
    } else if (radioresrvsTo === true) {
      setRadioresrvsTo(false);
    }
  }
  function radioCheckNoresrvsTo(event) {
    if (radioNoresrvsTo === false) {
      setRadioNoresrvsTo(true);
      setRadioresrvsTo(false);
    } else if (radioNoresrvsTo === true) {
      setRadioNoresrvsTo(false);
    }
  }

  const [radiomrtgeeRes, setRadiomrtgeeRes] = React.useState(false);
  const [radioNomrtgeeRes, setRadioNomrtgeeRes] = React.useState(false);
  function radioCheckmrtgeeRes(event) {
    if (radiomrtgeeRes === false) {
      setRadiomrtgeeRes(true);
      setRadioNomrtgeeRes(false);
    } else if (radiomrtgeeRes === true) {
      setRadiomrtgeeRes(false);
    }
  }
  function radioCheckNomrtgeeRes(event) {
    if (radioNomrtgeeRes === false) {
      setRadioNomrtgeeRes(true);
      setRadiomrtgeeRes(false);
    } else if (radioNomrtgeeRes === true) {
      setRadioNomrtgeeRes(false);
    }
  }

  const [radiohoaInv, setRadiohoaInv] = React.useState(false);
  const [radioNohoaInv, setRadioNohoaInv] = React.useState(false);
  function radioCheckhoaInv(event) {
    if (radiohoaInv === false) {
      setRadiohoaInv(true);
      setRadioNohoaInv(false);
    } else if (radiohoaInv === true) {
      setRadiohoaInv(false);
    }
  }
  function radioCheckNohoaInv(event) {
    if (radioNohoaInv === false) {
      setRadioNohoaInv(true);
      setRadiohoaInv(false);
    } else if (radioNohoaInv === true) {
      setRadioNohoaInv(false);
    }
  }

  const [radiosoleOwnrship, setRadiosoleOwnrship] = React.useState(false);
  const [radioNosoleOwnrship, setRadioNosoleOwnrship] = React.useState(false);
  function radioChecksoleOwnrship(event) {
    if (radiosoleOwnrship === false) {
      setRadiosoleOwnrship(true);
      setRadioNosoleOwnrship(false);
    } else if (radiosoleOwnrship === true) {
      setRadiosoleOwnrship(false);
    }
  }
  function radioCheckNosoleOwnrship(event) {
    if (radioNosoleOwnrship === false) {
      setRadioNosoleOwnrship(true);
      setRadiosoleOwnrship(false);
    } else if (radioNosoleOwnrship === true) {
      setRadioNosoleOwnrship(false);
    }
  }

  const [radiounitsUsed, setRadiounitsUsed] = React.useState(false);
  const [radioNounitsUsed, setRadioNounitsUsed] = React.useState(false);
  function radioCheckunitsUsed(event) {
    if (radiounitsUsed === false) {
      setRadiounitsUsed(true);
      setRadioNounitsUsed(false);
    } else if (radiounitsUsed === true) {
      setRadiounitsUsed(false);
    }
  }
  function radioCheckNounitsUsed(event) {
    if (radioNounitsUsed === false) {
      setRadioNounitsUsed(true);
      setRadiounitsUsed(false);
    } else if (radioNounitsUsed === true) {
      setRadioNounitsUsed(false);
    }
  }

  const [radiofloodZone, setRadiofloodZone] = React.useState(false);
  const [radioNofloodZone, setRadioNofloodZone] = React.useState(false);
  function radioCheckfloodZone(event) {
    if (radiofloodZone === false) {
      setRadiofloodZone(true);
      setRadioNofloodZone(false);
    } else if (radiofloodZone === true) {
      setRadiofloodZone(false);
    }
  }
  function radioCheckNofloodZone(event) {
    if (radioNofloodZone === false) {
      setRadioNofloodZone(true);
      setRadiofloodZone(false);
    } else if (radioNofloodZone === true) {
      setRadioNofloodZone(false);
    }
  }

  const [radiohoaAccts, setRadiohoaAccts] = React.useState(false);
  const [radioNohoaAccts, setRadioNohoaAccts] = React.useState(false);
  function radioCheckhoaAccts(event) {
    if (radiohoaAccts === false) {
      setRadiohoaAccts(true);
      setRadioNohoaAccts(false);
    } else if (radiohoaAccts === true) {
      setRadiohoaAccts(false);
    }
  }
  function radioCheckNohoaAccts(event) {
    if (radioNohoaAccts === false) {
      setRadioNohoaAccts(true);
      setRadiohoaAccts(false);
    } else if (radioNohoaAccts === true) {
      setRadioNohoaAccts(false);
    }
  }

  const [radiohoaAcctsAcess, setRadiohoaAcctsAcess] = React.useState(false);
  const [radioNohoaAcctsAcess, setRadioNohoaAcctsAcess] = React.useState(false);
  function radioCheckhoaAcctsAcess(event) {
    if (radiohoaAcctsAcess === false) {
      setRadiohoaAcctsAcess(true);
      setRadioNohoaAcctsAcess(false);
    } else if (radiohoaAcctsAcess === true) {
      setRadiohoaAcctsAcess(false);
    }
  }
  function radioCheckNohoaAcctsAcess(event) {
    if (radioNohoaAcctsAcess === false) {
      setRadioNohoaAcctsAcess(true);
      setRadiohoaAcctsAcess(false);
    } else if (radioNohoaAcctsAcess === true) {
      setRadioNohoaAcctsAcess(false);
    }
  }

  const [radiohoaAcctsMnthly, setRadiohoaAcctsMnthly] = React.useState(false);
  const [radioNohoaAcctsMnthly, setRadioNohoaAcctsMnthly] = React.useState(
    false
  );
  function radioCheckhoaAcctsMnthly(event) {
    if (radiohoaAcctsMnthly === false) {
      setRadiohoaAcctsMnthly(true);
      setRadioNohoaAcctsMnthly(false);
    } else if (radiohoaAcctsMnthly === true) {
      setRadiohoaAcctsMnthly(false);
    }
  }
  function radioCheckNohoaAcctsMnthly(event) {
    if (radioNohoaAcctsMnthly === false) {
      setRadioNohoaAcctsMnthly(true);
      setRadiohoaAcctsMnthly(false);
    } else if (radioNohoaAcctsMnthly === true) {
      setRadioNohoaAcctsMnthly(false);
    }
  }

  const [radiohoaAcctsCheck, setRadiohoaAcctsCheck] = React.useState(false);
  const [radioNohoaAcctsCheck, setRadioNohoaAcctsCheck] = React.useState(false);
  function radioCheckhoaAcctsCheck(event) {
    if (radiohoaAcctsCheck === false) {
      setRadiohoaAcctsCheck(true);
      setRadioNohoaAcctsCheck(false);
    } else if (radiohoaAcctsCheck === true) {
      setRadiohoaAcctsCheck(false);
    }
  }
  function radioCheckNohoaAcctsCheck(event) {
    if (radioNohoaAcctsCheck === false) {
      setRadioNohoaAcctsCheck(true);
      setRadiohoaAcctsCheck(false);
    } else if (radioNohoaAcctsCheck === true) {
      setRadioNohoaAcctsCheck(false);
    }
  }

  const [radiohoaAcctsMngmt, setRadiohoaAcctsMngmt] = React.useState(false);
  const [radioNohoaAcctsMngmt, setRadioNohoaAcctsMngmt] = React.useState(false);
  function radioCheckhoaAcctsMngmt(event) {
    if (radiohoaAcctsMngmt === false) {
      setRadiohoaAcctsMngmt(true);
      setRadioNohoaAcctsMngmt(false);
    } else if (radiohoaAcctsMngmt === true) {
      setRadiohoaAcctsMngmt(false);
    }
  }
  function radioCheckNohoaAcctsMngmt(event) {
    if (radioNohoaAcctsMngmt === false) {
      setRadioNohoaAcctsMngmt(true);
      setRadiohoaAcctsMngmt(false);
    } else if (radioNohoaAcctsMngmt === true) {
      setRadioNohoaAcctsMngmt(false);
    }
  }

  const [radiohoaAcctsMngmtCmpny, setRadiohoaAcctsMngmtCmpny] = React.useState(
    false
  );
  const [
    radioNohoaAcctsMngmtCmpny,
    setRadioNohoaAcctsMngmtCmpny
  ] = React.useState(false);
  function radioCheckhoaAcctsMngmtCmpny(event) {
    if (radiohoaAcctsMngmtCmpny === false) {
      setRadiohoaAcctsMngmtCmpny(true);
      setRadioNohoaAcctsMngmtCmpny(false);
    } else if (radiohoaAcctsMngmtCmpny === true) {
      setRadiohoaAcctsMngmtCmpny(false);
    }
  }
  function radioCheckNohoaAcctsMngmtCmpny(event) {
    if (radioNohoaAcctsMngmtCmpny === false) {
      setRadioNohoaAcctsMngmtCmpny(true);
      setRadiohoaAcctsMngmtCmpny(false);
    } else if (radioNohoaAcctsMngmtCmpny === true) {
      setRadioNohoaAcctsMngmtCmpny(false);
    }
  }

  function validateEmail(value) {
    let error;
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid Email Address";
    }
    return error;
  }

  function validateDecimal6(value) {
    let error;
    if (value && !/^\d{0,4}(\.\d{1,2})?$/i.test(value)) {
      error = "Invalid. 4 numbers allowed before decimal and 2 after decimal";
    }
    return error;
  }

  function validatePercent(value) {
    let error;
    if (
      value &&
      !/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i.test(value)
    ) {
      error = "Invalid Percentage";
    }
    return error;
  }

  function validateNumNoStr(value) {
    let error;
    if (value && !/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNumNoStr1(value) {
    let error;
    if (value && !/^[0-9]\d*$/i.test(value)) {
      error = "Invalid must be a number greater than 0";
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (value && !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i.test(value)) {
      error = "Invalid Name";
    }
    return error;
  }

  function validateTax(value) {
    let error;
    if (value && !/[0-9]{2}-[0-9]{7}$/i.test(value)) {
      error = "Invalid Tax ID. Format: XX-XXXXXXX";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    if (
      value &&
      !/^(?:(?:\\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
        value
      )
    ) {
      error = "Invalid Phone Number. eg((555)-555-5555, 555-555-5555)";
    }
    return error;
  }
  const [duplicateIndvNameError, setDuplicateIndvNameError] = useState(false);
  const [duplicateOwnerNameError, setDuplicateOwnerNameError] = useState(false);

  let getDuplicateIndvName = data => {
    var valueArr = data.map(function(item) {
      return item.indvName;
    });
    var duplicateItem = [];
    var isDuplicate = valueArr.some(function(item, idx) {
      // return ;
      if (item != "") {
        if (valueArr.indexOf(item) != idx) {
          data[idx].isError = true;
          duplicateItem.push(idx);
          setDuplicateIndvNameError(true);
          return true;
        } else {
          data[idx].isError = false;
          setDuplicateIndvNameError(false);
          return false;
        }
      }
    });

    console.log("isDuplicate", isDuplicate, duplicateItem, data);

    return isDuplicate;
  };

  let getDuplicateOwnerName = data => {
    var valueArr = data.map(function(item) {
      return item.ownrTenantName;
    });

    console.log("valueArr", valueArr);
    var duplicateItem = [];
    var isDuplicate = valueArr.some(function(item, idx) {
      // return ;
      if (item != "") {
        if (valueArr.indexOf(item) != idx) {
          data[idx].isError = true;
          duplicateItem.push(idx);
          setDuplicateOwnerNameError(true);
          return true;
        } else {
          data[idx].isError = false;
          setDuplicateOwnerNameError(false);
          return false;
        }
      }
    });

    console.log("isDuplicate", isDuplicate, duplicateItem, data);

    return isDuplicate;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          let isDuplicateIndv = getDuplicateIndvName(
            values.unitOwnrshp1076DataList
          );

          let isDuplicateOwner;

          if (values.unitsUsedCommrclPurpseInd) {
            isDuplicateOwner = getDuplicateOwnerName(
              values.commUse1076DataList
            );
          }

          console.log("isDuplicateIndv", isDuplicateIndv);
          if (!isDuplicateIndv) {
            if (values.unitsUsedCommrclPurpseInd) {
              if (!isDuplicateOwner) {
                setFormData(values);
                direction === "back" ? prevStep() : nextStep();
              }
            } else {
              setFormData(values);
              direction === "back" ? prevStep() : nextStep();
            }
          }
        }}
      >
        {({ values, errors, touched}) => (
          
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add Established PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 2 of 9 - HOA Questionnaire (optional)
                </div>
                <div className="attribute-title">Project Legal Name</div>
                <Field
                  name="projLegalName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label':'Project Legal Name' }}
                  variant="outlined"
                />
                <div className="attribute-title">Project Physical Address</div>
                <Field
                  name="projPhyAddr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'Project Physical Address' }}
                  variant="outlined"
                />
                <div className="attribute-title">HOA Management Address</div>
                <Field
                  name="hoaMgmtAddr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'HOA Management Address' }}
                  variant="outlined"
                />
                <div className="attribute-title">
                  HOA Name (If different from Project Legal Name)
                </div>
                <Field
                  name="hoaName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label':'Hoa Name id different from project name' }}
                  variant="outlined"
                />
                <div className="attribute-title">HOA Tax ID Number</div>
                <Field
                  name="hoaTaxId"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label':'HOA Tax ID Number' }}
                  validate={validateTax}
                  variant="outlined"
                  error={touched.hoaTaxId && errors.hoaTaxId}
                  helperText={touched.hoaTaxId && errors.hoaTaxId}
                />
                <div className="attribute-title">
                  HOA Management Company Tax ID Number
                </div>
                <Field
                  name="hoaMgmtCmpnyTaxId"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label': 'HOA Management Company Tax ID number' }}
                  validate={validateTax}
                  variant="outlined"
                  error={touched.hoaMgmtCmpnyTaxId && errors.hoaMgmtCmpnyTaxId}
                  helperText={
                    touched.hoaMgmtCmpnyTaxId && errors.hoaMgmtCmpnyTaxId
                  }
                />
                <div className="attribute-title">
                  Name of Master or Umbrella Association (If Applicable)
                </div>
                <Field
                  name="mstrAssocName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label':'Name of master or umbrella association' }}
                  variant="outlined"
                />
                <div className="attribute-title">
                  Does the project contain any of the following (check all that
                  apply):
                </div>
                <div className="attribute-title">
                  Hotel/motel/resort activities, mandatory or voluntary rental-
                  pooling arrangements, or other restrictions on the unitowner’s
                  ability to occupy the unit
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="rentalPoolngArrngmntInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radio}
                      onClick={radioCheck}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="rentalPoolngArrngmntInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNo}
                      onClick={radioCheckNo}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Deed or resale restrictions
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="deedResaleRestrctnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radioDeed}
                      onClick={radioCheckDeed}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="deedResaleRestrctnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNoDeed}
                      onClick={radioCheckNoDeed}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">Manufactured Homes</div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="mfHomeInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiomf}
                      onClick={radioCheckmf}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="mfHomeInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNomf}
                      onClick={radioCheckNomf}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Mandatory fee-based memberships for use of project amenities
                  or services
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="mndtryMbrshipInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiomnd}
                      onClick={radioCheckmnd}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="mndtryMbrshipInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNomnd}
                      onClick={radioCheckNomnd}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Non-incidental income from business operations
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="nonIncdntlIncomeInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radioincdnt}
                      onClick={radioCheckincdnt}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="nonIncdntlIncomeInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNoincdnt}
                      onClick={radioCheckNoincdnt}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Supportive or continuing care for seniors or for residents
                  with disabilities
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="supprtCareSeniorsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiosupprt}
                      onClick={radioChecksupprt}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="supprtCareSeniorsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNosupprt}
                      onClick={radioCheckNosupprt}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Is the project 100% complete, including all construction or
                  renovation of units, common elements, and shared amenities for
                  all project phases?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="proj100pctCompltnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radioproj100}
                      onClick={radioCheckproj100}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="proj100pctCompltnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNoproj100}
                      onClick={radioCheckNoproj100}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Is the project subject to additional phasing or annexation?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="subjAddtnlPhasingInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiosubj}
                      onClick={radioChecksubj}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="subjAddtnlPhasingInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNosubj}
                      onClick={radioCheckNosubj}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Is the project legally phased?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="legallyPhasedInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiolegally}
                      onClick={radioChecklegally}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="legallyPhasedInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNolegally}
                      onClick={radioCheckNolegally}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  How many phases have been completed?
                </div>
                <Field
                  name="noOfPhasesCmpltd"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.noOfPhasesCmpltd && errors.noOfPhasesCmpltd}
                  helperText={
                    touched.noOfPhasesCmpltd && errors.noOfPhasesCmpltd
                  }
                />
                <div className="attribute-title">
                  How many total phases are legally planned for the project?
                </div>
                <Field
                  name="totalPhasesLegallyPlanned"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label': 'Total Phases legally planned' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totalPhasesLegallyPlanned &&
                    errors.totalPhasesLegallyPlanned
                  }
                  helperText={
                    touched.totalPhasesLegallyPlanned &&
                    errors.totalPhasesLegallyPlanned
                  }
                />
                <div className="attribute-title">
                  How many total units are planned for the project?
                </div>
                <Field
                  name="totalUnitsPlanned"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label': 'Total units planned' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.totalUnitsPlanned && errors.totalUnitsPlanned}
                  helperText={
                    touched.totalUnitsPlanned && errors.totalUnitsPlanned
                  }
                />
                <div className="attribute-title">
                  Are all planned amenities and common facilities fully
                  complete?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="plannedAmenitiesCompltnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radioplanned}
                      onClick={radioCheckplanned}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="plannedAmenitiesCompltnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNoplanned}
                      onClick={radioCheckNoplanned}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Has the developer transferred control of the HOA to the unit
                  owners?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="trnsfrControlHoaInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiotrnsfr}
                      onClick={radioChecktrnsfr}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="trnsfrControlHoaInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNotrnsfr}
                      onClick={radioCheckNotrnsfr}
                    />
                    No
                  </label>
                </div>
                {values.trnsfrControlHoaInd === true ||
                values.trnsfrControlHoaInd === "true" ? (
                  <>
                    <div className="attribute-title">Date Transferred</div>
                    <Field
                      name="dtTrnsfrControlHoa"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="section-title">
                  Newly Converted or Rehabilitated Project Information
                </div>
                <div className="attribute-title">
                  Is the project a conversion within the past 3 years of an
                  existing structure that was used as an apartment,
                  hotel/resort, retail or professional business, industrial or
                  for other non-residential use?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="convrsnOfExistngStructureInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radioconvrsn}
                      onClick={radioCheckconvrsn}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="convrsnOfExistngStructureInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNoconvrsn}
                      onClick={radioCheckNoconvrsn}
                    />
                    No
                  </label>
                </div>
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      In what year was the property built?
                    </div>
                    <Field
                      name="yrBuilt"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 4, 'aria-label': 'Year built' }}
                      validate={validateNumNoStr}
                      variant="outlined"
                      error={touched.yrBuilt && errors.yrBuilt}
                      helperText={touched.yrBuilt && errors.yrBuilt}
                    />
                  </>
                ) : (
                  ""
                )}
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      In what year was the property converted?
                    </div>
                    <Field
                      name="yrConvrtd"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 4, 'aria-label': 'Year converted' }}
                      validate={validateNumNoStr}
                      variant="outlined"
                      error={touched.yrConvrtd && errors.yrConvrtd}
                      helperText={touched.yrConvrtd && errors.yrConvrtd}
                    />
                  </>
                ) : (
                  ""
                )}
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      Was the conversion a full gut rehabilitation of the
                      existing structure(s), including replacement of all major
                      mechanical components?
                    </div>
                    <div
                      role="group"
                      aria-labelledby="radio-group"
                      className="radio-box"
                    >
                      <label>
                        <Field
                          name="fullGutRehabConvrsnInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="true"
                          checked={radiofullGut}
                          onClick={radioCheckfullGut}
                        />
                        Yes
                      </label>
                      <label>
                        <Field
                          name="fullGutRehabConvrsnInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="false"
                          checked={radioNofullGut}
                          onClick={radioCheckNofullGut}
                        />
                        No
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      Does the report from the licensed engineer indicate that
                      the project is structurally sound, and that the condition
                      and remaining useful life of the project’s major
                      components are sufficient?
                    </div>
                    <div
                      role="group"
                      aria-labelledby="radio-group"
                      className="radio-box"
                    >
                      <label>
                        <Field
                          name="structrllySoundInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="true"
                          checked={radiostructrlly}
                          onClick={radioCheckstructrlly}
                        />
                        Yes
                      </label>
                      <label>
                        <Field
                          name="structrllySoundInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="false"
                          checked={radioNostructrlly}
                          onClick={radioCheckNostructrlly}
                        />
                        No
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      Are all repairs affecting safety, soundness, and
                      structural integrity complete?
                    </div>
                    <div
                      role="group"
                      aria-labelledby="radio-group"
                      className="radio-box"
                    >
                      <label>
                        <Field
                          name="repairsCompltnInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="true"
                          checked={radiorepairs}
                          onClick={radioCheckrepairs}
                        />
                        Yes
                      </label>
                      <label>
                        <Field
                          name="repairsCompltnInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="false"
                          checked={radioNorepairs}
                          onClick={radioCheckNorepairs}
                        />
                        No
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      Are replacement reserves allocated for all capital
                      improvements?
                    </div>
                    <div
                      role="group"
                      aria-labelledby="radio-group"
                      className="radio-box"
                    >
                      <label>
                        <Field
                          name="replacemntResrvsAlloctnInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="true"
                          checked={radioreplacemnt}
                          onClick={radioCheckreplacemnt}
                        />
                        Yes
                      </label>
                      <label>
                        <Field
                          name="replacemntResrvsAlloctnInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="false"
                          checked={radioNoreplacemnt}
                          onClick={radioCheckNoreplacemnt}
                        />
                        No
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {values.convrsnOfExistngStructureInd === true ||
                values.convrsnOfExistngStructureInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      Are the project’s reserves sufficient to fund the
                      improvements?
                    </div>
                    <div
                      role="group"
                      aria-labelledby="radio-group"
                      className="radio-box"
                    >
                      <label>
                        <Field
                          name="resrvsToFundImprovmntsInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="true"
                          checked={radioresrvsTo}
                          onClick={radioCheckresrvsTo}
                        />
                        Yes
                      </label>
                      <label>
                        <Field
                          name="resrvsToFundImprovmntsInd"
                          as={Radio}
                          type="radio"
                          variant="outlined"
                          value="false"
                          checked={radioNoresrvsTo}
                          onClick={radioCheckNoresrvsTo}
                        />
                        No
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="section-title">Financial Information</div>
                <div className="attribute-title">
                  How many unit owners are 60 or more days delinquent on common
                  expense assessments?
                </div>
                <Field
                  name="unitOwnrsDelinqCommnExpnseCnt"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Unit Owner delinquent Common expense count' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.unitOwnrsDelinqCommnExpnseCnt &&
                    errors.unitOwnrsDelinqCommnExpnseCnt
                  }
                  helperText={
                    touched.unitOwnrsDelinqCommnExpnseCnt &&
                    errors.unitOwnrsDelinqCommnExpnseCnt
                  }
                />
                <div className="attribute-title">
                  In the event a lender acquires a unit due to foreclosure or a
                  deed-in-lieu of foreclosure, is the mortgage responsible for
                  paying delinquent common expense assessments?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="mrtgeeRespnbleDelinqExpnseInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiomrtgeeRes}
                      onClick={radioCheckmrtgeeRes}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="mrtgeeRespnbleDelinqExpnseInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNomrtgeeRes}
                      onClick={radioCheckNomrtgeeRes}
                    />
                    No
                  </label>
                </div>
                {values.mrtgeeRespnbleDelinqExpnseInd === true ||
                values.mrtgeeRespnbleDelinqExpnseInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      For how long is the mortgagee responsible for paying
                      common expense assessments?
                    </div>
                    <div className="attribute-select">
                      <Field
                        className="attribute-select-dropdown"
                        name="mrtgeeRespnbleDelinqExpnseTerm"
                        as="select"
                        aria-label='Mortgage Responsible for delinquent expense term'
                      >
                        <option value=" ">Select Term</option>
                        {descriptionList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="attribute-title">
                  Is the HOA involved in any active or pending litigation?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaInvlvdLitigtnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaInv}
                      onClick={radioCheckhoaInv}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaInvlvdLitigtnInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaInv}
                      onClick={radioCheckNohoaInv}
                    />
                    No
                  </label>
                </div>
                {values.hoaInvlvdLitigtnInd === true ||
                values.hoaInvlvdLitigtnInd === "true" ? (
                  <>
                    <div className="attribute-title">Attorney's Name</div>
                    <Field
                      name="litigAttrneyName"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      inputProps = {{
                        'aria-label': 'Attorneys name'
                      }}
                    />
                    <div className="attribute-title">
                      Attorney's Phone Number
                    </div>
                    <Field
                      name="litigAttrneyPhone"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 16, 'aria-label': 'Attorney phone number' }}
                      validate={validatePhone}
                      variant="outlined"
                      error={
                        touched.litigAttrneyPhone && errors.litigAttrneyPhone
                      }
                      helperText={
                        touched.litigAttrneyPhone && errors.litigAttrneyPhone
                      }
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="section-title">
                  Ownership & Other Information
                </div>
                <div className="attribute-title">
                  Total number of units for Entire Project
                </div>
                <Field
                  name="totUnitsProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total number of units for entire project' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.totUnitsProj && errors.totUnitsProj}
                  helperText={touched.totUnitsProj && errors.totUnitsProj}
                />
                <div className="attribute-title">
                  Total number of units for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units phase' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.totUnitsPhase && errors.totUnitsPhase}
                  helperText={touched.totUnitsPhase && errors.totUnitsPhase}
                />
                <div className="attribute-title">
                  Total number of units sold and closed for Entire Project
                </div>
                <Field
                  name="totUnitsSoldClosedProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total number of units sold' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsSoldClosedProj &&
                    errors.totUnitsSoldClosedProj
                  }
                  helperText={
                    touched.totUnitsSoldClosedProj &&
                    errors.totUnitsSoldClosedProj
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsSoldClosedPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total number of units sold and closed' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsSoldClosedPhase &&
                    errors.totUnitsSoldClosedPhase
                  }
                  helperText={
                    touched.totUnitsSoldClosedPhase &&
                    errors.totUnitsSoldClosedPhase
                  }
                />
                <div className="attribute-title">
                  Total number of units under bona-fide sales contracts for
                  Entire Project
                </div>
                <Field
                  name="totUnitsBonafidSalesProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units bonafide sales project' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsBonafidSalesProj &&
                    errors.totUnitsBonafidSalesProj
                  }
                  helperText={
                    touched.totUnitsBonafidSalesProj &&
                    errors.totUnitsBonafidSalesProj
                  }
                />
                <div className="attribute-title">
                  Total number of units under bona-fide sales contracts for
                  Subject Legal Phase
                </div>
                <Field
                  name="totUnitsBonafidSalesPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units bonafide sales phase' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsBonafidSalesPhase &&
                    errors.totUnitsBonafidSalesPhase
                  }
                  helperText={
                    touched.totUnitsBonafidSalesPhase &&
                    errors.totUnitsBonafidSalesPhase
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed or under contract to
                  owner-occupants for Entire Project
                </div>
                <Field
                  name="totUnitsOwnrOccpntProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units owner occupied' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsOwnrOccpntProj &&
                    errors.totUnitsOwnrOccpntProj
                  }
                  helperText={
                    touched.totUnitsOwnrOccpntProj &&
                    errors.totUnitsOwnrOccpntProj
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed or under contract to
                  owner-occupants for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsOwnrOccpntPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units sold and closed under contract to owner-occupants' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsOwnrOccpntPhase &&
                    errors.totUnitsOwnrOccpntPhase
                  }
                  helperText={
                    touched.totUnitsOwnrOccpntPhase &&
                    errors.totUnitsOwnrOccpntPhase
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed or under contract to
                  second home owners for Entire Project
                </div>
                <Field
                  name="totUnitsSecOwnrProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units second owner project' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsSecOwnrProj && errors.totUnitsSecOwnrProj
                  }
                  helperText={
                    touched.totUnitsSecOwnrProj && errors.totUnitsSecOwnrProj
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed or under contract to
                  second home owners for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsSecOwnrPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units second owner phase' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsSecOwnrPhase && errors.totUnitsSecOwnrPhase
                  }
                  helperText={
                    touched.totUnitsSecOwnrPhase && errors.totUnitsSecOwnrPhase
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed or under contract to
                  investor owners for Entire Project
                </div>
                <Field
                  name="totUnitsInvstrOwnrProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units investor own project' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsInvstrOwnrProj &&
                    errors.totUnitsInvstrOwnrProj
                  }
                  helperText={
                    touched.totUnitsInvstrOwnrProj &&
                    errors.totUnitsInvstrOwnrProj
                  }
                />
                <div className="attribute-title">
                  Total number of units sold and closed or under contract to
                  investor owners for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsInvstrOwnrPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units investor owner phase' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsInvstrOwnrPhase &&
                    errors.totUnitsInvstrOwnrPhase
                  }
                  helperText={
                    touched.totUnitsInvstrOwnrPhase &&
                    errors.totUnitsInvstrOwnrPhase
                  }
                />
                <div className="attribute-title">
                  Total number of units being rented by developer, sponsor, or
                  converter for Entire Project
                </div>
                <Field
                  name="totUnitsRentedProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units rented for project' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsRentedProj && errors.totUnitsRentedProj
                  }
                  helperText={
                    touched.totUnitsRentedProj && errors.totUnitsRentedProj
                  }
                />
                <div className="attribute-title">
                  Total number of units being rented by developer, sponsor, or
                  converter for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsRentedPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units rented for phase' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsRentedPhase && errors.totUnitsRentedPhase
                  }
                  helperText={
                    touched.totUnitsRentedPhase && errors.totUnitsRentedPhase
                  }
                />
                <div className="attribute-title">
                  Total number of units owned by the HOA for Entire Project
                </div>
                <Field
                  name="totUnitsOwnByHoaProj"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units owned by HOA for project' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsOwnByHoaProj && errors.totUnitsOwnByHoaProj
                  }
                  helperText={
                    touched.totUnitsOwnByHoaProj && errors.totUnitsOwnByHoaProj
                  }
                />
                <div className="attribute-title">
                  Total number of units owned by the HOA for Subject Legal Phase
                </div>
                <Field
                  name="totUnitsOwndByHoaPhase"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9, 'aria-label': 'Total units owned by HOA for the phase' }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totUnitsOwndByHoaPhase &&
                    errors.totUnitsOwndByHoaPhase
                  }
                  helperText={
                    touched.totUnitsOwndByHoaPhase &&
                    errors.totUnitsOwndByHoaPhase
                  }
                />

                <div className="section-title">
                  Complete the following table if more than one unit is owned by
                  the same individual or entity
                </div>
                <div className="table-header">
                  <div className="table-header-title">
                    Individual/Entity Name
                  </div>
                  <div className="table-header-title">Developer or Sponsor</div>
                  <div className="table-header-title">
                    Number of Units Owned
                  </div>
                  <div className="table-header-title">
                    Percentage Owned of Total Project Units
                  </div>
                  <div className="table-header-title">
                    Number Leased at Market Rent
                  </div>
                  <div className="table-header-title">
                    Number Leased under Rent Control
                  </div>
                </div>
                <FieldArray name="unitOwnrshp1076DataList">
                  {({ push, remove }) => (
                    <div>
                      {values.unitOwnrshp1076DataList.map((p, index) => {
                        return (
                          <div className="table-attributes">
                            <div className="table-attribute">
                              <Field
                                name={`unitOwnrshp1076DataList[${index}].indvName`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                onKeyUp={() => {
                                  getDuplicateIndvName(
                                    values.unitOwnrshp1076DataList
                                  );
                                }}
                                onBlur={() => {
                                  getDuplicateIndvName(
                                    values.unitOwnrshp1076DataList
                                  );
                                }}
                                inputProps = {{
                                  'aria-label': `Unit ownership 1076-${index}`
                                }}
                              />
                              {duplicateIndvNameError &&
                              values.unitOwnrshp1076DataList[index].isError ? (
                                <div style={{ color: "#ff0000" }}>
                                  Individual Name{" "}
                                  {
                                    values.unitOwnrshp1076DataList[index]
                                      .indvName
                                  }{" "}
                                  already exists
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              role="group"
                              aria-labelledby="radio-group"
                              className="table-radio"
                            >
                              <label>
                                <Field
                                  name={`unitOwnrshp1076DataList[${index}].devlSpnsrInd`}
                                  as={Radio}
                                  type="radio"
                                  variant="outlined"
                                  value="true"
                                />
                                Yes
                              </label>
                              <label>
                                <Field
                                  name={`unitOwnrshp1076DataList[${index}].devlSpnsrInd`}
                                  as={Radio}
                                  type="radio"
                                  variant="outlined"
                                  value="false"
                                />
                                No
                              </label>
                            </div>
                            <div className="table-attribute">
                              <Field
                                name={`unitOwnrshp1076DataList[${index}].nbrOfUnitsOwndCnt`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                inputProps={{
                                  maxlength: 8,
                                  pattern: "^[0-9]*$"
                                }}
                                helperText="Number Only"
                                variant="outlined"
                                inputProps = {{
                                  'aria-label': `Number of Units Owned Cnt-${index}`
                                }}
                              />
                            </div>
                            <div className="table-attribute-1">
                              <Field
                                name={`unitOwnrshp1076DataList[${index}].unitsOwndPct`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                inputProps={{
                                  maxlength: 8,
                                  pattern:
                                    "(^100(.0{1,2})?$)|(^([1-9]([0-9])?|0)(.[0-9]{1,2})?$)"
                                }}
                                helperText="Percent Only"
                                inputProps = {{
                                  'aria-label': `Units ownd pct - ${index}`
                                }}
                              />
                            </div>
                            <div className="table-attribute-2">
                              <Field
                                name={`unitOwnrshp1076DataList[${index}].nbrUnitsLeasedMrktRentCnt`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                inputProps={{
                                  maxlength: 8,
                                  pattern: "^[0-9]*$"
                                }}
                                helperText="Number Only"
                                variant="outlined"
                                inputProps = {{
                                  'aria-label': `Nbr Units Leased Mrkt Rent cnt- ${index}`
                                }}
                              />
                            </div>
                            <div className="table-attribute-3">
                              <Field
                                name={`unitOwnrshp1076DataList[${index}].nbrUnitsLeasedRentCntrlCnt`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                inputProps={{
                                  maxlength: 8,
                                  pattern: "^[0-9]*$"
                                }}
                                helperText="Number Only"
                                variant="outlined"
                                inputProps = {{
                                  'aria-label': `Nbr units leased rent cntrl cnt - ${index}`
                                }}
                              />
                            </div>
                            <div
                              className="trash-can"
                              onClick={() => remove(index)}
                            >
                              <DeleteForeverOutlinedIcon />
                            </div>
                          </div>
                        );
                      })}
                      <div className="add-table-button">
                        <Button
                          type="button"
                          onClick={() => {
                            let isDuplicate = getDuplicateIndvName(
                              values.unitOwnrshp1076DataList
                            );
                            console.log("click => ", isDuplicate);

                            if (!isDuplicate) {
                              push({
                                indvName: "",
                                devlSpnsrInd: "",
                                nbrOfUnitsOwndCnt: "",
                                unitsOwndPct: "",
                                nbrUnitsLeasedMrktRentCnt: "",
                                nbrUnitsLeasedRentCntrlCnt: "",
                                createdBy: passData.user
                              });
                            }
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <div className="attribute-title">
                  Do the unit owners have sole ownership interest in and the
                  right to use the project amenities and commonareas?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="soleOwnrshipIntrstAmenitiesInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiosoleOwnrship}
                      onClick={radioChecksoleOwnrship}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="soleOwnrshipIntrstAmenitiesInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNosoleOwnrship}
                      onClick={radioCheckNosoleOwnrship}
                    />
                    No
                  </label>
                </div>
                {values.soleOwnrshipIntrstAmenitiesInd === false ||
                values.soleOwnrshipIntrstAmenitiesInd === "false" ? (
                  <>
                    <div className="attribute-title">
                      Who has ownership interest in and rights to use the
                      project amenities and common areas?
                    </div>
                    <Field
                      name="soleOwnrshipIntrstAmenitiesExpln"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 2000 }}
                      variant="outlined"
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="attribute-title">
                  Are any units in the project used for commercial or
                  non-residential purposes?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="unitsUsedCommrclPurpseInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiounitsUsed}
                      onClick={radioCheckunitsUsed}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="unitsUsedCommrclPurpseInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNounitsUsed}
                      onClick={radioCheckNounitsUsed}
                    />
                    No
                  </label>
                </div>
                {values.unitsUsedCommrclPurpseInd === true ||
                values.unitsUsedCommrclPurpseInd === "true" ? (
                  <>
                    <div className="section-title">
                      Complete the following table:
                    </div>
                    <div className="table-header">
                      <div className="table-header-title-2">
                        Type of Commercial or Non-Residential Use
                      </div>
                      <div className="table-header-title-2">
                        Name of Owner or Tenant
                      </div>
                      <div className="table-header-title-2">
                        Number of Units
                      </div>
                      <div className="table-header-title-2">Square Footage</div>
                      <div className="table-header-title-2">
                        Percent Square Footage of Total Project Square Footage
                      </div>
                    </div>
                    <FieldArray name="commUse1076DataList">
                      {({ push, remove }) => (
                        <div>
                          {values.commUse1076DataList.map((p, index) => {
                            return (
                              <div className="table-attributes-2">
                                <div className="table-attribute-4">
                                  <Field
                                    name={`commUse1076DataList[${index}].commrclUseType`}
                                    as={TextField}
                                    size="small"
                                    fullWidth={true}
                                    inputProps = {{
                                      'aria-label': `commUse1076DataList ${index} commercial use type`
                                    }}
                                    variant="outlined"
                                  />
                                </div>
                                <div className="table-attribute-4">
                                  <Field
                                    name={`commUse1076DataList[${index}].ownrTenantName`}
                                    as={TextField}
                                    size="small"
                                    fullWidth={true}
                                    variant="outlined"
                                    inputProps = {{
                                      'aria-label': `commUse1076DataList ${index} owner tenant name`
                                    }}
                                    onKeyUp={event => {
                                      getDuplicateOwnerName(
                                        values.commUse1076DataList
                                      );
                                    }}
                                    onBlur={() => {
                                      getDuplicateOwnerName(
                                        values.commUse1076DataList
                                      );
                                    }}
                                  />
                                  {duplicateOwnerNameError &&
                                  values.commUse1076DataList[index].isError ? (
                                    <div style={{ color: "#ff0000" }}>
                                      Name of Owner{" "}
                                      {
                                        values.commUse1076DataList[index]
                                          .ownrTenantName
                                      }{" "}
                                      is already exists
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="table-attribute-4">
                                  <Field
                                    name={`commUse1076DataList[${index}].nbrOfUnitsCnt`}
                                    as={TextField}
                                    size="small"
                                    fullWidth={true}
                                    inputProps={{
                                      maxlength: 8,
                                      pattern: "[0-9]{1,15}",
                                      'aria-label': `${index} number of units count`
                                    }}
                                    helperText="Number Only"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="table-attribute-4">
                                  <Field
                                    name={`commUse1076DataList[${index}].sqFootage`}
                                    as={TextField}
                                    size="small"
                                    fullWidth={true}
                                    inputProps={{
                                      maxlength: 8,
                                      pattern: "^[0-9]{0,4}(.[0-9]{1,2})?$",
                                      'aria-label': `${index} square footage`
                                    }}
                                    helperText="Number Only Max(XXXX.XX)"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="table-attribute-4">
                                  <Field
                                    name={`commUse1076DataList[${index}].sqFtPct`}
                                    as={TextField}
                                    size="small"
                                    fullWidth={true}
                                    inputProps={{
                                      maxlength: 8,
                                      pattern:
                                        "(^100(.0{1,2})?$)|(^([1-9]([0-9])?|0)(.[0-9]{1,2})?$)",
                                        'aria-label': `${index} sq feet pct`
                                    }}
                                    helperText="Percent Only"
                                    variant="outlined"
                                  />
                                </div>
                                <div
                                  className="trash-can"
                                  onClick={() => remove(index)}
                                >
                                  <DeleteForeverOutlinedIcon />
                                </div>
                              </div>
                            );
                          })}
                          <div className="add-table-button">
                            <Button
                              type="button"
                              onClick={() => {
                                let isDuplicate = getDuplicateOwnerName(
                                  values.commUse1076DataList
                                );
                                console.log("click => ", isDuplicate);

                                if (!isDuplicate) {
                                  push({
                                    commrclUseType: "",
                                    ownrTenantName: "",
                                    nbrOfUnitsCnt: "",
                                    sqFootage: "",
                                    sqFtPct: "",
                                    createdBy: passData.user
                                  });
                                }
                              }}
                            >
                              <AddCircleOutlineIcon />
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </>
                ) : (
                  ""
                )}

                <div className="attribute-title">
                  What is the total square footage of commercial space in the
                  building that is separate from the residentialHOA? Include
                  above and below grade space used for commercial purposes, such
                  as public parking facilities, retail space, apartments,
                  commercial offices, and so on. Total square footage of
                  commercial space:
                </div>
                <Field
                  name="totSqFootageCommSpace"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.totSqFootageCommSpace &&
                    errors.totSqFootageCommSpace
                  }
                  helperText={
                    touched.totSqFootageCommSpace &&
                    errors.totSqFootageCommSpace
                  }
                />

                <div className="section-title">
                  Insurance Information & Financial Controls
                </div>
                <div className="attribute-title">
                  Are units or common elements located in a flood zone?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="floodZoneInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiofloodZone}
                      onClick={radioCheckfloodZone}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="floodZoneInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNofloodZone}
                      onClick={radioCheckNofloodZone}
                    />
                    No
                  </label>
                </div>
                {values.floodZoneInd === true ||
                values.floodZoneInd === "true" ? (
                  <>
                    <div className="attribute-title">
                      Flood coverage is in force equaling
                    </div>
                    <div className="attribute-select">
                      <Field
                        className="attribute-select-dropdown"
                        name="floodCvrgInForceDesc"
                        as="select"
                        aria-label= 'Flood covering in force'
                      >
                        <option value=" ">Select Coverage Amount</option>
                        {amountList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {values.floodCvrgInForceDesc === "some other amount" ? (
                  <>
                    <div className="attribute-title">Enter Amount</div>
                    <Field
                      name="floodCvrgInForceDescOtherAmt"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 16, 'aria-label': 'Flood covering in force other amount' }}
                      validate={validateNumNoStr}
                      variant="outlined"
                      error={
                        touched.floodCvrgInForceDescOtherAmt &&
                        errors.floodCvrgInForceDescOtherAmt
                      }
                      helperText={
                        touched.floodCvrgInForceDescOtherAmt &&
                        errors.floodCvrgInForceDescOtherAmt
                      }
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="section-title">
                  Check all of the following that apply regarding HOA financial
                  accounts:
                </div>
                <div className="attribute-title">
                  HOA maintains separate accounts for operating and reserve
                  funds
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaAcctsSeparateOpratingReserveFundsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaAccts}
                      onClick={radioCheckhoaAccts}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaAcctsSeparateOpratingReserveFundsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaAccts}
                      onClick={radioCheckNohoaAccts}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Appropriate access controls are in place for each account
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaAcctsAcessCntrlsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaAcctsAcess}
                      onClick={radioCheckhoaAcctsAcess}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaAcctsAcessCntrlsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaAcctsAcess}
                      onClick={radioCheckNohoaAcctsAcess}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  The bank sends copies of monthly bank statements directly to
                  the HOA
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaAcctsMnthlyBankStmtsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaAcctsMnthly}
                      onClick={radioCheckhoaAcctsMnthly}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaAcctsMnthlyBankStmtsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaAcctsMnthly}
                      onClick={radioCheckNohoaAcctsMnthly}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  Two members of the HOA Board of Directors are required to sign
                  any check written on the reserve account
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaAcctsCheckSignedReqdMembersInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaAcctsCheck}
                      onClick={radioCheckhoaAcctsCheck}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaAcctsCheckSignedReqdMembersInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaAcctsCheck}
                      onClick={radioCheckNohoaAcctsCheck}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  The Management Company maintains separate records and bank
                  accounts for each HOA that uses its services
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaAcctsMngmtCmpnyAccts_maintnanceInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaAcctsMngmt}
                      onClick={radioCheckhoaAcctsMngmt}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaAcctsMngmtCmpnyAccts_maintnanceInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaAcctsMngmt}
                      onClick={radioCheckNohoaAcctsMngmt}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">
                  The Management Company does not have the authority to draw
                  checks on, or transfer funds from, the reserve account of the
                  HOA
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="hoaAcctsMngmtCmpnyAuthorityReqmntsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiohoaAcctsMngmtCmpny}
                      onClick={radioCheckhoaAcctsMngmtCmpny}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="hoaAcctsMngmtCmpnyAuthorityReqmntsInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNohoaAcctsMngmtCmpny}
                      onClick={radioCheckNohoaAcctsMngmtCmpny}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">Hazard Carrier/Agent Name</div>
                <Field
                  name="hzrdInsAgentName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'Hazard carrier/agent name' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.hzrdInsAgentName && errors.hzrdInsAgentName}
                  helperText={
                    touched.hzrdInsAgentName && errors.hzrdInsAgentName
                  }
                />
                <div className="attribute-title">
                  Hazard Carrier/Agent Phone Number
                </div>
                <Field
                  name="hzrdInsAgentPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label': 'Hazard agent phone number' }}
                  validate={validatePhone}
                  variant="outlined"
                  error={touched.hzrdInsAgentPhone && errors.hzrdInsAgentPhone}
                  helperText={
                    touched.hzrdInsAgentPhone && errors.hzrdInsAgentPhone
                  }
                />
                <div className="attribute-title">Hazard Policy Number</div>
                <Field
                  name="hzrdInsPlcyNbr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label': 'Hazard policy number' }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.hzrdInsPlcyNbr && errors.hzrdInsPlcyNbr}
                  helperText={touched.hzrdInsPlcyNbr && errors.hzrdInsPlcyNbr}
                />
                <div className="attribute-title">
                  Liability Carrier/Agent Name
                </div>
                <Field
                  name="liabInsAgentName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200,  'aria-label': 'Liability agent name' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.liabInsAgentName && errors.liabInsAgentName}
                  helperText={
                    touched.liabInsAgentName && errors.liabInsAgentName
                  }
                />
                <div className="attribute-title">
                  Liability Carrier/Agent Phone Number
                </div>
                <Field
                  name="liabInsAgentPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16,  'aria-label': 'Liability agent phone number' }}
                  validate={validatePhone}
                  variant="outlined"
                  error={touched.liabInsAgentPhone && errors.liabInsAgentPhone}
                  helperText={
                    touched.liabInsAgentPhone && errors.liabInsAgentPhone
                  }
                />
                <div className="attribute-title">Liability Policy Number</div>
                <Field
                  name="liabInsPlcyNbr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16,  'aria-label': 'Liability policy number' }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.liabInsPlcyNbr && errors.liabInsPlcyNbr}
                  helperText={touched.liabInsPlcyNbr && errors.liabInsPlcyNbr}
                />
                <div className="attribute-title">
                  Fidelity Carrier/Agent Name
                </div>
                <Field
                  name="fidelInsAgentName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200,  'aria-label': 'Fidelity agent name' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.fidelInsAgentName && errors.fidelInsAgentName}
                  helperText={
                    touched.fidelInsAgentName && errors.fidelInsAgentName
                  }
                />
                <div className="attribute-title">
                  Fidelity Carrier/Agent Phone Number
                </div>
                <Field
                  name="fidelInsAgentPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16,  'aria-label': 'Fidelity agent phone number' }}
                  validate={validatePhone}
                  variant="outlined"
                  error={
                    touched.fidelInsAgentPhone && errors.fidelInsAgentPhone
                  }
                  helperText={
                    touched.fidelInsAgentPhone && errors.fidelInsAgentPhone
                  }
                />
                <div className="attribute-title">Fidelity Policy Number</div>
                <Field
                  name="fidelInsPlcyNbr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16, 'aria-label': 'Fidelity policy number' }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.fidelInsPlcyNbr && errors.fidelInsPlcyNbr}
                  helperText={touched.fidelInsPlcyNbr && errors.fidelInsPlcyNbr}
                />
                <div className="attribute-title">Flood Carrier/Agent Name</div>
                <Field
                  name="floodInsAgentName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200,  'aria-label': 'Flood agent name' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.floodInsAgentName && errors.floodInsAgentName}
                  helperText={
                    touched.floodInsAgentName && errors.floodInsAgentName
                  }
                />
                <div className="attribute-title">
                  Flood Carrier/Agent Phone Number
                </div>
                <Field
                  name="floodInsAgentPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16,  'aria-label': 'Flood agent phone number' }}
                  validate={validatePhone}
                  variant="outlined"
                  error={
                    touched.floodInsAgentPhone && errors.floodInsAgentPhone
                  }
                  helperText={
                    touched.floodInsAgentPhone && errors.floodInsAgentPhone
                  }
                />
                <div className="attribute-title">Flood Policy Number</div>
                <Field
                  name="floodInsPlcyNbr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16,  'aria-label': 'Flood policy number' }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.floodInsPlcyNbr && errors.floodInsPlcyNbr}
                  helperText={touched.floodInsPlcyNbr && errors.floodInsPlcyNbr}
                />

                <div className="section-title">Contact Information</div>

                <div className="attribute-title">Name of Preparer</div>
                <Field
                  name="preparerName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200,  'aria-label': 'Name of preparer' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.preparerName && errors.preparerName}
                  helperText={touched.preparerName && errors.preparerName}
                />
                <div className="attribute-title">Title of Preparer</div>
                <Field
                  name="preparerTitle"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128,  'aria-label': 'Title of preparer' }}
                  variant="outlined"
                />
                <div className="attribute-title">Preparer’s Company Name</div>
                <Field
                  name="preparerCmpnyName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200,  'aria-label': 'Preparer company name' }}
                  variant="outlined"
                />
                <div className="attribute-title">Preparer’s Phone</div>
                <Field
                  name="preparerPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 16,  'aria-label': 'Preparer phone' }}
                  validate={validatePhone}
                  variant="outlined"
                  error={touched.preparerPhone && errors.preparerPhone}
                  helperText={touched.preparerPhone && errors.preparerPhone}
                />
                <div className="attribute-title">Preparer’s Email</div>
                <Field
                  name="preparerEmail"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ maxLength: 128,  'aria-label': 'Preparer email' }}
                  validate={validateEmail}
                  error={touched.preparerEmail && errors.preparerEmail}
                  helperText={touched.preparerEmail && errors.preparerEmail}
                />
                <div className="attribute-title">
                  Preparer’s Company Address
                </div>
                <Field
                  name="preparerCmpnyAddr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200,  'aria-label': 'Preparer company address' }}
                  variant="outlined"
                />
                <div className="attribute-title">Date Completed</div>
                <Field
                  name="dtCmpltd"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ 'aria-label': 'Date completed' }}
                />

                <div className="bottom-button-container">
                  <Button
                    type="submit"
                    className="next-button"
                    onClick={() => setDirection("forward")}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    type="submit"
                    className="previous-button"
                    onClick={() => setDirection("back")}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{fill: "red"}}/>
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

Form1076.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  step: PropTypes.func.isRequired
};


import { STYLE_RESULTS } from '../actions/ActionType'

export const initialState = {

        styles: [],
        
}
export const styleReducer = (style = initialState, action) => {
    switch (action.type) {
        case STYLE_RESULTS:
            return {
           styles: action.payload
          } 
        default:
            return style
    }
}
import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import {
  isReadonlyRole,
  isProjectRole,
  isProjectStandardRole,
} from "../../redux/actions/userAction";
import InfoIcon from "@material-ui/icons/Info";
import "./Project.css";

const CertifyModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const status = props.status === "Incomplete" ? "Incomplete" : "new";

  return (
    <>
      <button className="item-d-container-button" onClick={handleShow}>
        Certify Project
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          background: "rgba(0, 0, 0, 0.5)"
        }}
      >
        <Modal.Body className="delete-modal-body">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <InfoIcon
              style={{ width: "50px", height: "50px", textAlign: "center" }}
            />
          </div>
          <div className="delete-modal-title">Certify Project</div>
          <div className="delete-modal-info">
            <span style={{ fontWeight: "bold" }}>Please Note</span>: In order to
            successfully certify the project, the entire project must meet all
            the necessary requirements, including all phases have been annexed
            into the project, all residential units and amenities are
            substantially complete, and presale is met as defined in the{" "}
            <a
              target="_blank"
              href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
              style={{color:"#0C77BA"}}
            >
              Selling Guide
            </a>
            . If you are aware of any issue that would result in a ‘Guide
            Ineligible’ at the project level, that would alternatively be
            eligible under a specific phase certification, please select cancel
            and certify the subject unit’s legal phase.
          </div>
        </Modal.Body>
        <Modal.Footer className="delete-modal-footer">
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
          <a href={`/certification/${props.projectvalue}/proj/${status}`}>
            <button className="certify" style={{backgroundColor:"#0C77BA"}}>Certify Project</button>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CertifyModal;

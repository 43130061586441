import React, { useState } from "react";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaTimes,
} from "react-icons/fa";
import FormStepper from "../stepper";
import { Accordion, Card } from "react-bootstrap";
import CancelModal from "../../ProjectStatusFinal/cancel-modal";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
export const Page2 = ({
  formData,
  formState,
  streetInfo,
  nextStep,
  prevStep,
  step,
}) => {
  const [direction, setDirection] = useState("back");
  const [showCancel, setShowCancel] = useState(false);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const [disable, setDisable] = useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function openCancelPopup(flag) {
    setShowCancel(flag);
  }

  function handleCancelCancel() {
    setShowCancel(false);
  }

  const entirePhaseData = {
    projectId: formData.projectId,
    parentPhaseId: formData.parentPhaseId,
    phaseId: formData.phaseId,
    phaseName: formData.phaseName,
    phaseType: formData.phaseType,
    buildingNumber: formData.buildingNumber,
    unitNumber: formData.unitNumber,
    unitCount: formData.unitCount,
    comments: formData.comments,
    street: streetInfo.street,
    city: formState.city,
    state: formState.state,
    zip5: formState.zip5,
    userName: passData.user,
  };

  const [apiErr, setApiErr] = useState("");

  function submitChildPhaseForm() {
    setDisable(true);
    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/phase/addNewChildPhase/`,
        entirePhaseData
      )
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          //setDocId(response.data);
          setTimeout(() => {
            nextStep();
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("child phase error", error);
      });
  }

  return (
    <>
      <CancelModal
        showCancel={showCancel}
        handleCancelCancel={handleCancelCancel}
        persId={formData.persId}
        title={"Cancel Conditional/Avialable Letter"}
      />
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          //direction === 'back' ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="parent-field-container">
              <div className="field-container">
                <div className="top-title-container">
                  <div className="big-title">Add Child Phase</div>
                  <div className="small-title">
                    Phase ID: {formData.phaseId}
                  </div>
                  <button
                    className="add-phase-button"
                    style={{ float: "right", marginTop: "-70px" }}
                    onClick={() => openCancelPopup(true)}
                  >
                    <FaTimes
                      style={{
                        marginRight: 5,
                        marginBottom: 2,
                        color: "#d0021b",
                      }}
                    />{" "}
                    Cancel
                  </button>
                </div>

                <div className="field-lower-container">
                  <div className="section-title">
                    Step 2: Review Child Phase Details
                  </div>
                  <>
                    <div className="page-container">
                      <div className="accordion-top-container">
                        <Accordion defaultActiveKey="1" id="section5">
                          <Card className="accordion-header-card">
                            <Card.Header className="accordion-header-space">
                              <ContextAwareToggle eventKey="1">
                                <p className="accordion-header-title">
                                  Child Phase Details
                                </p>
                              </ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="accordion-body-card">
                                <div className="letter-accordion-body-container">
                                  <div
                                    className="left"
                                    style={{
                                      paddingTop: 24,
                                      paddingLeft: 24,
                                      lineHeight: "24px",
                                    }}
                                  >
                                    <div className="label">Phase ID</div>
                                    <div className="value">
                                      {formData.phaseId}
                                    </div>
                                    <div className="label">Phase Name</div>
                                    <div className="value">
                                      {formData.phaseName}
                                    </div>
                                    <div className="label">Phase street</div>
                                    <div className="value">
                                      {streetInfo.street}
                                    </div>
                                    <div className="label">Phase City</div>
                                    <div className="value">
                                      {formState.city}
                                    </div>
                                    <div className="label">Phase State</div>
                                    <div className="value">
                                      {formState.state}
                                    </div>
                                    <div className="label">Phase Zip Code</div>
                                    <div className="value">
                                      {formState.zip5}
                                    </div>
                                  </div>
                                  <div
                                    className="right"
                                    style={{
                                      paddingTop: 24,
                                      paddingLeft: 24,
                                      lineHeight: "24px",
                                    }}
                                  >
                                    <div className="label">Phase Type</div>
                                    <div className="value">
                                      {formData.phaseType}
                                    </div>
                                    <div className="label">Building Number</div>
                                    <div className="value">
                                      {formData.buildingNumber}
                                    </div>
                                    <div className="label">Unit Number</div>
                                    <div className="value">
                                      {formData.unitNumber}
                                    </div>
                                    <div className="label">
                                      Number of Units in Phase
                                    </div>
                                    <div className="value">
                                      {formData.unitCount}
                                    </div>
                                    <div className="label">Comments</div>
                                    <div className="value">
                                      {formData.comments}
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          color: "#ff0000",
                          padding: 15,
                        }}
                      >
                        {apiErr ? apiErr : null}
                      </div>
                    </div>
                  </>
                  <div className="bottom-button-container">
                    <Button
                      type="submit"
                      className="next-button"
                      disabled={disable}
                      onClick={() => {
                        submitChildPhaseForm();
                      }}
                    >
                      Next Section
                      <ArrowForwardIcon />
                    </Button>
                    <Button
                      type="submit"
                      className="previous-button"
                      onClick={() => prevStep()}
                    >
                      <ArrowBackIcon />
                      Previous Section
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              {/* <button
                        className="delete-modal-button"
                        style={{
                            float: 'right',
                            border: 0
                        }}
                        onClick={() => openCancelPopup(true)}
                        >
                        <FaTimes
                            className="edit-profile-icon"
                            style={{
                            marginRight: 5
                            }}
                        />{" "}
                        Cancel 
                        </button> */}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

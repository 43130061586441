import React from 'react';
import { Link,Route } from 'react-router-dom';
import  Project  from './Project';

const Projects = ({ match }) => {

    const mockProject = [
        {
            id: 1,
            projectName: "The Coliseum",
            imgUrl: 'https://www.moneyunder30.com/wp-content/uploads/2018/03/Should-you-buy-a-condo.jpg',
            address: '4655 Wendell Ave, Dallas, TX 75001',
            unit: '38',
            update: 'July 30,2020',
            yearBuilt: '2010',
            eligibility: 'Pre-eligible',
            projName: 'ABC Company',
            condoAlias: 'Fig Tree Condos',
            coaAddress: '945 Vista Ridge Boulevard, Dallas, TX',
            coaTaxId: '43-3298439',
            coaCompanyTaxId: '43-32-98439',
            masterAssociation: 'XYZ Company',
            singleEntity: '8',
            ownerOccupancy: '84',
            delinquentFees: '2',
            pendingLitigation: 'No',
            commercialUsage: '2'
        },
        {
            id: 2,
            projectName: "The Other Condo",
            imgUrl: 'https://cdn2.ramseysolutions.net/dynamic/media/b2b/elp/blog/what-is-a-condo-header.jpg/960w.jpg',
            address: '3333 Address Ave, Dallas, TX 75001',
            unit: '30',
            update: 'November 30,2019',
            yearBuilt: '2015',
            eligibility: 'Pre-eligible',
            projName: 'EFG Company',
            condoAlias: 'Fig Tree Condos',
            coaAddress: '945 Vista Ridge Boulevard, Dallas, TX',
            coaTaxId: '43-3298439',
            coaCompanyTaxId: '43-32-98439',
            masterAssociation: 'POI Company',
            singleEntity: '8',
            ownerOccupancy: '84',
            delinquentFees: '2',
            pendingLitigation: 'No',
            commercialUsage: '2'
        },
    ]
  var linkList = mockProject.map( (project) => {
    return(
      <li key={project.id}>
        <Link to={`${match.url}/${project.id}`}>
          {project.projectName}
        </Link>
      </li>
      )
          
    })
 
  return(
    <div>
     <div className="all-project">
      <div className="all-project-style">
        <h3> All Projects</h3>
        <ul  style={{ listStyleType: 'none', padding: 0, fontSize:'15px' }}> {linkList} </ul>
      </div>
    </div>
    
    <Route path={`${match.url}/:projectId`} render={ (props) => <Project data= {mockProject} {...props} />}/>
    <Route exact path={match.url} 
      render={() => (
        <div style={{ textAlign:'center'}}>Please select a Project.</div>
      )}
    />

    </div>
  )
}
export default Projects;
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Grid, Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import "../../AddEstablishedPers/AddEstablishedPers.css";

import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

export const EnterIneligible = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  prevStep2,
  prevStep3,
  prevStep4,
  step,
  persId,
}) => {
  const {
    ineligibilityExplaination,
    reviewedProjectExhibits,
    sellingGuideReference,
  } = formData;
  const [sellerErr, setSellerErr] = useState();
  const [listErr, setListErr] = useState();
  const [editorErr, setEditorErr] = useState();
  const [phaseName, setPhaseName] = useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    let phaseNameTmp = [];
    if (formData.phaseDecisions) {
      for (let phase of formData.phaseDecisions) {
        if (phase.persDecison == "Ineligible") {
          phaseNameTmp.push(phase.phaseName);
        }
      }

      setPhaseName(phaseNameTmp);
    }
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    let tab = RegExp("\\t", "g");
    let validatedData = value.toString().replace(tab, " ");
    let _formData = { ...formData };
    _formData[name] = validatedData;
    setFormData({ ..._formData });
  };

  function checkPrevPhaseRedirection(data) {
    let IsConditionAvail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Available";
    });

    let IsConditionUnavail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Unavailable";
    });

    let IsFinalApproval = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Final Project Approval";
    });

    let IsSuspention = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Suspension of the Application";
    });

    let IsIneligible = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    // resetPhaseCondition();

    if (IsSuspention) {
      prevStep();
    } else if (IsConditionAvail) {
      prevStep2();
    } else if (IsFinalApproval) {
      prevStep2();
    } else if (IsConditionUnavail) {
      prevStep3();
    } else if (IsIneligible) {
      prevStep4();
    }
  }

  function checkSellingGuideValidation(event) {
    // formData.sellingGuideReference = event.target.value;
    if (!event.target.value) {
      setSellerErr("Required");
    } else {
      setSellerErr("");
    }
  }

  function checkReviewListValidation(event) {
    // formData.reviewedProjectExhibits = event.target.value;
    if (!event.target.value) {
      setListErr("Required");
    } else {
      setListErr("");
    }
  }

  function checkReasonValidation(event) {
    // formData.ineligibilityExplaination = event.target.value;
    if (!event.target.value) {
      setEditorErr("Required");
    } else {
      setEditorErr("");
    }
  }

  function checkAllValidation() {
    if (
      !formData.sellingGuideReference &&
      !formData.reviewedProjectExhibits &&
      !formData.ineligibilityExplaination
    ) {
      setSellerErr("Required");
      setListErr("Required");
      setEditorErr("Required");
    } else if (
      !formData.reviewedProjectExhibits &&
      !formData.ineligibilityExplaination
    ) {
      setSellerErr("");
      setListErr("Required");
      setEditorErr("Required");
    } else if (
      !formData.sellingGuideReference &&
      !formData.ineligibilityExplaination
    ) {
      setSellerErr("Required");
      setListErr("");
      setEditorErr("Required");
    } else if (
      !formData.reviewedProjectExhibits &&
      !formData.sellingGuideReference
    ) {
      setSellerErr("Required");
      setListErr("Required");
      setEditorErr("");
    } else if (!formData.sellingGuideReference) {
      setSellerErr("Required");
      setListErr("");
      setEditorErr("");
    } else if (!formData.reviewedProjectExhibits) {
      setSellerErr("");
      setListErr("Required");
      setEditorErr("");
    } else if (!formData.ineligibilityExplaination) {
      setSellerErr("");
      setListErr("");
      setEditorErr("Required");
    } else {
      setSellerErr("");
      setListErr("");
      setEditorErr("");

      if (
        formData.decisionLevel == "Entire project" ||
        formData.decisionLevel == "Entire Project"
      ) {
        nextStep();
      } else {
        nextStep();
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          checkAllValidation(values);
          console.log("formdata ----", formData);
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Decision</div>
                <div className="small-title">For PERS ID: {persId}</div>
              </div>
              <div className="field-lower-container">
                <div className="section-title">
                  Step 6 : Enter Ineligibility Detail
                </div>

                {formData.decisionLevel == "Entire project" ||
                formData.decisionLevel == "Entire Project" ? (
                  <div>
                    <div
                      id="radio-group"
                      className="attribute-title"
                      style={{ fontWeight: "bold" }}
                    >
                      Project/Phases with Ineligible Decision
                    </div>
                    <div style={{ marginLeft: 50 }}>Entire Project</div>
                  </div>
                ) : (
                  <div>
                    <div
                      id="radio-group"
                      className="attribute-title"
                      style={{ fontWeight: "bold" }}
                    >
                      Project/Phases with Ineligible Decision
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      {phaseName.map((item, index) => (
                        <div>{item}</div>
                      ))}
                    </div>
                  </div>
                )}
                <div style={{ marginLeft: 40, marginRight: 50 }}>
                  <div className="field">
                    <div className="field-label" style={{ fontWeight: "bold" }}>
                      Selling and Servicing Guide Reference{" "}
                      <span className="mendatory-sign">*</span>
                      {/* <Tooltip
                        title="Provide reference of selling/servicing guide for reason of PERS ineligibility"
                        placement="top-start"
                      >
                        <InfoIcon />
                      </Tooltip> */}
                    </div>
                    <Grid item xs={12}>
                      <input
                        type="text"
                        className="form-control"
                        name="sellingGuideReference"
                        value={sellingGuideReference}
                        placeholder={
                          "i.e. Selling Guide B4-2.1-03, Ineligible Projects (10/07/2020)"
                        }
                        size="200"
                        maxLength="200"
                        onChange={(e) => {
                          handleChange(e);
                          checkSellingGuideValidation(e);
                        }}
                      />
                      <div className="mendatory-sign">{sellerErr}</div>
                    </Grid>
                  </div>
                  <div className="field">
                    <div className="field-label" style={{ fontWeight: "bold" }}>
                      List of reviewed Project Exhibits{" "}
                      <span className="mendatory-sign">*</span>
                    </div>
                    <Grid item xs={12}>
                      <input
                        type="text"
                        className="form-control"
                        name="reviewedProjectExhibits"
                        value={reviewedProjectExhibits}
                        placeholder={
                          "i.e. budget, HOA questionnaire, appraisal, and a web search."
                        }
                        size="200"
                        maxLength="200"
                        onChange={(e) => {
                          handleChange(e);
                          checkReviewListValidation(e);
                        }}
                      />
                      <div className="mendatory-sign">{listErr}</div>
                    </Grid>
                  </div>
                  <div>
                    <div
                      className="field-label"
                      style={{ fontWeight: "bold", marginTop: 15 }}
                    >
                      Reason for Ineligibility{" "}
                      <span className="mendatory-sign">*</span>
                    </div>
                    <textarea
                      className="form-control"
                      placeholder="Type reason for ineligibility"
                      maxLength="2000"
                      type="text"
                      style={{ height: 150 }}
                      name="ineligibilityExplaination"
                      value={ineligibilityExplaination}
                      onChange={(e) => {
                        handleChange(e);
                        checkReasonValidation(e);
                      }}
                    />

                    <div className="mendatory-sign">{editorErr}</div>
                    <span
                      style={{
                        display: "inline-block",
                        marginRight: "5px",
                        float: "right",
                        fontSize: "14px",
                        color: "#787979",
                      }}
                    >{`${(ineligibilityExplaination || "").length}/2000`}</span>
                  </div>
                </div>

                <div className="bottom-button-container">
                  <div>
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      onClick={() => {}}
                    >
                      Next Decision
                    </Button>
                  </div>
                  <Button
                    type="button"
                    className="previous-button review-preview"
                    onClick={() => {
                      if (
                        formData.decisionLevel == "Entire project" ||
                        formData.decisionLevel == "Entire Project"
                      ) {
                        prevStep4();
                      } else {
                        checkPrevPhaseRedirection(formData);
                      }
                    }}
                  >
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container"></div>
          </Form>
        )}
      </Formik>
    </>
  );
};

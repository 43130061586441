import React, { useState, useEffect } from "react";
import { Page1 } from "./pages/page-1";
import { Page2 } from "./pages/page-2";
import { Page3 } from "./pages/page-3";
import axios from "axios";

export const ChildPhase = props => {
    window.onscroll = function() {
        effects();
    };
    
    function effects() {}

    const phaseId = props.match.params.phase_id;
    const [formState, setFormState] = useState({});   

    const getPhaseDetails = async () => {
        try {
          const { data } = await axios.get(
            `${global.REACT_APP_API_ENDPOINT}/phase/getPhaseAddress/${phaseId}`
          );
          setFormState(data);
          setStreetInfo({
              street: data.street
          })
        } catch (err) {
          console.log(err);
        }
      };

    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        projectId: props.match.params.project_id,
        parentPhaseId: props.match.params.phase_id,
        phaseId: props.match.params.phase_id,
        phaseName: "",
        phaseType: "",
        buildingNumber: "",
        unitNumber: "",
        unitCount: "",
        comments: ""
        
    });

    
    const [streetInfo, setStreetInfo] = useState ({
        street: ""
    })

    useEffect(() => {
        getPhaseDetails();
    }, []);

    const nextStep = () => setStep(prev => prev + 1);
    const prevStep = () => setStep(prev => prev - 1);

    switch (step) {
        // switch (6) {
            case 0:
                return (
                <Page1
                    formData={formData}
                    formState={formState}
                    setFormData={setFormData}
                    streetInfo={streetInfo}
                    setStreetInfo={setStreetInfo}
                    nextStep={nextStep}
                    step={step}
                />
            );
            case 1:
                return (
                <Page2
                    formData={formData}
                    formState={formState}
                    streetInfo={streetInfo}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    step={step}
                />
            );
            case 2:
                return (
                <Page3
                    formData={formData}
                    step={step}
                />
            );
            default:
            return <Page3 formData={formData}  step={step} />;
        }


}
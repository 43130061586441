import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Accordion, Card } from "react-bootstrap";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import ApartmentIcon from "../../images/apartment_black_24dp@2x.svg";
import LayersIcon from "../../images/layers_black_24dp@1x.svg";
import CriticalIcon from "../../images/Critical@2x.svg";
import { isTPORole } from "../../redux/actions/userAction";
import { FinancialsSpecialAssessmentsCriticalRepairs } from "../shared/components/PrintCertificationForms/FinancialsSpecialAssessmentsCriticalRepairs";
import { GuideEligibility } from "../shared/components/PrintCertificationForms/GuideEligibility";
import { LitigationUsageAndInsurance } from "../shared/components/PrintCertificationForms/LitigationUsageAndInsurance";
import { NewProjectEligibility } from "../shared/components/PrintCertificationForms/NewProjectEligibility";
import { PhasePresaleAndInvestorConcentration } from "../shared/components/PrintCertificationForms/PhasePresaleAndInvestorConcentration";
import { ProjectInformation } from "../shared/components/PrintCertificationForms/ProjectInformation";
import { ProjectOwnership } from "../shared/components/PrintCertificationForms/ProjectOwnership";
import { ProjectPresaleAndInvestorConcentration } from "../shared/components/PrintCertificationForms/ProjectPresaleAndInvestorConcentration";
import { fspAssmtsRuleCheck } from "../shared/services/SharedService"
export const ViewPrintCertification = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const certId = props.match.params.cert_id;

  const phaseId = props.match.params.phase_id;

  const projectId = props.match.params.project_id;

  const [docId, setDocId] = useState([]);
  const [streetInfo, setStreetInfo] = useState({});
  const [convResult, setConvResult] = useState("");

  const getCertDetails = async () => {
    try {
      const { data } = await axios.get(
        `${global.REACT_APP_API_ENDPOINT}/lender/lenderViewHistoricalCertificationDetails/${certId}`
      );
      setDocId({ data });

      console.log("Data is here", data);
      if (data && typeof data.projectPresaleSt !== "undefined") {
        setConvResult(data.projectPresaleSt.ppPreSaleConveyPercntge);
      }
    } catch (err) {
      console.log(err);
    }
    if (phaseId === "proj") {
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getProjectInfoForLC/${projectId}`
        );
        setStreetInfo({ data });
        setStreetInfo({
          projectCity: data.city,
          projectState: data.state,
          projectStreet: data.street,
          projectZip: data.zip,
          projectName: data.projectName,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getPhaseInfoForLC/${phaseId}`
        );
        setStreetInfo({ data });
        setStreetInfo({
          phaseCity: data.phaseCity,
          phaseName: data.phaseName,
          phaseState: data.phaseState,
          phaseStreet: data.phaseStreet,
          phaseZip: data.phaseZip,
          projectName: data.projectName,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (sessionStorage.getItem("role") && sessionStorage.getItem("passData")) {
      console.log('inside if')
      getCertDetails();
    } else {
      console.log('inside else')
      getUserDetails();
    }
  }

  const getUserDetails = async () => {
    let userData = {
      dealerOrgName: "Local Org",
      firstName: "Jason",
      lastName: "Smith",
      mail: "user_smith@fanniemae.com",
      telephoneNumber: "703-833-1111",
      user: "r0usct",
      roles: [
        "SG-GFY-LE-CondoGo-ProjStd",
        "SG-GFY-LE-CondoGo-ProjStd-RO",
        "SG-GFY-LE-CondoGo-Adm",
        "SG-GFY-PROD-CondoGo-ProjStd-RO",
        "SG-GFY-PROD-CondoGo-ProjStd",
        "CONDO_Lender User",
        "CONDO_Lender Read-Only",
        "CONDO_Lender Admin User",
        "CONDOCOR_Correspondent User",
        "CONDOCOR_Correspondent Admin User",
        "CONDOCOR_Correspondent Read Only"
      ],
      isExternalUser: "",
      isInternalUser: true,
    };
    if (window.location.hostname !== "localhost" &&
      window.location.hostname !==
      "webapp-essui-eb.eitcg.devl-ess.aws.fanniemae.com" &&
      window.location.hostname !==
      "cgo-ui-dev-01-eb.eitcg.devl-sfbu.aws.fanniemae.com" &&
      window.location.hostname !==
      "ess-ui-devl-eb.eitcg.devl-ess.aws.fanniemae.com"
    ) {
      axios
        .get(`/userdetails`)
        .then((response) => {
          console.log("response user", response);
          if (response.status == 200) {
            userData = response.data.userDetails;
            userData['user'] = response.data.userDetails.uid;
            userData['roles'] = response.data.userDetails.groups;
            sessionStorage.setItem("passData", JSON.stringify(userData));
            translatedRoleMap();
          }
        })
        .catch((error) => {
          console.log(error)
        });
    } else {
      translatedRoleMap();
    }
  }

  const translatedRoleMap = async () => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/translatedRoleMap`)
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.setItem("roleData", JSON.stringify(response.data));
          if (!sessionStorage.getItem("role")) {
            sessionStorage.setItem("role", response.data.selectedRole);
          }
          getCertDetails();
        }
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  function getCertDate() {
    const certDate = moment(docId.data.certfcnDate)
      .format("MM/DD/YY");
    return certDate;
  }

  function goToProject() {
    window.location.href = `/project/${projectId}`;
  }

  function goToPhase() {
    window.location.href = `/project/phase/${phaseId}`;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));
  const classes = useStyles();

  return (
    <div>
      {typeof docId.data !== "undefined" ? (
        <div className="certification-container">
          <div className="main" style={{ backgroundColor: "#ffffff" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="subtitle">
                {phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Icon"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Icon"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "initial" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "50px" }}>
                    <div className="subtitle-certified-title">Certification ID</div>
                    <div className="subtitle-certified-attribute">
                      {docId.data.certificationId}
                      {console.log(docId.data.certificationId)}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "150px" }}>
                    <div className="subtitle-certified-title">Certified by</div>
                    <div className="subtitle-certified-attribute">
                      {docId.data.lenderUserName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {typeof docId.data.guideEligibilityAndProjectInfoSt !==
              "undefined" ? (
              <>
                {docId.data.certificationStatus === "Certified by Lender" ? (
                  <div
                    className="decline-info"
                    style={{ border: "1px solid #098620" }}
                  >
                    <div
                      className="decline-info-symbol"
                      style={{ backgroundColor: "#098620" }}
                    >
                      <CheckIcon
                        style={{
                          color: "#FFFFFF",
                          width: "30px",
                          height: "30px",
                          margin: "5px 0px",
                        }}
                      />
                    </div>
                    <div className="decline-info-text">
                      {docId.data.certificationStatus}
                    </div>
                  </div>
                ) : (
                  <div className="decline-info">
                    <div className="decline-info-symbol">
                      <CloseIcon
                        style={{
                          color: "#FFFFFF",
                          width: "30px",
                          height: "30px",
                          margin: "5px 0px",
                        }}
                      />
                    </div>
                    <div className="decline-info-text">
                      {docId.data.certificationStatus}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="info" style={{ margin: "0px" }}>
              <div style={{ display: "flex" }}>
                <div className="info-container">
                  {phaseId === "proj" ? (
                    <>
                      <div className="type">Project ID</div>
                      <div className="attributes">{projectId}</div>
                    </>
                  ) : (
                    <>
                      <div className="type">Phase ID</div>
                      <div className="attributes">{phaseId}</div>
                    </>
                  )}
                </div>
                <div
                  className="info-container"
                  style={{ paddingLeft: "220px" }}
                >
                  {phaseId === "proj" ? (
                    <>
                      <div className="type">Address</div>
                      <div className="attributes">
                        {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                        {streetInfo.projectState} {streetInfo.projectZip}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="type">Address</div>
                      <div className="attributes">
                        {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                        {streetInfo.phaseState} {streetInfo.phaseZip}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="info-container" style={{ paddingTop: "0px" }}>
                  {phaseId === "proj" ? (
                    <>
                      <div className="type">Project Name</div>
                      <div className="attributes">{streetInfo.projectName}</div>
                    </>
                  ) : (
                    <>
                      <div className="type">Project/Phase Name</div>
                      <div className="attributes">
                        {streetInfo.projectName} / {streetInfo.phaseName}
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="info-container"
                  style={{ paddingLeft: "0px", paddingTop: "0px" }}
                >
                  <div className="status-type">Status</div>
                  {typeof docId.data.guideEligibilityAndProjectInfoSt !==
                    "undefined" ? (
                    <>
                      {docId.data.certificationStatus ===
                        "Certified by Lender" ? (
                        <>
                          <div
                            className="status-attributes"
                            style={{ color: "#098620", fontWeight: "bold" }}
                          >
                            {docId.data.certificationStatus}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="status-attributes"
                            style={{ color: "#D0021B", fontWeight: "bold" }}
                          >
                            {docId.data.certificationStatus}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="info-container" style={{ paddingTop: "0px" }}>
                  <div className="type">Certification Date</div>
                  <div className="attributes">
                    {docId.data.certfcnDate ? getCertDate() : "NA"}
                  </div>
                </div>
                <div className="info-container" style={{ paddingTop: "0px" }}>
                  <div className="type">Expires</div>
                  <div className="attributes">
                    {docId.data.dateExpiry ? docId.data.dateExpiry : "NA"}
                  </div>{" "}
                </div>
                <div className="info-container" style={{ paddingTop: "0px" }}>
                  <div className="type">Project Type</div>
                  <div className="attributes">
                    {docId.data.certProjectType === "projectEst"
                      ? "Established"
                      : docId.data.certProjectType === "projectNew"
                        ? "New"
                        : "NA"}
                  </div>{" "}
                </div>
                <div className="info-container" style={{ paddingTop: "0px" }}>
                  <div className="type">
                    Project Type Code to be used at delivery
                  </div>
                  <div className="attributes">
                    {docId.data.certProjectType === "projectNew"
                      ? "R-Full Review (with or without CPM)—New condo project"
                      : docId.data.certProjectType === "projectEst"
                        ? "S-Full Review (with or without CPM)—Established condo project"
                        : ""}
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="form">
              <div className="form-header">
                <div className="title">Results</div>
              </div>
              <div className="form-body">
                {docId.data.delvyRestrictions ? (
                  <div className="certification-result-restriction-container">
                    <div className="restriction-title">
                      <img
                        alt="Critical Warning Icon"
                        style={{ paddingRight: "8px", paddingBottom: "3px" }}
                        src={CriticalIcon}
                      />
                      Delivery Restrictions
                    </div>
                    <div className="restriction-attribute">
                      <img
                        alt="Apartment Icon"
                        style={{
                          marginRight: "8px",
                          marginTop: "3px",
                          width: "17px",
                          height: "17px",
                        }}
                        src={ApartmentIcon}
                      />
                      {docId.data.delvyRestrictions}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {typeof docId.data.guideEligibilityAndProjectInfoSt !==
                  "undefined" ? (
                  <>
                    {(docId.data.guideEligibilityAndProjectInfoSt
                      .geHotelMotelResortIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geTimeshareCountSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geHouseBoatIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geMulLivingSpacesIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geSplitOwnershipIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geNIncIncomeAbovePmtdIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .gePerTotIncNonIncIncomeSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geUnpaidCommonExpAsmtsIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geCareFacilityIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .gePayMemshpOrLeaseIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .getCmplnHOAOwnshpIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geEnvHazardIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .geRtFtRefslOrReSlRstnsIndSt === "Fail" ||
                      docId.data.newProjectEligibilitySt
                        .npeFinConExcessIndSt === "Fail") &&
                      docId.data.certificationStatus === "Guide Ineligible" ? (
                      <>
                        <p
                          className="print-page-header-title"
                          style={{ color: "#D0021B" }}
                        >
                          1. Guide Eligibility
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="print-page-header-title">
                          1. Guide Eligibility
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="print-page-header-title">
                      1. Guide Eligibility
                    </p>
                  </>
                )}
                <GuideEligibility data={docId.data} />
                {typeof docId.data.guideEligibilityAndProjectInfoSt !==
                  "undefined" ? (
                  <>
                    {(docId.data.guideEligibilityAndProjectInfoSt
                      .piYearBuiltSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piConvtdProjIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piGutRehabIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piYearConvtdSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piAddnPhsOrAnnxIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piCntlHOATurnOverIndSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piDtTurndOverSt === "Fail" ||
                      docId.data.guideEligibilityAndProjectInfoSt
                        .piCommnAreasCompltIndSt === "Fail" ||
                      docId.data.newProjectEligibilitySt
                        .npeAssrArrgReviewedIndSt === "Fail") &&
                      docId.data.certificationStatus === "Guide Ineligible" ? (
                      <>
                        <p
                          className="print-page-header-title"
                          style={{ color: "#D0021B" }}
                        >
                          2. Project Information
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="print-page-header-title">
                          2. Project Information
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="print-page-header-title">
                      2. Project Information
                    </p>
                  </>
                )}
                <ProjectInformation data={docId.data} />
                {typeof docId.data.projectPresaleSt !== "undefined" ? (
                  <>
                    {(docId.data.projectPresaleSt.ppTotalUnitsSt === "Fail" ||
                      docId.data.projectPresaleSt.ppOwnerOccupiedUnitsSt ===
                      "Fail" ||
                      docId.data.projectPresaleSt.ppSecondHomesSt === "Fail" ||
                      docId.data.projectPresaleSt.ppUnitsRentedSt === "Fail" ||
                      docId.data.projectPresaleSt.ppUnitsCompleteSt ===
                      "Fail" ||
                      docId.data.projectPresaleSt.ppUnitsConveyedSt ===
                      "Fail" ||
                      docId.data.projectPresaleSt.ppPreSaleConveyPercntgeSt ===
                      "Fail" ||
                      docId.data.projectPresaleSt
                        .ppDevOwndUntsRentalHoldBackIndSt === "Fail" ||
                      docId.data.projectPresaleSt.ppDevOwnedUnitsSt ===
                      "Fail" ||
                      docId.data.projectPresaleSt
                        .ppMaintFeesDevOwndCurrentIndSt === "Fail" ||
                      docId.data.projectPresaleSt
                        .ppActivePendingSpAssmntsIndSt === "Fail") &&
                      docId.data.certificationStatus === "Guide Ineligible" ? (
                      <>
                        <p
                          className="print-page-header-title"
                          style={{ color: "#D0021B" }}
                        >
                          3. Project: Presale and Investor Concentration
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="print-page-header-title">
                          3. Project: Presale and Investor Concentration
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="print-page-header-title">
                      3. Project: Presale and Investor Concentration
                    </p>
                  </>
                )}
                <ProjectPresaleAndInvestorConcentration data={docId.data} convResult={convResult} />
                {phaseId === "proj" ? (
                  <></>
                ) : (
                  <>
                    {typeof docId.data.phasePresaleSt !== "undefined" ? (
                      <>
                        {(docId.data.phasePresaleSt.phpUnitsCompleteSt ===
                          "Fail" ||
                          docId.data.phasePresaleSt
                            .phpUnitsSoldOwnerOccpSecondHomeRsdnSt === "Fail" ||
                          docId.data.phasePresaleSt.phpOwnerOccupiedUnitsSt ===
                          "Fail" ||
                          docId.data.phasePresaleSt.phpSecondHomesSt ===
                          "Fail") &&
                          docId.data.certificationStatus ===
                          "Guide Ineligible" ? (
                          <>
                            <p
                              className="print-page-header-title"
                              style={{ color: "#D0021B" }}
                            >
                              4. Phase: Presale and Investor Concentration
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="print-page-header-title">
                              4. Phase: Presale and Investor Concentration
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="print-page-header-title">
                          4. Phase: Presale and Investor Concentration
                        </p>
                      </>
                    )}
                    <PhasePresaleAndInvestorConcentration data={docId.data} />
                  </>
                )}
                <>
                  {" "}
                  {docId.data.certProjectType === "projectEst" ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      {typeof docId.data.newProjectEligibilitySt !==
                        "undefined" ? (
                        <>
                          {(docId.data.newProjectEligibilitySt
                            .npeMasterAsscIndSt === "Fail" ||
                            docId.data.newProjectEligibilitySt
                              .npePayMnthyAssesLndrRevwPlanIndSt === "Fail" ||
                            docId.data.newProjectEligibilitySt
                              .npeLglDocsComplyIndSt === "Fail") &&
                            docId.data.certificationStatus ===
                            "Guide Ineligible" ? (
                            <>
                              {phaseId === "proj" ? (
                                <p
                                  className="print-page-header-title"
                                  style={{ color: "#D0021B" }}
                                >
                                  4. New Project Eligibility
                                </p>
                              ) : (
                                <p
                                  className="print-page-header-title"
                                  style={{ color: "#D0021B" }}
                                >
                                  5. New Project Eligibility
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {phaseId === "proj" ? (
                                <p className="print-page-header-title">
                                  4. New Project Eligibility
                                </p>
                              ) : (
                                <p className="print-page-header-title">
                                  5. New Project Eligibility
                                </p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {phaseId === "proj" ? (
                            <p className="print-page-header-title">
                              4. New Project Eligibility
                            </p>
                          ) : (
                            <p className="print-page-header-title">
                              5. New Project Eligibility
                            </p>
                          )}
                        </>
                      )}
                      <NewProjectEligibility data={docId.data} />
                    </>
                  )}
                </>
                {typeof docId.data.projectOwnershipSt !== "undefined" ? (
                  <>
                    {(docId.data.projectOwnershipSt
                      .poOwnersHaveSoleOwnshpIndSt === "Fail" ||
                      docId.data.projectOwnershipSt.poUnitsSnglOwnSt ===
                      "Fail" ||
                      docId.data.projectOwnershipSt.poLeaseComplyIndSt ===
                      "Fail" ||
                      docId.data.projectOwnershipSt
                        .poSnglOwnMoreThanOneIndSt === "Fail") &&
                      docId.data.certificationStatus === "Guide Ineligible" ? (
                      <>
                        {docId.data.certProjectType === "projectEst" ? (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            4. Project Ownership
                          </p>
                        ) : phaseId === "proj" ? (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            5. Project Ownership
                          </p>
                        ) : (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            6. Project Ownership
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {(convResult < 90 &&
                          phaseId === "proj" &&
                          docId.data.guideEligibilityAndProjectInfoSt
                            .piAddnPhsOrAnnxInd === "No" &&
                          docId.data.guideEligibilityAndProjectInfoSt
                            .piCntlHOATurnOverInd === "Yes" &&
                          docId.data.guideEligibilityAndProjectInfoSt
                            .piCommnAreasCompltInd === "Yes" &&
                          docId.data.projectPresaleSt.ppUnitsComplete ===
                          docId.data.projectPresaleSt.ppTotalUnits &&
                          docId.data.projectPresaleSt
                            .ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                          docId.data.projectPresaleSt.ppDevOwnedUnits /
                          docId.data.projectPresaleSt.ppTotalUnits <=
                          0.2) ||
                          (convResult >= 90 &&
                            phaseId === "proj" &&
                            docId.data.guideEligibilityAndProjectInfoSt
                              .piAddnPhsOrAnnxInd === "No" &&
                            docId.data.guideEligibilityAndProjectInfoSt
                              .piCntlHOATurnOverInd === "Yes" &&
                            docId.data.guideEligibilityAndProjectInfoSt
                              .piCommnAreasCompltInd === "Yes" &&
                            docId.data.projectPresaleSt.ppUnitsComplete ===
                            docId.data.projectPresaleSt.ppTotalUnits) ? (
                          <p className="print-page-header-title">
                            4. Project Ownership
                          </p>
                        ) : phaseId === "proj" ? (
                          <p className="print-page-header-title">
                            5. Project Ownership
                          </p>
                        ) : (
                          <p className="print-page-header-title">
                            6. Project Ownership
                          </p>
                        )}
                      </>
                    )}
                  </>
                      
                ) : (
                  <>
                    {docId.data.certProjectType === "projectEst" ? (
                      <p className="print-page-header-title">
                        4. Project Ownership
                      </p>
                    ) : phaseId === "proj" ? (
                      <p className="print-page-header-title">
                        5. Project Ownership
                      </p>
                    ) : (
                      <p className="print-page-header-title">
                        6. Project Ownership
                      </p>
                    )}
                  </>
                )}
                <ProjectOwnership data={docId.data} />
                {typeof docId.data.lcFinancialsSt !== "undefined" ? (
                  <>
                    {(    docId.data.lcFinancialsSt.famtExclItemsSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fbugtAnlIncomeSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fbugtAnlReservesSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fdefdMntIndSt === 
                          "Fail" ||
                          docId.data.lcFinancialsSt.finspFailIndSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fleakRepairIndSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fmetDefIndSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fphyDetIndSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.fresStudyReqIndSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.funfundRepIndSt ===
                          "Fail" ||
                          docId.data.lcFinancialsSt.funitsHOAExpnPastDueSt ===
                          "Fail" ||
                      fspAssmtsRuleCheck( docId.data.lcFinancialsSt.fspAssmts) === "Fail"
                      ) &&
                      docId.data.certificationStatus === "Guide Ineligible" ? (
                      <>
                        {(convResult < 90 && phaseId === 'proj' &&
                          docId.data.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                          docId.data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                          docId.data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                          docId.data.projectPresaleSt.ppUnitsComplete ===
                          docId.data.projectPresaleSt.ppTotalUnits &&
                          docId.data.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                          docId.data.projectPresaleSt.ppDevOwnedUnits / docId.data.projectPresaleSt.ppTotalUnits <= 0.2) ||
                          (convResult >= 90 && phaseId === 'proj' &&
                            docId.data.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                            docId.data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                            docId.data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                            docId.data.projectPresaleSt.ppUnitsComplete ===
                            docId.data.projectPresaleSt.ppTotalUnits) ? (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            5. Financials, Special Assessments & Critical Repairs
                          </p>
                        ) : phaseId === 'proj' ? (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            6. Financials, Special Assessments & Critical Repairs
                          </p>
                        ) : (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            7. Financials, Special Assessments & Critical Repairs
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {(convResult < 90 &&
                          phaseId === 'proj' &&
                          docId.data.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                          docId.data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                          docId.data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                          docId.data.projectPresaleSt.ppUnitsComplete ===
                          docId.data.projectPresaleSt.ppTotalUnits &&
                          docId.data.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                          "Yes" &&
                          docId.data.projectPresaleSt.ppDevOwnedUnits /
                          docId.data.projectPresaleSt.ppTotalUnits <=
                          0.2) ||
                          (convResult >= 90 &&
                            phaseId === 'proj' &&
                            docId.data.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                            docId.data.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                            docId.data.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                            docId.data.projectPresaleSt.ppUnitsComplete ===
                            docId.data.projectPresaleSt.ppTotalUnits) ? (
                          <p className="print-page-header-title">
                            5. Financials, Special Assessments & Critical Repairs
                          </p>
                        ) : phaseId === 'proj' ? (
                          <p className="print-page-header-title">
                            6. Financials, Special Assessments & Critical Repairs
                          </p>
                        ) : (
                          <p className="print-page-header-title">
                            7. Financials, Special Assessments & Critical Repairs
                          </p>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {(convResult < 90 &&
                      phaseId === 'proj' &&
                      docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                      docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                      docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                      docData.projectPresaleSt.ppUnitsComplete ===
                      docData.projectPresaleSt.ppTotalUnits &&
                      docData.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
                      "Yes" &&
                      docData.projectPresaleSt.ppDevOwnedUnits /
                      docData.projectPresaleSt.ppTotalUnits <=
                      0.2) ||
                      (convResult >= 90 &&
                        phaseId === 'proj' &&
                        docData.guideEligibilityAndProjectInfoSt.piAddnPhsOrAnnxInd === "No" &&
                        docData.guideEligibilityAndProjectInfoSt.piCntlHOATurnOverInd === "Yes" &&
                        docData.guideEligibilityAndProjectInfoSt.piCommnAreasCompltInd === "Yes" &&
                        docData.projectPresaleSt.ppUnitsComplete ===
                        docData.projectPresaleSt.ppTotalUnits) ? (
                      <p className="print-page-header-title">
                        5. Financials, Special Assessments & Critical Repairs
                      </p>
                    ) : phaseId === 'proj' ? (
                      <p className="print-page-header-title">
                        6. Financials, Special Assessments & Critical Repairs
                      </p>
                    ) : (
                      <p className="print-page-header-title">
                        7. Financials, Special Assessments & Critical Repairs
                      </p>
                    )}
                  </>
                )}
                <FinancialsSpecialAssessmentsCriticalRepairs data={docId.data} />

                {typeof docId.data.lcLitigationSt !== "undefined" ? (
                  <>
                    {(docId.data.lcLitigationSt.lsafetyLitgtnIndSt === "Fail" ||
                      docId.data.lcLitigationSt.linsurancCvgIndSt === "Fail" ||
                      docId.data.lcLitigationSt.lminrMttrLitgtnIndSt ===
                      "Fail" ||
                      docId.data.lcLitigationSt.lnonResSqFtgSt === "Fail") &&
                      docId.data.certificationStatus === "Guide Ineligible" ? (
                      <>
                        {docId.data.certProjectType === "projectEst" ? (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            6. Litigation, Usage & Insurance
                          </p>
                        ) : phaseId === "proj" ? (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            7. Litigation, Usage & Insurance
                          </p>
                        ) : (
                          <p
                            className="print-page-header-title"
                            style={{ color: "#D0021B" }}
                          >
                            8. Litigation, Usage & Insurance
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {docId.data.certProjectType === "projectEst" ? (
                          <p className="print-page-header-title">
                            6. Litigation, Usage & Insurance
                          </p>
                        ) : phaseId === "proj" ? (
                          <p className="print-page-header-title">
                            7. Litigation, Usage & Insurance
                          </p>
                        ) : (
                          <p className="print-page-header-title">
                            8. Litigation, Usage & Insurance
                          </p>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {docId.data.certProjectType === "projectEst" ? (
                      <p className="print-page-header-title">
                        6. Litigation, Usage & Insurance
                      </p>
                    ) : phaseId === "proj" ? (
                      <p className="print-page-header-title">
                        7. Litigation, Usage & Insurance
                      </p>
                    ) : (
                      <p className="print-page-header-title">
                        8. Litigation, Usage & Insurance
                      </p>
                    )}
                  </>
                )}
                <LitigationUsageAndInsurance data={docId.data} />
              </div>

              <div className="form-footer" style={{ display: "block" }}>
                <div className="user-agreement-title">User Agreement</div>
                {!isTPORole() &&
                  <div className="disclosure">
                    Lender certifies and agrees that (i) the Lender has reviewed
                    and recommends the project for approval by Fannie Mae; (ii)
                    all provisions of the Fannie Mae Selling Guide (collectively,
                    including all Announcements, the{" "}
                    <strong>“Selling Guide”</strong>) pertaining to the Full
                    Review of condo projects using CPM will apply to the subject
                    project and that Lender is responsible for ensuring full
                    compliance with all applicable provisions; (iii) the
                    information and statements contained in this application are
                    true and correct; and (iv) to the best of Lender’s knowledge,
                    there is nothing about the subject property that would make
                    the project ineligible or cause the project to be ineligible
                    for the Full Review process using CPM. Lender agrees that the
                    questions included in CPM are provided to assist the Lender
                    with the Full Review process, that CPM Certifications are
                    based solely on data that the Lender enters in CPM and that
                    additional project eligibility requirements may be included in
                    the Selling Guide. If there is any conflict between the
                    project eligibility requirements in CPM and those in the
                    Selling Guide, the Selling Guide provisions will govern. The
                    Lender must notify the CPM Management team if it becomes aware
                    of any information that could impact the eligibility status
                    reflected in CPM and must provide such notification as soon as
                    practicable but no later than five days after becoming aware
                    of such information. In such instances, Lender must confirm
                    the project’s approved status before selling a loan to Fannie
                    Mae that is secured by one of the project’s units. Fannie Mae
                    reserves the right to change a project’s eligibility status
                    designation after certification if information acquired has an
                    impact on a previously issued eligibility determination.
                  </div>
                }
                {isTPORole() &&
                  <div className="disclosure">
                    Licensee certifies and agrees that (i) the Licensee has reviewed
                    and recommends the project for approval by Fannie Mae; (ii)
                    all provisions of the Fannie Mae Selling Guide (collectively,
                    including all Announcements, the{" "}
                    <strong>“Selling Guide”</strong>) pertaining to the Full
                    Review of condo projects using CPM will apply to the subject
                    project and that Licensee is responsible for ensuring full
                    compliance with all applicable provisions; (iii) the
                    information and statements contained in this application are
                    true and correct; and (iv) to the best of Licensee’s knowledge,
                    there is nothing about the subject property that would make
                    the project ineligible or cause the project to be ineligible
                    for the Full Review process using CPM. Licensee agrees that the
                    questions included in CPM are provided to assist the Licensee
                    with the Full Review process, that CPM Certifications are
                    based solely on data that the Licensee enters in CPM and that
                    additional project eligibility requirements may be included in
                    the Selling Guide. If there is any conflict between the
                    project eligibility requirements in CPM and those in the
                    Selling Guide, the Selling Guide provisions will govern. The
                    Licensee must notify the CPM Management team if it becomes aware
                    of any information that could impact the eligibility status
                    reflected in CPM and must provide such notification as soon as
                    practicable but no later than five days after becoming aware
                    of such information. In such instances, Licensee must confirm
                    the project’s approved status before selling a loan to Fannie
                    Mae that is secured by one of the project’s units. Fannie Mae
                    reserves the right to change a project’s eligibility status
                    designation after certification if information acquired has an
                    impact on a previously issued eligibility determination.
                  </div>
                }
                <div className="user-information">
                  <div className="contact-phone">
                    Lender Contact Phone{" "}
                    <span style={{ color: "#BD2B2B" }}>*</span>
                  </div>
                  <div className="user-phone">{docId.data.lenderPhone}</div>
                  <div className="contact-email">
                    Lender Contact Email{" "}
                    <span style={{ color: "#BD2B2B" }}>*</span>
                  </div>
                  <div className="user-email">{docId.data.lenderEmail}</div>
                  <div className="contact-id">
                    User ID <span style={{ color: "#BD2B2B" }}>*</span>
                  </div>
                  <div className="user-id">{docId.data.createdBy}</div>
                  <div className="contact-lender">
                    Lender <span style={{ color: "#BD2B2B" }}>*</span>
                  </div>
                  <div className="user-lender">{docId.data.lenderId}</div>
                </div>
                <div
                  className="user-agreement-title"
                  style={{ marginTop: "20px" }}
                >
                  User Agreement
                </div>
                <div className="user-agreement-subtitle">
                  Electronic Signature
                  <span style={{ color: "#BD2B2B" }}>*</span>
                </div>
                <div className="user-agreement-attribute">
                  Signed by: {docId.data.lenderUserName}
                </div>
              </div>
              <div className="form-footer">
                <div></div>
                <div>
                  {phaseId === "proj" ? (
                    <>
                      <button
                        className="complete-cert-print-close-btn"
                        onClick={goToProject}
                      >
                        Close
                      </button>
                      <button
                        onClick={window.print}
                        className="complete-cert-print-btn"
                      >
                        <PrintIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#0C77BA",
                            paddingRight: "6px",
                          }}
                        />
                        Print
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="complete-cert-print-close-btn"
                        onClick={goToPhase}
                      >
                        Close
                      </button>
                      <button
                        onClick={window.print}
                        className="complete-cert-print-btn"
                      >
                        <PrintIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#0C77BA",
                            paddingRight: "6px",
                          }}
                        />
                        Print
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

import * as React from "react";
import PropTypes from "prop-types";
import "./SelectStyles.css";

const Select = ({
  id,
  name,
  label,
  changeHandler,
  options,
  value,
  disabled,
  selectWidth,
  marginTop
}) => {
  const [selectValue, setSelectValue] = React.useState("");

  React.useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const onChangeHandler = event => {
    setSelectValue(event.target.value);
    changeHandler(event);
  };
  return (
    <div className="selectContainer">
      {label && (
        <div>
          <label htmlFor={id} className="selectLabel">
            {label}
          </label>
        </div>
      )}

      <div>
        <select
          aria-label={name}
          name={name}
          id={id}
          disabled={disabled}
          className={"select"}
          onChange={onChangeHandler}
          value={selectValue}
          style={{ width: selectWidth }}
        >
          {options &&
            options.length > 0 &&
            options.map(item => {
              return (
                <option key={item.key} value={item.key}>
                  {item.value}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  changeHandler: PropTypes.func.isRequired
};

export default Select;

import React from "react";
import Card from "react-bootstrap/Card";
import "./Row.css";

const Row = ({ project }) => {
  const {
    projectName,
    street,
    city,
    state,
    newProjectType,
    hoaTaxId,
    projectId,
  } = project;
  console.log(project);

  return (
    <a href={`/project/${projectId}`}>
      <Card variant="left" className="list-card-row">
        <Card.Body className="list-card-body">
          <div className="list-card-project-name">{projectName}</div>
          <div className="list-card-status">{newProjectType}</div>
          <div className="list-card-address">{street}</div>
          <div className="list-card-city">{city}</div>
          <div className="list-card-state">{state}</div>
          <div className="list-card-projectid">{projectId}</div>
          <div className="list-card-hoaid">{hoaTaxId}</div>
        </Card.Body>
      </Card>
    </a>
  );
};

export default Row;

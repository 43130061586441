import React from "react";
export const LitigationUsageAndInsurance = ({ data }) => {
    return (
      <div style={{ padding: "18px 24px" }}>
      <div className="question">
        <div className="number">1.</div>{" "}
        <div>
          Is there any pre-litigation or litigation
          associated with the project, and naming either
          the HOA or Developer as a party?
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.lcLitigationSt.llitigationInd}
        </div>
      </div>
      {data.lcLitigationSt.llitigationInd === "Yes" ? (
        <>
          <div className="question">
            <div
              className="number"
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.4)",
                borderBottom: "1px solid rgba(0,0,0,0.4)",
                marginLeft: "37px",
                marginBottom: "10px",
              }}
            ></div>{" "}
            <div style={{ paddingTop: "10px" }}>
              Is the project currently in litigation
              related to the safety, structural soundness,
              habitability, or functional use of the
              project?
            </div>
          </div>
          <div
            className="summary-answer"
            style={{ paddingLeft: "86px" }}
          >
            <div className="label">Answer:&nbsp;</div>
            {typeof data.lcLitigationSt !==
            "undefined" ? (
              <>
                {data.lcLitigationSt
                  .lsafetyLitgtnIndSt === "Fail" ? (
                  <>
                    <div
                      className="attribute"
                      style={{ color: "#D0021B" }}
                    >
                      {data.lcLitigationSt.lsafetyLitgtnInd}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="attribute">
                      {data.lcLitigationSt.lsafetyLitgtnInd}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {data.lcLitigationSt.llitigationInd === "Yes" ? (
        <>
          <div className="question">
            <div
              className="number"
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.4)",
                borderBottom: "1px solid rgba(0,0,0,0.4)",
                marginLeft: "37px",
                marginBottom: "10px",
              }}
            ></div>{" "}
            <div style={{ paddingTop: "10px" }}>
              Is the project currently in litigation
              involving minor matters as defined in the{" "}
              <a
                target="_blank"
                href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
              >
                {" "}
                Selling Guide
              </a>
              ?
            </div>
          </div>
          <div
            className="summary-answer"
            style={{ paddingLeft: "86px" }}
          >
            <div className="label">Answer:&nbsp;</div>
            {typeof data.lcLitigationSt !==
            "undefined" ? (
              <>
                {data.lcLitigationSt
                  .lminrMttrLitgtnIndSt === "Fail" ? (
                  <>
                    <div
                      className="attribute"
                      style={{ color: "#D0021B" }}
                    >
                      {data.lcLitigationSt.lminrMttrLitgtnInd}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="attribute">
                      {data.lcLitigationSt.lminrMttrLitgtnInd}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="question">
        <div className="number">2.</div>{" "}
        <div>
          Does the project or building in which the
          project is located contain commercial or
          mixed-use space?
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.lcLitigationSt.lcommerclSpaceInd}
        </div>
      </div>
      {data.lcLitigationSt.lcommerclSpaceInd === "Yes" ? (
        <>
          <div className="question">
            <div
              className="number"
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.4)",
                borderBottom: "1px solid rgba(0,0,0,0.4)",
                marginLeft: "37px",
                marginBottom: "10px",
              }}
            ></div>{" "}
            <div style={{ paddingTop: "10px" }}>
              What is the total percentage used for
              non-residential purposes as defined by the{" "}
              <a
                target="_blank"
                href="https://www.fanniemae.com/content/guide/selling/b4/2.1/03.html"
              >
                {" "}
                Selling Guide
              </a>
              ?
            </div>
          </div>
          <div
            className="summary-answer"
            style={{ paddingLeft: "86px" }}
          >
            <div className="label">Answer:&nbsp;</div>
            {typeof data.lcLitigationSt !==
            "undefined" ? (
              <>
                {data.lcLitigationSt.lnonResSqFtgSt ===
                "Fail" ? (
                  <>
                    <div
                      className="attribute"
                      style={{ color: "#D0021B" }}
                    >
                      {data.lcLitigationSt.lnonResSqFtg}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="attribute">
                      {data.lcLitigationSt.lnonResSqFtg}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="question">
        <div className="number">3.</div>{" "}
        <div>
          Does the project’s insurance coverage (including
          property, flood, liability, and fidelity/crime,
          as applicable) meet the Selling Guide
          requirements in Section{" "}
          <a
            target="_blank"
            href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B7-Insurance/Chapter-B7-3-Property-and-Flood-Insurance/"
          >
            {" "}
            B7-3
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B7-Insurance/Chapter-B7-4-Liability-and-Fidelity-Crime-Insurance/"
          >
            {" "}
            B7-4
          </a>
          ?
        </div>
      </div>
      <div className="summary-answer">
        <div className="label">Answer:&nbsp;</div>
        {typeof data.lcLitigationSt !== "undefined" ? (
          <>
            {data.lcLitigationSt.linsurancCvgIndSt ===
            "Fail" ? (
              <>
                <div
                  className="attribute"
                  style={{ color: "#D0021B" }}
                >
                  {data.lcLitigationSt.linsurancCvgInd}
                </div>
              </>
            ) : (
              <>
                <div className="attribute">
                  {data.lcLitigationSt.linsurancCvgInd}
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
    );
}

import * as React from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import "./AddNewPhase.css";
import { fetchStates, fetchStyles } from "../../redux/actions/filterActions";
import VerticalLinearStepper from "../VerticalLinearStepper/VerticalLinearStepper";
import AddNewPhasePage from "./Pages/AddNewPhasePage";
import { fetchPhaseTypes } from "../../redux/actions/addPhaseTypeActions";
import SuccessPage from "../SuccessPage/SuccessPage";
import { useParams } from "react-router-dom";

const AddNewPhase = () => {
  const [stepCount, setStepCount] = React.useState(0);
  const { project_id } = useParams();

  const [newPhase, setNewPhase] = React.useState("");

  const incrementCounter = () => setStepCount(stepCount + 1);
  const decrementCounter = () => setStepCount(stepCount - 1);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchStates());
    dispatch(fetchPhaseTypes());
    window.scrollTo(0, 0);
  }, []);
  const getPage = () => {
    switch (stepCount) {
      case 0:
        return (
          <AddNewPhasePage
            incStepCounter={incrementCounter}
            decStepCounter={decrementCounter}
            addPhase={setNewPhase}
          />
        );
      case 1:
        return (
          <SuccessPage
            msg={`A new phase ${newPhase} has been added!`}
            project_id={project_id}
          />
        );
      default:
        return "";
    }
  };

  return (
    <Grid container>
      <Grid item xs={3} style={{ backgroundColor: "#F2F2F2" }}>
        <VerticalLinearStepper
          steps={["Add New Phase", "Final Confirmation"]}
          activeStep={stepCount}
        />
      </Grid>
      <Grid
        container
        item
        xs={9}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ padding: "20px", border: "solid 1px black" }}
      >
        {getPage()}
      </Grid>
    </Grid>
  );
};

export default AddNewPhase;

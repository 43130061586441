import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import {
  TextField,
  makeStyles,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import Currency, { handleValueChange } from "../Currency";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import SplAssmtList from "./SplAssmtList";
import ReactSelect from "react-select";
import Option from "../../Option";
import { MaxSplAssmt, MaterialOptions } from "../../shared/constants/SplAssmtReasons";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'

export const Page7 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  convResult,
  midRuleType,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const [specialAssessmentError, setSpecialAssessmentError] = useState("");
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";
  const [specialAssessmentData, setSpecialAssessmentData] = useState([]);
  const materialOptions = MaterialOptions;
  const [showOtherMaterialDeficiencies, setShowOtherMaterialDeficiencies] = useState(formData.fmetDefElem.filter(r => r.label === "Other").length > 0 ? true : false);
  const [showOtherPhysicalDeterioration, setShowOtherPhysicalDeterioration] = useState(formData.fphyDetEle.filter(r => r.label === "Other").length > 0 ? true : false);
  const [fphyDetEle, setFphyDetEle] = useState(formData.fphyDetEle);
  const [fmetDefElem, setFfmetDefElem] = useState(formData.fmetDefElem);

  const handleOptionChange = (event, field) => {
    if (field === 'MaterialDeficiencies') {
      setShowOtherMaterialDeficiencies(event.filter(r => r.label === "Other").length > 0 ? true : false);
    } else {
      setShowOtherPhysicalDeterioration(event.filter(r => r.label === "Other").length > 0 ? true : false);
    }
  };

  const [spcAssmtFormValidation, setSpcAssmtFormValidation] = useState([]);
  const [onSave, setOnSave] = useState(false);

  function saveApi() {
    let requestObject = certificationFormatter(formData);
    requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
        ? ""
        : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
        `/lndrCertification/saveLenderCertificationFormsPhase`,
        requestObject
      )
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            if (formData.phaseId === "proj") {
              window.location.href = "/project/" + formData.projectId;
            } else {
              window.location.href = "/project/phase/" + formData.phaseId;
            }
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          }
        }
      })
      .catch((error) => {
        console.log("certification phase error", error);
      });
  }

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateExpirationDate(value) {
    if (!value) {
      return "Required";
    }

    const inputDate = new Date(value);

    if (inputDate.toString() === "Invalid Date") {
      return "Invalid date format";
    }

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);
    minDate.setUTCHours(0);
    minDate.setUTCMinutes(0);
    minDate.setUTCSeconds(0);
    minDate.setUTCMilliseconds(0);

    if (inputDate < minDate) {
      return "Date cannot be before one year ago."
    }

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 5)

    if (inputDate > maxDate) {
      return "Date cannot be more than 5 years in the future."
    }
  }

  function validateNumNoDec(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  function updateMinDate() {
    let date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    return date.toISOString().slice(0, 10);
  }

  const minDate = updateMinDate();

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
    inputCurrency: {
      backgroundColor: "#FCFCFC!important",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();

  const saveAndNext = (data) => {
    setOnSave(!onSave);
    setTimeout(() => {
      if (spcAssmtFormValidation?.filter(v => v === false).length === 0 && specialAssessmentError === "") {
        let values = data;
        values.fspAssmts = specialAssessmentData;
        values = updateResetQuestions(values, 'next');
        setFormData(values);
        nextStep();
      }
    }, 500);
  };

  const updateResetQuestions = (data, action) => {
    let values = data
    if (action === 'save') {
      values = page7tMultiSelectOptions(data);
    }
    //Reset sub questions
    if (((values.fbugtAnlReserves / (values.fbugtAnlIncome - values.famtExclItems)) * 100 * 1) > 10) {
      values.fresStudyReqInd = "";
    }
    if (values.fmetDefInd !== "Yes") {
      values.fmetDefElem = [];
      values.fotherReasonMetDef = "";
    }

    if (values.fphyDetInd !== "Yes") {
      values.fphyDetEle = [];
      values.fotherReasonPhyDet = "";
    }

    if (values.fphyDetEle?.filter(r => r.label === "Other").length === 0) {
      values.fotherReasonPhyDet = "";
    }
    if (values.fmetDefElem.filter(r => r.label === "Other").length === 0) {
      values.fotherReasonMetDef = "";
    }

    return values
  }

  const updateSpecialAssessmentData = (data, index, validForm) => {
    let actualData = specialAssessmentData;
    data.fnoOfUnitsDelinquent = data.fnoOfUnitsDelinquent ? parseInt(data.fnoOfUnitsDelinquent) : data.fnoOfUnitsDelinquent;
    data.fttlSpAssmts = data.fttlSpAssmts ? parseInt(data.fttlSpAssmts) : data.fttlSpAssmts;
    actualData[index] = data;
    setSpecialAssessmentData(actualData);
    if (spcAssmtFormValidation.length !== 0) {
      let validationforms = spcAssmtFormValidation
      validationforms[index] = validForm
      setSpcAssmtFormValidation(validationforms);
    }
  }

  function validateFsplAssmtsInd(value) {
    setSpecialAssessmentError("")
    if (parseInt(value) !== specialAssessmentData.length) {
      let specialAssessmentTemplate = {
        fttlSpAssmts: "",
        freasonsSpAssmts: [],
        fotherReasonSpAssmts: "",
        fremediateRepairInd: "",
        fnoOfUnitsDelinquent: "",
      }
      let specialAssessmentData = []
      let validationforms = []
      let error = validateNumDec(value);
      if (!error) {
        if (parseInt(value) > MaxSplAssmt) {
          error = "Can enter max up to 5 Special Assessments, If this project has more than 5 special assessments, please contact Fannie Mae for additional guidance.";
          setSpecialAssessmentError("Can enter max up to 5 Special Assessments, If this project has more than 5 special assessments, please contact Fannie Mae for additional guidance.")
        } else {
          setSpecialAssessmentError("")
          specialAssessmentData = Array.from({ length: parseInt(value) }).map(x => specialAssessmentTemplate)
          setSpecialAssessmentData(specialAssessmentData)
          validationforms = Array.from({ length: parseInt(value) }).map(x => false);
          setSpcAssmtFormValidation(validationforms)
        }
      } else {
        setSpecialAssessmentData([]);
        setSpcAssmtFormValidation([]);
        return error;
      }
    }
  }

  function validateNumDec(value) {
    let error;
    if (!value) { 
      if (value === 0) {
        return error;
      }
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  function renderSpecialAssessment(ppTotalUnits) {
    if (document.getElementById("fnoOfSpAssmts")?.value) {
      let fnoOfSpAssmts = parseInt(document.getElementById("fnoOfSpAssmts").value);
      if (specialAssessmentData.length === 0) {
        if (specialAssessmentData.length !== formData.fspAssmts) {
          if (formData.fspAssmts.length > 0) {
            let specialAssData = formData.fspAssmts;
            setSpecialAssessmentData(specialAssData);
            if (spcAssmtFormValidation.length === 0) {
              let validationforms = []
              validationforms = Array.from({ length: parseInt(fnoOfSpAssmts) }).map(x => true);
              setSpcAssmtFormValidation(validationforms)
            }
          }

        }
      }
      let list;
      if (fnoOfSpAssmts && fnoOfSpAssmts !== 0 && fnoOfSpAssmts <= 5) {
        list = (
          <SplAssmtList list={specialAssessmentData} updateSpecialAssessmentData={updateSpecialAssessmentData} totalUnits={ppTotalUnits} onSave={onSave}
          />
        );
      }
      return list;
    }
  }

  function formatLimitDecimals(value, decimals) { 
    const stringValue = value.toString();
    if(stringValue.includes('e')) {
        // TODO: remove exponential notation
        throw 'invald number';
    } else {
      const [integerPart, decimalPart] = stringValue.split('.');
      if(decimalPart) {
        return +[integerPart, decimalPart.slice(0, decimals)].join('.')
      } else {
        return integerPart;
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validate={(values) => {
        const errors = {};
        if (!values.fbugtAnlIncome) {
          errors.fbugtAnlIncome = "Required";
        } else if (
          !/(^[0-9]{1,8})+(\.[0-9]{1,2})?$/i.test(values.fbugtAnlIncome)
        ) {
          errors.fbugtAnlIncome = "Invalid Currency";
        }
        if (!values.famtExclItems && values.famtExclItems !== 0) {
          errors.famtExclItems = "Required";
        } else if (
          !/(^[0-9]{1,8})+(\.[0-9]{1,2})?$/i.test(values.famtExclItems)
        ) {
          errors.famtExclItems = "Invalid Currency";
        }
        if (!values.fbugtAnlReserves) {
          errors.fbugtAnlReserves = "Required";
        } else if (
          !/(^[0-9]{1,8})+(\.[0-9]{1,2})?$/i.test(values.fbugtAnlReserves)
        ) {
          errors.fbugtAnlReserves = "Invalid Currency";
        }
        if (values.fsplAssmtsInd === "Yes") {
          if (!values.fttlSpAssmts) {
            errors.fttlSpAssmts = "Required";
          } else if (
            !/(^[0-9]{1,8})+(\.[0-9]{1,2})?$/i.test(values.fttlSpAssmts)
          ) {
            errors.fttlSpAssmts = "Invalid Currency";
          }
        }
        return errors;
      }}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.phaseId === "proj" &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={4} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={5} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={5} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={6} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  {values.phaseId === "proj" ? (
                    <div
                      className="info-container"
                      style={{ padding: "0px 0px 0px 134px" }}
                    >
                      <>
                        <div className="type">Project Type</div>
                        <div className="attributes">
                          {status === "est" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectNew" ? (
                                <div>
                                  New
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>Established</div>
                              )}
                            </>
                          ) : status === "new" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectEst" ? (
                                <div>
                                  Established
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>New</div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {midRuleType === "projectNew" ? (
                        <div className="subheader">Section 6 OF 8</div>
                      ) : (
                        <div className="subheader">Section 5 OF 7</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 7 OF 9</div>
                    </>
                  )}
                  <div className="title">
                    Financials, Special Assessments & Critical Repairs
                  </div>
                  <div className="subtitle"></div>
                </div>
                <div className="form-body">
                  <div className="question">
                    <div className="number">1.</div>
                    <div>
                      How many units are ≥60 days past due on their HOA common
                      expense assessments?
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="funitsHOAExpnPastDue"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateNumNoDec}
                      error={
                        touched.funitsHOAExpnPastDue &&
                        errors.funitsHOAExpnPastDue
                      }
                      helperText={
                        touched.funitsHOAExpnPastDue &&
                        errors.funitsHOAExpnPastDue
                      }
                    />
                  </div>
                  <div className="question">
                    <div className="number">2.</div>
                    <div>
                      What is the expiration date of the current annual budget?{" "}
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="fexpDateAnlBudget"
                      as={TextField}
                      type="date"
                      inputProps={{
                        className: classes.input,
                        min: minDate,
                        max: '9999-12-31'
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateExpirationDate}
                      error={
                        touched.fexpDateAnlBudget && errors.fexpDateAnlBudget
                      }
                      helperText={
                        touched.fexpDateAnlBudget && errors.fexpDateAnlBudget
                      }
                    />
                  </div>
                  <div className="question">
                    <div className="number">3.</div>
                    <div>What are the budgeted annual assessment income?</div>
                  </div>
                  <div className="answer">
                    <Currency
                      name="fbugtAnlIncome"
                      as={TextField}
                      inputProps={{
                        className: classes.inputCurrency,
                      }}
                      variant="outlined"
                      color="#FCFCFC"
                      value={values.fbugtAnlIncome}
                      error={errors.fbugtAnlIncome && touched.fbugtAnlIncome}
                      helperText={
                        touched.fbugtAnlIncome && errors.fbugtAnlIncome
                      }
                      onValueChange={handleValueChange(
                        "fbugtAnlIncome",
                        setFieldValue
                      )}
                    />
                  </div>
                  <div className="question">
                    <div className="number">4.</div>
                    <div>
                      What is the total amount of exclusionary items as defined
                      by the{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                      >
                        {" "}
                        Selling Guide B4-2.2-02
                      </a>
                      ?(Put zero if not applicable)
                    </div>
                  </div>
                  <div className="answer">
                    <Currency
                      name="famtExclItems"
                      as={TextField}
                      inputProps={{
                        className: classes.inputCurrency,
                      }}
                      variant="outlined"
                      color="#FCFCFC"
                      value={values.famtExclItems}
                      error={touched.famtExclItems && errors.famtExclItems}
                      helperText={touched.famtExclItems && errors.famtExclItems}
                      onValueChange={handleValueChange(
                        "famtExclItems",
                        setFieldValue
                      )}
                    />
                  </div>
                  <div className="question">
                    <div className="number">5.</div>
                    <div>What are the budgeted annual reserves?</div>
                  </div>
                  <div className="answer" style={{ display: "flex" }}>
                    <Currency
                      name="fbugtAnlReserves"
                      as={TextField}
                      inputProps={{
                        className: classes.inputCurrency,
                      }}
                      variant="outlined"
                      color="#FCFCFC"
                      value={values.fbugtAnlReserves}
                      error={
                        errors.fbugtAnlReserves && touched.fbugtAnlReserves
                      }
                      helperText={
                        touched.fbugtAnlReserves && errors.fbugtAnlReserves
                      }
                      onValueChange={handleValueChange(
                        "fbugtAnlReserves",
                        setFieldValue
                      )}
                    />
                    <div
                      className="convey-result"
                      style={{ marginLeft: "10px" }}
                    >
                      {(values.fbugtAnlReserves !== "" && values.fbugtAnlReserves != null) &&
                        (values.fbugtAnlIncome !== "" && values.fbugtAnlIncome != null) &&
                        (values.famtExclItems !== "" && values.famtExclItems != null) ? (
                        <>
                          {isFinite(
                            (values.fbugtAnlReserves /
                              (values.fbugtAnlIncome - values.famtExclItems)) *
                            100
                          )
                            ? (
                              formatLimitDecimals(
                                ((values.fbugtAnlReserves /
                                  (values.fbugtAnlIncome -
                                    values.famtExclItems)) * 100), 2)) : "NA"}
                        </>
                      ) : (
                        <></>
                      )}
                      % Reserves
                    </div>
                  </div>
                  {(values.fbugtAnlReserves !== "" && values.fbugtAnlReserves != null) &&
                    (values.fbugtAnlIncome !== "" && values.fbugtAnlIncome != null) &&
                    (values.famtExclItems !== "" && values.famtExclItems != null) ? (
                    <>
                      {(
                        (values.fbugtAnlReserves /
                          (values.fbugtAnlIncome -
                            values.famtExclItems)) *
                        100 *
                        1
                      ) < 10 ? (
                        <>
                          <div className="question">
                            <div
                              className="number"
                              style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                              }}
                            ></div>{" "}
                            <div style={{ paddingTop: "10px" }}>
                              The lender has reviewed a reserve study that meets the requirements in Section
                              <a
                                target="_blank"
                                href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                              >
                                {" "}
                                B4-2.2-02
                              </a> including demonstrating that the project's funded reserves provides adequate financial protection and meet or exceed the recommendations included in the reserve study.
                            </div>
                          </div>
                          <div style={{ display: "flex", paddingLeft: "35px" }}>
                            <div className="answer">
                              <div
                                role="group"
                                aria-labelledby="radio-group"
                                className="r-container"
                              >
                                <label className="label">
                                  <Field
                                    name="fresStudyReqInd"
                                    type="radio"
                                    variant="outlined"
                                    value="Yes"
                                    className="alignment"
                                    validate={validateRadio}
                                  />
                                  Yes
                                </label>
                                <label className="label">
                                  <Field
                                    name="fresStudyReqInd"
                                    type="radio"
                                    variant="outlined"
                                    value="No"
                                    className="alignment"
                                    validate={validateRadio}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div></>) : <></>}
                    </>) : (<></>)
                  }
                  <div className="question">
                    <div className="number">6.</div>{" "}
                    <div>
                      How many planned or active special assessments have been issued  <a
                        target="_blank"
                        href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B4-Underwriting-Property/Chapter-B4-2-Project-Standards/#Projects.20in.20Need.20of.20Critical.20Repairs">
                        {" "}
                        B4-2.1-03
                      </a>? (if none, enter zero)?If the HOA does not separately track special assessments, enter 1
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <Field
                        name="fnoOfSpAssmts"
                        id="fnoOfSpAssmts"
                        as={TextField}
                        inputProps={{
                          className: classes.input,
                        }}
                        type="number"
                        min="0"
                        color="#FCFCFC"
                        variant="outlined"
                        validate={validateFsplAssmtsInd}
                        error={
                          touched.fnoOfSpAssmts &&
                          errors.fnoOfSpAssmts
                        }
                        helperText={
                          touched.fnoOfSpAssmts &&
                          errors.fnoOfSpAssmts
                        }
                      />
                      <div className="form-error">{specialAssessmentError}</div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "-30px" }}>
                    {renderSpecialAssessment(values.ppTotalUnits)}
                  </div>
                  <div className="question">
                    <div className="number">7.</div>{" "}
                    <div>
                      Is the project subject to partial or total evacuation order due to unsafe conditions that have not been remediated?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="radio-answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="radio-container"
                      >
                        <label className="label">
                          <Field
                            name="fdefdMntInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="fdefdMntInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                        <label className="label">
                          <Field
                            name="fdefdMntInd"
                            type="radio"
                            variant="outlined"
                            value="Unable to determine"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Unable to determine
                        </label>
                      </div>
                    </div>
                    {errors.fdefdMntInd && touched.fdefdMntInd && (
                      <div className="form-error">{errors.fdefdMntInd}</div>
                    )}
                  </div>
                  <div className="question">
                    <div className="number">8.</div>{" "}
                    <div>
                      Does the project have any material deficiencies which, if left uncorrected, have the potential to result in or contribute to critical element or system failure within one year (e.g., sea wall, elevators, waterproofing, stairwells)?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="radio-answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="radio-container"
                      >
                        <label className="label">
                          <Field
                            name="fmetDefInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="fmetDefInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                        <label className="label">
                          <Field
                            name="fmetDefInd"
                            type="radio"
                            variant="outlined"
                            value="Unable to determine"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Unable to determine
                        </label>
                      </div>
                    </div>
                    {errors.fmetDefInd && touched.fmetDefInd && (
                      <div className="form-error">{errors.fmetDefInd}</div>
                    )}
                  </div>
                  {values.fmetDefInd === "Yes" ?
                    (<>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          What elements are impacted? (Check all that apply)
                        </div>
                      </div>
                      <div style={{ display: "flex", paddingLeft: "35px" }}>
                        <div className="answer" style={{
                          width: "600px"
                        }}>
                          <ReactSelect
                            name="fmetDefElem"
                            value={fmetDefElem}
                            options={materialOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            allowSelectAll={false}
                            onChange={(value) => {
                              setFfmetDefElem(value);
                              values.fmetDefElem = value;
                              values.fotherReasonMetDef = value?.filter(r => r.label === "Other").length > 0 ? values.fotherReasonMetDef : "";
                              handleOptionChange(value, 'MaterialDeficiencies');
                            }}
                          />
                        </div>
                      </div>
                      {
                        showOtherMaterialDeficiencies === true ? (
                          <>
                            <div className="question">
                              <div
                                className="number"
                                style={{
                                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                                  borderBottom: "1px solid rgba(0,0,0,0.4)",
                                  marginLeft: "37px",
                                  marginBottom: "10px",
                                }}
                              ></div>{" "}
                              <div style={{ paddingTop: "10px" }}>
                                Other Reason?
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="answer">
                                <Field
                                  name="fotherReasonMetDef"
                                  id="fotherReasonMetDef"
                                  as={TextField}
                                  inputProps={{
                                    className: classes.input,
                                  }}
                                  color="#FCFCFC"
                                  variant="outlined"
                                  error={touched.fotherReasonMetDef && errors.fotherReasonMetDef}
                                  helperText={touched.fotherReasonMetDef && errors.fotherReasonMetDef}
                                />

                              </div>
                            </div>
                          </>
                        ) :
                          <></>
                      }
                    </>

                    ) : (<></>)}


                  <div className="question">
                    <div className="number">9.</div>{" "}
                    <div>
                      Is there any mold, water intrusions or potentially damaging leaks to the project's building(s) have not been repaired?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="radio-answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="radio-container"
                      >
                        <label className="label">
                          <Field
                            name="fleakRepairInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="fleakRepairInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                        <label className="label">
                          <Field
                            name="fleakRepairInd"
                            type="radio"
                            variant="outlined"
                            value="Unable to determine"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Unable to determine
                        </label>
                      </div>
                    </div>
                    {errors.fleakRepairInd && touched.fleakRepairInd && (
                      <div className="form-error">{errors.fleakRepairInd}</div>
                    )}
                  </div>
                  <div className="question">
                    <div className="number">10.</div>{" "}
                    <div>
                      Is there any advanced physical deterioration?</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="radio-answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="radio-container"
                      >
                        <label className="label">
                          <Field
                            name="fphyDetInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="fphyDetInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                        <label className="label">
                          <Field
                            name="fphyDetInd"
                            type="radio"
                            variant="outlined"
                            value="Unable to determine"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Unable to determine
                        </label>
                      </div>
                    </div>
                    {errors.fphyDetInd && touched.fphyDetInd && (
                      <div className="form-error">{errors.fphyDetInd}</div>
                    )}
                  </div>
                  {values.fphyDetInd === "Yes" ?
                    (<>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          What elements are impacted? (Check all that apply)
                        </div>
                      </div>
                      <div style={{ display: "flex", paddingLeft: "35px" }}>
                        <div className="answer" style={{
                          width: "600px"
                        }}>
                          <ReactSelect
                            name="fphyDetEle"
                            value={fphyDetEle}
                            options={materialOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            allowSelectAll={false}
                            onChange={(value) => {
                              setFphyDetEle(value);
                              values.fphyDetEle = value;
                              values.fotherReasonPhyDet = value?.filter(r => r.label === "Other").length > 0 ? values.fotherReasonPhyDet : "";
                              handleOptionChange(value, 'PhysicalDeterioration');
                            }}
                          />
                        </div>
                      </div>
                      {
                        showOtherPhysicalDeterioration === true ? (
                          <>
                            <div className="question">
                              <div
                                className="number"
                                style={{
                                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                                  borderBottom: "1px solid rgba(0,0,0,0.4)",
                                  marginLeft: "37px",
                                  marginBottom: "10px",
                                }}
                              ></div>{" "}
                              <div style={{ paddingTop: "10px" }}>
                                Other Reason?
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="answer">
                                <Field
                                  name="fotherReasonPhyDet"
                                  id="fotherReasonPhyDet"
                                  as={TextField}
                                  inputProps={{
                                    className: classes.input,
                                  }}
                                  color="#FCFCFC"
                                  variant="outlined"
                                  error={touched.fotherReasonPhyDet && errors.fotherReasonPhyDet}
                                  helperText={touched.fotherReasonPhyDet && errors.fotherReasonPhyDet}
                                />

                              </div>
                            </div>
                          </>
                        ) :
                          <></>
                      }
                    </>

                    ) : (<></>)}
                  <div className="question">
                    <div className="number">11.</div>{" "}
                    <div>
                      Has the project failed to pass state or other jurisdictional mandatory inspections and/or certifications specific to structural soundness, safety, and habitability?                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="radio-answer" style={{ padding: "8px 46px" }} >
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="fail-radio-container"
                      >
                        <label className="label">
                          <Field
                            name="finspFailInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="finspFailInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                        <label className="label">
                          <Field
                            name="finspFailInd"
                            type="radio"
                            variant="outlined"
                            value="Unable to determine"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Unable to determine
                        </label>
                        <label className="label">
                          <Field
                            name="finspFailInd"
                            type="radio"
                            variant="outlined"
                            value="Not applicable"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Not applicable
                        </label>
                      </div>
                    </div>
                    {errors.finspFailInd && touched.finspFailInd && (
                      <div className="form-error">{errors.finspFailInd}</div>
                    )}
                  </div>
                  <div className="question">
                    <div className="number">12.</div>{" "}
                    <div>
                      Are there any unfunded repairs costing more than $10,000 per unit that should be undertaken within the next 12 months (does not include repairs made by the unit owner or repairs funded through a special assessment)                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="radio-answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="radio-container"
                      >
                        <label className="label">
                          <Field
                            name="funfundRepInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="funfundRepInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                        <label className="label">
                          <Field
                            name="funfundRepInd"
                            type="radio"
                            variant="outlined"
                            value="Unable to determine"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Unable to determine
                        </label>
                      </div>
                    </div>
                    {errors.funfundRepInd && touched.funfundRepInd && (
                      <div className="form-error">{errors.funfundRepInd}</div>
                    )}
                  </div>
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button className="save-exit-btn" onClick={saveApi}>
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>
                  <button
                    className="next-button"
                    type="submit"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

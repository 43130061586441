import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'

export const Page3 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  errorStep,
  skipStep2,
  skipStep3,
  setConvResult,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [result, setResult] = useState("");
  let calculatedConveyance = result;
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";

  function saveApi() {
    formData = page7tMultiSelectOptions(formData)
    let requestObject = certificationFormatter(formData);
    requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
        ? ""
        : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/lndrCertification/saveLenderCertificationFormsPhase`,
        requestObject
      )
      .then((response) => {
        if (response.status == 200) {
          //setDocId(response.data);
          setTimeout(() => {
            if (formData.phaseId === "proj") {
              window.location.href = "/project/" + formData.projectId;
            } else {
              window.location.href = "/project/phase/" + formData.phaseId;
            }
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            console.log(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("certification phase error", error);
      });
  }

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateConveyance(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (calculatedConveyance === "" || value === "") {
      error = "Please Calculate Conveyance";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    } else if (value === 0 && !calculatedConveyance) {
      error = "Please Calculate Conveyance";
    } else if (value && value > 0 && !calculatedConveyance) {
      error = "Please Calculate Conveyance";
    }
    return error;
  }

  function validateNumNoDec(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  function validateNumDec(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  function validateTotalUnits(value) {
    let error = validateNumDec(value);
    if (!error) {
      if (value == 0) {
        setTotalUnitError("Total # of Units can't be 0")
      } else {
        setTotalUnitError("")
      }
    } else {
      return error;
    }
  }

  function validateOccupiedUnits(value) {
    let error = validateNumDec(value);
    let totalUnits = document.getElementById("firstNumber").value;
    if (!error) {
      if (parseInt(value) > parseInt(totalUnits)) {
        setOccError("Owner Occupied cannot be greater than Total # of Units")
      } else {
        setOccError("")
      }
    } else {
      return error;
    }
  }

  function validateSecondHomes(value) {
    let error = validateNumDec(value);
    let totalUnits = document.getElementById("firstNumber").value;
    if (!error) {
      if (parseInt(value) > parseInt(totalUnits)) {
        setSecError("Second Homes cannot be greater than Total # of Units")
      } else {
        setSecError("")
      }
    } else {
      return error;
    }
  }

  function validateCompNumber(value) {
    let error = validateNumDec(value);
    let totalUnits = document.getElementById("firstNumber").value;
    if (!error) {
      if (parseInt(value) > parseInt(totalUnits)) {
        setCompError("Units Complete cannot be greater than Total # of Units");
      } else {
        setCompError("");
      }
    } else {
      return error;
    }
  }
  function validateUnitsRented(value) {
    let error = validateNumDec(value);
    const totalUnits = document.getElementById("firstNumber").value;
    const occupiedUnits = document.getElementById("occupiedUnits").value;
    const secondHomes = document.getElementById("secondHomes").value;
    if (!error) {
      if (occError == "" && secError == "" && totalUnitError == "") {
        if (parseInt(occupiedUnits) + parseInt(secondHomes) + parseInt(value) > parseInt(totalUnits) ) {
          setRentError("# of Owner-occupied Units + # of Second Homes + # of Rented Homes cannot be greater than Total # of Units");  
        } else {
          setRentError("");
        }
      } else {
        setRentError("");
      }
    } else {
      setRentError("");
      return error;
    }
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();

  function divideBy() {
    calculatedConveyance =
      (document.getElementById("secondNumber").value /
        document.getElementById("firstNumber").value) *
      100;
    setResult(calculatedConveyance);
  }

  const [occError, setOccError] = useState("");
  const [secError, setSecError] = useState("");
  const [compError, setCompError] = useState("");
  const [rentError, setRentError] = useState("");
  const [totalUnitError, setTotalUnitError] = useState("");

  const saveAndNext = (data) => {
    let values = data;
    //reset values
    if (
      !(
        result !== "" &&
        result < 90 &&
        values.ppUnitsConveyed !== "" &&
        values.ppTotalUnits !== "" &&
        values.piAddnPhsOrAnnxInd === "No" &&
        values.piCntlHOATurnOverInd === "Yes" &&
        values.piCommnAreasCompltInd === "Yes" &&
        values.ppUnitsComplete === values.ppTotalUnits
      )
    ) {
      values.ppDevOwndUntsRentalHoldBackInd = "No";
      values.ppDevOwnedUnits = "";
      values.ppMaintFeesDevOwndCurrentInd = "";
      values.ppActivePendingSpAssmntsInd = "";
    }
    if (values.ppDevOwndUntsRentalHoldBackInd === "No") {
      values.ppDevOwnedUnits = "";
      values.ppMaintFeesDevOwndCurrentInd = "";
      values.ppActivePendingSpAssmntsInd = "";
    }
    setFormData(values);
    setConvResult(result.toFixed(2));
    if (occError == "" && secError == "" && compError == "" && rentError == "" && totalUnitError == "" ) {
    if (values.phaseId === "proj") {
        if (result < 90) {
          if (
            values.piAddnPhsOrAnnxInd === "No" &&
            values.piCntlHOATurnOverInd === "Yes" &&
            values.piCommnAreasCompltInd === "Yes" &&
            values.ppUnitsComplete === values.ppTotalUnits &&
            values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
            values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2
          ) {
            values.npeLglDocsComplyInd = "";
            values.npeMasterAsscInd = "";
            values.npePayMnthyAssesLndrRevwPlanInd = "";
            setFormData(values);
            skipStep3();
          } else {
            skipStep2();
          }
        } else {
          if (
            values.piAddnPhsOrAnnxInd === "No" &&
            values.piCntlHOATurnOverInd === "Yes" &&
            values.piCommnAreasCompltInd === "Yes" &&
            values.ppUnitsComplete === values.ppTotalUnits
          ) {
            values.npeLglDocsComplyInd = "";
            values.npeMasterAsscInd = "";
            values.npePayMnthyAssesLndrRevwPlanInd = "";
            setFormData(values);
            skipStep3();
          } else {
            skipStep2();
          }
        }
      } else {
        if (result < 90) {
          if (
            values.piAddnPhsOrAnnxInd === "No" &&
            values.piCntlHOATurnOverInd === "Yes" &&
            values.piCommnAreasCompltInd === "Yes" &&
            values.ppUnitsComplete === values.ppTotalUnits &&
            values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
            values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2
          ) {
            errorStep();
          } else {
            nextStep();
          }
        } else {
          if (
            values.piAddnPhsOrAnnxInd === "No" &&
            values.piCntlHOATurnOverInd === "Yes" &&
            values.piCommnAreasCompltInd === "Yes" &&
            values.ppUnitsComplete === values.ppTotalUnits
          ) {
            errorStep();
          } else {
            nextStep();
          }
        }
    }
  }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched, validateField }) => (
        <Form>
          <div className="certification-container">
            <FormStepper step={step} type={values.phaseId} class={status} />
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ padding: "0px 0px 0px 134px" }}
                  >
                    <>
                      <div className="type">Project Type</div>
                      <div className="attributes">
                        {status === "new" ? (
                          <>New</>
                        ) : status === "est" ? (
                          <>Established</>
                        ) : (
                          <>Incomplete</>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {status === "new" ? (
                        <div className="subheader">Section 3 OF 8</div>
                      ) : (
                        <div className="subheader">Section 3 OF 7</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 3 OF 9</div>
                    </>
                  )}
                  <div className="title">
                    Presale and Investor Concentration
                  </div>
                  <div className="subtitle">
                    Provide Unit Information about the <u>Entire Project</u>
                  </div>
                </div>
                <div className="form-body">
                  <div className="form-title">Entire Project</div>
                  <div className="question">
                    <div className="number">1.</div>
                    <div>Total # of Units</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="ppTotalUnits"
                      id="firstNumber"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateTotalUnits}
                      error={touched.ppTotalUnits && errors.ppTotalUnits}
                      helperText={touched.ppTotalUnits && errors.ppTotalUnits}
                    />
                    <div className="form-error">{totalUnitError}</div>
                  </div>
                  <div className="question">
                    <div className="number">2.</div>
                    <div># of Owner-Occupied Units</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="ppOwnerOccupiedUnits"
                      id="occupiedUnits"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateOccupiedUnits}
                      error={
                        touched.ppOwnerOccupiedUnits &&
                        errors.ppOwnerOccupiedUnits
                      }
                      helperText={
                        touched.ppOwnerOccupiedUnits &&
                        errors.ppOwnerOccupiedUnits
                      }
                    />
                    <div className="form-error">{occError}</div>
                  </div>
                  <div className="question">
                    <div className="number">3.</div>
                    <div>
                      # of Second Homes(if unknown, enter zero and combine
                      primary and second homes in the # of Owner-Occupied Units)
                    </div>
                  </div>
                  <div className="answer">
                    <Field
                      name="ppSecondHomes"
                      id="secondHomes"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateSecondHomes}
                      error={touched.ppSecondHomes && errors.ppSecondHomes}
                      helperText={touched.ppSecondHomes && errors.ppSecondHomes}
                    />
                    <div className="form-error">{secError}</div>
                  </div>
                  <div className="question">
                    <div className="number">4.</div>
                    <div># of Units Rented (Investor)</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="ppUnitsRented"
                      id="rentedUnits"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateUnitsRented}
                      error={touched.ppUnitsRented && errors.ppUnitsRented}
                      helperText={touched.ppUnitsRented && errors.ppUnitsRented}
                    />
                  <div className="form-error">{rentError}</div>
                  </div>
                  <div className="question">
                    <div className="number">5.</div>
                    <div># of Units Complete</div>
                  </div>
                  <div className="answer">
                    <Field
                      name="ppUnitsComplete"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateCompNumber}
                      error={touched.ppUnitsComplete && errors.ppUnitsComplete}
                      helperText={
                        touched.ppUnitsComplete && errors.ppUnitsComplete
                      }
                    />
                    <div className="form-error">{compError}</div>
                  </div>
                  <div className="question">
                    <div className="number">6.</div>
                    <div>
                      # of Units Conveyed/Closed (if this is the first sale in
                      the project, enter 1)
                    </div>
                  </div>
                  <div className="answer" style={{ display: "flex" }}>
                    <Field
                      name="ppUnitsConveyed"
                      id="secondNumber"
                      as={TextField}
                      inputProps={{
                        className: classes.input,
                        maxLength: 9,
                      }}
                      onKeyPress={(event) => setResult("")}
                      onKeyUp={(event) => setResult("")}
                      onKeyDown={(event) => setResult("")}
                      color="#FCFCFC"
                      variant="outlined"
                      validate={validateConveyance}
                      error={touched.ppUnitsConveyed && errors.ppUnitsConveyed}
                      helperText={
                        touched.ppUnitsConveyed && errors.ppUnitsConveyed
                      }
                    />
                    <Button
                      disabled={
                        values.ppUnitsConveyed === "" ||
                        values.ppTotalUnits === ""
                      }
                      className="convey-calc-btn"
                      onClick={() => {
                        divideBy();
                        validateField("ppUnitsConveyed");
                      }}
                    >
                      <KeyboardIcon
                        style={{
                          transform: "rotate(180deg)",
                          marginRight: "8px",
                        }}
                      />
                      Calculate Conveyance
                    </Button>
                    <div className="convey-result">
                      {(result * 1).toFixed(2)}% Conveyed
                    </div>
                  </div>
                  {result !== "" &&
                  result < 90 &&
                  values.ppUnitsConveyed !== "" &&
                  values.ppTotalUnits !== "" &&
                  values.piAddnPhsOrAnnxInd === "No" &&
                  values.piCntlHOATurnOverInd === "Yes" &&
                  values.piCommnAreasCompltInd === "Yes" &&
                  values.ppUnitsComplete === values.ppTotalUnits ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Are there developer-owned units that meet the rental
                          hold-back requirements as defined in the
                          <a
                            target="_blank"
                            href="https://www.fanniemae.com/content/guide/selling/b4/2.1/01.html"
                          >
                            {" "}
                            Selling Guide
                          </a>
                          ?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="ppDevOwndUntsRentalHoldBackInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="ppDevOwndUntsRentalHoldBackInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.ppDevOwndUntsRentalHoldBackInd &&
                          touched.ppDevOwndUntsRentalHoldBackInd && (
                            <div className="form-error">
                              {errors.ppDevOwndUntsRentalHoldBackInd}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {values.ppDevOwndUntsRentalHoldBackInd === "Yes" ? (
                    <>
                      {result !== "" &&
                      result < 90 &&
                      values.ppUnitsConveyed !== "" &&
                      values.ppTotalUnits !== "" ? (
                        <>
                          <div className="question">
                            <div
                              className="number"
                              style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                              }}
                            ></div>{" "}
                            <div style={{ paddingTop: "10px" }}>
                              How many developer-owned units are held?
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div
                              className="answer"
                              style={{ paddingLeft: "86px" }}
                            >
                              <Field
                                name="ppDevOwnedUnits"
                                as={TextField}
                                inputProps={{
                                  className: classes.input,
                                  maxLength: 9,
                                }}
                                color="#FCFCFC"
                                variant="outlined"
                                validate={validateNumNoDec}
                                error={
                                  touched.ppDevOwnedUnits &&
                                  errors.ppDevOwnedUnits
                                }
                                helperText={
                                  touched.ppDevOwnedUnits &&
                                  errors.ppDevOwnedUnits
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {result !== "" &&
                      result < 90 &&
                      values.ppUnitsConveyed !== "" &&
                      values.ppTotalUnits !== "" ? (
                        <>
                          <div className="question">
                            <div
                              className="number"
                              style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                              }}
                            ></div>{" "}
                            <div style={{ paddingTop: "10px" }}>
                              Are the maintenance fees for developer-owned units
                              current?
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div
                              className="answer"
                              style={{ paddingLeft: "86px" }}
                            >
                              <div
                                role="group"
                                aria-labelledby="radio-group"
                                className="r-container"
                              >
                                <label className="label">
                                  <Field
                                    name="ppMaintFeesDevOwndCurrentInd"
                                    type="radio"
                                    variant="outlined"
                                    value="Yes"
                                    className="alignment"
                                    validate={validateRadio}
                                  />
                                  Yes
                                </label>
                                <label className="label">
                                  <Field
                                    name="ppMaintFeesDevOwndCurrentInd"
                                    type="radio"
                                    variant="outlined"
                                    value="No"
                                    className="alignment"
                                    validate={validateRadio}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                            {errors.ppMaintFeesDevOwndCurrentInd &&
                              touched.ppMaintFeesDevOwndCurrentInd && (
                                <div className="form-error">
                                  {errors.ppMaintFeesDevOwndCurrentInd}
                                </div>
                              )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {result !== "" &&
                      result < 90 &&
                      values.ppUnitsConveyed !== "" &&
                      values.ppTotalUnits !== "" ? (
                        <>
                          <div className="question">
                            <div
                              className="number"
                              style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "37px",
                                marginBottom: "10px",
                              }}
                            ></div>{" "}
                            <div style={{ paddingTop: "10px" }}>
                              Are there any active or pending special
                              assessments?
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div
                              className="answer"
                              style={{ paddingLeft: "86px" }}
                            >
                              <div
                                role="group"
                                aria-labelledby="radio-group"
                                className="r-container"
                              >
                                <label className="label">
                                  <Field
                                    name="ppActivePendingSpAssmntsInd"
                                    type="radio"
                                    variant="outlined"
                                    value="Yes"
                                    className="alignment"
                                    validate={validateRadio}
                                  />
                                  Yes
                                </label>
                                <label className="label">
                                  <Field
                                    name="ppActivePendingSpAssmntsInd"
                                    type="radio"
                                    variant="outlined"
                                    value="No"
                                    className="alignment"
                                    validate={validateRadio}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                            {errors.ppActivePendingSpAssmntsInd &&
                              touched.ppActivePendingSpAssmntsInd && (
                                <div className="form-error">
                                  {errors.ppActivePendingSpAssmntsInd}
                                </div>
                              )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button className="save-exit-btn" onClick={saveApi}>
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>
                  <button
                    className="next-button"
                    type="submit"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { isReadonlyRole, isProjectRole, isProjectStandardRole } from "../../redux/actions/userAction";
import DeleteIcon from "@material-ui/icons/Delete";
import "./Project.css";

const DeleteModal = props => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function deleteProject() {
    if (props.type === "project") {
      axios
        .delete(
          global.REACT_APP_API_ENDPOINT +
            `/condoMgr/deleteProject/${props.projectvalue}`
        )
        .then(response => {
          if (response.status == 200) {
            localStorage.removeItem("project" + props.projectvalue)
            window.location.href = "/";
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              window.location.href = "/error-page";
            } else {
              console.log(response.data.message);
            }
          }
        })
        .catch(error => {
          console.log("delete error", error);
        });
    } else if (props.type === "phase") {
      axios
        .delete(
          global.REACT_APP_API_ENDPOINT +
            `/phase/deletePhase/${props.phasevalue}`
        )
        .then(response => {
          if (response.status == 200) {
            window.location.href = `/project/${props.projectvalue}`;
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              window.location.href = "/error-page";
            } else {
              console.log(response.data.message);
            }
          }
        })
        .catch(error => {
          console.log("delete error", error);
        });
    } else {
        axios
          .delete(
            global.REACT_APP_API_ENDPOINT +
              `/phase/deletePhase/${props.childphasevalue}`
          )
          .then(response => {
            if (response.status == 200) {
              window.location.href = `/project/phase/${props.phasevalue}`;
            } else if (response.data && response.data.message) {
              if (response.data.message == "Internal server error") {
                window.location.href = "/error-page";
              } else {
                console.log(response.data.message);
              }
            }
          })
          .catch(error => {
            console.log("delete error", error);
          });
    }
  }

  return (
    <>
      {isProjectStandardRole() ? (
        <button className="delete-project-button" onClick={handleShow}>
          <DeleteIcon style={{ marginRight: "6px", paddingBottom: "2px" }} />
          Delete {props.type === "project" ? <>Project</> : <>Phase</>}
        </button>
      ) : null}

      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "1070"
        }}
      >
        <Modal.Body className="delete-modal-body">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DeleteIcon
              style={{ width: "50px", height: "50px", textAlign: "center" }}
            />
          </div>
          <div className="delete-modal-title">
            Delete {props.type === "project" ? <>Project</> : <>Phase</>}
          </div>
          <div className="delete-modal-info">
            You’ll lose all information in this{" "}
            {props.type === "project" ? <>project</> : <>phase</>}. We can’t
            recover them once you delete. Are you sure you want to{" "}
            <span style={{ color: "red" }}>permanently delete</span> this{" "}
            {props.type === "project" ? <>project</> : <>phase</>}?
          </div>
        </Modal.Body>
        <Modal.Footer className="delete-modal-footer">
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="delete"
            onClick={() => {
              deleteProject();
            }}
          >
            Yes, delete it
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;

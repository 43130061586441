import React, { Component, useContext  } from 'react';
import axios from 'axios';
import './Profile.css';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { FaChevronLeft, FaChevronUp, FaChevronDown } from 'react-icons/fa';


function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
      
    

    return (
      <div className="accordion-header-container" onClick={decoratedOnClick}>
          {isCurrentEventKey ? <FaChevronUp className="accordion-header-icon"/> : <FaChevronDown className="accordion-header-icon"/> }
          {children}
      </div>
    );
  }
  export default ContextAwareToggle 
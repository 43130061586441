import React, { useState } from "react";
import "./AddNewPersDecision.css";
import "./select.css";
import { PersStatus } from "./pages/PersStatus";
import { EnterCondition } from "./pages/EnterCondition";
import { EnterRestriction } from "./pages/EnterRestriction";
import { Review } from "./pages/Review";
import { Success } from "./pages/Success";
import { EnterIneligible } from "./pages/EnterIneligible";
import { EnterSuspension } from "./pages/EnterSuspension";
import { LendingDetails } from "./pages/LendingDetails";

export const AddNewPersDecision = (props) => {
  const [selectedPhaseSaved, setSelectedSaved] = useState([]);
  const [selectedPhaseList, setSelectedPhaseList] = React.useState([]);
  window.onscroll = function () {
    effects();
  };
  function effects() {}
  // React.useState([]);

  // useState([
  //   { value: "600176001", name: "Phase Name1" },
  //   { value: "600176003", name: "Phase Name3" },
  //   { value: "600176005", name: "Phase Name5" }
  // ]);

  const [step, setStep] = useState(0);
  const [persData, setPersData] = useState({});
  // const [formData, setFormData] = useState();

  const [formData, setFormData] = useState({
    projectId: props.match.params.project_id,
    persId: props.match.params.pers_id,
    decisionLevel: "Entire project",
    persDecisionStatus: "",
    persExpiration: "NA",
    phaseIds: [],
    perCondtnsRef: [],
    addedConditions: [],
    availablePhases: [],
    restrictions: [],
    selectedPhases: [],
    applySamePhaseDeliveryRestriction: false,
    applySamePhaseConditions: false,
    // ineligibilityExplaination: "",
  });

  const nextStep = () => setStep((prev) => prev + 1);
  const nextStep2 = () => setStep((prev) => prev + 2);
  const nextStep3 = () => setStep((prev) => prev + 3);
  const nextStep4 = () => setStep((prev) => prev + 4);
  const prevStep = () => setStep((prev) => prev - 1);
  const prevStep2 = () => setStep((prev) => prev - 2);
  const prevStep3 = () => setStep((prev) => prev - 3);
  const prevStep4 = () => setStep((prev) => prev - 4);
  const prevStep5 = () => setStep((prev) => prev - 5);

  switch (step) {
    // switch (4) {
    case 0:
      return (
        <LendingDetails
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          step={step}
          projectId={props.match.params.project_id}
          persId={props.match.params.pers_id}
          persType={props.match.params.pers_type}
          setSelectedPhaseList={setSelectedPhaseList}
          setSelectedSaved={setSelectedSaved}
          selectedPhaseSaved={selectedPhaseSaved}
        />
      );
    case 1:
      return (
        <PersStatus
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          nextStep2={nextStep2}
          nextStep3={nextStep3}
          nextStep4={nextStep4}
          prevStep={prevStep}
          prevStep2={prevStep2}
          prevStep3={prevStep3}
          step={step}
          persId={props.match.params.pers_id}
          projectId={props.match.params.project_id}
          selectedPhaseList={selectedPhaseList}
        />
      );
    case 2:
      return (
        <EnterCondition
          nextStep={nextStep}
          nextStep2={nextStep2}
          nextStep3={nextStep3}
          nextStep4={nextStep4}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
          persId={props.match.params.pers_id}
          projectId={props.match.params.project_id}
          step={step}
        />
      );
    case 3:
      return (
        <EnterRestriction
          nextStep={nextStep}
          nextStep2={nextStep2}
          nextStep3={nextStep3}
          prevStep={prevStep}
          prevStep2={prevStep2}
          prevStep3={prevStep3}
          formData={formData}
          setFormData={setFormData}
          persId={props.match.params.pers_id}
          projectId={props.match.params.project_id}
          step={step}
        />
      );
    case 4:
      return (
        <EnterSuspension
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          nextStep2={nextStep2}
          nextStep3={nextStep3}
          prevStep2={prevStep2}
          prevStep3={prevStep3}
          prevStep4={prevStep4}
          prevStep={prevStep}
          step={step}
          persId={props.match.params.pers_id}
        />
      );
    case 5:
      return (
        <EnterIneligible
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep2={prevStep2}
          prevStep3={prevStep3}
          prevStep4={prevStep4}
          prevStep5={prevStep5}
          prevStep={prevStep}
          step={step}
          persId={props.match.params.pers_id}
        />
      );
    case 6:
      return (
        <Review
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep2={prevStep2}
          prevStep3={prevStep3}
          prevStep4={prevStep4}
          prevStep={prevStep}
          step={step}
          persId={props.match.params.pers_id}
        />
      );
    case 7:
      return (
        <Success
          formData={formData}
          step={step}
          persId={props.match.params.pers_id}
          persType={props.match.params.pers_type}
        />
      );
    default:
      return (
        <LendingDetails
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          persId={props.match.params.pers_id}
          projectId={props.match.params.project_id}
          selectedPhaseList={selectedPhaseList}
        />
      );
  }
};

import { STRUCTURES1026_RESULTS } from "../actions/ActionType";

export const initialState = {
  types: []
};
export const structures1026Reducer = (type = initialState, action) => {
  switch (action.type) {
    case STRUCTURES1026_RESULTS:
      return {
        structures1026: action.payload
      };
    default:
      return type;
  }
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card, Button } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field, DatePicker } from "../Pers-1071";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import { isReadonlyRole } from "../../redux/actions/userAction";

export default function Pers1071({
  collapseKey,
  sectionData,
  persId,
  projectId
}) {
  const [isEdit, setEdit] = useState(false);
  const [formState, setFormState] = useState({});
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const states = useSelector(
    dialogueReducer => dialogueReducer.dialogueReducer.states
  );

  const yesno = [
    {
      key: true,
      value: "Yes"
    },
    {
      key: false,
      value: "No"
    }
  ];

  useEffect(() => {
    setFormState({ ...sectionData });
  }, [sectionData]);

  const handleSaveEdit = async () => {
    setApiErr("");
    setInternalErr(false);
    try {
      const body = {
        persId,
        projectId,
        phaseId: null,
        pers1071: { ...formState }
      };
      console.log("formState", formState);
      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT + `/pers/updatePersProjectAndForms/1071`,
        body
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setFormState({ ...sectionData });
    }
  };

  const handleChange = event => {
    let _formData = { ...formState };
    _formData[event.target.name] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleDatePickerChange = event => {
    let _formData = { ...formState };
    let value = event.target.value;
    if (value === null || value === '') {
      value = null;
    } else if (new Date(event.target.value) < new Date("1776-01-01")){
      value = null;
    }
    _formData[event.target.name] = value;
    setFormState({ ..._formData });
  };

  const handleMbFireAndCoverageInsuranceInForceIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["mbFireAndCoverageInsuranceInForceIndicator"] =
      event.target.value;
    setFormState({ ..._formData });
  };

  const handleInsuranceEqualsReplacementCostIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["insuranceEqualsReplacementCostIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleSpecialCondominiumEndorsementIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["specialCondominiumEndorsementIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleStandardMortgageClauseIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["standardMortgageClauseIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleAgreedAmountEndorsementIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["agreedAmountEndorsementIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleInflationGuardEndorsementIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["inflationGuardEndorsementIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleReplacementCostEndorsementIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["replacementCostEndorsementIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleOtherSpecialEndorsementIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["otherSpecialEndorsementIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleConstructionCodeChangeIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["constructionCodeChangeIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleCccExposureProtectedByEndorsementsIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["cccExposureProtectedByEndorsementsIndicator"] =
      event.target.value;
    setFormState({ ..._formData });
  };

  const handleCondoInsuranceTrusteeIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["condoInsuranceTrusteeIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleFidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAIndChange = event => {
    let _formData = { ...formState };
    _formData["fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd"] =
      event.target.value;
    setFormState({ ..._formData });
  };

  const handleFidelityCvrgNoFinControlEqlsThreeMonthAssmtsIndChange = event => {
    let _formData = { ...formState };
    _formData["fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd"] =
      event.target.value;
    setFormState({ ..._formData });
  };

  const handleBoilerInsPolicyIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["boilerInsPolicyIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleLiabiltyInsPolicyIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["liabiltyInsPolicyIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleFloodInsPolicyIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["floodInsPolicyIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  const handleHoaMaintainInsuranceIndicatorChange = event => {
    let _formData = { ...formState };
    _formData["hoaMaintainInsuranceIndicator"] = event.target.value;
    setFormState({ ..._formData });
  };

  return (
    <Accordion defaultActiveKey="1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">
              Statement of Insurance and Fidelity Coverage (1071)
            </p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? (
            isEdit ? (
              <div>
                <button
                  className="cancel-button"
                  onClick={() => {
                    toggleEdit(false);
                    setApiErr("");
                  }}
                >
                  Cancel
                </button>
                <button className="add-phase-button" onClick={handleSaveEdit}>
                  <FaSave className="edit-profile-icon" /> Save Edits
                </button>
              </div>
            ) : (
              <button
                className="add-phase-button"
                onClick={() => {
                  toggleEdit(true);
                  setInternalErr(false);
                  setApiErr("");
                }}
              >
                <FaPen className="edit-profile-icon" /> Edit Section
              </button>
            )
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Overview</div>
                  <div className="accordion-body-card-left">
                    <DatePicker
                      label="Expiration date of master or blanket insurance policy"
                      name="masterOrBlanketInsuranceExpirationDate"
                      formData={formState}
                      handleChange={(e) => {
                        if (new Date(e.target.value) < new Date("1776-01-01")) {
                          formState.masterOrBlanketInsuranceExpirationDate = null;
                        }
                        handleDatePickerChange(e);
                      }}
                      isEdit={isEdit}
                      min="1776-01-01"
                    />
                    <Field
                      label="Are the Master or Blanket Type Fire and Extended
                                        Coverage Insurance in Force?"
                      name="mbFireAndCoverageInsuranceInForceIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleMbFireAndCoverageInsuranceInForceIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Is the amount of insurance equal to 100% of the current
                                        replacement cost of the insurable improvements including
                                        unit interior improvements and betterments (if a
                                        condominium project) or 100% of the current replacement
                                        cost of the insurable improvements to the common areas
                                        (if a PUD)?"
                      name="insuranceEqualsReplacementCostIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleInsuranceEqualsReplacementCostIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="The amount of such insurance in force is"
                      name="fireAndExtCoverageInsuranceAmount"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Policy Includes
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Special Condominium Endorsement"
                      name="specialCondominiumEndorsementIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleSpecialCondominiumEndorsementIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Standard Mortgage Clause or Equivalent"
                      name="standardMortgageClauseIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleStandardMortgageClauseIndicatorChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Agreed Amount Endorsement"
                      name="agreedAmountEndorsementIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleAgreedAmountEndorsementIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Inflation Guard Endorsement"
                      name="inflationGuardEndorsementIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleInflationGuardEndorsementIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Replacement Cost Endorsement"
                      name="replacementCostEndorsementIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleReplacementCostEndorsementIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Other Special Endorsement"
                      name="otherSpecialEndorsementIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleOtherSpecialEndorsementIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="Is the project subject to a substantial construction
                                    code change that would be operative in the event of
                                    partial destruction of the project by an insured hazard?"
                      name="constructionCodeChangeIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleConstructionCodeChangeIndicatorChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Is this exposure protected by the appropriate
                                    endorsements?"
                      name="cccExposureProtectedByEndorsementsIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleCccExposureProtectedByEndorsementsIndicatorChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="If a condominium, is there an insurance trustee?"
                      name="condoInsuranceTrusteeIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleCondoInsuranceTrusteeIndicatorChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Is Fidelity Coverage in force in an amount at least
                                        equal to the estimated maximum amount of funds that will
                                        be in the custody of the Homeowners’ Association or its
                                        management agent at any time during the term of the
                                        coverage?"
                      name="fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleFidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAIndChange
                      }
                      isEdit={isEdit}
                    />
                    <Field
                      label="If No, are the financial controls that justify a lesser
                                amount among those specified in the Fannie Mae Selling
                                Guide, and is the coverage at least equal to the sum of
                                three months’ assessments on all units in the project?"
                      name="fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={
                        handleFidelityCvrgNoFinControlEqlsThreeMonthAssmtsIndChange
                      }
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="If the project contains a steam boiler, is a boiler
                                insurance policy in effect? If Yes, amount of coverage"
                      name="boilerInsPolicyIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleBoilerInsPolicyIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.boilerInsPolicyIndicator === true ||
                    formState.boilerInsPolicyIndicator === "true" ? (
                      <Field
                        label="Amount"
                        name="boilerInsuranceCoverageAmount"
                        formData={formState}
                        number={true}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Is a public liability insurance policy in force? If Yes,
                                amount of coverage"
                      name="liabiltyInsPolicyIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleLiabiltyInsPolicyIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.liabiltyInsPolicyIndicator === true ||
                    formState.liabiltyInsPolicyIndicator === "true" ? (
                      <Field
                        label="Amount"
                        name="liabilityInsuranceCoverageAmount"
                        formData={formState}
                        number={true}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Is flood insurance in force? If Yes, amount of coverage"
                      name="floodInsPolicyIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleFloodInsPolicyIndicatorChange}
                      isEdit={isEdit}
                    />
                    {formState.floodInsPolicyIndicator === true ||
                    formState.floodInsPolicyIndicator === "true" ? (
                      <Field
                        label="Amount"
                        name="floodInsuranceCoverageAmount"
                        formData={formState}
                        number={true}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      label="Association Coverage"
                      name="associationCoverageAmount"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Management Coverage"
                      name="managementCoverageAmount"
                      formData={formState}
                      number={true}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Do the constituent documents of the project require the
                                Homeowners’ Association to maintain the insurance and
                                fidelity coverage required by Fannie Mae?"
                      name="hoaMaintainInsuranceIndicator"
                      formData={formState}
                      dropdown={true}
                      states={yesno}
                      handleChange={handleHoaMaintainInsuranceIndicatorChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Exceptions"
                      name="exceptions1071"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

import React, { useState, useEffect } from "react";
import { FaTrash, FaPlus } from "react-icons/fa";

import { Field } from "../Pers-atoms";

const passData = JSON.parse(sessionStorage.getItem("passData"));

export default function Table2({
  lender = {},
  isEdit,
  persId,
  projectId,
  update
}) {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const { commUse1076DataList = [] } = lender;
    const initialData = [...JSON.parse(JSON.stringify(commUse1076DataList))];
    setFormData(initialData);
  }, [lender, isEdit]);

  const handleChange = ({ target: { name, checked, type, value } }, index) => {
    const row = { ...formData[index] };
    row[name] = type === "checkbox" ? checked : value;
    const _formData = formData.map((r, i) => (i === index ? { ...row } : r));
    setFormData(_formData);
    update(_formData, "commUse1076DataList");
  };

  const addClickHandler = () => {
    const newRow = {
      projectId,
      persId,
      indvName: "",
      devlSpnsrInd: false,
      nbrOfUnitsOwndCnt: null,
      unitsOwndPct: null,
      nbrUnitsLeasedMrktRentCnt: null,
      nbrUnitsLeasedRentCntrlCnt: null,
      createdBy: passData.user,
      dateCreated: null
    };
    const _formData = [...formData, { ...newRow }];
    setFormData(_formData);
    update(_formData, "commUse1076DataList");
  };

  const removeHandler = index => {
    const _formData = formData.filter((_, i) => i !== index);
    setFormData(_formData);
    update(_formData, "commUse1076DataList");
  };
  return (
    <>
      {isEdit && (
        <div className="table-add-button">
          <button className="add-phase-button" onClick={addClickHandler}>
            <FaPlus className="edit-profile-icon" /> Add
          </button>
        </div>
      )}
      <div className="table-header">If Yes, complete the following table</div>
      <div className="list-container">
        <div className="list-header">
          <div className="list-header-name">
            Type of Commercial or Non-Residential Use
          </div>
          <div className="list-header-name">Name of Owner or Tenant</div>
          <div className="list-header-name">Number of Units</div>
          <div className="list-header-name">Square Footage</div>
          <div className="list-header-name">
            % Square Footage of Total Project Square Footage
          </div>
          {/* {isEdit && <div className="list-header-name">Actions</div>} */}
        </div>
        <div className="list-body">
          {formData.map((_, index) => (
            <div key={index}>
              <div className="card-row-list">
                <div className="card-name-list">
                  <Field
                    label=""
                    name="commrclUseType"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="ownrTenantName"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="nbrOfUnitsCnt"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="sqFootage"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="sqFtPct"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                {/* {isEdit && (
                  <div className="card-name-list">
                    <button
                      className="add-phase-button"
                      name={index}
                      onClick={() => removeHandler(index)}
                    >
                      <FaTrash className="edit-profile-icon" />
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

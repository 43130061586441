import React from "react";
import { Button } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

const ExactMatchModal = ({
  handleDuplicateModalCancel,
  show,
  duplicateProjectData,
  title,
}) => {
  let path = window.location.hostname;

  return (
    <Modal
      show={show}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleDuplicateModalCancel}
      backdrop="static"
      size="lg"
      centered
    >
      <Modal.Header className="the-modal-header" closeButton>
        <FaExclamationTriangle
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "16px",
            width: "16px",
            marginTop:"3px",
            marginLeft:"6px"
          }}
        />
        <Modal.Title className="the-modal-title" style={{alignItems:"center"}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
          <>
            <div style={{ marginTop: "15px" }}>
            This entry matches the following project in the system and cannot be saved. 
            </div>
            <div style={{ marginTop:"14px", overflow:"auto"}}>
                {
                    duplicateProjectData.map(projectData => {
                        const {projectId, state, totalUnits, zipcode, city, projectName} = projectData;
                        return <div key={projectId}>
                            <Link to={`/project/${projectId}`} style={{color:"#1176B5"}} target="_blank">{projectName}</Link>, {totalUnits} Total Units, {city}, {state}, {zipcode}
                        </div>
                    }) 
                }
            </div>
            <div style={{ marginTop: "15px" }}>
            Please reference the existing project or provide a different Name/Zip Code to prevent a duplicate record from being created.
            </div>
        </>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer" style={{justifyContent:"space-between", paddingRight:24}}>
          <div></div>
      <Button className='neutral-modal-button' onClick={() => { handleDuplicateModalCancel() }}> Done </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExactMatchModal;

import React, { useState, useRef } from "react";
import axios from 'axios';
import { Grid, Button, RadioGroup, Radio, FormControlLabel, makeStyles } from "@material-ui/core";
import { addProject } from "../../../redux/actions/addProjectActions";
import { useSelector, useDispatch } from "react-redux";
import "../AddNewProject.css";
import {
  isProjectRole
} from "../../../redux/actions/userAction";
import WarningModal from "../warning-modal";
import ExactMatchModal from "../exact-match-modal";

import { getGoogleAddressFields } from "../../shared/services/SharedService";

const useStyles = makeStyles(style => ({
  fontSize: {
      "& span:last-child": {
          fontWeight: 300
      }
  }
}));

let projectInfoCopy;



const CondoProjectPage = ({
  incStepCounter,
  incrementCounter2,
  decStepCounter,
  userRole
}) => {
  const condoProjectInfo = useSelector(
    store => store.addProjectReducer.condoProjectInfo
  );
  const classes = useStyles()



  const [projectInfo, setprojectInfo] = React.useState(condoProjectInfo);
  projectInfoCopy = projectInfo;
  const [errorPhaseName, seterrorPhaseName] = React.useState("");
  const [showModal1, setshowModal1] = React.useState(false);
  const [showModal2, setshowModal2] = React.useState(false);
  const [errorstreetAddress, seterrorstreetAddress] = React.useState("");
  const [errorUserName, seterrorUser] = React.useState("");
  const [errorunits, seterrorunits] = React.useState("");
  const [errorCity, seterrorCity] = React.useState("");
  const [errorState, seterrorState] = React.useState("");
  const [errorCoop, seterrorCoop] = React.useState("");
  const [errorManf, seterrorManf] = React.useState("");
  const [errorStatus, seterrorStatus] = React.useState("");
  const [errorZip5, seterrorZip5] = React.useState("");
  const [errorStyleCemment, seterrorStyleComment] = React.useState("");
  const [errorHoaTax, seterrorHoaTax] = React.useState("");
  const [errorType, seterrorType] = React.useState("");
  const [duplicateData, setduplicateData] = React.useState([]);

  const [apiErr, setApiErr] = React.useState("");

  const checkDuplicateProject = async () => {
    const { phaseName, units, zip5 } = projectInfoCopy
    const duplicateCheckData = {
  
      condoProjectName: phaseName,
      condoUnitCount: units,
      condoZip: zip5
    }
    if(phaseName && units && zip5 && !errorZip5 && !errorunits && !errorPhaseName){
      try {
        const result = await axios.post(
          global.REACT_APP_API_ENDPOINT +
          `/condoMgr/searchIfDuplicateProjectExists`,
          duplicateCheckData)
          if(result.data && result.data.totalResults){
            setduplicateData(result.data.cpDetails)
            setshowModal1(true)
          }
      } catch (err) {
        throw Error(err);
      }
    }
   
  }
  const dispatch = useDispatch();
  React.useEffect(() => {
    initializeAutoComplete();
  }, [projectInfo]);

  // Create element references
  let phaseName = React.createRef();
  let zip5 = React.createRef();
  let streetAddress = React.createRef();
  let units = React.createRef();
  let city = React.createRef();
  let style = React.createRef();
  let type = React.createRef();
  let State = React.createRef();
  let Coop = React.createRef();
  let Manf = React.createRef();
  let Status = React.createRef();
  let userName = React.createRef();
  let HOAtaxid = React.createRef();
  let  ref = useRef(null);

  const [isTypeSelected, setIsTypeSelected] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [commentLength, setCommentLength] = React.useState(0);
  const [styleLength, setStyleLength] = React.useState(0);

  const state = useSelector(store => store.dialogueReducer.states);
  const styles = useSelector(store => store.styleReducer.styles);
  const types = useSelector(store => store.typeReducer.types);

  // const dispatch = useDispatch();

  function handleChange(event) {
    setprojectInfo({ ...projectInfo, [event.target.name]: event.target.value });
  }

  function handleWarningModalCancel(){
    setshowModal1(false)
  }
  function handleDuplicateModalCancel(){
    setshowModal2(false)
  }
  

  async function calladdProject(e) {
    e.preventDefault();
    setApiErr("");

      setIsSubmitted(true)

    // if (type.current.value.length == 0) {
    //   seterrorType("This field is Required");
    // } else {
    //   seterrorType("");
    // }

    if (phaseName.current.value.length == 0) {
      seterrorPhaseName("This field is Required");
    } else {
      seterrorPhaseName("");
    }
    if (units.current.value.length == 0) {
      seterrorunits("This field is required");
    } else if (units.current.value <= 0) {
      seterrorunits("Number of units cannot be 0 or negative");
    } else {
      seterrorunits("");
    }
    if (ref.current.value.length == 0) {
      seterrorstreetAddress("This field is required");
    } else {
      seterrorstreetAddress("");
    }
    const regex = new RegExp(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/);
    if (city.current.value.length == 0) {
      seterrorCity("This field is required");
    } else if (regex.test(city.current.value) === false) {
      seterrorCity("Invalid City");
    } else {
      seterrorCity("");
    }
    if (State.current.value.length == 0) {
      seterrorState("This field is required");
    } else {
      seterrorState("");
    }
    if (Coop.current.value.length == 0) {
      seterrorCoop("This field is required");
    } else {
      seterrorCoop("");
    }
    if (Manf.current.value.length == 0) {
      seterrorManf("This field is required");
    } else {
      seterrorManf("");
    }
    if (Status.current.value.length == 0) {
      seterrorStatus("This field is required");
    } else {
      seterrorStatus("");
    }
    if (zip5.current.value.length == 0) {
      seterrorZip5("This field is required");
    } else {
      seterrorZip5("");
    }
    const regexZip = new RegExp(/^[0-9]{5}$/);
    if (zip5.current.value.length == 0) {
      seterrorZip5("This field is required");
    } else if (regexZip.test(zip5.current.value) === false) {
      seterrorZip5("Invalid Zip Code");
    } else {
      seterrorZip5("");
    }


    if (projectInfo.style == "Other" && projectInfo.styleComments == "") {
      seterrorStyleComment("This field is required");
    } else {
      seterrorStyleComment("");
    }

    if (HOAtaxid.current.value.length > 0) {
      if (HOAtaxid.current.value.length > 16) {
        seterrorHoaTax("Please enter less then 16 digits");
      } else if (!/[0-9]{2}-[0-9]{7}$/i.test(HOAtaxid.current.value)) {
        seterrorHoaTax("Invalid Tax ID. Format: XX-XXXXXXX");
      } else {
        seterrorHoaTax("");
      }
    }

    if (
      // type.current.value.length !== 0 &&
      projectInfo.type.length !== 0 &&
      phaseName.current.value.length !== 0 &&
      units.current.value.length !== 0 &&
      ref.current.value.length !== 0 &&
      city.current.value.length !== 0 &&
      regex.test(city.current.value) === true &&
      State.current.value.length !== 0 &&
      Coop.current.value.length !== 0 &&
      Manf.current.value.length !== 0 &&
      Status.current.value.length !== 0 &&
      zip5.current.value.length !== 0 &&
      regexZip.test(zip5.current.value) === true &&
      units.current.value > 0
    ) {
      if (isProjectRole()) {
        if (
          //userName.current.value.length !== 0 &&
          //regexName.test(userName.current.value) === true &&
          (HOAtaxid.current.value.length == 0 ||
            /[0-9]{2}-[0-9]{7}$/i.test(HOAtaxid.current.value))
        ) {
          if (projectInfo.style == "Other" && projectInfo.styleComments == "") {
            seterrorStyleComment("This field is required");
          } else {
            seterrorStyleComment("");
            const response = await dispatch(
              // addProject(projectInfo, userRole, incStepCounter) to move to attachments

              addProject(projectInfo, userRole, incrementCounter2)
            );

            if(response && response.cpDetails){
              setshowModal2(true);
              setduplicateData(response.cpDetails)
            }
            // incStepCounter();
            if (response && response.message) {
              if (response.message == 'Internal server error') {
                window.location.href = "/error-page";
              } else {
                setApiErr(response.message);
              }
              
            }
          }
        }
      } else {
        if (
          HOAtaxid.current.value.length == 0 ||
          /[0-9]{2}-[0-9]{7}$/i.test(HOAtaxid.current.value)
        ) {
          if (projectInfo.style == "Other" && projectInfo.styleComments == "") {
            seterrorStyleComment("This field is required");
          } else {
            seterrorStyleComment("");

            const response = await dispatch(
              addProject(projectInfo, userRole, incrementCounter2)
            );

            // incrementCounter2();
            if (response && response.message) {
              if (response.message == 'Internal server error') {
                window.location.href = "/error-page";
              } else {
                setApiErr(response.message);
              }
            }
          }
        }
      }
    }
  }

  const getSelectedRole = () => {
    const role = window.sessionStorage.getItem("role")
    return role.toLowerCase().includes("lender") && projectInfo.type === "New Condo"
  }

  const updateAddressField = (place) => {
    let address = getGoogleAddressFields(place)
    setprojectInfo({
      ...projectInfo,
      streetAddress: address.street,
      city: address.city,
      state: address.state,
      zip5: address.zip
    });
  //Update the validations
    //Street
    if (address.street.length > 0) {
      seterrorstreetAddress("");
    }
    // City
    if (address.city.length > 0) {
      seterrorCity('');
    }
    // State
    if (address.state.length > 0) {
      seterrorCity('');
    }
    // Zip
    if (address.zip.length > 0) {
      seterrorZip5('');
    }
  }

  function initializeAutoComplete() {
    if (ref?.current) {
      const input = ref.current;
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: 'US' },
        fields: ["address_components"],
        types: ['address']
      });
  
      autocomplete?.addListener('place_changed', () => {
        const place = autocomplete?.getPlace();
        if (place) {
          updateAddressField(place)
        }
      });
    }
  }

  return (
    <>
    <WarningModal
      show={showModal1}
      handleWarningModalCancel={handleWarningModalCancel}
      title={ 'Potential Duplication'}
      duplicateProjectData={duplicateData}
    />
    <ExactMatchModal
      show={showModal2}
      handleDuplicateModalCancel={handleDuplicateModalCancel}
      title={ 'Duplication Discovered'}
      duplicateProjectData={duplicateData}
    />
    <Grid item xs={12}>
      <form id="addProjectForm">
        <Grid item xs={12}>
          <h1 style={{ justifyContent: "center" }}>Add New Project</h1>
        </Grid>
        <Grid xs={12} styles={{ marginTop: 20 }}>
          {isProjectRole() ? (
            <h6 style={{ justifyContent: "center" }}>
              Step 1 of 3 - New Project Details
            </h6>
          ) : (
            <h6 style={{ justifyContent: "center" }}>
              Step 1 of 2 - New Project Details
            </h6>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Select New Project Type<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>

              <RadioGroup ref={type} row aria-label="position" name="type" defaultValue=""
                onChange={event => {
                  handleChange(event);
                }} >
                {types &&
                  types.length > 0 &&
                  types.filter((e, idx) => idx !== 0).map(item => {
                    return (
                      <FormControlLabel
                        className={classes.fontSize}
                        key={item.key}
                        style={{ fontWeight: "normal" }}
                        value={item.key}

                        control={<Radio color="primary" />}
                        label={item.value}
                        labelPlacement="end"
                      />
                    );
                  })}
               
              </RadioGroup>

             
            </Grid>
            <Grid  item xs={12} sm={12} md={7} style={{margin: 0 ,paddingTop: "0px"}}>
              {
               !projectInfo.type.length && isSubmitted ?
               <p style={{color: "#f00"}}>Please select project type</p> :
               null
             }
             </Grid>
            <Grid  item xs={12} sm={12} md={7} className="please-contact-fanni-border"
             style={{display: getSelectedRole()  ? "block":"none"}}>
            
            <p className="please-contact-fanni">
              Please contact Fannie Mae project standards to add Phases to this record. 
              In order to certify a specific phase, 
              it must be setup in the system under the parent project.

              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Project Legal Name<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                autoFocus
                required
                type="text"
                className="form-control"
                name="phaseName"
                style={{ marginLeft: "0" }}
                value={projectInfo.phaseName}
                ref={phaseName}
                onChange={event => {
                  handleChange(event);

                  if (phaseName.current.value.length == 0) {
                    seterrorPhaseName("This field is required");
                  } else {
                    seterrorPhaseName("");
                  }
                }}
                onBlur={event => {
                  checkDuplicateProject()
                }}
              />
              {errorPhaseName != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorPhaseName}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Legacy CPM ID</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                type="text"
                className="form-control"
                name="cpmId"
                value={projectInfo.cpmId}
                maxLength="16"
                onChange={event => {
                  handleChange(event);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Alternative Name</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                type="text"
                className="form-control"
                name="alternativeName"
                value={projectInfo.alternativeName}
                onChange={event => {
                  handleChange(event);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Number of Known Units in Project
                <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                required
                type="number"
                className="form-control"
                name="units"
                min="0"
                value={projectInfo.units}
                ref={units}
                onChange={event => {
                  handleChange(event);
                  if (units.current.value.length == 0) {
                    seterrorunits("This field is required");
                  } else if (units.current.value <= 0) {
                    seterrorunits("Number of units cannot be 0 or negative");
                  } else {
                    seterrorunits("");
                  }
                }}
                onBlur={event => {
                  checkDuplicateProject()
                }}
              />
              {errorunits != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorunits}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Address<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                ref={ref}
                required
                type="text"
                className="form-control"
                name="streetAddress"
                placeholder=""
                value={projectInfo.streetAddress ?? ''}
                onChange={event => {
                  handleChange(event);
                  if (ref.current.value.length == 0) {
                    seterrorstreetAddress("This field is required");
                  } else {
                    seterrorstreetAddress("");
                  }
                }}
              />
              {errorstreetAddress != "" ? (
                <p style={{ color: "red", margin: "0px" }}>
                  {errorstreetAddress}
                </p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                City<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                required
                type="text"
                className="form-control"
                name="city"
                value={projectInfo.city}
                ref={city}
                onChange={event => {
                  handleChange(event);
                  const regex = new RegExp(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/);
                  if (city.current.value.length == 0) {
                    seterrorCity("This field is required");
                  } else if (regex.test(city.current.value) === false) {
                    seterrorCity("Invalid City");
                  } else {
                    seterrorCity("");
                  }
                }}
              />
              {errorCity != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorCity}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                State<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <select
                required
                name="state"
                id={"id"}
                className="form-control"
                ref={State}
                value={projectInfo.state}
                onChange={event => {
                  handleChange(event);
                  if (State.current.value.length == 0) {
                    seterrorState("Please Select a State");
                  } else {
                    seterrorState("");
                  }
                }}
              >
                {state &&
                  state.length > 0 &&
                  state.map(item => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
              </select>
              {errorState != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorState}</p>
              ) : null}
            </Grid>
          </Grid>
         
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Zip<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <input
                required
                type="text"
                className="form-control"
                name="zip5"
                value={projectInfo.zip5}
                ref={zip5}
                maxLength="5"
                onChange={event => {
                  handleChange(event);
                  const regexZip = new RegExp(/^[0-9]{5}$/);
                  if (zip5.current.value.length == 0) {
                    seterrorZip5("This field is required");
                  } else if (regexZip.test(zip5.current.value) === false) {
                    seterrorZip5("Invalid Zip Code");
                  } else {
                    seterrorZip5("");
                  }
                }}
                onBlur={event => {
                  checkDuplicateProject()
                }}
              />
              {errorZip5 != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorZip5}</p>
              ) : null}
            </Grid>
            {isProjectRole() ? (
              <Grid item xs={6} sm={6} md={5}>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="zip"
                  value={projectInfo.zip}
                  size="4"
                  maxLength="4"
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          
          </Grid>
       

          </Grid>
         <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Style
                {/* <span style={{ color: "red" }}>*</span> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <select
                required
                name="style"
                id={"id"}
                className="form-control"
                ref={style}
                value={projectInfo.style}
                onChange={event => {
                  handleChange(event);
                }}
              >
                {styles &&
                  styles.length > 0 &&
                  styles.map(item => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
              </select>

              {projectInfo.style == "Other" ? (
                <div>
                  <textarea
                    rows="3"
                    type="text"
                    className="form-control mt-3"
                    name="styleComments"
                    required
                    maxLength="200"
                    placeholder="Enter your Style*"
                    onChange={event => {
                      setprojectInfo({
                        ...projectInfo,
                        styleComments: event.target.value
                      });
                      setStyleLength(event.target.value.length);
                    }}
                  ></textarea>
                  <span
                    style={{
                      display: "inline-block",
                      marginRight: "5px",
                      float: "right",
                      fontSize: "14px",
                      color: "#787979"
                    }}
                  >{`${styleLength}/200`}</span>
                  {errorStyleCemment != "" ? (
                    <p style={{ color: "red", margin: "0px" }}>
                      {errorStyleCemment}
                    </p>
                  ) : null}
                </div>
              ) : null}
            </Grid>
          </Grid>

         
          <Grid container spacing={2} className="dialoguClass" 
           style={{display: getSelectedRole() ? "none":"block"}}>
            <Grid item xs={12} sm={12} md={12}>
              <div>HOA Tax ID</div>
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <input
                className="form-control"
                name="HOAtaxid"
                ref={HOAtaxid}
                maxLength={16}
                value={projectInfo.HOAtaxid}
                onChange={handleChange}
              />

              {errorHoaTax != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorHoaTax}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Home Owner's Association Name</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                className="form-control"
                name="HOAname"
                value={projectInfo.HOAname}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Master Association Name</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                className="form-control"
                name="MAname"
                value={projectInfo.MAname}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Is the project a cooperative?<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <select
                required
                name="coopInd"
                id={"id"}
                className="form-control"
                ref={Coop}
                value={projectInfo.coopInd}
                onChange={event => {
                  handleChange(event);
                  if (Coop.current.value.length == 0) {
                    seterrorCoop("Please Select Yes or No");
                  } else {
                    seterrorCoop("");
                  }
                }}
              >         
                <option value="">Select Yes or No</option>
                <option value="true">Yes</option>
                <option value="false">No</option>                      
              </select>
              {errorCoop != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorCoop}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Does the project consist of manufactured housing?<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <select
                required
                name="manfdHousngInd"
                id={"id"}
                className="form-control"
                ref={Manf}
                value={projectInfo.manfdHousngInd}
                onChange={event => {
                  handleChange(event);
                  if (Manf.current.value.length == 0) {
                    seterrorManf("Please Select Yes or No");
                  } else {
                    seterrorManf("");
                  }
                }}
              >         
                <option value="">Select Yes or No</option>
                <option value="true">Yes</option>
                <option value="false">No</option>                      
              </select>
              {errorManf != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorManf}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Does the project consist of attached units?<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <select
                required
                name="unitsType"
                id={"id"}
                className="form-control"
                ref={Status}
                value={projectInfo.unitsType}
                onChange={event => {
                  handleChange(event);
                  if (Status.current.value.length == 0) {
                    seterrorStatus("Please Select a Unit Type");
                  } else {
                    seterrorStatus("");
                  }
                }}
              >         
                <option value="">Select a Unit Type</option>
                <option value="Attached">Attached</option>
                <option value="Detached">Detached</option> 
                <option value="Combination">Combination</option>                      
              </select>
              {errorStatus != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorStatus}</p>
              ) : null}
            </Grid>
          </Grid>
          {isProjectRole() ? (
            <Grid container spacing={2} className="dialoguClass">
              <Grid item xs={12} sm={12} md={12}>
                <div>Comments</div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div>
                  <textarea
                    rows="3"
                    type="text"
                    className="form-control mt-3"
                    name="comments"
                    maxLength="5000"
                    placeholder="Enter your Comments"
                    onChange={e => {
                      handleChange(e);
                      setCommentLength(e.target.value.length);
                    }}
                  ></textarea>
                  <span
                    style={{
                      display: "inline-block",
                      marginRight: "5px",
                      float: "right",
                      fontSize: "14px",
                      color: "#787979"
                    }}
                  >{`${commentLength}/5000`}</span>
                </div>
              </Grid>
            </Grid>
          ) : null}

          {/*  <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Is this new project?</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="isNewInd"
                value={projectInfo.isNewInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Affordable Housing?</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="affordableHousingInd"
                value={projectInfo.affordableHousingInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Age Restrictions?</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="ageRestrictionInd"
                value={projectInfo.ageRestrictionInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Do the unit owners have exclusive ownership rights to their
                unit?
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="unitOwnersExclusiveOwnshipRightInd"
                value={projectInfo.unitOwnersExclusiveOwnshipRightInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                If Yes, is the master flood insurance premium paid by the unit
                owners as part of Association dues?
                <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="assocFeeIncludesFloodInsurancePremiumInd"
                value={projectInfo.assocFeeIncludesFloodInsurancePremiumInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput required={true} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput required={true} />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Has the project been turned over to the unit owners?
                <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="transferOfControlToUnitOwnersInd"
                value={projectInfo.transferOfControlToUnitOwnersInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput required={true} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput required={true} />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Is the project 100% complete, including all construction or
                renovation of units, common elements and shared amenities for
                all project phases?
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="projectCompleteInd"
                value={projectInfo.projectCompleteInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Is the project at least 90% sold and closed?
                <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="proj_90_pct_sold_and_completeInd"
                value={projectInfo.proj_90_pct_sold_and_completeInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput required={true} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput required={true} />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Is the Association governed by a Master Association?</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <RadioGroup
                row
                name="monthlyMorethan50MstrAssocFeeInd"
                value={projectInfo.monthlyMorethan50MstrAssocFeeInd}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<RadioInput />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<RadioInput />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid> */}
          <div
            style={{
              color: "#ff0000"
            }}
          >
            {apiErr ? apiErr : null}
          </div>
          <Grid container  spacing={2} className="dialoguClass">
            <Grid item xs={12} sm ={12} md={7}>
            <Grid container justify="flex-end">

            <Button
              type="submit"
              // endIcon={<ArrowForward/>}
              style={{
                color: "#fff",
                backgroundColor: "rgb(17, 118, 181)",
                borderRadius: "4px",
                boxShadow:
                  "rgb(0 0 0 / 37%) 0px 0.4px 0.6px 0px, rgb(10 100 157) 0px 0.1px 0px 0.4px",
                height: "40px",
                width: "150px",
                textTransform: "capitalize"
              }}
              // style={{
              //   color: "white",
              //   backgroundColor: "#1176B5",
              //   borderRadius: "4px",
              //   boxShadow:
              //     "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
              //   height: "40px",
              //   width: "190px",
              //   textTransform: "capitalize"
              // }}
              onSubmit={e => calladdProject(e)}
              onClick={e => calladdProject(e)}
              form="addProjectForm"
            >
              Save
            </Button>
           
            </Grid>
              </Grid>
            {/* <Button
              type="submit"
              className="ml-3"
              style={{
                color: "black",
                backgroundColor: "#fcfcfc",
                borderRadius: "4px",
                boxShadow:
                  "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                height: "40px",
                width: "150px",
                textTransform: "capitalize"
              }}
              form="addProjectForm"
            >
              Back
            </Button> */}
         
          </Grid>
        </Grid>
      </form>
    </Grid>
    </>
  );
};

export default CondoProjectPage;

import React from 'react';
import './LenderList.css';
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import { Accordion, Card } from "react-bootstrap";



const IndividualLender = ({ item }) => {

  return (
    <div >
        <Accordion defaultActiveKey="1">
            <Card className="accordion-header-card">
                <Card.Header className="accordion-header-space">
                    <ContextAwareToggle eventKey="0">
                        <p className="accordion-header-title">
                        {item.lenderSSNId}
                        </p>
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className="accordion-body-card">
                        <div className="accordion-body-card-title">Overview</div>
                        <div className="accordion-body-card-left">
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Project Legal Name</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.projectName}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Master Association</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.masterAssociationName}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Alternate Project Name</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.altProjectName}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Project Style</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.projectStyle}</div>
                            </div>
                            {item.projectStyle === "Other" ? (
                                <div class="accordion-body-card-attributes">
                                    <div class="accordion-body-card-attributes-name">Other Comments</div>
                                    <div class="accordion-body-card-attributes-semicolon">:</div>
                                    <div class="accordion-body-card-attributes-value">{item.styleComments}</div>
                                </div>
                            ) : ("")}
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">State</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.state}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">City</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.city}</div>
                            </div>
                        </div>
                        <div className="accordion-body-card-right">
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Total Units</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.unitCount}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">HOA Name</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.hoaName}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">HOA Tax ID</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.hoaTaxId}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Address</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.street}</div>
                            </div>
                            <div class="accordion-body-card-attributes">
                                <div class="accordion-body-card-attributes-name">Zip</div>
                                <div class="accordion-body-card-attributes-semicolon">:</div>
                                <div class="accordion-body-card-attributes-value">{item.zip5}</div>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </div>
  );
};


export default IndividualLender;
import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

import ContextAwareToggle from "../Profile/ContextAwareToggle";
import Decision from "../Decision/Decision";
import { isReadonlyRole } from "../../redux/actions/userAction";

export default function Section8({ collapseKey, persId, projectId, persType, reasonCodes }) {
  const [getDecision, setDecision] = useState(null);

  const fetchDecisionData = async () => {
    const { data } = await axios.get(
      `${global.REACT_APP_API_ENDPOINT}/decision/getPersDecisionHistory/${persId}`
    );
    setDecision(data);
  };
  useEffect(() => {
    if (projectId) {
      fetchDecisionData();
    }
  }, [projectId]);

  const renderDecision = () => {
    let list = <h1>Loading...</h1>;
    if (getDecision) {
      list = <Decision list={getDecision} persId={persId} reasonCodes={reasonCodes}/>;
    }
    return list;
  };

  return (
    <Accordion defaultActiveKey="1" id="section1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">PERS Decisions</p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? (
            <Link to={`/add-new-decision/${projectId}/${persId}/${persType}`}>
              <button className="add-phase-button">Add New Decision</button>
            </Link>
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            {renderDecision()}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

import React from "react";
import "./Help.css";
import { Card } from "react-bootstrap";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RocketshipIcon from "../../images/rocketship.svg";
import BookIcon from "../../images/book.svg";
import QuestionMarkIcon from "../../images/Question-mark.svg";
import CertificationIcon from "../../images/certification.svg";
import OtherResourcesIcon from "../../images/other-resources.svg";
import DesktopIcon from "../../images/desktop.svg";
import MedalBadgeIcon from "../../images/medal-badge.svg";
const cards = [
  {
    image: RocketshipIcon,
    title: "CPM Quick Start Guide",
    body: "Get a quick start on accessing and using CPM.",
    buttonText: "Watch Video",
    buttonClick:
      "https://singlefamily.fanniemae.com/external-resource/cpm-quick-start-guide",
  },
  {
    image: BookIcon,
    title: "User Guide",
    body: "View our guide on navigating the platform.",
    buttonText: "View Document",
    buttonClick:
      "https://singlefamily.fanniemae.com/media/document/pdf/condo-project-manager-user-guide",
  },
  {
    image: QuestionMarkIcon,
    title: "Frequently Asked Questions (FAQs)",
    body: "Get answers to some of our most frequently asked questions.",
    buttonText: "View FAQs",
    buttonClick:
      "https://singlefamily.fanniemae.com/media/document/pdf/cpm-faqs",
  },
];

const helpCard = (content, key) => {
  const {
    image,
    title,
    body,
    buttonText,
    hideBelowTheFold,
    displayText,
    buttonClick,
  } = content;

  return (
    <Card className="help-card" key={key}>
      <img style={{ width: 60, height: 60, margin: 20 }} src={image} />
      <h3 className="help-body-header card-text">{title}</h3>
      <p className="help-body-content card-text card-text-body">{body}</p>
      {!hideBelowTheFold ? (
        <>
          <div style={{ paddingLeft: 20 }}>
            <div className="line"></div>
          </div>
          <button
            className="card-button"
            onClick={(e) => {
              e.preventDefault();
              window.open(buttonClick, "_blank");
            }}
          >
            {buttonText}
          </button>
        </>
      ) : (
        displayText
      )}
    </Card>
  );
};
export const Help = () => (
  <div>
    <div className="help-banner">
      <p className="help-center">Help Center</p>
      <p className="have-a-question-we">
        Have a question? We'd love to help you!
      </p>
    </div>
    <div className="help-body">
      <div>
        <h3 className="getting-started-help-body-header">Getting Started</h3>
        <p className="getting-started-help-body-content">
          Use these resources to help you get started on the platform.
        </p>
      </div>
      {cards.map((card, index) => {
        return helpCard(card, index);
      })}
      <Card className="help-card">
        <img
          style={{ width: 60, height: 60, margin: 20 }}
          src={OtherResourcesIcon}
        />
        <h3 className="help-body-header card-text">
          {"Adding Projects or Phases"}
        </h3>
        <ul style={{ fontSize: 18, paddingLeft: 40, color: "#0C77BA" }}>
          <li style={{ marginLeft: 32, color: "black", fontSize: 18 }}>
            <a
              href="https://singlefamily.fanniemae.com/media/document/pdf/cpm-add-or-update-projectphase-job-aid"
              target="_blank"
            >
              How to add a new project or phase.
            </a>
          </li>
          <li style={{ marginLeft: 32, color: "black", fontSize: 18 }}>
            <a
              href="https://singlefamily.fanniemae.com/media/document/pdf/cpm-project-request-form"
              target="_blank"
            >
              Access the form to add a new project.
            </a>
          </li>
          <li style={{ marginLeft: 32, color: "black", fontSize: 18 }}>
            <a
              href="https://singlefamily.fanniemae.com/media/document/pdf/cpm-phase-request-form"
              target="_blank"
            >
              Access the form to add a new phase.
            </a>
          </li>
        </ul>
      </Card>

      <Card className="help-card">
        <img
          style={{ width: 60, height: 60, margin: 20 }}
          src={OtherResourcesIcon}
        />
        <h3 className="help-body-header card-text">{"Other Resources"}</h3>
        <ul style={{ fontSize: 18, paddingLeft: 40, color: "#0C77BA" }}>
          <li style={{ marginLeft: 32, color: "black", fontSize: 18 }}>
            <a
              href="https://singlefamily.fanniemae.com/applications-technology/condo-project-manager"
              target="_blank"
            >
              Condo Project Manager page on FannieMae.com
            </a>
          </li>
          <li style={{ marginLeft: 32, color: "black", fontSize: 18 }}>
            <a
              href="https://singlefamily.fanniemae.com/originating-underwriting/condo-co-op-and-pud-eligibility"
              target="_blank"
            >
              Condo, Co-op, and PUD Project Eligibility
            </a>
          </li>
          <li style={{ marginLeft: 32, color: "black", fontSize: 18 }}>
            <a
              href="https://www.fanniemae.com/content/recorded_tutorial/condo-project-review-guidelines"
              target="_blank"
            >
              Project Review Guidelines for Condos
            </a>
          </li>
        </ul>
      </Card>
    </div>
    <div style={{ height: /*458*/ "100%", backgroundColor: "#F2F2F2" }}>
      <div className="help-body">
        <h3 className="getting-started-help-body-header">Customer Support</h3>
        <p
          className="getting-started-help-body-content"
          style={{
            color: "rgba(0,0,0,0.82)",
            fontFamily: "Source Sans Pro",
            fontSize: "24px",
          }}
        >
          For customer support, please reach out and we will try to respond as
          quickly as possible.
        </p>
        <Card className="help-card">
          <img
            style={{ width: 60, height: 60, margin: 20 }}
            src={DesktopIcon}
          />
          <h3 className="help-body-header card-text">
            {"CPM Technical and Registration"}
          </h3>
          <p className="help-body-content card-text card-text-body">
            For Technical or Registration issues - Contact our
            <a
              href="https://singlefamily.fanniemae.com/applications-technology/technology-support-center"
              target="_blank"
            >
              &nbsp;Technology Support Center
            </a>
          </p>
          <div style={{ paddingLeft: 20 }}>
            <div className="line"></div>
          </div>
          <span
            style={{
              paddingLeft: 30,
              paddingTop: 20,
              color: "rgb(94 93 93)",
              fontSize: 18,
            }}
          >
            <PhoneIcon />
            <span className="card-text card-text-body">
              1-800-232-6643 Option 1
            </span>
          </span>
        </Card>

        <Card className="help-card">
          <img
            style={{ width: 60, height: 60, margin: 20 }}
            src={MedalBadgeIcon}
          />
          <h3 className="help-body-header card-text">
            {"Project Certifications or System Functionality"}
          </h3>
          <p
            className="help-body-content card-text card-text-body"
            style={{ height: "max-content" }}
          >
            For questions on the Condo certification process the new system
            features, please email Fannie Mae Project Standards
          </p>
          <div style={{ paddingLeft: 20 }}>
            <div className="line"></div>
          </div>
          <span
            style={{
              paddingLeft: 30,
              paddingTop: 20,
              color: "rgb(94 93 93)",
              fontSize: 18,
            }}
          >
            <EmailIcon />
            <span className="card-text card-text-body">
              <a href="mailto:cpm_manage@fanniemae.com">
                cpm_manage@fanniemae.com
              </a>
            </span>
          </span>
        </Card>
      </div>
    </div>
  </div>
);

export default Help;

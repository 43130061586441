import React, { Component } from "react";
import axios from "axios";
import "./ProjectsList.css";
import {
  Navbar,
  Nav,
  Card,
  TabContainer,
  Tab,
  TabContent,
  TabPane
} from "react-bootstrap";
import { IoMdDownload } from "react-icons/io";
import {
  FaChevronLeft,
  FaFilter,
  FaCaretDown,
  FaListUl,
  FaTh,
  FaSort
} from "react-icons/fa";
import List from "./ListNoImage/List";
import { isProjectRole } from "../../redux/actions/userAction";

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: [],
      myproject: []
    };
  }

  async componentDidMount() {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    try {
      const { data: project } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/getAllProjects/`
      );
      this.setState({
        project
      });
      console.log(this.state.project);
    } catch (err) {
      console.log(err);
    }

    try {
      const { data: myproject } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/getProjectsByUser/` + passData.user
      );
      this.setState({
        myproject
      });
      console.log(this.state.myproject);
    } catch (err) {
      console.log(err);
    }
  }

  allStorage() {
    let list = <h1>Loading...</h1>;
    if (this.state.project) {
      list = <List list={this.state.project} />;
    }
    return list;
  }

  myStorage() {
    let list = <h1>Loading...</h1>;
    if (this.state.myproject) {
      list = <List list={this.state.myproject} />;
    }
    return list;
  }

  // downloadExcel() {
  //   const passData = JSON.parse(sessionStorage.getItem("passData"));
  //   axios({
  //     url:
  //       global.REACT_APP_API_ENDPOINT + `/reports/exportAllProjectAndPhaseDataToExcel/`+ passData.user,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then(response => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "condo_allprojects.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // }

  render() {
    return (
      <div>
        <div className="projects-list-title">Projects List</div>
        <div>
          <TabContainer id="project-list-tab" defaultActiveKey="first">
            <Nav variant="pills" className="project-list-nav">
              <Nav.Item>
                <Nav.Link className="project-list-link" eventKey="first">
                  My Projects
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="project-list-link" eventKey="second">
                  All Projects
                </Nav.Link>
              </Nav.Item>
              {/* {
                isProjectRole() ?  <div className="ml-auto">
                <div className="filter-container">
                  <div className="filter-button">
                  <button className="download-xlsx" onClick={this.downloadExcel}>
                      <IoMdDownload className="download-icon" /> Download XLSX
                  </button>
                  </div>
                </div>
              </div> : ''
              } */}
            </Nav>
            <TabContent className="projects-content">
              <div className="projects-container">
                <div className="list-filters">
                  <div className="filter-by-condo">
                    Condo Project
                    <FaSort className="sort-icon" />
                  </div>
                  <div className="filter-by-status">
                    Type
                    <FaSort className="sort-icon" />
                  </div>
                  <div className="filter-by-address">
                    Address
                    <FaSort className="sort-icon" />
                  </div>
                  <div className="filter-by-city">
                    City
                    <FaSort className="sort-icon" />
                  </div>
                  <div className="filter-by-state">
                    State
                    <FaSort className="sort-icon" />
                  </div>
                  <div className="filter-by-projectid">
                    Project ID
                    <FaSort className="sort-icon" />
                  </div>
                  <div className="filter-by-hoaid">
                    HOA Tax ID
                    <FaSort className="sort-icon" />
                  </div>
                </div>
              </div>
              <TabPane eventKey="first">{this.myStorage()}</TabPane>
              <TabPane eventKey="second">{this.allStorage()}</TabPane>
              <div className="projects-footer"></div>
            </TabContent>
          </TabContainer>
        </div>
      </div>
    );
  }
}
export default ProjectsList;

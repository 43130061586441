import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import FannieMaeIcon from "../../images/icons/Shape@1x.svg";
import { isInternal } from "../../redux/actions/userAction";
import ReasonCodeTooltip from "../SharedComponents/ReasonCodeTooltip/ReasonCodeTooltip";
import DatePicker from "../SharedComponents/DatePicker/DatePicker";
import ReactSelect from "react-select";
const projectOverride = (data) => {
  return axios({
    method: "POST",
    url: `${global.REACT_APP_API_ENDPOINT}/condoMgr/saveManuallyOverriddenFMReviewStatusForProject`,
    data: data,
  });
};

const phaseOverride = (data) => {
  return axios({
    method: "POST",
    url: `${global.REACT_APP_API_ENDPOINT}/condoMgr/saveManuallyOverriddenFMReviewStatusForPhase`,
    data: data,
  });
};

const getProjectStatusDetails = (id) => {
  return axios({
    method: "GET",
    url: `${global.REACT_APP_API_ENDPOINT}/condoMgr/getEditInfoForManuallyOverriddenFMReviewStatusForProject/${id}`,
  });
};

const getPhaseStatusDetails = (phaseId, projectId) => {
  return axios({
    method: "GET",
    url: `${global.REACT_APP_API_ENDPOINT}/phase/getEditInfoForManuallyOverriddenFMReviewStatusForPhase/${projectId}/${phaseId}`,
  });
};

const ContainerCModal = ({
  showContainerCModal,
  setShowConainerCModal,
  details,
  getSavedProjectResponse,
  getSavedPhaseResponse,
  getSavedChildPhaseResponse,
  reasonCodes
}) => {
  const loginUserDetails = JSON.parse(
    window.sessionStorage.getItem("passData")
  );
  const [editInfoStatus, setEditInfoStatus] = useState({});
  const [overrideReason, setoverrideReason] = useState("");
  const [value, setValue] = useState("No Fannie Mae Review");
  const [projectData, setListItems] = useState([]);
  const [codes, setReasonCodes] = useState([]);
  const [selectedReasonCodes, setSelectedReasonCodes] = useState([]);
  const [disable, setDisable] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [approvalExpiryDate, setApprovalExpiryDate] = useState(null);

  useEffect(() => {
    setValue("No Fannie Mae Review");
    setSelectedReasonCodes([]);
    if ( isInternal() ) {
      if (details && details.projectId) {
        if (details.type === "project") {
          getProjectStatusDetails(details.projectId)
            .then((res) => {
              setEditInfoStatus(res.data);
              setListItems(res.data.manualOverrideCanChangeTo);              
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (details.type === "phase") {
          console.log(details, "details project data...");
          getPhaseStatusDetails(details.phaseId, details.projectId)
            .then((res) => {
              setEditInfoStatus(res.data);
              setListItems(res.data.manualOverrideCanChangeTo);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (details.type === "childPhase") {
          console.log(details);
          getPhaseStatusDetails(details.phaseId, details.projectId)
            .then((res) => {
              setEditInfoStatus(res.data);
              setListItems(res.data.manualOverrideCanChangeTo);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (reasonCodes?.length > 0) {
          const options = [];
          reasonCodes.map( r => {
            options.push({'label': r.reasonCode , 'value': r.reasonId})
          })
          options.sort((a,b) => a.label.localeCompare(b.label))
          setReasonCodes(options);
        }
      }
    }
  }, [details]);
  const handleClose = () => {
    setShowConainerCModal(false);
    setDisable(false);
  };
  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null) {
        delete obj[propName];
      }
    }
    return obj;
  };
  const handleSave = () => {
    setReasonError(selectedReasonCodes.length > 0 ? false : true);
    let status = value;
    const statusList = projectData;
    const reasonIds = []
    if (
      (value === "" || value === "No Fannie Mae Review") &&
      statusList.length === 1
    ) {
      if (statusList.indexOf("No Fannie Mae Review") !== -1) {
        status = "No Fannie Mae Review";
      } else {
        status = statusList[0];
      }
    }
    console.log(editInfoStatus, "editInfoStatus");
    if ( selectedReasonCodes && selectedReasonCodes.length > 0) {
      selectedReasonCodes.map(reason => {
        reasonIds.push(reason.value);
      })
    }
    if ((status !== 'Unavailable') || (status === 'Unavailable' && reasonIds.length > 0 )) {
      setDisable(true);
      handleClose();
      if (details.type === "project") {
        projectOverride(
          clean({
            ...details,
            overrideReason: overrideReason,
            createdBy: loginUserDetails.user,
            reasonIds: reasonIds,
            fnmaReviewStatus: status,
            approvalExpiryDate
          })
        ).then((res) => {
          console.log(res);
          getSavedProjectResponse({
            ...res.data,
            showManualOverrideButton: editInfoStatus.showManualOverrideButton,
          });
          setoverrideReason("");
          setValue("");
          setApprovalExpiryDate(null);
        });
      } else if (details.type === "phase") {
        phaseOverride(
          clean({
            ...details,
            overrideReason: overrideReason,
            createdBy: loginUserDetails.user,
            reasonIds: reasonIds,
            fnmaReviewStatus: status,
            approvalExpiryDate
          })
        ).then((res) => {
          console.log(res);
          getSavedPhaseResponse({
            ...res.data,
            showManualOverrideButton: editInfoStatus.showManualOverrideButton,
          });
          setoverrideReason("");
          setValue("");
          setApprovalExpiryDate(null);
        });
      } else if (details.type === "childPhase") {
        phaseOverride(
          clean({
            ...details,
            overrideReason: overrideReason,
            createdBy: loginUserDetails.user,
            reasonIds: reasonIds,
            fnmaReviewStatus: status,
            approvalExpiryDate
          })
        ).then((res) => {
          console.log(res);
          getSavedChildPhaseResponse({
            ...res.data,
            showManualOverrideButton: editInfoStatus.showManualOverrideButton,
          });
          setoverrideReason("");
          setValue("");
          setApprovalExpiryDate(null);
        });
      }
    }

  };

  return (
    <Modal
      show={showContainerCModal}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      style={{ padding: "24px 22px" }}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ alignItems: "center" }}>
        <div>
          <img
            alt="Fannie Mae Icon"
            src={FannieMaeIcon}
            style={{ marginRight: "10px", height: "16px", width: "16px" }}
          />
        </div>
        <Modal.Title
          className="status-title"
          id="contained-modal-title-vcenter"
        >
          Fannie Mae Review Status Change
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="w-75 pl-3 ">
          <div className="form-group">
            <label className="change-status">Change Status To</label>
            <select
              className="status-dropdown"
              aria-label="Change Status To"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setoverrideReason("");
                setApprovalExpiryDate(null);
                if(e.target.value === "Approved By Fannie Mae")
                  setoverrideReason("Fannie Mae Review Completed");
              }}
            >
              {projectData &&
                projectData.map((each, idx) => (
                  <option value={each} key={idx}>
                    {each}
                  </option>
                ))}
            </select>
          </div>
          {value === "Approved By Fannie Mae" &&
            <div class="form-group">
              <label className="">Expiry Date</label>
              <DatePicker
                id="approvalExpiryDate"
                label=""
                placeholder={"Expiry Date"}
                name={approvalExpiryDate}
                changeHandler={(e) => {
                  if (new Date(event.target.value) >= new Date("1776-01-01")) {
                    setApprovalExpiryDate(e.target.value);
                  } else {
                    setApprovalExpiryDate(null);
                  }
                }}
                value={approvalExpiryDate}
                datePickerWidth="150px"
                min="1776-01-01"
              /></div>}
          <div class="form-group">
            <label className="">Reason for change</label>
            {value === "Unavailable" ? (
              <>
               <ReasonCodeTooltip reasonCodes={reasonCodes}/>
                <ReactSelect
                  value={selectedReasonCodes}
                  className="reason-codes"
                  options={codes}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(value) => {
                    setSelectedReasonCodes(value)
                    setReasonError(value.length > 0 ? false : true);
                  }}
                  allowSelectAll={false}
                />
                {reasonError ? (
                                <div
                                  style={{
                                    color: "#ff0000",
                                  }}
                                >
                                  Please select reason codes.
                                </div>
                              ) : (<></>)}
              </>) :
              (<>
                <textarea
                  className="status-textarea"
                  aria-label="Reason for Change"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={overrideReason}
                  style={{ paddingLeft: 8, paddingTop: 8 }}
                  onChange={(e) => {
                    setoverrideReason(e.target.value);
                  }}
                />
              </>)
            }
          </div>
          <div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#f7f7f7" }}>
        <Button
          className="status-savebtn"
          onClick={handleSave}
          disabled={disable}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContainerCModal;


import { STATE_RESULTS } from '../actions/ActionType'

export const initialState = {

        states: [],
        
}
export const dialogueReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATE_RESULTS:
            return {
           states: action.payload
          } 
        default:
            return state
    }
}


import React from 'react';
import IndividualCard from './IndividualCard';
import './Cards.css';
import {stableSort, getComparator} from '../List/EnhancedTable';


const Cards = ({ list,sort }) => {
  let cards = <h3>Loading...</h3>;

  if (list) {
    cards = stableSort(list, getComparator('asc', sort &&sort.sortValue)).map((m, i) => <IndividualCard key={i} project={m} />);
  }

  return (
    <div className="card-display">
      {cards}
    </div>
  );
};

export default Cards;
import React, { useState, useEffect } from "react";
import IndividualPdf from "./IndividualPdf";
import "./Pdf.css";
import { FaSort } from "react-icons/fa";

const Pdf = ({ list }) => {
  const [sortType, setSortType] = useState({
    fileName: "asc",
    userName: "asc",
    uploadedDate: "asc",
    notes: "asc",
  });
  const [pdfList, setPdfList] = useState([]);

  useEffect(() => {
    if (list) {
      setPdfList(list);
    }
  }, [list]);

  const onSort = (column) => {
    const sortOrder = sortType[column] === "asc" ? "desc" : "asc";
    const obj = { ...sortType, [column]: sortOrder };
    setSortType(JSON.parse(JSON.stringify(obj)));
    sortPdfList(column);
  };

  const sortPdfList = (column) => {
    let sortList = [].concat(pdfList);
    if (column === "uploadedDate") {
      sortList
        .sort((a, b) => {
          const item1 = new Date(a.uploadedDate).getTime();
          const item2 = new Date(b.uploadedDate).getTime();
          return sortType[column] === "asc" ? item1 - item2 : item2 - item1;
        })
        .map((pdf) => pdf);
    } else {
      sortList
        .sort((a, b) => {
          const item1 = a[column].toLowerCase();
          const item2 = b[column].toLowerCase();
          return sortType[column] === "asc"
            ? item1.localeCompare(item2)
            : item2.localeCompare(item1);
        })
        .map((pdf) => pdf);
    }
    setPdfList(sortList);
  };

  return (
    <div className="pdf-container">
      <div className="pdf-header">
        <div className="pdf-header-name">
          Filename{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("fileName");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Upload By{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("userName");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Uploaded Date{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("uploadedDate");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Notes{" "}
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("notes");
            }}
          />
        </div>
      </div>
      <div className="pdf-body">
        {pdfList && pdfList.length > 0 ? (
          pdfList.map((m, i) => <IndividualPdf key={i} item={m} />)
        ) : (
          <></>
        )}
      </div>{" "}
    </div>
  );
};
export default Pdf;

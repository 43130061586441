import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CancelButton } from "../CancelButton";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'
export const Page2 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  step,
  setYear1,
  setYear2,
  setYear3,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateNumNoDec(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();

  const year = new Date().getFullYear();
  const years = Array.from(new Array(120), (val, index) => year - index);
  const stringYears = years.map(String);
  console.log("YEARSSSS", stringYears);

  const [yearError, setYearError] = useState("");
  let yvalue1 = formData.piYearBuilt;
  const yvalue2 = formData.piYearConvtd;
  const yvalue3 = formData.piDtTurndOver;
  const [yearValue, setYearValue] = useState(yvalue1);
  const [yearConv, setYearConv] = useState(yvalue2);
  const [yearTurned, setYearTurned] = useState(yvalue3);
  const [yearValueError, setYearValueError] = useState("");
  const [yearConvError, setYearConvError] = useState("");
  const [yearTurnedError, setYearTurnedError] = useState("");
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";
  

  function saveApi() {
    if (
      yearValueError === "" &&
      yearConvError === "" &&
      yearTurnedError === ""
    ) {
      formData = page7tMultiSelectOptions(formData)
      let requestObject = certificationFormatter(formData);
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
        requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
          ? ""
          : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
      axios
        .post(
          global.REACT_APP_API_ENDPOINT +
            `/lndrCertification/saveLenderCertificationFormsPhase`,
          requestObject
        )
        .then((response) => {
          if (response.status == 200) {
            setTimeout(() => {
              if (formData.phaseId === "proj") {
                window.location.href = "/project/" + formData.projectId;
              } else {
                window.location.href = "/project/phase/" + formData.phaseId;
              }
            }, 300);
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              window.location.href = "/error-page";
            } else {
              console.log(response.data.message);
            }
          }
        })
        .catch((error) => {
          console.log("certification phase error", error);
        });
    }
  }

  const isdigit = (value) => {
    const val = Number(value) ? true : false;
    return val;
  };

  const isValidYear = (value) => {
    let isValid = true;
    let year = value.toString();
    if (year && year !== "" && year !== "0") {
      if (isdigit(year) && year.length === 4) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else if (year !== "0") {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const validateYearValue = (value) => {
    if (value !== "") {
      if (isValidYear(value)) {
        setYearValueError("");
      } else {
        setYearValueError("Invalid Year");
      }
    }
  };

  const validateYearTurned = (value) => {
    if (value !== "") {
      if (isValidYear(value)) {
        setYearTurnedError("");
      } else {
        setYearTurnedError("Invalid Year");
      }
    }
  };

  const validateYearConv = (value) => {
    if (value !== "") {
      if (isValidYear(value)) {
        setYearConvError("");
      } else {
        setYearConvError("Invalid Year");
      }
    }
  };

  const saveAndNext = (values) => {
    let data = values;
    setYear1(yearValue);
    setYear2(yearConv);
    setYear3(yearTurned);
    data.piYearBuilt = yearValue;
    data.piYearConvtd = yearConv;
    data.piDtTurndOver = yearTurned;
    if (yearValue === "0") {
      setYearValueError("Invalid Year");
    } else {
      validateYearValue(yearValue);
    }
    // reset subquestions
    if (data.piConvtdProjInd === "No") {
      data.piGutRehabInd = "";
      data.piYearConvtd = "";
    } else {
      if (yearConv === "0") {
        setYearConvError("Invalid Year");
      } else {
        validateYearConv(yearConv);
      }
    }
    if (data.piCntlHOATurnOverInd === "No") {
      data.piDtTurndOver = "";
    } else {
      if (yearTurned === "0") {
        setYearTurnedError("Invalid Year");
      } else {
        validateYearTurned(yearTurned);
      }
    }
    if (data.piCommnAreasCompltInd === "Yes") {
      data.npeAssrArrgReviewedInd = "";
    }
    if (
      yearValueError === "" &&
      yearConvError === "" &&
      yearTurnedError === ""
    ) {
      setFormData(data);
      nextStep();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            <FormStepper step={step} type={values.phaseId} class={status} />
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ padding: "0px 0px 0px 134px" }}
                  >
                    <>
                      <div className="type">Project Type</div>
                      <div className="attributes">
                        {status === "new" ? (
                          <>New</>
                        ) : status === "est" ? (
                          <>Established</>
                        ) : (
                          <>Incomplete</>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {status === "new" ? (
                        <div className="subheader">Section 2 OF 8</div>
                      ) : (
                        <div className="subheader">Section 2 OF 7</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 2 OF 9</div>
                    </>
                  )}
                  <div className="title">Project Information</div>
                  <div className="subtitle">
                    Provide details about the Entire Project
                  </div>
                </div>
                <div className="form-body">
                  <div className="question">
                    <div className="number">1.</div>
                    <div>In what year was the project originally built?</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <Autocomplete
                        options={stringYears}
                        value={yearValue.toString()}
                        onInputChange={(event, yearValue) => {
                          setYearValue(yearValue);
                        }}
                        style={{ width: 150 }}
                        validate={validateYearValue(yearValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            readOnly
                            required={yearValue?.length === 0}
                          />
                        )}
                      />
                      <div className="form-error">{yearValueError}</div>
                    </div>
                  </div>
                  <div className="question">
                    <div className="number">2.</div>
                    <div>Is this a converted project?</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="piConvtdProjInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="piConvtdProjInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.piConvtdProjInd && touched.piConvtdProjInd && (
                      <div className="form-error">{errors.piConvtdProjInd}</div>
                    )}
                  </div>
                  {values.piConvtdProjInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Is the project a gut rehabilitation conversion as
                          defined in{" "}
                          <a
                            target="_blank"
                            href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                          >
                            {" "}
                            B4-2.2-02
                          </a>{" "}
                          of the Selling Guide, or a non-gut conversion that
                          meets the definition of an Established Project?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="piGutRehabInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="piGutRehabInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.piGutRehabInd && touched.piGutRehabInd && (
                          <div className="form-error">
                            {errors.piGutRehabInd}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {values.piConvtdProjInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          What year was the project converted?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <Autocomplete
                            options={stringYears}
                            value={yearConv.toString()}
                            onInputChange={(event, yearConv) => {
                              setYearConv(yearConv);
                            }}
                            style={{ width: 150 }}
                            validate={validateYearConv(yearConv)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                readOnly
                                variant="outlined"
                                required={yearConv?.length === 0}
                              />
                            )}
                          />
                          <div className="form-error">{yearConvError}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="question">
                    <div className="number">3.</div>
                    <div>
                      Is the project subject to additional legal phasing or
                      annexation?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="piAddnPhsOrAnnxInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="piAddnPhsOrAnnxInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.piAddnPhsOrAnnxInd &&
                      touched.piAddnPhsOrAnnxInd && (
                        <div className="form-error">
                          {errors.piAddnPhsOrAnnxInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">4.</div>
                    <div>
                      Has control of the HOA been turned over to the unit
                      purchasers?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="piCntlHOATurnOverInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="piCntlHOATurnOverInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.piCntlHOATurnOverInd &&
                      touched.piCntlHOATurnOverInd && (
                        <div className="form-error">
                          {errors.piCntlHOATurnOverInd}
                        </div>
                      )}
                  </div>
                  {values.piCntlHOATurnOverInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Year Turned Over
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <Autocomplete
                            options={stringYears}
                            value={yearTurned.toString()}
                            onInputChange={(event, yearTurned) => {
                              setYearTurned(yearTurned);
                            }}
                            style={{ width: 150 }}
                            validate={validateYearTurned(yearTurned)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                readOnly
                                required={yearTurned?.length === 0}
                              />
                            )}
                          />
                          <div className="form-error">{yearTurnedError}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="question">
                    <div className="number">5.</div>
                    <div>
                      Are the common areas and amenities 100% complete for the
                      entire project?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="piCommnAreasCompltInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="piCommnAreasCompltInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.piCommnAreasCompltInd &&
                      touched.piCommnAreasCompltInd && (
                        <div className="form-error">
                          {errors.piCommnAreasCompltInd}
                        </div>
                      )}
                  </div>
                  {values.piCommnAreasCompltInd === "No" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div>
                          Have acceptable completion assurance arrangements been
                          reviewed (such as a bond, cash deposit, letter of
                          credit) to guarantee the future completion of all
                          project facilities, common elements and limited common
                          elements, or is the project still under construction
                          and not considered substantially complete?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer">
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="npeAssrArrgReviewedInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="npeAssrArrgReviewedInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.npeAssrArrgReviewedInd &&
                          touched.npeAssrArrgReviewedInd && (
                            <div className="form-error">
                              {errors.npeAssrArrgReviewedInd}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button className="save-exit-btn" onClick={saveApi}>
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>
                  <button
                    className="next-button"
                    type="submit"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import React from "react";
import { SpecialAssessments } from "./SpecialAssessments";
import moment from "moment";
import { policyChangeDate } from "../../constants/SplAssmtReasons";
export const FinancialsSpecialAssessmentsCriticalRepairs = ({ data }) => {
  let splAssmtList = <></>;
  if (data.lcFinancialsSt.fspAssmts.length > 0) {
    splAssmtList = data.lcFinancialsSt.fspAssmts.map((data, index) => <SpecialAssessments data={data} index={index} />);
  }

  const inArray = (list, key) => {
    var length = list.length;
    if (list.length === 0) {
      return false;
    }
    for (var i = 0; i < length; i++) {
      if (list[i].toLowerCase() == key.toLowerCase())
        return true;
    }
    return false;
  };


  return (
    <div>
      {moment(data.certfcnDate) < moment(policyChangeDate) ?
        (<>
          <div style={{ padding: "18px 24px" }}>
            <div className="question">
              <div className="number">1.</div>
              <div>
                How many units are ≥60 days past due on their
                HOA common expense assessments?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .funitsHOAExpnPastDueSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.funitsHOAExpnPastDue}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.funitsHOAExpnPastDue}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">2.</div>
              <div>
                What is the expiration date of the current
                annual budget?{" "}
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              <div className="attribute">
                {data.lcFinancialsSt.fexpDateAnlBudget}
              </div>
            </div>
            <div className="question">
              <div className="number">3.</div>
              <div>
                What are the budgeted annual assessment income?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt.fbugtAnlIncomeSt ===
                    "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fbugtAnlIncome}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fbugtAnlIncome}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">4.</div>
              <div>
                What is the total amount of exclusionary items
                as defined by the{" "}
                <a
                  target="_blank"
                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                >
                  {" "}
                  Selling Guide B4-2.2-02
                </a>
                ?(Put zero if not applicable)
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt.famtExclItemsSt ===
                    "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.famtExclItems}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.famtExclItems}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">5.</div>
              <div>What are the budgeted annual reserves?</div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt.fbugtAnlReservesSt ===
                    "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fbugtAnlReserves}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fbugtAnlReserves}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">6.</div>{" "}
              <div>
                Are there currently any special assessments?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              <div className="attribute">
                {data.lcFinancialsSt.fsplAssmtsInd}
              </div>
            </div>
            {data.lcFinancialsSt.fsplAssmtsInd === "Yes" ? (
              <>
                <div className="question">
                  <div
                    className="number"
                    style={{
                      borderLeft: "1px solid rgba(0,0,0,0.4)",
                      borderBottom: "1px solid rgba(0,0,0,0.4)",
                      marginLeft: "37px",
                      marginBottom: "10px",
                    }}
                  ></div>{" "}
                  <div style={{ paddingTop: "10px" }}>
                    What is the total amount of the special
                    assessment?
                  </div>
                </div>
                <div
                  className="summary-answer"
                  style={{ paddingLeft: "86px" }}
                >
                  <div className="label">Answer:&nbsp;</div>
                  <div className="attribute">
                    {data.lcFinancialsSt.fttlSpAssmts}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="question">
              <div className="number">7.</div>{" "}
              <div>
                Is there currently any significant deferred
                maintenance or physical obsolescence noted in
                the project?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              <div className="attribute">
                {data.lcFinancialsSt.fdefdMntInd}
              </div>
            </div>
          </div>
        </>) : (<>
          <div style={{ padding: "18px 24px" }}>
            <div className="question">
              <div className="number">1.</div>
              <div>
                How many units are ≥60 days past due on their
                HOA common expense assessments?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .funitsHOAExpnPastDueSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.funitsHOAExpnPastDue}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.funitsHOAExpnPastDue}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">2.</div>
              <div>
                What is the expiration date of the current
                annual budget?{" "}
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              <div className="attribute">
                {data.lcFinancialsSt.fexpDateAnlBudget}
              </div>
            </div>
            <div className="question">
              <div className="number">3.</div>
              <div>
                What are the budgeted annual assessment income?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt.fbugtAnlIncomeSt ===
                    "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fbugtAnlIncome}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fbugtAnlIncome}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">4.</div>
              <div>
                What is the total amount of exclusionary items
                as defined by the{" "}
                <a
                  target="_blank"
                  href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
                >
                  {" "}
                  Selling Guide B4-2.2-02
                </a>
                ?(Put zero if not applicable)
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt.famtExclItemsSt ===
                    "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.famtExclItems}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.famtExclItems}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">5.</div>
              <div>What are the budgeted annual reserves?</div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt.fbugtAnlReservesSt ===
                    "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fbugtAnlReserves}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fbugtAnlReserves}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {
              (
                (data.lcFinancialsSt.fbugtAnlReserves /
                  (data.lcFinancialsSt.fbugtAnlIncome -
                    data.lcFinancialsSt.famtExclItems)) *
                100 *
                1
              ) < 10 ?
                <>
                  <div className="question">
                    <div
                      className="number"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                        marginLeft: "37px",
                        marginBottom: "10px",
                      }}
                    ></div>{" "}
                    <div style={{ paddingTop: "10px" }}>
                      The lender has reviewed a reserve study that meets the requirements in Section
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                      >
                        {" "}
                        B4-2.2-02
                      </a> including demonstrating that the project's funded reserves provides adequate financial protection and meet or exceed the recommendations included in the reserve study.
                    </div>
                  </div>
                  <div
                    className="summary-answer"
                    style={{ paddingLeft: "86px" }}
                  >

                    {typeof data.lcFinancialsSt !== "undefined" ? (
                      <>
                        {data.lcFinancialsSt
                          .fresStudyReqIndSt === "Fail" ? (
                          <>
                            <div
                              className="attribute"
                              style={{ color: "#D0021B" }}
                            >
                              {data.lcFinancialsSt.fresStudyReqInd}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="attribute">
                              {data.lcFinancialsSt.fresStudyReqInd}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
                : <></>
            }
            <div className="question">
              <div className="number">6.</div>{" "}
              <div>
                How many planned or active special assessments have been issued  <a
                  target="_blank"
                  href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B4-Underwriting-Property/Chapter-B4-2-Project-Standards/#Projects.20in.20Need.20of.20Critical.20Repairs">
                  {" "}
                  B4-2.1-03
                </a>? (if none, enter zero)?If the HOA does not separately track special assessments, enter 1
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              <div className="attribute">
                {data.lcFinancialsSt.fnoOfSpAssmts}
              </div>
            </div>
            {
              data.lcFinancialsSt.fnoOfSpAssmts > 0 ?
                <>
                  <hr style={{ borderTop: "dotted 1px", color: "#2e2e2e" }} />
                  {splAssmtList}
                </>
                : (<></>)
            }
            <div className="question">
              <div className="number">7.</div>{" "}
              <div>
                Is the project subject to partial or total evacuation order due to unsafe conditions that have not been remediated?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .fdefdMntIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fdefdMntInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fdefdMntInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">8.</div>{" "}
              <div>
                Does the project have any material deficiencies which, if left uncorrected, have the potential to result in or contribute to critical element or system failure within one year (e.g., sea wall, elevators, waterproofing, stairwells)?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .fmetDefIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fmetDefInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fmetDefInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {
              data.lcFinancialsSt.fmetDefInd === "Yes" && data.lcFinancialsSt.fmetDefElem?.length > 0 ?
                <>
                  <div className="question">
                    <div
                      className="number"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                        marginLeft: "37px",
                        marginBottom: "10px",
                      }}
                    ></div>{" "}
                    <div style={{ paddingTop: "10px" }}>
                      What elements are impacted? (Check all that apply)?
                    </div>
                  </div>
                  <div
                    className="summary-answer"
                    style={{ paddingLeft: "86px" }}
                  >
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute">
                      {data.lcFinancialsSt.fmetDefElem.toString()}
                    </div>
                  </div>
                  {
                    inArray(data.lcFinancialsSt.fmetDefElem, 'Other') ?
                      (<>
                        <>
                          <div className="question">
                            <div
                              className="number"
                              style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "70px",
                                marginBottom: "10px",
                              }}
                            ></div>{" "}
                            <div style={{ paddingTop: "10px" }}>
                              Other Reason?
                            </div>
                          </div>
                          <div
                            className="summary-answer"
                            style={{ paddingLeft: "86px" }}
                          >
                            <div className="label">Answer:&nbsp;</div>
                            <div className="attribute">
                              {data.lcFinancialsSt.fotherReasonMetDef}
                            </div>
                          </div>
                        </>
                      </>) :
                      (<></>)
                  }
                </>
                : <></>
            }
            <div className="question">
              <div className="number">9.</div>{" "}
              <div>
                Is there any mold, water intrusions or potentially damaging leaks to the project's building(s) have not been repaired?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt.fleakRepairIndSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .fleakRepairIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fleakRepairInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fleakRepairInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">10.</div>{" "}
              <div>
                Is there any advanced physical deterioration?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .fphyDetIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.fphyDetInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.fphyDetInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {
              data.lcFinancialsSt.fphyDetInd === "Yes" && data.lcFinancialsSt.fphyDetEle.length > 0 ?
                <>
                  <div className="question">
                    <div
                      className="number"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,0.4)",
                        borderBottom: "1px solid rgba(0,0,0,0.4)",
                        marginLeft: "37px",
                        marginBottom: "10px",
                      }}
                    ></div>{" "}
                    <div style={{ paddingTop: "10px" }}>
                      What elements are impacted? (Check all that apply)?
                    </div>
                  </div>
                  <div
                    className="summary-answer"
                    style={{ paddingLeft: "86px" }}
                  >
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute">
                      {data.lcFinancialsSt.fphyDetEle.toString()}
                    </div>
                  </div>
                  {
                    inArray(data.lcFinancialsSt.fphyDetEle, 'Other') ?
                      (<>
                        <>
                          <div className="question">
                            <div
                              className="number"
                              style={{
                                borderLeft: "1px solid rgba(0,0,0,0.4)",
                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                marginLeft: "70px",
                                marginBottom: "10px",
                              }}
                            ></div>{" "}
                            <div style={{ paddingTop: "10px" }}>
                              Other Reason?
                            </div>
                          </div>
                          <div
                            className="summary-answer"
                            style={{ paddingLeft: "86px" }}
                          >
                            <div className="label">Answer:&nbsp;</div>
                            <div className="attribute">
                              {data.lcFinancialsSt.fotherReasonPhyDet}
                            </div>
                          </div>
                        </>
                      </>) :
                      (<></>)
                  }
                </>
                : <></>
            }

            <div className="question">
              <div className="number">11.</div>{" "}
              <div>
                Has the project failed to pass state or other jurisdictional mandatory inspections and/or certifications specific to structural soundness, safety, and habitability?
              </div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .finspFailIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.finspFailInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.finspFailInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="question">
              <div className="number">12.</div>{" "}
              <div>
                Are there any unfunded repairs costing more than $10,000 per unit that should be undertaken within the next 12 months (does not include repairs made by the unit owner or repairs funded through a special assessment)</div>
            </div>
            <div className="summary-answer">
              <div className="label">Answer:&nbsp;</div>
              {typeof data.lcFinancialsSt !== "undefined" ? (
                <>
                  {data.lcFinancialsSt
                    .funfundRepIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.lcFinancialsSt.funfundRepInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.lcFinancialsSt.funfundRepInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>)
      }
    </div>
  );
}

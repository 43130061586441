import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link } from "react-router-dom";
import {
  FaChevronLeft,
  FaFilter,
  FaCaretDown,
  FaListUl,
  FaTh,
  FaSort,
} from "react-icons/fa";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "projectName",
    numeric: false,
    disablePadding: true,
    label: "Condo Project",
  },
  { id: "Status", numeric: true, disablePadding: false, label: "Status" },
  { id: "street", numeric: true, disablePadding: false, label: "Address" },
  { id: "city", numeric: true, disablePadding: false, label: "City" },
  { id: "state", numeric: true, disablePadding: false, label: "State" },
  {
    id: "projectId",
    numeric: true,
    disablePadding: false,
    label: "Project ID",
  },
  {
    id: "HOA_Tax_ID",
    numeric: true,
    disablePadding: false,
    label: "HOA Tax ID",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="header">
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className="headerstyle"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <MuiThemeProvider theme={theme}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                classes={{
                  // Override with the active class if this is the selected column or inactive otherwise
                  icon:
                    orderBy === headCell.id
                      ? classes.activeSortIcon
                      : classes.inactiveSortIcon,
                }}
              >
                {/* <div style={{display: 'grid'}}> */}
                {/* <ArrowDropUpIcon/>
            <ArrowDropDownIcon/> */}
                <FaSort className="sort" />
                {/* </div> */}
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </MuiThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexDirection: "row-reverse",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  activeSortIcon: {
    opacity: 1,
    display: "none",
  },
  // Half visible for inactive icons
  inactiveSortIcon: {
    opacity: 0.4,
    display: "none",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const theme = createTheme({
  overrides: {
    MuiTableSortLabel: {
      root: {
        flexDirection: "row-reverse",
      },
    },
  },
});

export default function EnhancedTable({ list }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("projectId");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);
  console.log("list........", list);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      component={Link}
                      to={`/project/${row.projectId}`}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      // selected={isItemSelected}
                    >
                      <TableCell
                        className="imgbox"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        padding="checkbox"
                      >
                        <img
                          className="image"
                          style={{
                            padding: "0px 10px 10px 0px",
                            borderBottom: "none",
                          }}
                          src={
                            global.REACT_APP_API_ENDPOINT +
                            `/docStorage/serveImage/${row.mainPicId}`
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        className="pname"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.projectName}
                      </TableCell>
                      <TableCell
                        className="status"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        align="right"
                      >
                        {"ELIGIBLE"}
                      </TableCell>
                      <TableCell
                        className="street"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        align="right"
                      >
                        {row.street}
                      </TableCell>
                      <TableCell
                        className="city"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        align="right"
                      >
                        {row.city}
                      </TableCell>
                      <TableCell
                        className="state"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        align="right"
                      >
                        {row.state}
                      </TableCell>
                      <TableCell
                        className="pid"
                        style={{
                          padding: "0px 10px 10px 0px",
                          borderBottom: "none",
                        }}
                        align="right"
                      >
                        {row.projectId}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import React, { useState, Fragment } from "react";
import "./Pdf.css";
import { GrClose } from "react-icons/gr";
import {
  FaTrashAlt,
  FaRegEdit,
  FaMinusCircle,
  FaPen,
  FaRegSave,
} from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { isReadonlyRole } from "../../redux/actions/userAction";

const IndividualComment = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [notes, setNotes] = useState("");
  const [item, setItem] = useState({});
  React.useEffect(() => {
    setItem(props.item);
  }, [props]);
  const deletePdf = () => {
    console.log(item.docMapId);
    axios
      .delete(
        global.REACT_APP_API_ENDPOINT +
          `/docStorage/deleteFile/${item.docMapId}`,
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((response) => {
        window.location.reload(false);
        console.log(response);
      })
      .catch((error) => {
        window.location.reload(false);
        console.log(error);
      });
  };

  const downloadFile = () => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/docStorage/downloadFile/${item.docMapId}`,
        {
          headers: { "Access-Control-Allow-Origin": "*" }
        }
      )
      .then((response) => {
        if (response?.data) {
          console.log(response.data);
          console.log(response.data.fileUrl);
          const url = response.data.fileUrl;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "test");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function DeleteModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEdit = () => {
      setNotes(item.notes);
      setIsEdit(true);
    };

    const getUserId = () => {
      const passData = window.sessionStorage.getItem("passData");
      if (passData && JSON.parse(passData).hasOwnProperty("user")) {
        return JSON.parse(passData).user;
      }
      return null;
    };

    const handleSave = () => {
      axios({
        method: "POST",
        url:
          global.REACT_APP_API_ENDPOINT +
          "/docStorage/editNotesForAttachmentsByDocMapId",
        headers: { "Access-Control-Allow-Origin": "*" },
        data: {
          docMapId: item.docMapId,
          notes: notes,
          updatedBy: getUserId(),
        },
      })
        .then((response) => {
          setItem({ ...item, notes: response.data.notes });
          setIsEdit(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <>
        {!isReadonlyRole() ? (
          <button className="pdf-delete-button" onClick={handleShow}>
            <FaTrashAlt
              className="pdf-delete-icon"
              style={{ color: "#d0021b" }}
            />
            Delete
          </button>
        ) : null}

        <Modal
          className="delete-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to delete this file?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="delete-modal-body">
            This will permenantly delete the file from this project profile!
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="delete-modal-cancel"
              variant="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="delete-modal-delete"
              variant="primary"
              onClick={deletePdf}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {!isReadonlyRole() ? (
          <Fragment>
            {isEdit ? (
              <div>
                {/* <button className="pdf-delete-button" onClick={() => setIsEdit(false)}>
                  <FaMinusCircle className="pdf-delete-icon" />
                   cancel
                 </button> */}
                <button className="pdf-delete-button" onClick={handleSave}>
                  <FaRegSave className="pdf-delete-icon" />
                  Save
                </button>
              </div>
            ) : (
              <button className="pdf-delete-button" onClick={handleEdit}>
                <FaPen className="pdf-delete-icon" />
                Edit
              </button>
            )}
          </Fragment>
        ) : null}
      </>
    );
  }

  return (
    <div className="indy-pdf-container">
      <div className="pdf-link-date-container">
        <div
          className="pdf-link"
          onClick={downloadFile}
          style={{ cursor: "pointer" }}
        >
          {" "}
          {item.fileName}
        </div>
        <div className="pdf-date-size">
          {item.fileSize} | {item.uploadedDate}
        </div>
      </div>
      <div className="pdf-uploaded-by">{item.userName}</div>
      <div className="pdf-uploaded-date">{item.uploadedDate}</div>

      {isEdit ? (
        <div className="pdf-notes">
          <div className="w-80 position-relative">
            <GrClose
              className="position-absolute individual-notes"
              onClick={() => setIsEdit(false)}
            />
            <Form.Control
              as="textarea"
              value={notes}
              name="notes"
              rows={3}
              maxLength="500"
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div className="pdf-notes">{item.notes}</div>
      )}
      <DeleteModal />
    </div>
  );
};

export default IndividualComment;

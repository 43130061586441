import React, { useEffect, useState } from "react";
import moment from "moment";

export const SessionErrorPage = () => {
  const [logoutDate, setLogoutDate] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("lastInteractionTime")) {
      let date = moment(sessionStorage.getItem("lastInteractionTime"))
        .add(5, "minutes")
        .format("MM/DD/YYYY HH:mm A");
      setLogoutDate(date);
      Object.keys(sessionStorage).forEach((key) =>
        sessionStorage.removeItem(key)
      );
    }
  });
  return (
    <>
      <div
        style={{
          fontSize: 20,
          textAlign: "center",
          padding: 40,
          fontWeight: "bold",
          color: "#212529",
        }}
      >
        Your 30 minute session has expired and you have been logged out{" "}
        {logoutDate ? "at " + logoutDate + " " : ""} due to inactivity. Please{" "}
        <a href="/">Login</a> again to continue working.{" "}
      </div>
    </>
  );
};

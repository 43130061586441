import React, { useState, useEffect } from "react";
import IndividualPhase from "./IndividualPhase";
import "./PhaseList.css";
import { FaSort } from "react-icons/fa";

const PhaseList = ({ list }) => {
  const [sortType, setSortType] = useState({
    phaseNumber: "asc",
    phaseId: "asc",
    street: "asc",
    unitNbr: "asc",
    phaseUnitCount: "asc",
    bldgNbr: "asc",
    certificationCount: "asc",
  });
  
  const [phaseList, setPhaseList] = useState([]);
  
  useEffect(() => {
    if (list) {
      setPhaseList(list);
    }
  }, [list]);
  
  const onSort = (column) => {
    const sortOrder = sortType[column] === "asc" ? "desc" : "asc";
    const obj = { ...sortType, [column]: sortOrder };
    setSortType(JSON.parse(JSON.stringify(obj)));
    sortPhaseList(column);
  };

  const getSortComparer = (column) => {
    const sortable = (value) => {
      if (!value) {
        return value;
      }
      if (!isNaN(+value)) {
        return +value;
      }
      return value.toString().toLowerCase();
    };

    const compare = (a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
      }

      // blanks come before 0
      if (typeof a === 'number' && !b) {
        return 1;
      }
        
      if (typeof b === 'number' && !a) {
        return -1;
      }

      if (typeof a === 'number' && typeof b === 'string') {
        return -1;
      }
      
      if (typeof a === 'string' && typeof b === 'number') {
        return 1;
      }
      
      if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
      }
      
      return a < b
        ? -1
        : a > b
        ? 1
        : 0;
    };

    return (itemA, itemB) => {
      if (sortType[column] !== 'asc') {
        [itemA, itemB] = [itemB, itemA];
      }

      return compare(sortable(itemA[column]), sortable(itemB[column]));
    };
  };
  
  const sortPhaseList = (column) => {
    // Array.sort always places undefined at the end of the array. We don't want this.
    const sortList = [].concat(phaseList).map(item => item === undefined ? null : item);
    sortList.sort(getSortComparer(column));
    setPhaseList(sortList);
  };

  let phase = <h3>Loading...</h3>;

  if (phaseList) {
    phase = phaseList.map((m, i) => <IndividualPhase key={i} item={m} />);
  }

  return (
    <div className="pdf-container">
      <div className="pdf-header">
        <div className="pdf-header-name" style={{ flex: "0.30" }}>
          Phase Name
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("phaseNumber");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Phase ID
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("phaseId");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Address
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("street");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Unit No.
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("unitNbr");
            }}
          />
        </div>
        <div className="pdf-header-name">
          No. of Units
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("phaseUnitCount");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Building No.
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("bldgNbr");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Cert. Status
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("certificationCount");
            }}
          />
        </div>
      </div>
      <div className="pdf-body">{phase}</div>
    </div>
  );
};
export default PhaseList;

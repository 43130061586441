import axios from "axios";
import moment from "moment";
import { AuditTrailActions } from "./components/shared/constants/AuditTrailActions";

export default {
  setupInterceptors: (props) => {
    global.REACT_APP_API_ENDPOINT = "/cgapi";
    const APIsTrackingForAuditLog = AuditTrailActions;
    if (
      window.location.hostname == "localhost" ||
      window.location.hostname ==
      "webapp-essui-eb.eitcg.devl-ess.aws.fanniemae.com"
    ) {
      global.REACT_APP_API_ENDPOINT =
        "http://localhost:5000/cgapi";
    } else if (window.location.hostname == "cgo-ui-dev-01-eb.eitcg.devl-sfbu.aws.fanniemae.com") {
      global.REACT_APP_API_ENDPOINT =
        "http://localhost:5000/cgapi";
    } else if (
      window.location.hostname ==
      "ess-ui-devl-eb.eitcg.devl-ess.aws.fanniemae.com"
    ) {
      global.REACT_APP_API_ENDPOINT =
        "https://ess-ven-devl-eb.eitcg.devl-ess.aws.fanniemae.com/cgapi";
    }

    //Set headers for all request
    axios.interceptors.request.use(
      (request) => {
        console.log("In request interceptor....");
        const role = sessionStorage.getItem("role");
        let url = "";
        if (window.location.hostname == "localhost" || window.location.hostname ==
          "webapp-essui-eb.eitcg.devl-ess.aws.fanniemae.com") {
            url = request.url;
        } else {
            url = "https://" + window.location.hostname + request.url;
        }
        if (url) {
          const method = new URL(url).pathname;
          if (APIsTrackingForAuditLog.findIndex(element => method.includes(element)) >= 0) {
            if (request?.method == "delete") {
              let data = method.split('/').filter(Boolean).pop() ? method.split('/').filter(Boolean).pop() : "No Data";
              sessionStorage.setItem("requestdata", data);
            } else {
              if (request?.data) {
                sessionStorage.setItem("requestdata", JSON.stringify(request.data));
              } else {
                sessionStorage.setItem("requestdata", "No Data");
              }
            }
            request.headers['request-startTime'] = new Date().getTime();
          }
        }
        request.headers["x-cg-assumed-role"] = role;
        axios.defaults.headers.common["x-cg-assumed-role"] = role;
        request.hostname = "";
        return request;
      },
      (error) => {
        console.error(error);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        const reponseTime = new Date();
        sessionStorage.setItem("lastInteractionTime", moment());
        if (response.status === 200) {
        /* Audit trails workflo for All the POST APIS and other important APIs Admin user wants to track */
          let url = "";
          if (window.location.hostname == "localhost" || window.location.hostname ==
          "webapp-essui-eb.eitcg.devl-ess.aws.fanniemae.com") {
            url = response.request?.responseURL;
          } else {
            url = "https://" + window.location.hostname + response.request?.responseURL;
          }
          if (url) {
            const method = new URL(url).pathname;
            if (APIsTrackingForAuditLog.findIndex(element => method.includes(element)) >= 0) {
              const api = APIsTrackingForAuditLog.find(element => method.includes(element));
              if (api !== 'postFileuploadContext') {
                const passData = JSON.parse(sessionStorage.getItem("passData"));
                let requestData = 'No Data';
                console.log(sessionStorage.getItem("requestdata"))
                if (sessionStorage.getItem("requestdata")) {
                  if (sessionStorage.getItem("requestdata") == 'No Data'){
                    requestData = sessionStorage.getItem("requestdata");
                  } else {
                    try {
                     if (JSON.parse(sessionStorage.getItem("requestdata"))) {
                        requestData = JSON.parse(sessionStorage.getItem("requestdata"));
                      } else{
                        requestData = sessionStorage.getItem("requestdata");
                     }
                    }
                    catch(e) {
                      requestData = sessionStorage.getItem("requestdata");
                    }
                  }
                } 
                const startTime = response.config.headers['request-startTime'];
                const duration = new Date().getTime() - startTime;
                const requestTime = new Date().setMilliseconds(new Date().getMilliseconds() - duration);
                const request = {
                  'id': null,
                  'action': response?.config?.method.toUpperCase(),
                  'function': api,
                  'requestUser': passData?.user,
                  'payload': JSON.stringify(requestData),
                  'requestTime': new Date(requestTime),
                  'responseTime': reponseTime,
                  'auditTimestamp': new Date()
                }
                axios
                .post(global.REACT_APP_API_ENDPOINT + `/auditdata/saveAuditTrail`, request)
                .then((response) => {
                  sessionStorage.removeItem("requestdata");
                })
                .catch((error) => {
                  throw Error(error);
                });
              } else {
                sessionStorage.removeItem("requestdata");
              }
            }
          }
        }
        return response;
      },
      function (error) {
        if ( window.location.href.indexOf("condogo/cgapi/?cdxRedirectPath=/") > -1) {
          window.location.replace(window.location.protocol +
            "//" + window.location.host + "/cgapi/?cdxRedirectPath=/");
        }
        if (error && error.response) {
          if (error.response.data) {
            if (!response.request?.responseURL.indexOf("cgapi") > -1  && error.response.status == 302) {
              window.location.replace(window.location.protocol +
                "//" + window.location.host + "/cgapi/?cdxRedirectPath=/");
            }
            if (error.response.status == 500 || error.response.status == 503) {
              window.location.href = "/error-page";
              error.response.data.message = "Internal server error";
            } else if (error.response.status == 417) {
              error.response.data.message = error.response.data.message;
            } else if (error.response.status == 422) {
              if (error.response.data.errors.length > 0) {
                let errMsg = [];
                for (let err of error.response.data.errors) {
                  let mergeobj = err.field + " " + err.message;
                  errMsg.push(mergeobj);
                }
                let conStr = errMsg.join(", ");
                error.response.data.message = conStr;
              } else {
                error.response.data.message = error.response.data.message;
              }
            }
          }
        }
        return error.response;
      }
    );
  },
};


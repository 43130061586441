import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../../AddEstablishedPers/AddEstablishedPers.css";
import MultiselectTwoSide from "react-multiselect-two-sides";
import AutoComplete from "../../SharedComponents/AutoComplete/AutoComplete";
import { red } from "@material-ui/core/colors";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const LendingDetails = ({
  formData,
  setFormData,
  nextStep,
  step,
  projectId,
  setSelectedPhaseList,
  setSelectedSaved,
  selectedPhaseSaved,
}) => {
  const [radio, setRadio] = React.useState("Entire Project");
  const [decisionLevel, setDecisionLevel] = React.useState("");
  const [isPhases, setIsPhases] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!formData.decisionLevel) {
      setDecisionLevel("Entire Project");
    } else {
      setDecisionLevel(formData.decisionLevel);
    }
  }, []);

  let [responseData, setResponseData] = React.useState("");
  const fetchData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);

  let [phaseResponseData, setPhaseResponseData] = React.useState("");
  const fetchPhaseData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/phase/getPhaseNamesByProjectId/` +
          projectId
      )
      .then((response) => {
        console.log("getPhaseNamesByProjectId =>>", response);
        setPhaseResponseData(response.data);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);

  React.useEffect(() => {
    fetchData();
    fetchPhaseData();
  }, [fetchData, fetchPhaseData]);

  const result = Object.keys(responseData).map((key) => responseData[key]);
  const stateList = result.map((state) => {
    return { value: state, label: state };
  });

  const phaseList = Object.keys(phaseResponseData).map((key) => {
    return { value: key, name: phaseResponseData[key] };
  });

  function validateDate(value) {
    if (!value) {
      return "Required Date";
    }

    const inputDate = new Date(value);

    if (inputDate.toString() === "Invalid Date") {
      return "Invalid date format";
    }

    const minDate = new Date("1776-01-01");

    if (inputDate < minDate) {
      return "Date cannot be before Jan 1, 1776."
    }

    return undefined;
  }

  function validateText(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateState(value) {
    let error;
    if (value === "" || !value) {
      error = "Required";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required Email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid Email Address";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    if (
      value &&
      !/^(?:(?:\\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
        value
      )
    ) {
      error = "Invalid Phone Number. eg((555)-555-5555, 555-555-5555)";
    }
    return error;
  }

  function validateCity(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value)) {
      error = "Invalid City";
    }
    return error;
  }

  function validateNumNoStrReq(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNumNoStrReq5(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNumNoStr(value) {
    let error;
    if (value && !/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNumNoStr5(value) {
    let error;
    if (value && !/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNum9(value) {
    let error;
    if (value && !/^((?!(0))[0-9]{9})$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function validateText(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function radioCheck(event) {
    setRadio(event.target.value);
    setDecisionLevel(event.target.value);

    if (event.target.value == "Phases") {
      setIsPhases(true);
    } else {
      setIsPhases(false);
    }
  }

  // let selected = [0, 3, 9];
  const [selectedPhase, setSelected] = React.useState([]);
  const [errSelectedPhase, setErrSelected] = React.useState([]);

  function handleChange(value) {
    setErrSelected("");
    setSelected(value);
    setSelectedSaved(value);

    let selectedPhaseData = [];
    for (let phase of phaseList) {
      let index = value.indexOf(phase.value);
      if (index >= 0) {
        selectedPhaseData.push(phase);
      }
    }
    setSelectedPhaseList(selectedPhaseData);

    console.log("selectedPhaseData", selectedPhaseData);
  }

  const [lenderIdError, setLenderIdError] = useState("");
  const [afLenderId, setAfLenderId] = useState("");
  const [afLenderName, setAfLenderName] = useState("Default");
  const [lenderIdAutoSuggestions, setlenderIdAutoSuggestions] = useState([]);
  const searchHandler = (state, selectedOption) => {
    if (selectedOption) {
      setAfLenderId(selectedOption.lenderId);
      setAfLenderName(selectedOption.lenderName);
    } else {
      let x = `${state}`;
      const newState = x.split(",").reduce((a, c, i) => {
        a[`s${i + 1}`] = c;
        return a;
      }, {});
      setAfLenderId(newState.s1);
      setAfLenderName(newState.s2);
    }
  };
  const autoCompleteChangeHandler = (flag, event) => {
    const value = flag ? "" : event.target.value;
    setAfLenderId(value);
    if (event.target.value.length >= 3) {
      const condoPayload = value;

      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/lender/searchLenders/${condoPayload}`
        )
        .then((response) => {
          const data = response.data;
          const slicedData = data.slice(0, 10);
          setlenderIdAutoSuggestions(slicedData);
        })
        .catch((error) => {
          setlenderIdAutoSuggestions([]);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          values.decisionLevel = decisionLevel;
          values.phaseIds = selectedPhase;
          values.lenderSSN = afLenderId;
          values.lenderName = afLenderName;

          setFormData(values);
          // setFormData({lenderSSN: afLenderId});
          setErrSelected("");
          setLenderIdError("");
          console.log("values >>> ", values, selectedPhase);
          if (decisionLevel == "Phases" || afLenderId === "") {
            if (selectedPhase.length <= 0) {
              setErrSelected("Please select atleast one phase");
            } else {
              nextStep();
            }
            setLenderIdError("Required");
          } else {
            nextStep();
          }
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 1 of 9 - Lender and Project Details
                </div>
                <>
                  <div id="radio-group" className="attribute-title">
                    Is the PERS providing a decision on the entire project or
                    specific phases?
                  </div>

                  <div
                    role="group"
                    aria-labelledby="radio-group"
                    className="radio-box"
                    style={{
                      flexDirection: "row",
                      marginLeft: 25,
                    }}
                  >
                    {phaseList?.length > 0 ? (
                      <>
                        {" "}
                        <label style={{ marginRight: 20 }}>
                          <Field
                            type="radio"
                            color="blue"
                            name="decisionLevel"
                            value="Entire Project"
                            checked={radio ? "Entire Project" : false}
                            onClick={radioCheck}
                          />
                          <span style={{ marginLeft: 5 }}>Entire Project</span>
                        </label>
                        <label>
                          <Field
                            type="radio"
                            color="blue"
                            name="decisionLevel"
                            value="Phases"
                            onClick={radioCheck}
                          />
                          <span style={{ marginLeft: 5 }}>Phases</span>
                        </label>
                      </>
                    ) : (
                      <>
                        <label style={{ marginRight: 20 }}>
                          <Field
                            type="radio"
                            color="blue"
                            name="decisionLevel"
                            value="Entire Project"
                            checked={radio ? "Entire Project" : false}
                            onClick={radioCheck}
                          />
                          <span style={{ marginLeft: 5 }}>Entire Project</span>
                        </label>
                      </>
                    )}
                  </div>
                </>
                {isPhases || values.decisionLevel == "Phases" ? (
                  <div className="phase-container">
                    <div className="phase-title">Phases</div>
                    <MultiselectTwoSide
                      options={phaseList}
                      value={
                        selectedPhase.length > 0
                          ? selectedPhase
                          : selectedPhaseSaved
                      }
                      className="msts_theme_example"
                      onChange={handleChange}
                      availableHeader="Available Phases"
                      // availableFooter={`Available: ${availableCount}`}
                      selectedHeader="Selected for this PERS"
                      // selectedFooter={`Selected: ${selectedCount}`}
                      labelKey="name"
                      showControls
                      // searchable
                    />
                    <div
                      style={{
                        color: "#ff0000",
                      }}
                    >
                      {errSelectedPhase}
                    </div>
                  </div>
                ) : null}

                <div className="attribute-title">
                  Received Date<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="receivedDate"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                    //   aria-label: "Received Date"
                  }}
                  inputProps={{
                    "aria-label": "Received Date",
                  }}
                  validate={validateDate}
                  error={touched.receivedDate && errors.receivedDate}
                  helperText={touched.receivedDate && errors.receivedDate}
                />
                <div className="attribute-title">
                  Assigned Risk Analyst<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="assgndRiskAnalyst"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.assgndRiskAnalyst && errors.assgndRiskAnalyst}
                  helperText={
                    touched.assgndRiskAnalyst && errors.assgndRiskAnalyst
                  }
                  inputProps={{
                    "aria-label": "Assigned Risk Analyst",
                  }}
                />
                {/* <div className="attribute-title">
                  Lender<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.lenderName && errors.lenderName}
                  helperText={touched.lenderName && errors.lenderName}
                /> */}
                <div className="attribute-title">
                  Lender Seller/Service Number
                  <span style={{ color: "red" }}>*</span>
                </div>
                <AutoComplete
                  autoCompleteClassName={"attribute-input"}
                  options={[...lenderIdAutoSuggestions]}
                  id={"afLenderId"}
                  name="lenderId"
                  value={afLenderId || ""}
                  changeHandler={autoCompleteChangeHandler}
                  searchHandler={searchHandler}
                  type="lenderId"
                  inputProps={{
                    "aria-label": "Lender Seller/Service Number",
                    autoComplete: "off",
                  }}
                />
                <div
                  style={{
                    color: "#ff0000",
                  }}
                >
                  {lenderIdError}
                </div>
                {/* <Field
                  name="lenderSSN"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStrReq}
                  inputProps={{ maxLength: 32 }}
                  error={touched.lenderSSN && errors.lenderSSN}
                  helperText={touched.lenderSSN && errors.lenderSSN}
                /> */}
                <div className="attribute-title">
                  Lender Address<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderAddress"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.lenderAddress && errors.lenderAddress}
                  helperText={touched.lenderAddress && errors.lenderAddress}
                  inputProps={{
                    "aria-label": "Lender Address",
                  }}
                />
                <div className="attribute-title">
                  Lender City<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderCity"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateCity}
                  inputProps={{ maxLength: 128 }}
                  error={touched.lenderCity && errors.lenderCity}
                  helperText={touched.lenderCity && errors.lenderCity}
                  inputProps={{
                    "aria-label": "Lender City",
                  }}
                />
                <div className="attribute-title">
                  Lender State<span style={{ color: "red" }}>*</span>
                </div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="lenderState"
                    as="select"
                    validate={validateState}
                    aria-label="Lender state"
                  >
                    <option value="">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {errors.lenderState && touched.lenderState ? (
                    <div
                      className="pers-error-message"
                      style={{ color: "red" }}
                    >
                      {errors.lenderState}
                    </div>
                  ) : null}
                </div>
                <div className="attribute-title">
                  Lender Zip Code<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderZip"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStrReq5}
                  inputProps={{ maxLength: 5 }}
                  error={touched.lenderZip && errors.lenderZip}
                  helperText={touched.lenderZip && errors.lenderZip}
                  inputProps={{
                    "aria-label": "Lender Zip",
                  }}
                />
                <div className="attribute-title">
                  Requester<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="requesterName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.requesterName && errors.requesterName}
                  helperText={touched.requesterName && errors.requesterName}
                  inputProps={{
                    "aria-label": "Requester",
                  }}
                />
                <div className="attribute-title">
                  Requester Email<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="requesterEmail"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateEmail}
                  inputProps={{ maxLength: 200 }}
                  error={touched.requesterEmail && errors.requesterEmail}
                  helperText={touched.requesterEmail && errors.requesterEmail}
                  inputProps={{
                    "aria-label": "Requester Email",
                  }}
                />
                <div className="attribute-title">
                  Builder/Developer Name (LLC)
                  <span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="builderOrDevelopeName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={
                    touched.builderOrDevelopeName &&
                    errors.builderOrDevelopeName
                  }
                  helperText={
                    touched.builderOrDevelopeName &&
                    errors.builderOrDevelopeName
                  }
                  inputProps={{
                    "aria-label": "Builder Name",
                  }}
                />
                <div className="attribute-title">
                  Builder/Developer Principals
                </div>
                <Field
                  name="builderOrDeveloperPrincipals"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{
                    "aria-label": "Builder or Developer Principals",
                  }}
                />
                <div className="attribute-title">
                  Builder/Developer Primary Contact
                </div>
                <Field
                  name="builderOrDeveloperPrimaryContact"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{
                    "aria-label": "Builder or Developer Primary Contact",
                  }}
                />
                <div className="attribute-title">Contact Phone</div>
                <Field
                  name="builderOrDeveloperContactPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 32 }}
                  validate={validatePhone}
                  error={
                    touched.builderOrDeveloperContactPhone &&
                    errors.builderOrDeveloperContactPhone
                  }
                  helperText={
                    touched.builderOrDeveloperContactPhone &&
                    errors.builderOrDeveloperContactPhone
                  }
                  variant="outlined"
                  inputProps={{
                    "aria-label": "Builder or Developer Contact Phone",
                  }}
                />
                <div className="attribute-title">Builder/Developer Address</div>
                <Field
                  name="builderOrDeveloperAddress"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{
                    "aria-label": "Builder or Developer Address",
                  }}
                />
                <div className="attribute-title">State</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="builderOrDeveloperState"
                    as="select"
                    aria-label="Builder or Developer state"
                  >
                    <option value=" ">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">ZIP Code</div>
                <Field
                  name="builderOrDeveloperZIPCode"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr5}
                  inputProps={{ maxLength: 5 }}
                  error={
                    touched.builderOrDeveloperZIPCode &&
                    errors.builderOrDeveloperZIPCode
                  }
                  helperText={
                    touched.builderOrDeveloperZIPCode &&
                    errors.builderOrDeveloperZIPCode
                  }
                  inputProps={{
                    "aria-label": "Zip Code",
                  }}
                />
                <div className="bottom-button-container">
                  <Button type="submit" className="next-button">
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{ fill: "red" }} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LendingDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  step: PropTypes.func.isRequired,
};

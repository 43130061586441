import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { MdFileUpload, MdClose, MdCheck } from "react-icons/md";
import "./Dropzone.css";
import { Button, ProgressBar } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Dropzone = (props) => {
  const fileInputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [disableSaveAndExit, setDisableSaveAndExit] = useState(false);
  const [retryFiles, setRetryFiles] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const addRetryFiles = (file) => {
    !retryFiles.includes(file) && setRetryFiles([ ...retryFiles, file ]);
  }

  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
    setDisplayFiles([...filteredArr]);
  }, [selectedFiles]);

  const [info, setState] = useState({
    notes: "",
  });

  const changeHandler = (e) => {
    setState({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    info.notes = encodeURI(info.notes);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const close = () => window.location.reload(false);

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    setRetryFiles([]);
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  // const handleFiles = (files) => {
  //   for (let i = 0; i < files.length; i++) {
  //     const file = new File(
  //       [files[i]],
  //       files[i].name.replace(/[/\\?%*:|"<>+ ]/g, "_"),
  //       { type: files[i].type }
  //     );
  //     if (validateFile(file)) {
  //       setSelectedFiles((prevArray) => [...prevArray, file]);
  //     } else {
  //       file["invalid"] = true;
  //       setSelectedFiles((prevArray) => [...prevArray, file]);
  //       setUnsupportedFiles((prevArray) => [...prevArray, file]);
  //     }
  //   }
  // };

  const handleFiles = (files) => {
    setDisableSaveAndExit(false);
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]["invalid"] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/vnd.ms-outlook",
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/zip",
      "application/octet-stream",
      "application/x-zip-compressed"
    ];
    if (validTypes.indexOf(file.type) === -1 || file.name?.length > 128) {
      if (file.name.endsWith(".msg") && file.name?.length < 128) {
        return true;
      } else {
        return false;
      }
    }
    if (
      file.name !== null &&
      file.name !== undefined &&
      file.name.endsWith(".msg")
    ) {
      return true;
    }
    return true;
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    const index = validFiles.findIndex((e) => e.name === name);
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setDisplayFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  const uploadFileToApigee = async (
    fileName,
    index,
    formData,
    note,
    persId,
    phaseId,
    projectId,
    file
  ) => {
    const uploadUrlRequest = {
      appCode: window.location.hostname == "eitcg.cdxms.devl-etss.aws.fanniemae.com" ? "eitcg-ess":  "eitcg" ,
      fileNames: [
        {
          filename: fileName,
          fileId: index + 1000,
        },
      ],
    };
    let fileUploadId;
    let currentValidFiles = validFiles;
    let currentFileIndex = validFiles.findIndex((obj) => {
      return obj.name === fileName;
    });
    axios
      .post(
        `/cdx-filupldproc/fileupload/processing/upload/url`,
        uploadUrlRequest
      )
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          currentValidFiles[currentFileIndex].percentage = 5;
          currentValidFiles[currentFileIndex].status = "init";
          currentValidFiles[currentFileIndex].startTime = new Date();
          currentValidFiles[currentFileIndex].displayStatus = "File Requested";
          currentValidFiles[currentFileIndex].retry = 0;
          setDisplayFiles([...currentValidFiles]);
          if (response.data[0].url) {
            fileUploadId = response.data[0].uploadReferenceId;
            axios
              .put(response.data[0].url, file, {
                headers: {
                  "content-type": file.type,
                  Accept: "*/*",
                  "Access-Control-Allow-Origin": "*",
                },
              })
              .then((response) => {
                if (response.status == 200 || response.status == 201) {
                  uploadStatusFromApigee(
                    fileUploadId,
                    note,
                    persId,
                    phaseId,
                    projectId,
                    fileName
                  );
                } else if (response.data && response.data.message) {
                  currentValidFiles[currentFileIndex].status = "error";
                  setDisplayFiles([...currentValidFiles]);
                }
              })
              .catch((err) => {
                currentValidFiles[currentFileIndex].status = "error";
                setDisplayFiles([...currentValidFiles]);
              });
          } else if (response.data && response.data.message) {
            currentValidFiles[currentFileIndex].percentage = 5;
            currentValidFiles[currentFileIndex].status = "error";
            setDisplayFiles([...currentValidFiles]);
          }
        }
      })
      .catch((err) => {
        currentValidFiles[currentFileIndex].status = "error";
        setDisplayFiles([...currentValidFiles]);
      });
  };

  const uploadStatusFromApigee = async (
    uploadReferenceId,
    note,
    persId,
    phaseId,
    projectId,
    fileName
  ) => {
    let currentValidFiles = validFiles;
    let currentFileIndex = validFiles.findIndex((obj) => {
      return obj.name === fileName;
    });
    let apiTimeout;
    if (uploadReferenceId) {
      axios
        .get(
          `/cdx-filupldproc/fileupload/processing/status/` + uploadReferenceId
        )
        .then((res) => {
          if (res.status == 200) {
            if (
              res.data.status == "REQUESTED" ||
              res.data.status == "SCANNED" ||
              res.data.status == "VIRUS_SCAN_STARTED"
            ) {
              currentValidFiles[currentFileIndex].percentage =
                res.data.status == "REQUESTED" ? 15 : 35;
              currentValidFiles[currentFileIndex].displayStatus = "Virus scan started";
              setDisplayFiles([...currentValidFiles]);
              apiTimeout = setTimeout(
                uploadStatusFromApigee(
                  uploadReferenceId,
                  note,
                  persId,
                  phaseId,
                  projectId,
                  fileName
                ),
                10000
              );
            } else if (
              res.data.status == "COMPLETED" ||
              res.data.status == "SCANNED"
            ) {
              clearTimeout(apiTimeout);
              currentValidFiles[currentFileIndex].percentage = 75;
              currentValidFiles[currentFileIndex].status =
                res.data.status == "ERROR" ? "error" : "init";
              currentValidFiles[currentFileIndex].displayStatus = "Scanning completed";
              setDisplayFiles([...currentValidFiles]);
              let fileUploadContext = {
                appCode: res.data.appCode,
                fileName: res.data.fileName,
                fileUploadId: res.data.fileUploadId,
                fileUploadNotes: note,
                letterId: 0,
                message: res.message,
                persId: persId,
                phaseId: phaseId,
                projectId: projectId,
                receivedId: res.data.userId,
                s3Bucket: res.data.s3Bucket,
                status: res.data.status,
                userId: res.data.userId,
              };
              uploadFileToSystem(fileUploadContext, fileName);
            } else if (res.data.status == "ERROR") {
              currentValidFiles[currentFileIndex].percentage = 75;
              currentValidFiles[currentFileIndex].displayStatus = "Virus found";
              setDisplayFiles([...currentValidFiles]);
              clearTimeout(apiTimeout);
            }
          } else {
            currentValidFiles[currentFileIndex].percentage = 75;
            setDisplayFiles([...currentValidFiles]);
            clearTimeout(apiTimeout);
          }
        })
        .catch((err) => {
          currentValidFiles[currentFileIndex].percentage = 75;
          currentValidFiles[currentFileIndex].status = "error";
          currentValidFiles[currentFileIndex].displayStatus = "Error while scanning";
          setDisplayFiles([...currentValidFiles]);
        });
    }
  };

  const uploadFileToSystem = async (uploadContext, fileName) => {
    let currentValidFiles = validFiles;
    let currentFileIndex = validFiles.findIndex((obj) => {
      return obj.name === fileName;
    });
    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/docStorage/postFileuploadContext`,
        uploadContext
      )
      .then((response) => {
        if (response.status == 200) {
          currentValidFiles[currentFileIndex].status = "completed";
          currentValidFiles[currentFileIndex].displayStatus = "Uploading";
          currentValidFiles[currentFileIndex].percentage = 100;
          currentValidFiles[currentFileIndex].endTime = new Date();
          setDisplayFiles([...currentValidFiles]);
          const passData = JSON.parse(sessionStorage.getItem("passData"));
          const request = {
            'id': null,
            'action': 'POST',
            'function': 'postFileuploadContext',
            'requestUser': passData?.user,
            'payload': JSON.stringify(uploadContext),
            'requestTime': currentValidFiles[currentFileIndex].startTime,
            'responseTime': currentValidFiles[currentFileIndex].endTime,
            'auditTimestamp': new Date()
          }
          axios
            .post(global.REACT_APP_API_ENDPOINT + `/auditdata/saveAuditTrail`, request)
            .then((response) => {
              if (currentValidFiles?.filter(x => x.status !== 'completed').length === 0) {
                setShowSnackbar(false);
              }
            })
            .catch(() => {
             
              currentValidFiles[currentFileIndex].status = "error";
              currentValidFiles[currentFileIndex].displayStatus = "";
              setDisplayFiles([...currentValidFiles]);
              console.log(currentValidFiles);
            });
          setDisplayFiles([...currentValidFiles]);
        }
      })
      .catch((err) => {
        currentValidFiles[currentFileIndex].retry += 1;
        setDisplayFiles([...currentValidFiles]);
        if (currentValidFiles[currentFileIndex].retry <= 10) {
          currentValidFiles[currentFileIndex].displayStatus = "Retrying";
          const mSecds = 10000 * currentValidFiles[currentFileIndex].retry;
          setTimeout(function () {
            uploadFileToSystem(uploadContext, fileName);
          }, mSecds);
        } else {
          currentValidFiles[currentFileIndex].status = "error";
          setDisplayFiles([...currentValidFiles]);
        }
      });
  };

  const uploadFiles = async (fileName) => { 
    setDisableSaveAndExit(true);
    let size = validFiles[0].size;
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    const captureSize = parseFloat((size / Math.pow(k, i)).toFixed(2)); 
    if (!showSnackbar) {
      if (sizes[i] === 'MB' && !validFiles[0].hasOwnProperty('status')) {
        if (captureSize > 200 ) {
          setShowSnackbar(true);
        }
      }
    }
    if (props.phasevalue === null && props.persvalue === null) {
      if (fileName !== null && fileName !== undefined) {
        const uploadIndex = validFiles.findIndex(x => x.name === fileName);
        const formData = new FormData();
        formData.append("file", validFiles[uploadIndex]);
        if (info.notes === "") {
          uploadFileToApigee(
            validFiles[uploadIndex].name,
            uploadIndex,
            formData,
            info.notes,
            props.persvalue,
            props.phasevalue,
            props.projectvalue,
            validFiles[uploadIndex]
          );
        } else {
          uploadFileToApigee(
            validFiles[uploadIndex].name,
            uploadIndex,
            formData,
            info.notes,
            props.persvalue,
            props.phasevalue,
            props.projectvalue,
            validFiles[uploadIndex]
          );
        }
      } else {
        for (let i = 0; i < validFiles.length; i++) {
          if (
            !displayFiles[i].hasOwnProperty("status") ||
            displayFiles[i].status === "error"
          ) {
            const formData = new FormData();
            formData.append("file", validFiles[i]);
            if (info.notes === "") {
              uploadFileToApigee(
                validFiles[i].name,
                i,
                formData,
                info.notes,
                props.persvalue,
                props.phasevalue,
                props.projectvalue,
                validFiles[i]
              );
            } else {
              uploadFileToApigee(
                validFiles[i].name,
                i,
                formData,
                info.notes,
                props.persvalue,
                props.phasevalue,
                props.projectvalue,
                validFiles[i]
              );
            }
          }
        }
      }
      
    } else if (props.persvalue === null) {
      let size = validFiles[0].size;
      const k = 1024;
      const space = "%20";
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      if (fileName !== null && fileName !== undefined) {
        const uploadIndex = validFiles.findIndex(x => x.name === fileName);
        const formData = new FormData();
        formData.append("file", validFiles[uploadIndex]);
        if (info.notes === "") {
          uploadFileToApigee(
            validFiles[uploadIndex].name,
            uploadIndex,
            formData,
            info.notes,
            props.persvalue,
            props.phasevalue,
            props.projectvalue,
            validFiles[uploadIndex]
          );
        } else {
          uploadFileToApigee(
            validFiles[uploadIndex].name,
            uploadIndex,
            formData,
            info.notes,
            props.persvalue,
            props.phasevalue,
            props.projectvalue,
            validFiles[uploadIndex]
          );
        }
      } else {
        for (let i = 0; i < validFiles.length; i++) {
          if (
            !displayFiles[i].hasOwnProperty("status") ||
            displayFiles[i].status === "error"
          ) {
            const formData = new FormData();
            formData.append("file", validFiles[i]);
            if (info.notes === "") {
              uploadFileToApigee(
                validFiles[i].name,
                i,
                formData,
                info.notes,
                props.persvalue,
                props.phasevalue,
                props.projectvalue,
                validFiles[i]
              );
            } else {
              uploadFileToApigee(
                validFiles[i].name,
                i,
                formData,
                info.notes,
                props.persvalue,
                props.phasevalue,
                props.projectvalue,
                validFiles[i]
              );
            }
          }
        }
      }
    } else {
      let size = validFiles[0].size;
      const k = 1024;
      const space = "%20";
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      const captureSize =
        parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
      if (fileName !== null && fileName !== undefined) {
        const uploadIndex = validFiles.findIndex(x => x.name === fileName);
        const formData = new FormData();
        formData.append("file", validFiles[uploadIndex]);
        if (info.notes === "") {
          uploadFileToApigee(
            validFiles[uploadIndex].name,
            uploadIndex,
            formData,
            info.notes,
            props.persvalue,
            props.phasevalue,
            props.projectvalue,
            validFiles[uploadIndex]
          );
        } else {
          uploadFileToApigee(
            validFiles[uploadIndex].name,
            uploadIndex,
            formData,
            info.notes,
            props.persvalue,
            props.phasevalue,
            props.projectvalue,
            validFiles[uploadIndex]
          );
        }
      } else {
        for (let i = 0; i < validFiles.length; i++) {
          if (
            !displayFiles[i].hasOwnProperty("status") ||
            displayFiles[i].status === "error"
          ) {
            const formData = new FormData();
            formData.append("file", validFiles[i]);
            if (info.notes === "") {
              uploadFileToApigee(
                validFiles[i].name,
                i,
                formData,
                info.notes,
                props.persvalue,
                props.phasevalue,
                props.projectvalue,
                validFiles[i]
              );
            } else {
              uploadFileToApigee(
                validFiles[i].name,
                i,
                formData,
                info.notes,
                props.persvalue,
                props.phasevalue,
                props.projectvalue,
                validFiles[i]
              );
            }
          }
        }
      }
    }
    (fileName !== null && fileName !== undefined) && setRetryFiles(retryFiles.filter(x => x !== fileName));
  };

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <div className="content">
      <form>
        <div className="notes-input-container">
          <div className="notes-title">Notes (200 character limit)</div>
          <input
            className="notes-input"
            placeholder="Enter Notes"
            maxLength="500"
            type="text"
            name="notes"
            value={info.notes}
            onChange={changeHandler}
          />
        </div>
        <div className="content-container">
          <div className="content-title">Attach Documents</div>
          <div
            className="drop-container"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
          >
            <div className="drop-message">
              <div className="upload-icon">
                <MdFileUpload className="upload-icon-md" />
              </div>
              <div className="upload-text">Drag and Drop here</div>
              <div className="upload-text">or</div>
              <div className="upload-text-button">Browse Files</div>
            </div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
          </div>
          <div className="file-display-container">
            {displayFiles.map((data, i) => {
              const containRetry = retryFiles.includes(data.name);
              return (
                <div className="file-status-bar" key={i}>
                  <div>
                    <div className="file-type-logo"></div>
                    <div className="file-type">{fileType(data.name)}</div>
                    <span
                      className={`file-name ${data.invalid ? "file-error" : ""}`}
                    >
                      {data.name.length > 30
                        ? data.name.substring(0, 25) + "..."
                        : data.name}
                    </span>
                  </div>
                  {!data.status ? (
                    <>
                      <div
                        className="file-remove"
                        onClick={() => removeFile(data.name)}
                      >
                        <MdClose />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.status === "init" ? (
                    <>
                      <div className="file-remove"> </div>
                      <div className="progress-container">
                        <ProgressBar now={data.percentage} />
                        <p className="file-status">{data.displayStatus}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.status === "completed" ? (
                    <>
                      <div className="file-done">
                        <MdCheck />
                      </div>
                      <div className="progress-container">
                        <ProgressBar variant="success" now={data.percentage} />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.status === "error" ? (
                    <>
                      <div className="file-remove">{!containRetry &&
                        <>Error <Button 
                          style={{ height: "unset", width: "unset", padding: "unset" }}
                          onClick={e => {
                              e.preventDefault();
                              addRetryFiles(data.name);
                              uploadFiles(data.name);
                            }}>Retry</Button>
                        </>
                        }</div>
                      <div className="progress-container">
                        {containRetry ? <ProgressBar now={0}/> : <ProgressBar variant="danger" now={data.percentage} />}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
          </div>
        </div>

        <div className="upload-button-container">
          {props.page === "pers" ? (
            props.type == "established" ? (
              <Button
                className="close-button"
                href={`/project/pers-establish/${props.persvalue}`}
              >
                Cancel
              </Button>
            ) : (
              <Button
                className="close-button"
                href={`/project/pers/${props.persvalue}`}
              >
                Cancel
              </Button>
            )
          ) : props.isAttch ? (
            <Button
              className="close-button"
              onClick={() => props.decStepCounter()}
            >
              Back
            </Button>
          ) : (
            <Button className="close-button" onClick={close}>
              Close
            </Button>
          )}

          {unsupportedFiles.length === 0 && validFiles.length === 0 ? (
            <Button className="upload-button" disabled>
              Save and Continue
            </Button>
          ) : (
            ""
          )}

          {(unsupportedFiles.length === 0 && validFiles.length) ? (
            <Button
              className="upload-button"
              type="button"
              // onClick={submitHandler}
              onClick={() => uploadFiles()}
              disabled={disableSaveAndExit}
            >
              Save and Continue{" "}
            </Button>
          ) : (
            ""
          )}

          {unsupportedFiles.length ? (
            <p className="error">
              Invalid File Format; Check type, size, or name length.
            </p>
          ) : (
            ""
          )}
        </div>
      </form>
      <Snackbar open={showSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="info"
          sx={{ width: '100%' }}
         >
          Files >200 MB will take longer than 8 minutes to upload.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Dropzone;
export const SplAssmtReasons = [
  { label: "Remediate Critical Repairs", value: "Remediate Critical Repairs" },
  { label: "Increase Reserve Funds", value: "Increase Reserve Funds" },
  { label: "Repay Loans", value: "Repay Loans" },
  { label: "Insurance Premium/Deductible", value: "Insurance Premium/Deductible" },
  { label: "Capital Improvements", value: "Capital Improvements" },
  { label: "Routine Maintenance", value: "Routine Maintenance" },
  { label: "Mandatory Code Enhancements", value: "Mandatory Code Enhancements" },
  { label: "Natural Disaster Repairs", value: "Natural Disaster Repairs" },
  { label: "Engineering or Inspection Report Fees", value: "Engineering or Inspection Report Fees" },
  { label: "Litigation Fees", value: "Litigation Fees" },
  { label: "Other", value: "Other" }
];


export const SplAssmtReasonsNew = ["Remediate Critical Repairs","Increase Reserve Funds","Repay Loans"];
export const MaxSplAssmt = 5;
export const policyChangeDate = "08/15/2023"
export const MaterialOptions = [
  { label: "Roof", value: "Roof" },
  { label: "Stairwell", value: "Stairwell" },
  { label: "Electrical Systems", value: "Electrical Systems" },
  { label: "Foundation", value: "Foundation" },
  { label: "Balconies", value: "Balconies" },
  { label: "Parking Structures", value: "Parking Structures" },
  { label: "Elevators", value: "Elevators" },
  { label: "Load Bearing Structures", value: "Load Bearing Structures" },
  { label: "Plumbing Systems", value: "Plumbing Systems" },
  { label: "Seawall", value: "Seawall" },
  { label: "Waterproofing", value: "Waterproofing" },
  { label: "Amenities", value: "Amenities" },
  { label: "Other", value: "Other" }
];

export const IncompleteData = {
  "guideEligibilityAndProjectInfoAndIds": {
    "lenderId": "12346",
    "projectId": "409019",
    "phaseId": "proj",
    "createdBy": "r0usct",
    "guideEligibilityAndProjectInfo": {
      "geHotelMotelResortInd": "Yes",
      "geTimeshareCount": "12",
      "geHouseBoatInd": "No",
      "geMulLivingSpacesInd": "No",
      "geSplitOwnershipInd": "No",
      "geNIncIncomeAbovePmtdInd": "No",
      "gePerTotIncNonIncIncome": "",
      "geIncomeFromPermittedSource": "",
      "geUnpaidCommonExpAsmtsInd": "No",
      "geCareFacilityInd": "No",
      "gePayMemshpOrLeaseInd": "No",
      "getCmplnHOAOwnshpInd": "No",
      "geEnvHazardInd": "No",
      "geRtFtRefslOrReSlRstnsInd": "No",
      "piYearBuilt": "2015",
      "piConvtdProjInd": "No",
      "piGutRehabInd": "",
      "piYearConvtd": "",
      "piAddnPhsOrAnnxInd": "No",
      "piCntlHOATurnOverInd": "No",
      "piDtTurndOver": "",
      "piCommnAreasCompltInd": "No"
    }
  },
  "projectPresaleAndInvestor": {
    "updatedBy": "r0usct",
    "projectPresaleInvstr": {
      "ppActivePendingSpAssmntsInd": "",
      "ppDevOwndUntsRentalHoldBackInd": "No",
      "ppDevOwnedUnits": "",
      "ppMaintFeesDevOwndCurrentInd": "",
      "ppOwnerOccupiedUnits": "20",
      "ppPreSaleConveyPercntge": "",
      "ppSecondHomes": "20",
      "ppTotalUnits": "100",
      "ppUnitsComplete": "20",
      "ppUnitsConveyed": "20",
      "ppUnitsRented": "20"
    }
  },
  "phasePresaleAndInvestor": {
    "updatedBy": "r0usct",
    "phasePresaleInvstr": {
      "phpOwnerOccupiedUnits": "",
      "phpPhases": "",
      "phpPhasesAnnexedToProject": "",
      "phpSecondHomes": "",
      "phpUnits": "",
      "phpUnitsComplete": "",
      "phpUnitsCurrPhaseAndPrPhase": "",
      "phpUnitsForSale": "",
      "phpUnitsRented": "",
      "phpUnitsSold": "",
      "phpUnitsSoldOwnerOccpSecondHomeRsdn": ""
    }
  },
  "newProjectEligibility": {
    "updatedBy": "r0usct",
    "newProjectEligibility": {
      "npeAssrArrgReviewedInd": "No",
      "npeFinConExcessInd": "No",
      "npeLglDocsComplyInd": "No",
      "npeMasterAsscInd": "No",
      "npePayMnthyAssesLndrRevwPlanInd": ""
    }
  },
  "projectOwnership": {
    "updatedBy": "r0usct",
    "projectOwnership": {
      "poLeaseComplyInd": "",
      "poLeaseholdInd": "No",
      "poOwnersHaveSoleOwnshpInd": "No",
      "poSnglOwnMoreThanOneInd": "No",
      "poUnitsSnglOwn": ""
    }
  },
  "lcFinancials": {
    "updatedBy": "r0usct",
    "financials": {
      "fnoOfSpAssmts": 1,
      "fresStudyReqInd": "",
      "fmetDefInd": "No",
      "fmetDefElem": [],
      "fotherReasonMetDef": "",
      "fleakRepairInd": "No",
      "funitsHOAExpnPastDue": "100",
      "fexpDateAnlBudget": "2023-08-10",
      "fbugtAnlIncome": 3391392,
      "famtExclItems": 100000,
      "fbugtAnlReserves": 339140,
      "fdefdMntInd": "No",
      "fsplAssmtsInd": "",
      "fttlSpAssmts": "",
      "fspAssmts": [
        {
          "fttlSpAssmts": null,
          "freasonsSpAssmts": [
            "Remediate Critical Repairs",
            "Other"
          ],
          "fotherReasonSpAssmts": "test",
          "fremediateRepairInd": "Yes",
          "fnoOfUnitsDelinquent": 12
        }
      ],
      "fphyDetInd": "No",
      "fphyDetEle": [],
      "fotherReasonPhyDet": "",
      "finspFailInd": "No",
      "funfundRepInd": "No"
    }
  },
  "lcLitigation": {
    "updatedBy": "r0usct",
    "litigation": {
      "lcommerclSpaceInd": "No",
      "linsurancCvgInd": "No",
      "llitigationInd": "No",
      "lminrMttrLitgtnInd": "",
      "lnonResSqFtg": 0,
      "lsafetyLitgtnInd": "",
      "lttlSqFtg": ""
    }
  }
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import "../AddEstablishedPers.css";
import axios from "axios";
import AutoComplete from "../../SharedComponents/AutoComplete/AutoComplete";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const LenderDetails = ({ formData, setFormData, nextStep, step }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function validateDate(value) {
    if (!value) {
      return "Required Date";
    }    

    const inputDate = new Date(value);

    if (inputDate.toString() === "Invalid Date") {
      return "Invalid date format";
    }

    const minDate = new Date("1776-01-01");

    if (inputDate < minDate) {
      return "Date cannot be before Jan 1, 1776."
    }

    return undefined;
  }

  function validateText(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateCity(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value)) {
      error = "Invalid City";
    }
    return error;
  }

  function validateNumNoStr(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNum9(value) {
    let error;
    if (value && !/^((?!(0))[0-9]{9})$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateState(value) {
    let error;
    if (value === "" || !value) {
      error = "Required";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required Email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid Email Address";
    }
    return error;
  }

  function validateNumNoStr5(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (value && !/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  let [stateData, setStateData] = React.useState("");
  const fetchStateData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then((response) => {
        setStateData(response.data);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);
  const stateResult = Object.keys(stateData).map((key) => stateData[key]);
  const stateList = stateResult.map((state) => {
    return { value: state, label: state };
  });

  function validateText(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [lenderIdError, setLenderIdError] = useState("");
  const [afLenderId, setAfLenderId] = useState("");
  const [afLenderName, setAfLenderName] = useState("Default");
  const [lenderIdAutoSuggestions, setlenderIdAutoSuggestions] = useState([]);
  const searchHandler = (state, selectedOption) => {
    if (selectedOption) {
      setAfLenderId(selectedOption.lenderId);
      setAfLenderName(selectedOption.lenderName);
    } else {
      let x = `${state}`;
      const newState = x.split(",").reduce((a, c, i) => {
        a[`s${i + 1}`] = c;
        return a;
      }, {});
      setAfLenderId(newState.s1);
      setAfLenderName(newState.s2);
    }
  };
  const autoCompleteChangeHandler = (flag, event) => {
    const value = flag ? "" : event.target.value;
    setAfLenderId(value);
    if (event.target.value.length >= 3) {
      const condoPayload = value;

      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/lender/searchLenders/${condoPayload}`
        )
        .then((response) => {
          const data = response.data;
          const slicedData = data.slice(0, 10);
          setlenderIdAutoSuggestions(slicedData);
        })
        .catch((error) => {
          setlenderIdAutoSuggestions([]);
        });
    }
  };

  console.log(formData);
  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          values.lenderSSN = afLenderId;
          values.lenderName = afLenderName;
          setFormData(values);
          console.log("values >>> ", values);
          if (afLenderId === "") {
            setLenderIdError("Required");
          } else {
            nextStep();
          }
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add Established PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 1 of 9 - Lender Details
                </div>
                <div className="attribute-title">
                  Received Date<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="receivedDate"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    "aria-label": "Received Date",
                  }}
                  validate={validateDate}
                  error={touched.receivedDate && errors.receivedDate}
                  helperText={touched.receivedDate && errors.receivedDate}
                />
                <div className="attribute-title">
                  Assigned Risk Analyst<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="assgndRiskAnalyst"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.assgndRiskAnalyst && errors.assgndRiskAnalyst}
                  helperText={
                    touched.assgndRiskAnalyst && errors.assgndRiskAnalyst
                  }
                  inputProps={{
                    "aria-label": "Assigned Risk Analyst",
                  }}
                />
                {/* <div className="attribute-title">
                  Lender<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.lenderName && errors.lenderName}
                  helperText={touched.lenderName && errors.lenderName}
                />
                <div className="attribute-title">
                  Lender Seller/Service Number
                  <span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderSSN"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 32 }}
                  error={touched.lenderSSN && errors.lenderSSN}
                  helperText={touched.lenderSSN && errors.lenderSSN}
                /> */}
                <div className="attribute-title">
                  Lender Seller/Service Number
                  <span style={{ color: "red" }}>*</span>
                </div>
                <AutoComplete
                  autoCompleteClassName={"attribute-input"}
                  options={[...lenderIdAutoSuggestions]}
                  id={"afLenderId"}
                  name="lenderId"
                  value={afLenderId || ""}
                  changeHandler={autoCompleteChangeHandler}
                  searchHandler={searchHandler}
                  type="lenderId"
                  inputProps={{
                    "aria-label": "Lender Seller/Service Number",
                    autoComplete: "off",
                  }}
                />
                <div
                  style={{
                    color: "#ff0000",
                  }}
                >
                  {lenderIdError}
                </div>
                <div className="attribute-title">
                  Lender Address<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderAddress"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.lenderAddress && errors.lenderAddress}
                  helperText={touched.lenderAddress && errors.lenderAddress}
                  inputProps={{
                    "aria-label": "Lender Address",
                  }}
                />
                <div className="attribute-title">
                  Lender City<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderCity"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateCity}
                  inputProps={{ maxLength: 128 }}
                  error={touched.lenderCity && errors.lenderCity}
                  helperText={touched.lenderCity && errors.lenderCity}
                  inputProps={{
                    "aria-label": "Lender City",
                  }}
                />
                <div className="attribute-title">
                  Lender State<span style={{ color: "red" }}>*</span>
                </div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="lenderState"
                    as="select"
                    validate={validateState}
                    aria-label="Lender State"
                  >
                    <option value="">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {errors.lenderState && touched.lenderState ? (
                    <div
                      className="pers-error-message"
                      style={{ color: "red" }}
                    >
                      {errors.lenderState}
                    </div>
                  ) : null}
                </div>
                <div className="attribute-title">
                  Lender Zip Code<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="lenderZip"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr5}
                  inputProps={{ maxLength: 5 }}
                  error={touched.lenderZip && errors.lenderZip}
                  helperText={touched.lenderZip && errors.lenderZip}
                  inputProps={{
                    "aria-label": "Lender Zip",
                  }}
                />
                <div className="attribute-title">
                  Requester<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="requesterName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateText}
                  inputProps={{ maxLength: 200 }}
                  error={touched.requesterName && errors.requesterName}
                  helperText={touched.requesterName && errors.requesterName}
                  inputProps={{
                    "aria-label": "Requester Name",
                  }}
                />
                <div className="attribute-title">
                  Requester Email<span style={{ color: "red" }}>*</span>
                </div>
                <Field
                  name="requesterEmail"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateEmail}
                  inputProps={{ maxLength: 200 }}
                  error={touched.requesterEmail && errors.requesterEmail}
                  helperText={touched.requesterEmail && errors.requesterEmail}
                  inputProps={{
                    "aria-label": "Requester Email",
                  }}
                />
                <div className="bottom-button-container">
                  <Button type="submit" className="next-button">
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{ fill: "red" }} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LenderDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  step: PropTypes.func.isRequired,
};

import React from 'react';
import PhaseRestrictions from './PhaseRestrictions';


const Restrictions = ({ list }) => {
  let restrictionsList = <h3>Loading...</h3>;

  if (list) {
    restrictionsList = list.map((m, i) => <PhaseRestrictions key={i} item={m} />);
  }

  return (
    <div >
        {restrictionsList}
    </div>
  )
}
export default Restrictions;
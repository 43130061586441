import React from "react";
import { Grid, Button } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const CancelModal = ({
  handleCancelCancel,
  persId,
  persType,
  showCancel,
  title,
}) => {
  let path = window.location.hostname;

  return (
    <Modal
      show={showCancel}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleCancelCancel}
      centered
    >
      <Modal.Header className="the-modal-header" closeButton>
        <FaInfoCircle
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "15px",
            width: "15px",
          }}
        />
        <Modal.Title className="the-modal-title">{title ? title: 'Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
        <div style={{ marginTop: "15px" }}>
          All progress within this form will be lost. Are you sure you want to
          cancel?
        </div>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer" style={{justifyContent:"space-between", paddingLeft:25}}>
        <Button className="go-back-button" style={{matginLeft:"15px"}} onClick={handleCancelCancel}>
          Go Back
        </Button>
        {persType == "New" ? (
          <Link
            to={`/project/pers/${persId}`}
            style={{ margin: "16px" }}
            replace
          >
            {/* <Button className="cancel-modal-button">Close</Button> */}
            <Button
              className="delete-modal-button"
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }}
            >
              Yes
            </Button>
          </Link>
        ) : (
          <Link
            to={`/project/pers-establish/${persId}`}
            style={{ margin: "16px" }}
            replace
          >
            <Button
              className="delete-modal-button"
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }}
            >
              Yes
            </Button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;

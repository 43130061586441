import React from 'react';
import IndividualDecision from './IndividualDecision';
import './Decision.css';


const Decision = ({ list, persId, reasonCodes }) => {
  let decision = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    decision = list.map((m, i) => <IndividualDecision key={i} item={m} persId={persId} reasonCodes={reasonCodes}/>);
  }

  return (
    <div className="pdf-container">
        <div className="pdf-header">
            <div className="pdf-header-name">Date</div>
            <div className="pdf-header-name">Entire Project or Phases</div>
            <div className="pdf-header-name">Reviewed By</div>
        </div>
        <div className="pdf-body">
            {decision}
        </div>
    </div>
  )
}
export default Decision;
import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import "./DeleteDuplicate.css";
import axios from "axios";
import DuplicatePhaseList from "./DuplicatePhaseList";
import MultiselectTwoSide from "react-multiselect-two-sides";

const DeletePhaseDuplicate = ({decrementCounter , incrementCounter,setFormData, formData}) => {
  const [duplicatePhaseCertification, setDuplicatePhaseCertification] = React.useState(formData.duplicatePhaseCertification);

  const onSubmitCalled = (e) => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let _formData= formData;

    _formData.updateBy = passData?.user
    setFormData(_formData);
    setTimeout(() => {
      if (formData.duplicatePhaseCertification > 0) {
        incrementCounter();
      } else {
        incrementCounter();
        incrementCounter();
      }
    }, 300);
  };

  return (
    <Grid item xs={12}>
      <div className="delete-duplicate-page">
       <Grid item xs={12}>
       <div className="section-title">
       Step 2: Delete duplicate phase configuration
        </div>
        </Grid>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <DuplicatePhaseList formData={formData} setFormData={setFormData} duplicatePhases={formData.duplicatePhases} targetPhases={formData.targetPhases} phasedropdownOption={formData.phasedropdownOption} setDuplicatePhaseCertification={setDuplicatePhaseCertification} duplicatePhaseForm={formData.duplicatePhaseForm} />
          </Grid>
        </Grid>
        { formData.duplicatePhaseForm && formData.duplicatePhaseForm.length > 0 &&
                      <Grid item xs={12} sm={12} md={12}>
                      <Grid container justify="flex-end">
                        <Button
                          type="button"
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(17, 118, 181)",
                            borderRadius: "4px",
                            boxShadow:
                              "rgb(0 0 0 / 37%) 0px 0.4px 0.6px 0px, rgb(10 100 157) 0px 0.1px 0px 0.4px",
                            height: "40px",
                            width: "150px",
                            marginRight: "10px",
                            textTransform: "capitalize"
                          }}
                          onClick={e => decrementCounter()}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(17, 118, 181)",
                            borderRadius: "4px",
                            boxShadow:
                              "rgb(0 0 0 / 37%) 0px 0.4px 0.6px 0px, rgb(10 100 157) 0px 0.1px 0px 0.4px",
                            height: "40px",
                            width: "150px",
                            textTransform: "capitalize"
                          }}
                          onSubmit={e => onSubmitCalled(e)}
                          onClick={e => onSubmitCalled(e)}
                          form="addProjectForm"
                        >
                          Next
                        </Button>
      
                      </Grid>
                    </Grid>
        }
      </div>
    </Grid>
  );
};

export default DeletePhaseDuplicate;

import React, { useState } from "react";
import "./PersLetter.css";
import { FaTrashAlt } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

const IndividualPersLetter = ({ item }) => {
  const downloadFile = () => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/docStorage/serveImage/${item.docMapId}`,
        {
          headers: { "Access-Control-Allow-Origin": "*" },
          responseType: 'blob'
        }
      )
      .then((response) => {
        if (response?.data) {
          console.log(response.data);
          const url = window.URL.createObjectURL(
            new Blob([response.data]),
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            'download',
            'Letter.pdf',
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="indy-pdf-container">
      <div className="pdf-link-date-container">
        <div className="pdf-link" onClick={downloadFile}>
          {item.fileName}
        </div>
        <div className="pdf-date-size">
          {item.fileSize} | {item.uploadedDate}
        </div>
      </div>
      <div className="letter-body-header">{item.phaseProjectName}</div>
      <div className="letter-body-header">{item.userName}</div>
      <div className="letter-body-header">{item.uploadedDate}</div>
    </div>
  );
};

export default IndividualPersLetter;

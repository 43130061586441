import React from 'react';
import DuplicatePhaseForm from './DuplicatePhaseForm';
const DuplicatePhaseList = ({ formData, setFormData, duplicatePhases, targetPhases, phasedropdownOption, setDuplicatePhaseCertification}) => {
  let duplicatePhaseList = <h3>Loading...</h3>;

  if (duplicatePhaseList) {
    duplicatePhases = duplicatePhases.filter((obj1, i, arr) => 
      arr.findIndex(obj2 => 
        JSON.stringify(obj2) === JSON.stringify(obj1)
      ) === i
    );
    duplicatePhaseList = duplicatePhases.map((data, index) => <DuplicatePhaseForm id={"duplicatePhase"+index} init={"true"} formData={formData} setFormData={setFormData} index ={index}   phase= {data} targetPhases = {targetPhases} phasedropdownOption= {phasedropdownOption} setDuplicatePhaseCertification= {setDuplicatePhaseCertification} />);
  }

  return (
    <div>
        {duplicatePhaseList}
    </div>
  )
}
export default DuplicatePhaseList;
import React, {useState} from "react";
import { Radio, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";
import FormStepper from "../stepper";
import CancelModal from "../../../../ProjectStatusFinal/cancel-modal";






export const Page1 = ({ formData, projectInfo, projectArray, phaseArray, setFormData, nextStep, step }) => {

    const [showCancel, setShowCancel] = useState(false);

    function openCancelPopup(flag) {
        setShowCancel(flag);
      }
    
      function handleCancelCancel() {
        setShowCancel(false);
      }

    function validateRadio(value) {
        let error;
        console.log(value)
        console.log(formData.checked)
        if(!value) {
            error = "Please Select an Entire Project or Phase";
        } 
        return error;
      }

      function validateCheck(value) {
        let error;
        if (value.length === 0) {
          error = "Please Select Phases";
        }
        return error;
      }
    
    const phaseMap = phaseArray.map((anObjectMapped, index) => { 
        return (
            <div role="group" aria-labelledby="checkbox-group" className="letter-checkbox-container">
                <label>
                    <Field type="checkbox" name="checked" validate={validateCheck} value={`${index}`} key={`${anObjectMapped.phaseInfo.phaseId}`} />
                    <label className="checkbox-phase-value">{anObjectMapped.phaseInfo.phaseDisplayDet}</label>
                </label>
            </div>
        );
    })
    return (
        <>
        <CancelModal
            showCancel={showCancel}
            handleCancelCancel={handleCancelCancel}
            persId={formData.persId}
            persType={formData.persType}
            title={ 'Cancel Conditional/Avialable Letter'}
          />
            <Formik
                initialValues={formData}
                onSubmit={values => {
                    setFormData(values);
                    nextStep();
                }}
            >
            {({ values, errors, touched }) => (
            <Form className="parent-form">
                <FormStepper step={step} />
                <div className="parent-field-container">
                    <div className="field-container">
                        <div className="top-title-container">
                            <div className="big-title">Generate Letter: Final Approval</div>
                            <div className="small-title">
                            PERS ID: {projectInfo.persId}
                            </div>
                        </div>
                        <div className="field-lower-container">
                            <div className="section-title">
                            Step 1: Select Project or Phases for the Letter
                            </div>
                            <div className="field-info-container">
                                <div className="field-value">{projectInfo.projectName}</div>
                                <div className="field-value">{projectInfo.projectAddress}</div>
                                <div className="field-value">{projectInfo.projectCity},&nbsp; {projectInfo.projectState}&nbsp;{projectInfo.projectZip5}</div>
                                <div className="field-value">Project ID:&nbsp;{projectInfo.projectId}</div>
                            </div>
                            <div>
                                <div>
                                    <div
                                    role="group"
                                    aria-labelledby="radio-group"
                                    className="radio-box"
                                    >
                                        {projectArray !== null ? (
                                        <label>
                                            <Field
                                            name="entireProjectInd"
                                            as={Radio}
                                            type="radio"
                                            variant="outlined"
                                            validate={validateRadio}
                                            value="true"
                                            />
                                            Entire Project
                                        </label>
                                        ): (<></>)}
                                        {phaseArray.length > 0 ? (
                                        <label>
                                            <Field
                                            name="entireProjectInd"
                                            as={Radio}
                                            type="radio"
                                            variant="outlined"
                                            validate={validateRadio}
                                            value="false"
                                            />
                                            Phases
                                        </label> 
                                        ): (<></>)}                                       
                                        {values.entireProjectInd === "false" ? (<>{phaseMap}</>):(<></>)}
                                        {errors.entireProjectInd && touched.entireProjectInd && <div className="radiobtn-letter-error">{errors.entireProjectInd}</div>}
                                        {errors.checked && touched.checked && <div className="radiobtn-letter-error">{errors.checked}</div>}
                                    </div>
                                </div>
                                <div className="bottom-button-container">
                                    <Button type="submit" className="next-button">
                                        Next Section
                                        <FaLongArrowAltRight />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="right-cancel-container">
                        <button
                        className="delete-modal-button"
                        style={{
                            float: 'right',
                            border: 0
                        }}
                        onClick={() => openCancelPopup(true)}
                        >
                        <FaTimes
                            className="edit-profile-icon"
                            style={{
                            marginRight: 5
                            }}
                        />{" "}
                        Cancel 
                        </button>
                    </div>
            </Form>
            )}
            </Formik>
        </>
    )

}
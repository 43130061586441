import React from "react";
import "./components/ProjectProfile/ProfileContainer.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Projects from "./components/ProjectProfile/Projects";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import { Landing } from "./components/Search/Landing";
import Footer from "./components/Footer/Footer";
import SearchResults from "./components/SearchResults/SearchResults";
import IneligibilityList from "./components/Ineligibility/IneligibilityList";
import { Settings } from "./components/Settings/Settings.js";
import { NoMatch } from "./components/NoMatch/NoMatch";
import ProjectsList from "./components/ProjectsList/ProjectsList";
import Profile from "./components/Profile/Profile";
import Project from "./components/Project/Project";
import BannerCreator from "./components/BannerCreator/BannerCreator";
import BannerHistory from "./components/BannerHistory/BannerHistory.jsx";
import Pers from "./components/Pers/Pers";
import Edit from "./components/Edit/Edit";
import AddNewProject from "./components/AddNewProject/AddNewProject";
import AddNewPhase from "./components/AddNewPhase/AddNewPhase";
import { AddNewPers } from "./components/AddNewPers/AddNewPers";
import { AddNewPersDecision } from "./components/AddNewPersDecision/AddNewPersDecision";
import PersEstablish from "./components/Pers-Establish";
import { AddEstablishedPers } from "./components/AddEstablishedPers/AddEstablishedPers.js";
import { Unauthorized } from "./components/Unauthorized/Unauthorized";
import ErrorPage from "./error-page";
import { ConditionalAvailable } from "./components/Letters/Conditional/Available/ConditionalAvailable";
import { ConditionalUnavailable } from "./components/Letters/Conditional/Unavailable/ConditionalUnavialable";
import { FinalApproval } from "./components/Letters/Final/Approval/FinalApproval";
import { IneligibleLetter } from "./components/Letters/Ineligible/Letter/IneligibleLetter";
import { SuspensionLetter } from "./components/Letters/Suspension/Letter/SuspensionLetter";
import { ChildPhase } from "./components/ChildPhase/ChildPhase";
import ChildPhasePage from "./components/ChildPhase/ChildPhasePage/ChildPhasePage";
import { Certification } from "./components/Certification/Certification";
import { ViewCertification } from "./components/ViewCertification/ViewCertification";
import { ViewPrintCertification } from "./components/ViewCertification/ViewPrintCertification";
import { Help } from "./components/Help/Help";
import { Reports } from "./components/Reports/Reports";
import RouteChangeTracker from "./RouteChangeTracker";
import WebFont from "webfontloader";
import { SessionTimeOutHandler } from "./components/SessionTimeOutHandler/SessionTimeOutHandler";
import { SessionErrorPage } from "./components/SessionTimeOutHandler/SessionErrorPage";
import ActiveBanner from "./components/ActiveBanner/ActiveBanner";
import ProjectErrorPage from "./components/Project/ProjectErrorPage";
import Admin from "./components/Admin/Admin";
import { Environments , DynatraceUrl} from "./components/shared/constants/environment";

WebFont.load({
  google: {
    families: ["Source Sans Pro", "sans-serif"],
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogueOpen: false,
    };
  }

  dialogueCallback = (flag) => {
    console.log(flag, "flag");
    this.setState({ dialogueOpen: flag });
  };

  getDynatraceURL = (env) => {
    if (env === Environments.local) {
      return DynatraceUrl.local;
    } else if (env === Environments.devl || env === Environments.devlExternal) {
      return DynatraceUrl.devl;
    } else if (env === Environments.test || env === Environments.testExternal) {
      return DynatraceUrl.test;
    } else if (env === Environments.acpt || env === Environments.acptExternal) {
      return DynatraceUrl.acpt;
    } else if (env === Environments.clve || env === Environments.clveExternal) {
      return DynatraceUrl.clve;
    }else if(env === Environments.prod || env === Environments.prodExternal) {
      return DynatraceUrl.prod;
    } else {
      return DynatraceUrl.local;
    }
  }
  
  configDynatrace = () => {

    const dynatraceScript = document.createElement('script');
    const dynatraceURL = this.getDynatraceURL(window.location.hostname);
    dynatraceScript.setAttribute('src', dynatraceURL);
    dynatraceScript.setAttribute('crossorigin', 'anonymous');
    document.body.appendChild(dynatraceScript);
    
  }
  componentDidMount() {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    this.configDynatrace();
    if (passData && passData.hasOwnProperty('user')) {
      window['dynatraceUserName'] = passData.user;
    }
  }

  HeadBar = () => {
    return (
      <React.Fragment>
        <NavigationBar dialogueCallback={this.dialogueCallback} />
      </React.Fragment>
    );
  };

  HandleSessionTime = () => {
    return (
      <React.Fragment>
        <SessionTimeOutHandler />
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <ActiveBanner />
        <RouteChangeTracker />

        <Router>
          <Route path="/unauthorized" component={Unauthorized} />
          {this.HeadBar()}
          {this.HandleSessionTime()}
          <Switch>
            <Route path="/projects" component={Projects} />
          </Switch>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/condogo/*" component={Landing} />
            <Route exact path="/condoproject" component={Landing} />
            <Route exact path="/search/:search" component={SearchResults} />
            <Route path="/projects-list" component={ProjectsList} />
            <Route path="/IneligibilityList" component={IneligibilityList} />
            <Route path="/Settings" component={Settings} />
            <Route path="/help-center" component={Help} />
            <Route path="/project/pers/:project_id" component={Pers} />
            <Route
              path="/project/pers-establish/:project_id"
              component={PersEstablish}
            />
            <Route
              path="/project/phase/child-phase/:phase_id"
              component={ChildPhasePage}
            />
            <Route path="/banner/:banner_id" component={BannerCreator} />
            <Route path="/banner-history" component={BannerHistory} />
            <Route path="/project/phase/:project_id" component={Profile} />
            <Route path="/project/:project_id" component={Project} />
            <Route exact path="/edit/:edit_id" component={Edit} />
            <Route path="/addNewProject" component={AddNewProject} />
            <Route path="/addNewProject/success" component={AddNewProject} />
            {/* <Route path="/conditional-approval/:project_id/:pers_id" component={ProjectStatus} /> */}
            <Route
              path="/conditional-available/:project_id/:pers_id/:pers_type"
              component={ConditionalAvailable}
            />
            <Route
              path="/conditional-unavailable/:project_id/:pers_id/:pers_type"
              component={ConditionalUnavailable}
            />
            <Route
              path="/final-approval/:project_id/:pers_id/:pers_type"
              component={FinalApproval}
            />
            <Route
              path="/ineligible-letter/:project_id/:pers_id/:pers_type"
              component={IneligibleLetter}
            />
            <Route
              path="/suspended/:project_id/:pers_id/:pers_type"
              component={SuspensionLetter}
            />
            <Route path="/addNewPhase/:project_id" component={AddNewPhase} />
            <Route
              path="/certification/:project_id/:phase_id/:status"
              component={Certification}
            />
            <Route path="/add-new-pers/:project_id" component={AddNewPers} />
            <Route
              path="/add-new-decision/:project_id/:pers_id/:pers_type"
              component={AddNewPersDecision}
            />
            <Route
              path="/add-child-phase/:project_id/:phase_id"
              component={ChildPhase}
            />
            <Route
              path="/add-established-pers/:project_id"
              component={AddEstablishedPers}
            />
            <Route path="/error-page" component={ErrorPage} />
            <Route
              path="/view-certification/:project_id/:phase_id/:status"
              component={ViewCertification}
            />

            <Route
              path="/print-certification/:cert_id/:project_id/:phase_id"
              component={ViewPrintCertification}
            />
            <Route path="/reports" component={Reports} />
            <Route path="/session-error-page" component={SessionErrorPage} />
            <Route path="/project-error-page" component={ProjectErrorPage} />
            <Route path="/admin" component={Admin} />
            <Route path="/project-error-page" component={ProjectErrorPage} />
            <Route component={NoMatch} />
          </Switch>
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;

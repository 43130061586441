import React from "react";
export const ProjectOwnership = ({ data }) => {
    return (
        <div style={{ padding: "18px 24px" }}>
        <div className="question">
          <div className="number">1.</div>{" "}
          <div>
            Will the unit owners have the sole ownership
            interest in, and rights to the use of, the
            project’s facilities, common elements, and
            limited common elements (unless two or more HOAs
            share amenities and have an agreement in place
            governing such arrangement)?
          </div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectOwnershipSt !==
          "undefined" ? (
            <>
              {data.projectOwnershipSt
                .poOwnersHaveSoleOwnshpIndSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectOwnershipSt.poOwnersHaveSoleOwnshpInd}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectOwnershipSt.poOwnersHaveSoleOwnshpInd}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="question">
          <div className="number">2.</div>{" "}
          <div>
            {" "}
            Is the project built on a leasehold estate?
          </div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          <div className="attribute">
            {data.projectOwnershipSt.poLeaseholdInd}
          </div>
        </div>
        {data.projectOwnershipSt.poLeaseholdInd === "Yes" ? (
          <>
            <div className="question">
              <div
                className="number"
                style={{
                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                  borderBottom: "1px solid rgba(0,0,0,0.4)",
                  marginLeft: "37px",
                  marginBottom: "10px",
                }}
              ></div>{" "}
              <div style={{ paddingTop: "10px" }}>
                Does the leasehold comply with Fannie Mae
                requirements outlined in{" "}
                <a
                  target="_blank"
                  href="https://www.fanniemae.com/content/guide/selling/b2/3/03.html"
                >
                  {" "}
                  B2-3-03
                </a>
                ?
              </div>
            </div>
            <div
              className="summary-answer"
              style={{ paddingLeft: "86px" }}
            >
              <div className="label">Answer:&nbsp;</div>
              {/* <div className="attribute">
                {data.projectOwnershipSt.poLeaseComplyInd}
              </div> */}
              {typeof data.projectOwnershipSt !==
              "undefined" ? (
                <>
                  {data.projectOwnershipSt
                    .poLeaseComplyIndSt === "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {data.projectOwnershipSt.poLeaseComplyInd}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {data.projectOwnershipSt.poLeaseComplyInd}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="question">
          <div className="number">3.</div>{" "}
          <div>
            Does a single entity own more units in the
            project than what is allowed by the{" "}
            <a
              target="_blank"
              href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
            >
              {" "}
              Selling Guide
            </a>
            ?{" "}
          </div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectOwnershipSt !==
          "undefined" ? (
            <>
              {data.projectOwnershipSt
                .poSnglOwnMoreThanOneIndSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectOwnershipSt.poSnglOwnMoreThanOneInd}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectOwnershipSt.poSnglOwnMoreThanOneInd}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        {data.projectOwnershipSt.poSnglOwnMoreThanOneInd === "Yes" ? (
          <>
            <div className="question">
              <div
                className="number"
                style={{
                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                  borderBottom: "1px solid rgba(0,0,0,0.4)",
                  marginLeft: "37px",
                  marginBottom: "10px",
                }}
              ></div>{" "}
              <div style={{ paddingTop: "10px" }}>
                How many units does the single-entity own?
                (For multiple single entities, enter the
                number for the largest single entity or
                combine the total units for affiliated
                single entities){" "}
              </div>
            </div>
            <div
              className="summary-answer"
              style={{ paddingLeft: "86px" }}
            >
              <div className="label">Answer:&nbsp;</div>
              <div className="attribute">
                {data.projectOwnershipSt.poUnitsSnglOwn}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
}

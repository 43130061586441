import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./ReasonCodeTooltip.css";
import InfoIcon from "@material-ui/icons/Info";

const CustomReasonCodeTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#f7f7f7",
    border: "1px solid #E6E8ED",
    height: "max-content",
    pointerEvents: "auto!important",
    maxHeight: "500px",
    maxWidth: "800px",
    overflowY: "scroll",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "2px solid #E6E8ED",
    },
  },
})(Tooltip);

const ReasonCodeTooltip = ({ reasonCodes }) => {
    const renderTooltipData = (list) => {
        return (
            <div class="reason-code-info-container">
                <h4 class="reason-code-info-title">Reason Codes Info</h4>
                <table class="reason-code-table">
                    <tr class="reason-code-header">
                        <th class="reason-code-header-desc ">Reason Code </th>
                        <th class="reason-code-header-desc ">Definition</th>
                    </tr>
                    {list && list.length > 0 ? (
                    list.map((l) => (
                        <tr class="reason-code-row">
                        <td class="reason-code-row-desc ">{l.reasonCode}</td>
                        <td class="reason-code-row-desc ">{l.reasonDefinitionInternal}</td>
                       </tr>
                    ))
                ) : (
                    <></>
                )}
                </table>
            </div>
        );
    };

  return (
    <CustomReasonCodeTooltip
      style={{ backgroundColor: "white" }}
      title={renderTooltipData(reasonCodes)}
      placement="right"
      arrow
      disableFocusListener
      disableTouchListener
      interactive
    >
      <InfoIcon
        style={{
          marginBottom: "3px",
          color: "#1c6fa3",
          width: 20,
          height: 18,
          marginLeft: "5px"}}
      />
    </CustomReasonCodeTooltip>
  );
};

export default ReasonCodeTooltip;

import * as React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {Dialog, DialogContent, DialogActions, Typography, withStyles, makeStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './DialogStyles.css';

const styles = (theme) => ({
  root: {
    // margin: 1,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#000'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomDialog = ({ isOpen, handleClose, title, content, actions, dialogWidth='48% !important', dialogHeight= '637px' }) => {
  const useStyles = makeStyles(() => ({
    paper: { width: dialogWidth, height: dialogHeight, borderRadius: '4px',maxWidth:"100%",
    backgroundColor: '#FFFFFF',margin:'0px !important',padding:'0px !important' },
  }));
  const classes = useStyles();
  return (
    <div>
      <Dialog classes={{ paper: classes.paper}} className={'dialog'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className='dialogTitle'>{title}</div>
        </DialogTitle>
        <DialogContent dividers>
          {content}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default CustomDialog;
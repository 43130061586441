import React, { Component } from "react";
import axios from "axios";
import "../BannerHistory/BannerHistory.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./Banner.css";
import InfoIcon from "@material-ui/icons/Info";
import CriticalIcon from "../../images/Critical@2x.svg";

class ActiveBanner extends Component {
  constructor(props) {
    super(props);
    let showSiteBanner =
      sessionStorage.getItem("showSiteSessionBanner") &&
      sessionStorage.getItem("showSiteSessionBanner") === "false"
        ? false
        : true;
    let showAnnouncementBanner =
      sessionStorage.getItem("showAnnouncementSessionBanner") &&
      sessionStorage.getItem("showAnnouncementSessionBanner") === "false"
        ? false
        : true;
    this.state = {
      activeSiteBanner: null,
      showSiteBanner: showSiteBanner,
      activeAnnouncementBanners: null,
      showAnnouncementBanner: showAnnouncementBanner,
    };
  }

  async componentDidMount() {
    if (this.state.showSiteBanner) {
      try {
        const { data: latestSiteBanner } = await axios.get(
          global.REACT_APP_API_ENDPOINT + "/banner/getLatestBannerByType",
          { params: { bannerType: "SM" } }
        );
        if (latestSiteBanner?.hasOwnProperty("bannerId")) {
          this.setState({
            activeSiteBanner: latestSiteBanner,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (this.state.showAnnouncementBanner) {
      try {
        const { data: latestAnnouncementBanner } = await axios.get(
          global.REACT_APP_API_ENDPOINT + "/banner/getLatestBannerByType",
          { params: { bannerType: "FA" } }
        );
        if (latestAnnouncementBanner?.hasOwnProperty("bannerId")) {
          this.setState({
            activeAnnouncementBanners: latestAnnouncementBanner,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  getLatestSiteBanners() {
    let bannerMessage = <div></div>;
    if (this.state.activeSiteBanner && this.state.showSiteBanner) {
      bannerMessage = (
        <div className="banner-alert">
          <div className="alert-icon">
            <img
              alt="Critical Warning Icon"
              className="button-image"
              src={CriticalIcon}
            />
          </div>
          <div>
            <div className="alert-title">
              {this.state.activeSiteBanner.title}
              <div
                className="close-icon"
                style={{ marginLeft: "auto", marginRight: "0px" }}
              >
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    sessionStorage.setItem("showSiteSessionBanner", false);
                    this.setState({
                      activeSiteBanner: null,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="alert-message">
              {this.state.activeSiteBanner.description}
            </div>
            {this.state.activeSiteBanner.links?.map((x, i) => {
              return (
                <div className="alert-message">
                  <a target="_blank" href={x.linkUrl}>
                    {x.linkText}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return bannerMessage;
  }

  getLatestAnnouncementBanners() {
    let bannerMessage = <div></div>;
    if (
      this.state.activeAnnouncementBanners &&
      this.state.showAnnouncementBanner
    ) {
      bannerMessage = (
        <div className="banner-announcement-alert">
          <div className="announcement-info-icon">
            <InfoIcon
              style={{
                width: 20,
                height: 18,
                marginBottom: "3px",
                color: "ffffff",
              }}
            />
          </div>
          <div>
            <div className="announcement-title">
              {this.state.activeAnnouncementBanners.title}
              <div
                className="close-icon"
                style={{ marginLeft: "auto", marginRight: "0px" }}
              >
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    sessionStorage.setItem(
                      "showAnnouncementSessionBanner",
                      false
                    );
                    this.setState({
                      activeAnnouncementBanners: null,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="announcement-message">
              {this.state.activeAnnouncementBanners.description}
            </div>
            {this.state.activeAnnouncementBanners.links?.map((x, i) => {
              return (
                <div className="announcement-message">
                  <a
                    className="announcement-link"
                    target="_blank"
                    href={x.linkUrl}
                  >
                    {x.linkText}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return bannerMessage;
  }

  render() {
    return (
      <div>
        {this.getLatestSiteBanners()}
        {this.getLatestAnnouncementBanners()}
      </div>
    );
  }
}
export default ActiveBanner;

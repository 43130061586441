import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

import DeleteModalRest from "./delete-modal-rest";

const CannedRestrication = ({ formData, name, isPre, setRestrictionsData }) => {
  const [restrictions, setRestrictions] = useState([]);
  const [previousRestrictions, setPreviousRestrictions] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deletedRow, setDeletedRow] = useState(null);

  useEffect(() => {
    setRestrictions([...(formData[name] || [])]);
    setPreviousRestrictions(JSON.parse(JSON.stringify(formData[name] || [])));
  }, [formData[name]]);

  const handleRowSelection = (event, index) => {
    const _restrictions = restrictions.map((restriction, i) => {
      return i === index
        ? { ...restriction, isSelected: event.target.checked, isEdit: false }
        : restriction;
    });

    setRestrictions(_restrictions);
    setRestrictionsData(_restrictions, name);
  };

  const handleRowChange = (event, index) => {
    console.log(restrictions, "restriction");
    let tab = RegExp("\\t|\\n", "g");
    let name = event.target.name.toString().replace(tab, " ");
    let value = event.target.value.toString().replace(tab, " ");
    console.log("inside handleRowChange");
    const _restrictions = restrictions.map((restriction, i) => {
      return i === index ? { ...restriction, [name]: value } : restriction;
    });

    setRestrictions(_restrictions);
  };

  const handleRowSave = (index) => {
    toggleEdit(index, false);
  };

  const handleRowCancel = (index, isEdit) => {
    const _restrictions = restrictions.map((restriction, i) => {
      return i === index
        ? { ...previousRestrictions[index], isEdit }
        : restriction;
    });

    setRestrictions(_restrictions);
    setRestrictionsData([..._restrictions], name);
  };

  const toggleEdit = (index, isEdit) => {
    const _restrictions = restrictions.map((restriction, i) =>
      i === index ? { ...restriction, isEdit } : restriction
    );

    setRestrictions(_restrictions);
    setRestrictionsData(_restrictions, name);
  };

  const handleDelete = (index) => {
    setDeletedRow(index);
    setShowDelete(true);
  };

  const handleDeleteSubmit = () => {
    const _restrictions = restrictions.filter((_, i) => i !== deletedRow);

    setRestrictions(_restrictions);
    setShowDelete(false);
    setDeletedRow(null);
    setRestrictionsData(_restrictions, name);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
    setDeletedRow(null);
  };

  return (
    <>
      {restrictions &&
        restrictions.map(
          ({ referenceName, referenceDesc, isSelected, isEdit }, index) => (
            <div>
              <div
                className="checkboxContainer"
                style={{
                  justifyContent: "space-between",
                  marginBottom: "0px",
                  marginTop: 10,
                }}
              >
                <div style={{ display: "flex", height: 0 }}>
                  <div>
                    <input
                      type="checkbox"
                      className={"checkboxfield"}
                      style={{ height: 15, width: 15, marginTop: 5 }}
                      id={`input_${index}`}
                      name={`${name}_restriction_${index}`}
                      checked={isSelected}
                      aria-label={`${name}_restriction_${index}`}
                      onChange={(event) => handleRowSelection(event, index)}
                    />
                  </div>
                  <div>
                    {(!isEdit || isPre) && (
                      <label
                        className={"checkboxLabel"}
                        htmlFor={"input"}
                        style={{ marginBottom: 0, marginLeft: 10 }}
                      >
                        {referenceName}
                      </label>
                    )}
                    {isEdit && !isPre && isSelected && (
                      <input
                        type="text"
                        name="referenceName"
                        className="form-control"
                        value={referenceName}
                        onChange={(event) => handleRowChange(event, index)}
                      />
                    )}
                  </div>
                </div>
                {isEdit ? (
                  <div>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        padding: 0,
                      }}
                      onClick={() => handleRowSave(index)}
                    >
                      <Save
                        style={{
                          color: "#0F5AF0",
                          marginRight: "5px",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                      Save
                    </Button>
                    <Button
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                      }}
                      className="test"
                      onClick={() => handleRowCancel(index, false)}
                    >
                      <Close
                        style={{
                          color: "#999999",
                          marginRight: "5px",
                          height: "18px",
                          width: "18px",
                        }}
                      />{" "}
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div>
                    {!isPre ? (
                      <>
                        <Button
                          style={{
                            textTransform: "capitalize",
                          }}
                          onClick={() => isSelected && toggleEdit(index, true)}
                          disabled={!isSelected}
                        >
                          <Edit
                            style={{
                              color: "#0F5AF0",
                              marginRight: "5px",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                          Edit
                        </Button>
                        <Button
                          style={{
                            marginLeft: "10px",
                            textTransform: "capitalize",
                          }}
                          className="test"
                          disabled={!isSelected}
                          onClick={() => handleDelete(index)}
                        >
                          <Delete
                            style={{
                              color: "#D02F2F",
                              marginRight: "5px",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                          Delete
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              <div>
                {isSelected && isEdit ? (
                  <textarea
                    name="referenceDesc"
                    value={referenceDesc}
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      padding: "5px",
                    }}
                    onChange={(event) => handleRowChange(event, index)}
                  />
                ) : (
                  <p
                    style={
                      isPre
                        ? { fontSize: 14, lineHeight: 1, marginTop: "35px" }
                        : { fontSize: 14, lineHeight: 1 }
                    }
                  >
                    {referenceDesc}
                  </p>
                )}
              </div>
            </div>
          )
        )}
      <DeleteModalRest
        showDelete={showDelete}
        referenceName={
          restrictions &&
          restrictions[deletedRow] &&
          restrictions[deletedRow].referenceName
        }
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteSubmit={handleDeleteSubmit}
      />
    </>
  );
};

export default CannedRestrication;

import React, { useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import axios from "axios";
import moment from "moment";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AuditTrailActions } from "../shared/constants/AuditTrailActions";
import RefreshIcon from '@material-ui/icons/Refresh';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  } else {
    return array;
  }
}

const headCells = [
  { id: 'function', label: 'Action', maxWidth: 100 },
  { id: 'auditTimestamp', label: 'Timestamp', maxWidth: 100 },
  { id: 'requestUser', label: 'User', maxWidth: 100 },
];

const customHeaderColumnStyle = {
  wordWrap: "break-word",
  maxWidth: "70px",
  backgroundColor: "rgba(17, 118, 181, 0.05)",
  fontWeight: 600
};

const customDataColumnStyle = {
  wordWrap: "break-word",
  maxWidth: "70px"
};

const customJSONHeadColumnStyle = {
  wordWrap: "break-word",
  maxWidth: "100px",
  backgroundColor: "rgba(17, 118, 181, 0.05)",
  fontWeight: 600
};

const customJSONColumnStyle = {
  wordWrap: "break-word",
  maxWidth: "100px"
};

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={customHeaderColumnStyle}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key="payload"
          align="left"
          style={customJSONHeadColumnStyle}>
          Updates
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const PayloadTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#f7f7f7",
    border: "1px solid #E6E8ED",
    pointerEvents: "auto!important",
    maxHeight: "350px",
    maxWidth: "500px",
    overflow: "scroll",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "2px solid #E6E8ED",
    },
  },
})(Tooltip);

export default function AuditTrailList() {
  const lastDay = new Date();
  lastDay.setDate(lastDay.getDate() - 1);
  const [auditList, setAuditList] = useState([]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('auditTimestamp');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorMinDate, setErrorMinDate] = React.useState("");
  const [errorMaxDate, setErrorMaxDate] = React.useState("");
  const [formData, setFormData] = useState({
    minDate: lastDay,
    maxDate: new Date(),
    feature: "",
  });
  const listOfFunctions = AuditTrailActions
  React.useEffect(() => {
    getAuditTrails(false);
  }, []);

  const getAuditTrails = async (refresh) => {
    let startTime;
    let endTime;
    let inputParams;
    if (refresh) {
      const lastDay = new Date();
      lastDay.setDate(lastDay.getDate() - 1);
      startTime = lastDay;
      endTime = new Date();
      setFormData ({
        minDate: lastDay,
        maxDate: new Date(),
        feature: "",
      });
      inputParams = {
        startTime: new Date(startTime).getTime(),
        endTime: new Date(endTime).getTime(),
      };
    } else {
      startTime = formData.minDate;
      endTime = formData.maxDate;
      inputParams = {
        startTime: new Date(startTime).getTime(),
        endTime: new Date(endTime).getTime(),
      };
      if (formData.feature != '') {
        inputParams['field'] = 'function';
        inputParams['value'] = formData.feature;
      }
    }
    try {
      const { data } = await axios.get(
        `${global.REACT_APP_API_ENDPOINT}/auditdata/getByCriteria`, { params: inputParams });
      setAuditList(data);
      handleChangePage(null, 10);
      setPage(0);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    console.log(property, order)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  const visibleRows = React.useMemo(
    () =>
      stableSort(auditList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  const renderTooltipData = (data) => {
    let tooltipData;
    if (isValidJson(data)) {
      tooltipData = JSON.stringify(JSON.parse(data), null, 2)
      return (
        <div style={{
          lineHeight: 1.5,
          fontSize: 14,
          maxHeight: "max-content",
        }}><pre>{tooltipData}</pre></div>
      );
    } else {
      tooltipData = data;
      return (
        <div style={{
          lineHeight: 1.5,
          fontSize: 14,
          maxHeight: "max-content",
        }}>{tooltipData}</div>
      );
    }

  };

  const isValidJson = (str) => {
    try {
      if (JSON.parse(str)) {
        return true;
      }
    } catch {
      return false;
    }
  }
  const renderDottedValue = (value = '') => {
    if (value && value.length > 25) {
      return `${value.substring(0, 100)}...`
    }
    return value
  }

  const convertDateFromUtcToLocal = (date) => {
    let newDateObj = new Date(date);
    newDateObj = new Date(newDateObj.getTime() - (new Date().getTimezoneOffset() * 60000));
    return formatDate(newDateObj);
  }

  const formatDate = (date) => {
    return `${new Date(date).getUTCFullYear()}-${`${new Date(date).getUTCMonth() + 1
      }`.padStart(2, 0)}-${`${new Date(date).getUTCDate()}`.padStart(
        2,
        0
      )}T${`${new Date(date).getUTCHours()}`.padStart(2, 0)}:${`${new Date(
        date
      ).getUTCMinutes()}`.padStart(2, 0)}`;
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div style={{ minHeight: "742px"}}>
      <Grid item xs={12}>
        <div style={{ marginLeft: "-18px"}}>
          <Grid container spacing={2} className="dialoguClass" >
            <Grid item xs={3}>
              <div>Start Date*</div>
              <input
                type="datetime-local"
                id="minDate"
                name="minDate"
                className="form-control"
                value={(convertDateFromUtcToLocal(formData.minDate) || "").toString().substring(0, 16)}
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setErrorMinDate("This field is required");
                  } else {
                    setErrorMinDate("");
                  }
                  handleChange(event);
                }}
              />
              {errorMinDate != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorMinDate}</p>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <div>End Date*</div>
              <input
                type="datetime-local"
                id="maxDate"
                name="maxDate"
                className="form-control"
                value={(convertDateFromUtcToLocal(formData.maxDate) || "").toString().substring(0, 16)}
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setErrorExpiryDate("This field is required");
                  } else {
                    if (
                      moment(formData.minDate) > moment(event.target.value)
                    ) {
                      setErrorMaxDate("Invalid Expiry Date");
                    } else {
                      setErrorMaxDate("");
                    }
                  }
                  handleChange(event);
                }}
              />
              {errorMaxDate != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorMaxDate}</p>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <div>Action</div>
              <select
                required
                name="feature"
                id="feature"
                className="form-control"
                value={formData.feature}
                onChange={event => {
                  handleChange(event);
                }}
              >
                <option key="" value="">All</option>
                {listOfFunctions &&
                  listOfFunctions.length > 0 &&
                  listOfFunctions.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </Grid>
            <Grid item xs={3}>
              <Button
                type="button"
                style={{
                  type: "button",
                  color: "white",
                  backgroundColor: "#1176B5",
                  borderRadius: "4px",
                  boxShadow:
                    "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                  height: "40px",
                  width: "190px",
                  marginTop: "20px",
                  marginleft: "20px",
                  textTransform: "capitalize",
                }}

                onClick={
                  (e) => {
                    if (errorMaxDate == "" && errorMinDate == "") {
                      getAuditTrails(false)
                    }
                  }
                }
              >
                Filter
              </Button>
              <Button
                type="submit"
                style={{
                  type: "button",
                  color: "white",
                  backgroundColor: "#1176B5",
                  borderRadius: "4px",
                  boxShadow:
                    "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                  height: "40px",
                  width: "190px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  textTransform: "capitalize",
                }}
                onClick={
                  (e) => {
                      getAuditTrails(true)
                  }
                }
                >
                Refresh
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
        { visibleRows &&
          visibleRows.length > 0 ? (
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={auditList.length}
                    />
                    <TableBody>
                      {visibleRows?.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align="left" component="th" scope="row" style={customDataColumnStyle}>{item.function}</TableCell>
                          <TableCell align="left" style={customDataColumnStyle} >{moment(item.auditTimestamp).format("MM/DD/YYYY HH:mm:ss A")}</TableCell>
                          <TableCell align="left" style={customDataColumnStyle}>{item.requestUser && item.requestUser === "" ? "" : item.requestUser}</TableCell>
                          <TableCell align="left" style={customJSONColumnStyle}>
                            {item.payload ? (
                              <PayloadTooltip
                                style={{ backgroundColor: "white" }}
                                title={renderTooltipData(item.payload)}
                                placement="right"
                                arrow
                                disableFocusListener
                                disableTouchListener
                                interactive
                              >
                                <span>
                                  {" "}
                                  {renderDottedValue(item.payload)}{" "}
                                </span>
                              </PayloadTooltip>
                            ) : (<span>{item.payload}</span>)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  component="div"
                  count={auditList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    ".MuiTablePagination-displayedRows": {
                      marginBottom: "0px"
                    },
                    ".MuiTablePagination-selectLabel": {
                      marginBottom: "0px"
                    },
                  }}
                />
              </Paper>
              <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
              />
            </Box>
          ) : (
          <>
          <p class="error"> No records found. </p>
          </>)}
    </div>
  );
}

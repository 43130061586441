import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Button, Grid } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import { ListItem, ListItemText } from "@material-ui/core/";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";

export const Review = ({
  formData,
  nextStep,
  prevStep,
  prevStep2,
  prevStep3,
  prevStep4,
  prevStep5,
  step,
  persId,
}) => {
  const {
    //perslen
    projectId,
    projectStreet,
    projectStyle,
    dateReceived,
    persStatus,
    persDecisionStatus,
    restrictions,
    persExpiration,
    lenderSSNumber,
    requester,
    lenderName,
    decisionLevel,
    requesterEmail,
    projectName,
    projectState,
    dtSpecificDate,
    addedRestrictions,
    addedConditions,
    perCondtnsRef,
  } = formData;

  const [phaseReview, setPhaseReview] = useState(false);
  const [disable, setDisable] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let phaseReviewedStr = [];
    if (formData.phaseDecisions) {
      for (let phase of formData.phaseDecisions) {
        phaseReviewedStr.push(phase.phaseName);
      }

      setPhaseReview(phaseReviewedStr.join(", "));
    }
  }, []);

  const [apiErr, setApiErr] = useState("");
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  function sunbmitPersForm(isFirstTimeDecision) {
    let paramCondition = [];
    let paramRestriction = [];

    for (let condition of perCondtnsRef) {
      if (condition.isSelected) {
        let obj = {};
        obj.isAddedCondn = false;
        obj.conditionDescription = condition.conditionDesc;
        obj.conditionName = condition.conditionName;

        paramCondition.push(obj);
      }
    }

    for (let addedCon of addedConditions) {
      if (addedCon.isSelected) {
        let obj = {};
        obj.isAddedCondn = true;
        obj.conditionDescription = addedCon.conditionDesc;
        obj.conditionName = addedCon.conditionName;

        paramCondition.push(obj);
      }
    }

    for (let rest of restrictions) {
      if (rest.isSelected) {
        let obj = {};
        obj.isAddedRestrictions = false;
        obj.restrictionsDescription = rest.referenceDesc;
        obj.restrictionsName = rest.referenceName;

        paramRestriction.push(obj);
      }
    }

    for (let addedRest of addedRestrictions) {
      if (addedRest.isSelected) {
        let obj = {};
        obj.isAddedRestrictions = true;
        obj.restrictionsDescription = addedRest.referenceDesc;
        obj.restrictionsName = addedRest.referenceName;

        paramRestriction.push(obj);
      }
    }

    let obj = {};
    if (persDecisionStatus == "Ineligible") {
      obj.ineligibilityExplaination = formData.ineligibilityExplaination;
      obj.reviewedProjectExhibits = formData.reviewedProjectExhibits;
      obj.sellingGuideReference = formData.sellingGuideReference;
    }

    let paramsData = {
      createdById: passData.user,
      decisionLevel: decisionLevel,
      persId: persId,
      projectId: projectId,
      reviewedBy: passData.firstName + " " + passData.lastName,
      projectDecision: {
        createdBy: passData.user,
        persDecison: persDecisionStatus,
        persExpiration:
          persExpiration === "Enter Specific date"
            ? dtSpecificDate
            : persExpiration,
        // conditions: paramCondition,
        // restrictions: paramRestriction,
        // suspensionReason: formData.suspensionReason
      },
    };

    if (persDecisionStatus == "Ineligible") {
      paramsData.projectDecision.ineligibleDetails = obj;
      paramsData.projectDecision.reasonIds = formData.reasonCodes;
    } else if (persDecisionStatus == "Suspension of the Application") {
      paramsData.projectDecision.suspensionReason = formData.suspensionReason;
    } else if (persDecisionStatus == "Conditional/Available") {
      paramsData.projectDecision.conditions = paramCondition;
      paramsData.projectDecision.restrictions = paramRestriction;
    } else if (persDecisionStatus == "Conditional/Unavailable") {
      paramsData.projectDecision.conditions = paramCondition;
      paramsData.projectDecision.reasonIds = formData.reasonCodes;
    } else if (persDecisionStatus == "Final Project Approval") {
      paramsData.projectDecision.restrictions = paramRestriction;
    }

    if(paramsData.projectDecision.reasonIds)
      paramsData.projectDecision.reasonIds = paramsData.projectDecision.reasonIds
          .map(reasonId =>reasonId.value);

    console.log("paramsData", paramsData);

    axios
      .post(global.REACT_APP_API_ENDPOINT + `/decision/saveNewPersDecision`, {
        ...paramsData,
        isFirstTimeDecision: isFirstTimeDecision,
      })
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          // setTimeout(() => {
          nextStep();
          // }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log("savePersProjectAndForms error", error);
      });
  }

  function submitPhaseForm(isFirstTimeDecision) {
    let phaseArray = [];

    for (let phase of formData.phaseDecisions) {
      let paramCondition = [];
      let paramRestriction = [];

      if (phase.perCondtnsRef) {
        for (let condition of phase.perCondtnsRef) {
          if (condition.isSelected) {
            let obj = {};
            obj.isAddedCondn = false;
            obj.conditionDescription = condition.conditionDesc;
            obj.conditionName = condition.conditionName;

            paramCondition.push(obj);
          }
        }
      }

      if (phase.addedConditions) {
        for (let addedCon of phase.addedConditions) {
          if (addedCon.isSelected) {
            let obj = {};
            obj.isAddedCondn = true;
            obj.conditionDescription = addedCon.conditionDesc;
            obj.conditionName = addedCon.conditionName;

            paramCondition.push(obj);
          }
        }
      }

      if (phase.restrictions) {
        for (let rest of phase.restrictions) {
          if (rest.isSelected) {
            let obj = {};
            obj.isAddedRestrictions = false;
            obj.restrictionsDescription = rest.referenceDesc;
            obj.restrictionsName = rest.referenceName;

            paramRestriction.push(obj);
          }
        }
      }

      if (phase.addedRestrictions) {
        for (let addedRest of phase.addedRestrictions) {
          if (addedRest.isSelected) {
            let obj = {};
            obj.isAddedRestrictions = true;
            obj.restrictionsDescription = addedRest.referenceDesc;
            obj.restrictionsName = addedRest.referenceName;

            paramRestriction.push(obj);
          }
        }
      }

      let obj = {};
      obj = {
        createdBy: passData.user,
        phaseId: phase.phaseId,
        persDecison: phase.persDecison,
        persExpiration:
          phase.persExpiration === "Enter Specific date"
            ? phase.persExpiration
            : phase.persExpiration,
      };

      if (phase.persDecison == "Ineligible") {
        let object = {};
        object.ineligibilityExplaination = formData.ineligibilityExplaination;
        object.reviewedProjectExhibits = formData.reviewedProjectExhibits;
        object.sellingGuideReference = formData.sellingGuideReference;
        obj.ineligibleDetails = object;
        obj.reasonIds = phase.reasonIds;
      } else if (phase.persDecison == "Suspension of the Application") {
        obj.suspensionReason = formData.suspensionReason;
      } else if (phase.persDecison == "Conditional/Available") {
        obj.conditions = paramCondition;
        obj.restrictions = paramRestriction;
      } else if (phase.persDecison == "Conditional/Unavailable") {
        obj.conditions = paramCondition;
        obj.reasonIds = phase.reasonIds;
      } else if (phase.persDecison == "Final Project Approval") {
        obj.restrictions = paramRestriction;
      }

      if (phase.persDecison) {
        phaseArray.push(obj);
      }
      // phaseArray.push(obj);
    }

    let paramsData = {
      createdById: passData.user,
      decisionLevel: decisionLevel,
      persId: persId,
      projectId: projectId,
      reviewedBy: passData.firstName + " " + passData.lastName,
      phaseDecisions: phaseArray,
    };

    console.log("paramsData", paramsData);

    axios
      .post(global.REACT_APP_API_ENDPOINT + `/decision/saveNewPersDecision/`, {
        ...paramsData,
        isFirstTimeDecision: isFirstTimeDecision,
      })
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          nextStep();
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log("savePersProjectAndForms error", error);
      });
  }

  function checkBackRedirection(data) {
    if (data.persDecisionStatus == "Conditional/Unavailable") {
      prevStep4();
    } else if (data.persDecisionStatus == "Suspension of the Application") {
      prevStep2();
    } else if (data.persDecisionStatus == "Ineligible") {
      prevStep();
    } else {
      prevStep3();
    }
  }

  function checkPhaseRedirection(data) {
    let IsConditionAvail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Available";
    });

    let IsConditionUnavail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Unavailable";
    });

    let IsFinalApproval = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Final Project Approval";
    });

    let IsSuspention = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Suspension of the Application";
    });

    let IsIneligible = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    if (IsIneligible) {
      prevStep();
    } else if (IsSuspention) {
      prevStep2();
    } else if (IsConditionAvail) {
      prevStep3();
    } else if (IsFinalApproval) {
      prevStep3();
    } else if (IsConditionUnavail) {
      prevStep4();
    }
  }

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          //   direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div
                className="top-title-container"
                style={{ marginBlock: 30, padding: 0 }}
              >
                <div className="big-title">Add New PERS Decision</div>
                <div className="small-title">For PERS ID: {persId}</div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">Step 7 : Review Decision</div>
                <>
                  <div className="page-container">
                    <div className="accordion-top-container">
                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Project Information
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project ID"
                                  secondary={projectId}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Name"
                                  secondary={projectName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Address"
                                  secondary={projectStreet + " " + projectState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Style"
                                  secondary={projectStyle}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Date Received"
                                  secondary={dateReceived}
                                />
                              </ListItem>
                              {decisionLevel == "Entire project" ||
                              decisionLevel == "Entire Project" ? (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Phases reviewed"
                                    secondary={"Entire project"}
                                  />
                                </ListItem>
                              ) : (
                                <ListItem className="page-select">
                                  <ListItemText
                                    primary="Phases reviewed"
                                    secondary={phaseReview}
                                  />
                                </ListItem>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>

                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Lender Information
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lende Namer"
                                  secondary={lenderName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Seller/Service Number"
                                  secondary={lenderSSNumber}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Requester"
                                  secondary={requester}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Requester Email"
                                  secondary={requesterEmail}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>

                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Decision Summary
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            {formData.decisionLevel == "Entire project" ||
                            formData.decisionLevel == "Entire Project" ? (
                              <Card.Body
                                className="accordion-body-card"
                                style={{ display: "flex", margin: 10 }}
                              >
                                <Grid
                                  item
                                  xs={2}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Entire Project
                                </Grid>
                                <Grid item xs={10}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "Row",
                                      justifyContent: "space-between",
                                      fontSize: 14,
                                    }}
                                  >
                                    <div>
                                      <div style={{ fontWeight: "bold" }}>
                                        PERS Decision :{" "}
                                      </div>
                                      <div> {formData.persDecisionStatus} </div>
                                    </div>
                                    {formData.persDecisionStatus !=
                                    "Ineligible" ? (
                                      <div>
                                        <div style={{ fontWeight: "bold" }}>
                                          Expiration :{" "}
                                        </div>
                                        <div> {formData.persExpiration} </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  {formData.persDecisionStatus ==
                                  "Ineligible" ? (
                                    <div>
                                      <div
                                        style={{
                                          marginLeft: 20,
                                          marginTop: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Selling and Servicing Guide Reference
                                        </div>
                                        <div
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          {formData.sellingGuideReference}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          marginLeft: 20,
                                          marginTop: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          List of reviewed Project Exhibits
                                        </div>
                                        <div
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          {formData.reviewedProjectExhibits}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          marginLeft: 20,
                                          marginTop: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Reason for Ineligibility
                                        </div>
                                        <div
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          {formData.ineligibilityExplaination}
                                        </div>
                                      </div>
                                    </div>
                                  ) : formData.persDecisionStatus ==
                                    "Suspension of the Application" ? (
                                    <div
                                      style={{
                                        marginLeft: 20,
                                        marginTop: 10,
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Reason for Suspension
                                      </div>
                                      <div
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        {formData.suspensionReason}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div style={{ marginTop: 15 }}>
                                        <div>
                                          <div style={{ fontWeight: "bold" }}>
                                            Conditions :{" "}
                                          </div>
                                          <div>
                                            {formData.perCondtnsRef &&
                                              formData.perCondtnsRef.map(
                                                (item, index) => (
                                                  <div>
                                                    {item.isSelected ? (
                                                      <div
                                                        style={{
                                                          marginLeft: 20,
                                                          marginTop: 10,
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          #{item.conditionName}
                                                        </div>
                                                        <div
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          {item.conditionDesc}
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                )
                                              )}
                                          </div>
                                          <div>
                                            {formData.addedConditions &&
                                              formData.addedConditions.map(
                                                (item, index) => (
                                                  <div>
                                                    {item.isSelected ? (
                                                      <div
                                                        style={{
                                                          marginLeft: 20,
                                                          marginTop: 10,
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          #{item.conditionName}
                                                        </div>
                                                        <div
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          {item.conditionDesc}
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{ marginTop: 15 }}>
                                        <div>
                                          <div style={{ fontWeight: "bold" }}>
                                            Delivery Restrictions :{" "}
                                          </div>
                                          <div>
                                            {formData.restrictions &&
                                              formData.restrictions.map(
                                                (item, index) => (
                                                  <div>
                                                    {item.isSelected ? (
                                                      <div
                                                        style={{
                                                          marginLeft: 20,
                                                          marginTop: 10,
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          #{item.referenceName}
                                                        </div>
                                                        <div
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          {item.referenceDesc}
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                )
                                              )}
                                          </div>
                                          <div>
                                            {formData.addedRestrictions &&
                                              formData.addedRestrictions.map(
                                                (item, index) => (
                                                  <div>
                                                    {item.isSelected ? (
                                                      <div
                                                        style={{
                                                          marginLeft: 20,
                                                          marginTop: 10,
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          #{item.referenceName}
                                                        </div>
                                                        <div
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          {item.referenceDesc}
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Card.Body>
                            ) : (
                              <Card.Body
                                className="accordion-body-card"
                                style={{ margin: 10 }}
                              >
                                {formData.phaseDecisions
                                  ? formData.phaseDecisions.map(
                                      (phase, index) => (
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginBottom: 30 }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {phase.phaseName}
                                            </Grid>
                                            <Grid item xs={10}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "Row",
                                                  justifyContent:
                                                    "space-between",
                                                  fontSize: 14,
                                                }}
                                              >
                                                <div>
                                                  <div
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    PERS Decision :{" "}
                                                  </div>
                                                  <div>
                                                    {" "}
                                                    {phase.persDecison}{" "}
                                                  </div>
                                                </div>
                                                {phase.persDecison !=
                                                "Ineligible" ? (
                                                  <div>
                                                    <div
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Expiration :{" "}
                                                    </div>
                                                    <div>
                                                      {" "}
                                                      {
                                                        phase.persExpiration
                                                      }{" "}
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                              {phase.persDecison ==
                                              "Ineligible" ? (
                                                <div>
                                                  <div
                                                    style={{
                                                      marginLeft: 20,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Selling and Servicing
                                                      Guide Reference
                                                    </div>
                                                    <div
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      {
                                                        formData.sellingGuideReference
                                                      }
                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{
                                                      marginLeft: 20,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      List of reviewed Project
                                                      Exhibits
                                                    </div>
                                                    <div
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      {
                                                        formData.reviewedProjectExhibits
                                                      }
                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{
                                                      marginLeft: 20,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Reason for Ineligibility
                                                    </div>
                                                    <div
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      {
                                                        formData.ineligibilityExplaination
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : phase.persDecison ==
                                                "Suspension of the Application" ? (
                                                <div
                                                  style={{
                                                    marginLeft: 20,
                                                    marginTop: 10,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Reason for Suspension
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    {formData.suspensionReason}
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  <div
                                                    style={{ marginTop: 15 }}
                                                  >
                                                    <div>
                                                      <div
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Conditions :{" "}
                                                      </div>
                                                      <div>
                                                        {phase.perCondtnsRef &&
                                                          phase.perCondtnsRef.map(
                                                            (item, index) => (
                                                              <div>
                                                                {item.isSelected ? (
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 20,
                                                                      marginTop: 10,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      #
                                                                      {
                                                                        item.conditionName
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.conditionDesc
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                      <div>
                                                        {phase.addedConditions &&
                                                          phase.addedConditions.map(
                                                            (item, index) => (
                                                              <div>
                                                                {item.isSelected ? (
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 20,
                                                                      marginTop: 10,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      #
                                                                      {
                                                                        item.conditionName
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.conditionDesc
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{ marginTop: 15 }}
                                                  >
                                                    <div>
                                                      <div
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Delivery Restrictions :{" "}
                                                      </div>
                                                      <div>
                                                        {phase.restrictions &&
                                                          phase.restrictions.map(
                                                            (item, index) => (
                                                              <div>
                                                                {item.isSelected ? (
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 20,
                                                                      marginTop: 10,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      #
                                                                      {
                                                                        item.referenceName
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.referenceDesc
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                      <div>
                                                        {phase.addedRestrictions &&
                                                          phase.addedRestrictions.map(
                                                            (item, index) => (
                                                              <div>
                                                                {item.isSelected ? (
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 20,
                                                                      marginTop: 10,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      #
                                                                      {
                                                                        item.referenceName
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.referenceDesc
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Grid>
                                          </div>
                                        </Grid>
                                      )
                                    )
                                  : null}
                              </Card.Body>
                            )}
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </>
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {apiErr ? apiErr : null}
                </div>
                <div className="bottom-button-container">
                  <div>
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      disabled={disable}
                      onClick={() => {
                        setDisable(true);
                        const isFirstTimeDecision = JSON.parse(
                          window.sessionStorage.getItem("isFirstTimeDecision")
                        );
                        console.log("isFirstTime", isFirstTimeDecision);
                        if (
                          formData.decisionLevel == "Entire project" ||
                          formData.decisionLevel == "Entire Project"
                        ) {
                          sunbmitPersForm(isFirstTimeDecision);
                        } else {
                          submitPhaseForm(isFirstTimeDecision);
                        }
                      }}
                    >
                      Save Decision
                    </Button>
                  </div>
                  <Button
                    type="submit"
                    className="previous-button review-preview"
                    onClick={() => {
                      if (
                        formData.decisionLevel == "Entire project" ||
                        formData.decisionLevel == "Entire Project"
                      ) {
                        checkBackRedirection(formData);
                      } else {
                        checkPhaseRedirection(formData);
                      }
                    }}
                  >
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container"></div>
          </Form>
        )}
      </Formik>
    </>
  );
};

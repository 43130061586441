import React, { useState } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter } from '../../shared/services/SharedService'

export const Page10 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  page3,
  prevStep,
  step,
  status,
  cancelCertification,
  convResult,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        nextStep();
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            <FormStepper step={3} type={values.phaseId} />
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                <img
                  alt="Phase Logo"
                  style={{ paddingRight: "8px", paddingBottom: "4px" }}
                  src={LayersBlackIcon}
                />
                {streetInfo.phaseName}
              </div>
              <div className="decline-info">
                <div className="decline-info-symbol">
                  <CloseIcon
                    style={{
                      color: "#FFFFFF",
                      width: "30px",
                      height: "30px",
                      margin: "5px 0px",
                    }}
                  />
                </div>
                <div className="decline-info-text">
                  This phase cannot be certified. Please certify the entire
                  project.
                </div>
              </div>
              <div className="info" style={{ margin: "0px" }}>
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    <div className="type">Phase ID</div>
                    <div className="attributes">{values.phaseId}</div>
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    <div className="type">Address</div>
                    <div className="attributes">
                      {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                      {streetInfo.phaseState} {streetInfo.phaseZip}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    <div className="type">Project/Phase Name</div>
                    <div className="attributes">
                      {streetInfo.projectName} / {streetInfo.phaseName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-header" style={{ padding: "34px 122px" }}>
                  <div className="decline-symbol">
                    <CloseIcon
                      style={{
                        width: "47px",
                        height: "47px",
                        border: "5px solid #D02F2F",
                        color: "#D02F2F",
                      }}
                    />
                  </div>
                  <div className="decline-title">
                    This phase cannot be certified.
                  </div>
                  <div className="decline-title">
                    Please certify the entire project.
                  </div>
                  <div className="decline-subtitle">
                    Based on your responses below, this looks like a phase in an
                    Established project. Only phases in New projects can be
                    certified. You can now certify the entire project by
                    navigating to the project record in CPM or update your
                    responses on the previous page, if entered in error.
                  </div>
                </div>
                <div className="form-body">
                  <div className="form-title">
                    Section 2: Project Information
                  </div>
                  <div className="question">
                    <div className="number">3.</div>
                    <div>
                      Is the project subject to additional legal phasing or
                      annexation?
                    </div>
                  </div>
                  <div className="summary-answer">
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute" style={{ color: "#D02F2F" }}>
                      No
                    </div>
                  </div>
                  <div className="question">
                    <div className="number">4.</div>
                    <div>
                      Has control of the HOA been turned over to the unit
                      purchasers?
                    </div>
                  </div>
                  <div className="summary-answer">
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute" style={{ color: "#D02F2F" }}>
                      Yes
                    </div>
                  </div>
                  <div className="question">
                    <div className="number">5.</div>
                    <div>
                      Are the common areas and amenities 100% complete for the
                      entire project?
                    </div>
                  </div>
                  <div className="summary-answer">
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute" style={{ color: "#D02F2F" }}>
                      Yes
                    </div>
                  </div>
                  <div className="form-title">
                    Section 3: Project: Presale and Investor Concentration
                  </div>
                  <div className="question">
                    <div className="number">5.</div>
                    <div># of Units Complete</div>
                  </div>
                  <div className="summary-answer">
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute" style={{ color: "#D02F2F" }}>
                      Equal to Total Units
                    </div>
                  </div>
                  {convResult >= 90 ? (
                    <></>
                  ) : (
                    <>
                      <div className="question">
                        <div className="number">6a.</div>
                        <div>
                          Are the developer-owned units that meet the rental
                          hold-back requirements as defined in the Selling
                          Guide?
                        </div>
                      </div>
                      <div className="summary-answer">
                        <div className="label">Answer:&nbsp;</div>
                        <div className="attribute" style={{ color: "#D02F2F" }}>
                          Yes
                        </div>
                      </div>
                      <div className="question">
                        <div className="number">6b.</div>
                        <div>How many developer-owned units are held?</div>
                      </div>
                      <div className="summary-answer">
                        <div className="label">Answer:&nbsp;</div>
                        <div className="attribute" style={{ color: "#D02F2F" }}>
                          <span>&#60;=</span>20% of total units
                        </div>
                      </div>
                    </>
                  )}

                  {/* <div className="question">
                    <div className="number">6c.</div>
                    <div>
                      Are the maintenance fees for developer-owned units
                      current?
                    </div>
                  </div>
                  <div className="summary-answer">
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute" style={{ color: "#D02F2F" }}>
                      Yes
                    </div>
                  </div>
                  <div className="question">
                    <div className="number">6d.</div>
                    <div>
                      Are there any active or pending special assessments?
                    </div>
                  </div>
                  <div className="summary-answer">
                    <div className="label">Answer:&nbsp;</div>
                    <div className="attribute" style={{ color: "#D02F2F" }}>
                      Yes
                    </div>
                  </div> */}
                </div>
                <div className="form-footer">
                  <button className="next-button" onClick={() => page3()}>
                    <ArrowBackIcon />
                    Previous Section
                  </button>
                  <div>
                    <button
                      className="exit-cert-btn"
                      onClick={() =>
                        (window.location.href =
                          "/project/phase/" + values.phaseId)
                      }
                    >
                      Exit Certification
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const Form1073 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState("back");
  console.log(formData);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [stateData, setStateData] = React.useState("");
  const fetchStateData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then(response => {
        setStateData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);
  const stateResult = Object.keys(stateData).map(key => stateData[key]);
  const stateList = stateResult.map(state => {
    return { value: state, label: state };
  });

  let [descriptionData, setDescriptionData] = React.useState("");
  const fetchDescriptionData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/BUILDING-TYPES`
      )
      .then(response => {
        setDescriptionData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchDescriptionData();
  }, [fetchDescriptionData]);
  const descriptionResult = Object.keys(descriptionData).map(
    key => descriptionData[key]
  );
  const descriptionList = descriptionResult.map(description => {
    return { value: description, label: description };
  });

  const [radio, setRadio] = React.useState(false);
  const [radioNo, setRadioNo] = React.useState(false);
  function radioCheck(event) {
    if (radio === false) {
      setRadio(true);
      setRadioNo(false);
    } else if (radio === true) {
      setRadio(false);
    }
  }
  function radioCheckNo(event) {
    if (radioNo === false) {
      setRadioNo(true);
      setRadio(false);
    } else if (radioNo === true) {
      setRadioNo(false);
    }
  }

  const [radioDeed, setRadioDeed] = React.useState(false);
  const [radioNoDeed, setRadioNoDeed] = React.useState(false);
  function radioCheckDeed(event) {
    if (radioDeed === false) {
      setRadioDeed(true);
      setRadioNoDeed(false);
    } else if (radioDeed === true) {
      setRadioDeed(false);
    }
  }
  function radioCheckNoDeed(event) {
    if (radioNoDeed === false) {
      setRadioNoDeed(true);
      setRadioDeed(false);
    } else if (radioNoDeed === true) {
      setRadioNoDeed(false);
    }
  }

  const [radiomf, setRadiomf] = React.useState(false);
  const [radioNomf, setRadioNomf] = React.useState(false);
  function radioCheckmf(event) {
    if (radiomf === false) {
      setRadiomf(true);
      setRadioNomf(false);
    } else if (radiomf === true) {
      setRadiomf(false);
    }
  }
  function radioCheckNomf(event) {
    if (radioNomf === false) {
      setRadioNomf(true);
      setRadiomf(false);
    } else if (radioNomf === true) {
      setRadioNomf(false);
    }
  }

  function validateNumNoStr(value) {
    let error;
    if (value && !/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateCity(value) {
    let error;
    if (value && !/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value)) {
      error = "Invalid City";
    }
    return error;
  }

  function validateDecimal7(value) {
    let error;
    if (value && !/^\d{0,5}(\.\d{1,2})?$/i.test(value)) {
      error =
        "Invalid Dollar Amount. 5 numbers allowed before decimal and 2 after decimal";
    }
    return error;
  }

  function validateDollar(value) {
    let error;
    if (value && !/^[0-9]+(\.[0-9]{1,2})?$/i.test(value)) {
      error = "Invalid Dollar Amount";
    }
    return error;
  }

  function validateNumNoStr1(value) {
    let error;
    if (value && !/^[1-9]\d*$/i.test(value)) {
      error = "Invalid must be a number greater than 0";
    }
    return error;
  }

  function validateYear(value) {
    let error;
    if (value && !/^((?!(0))[0-9]{4})$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateDecimal6(value) {
    let error;
    if (value && !/^\d{0,4}(\.\d{1,2})?$/i.test(value)) {
      error =
        "Invalid Dollar Amount. 4 numbers allowed before decimal and 2 after decimal";
    }
    return error;
  }

  function validateNumNoStr5(value) {
    let error;
    if (value && !/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ values, touched, errors }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add Established PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 4 of 9 - Appraisal (optional)
                </div>
                <div className="attribute-title">Property Address </div>
                <Field
                  name="propertyAddr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps = {{
                    'aria-label': "Property Address"
                  }}
                />
                <div className="attribute-title">Unit Number</div>
                <Field
                  name="unitNumber"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 8 }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.unitNumber && errors.unitNumber}
                  helperText={touched.unitNumber && errors.unitNumber}
                  inputProps = {{
                    'aria-label': "Unit Number"
                  }}
                />
                <div className="attribute-title">City</div>
                <Field
                  name="propertyCity"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128 }}
                  validate={validateCity}
                  variant="outlined"
                  error={touched.propertyCity && errors.propertyCity}
                  helperText={touched.propertyCity && errors.propertyCity}
                  inputProps = {{
                    'aria-label': "Property City"
                  }}
                />
                <div className="attribute-title">State</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="propertyState"
                    as="select"
                    aria-label='Property State'
                  >
                    <option value=" ">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">Zip Code</div>
                <Field
                  name="propertyZipCode"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 5 }}
                  validate={validateNumNoStr5}
                  variant="outlined"
                  error={touched.propertyZipCode && errors.propertyZipCode}
                  helperText={touched.propertyZipCode && errors.propertyZipCode}
                  inputProps = {{
                    'aria-label': "Property Zip code"
                  }}
                />
                <div className="attribute-title">County</div>
                <Field
                  name="county"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128 }}
                  validate={validateCity}
                  variant="outlined"
                  error={touched.county && errors.county}
                  helperText={touched.county && errors.county}
                  inputProps = {{
                    'aria-label': "County"
                  }}
                />
                <div className="attribute-title">Legal Description </div>
                <Field
                  name="legalDesc"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps = {{
                    'aria-label': "Legal Description"
                  }}
                />
                <div className="attribute-title">R.E. Taxes (Cost)</div>
                <Field
                  name="reTaxes"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 12 }}
                  validate={validateDecimal7}
                  variant="outlined"
                  error={touched.reTaxes && errors.reTaxes}
                  helperText={touched.reTaxes && errors.reTaxes}
                  inputProps = {{
                    'aria-label': "RE taxes"
                  }}
                />
                <div className="attribute-title">Project Name</div>
                <Field
                  name="projectName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps = {{
                    'aria-label': "Project Name"
                  }}
                />
                <div className="attribute-title">
                  Special Assessments (Cost)
                </div>
                <Field
                  name="specialAssmnt"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 12 }}
                  validate={validateDecimal7}
                  variant="outlined"
                  error={touched.specialAssmnt && errors.specialAssmnt}
                  helperText={touched.specialAssmnt && errors.specialAssmnt}
                  inputProps = {{
                    'aria-label': "Special Assmnt"
                  }}
                />
                <div className="attribute-title">HOA Fees (Cost)</div>
                <Field
                  name="hoaFees"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 12 }}
                  validate={validateDecimal6}
                  variant="outlined"
                  error={touched.hoaFees && errors.hoaFees}
                  helperText={touched.hoaFees && errors.hoaFees}
                  inputProps = {{
                    'aria-label': "HOA fees"
                  }}
                />
                <div className="attribute-title">Project Site</div>
                <div className="attribute-title">
                  FEMA Special Flood Hazard Area
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="femaSplFloodHzrdAreaInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radio}
                      onClick={radioCheck}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="femaSplFloodHzrdAreaInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNo}
                      onClick={radioCheckNo}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">FEMA Flood Zone</div>
                <Field
                  name="femaFloodZone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps = {{
                    'aria-label': "Fema Flood Zone"
                  }}
                />
                <div className="attribute-title">FEMA Map Number</div>
                <Field
                  name="femaMapNbr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.femaMapNbr && errors.femaMapNbr}
                  helperText={touched.femaMapNbr && errors.femaMapNbr}
                  inputProps = {{
                    'aria-label': "FEMA Map number"
                  }}
                />
                <div className="attribute-title">Project Description</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="projectDesc"
                    as="select"
                    aria-label='Project Description'
                  >
                    <option value=" ">Select Description</option>
                    {descriptionList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="section-title">General Description</div>
                <div className="attribute-title">Number of Stories</div>
                <Field
                  name="nbrStoriesCnt"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 5 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.nbrStoriesCnt && errors.nbrStoriesCnt}
                  helperText={touched.nbrStoriesCnt && errors.nbrStoriesCnt}
                  inputProps = {{
                    'aria-label': "NBR Stories Cnt"
                  }}
                />
                <div className="attribute-title">Year Built</div>
                <Field
                  name="yrBuilt1073"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 4 }}
                  validate={validateYear}
                  variant="outlined"
                  error={touched.yrBuilt1073 && errors.yrBuilt1073}
                  helperText={touched.yrBuilt1073 && errors.yrBuilt1073}
                  inputProps = {{
                    'aria-label': "Year built"
                  }}
                />
                <div className="attribute-title">Effective Age</div>
                <Field
                  name="effAge"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 10 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.effAge && errors.effAge}
                  helperText={touched.effAge && errors.effAge}
                  inputProps = {{
                    'aria-label': "Effective age"
                  }}
                />
                <div className="section-title">If Project is Completed</div>
                <div className="attribute-title">Number of Units </div>
                <Field
                  name="nbrUnitsCnt"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.nbrUnitsCnt && errors.nbrUnitsCnt}
                  helperText={touched.nbrUnitsCnt && errors.nbrUnitsCnt}
                  inputProps = {{
                    'aria-label': "Nbr units cnt"
                  }}
                />
                <div className="attribute-title">Number of Units Rented </div>
                <Field
                  name="nbrUnitsRentedCnt"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={touched.nbrUnitsRentedCnt && errors.nbrUnitsRentedCnt}
                  helperText={
                    touched.nbrUnitsRentedCnt && errors.nbrUnitsRentedCnt
                  }
                  inputProps = {{
                    'aria-label': "Nbr units rented cnt"
                  }}
                />
                <div className="attribute-title">
                  Number of Owner Occupied Units
                </div>
                <Field
                  name="nbrUnitsOwnrOccpdCnt"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 9 }}
                  validate={validateNumNoStr1}
                  variant="outlined"
                  error={
                    touched.nbrUnitsOwnrOccpdCnt && errors.nbrUnitsOwnrOccpdCnt
                  }
                  helperText={
                    touched.nbrUnitsOwnrOccpdCnt && errors.nbrUnitsOwnrOccpdCnt
                  }
                  inputProps = {{
                    'aria-label': "Nbr units ownr occpd cnt"
                  }}
                />
                <div className="attribute-title">
                  Was the project created by the conversion of an existing
                  building(s) into a condominium?{" "}
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="convExstngBldgInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radioDeed}
                      onClick={radioCheckDeed}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="convExstngBldgInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNoDeed}
                      onClick={radioCheckNoDeed}
                    />
                    No
                  </label>
                </div>
                {values.convExstngBldgInd === true ||
                values.convExstngBldgInd === "true" ? (
                  <>
                    <div className="attribute-title">Description</div>
                    <Field
                      name="convExstngBldgDesc"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 2000 }}
                      variant="outlined"
                      inputProps = {{
                        'aria-label': "Conv Exstng Bldg Desc"
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="attribute-title">
                  Is there any commercial space in the project?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="radio-box"
                >
                  <label>
                    <Field
                      name="commSpaceInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="true"
                      checked={radiomf}
                      onClick={radioCheckmf}
                      inputProps = {{
                        'aria-label': "Comm space Ind"
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      name="commSpaceInd"
                      as={Radio}
                      type="radio"
                      variant="outlined"
                      value="false"
                      checked={radioNomf}
                      onClick={radioCheckNomf}
                    />
                    No
                  </label>
                </div>
                {values.commSpaceInd === true ||
                values.commSpaceInd === "true" ? (
                  <>
                    <div className="attribute-title">Description</div>
                    <Field
                      name="commSpaceDesc"
                      as={TextField}
                      className="attribute-input"
                      size="small"
                      fullWidth={true}
                      inputProps={{ maxLength: 2000 }}
                      variant="outlined"
                      inputProps = {{
                        'aria-label': "Comm Space Desc"
                      }}
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="section-title">Appraiser</div>
                <div className="attribute-title">
                  Address of Property Appraised
                </div>
                <Field
                  name="subjPropAppraisdAddr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps = {{
                    'aria-label': "Subj Prop Appraisd Addr"
                  }}
                />
                <div className="attribute-title">
                  Appraised Value of Subject Property
                </div>
                <Field
                  name="subjPropAppraisdVal"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  validate={validateDollar}
                  variant="outlined"
                  error={
                    touched.subjPropAppraisdVal && errors.subjPropAppraisdVal
                  }
                  helperText={
                    touched.subjPropAppraisdVal && errors.subjPropAppraisdVal
                  }
                  inputProps = {{
                    'aria-label': "Subj Prop Appraisal Val"
                  }}
                />

                <div className="bottom-button-container">
                  <Button
                    type="submit"
                    className="next-button"
                    onClick={() => setDirection("forward")}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    type="submit"
                    className="previous-button"
                    onClick={() => setDirection("back")}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{fill: "red"}} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

Form1073.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};

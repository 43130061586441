import React from "react";
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'; 
import { makeStyles } from "@material-ui/core/styles";
import { isLenderRole, isTPORole } from "../../redux/actions/userAction"

  {/* 
    This functional component is responsible for rendering the cancel certification button on the certification page
    The props expected by this component are:  
      - cancelCertification(required), 
      - status (required)
*/}

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    float: "right",
    backgroundColor: "#f2f2f2" ,
    position: '-webkit-sticky',
    position: 'sticky',
    top:40
  }
});
export const CancelButton = props => {
  const classes = useStyles();
  const isIncomplete =  props.status && (props.status === "Incomplete");
  return <>
    { isLenderRole() || isTPORole() ?
      <Button variant="outlined"  className={classes.button} onClick={()=>{props.cancelCertification()}}>
        <CloseIcon fontSize="small" />
        {"Cancel Certification"}
    </Button> 
    : ''
    }
  </>
}
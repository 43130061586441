import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'react-bootstrap/Modal'
import { Button, Radio } from '@material-ui/core';
import FormStepper from '../FormStepper';
import axios from 'axios';
import '../../AddEstablishedPers/AddEstablishedPers.css';
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";


export const Form1071 = ({ formData, setFormData, nextStep, prevStep, step }) => {
    const [direction, setDirection] = useState('back');

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const cccExposureProtectedByEndorsementsIndicator = () => {
        var cccExposureProtectedByEndorsementsIndicator = document.querySelector('input[name = "constructionCodeChangeIndicator"]:checked');
        if(cccExposureProtectedByEndorsementsIndicator != null){  
            console.log(cccExposureProtectedByEndorsementsIndicator.value)
            if(cccExposureProtectedByEndorsementsIndicator.value == "true")
            {   
                return (
                <>
                    <div id="radio-group" className="attribute-title">Is this exposure protected by the appropriate endorsements?</div>
                    <div role="group" aria-labelledby="radio-group" className="radio-box">
                        <label>
                            <Field type="radio" as={Radio} as={Radio} name="cccExposureProtectedByEndorsementsIndicator" value="true" />
                            Yes
                        </label>
                        <label>
                            <Field type="radio" as={Radio} as={Radio} name="cccExposureProtectedByEndorsementsIndicator" value="false" />
                            No
                        </label>
                    </div>
                </>
                )
            } 
            else if(cccExposureProtectedByEndorsementsIndicator.value != "true")
            {
                return ""
            }
        }
    }
    const boilerInsuranceCoverageAmount = () => {
        var boilerInsuranceCoverageAmount = document.querySelector('input[name = "boilerInsPolicyIndicator"]:checked');
        if(boilerInsuranceCoverageAmount != null){  
            console.log(boilerInsuranceCoverageAmount.value)
            if(boilerInsuranceCoverageAmount.value == "true")
            {   
                return (
                <>
                    <div className="attribute-title">Amount</div>
                    <Field 
                        name="boilerInsuranceCoverageAmount" 
                        as={TextField} 
                        className="page-attribute"
                        variant="outlined"
                        validate={validateDecimal10}
                        inputProps={{ maxLength: 15 }}
                    />
                </>
                )
            } 
            else if(boilerInsuranceCoverageAmount.value != "true")
            {
                return ""
            }
        }
    }
    const liabilityInsuranceCoverageAmount = () => {
        var liabilityInsuranceCoverageAmount = document.querySelector('input[name = "liabiltyInsPolicyIndicator"]:checked');
        if(liabilityInsuranceCoverageAmount != null){  
            console.log(liabilityInsuranceCoverageAmount.value)
            if(liabilityInsuranceCoverageAmount.value == "true")
            {   
                return (
                <>
                    <div className="attribute-title">Amount</div>
                    <Field 
                        name="liabilityInsuranceCoverageAmount" 
                        as={TextField} 
                        className="page-attribute"
                        variant="outlined"
                        validate={validateDecimal10}
                        inputProps={{ maxLength: 15 }}
                    />
                </>
                )
            } 
            else if(liabilityInsuranceCoverageAmount.value != "true")
            {
                return ""
            }
        }
    }
    const floodInsuranceCoverageAmount = () => {
        var floodInsuranceCoverageAmount = document.querySelector('input[name = "floodInsPolicyIndicator"]:checked');
        if(floodInsuranceCoverageAmount != null){  
            console.log(floodInsuranceCoverageAmount.value)
            if(floodInsuranceCoverageAmount.value == "true")
            {   
                return (
                <>
                    <div className="attribute-title">Amount</div>
                    <Field 
                        name="floodInsuranceCoverageAmount" 
                        as={TextField} 
                        className="page-attribute"
                        variant="outlined"
                        validate={validateDecimal10}
                        inputProps={{ maxLength: 15 }}
                    />
                </>
                )
            } 
            else if(floodInsuranceCoverageAmount.value != "true")
            {
                return ""
            }
        }
    }
    const fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd = () => {
        var fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd = document.querySelector('input[name = "fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd"]:checked');
        if(fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd != null){  
            console.log(fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd.value)
            if(fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd.value == "false")
            {   
                return (
                <>
                    <div id="radio-group" className="attribute-title">Are the financial controls that justify a lesser amount among those specified in the Fannie Mae Selling Guide, and is the coverage at least equal to the sum of three months’ assessments on all units in the project?</div>
                    <div role="group" aria-labelledby="radio-group" className="radio-box">
                        <label>
                            <Field type="radio" as={Radio} as={Radio} name="fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd" value="true" />
                            Yes
                        </label>
                        <label>
                            <Field type="radio" as={Radio} as={Radio} name="fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd" value="false" />
                            No
                        </label>
                    </div>
                </>
                )
            } 
            else if(fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd.value != "false")
            {
                return ""
            }
        }
    }

    const [radio, setRadio] = React.useState(false);
    const [radioNo, setRadioNo] = React.useState(false);
    function radioCheck(event) {
      if(radio === false) {
        setRadio(true)
        setRadioNo(false)
      } else if(radio === true){
        setRadio(false)
      }
    }
    function radioCheckNo(event) {
      if(radioNo === false) {
        setRadioNo(true)
        setRadio(false)
      } else if(radioNo === true){
        setRadioNo(false)
      }
    }
  
    const [radioDeed, setRadioDeed] = React.useState(false);
    const [radioNoDeed, setRadioNoDeed] = React.useState(false);
    function radioCheckDeed(event) {
      if(radioDeed === false) {
        setRadioDeed(true)
        setRadioNoDeed(false)
      } else if(radioDeed === true){
        setRadioDeed(false)
      }
    }
    function radioCheckNoDeed(event) {
      if(radioNoDeed === false) {
        setRadioNoDeed(true)
        setRadioDeed(false)
      } else if(radioNoDeed === true){
        setRadioNoDeed(false)
      }
    }
  
    const [radiomf, setRadiomf] = React.useState(false);
    const [radioNomf, setRadioNomf] = React.useState(false);
    function radioCheckmf(event) {
      if(radiomf === false) {
        setRadiomf(true)
        setRadioNomf(false)
      } else if(radiomf === true){
        setRadiomf(false)
      }
    }
    function radioCheckNomf(event) {
      if(radioNomf === false) {
        setRadioNomf(true)
        setRadiomf(false)
      } else if(radioNomf === true){
        setRadioNomf(false)
      }
    }
    
    const [radiomnd, setRadiomnd] = React.useState(false);
    const [radioNomnd, setRadioNomnd] = React.useState(false);
    function radioCheckmnd(event) {
      if(radiomnd === false) {
        setRadiomnd(true)
        setRadioNomnd(false)
      } else if(radiomnd === true){
        setRadiomnd(false)
      }
    }
    function radioCheckNomnd(event) {
      if(radioNomnd === false) {
        setRadioNomnd(true)
        setRadiomnd(false)
      } else if(radioNomnd === true){
        setRadioNomnd(false)
      }
    }
  
    const [radioincdnt, setRadioincdnt] = React.useState(false);
    const [radioNoincdnt, setRadioNoincdnt] = React.useState(false);
    function radioCheckincdnt(event) {
      if(radioincdnt === false) {
        setRadioincdnt(true)
        setRadioNoincdnt(false)
      } else if(radioincdnt === true){
        setRadioincdnt(false)
      }
    }
    function radioCheckNoincdnt(event) {
      if(radioNoincdnt === false) {
        setRadioNoincdnt(true)
        setRadioincdnt(false)
      } else if(radioNoincdnt === true){
        setRadioNoincdnt(false)
      }
    }
  
    const [radiosupprt, setRadiosupprt] = React.useState(false);
    const [radioNosupprt, setRadioNosupprt] = React.useState(false);
    function radioChecksupprt(event) {
      if(radiosupprt === false) {
        setRadiosupprt(true)
        setRadioNosupprt(false)
      } else if(radiosupprt === true){
        setRadiosupprt(false)
      }
    }
    function radioCheckNosupprt(event) {
      if(radioNosupprt === false) {
        setRadioNosupprt(true)
        setRadiosupprt(false)
      } else if(radioNosupprt === true){
        setRadioNosupprt(false)
      }
    }
  
    const [radioproj100, setRadioproj100] = React.useState(false);
    const [radioNoproj100, setRadioNoproj100] = React.useState(false);
    function radioCheckproj100(event) {
      if(radioproj100 === false) {
        setRadioproj100(true)
        setRadioNoproj100(false)
      } else if(radioproj100 === true){
        setRadioproj100(false)
      }
    }
    function radioCheckNoproj100(event) {
      if(radioNoproj100 === false) {
        setRadioNoproj100(true)
        setRadioproj100(false)
      } else if(radioNoproj100 === true){
        setRadioNoproj100(false)
      }
    }
  
    const [radiosubj, setRadiosubj] = React.useState(false);
    const [radioNosubj, setRadioNosubj] = React.useState(false);
    function radioChecksubj(event) {
      if(radiosubj === false) {
        setRadiosubj(true)
        setRadioNosubj(false)
      } else if(radiosubj === true){
        setRadiosubj(false)
      }
    }
    function radioCheckNosubj(event) {
      if(radioNosubj === false) {
        setRadioNosubj(true)
        setRadiosubj(false)
      } else if(radioNosubj === true){
        setRadioNosubj(false)
      }
    }
  
    const [radiolegally, setRadiolegally] = React.useState(false);
    const [radioNolegally, setRadioNolegally] = React.useState(false);
    function radioChecklegally(event) {
      if(radiolegally === false) {
        setRadiolegally(true)
        setRadioNolegally(false)
      } else if(radiolegally === true){
        setRadiolegally(false)
      }
    }
    function radioCheckNolegally(event) {
      if(radioNolegally === false) {
        setRadioNolegally(true)
        setRadiolegally(false)
      } else if(radioNolegally === true){
        setRadioNolegally(false)
      }
    }
  
    const [radioplanned, setRadioplanned] = React.useState(false);
    const [radioNoplanned, setRadioNoplanned] = React.useState(false);
    function radioCheckplanned(event) {
      if(radioplanned === false) {
        setRadioplanned(true)
        setRadioNoplanned(false)
      } else if(radioplanned === true){
        setRadioplanned(false)
      }
    }
    function radioCheckNoplanned(event) {
      if(radioNoplanned === false) {
        setRadioNoplanned(true)
        setRadioplanned(false)
      } else if(radioNoplanned === true){
        setRadioNoplanned(false)
      }
    }
  
    const [radiotrnsfr, setRadiotrnsfr] = React.useState(false);
    const [radioNotrnsfr, setRadioNotrnsfr] = React.useState(false);
    function radioChecktrnsfr(event) {
      if(radiotrnsfr === false) {
        setRadiotrnsfr(true)
        setRadioNotrnsfr(false)
      } else if(radiotrnsfr === true){
        setRadiotrnsfr(false)
      }
    }
    function radioCheckNotrnsfr(event) {
      if(radioNotrnsfr === false) {
        setRadioNotrnsfr(true)
        setRadiotrnsfr(false)
      } else if(radioNotrnsfr === true){
        setRadioNotrnsfr(false)
      }
    }
  
    const [radioconvrsn, setRadioconvrsn] = React.useState(false);
    const [radioNoconvrsn, setRadioNoconvrsn] = React.useState(false);
    function radioCheckconvrsn(event) {
      if(radioconvrsn === false) {
        setRadioconvrsn(true)
        setRadioNoconvrsn(false)
      } else if(radioconvrsn === true){
        setRadioconvrsn(false)
      }
    }
    function radioCheckNoconvrsn(event) {
      if(radioNoconvrsn === false) {
        setRadioNoconvrsn(true)
        setRadioconvrsn(false)
      } else if(radioNoconvrsn === true){
        setRadioNoconvrsn(false)
      }
    }
  
    const [radiofullGut, setRadiofullGut] = React.useState(false);
    const [radioNofullGut, setRadioNofullGut] = React.useState(false);
    function radioCheckfullGut(event) {
      if(radiofullGut === false) {
        setRadiofullGut(true)
        setRadioNofullGut(false)
      } else if(radiofullGut === true){
        setRadiofullGut(false)
      }
    }
    function radioCheckNofullGut(event) {
      if(radioNofullGut === false) {
        setRadioNofullGut(true)
        setRadiofullGut(false)
      } else if(radioNofullGut === true){
        setRadioNofullGut(false)
      }
    }
  
    const [radiostructrlly, setRadiostructrlly] = React.useState(false);
    const [radioNostructrlly, setRadioNostructrlly] = React.useState(false);
    function radioCheckstructrlly(event) {
      if(radiostructrlly === false) {
        setRadiostructrlly(true)
        setRadioNostructrlly(false)
      } else if(radiostructrlly === true){
        setRadiostructrlly(false)
      }
    }
    function radioCheckNostructrlly(event) {
      if(radioNostructrlly === false) {
        setRadioNostructrlly(true)
        setRadiostructrlly(false)
      } else if(radioNostructrlly === true){
        setRadioNostructrlly(false)
      }
    }

    function validateDate(value) {
      let error;
      if (value && +new Date(value) < +new Date("1776-01-01")) {
        error = "Date cannot be before Jan 1, 1776.";
      }
      return error;
    }

    const minDate = updateMinDate();
    
    function updateMinDate() {
      let date = new Date('1776-01-01');
      return date.toISOString().slice(0, 10);
    }  
  
    const [radiorepairs, setRadiorepairs] = React.useState(false);
    const [radioNorepairs, setRadioNorepairs] = React.useState(false);
    function radioCheckrepairs(event) {
      if(radiorepairs === false) {
        setRadiorepairs(true)
        setRadioNorepairs(false)
      } else if(radiorepairs === true){
        setRadiorepairs(false)
      }
    }
    function radioCheckNorepairs(event) {
      if(radioNorepairs === false) {
        setRadioNorepairs(true)
        setRadiorepairs(false)
      } else if(radioNorepairs === true){
        setRadioNorepairs(false)
      }
    }

    function validateDecimal10(value) {
        let error;
         if (value && !/^\d{0,8}(\.\d{1,2})?$/i.test(value)) {
          error = 'Invalid Dollar Amount. 8 numbers allowed before decimal and 2 after decimal';
        }
        return error;
      }

      function validateDecimal8(value) {
        let error;
         if (value && !/^\d{0,6}(\.\d{1,2})?$/i.test(value)) {
          error = 'Invalid Dollar Amount. 6 numbers allowed before decimal and 2 after decimal';
        }
        return error;
      }

      function validateDollar(value) {
        let error;
        if (value && !/^[0-9]+(\.[0-9]{1,2})?$/i.test(value)) {
          error = 'Invalid Dollar Amount';
        }
        return error;
      }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          direction === 'back' ? prevStep() : nextStep();
        }}
      >
      {({ values, errors, touched }) => (
        <Form className="parent-form">
        <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">For Condo Project: {values.projectId}</div>
              </div>
          
              <div className="field-lower-container">
                <div className="section-title">Section 4 of 9 - Statement of Insurance and Fidelity Coverage (optional)</div>
        
                <div className="attribute-title">Expiration date of master or blanket insurance policy</div>
                <Field 
                    name="masterOrBlanketInsuranceExpirationDate" 
                    as={TextField} 
                    className="attribute-input"
                    size="small"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps ={{
                      'aria-label': 'Master or blanket insurance expiration date',
                       'min': minDate
                    }}
                    validate={validateDate}
                    error={touched.masterOrBlanketInsuranceExpirationDate && errors.masterOrBlanketInsuranceExpirationDate}
                    helperText={touched.masterOrBlanketInsuranceExpirationDate && errors.masterOrBlanketInsuranceExpirationDate}
                />
                <div id="radio-group" className="attribute-title">Are the Master or Blanket Type Fire and Extended Coverage Insurance in Force?</div>
                <div role="group" aria-labelledby="radio-group" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="mbFireAndCoverageInsuranceInForceIndicator" value="true" checked = {radio} onClick={radioCheck}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="mbFireAndCoverageInsuranceInForceIndicator" value="false" checked = {radioNo} onClick={radioCheckNo}/>
                        No
                    </label>
                </div>
                <div id="radio-group-1" className="attribute-title">Is the amount of insurance equal to 100% of the current replacement cost of the insurable improvements including unit interior improvements and betterments (if a condominium project) or 100% of the current replacement cost of the insurable improvements to the common areas (if a PUD)?	</div>
                <div role="group" aria-labelledby="radio-group-1" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="insuranceEqualsReplacementCostIndicator" value="true" checked = {radioDeed} onClick={radioCheckDeed}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="insuranceEqualsReplacementCostIndicator" value="false" checked = {radioNoDeed} onClick={radioCheckNoDeed}/>
                        No
                    </label>
                </div>
                <div className="attribute-title">The amount of such insurance in force in $</div>
                <Field 
                    name="fireAndExtCoverageInsuranceAmount" 
                    as={TextField} 
                    className="attribute-input"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    validate={validateDecimal10}
                    inputProps={{ maxLength: 15 }}
                    error={touched.fireAndExtCoverageInsuranceAmount && errors.fireAndExtCoverageInsuranceAmount}
                    helperText={touched.fireAndExtCoverageInsuranceAmount && errors.fireAndExtCoverageInsuranceAmount}
                    inputProps ={{
                      'aria-label': 'Fire and Ext Coverage Insurance amount'
                    }}
                />
                <div className="policy-header">The Policy Includes:</div>
                <div id="radio-group-2" className="attribute-title">Special Condominium Endorsement	</div>
                <div role="group" aria-labelledby="radio-group-2" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="specialCondominiumEndorsementIndicator" value="true" checked = {radiomf} onClick={radioCheckmf}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="specialCondominiumEndorsementIndicator" value="false" checked = {radioNomf} onClick={radioCheckNomf}/>
                        No
                    </label>
                </div>
                <div id="radio-group-3" className="attribute-title">Standard Mortgage Clause or Equivalent	</div>
                <div role="group" aria-labelledby="radio-group-3" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="standardMortgageClauseIndicator" value="true" checked = {radiomnd} onClick={radioCheckmnd}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="standardMortgageClauseIndicator" value="false" checked = {radioNomnd} onClick={radioCheckNomnd}/>
                        No
                    </label>
                </div>
                <div id="radio-group-4" className="attribute-title">Agreed Amount Endorsement	</div>
                <div role="group" aria-labelledby="radio-group-4" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="agreedAmountEndorsementIndicator" value="true" checked = {radioincdnt} onClick={radioCheckincdnt}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="agreedAmountEndorsementIndicator" value="false" checked = {radioNoincdnt} onClick={radioCheckNoincdnt}/>
                        No
                    </label>
                </div>
                <div id="radio-group-5" className="attribute-title">Inflation Guard Endorsement	</div>
                <div role="group" aria-labelledby="radio-group-5" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="inflationGuardEndorsementIndicator" value="true" checked = {radiosupprt} onClick={radioChecksupprt}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="inflationGuardEndorsementIndicator" value="false" checked = {radioNosupprt} onClick={radioCheckNosupprt}/>
                        No
                    </label>
                </div>
                <div id="radio-group-6" className="attribute-title">Replacement Cost Endorsement	</div>
                <div role="group" aria-labelledby="radio-group-6" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="replacementCostEndorsementIndicator" value="true" checked = {radioproj100} onClick={radioCheckproj100}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="replacementCostEndorsementIndicator" value="false" checked = {radioNoproj100} onClick={radioCheckNoproj100}/>
                        No
                    </label>
                </div>
                <div id="radio-group-7" className="attribute-title">Other Special Endorsement	</div>
                <div role="group" aria-labelledby="radio-group-7" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="otherSpecialEndorsementIndicator" value="true" checked = {radiosubj} onClick={radioChecksubj}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="otherSpecialEndorsementIndicator" value="false" checked = {radioNosubj} onClick={radioCheckNosubj}/>
                        No
                    </label>
                </div>

                <div id="radio-group-8" className="attribute-title">Is the project subject to a substantial construction code change that would be operative in the event of partial destruction of the project by an insured hazard?	</div>
                <div role="group" aria-labelledby="radio-group-8" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="constructionCodeChangeIndicator" value="true" checked = {radiolegally} onClick={radioChecklegally}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="constructionCodeChangeIndicator" value="false" checked = {radioNolegally} onClick={radioCheckNolegally}/>
                        No
                    </label>
                    {cccExposureProtectedByEndorsementsIndicator()}
                </div>
                <div id="radio-group-9" className="attribute-title">If a condominium, is there an insurance trustee?</div>
                <div role="group" aria-labelledby="radio-group-9" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="condoInsuranceTrusteeIndicator" value="true" checked = {radioplanned} onClick={radioCheckplanned}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="condoInsuranceTrusteeIndicator" value="false" checked = {radioNoplanned} onClick={radioCheckNoplanned}/>
                        No
                    </label>
                </div>
                <div id="radio-group-10" className="attribute-title">If the project contains a steam boiler, is a boiler insurance policy in effect?</div>
                <div role="group" aria-labelledby="radio-group-10" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="boilerInsPolicyIndicator" value="true" checked = {radiotrnsfr} onClick={radioChecktrnsfr}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="boilerInsPolicyIndicator" value="false" checked = {radioNotrnsfr} onClick={radioCheckNotrnsfr}/>
                        No
                    </label>
                    {boilerInsuranceCoverageAmount()}
                    {errors.boilerInsuranceCoverageAmount && touched.boilerInsuranceCoverageAmount ? <div style={{ color: "red", paddingLeft: "30px" }}>{errors.boilerInsuranceCoverageAmount}</div> : null}
                </div>
                <div id="radio-group-11" className="attribute-title">Is a public liability insurance policy in force?</div>
                <div role="group" aria-labelledby="radio-group-11" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="liabiltyInsPolicyIndicator" value="true" checked = {radioconvrsn} onClick={radioCheckconvrsn}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="liabiltyInsPolicyIndicator" value="false" checked = {radioNoconvrsn} onClick={radioCheckNoconvrsn}/>
                        No
                    </label>
                    {liabilityInsuranceCoverageAmount()}
                    {errors.liabilityInsuranceCoverageAmount && touched.liabilityInsuranceCoverageAmount ? <div style={{ color: "red", paddingLeft: "30px" }}>{errors.liabilityInsuranceCoverageAmount}</div> : null}
                </div>
                <div id="radio-group-12" className="attribute-title">Is flood insurance in force?</div>
                <div role="group" aria-labelledby="radio-group-12" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="floodInsPolicyIndicator" value="true" checked = {radiofullGut} onClick={radioCheckfullGut}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="floodInsPolicyIndicator" value="false" checked = {radioNofullGut} onClick={radioCheckNofullGut}/>
                        No
                    </label>
                    {floodInsuranceCoverageAmount()}
                    {errors.floodInsuranceCoverageAmount && touched.floodInsuranceCoverageAmount ? <div style={{ color: "red", paddingLeft: "30px" }}>{errors.floodInsuranceCoverageAmount}</div> : null}
                </div>
                <div id="radio-group-13" className="attribute-title">Is Fidelity Coverage in force in an amount at least equal to the estimated maximum amount of funds that will be in the custody of the Homeowners’ Association or its management agent at any time during the term of the coverage?</div>
                <div role="group" aria-labelledby="radio-group-13" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd" value="true" checked = {radiostructrlly} onClick={radioCheckstructrlly}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd" value="false" checked = {radioNostructrlly} onClick={radioCheckNostructrlly}/>
                        No
                    </label>
                    {fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd()}
                </div>
                <div className="attribute-title">Association Coverage</div>
                <Field 
                    name="associationCoverageAmount" 
                    as={TextField} 
                    className="attribute-input"
                    size="small"
                    fullWidth={true}
                    variant="outlined"validate={validateDecimal8}
                    inputProps={{ maxLength: 15 }}
                    error={touched.associationCoverageAmount && errors.associationCoverageAmount}
                    helperText={touched.associationCoverageAmount && errors.associationCoverageAmount}
                    inputProps ={{
                      'aria-label': 'Association coverage amount'
                    }}
                />
                <div className="attribute-title">Management Coverage</div>
                <Field 
                    name="managementCoverageAmount" 
                    as={TextField} 
                    className="attribute-input"
                    size="small"
                    fullWidth={true}
                    variant="outlined"validate={validateDecimal8}
                    inputProps={{ maxLength: 15 }}
                    error={touched.managementCoverageAmount && errors.managementCoverageAmount}
                    helperText={touched.managementCoverageAmount && errors.managementCoverageAmount}
                    inputProps ={{
                      'aria-label': 'Management coverage amount'
                    }}
                />
                <div id="radio-group-14" className="attribute-title">Do the constituent documents of the project require the Homeowners’ Association to maintain the insurance and fidelity coverage required by Fannie Mae?</div>
                <div role="group" aria-labelledby="radio-group-14" className="radio-box">
                    <label>
                        <Field type="radio" as={Radio} name="hoaMaintainInsuranceIndicator" value="true" checked = {radiorepairs} onClick={radioCheckrepairs}/>
                        Yes
                    </label>
                    <label>
                        <Field type="radio" as={Radio} name="hoaMaintainInsuranceIndicator" value="false" checked = {radioNorepairs} onClick={radioCheckNorepairs}/>
                        No
                    </label>
                </div>
                <div className="attribute-title">Exceptions</div>
                <Field 
                    name="exceptions1071"
                    as={TextField} 
                    className="attribute-input"
                    size="small"
                    fullWidth={true}
                    inputProps={{ maxLength: 2000 }}
                    variant="outlined"
                    inputProps ={{
                      'aria-label': 'Exceptions'
                    }}
                />
        <div className="bottom-button-container">
            
            <Button
              type="submit"
              className="next-button"
              onClick={() => setDirection('forward')}
            >
                Next Section             
                <ArrowForwardIcon />
            </Button>
            <Button
              type="submit"
              className="previous-button"
              onClick={() => setDirection('back')}
            >
                <ArrowBackIcon />
              Previous Section    
            </Button>
            </div>
        </div>
    </div>
    <div className="right-cancel-container">
                <Button
                  className="cancel-pers-button"
                  onClick={handleShow}
                >
                  <CloseIcon style={{fill:"red"}} />
                  Cancel PERS Request          
                </Button>
                <Modal show={show} className="my-modal" onHide={handleClose}>
                  <Modal.Header className="the-modal-header" closeButton>
                    <Modal.Title className="the-modal-title">Cancel PERS Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="the-modal-body">All of your progress will be lost. Are you sure you want to cancel?</Modal.Body>
                  <Modal.Footer className="the-modal-footer">
                    <Button className="go-back-button" onClick={handleClose}>
                      Go Back
                    </Button>
                    <Button className="cancel-modal-button" 
                        href={`/`}>
                      Cancel PERS Request
                    </Button>
                  </Modal.Footer>
                </Modal>
            </div>
        </Form>
      )}
      </Formik>
    </>
  );
};
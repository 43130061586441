import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Project.css";
import FannieMaeIcon from "../../images/icons/Shape@1x.svg";

const ConfirmModal = ({
  showConfirmModal,
  setShowConfirmModal,
  getSaveConfirmation
}) => {
  const [reasonChange, setReasonChange] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const handleClose = () => {
    setReasonChange("");
    setShowConfirmModal(false);
  };

  return (
    <>
      <Modal
        show={showConfirmModal}
        onHide={handleClose}
        centered
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "1070"
        }}
      >
        <Modal.Header closeButton style={{ alignItems: "center" }}>
          <div>
            <img
              alt="Fannie Mae Icon"
              src={FannieMaeIcon}
              style={{ marginRight: "10px", height: "16px", width: "16px" }}
            />
          </div>
          <Modal.Title
            className="status-title"
            id="contained-modal-title-vcenter"
          >
            Save Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirm-modal-body">
          <form className="w-75 pl-3 ">
            <div class="form-group">
              <label className="reason-label">Changes Made & Reason</label>
              <textarea
                className="status-textarea"
                aria-label="Reason for Change"
                id="exampleFormControlTextarea1"
                rows="3"
                maxLength="5000"
                value={reasonChange}
                style={{ paddingLeft: 8, paddingTop: 8 }}
                onChange={(e) => {
                  if (e.target.value && e.target.value !== '') {
                    setReasonError(false);
                    setReasonChange(e.target.value);
                  } else {
                    setReasonChange(e.target.value);
                    setReasonError(true);
                  }
                }}
              ></textarea>
              {reasonError ? (
                <div
                  style={{
                    color: "#ff0000",
                  }}
                >
                  Please enter reason of change.
                </div>
              ) : (<></>)}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="delete-modal-footer">
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="delete"
            onClick={() => {
              if (reasonChange && reasonChange !== '') {
                getSaveConfirmation(reasonChange);
                setReasonChange("");
              } else {
                setReasonError(true);
              }
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;

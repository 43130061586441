import { TYPE_RESULTS } from "../actions/ActionType";

export const initialState = {
  types: []
};
export const typeReducer = (type = initialState, action) => {
  switch (action.type) {
    case TYPE_RESULTS:
      return {
        types: action.payload
      };
    default:
      return type;
  }
};

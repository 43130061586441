import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import Dropzone from "../../Dropzone/Dropzone";

export const Attachments = ({ formData, nextStep, prevStep, step, persId }) => {
  const {
    //perslen
    projectId
  } = formData;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 8 of 9 - Attachments
                </div>
                <Dropzone
                  projectvalue={projectId}
                  phasevalue={null}
                  persvalue={persId}
                  incStepCounter={nextStep}
                  decStepCounter={prevStep}
                  type={'new'}
                  page={'pers'}
                />

                <div className="bottom-button-container">
                  {/* <Button
                    type="submit"
                    className="next-button"
                    onClick={() => nextStep()}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button> */}
                </div>
              </div>
            </div>
            <div className="right-cancel-container"></div>
          </Form>
        )}
      </Formik>
    </>
  );
};

Attachments.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};

import React, { useState, useEffect, useCallback } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field } from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function Pers1029({
  collapseKey,
  sectionData,
  persId,
  projectId
}) {
  const [isEdit, setEdit] = useState(false);
  const [formState, setFormState] = useState({});
  const [sourceOfSales, setSourceOfSales] = useState([]);
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const fetchSalesSourceInformationData = useCallback(async () => {
    try {
      

      const { data: salesSources } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/SOURCE_SALES_INFO_1029`
      );
      const transformedRes = Object.keys(salesSources).map(key => ({
        key: salesSources[key],
        value: salesSources[key]
      }));

      setSourceOfSales(transformedRes);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchSalesSourceInformationData();
  }, []);

  useEffect(() => {
    setFormState({ ...sectionData });
  }, [sectionData]);


  const handleSaveEdit = async () => {
    setApiErr("");
    setInternalErr(false);

    try {
      const body = {
        persId,
        projectId,
        phaseId: null,
        pers1029: { ...formState }
      };
      console.log("formState", formState);
      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updatePersProjectAndForms/1029`,
        body
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setFormState({ ...sectionData });
    }
  };

  const handleChange = event => {
    let _formData = { ...formState };
    _formData[event.target.name] = event.target.value;
    setFormState({ ..._formData });
  };

  return (
    <Accordion defaultActiveKey="1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">
              Warranty of Project Presales (1029)
            </p>
          </ContextAwareToggle>
           {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => {
                  toggleEdit(false);
                  setApiErr("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                toggleEdit(true);
                setInternalErr(false);
                setApiErr("");
              }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
          ): null }
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Overview</div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Total Number of Units in the Subject Legal/Construction Phase"
                      name="totalUnitsCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of Units in the Subject Legal/Construction Phase Sold To: Principal Residence Purchasers"
                      name="unitsSoldToPrincipalResidencePurchasersCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of Units in the Subject Legal/Construction Phase Sold To: Second Home Purchasers"
                      name="unitsSoldToSecondHomePurchasersCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of Units in the Subject Legal/Construction Phase Sold To: Investor Purchasers"
                      name="unitsSoldToInvestorPurchasersCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total Number of Units In Prior Legal/Construction Phases"
                      name="priorTotalUnitsCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Number of Units in Prior Legal/Construction Phase(s) Sold To Principal Residence Purchasers"
                      name="priorUnitsSoldToPrincipalResidencePurchasersCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />

                    <Field
                      label="Number of Units in Prior Legal/Construction Phase(s) Sold To Second Home Purchasers"
                      name="priorUnitsSoldToSecondHomePurchasersCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />

                    <Field
                      label="Number of Units in Prior Legal/Construction Phase(s) Sold To Investor Purchasers"
                      name="priorUnitsSoldToInvestorPurchasersCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />

                    <Field
                      label="Indicate the Source of sales information"
                      name="salesSourceInformation"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={sourceOfSales}
                      dropdownWidth="200px"
                    />

                    <Field
                      label="Provide the number of closed sales of units"
                      name="closedSalesUnitsCount"
                      formData={formState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

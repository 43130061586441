import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Grid, Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import "../../AddEstablishedPers/AddEstablishedPers.css";

// import "./index.css";

export const EnterSuspension = ({
  formData,
  setFormData,
  nextStep,
  nextStep2,
  prevStep,
  prevStep2,
  prevStep3,
  prevStep4,
  step,
  persId,
}) => {
  const { suspensionReason } = formData;
  const [editorErr, setEditorErr] = useState();
  const [phaseName, setPhaseName] = useState([]);
  const [phaseExpDate, setPhaseExpDate] = useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    let phaseNameTmp = [];
    if (formData.phaseDecisions) {
      console.log("formData.phaseDecisions", formData.phaseDecisions);
      for (let phase of formData.phaseDecisions) {
        if (phase.persDecison == "Suspension of the Application") {
          phaseNameTmp.push(phase.phaseName);
          setPhaseExpDate(phase.persExpiration);
        }
      }

      setPhaseName(phaseNameTmp);
    }
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    let tab = RegExp("\\t", "g");
    let validatedData = value.toString().replace(tab, " ");
    let _formData = { ...formData };
    _formData[name] = validatedData;
    setFormData({ ..._formData });
  };

  function checkPrevPhaseRedirection(data) {
    let IsConditionAvail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Available";
    });

    let IsConditionUnavail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Unavailable";
    });

    let IsFinalApproval = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Final Project Approval";
    });

    let IsSuspention = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Suspension of the Application";
    });

    let IsIneligible = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    // resetPhaseCondition();

    if (IsConditionAvail) {
      prevStep();
    } else if (IsFinalApproval) {
      prevStep();
    } else if (IsConditionUnavail) {
      prevStep2();
    } else if (IsIneligible) {
      prevStep3();
    } else if (IsSuspention) {
      prevStep3();
    }
  }

  function checkNextPhaseRedirection() {
    let IsIneligible = formData.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    if (IsIneligible) {
      nextStep();
    } else {
      nextStep2();
    }
  }

  function checkReasonValidation(event) {
    // formData.ineligibilityExplaination = event.target.value;
    if (!event.target.value) {
      setEditorErr("Required");
    } else {
      setEditorErr("");
    }
  }

  function checkAllValidation() {
    if (!formData.suspensionReason) {
      setEditorErr("Required");
    } else {
      setEditorErr("");

      if (
        formData.decisionLevel == "Entire project" ||
        formData.decisionLevel == "Entire Project"
      ) {
        nextStep2();
      } else {
        checkNextPhaseRedirection();
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          checkAllValidation(values);
          console.log("formdata ----", formData);
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Decision</div>
                <div className="small-title">For PERS ID: {persId}</div>
              </div>
              <div className="field-lower-container">
                <div className="section-title">
                  Step 5 : Enter Suspension Details
                </div>

                {formData.decisionLevel == "Entire project" ||
                formData.decisionLevel == "Entire Project" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="attribute-title">
                        Project/Phases with Suspension Decision
                      </div>
                      <div style={{ marginLeft: 50 }}>Entire Project</div>
                    </div>
                    <div>
                      <div className="attribute-title"> Expiration Date</div>
                      <div style={{ textAlign: "right", marginRight: 50 }}>
                        {" "}
                        {formData.persExpiration}{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="attribute-title">
                        Project/Phases with Suspension Decision
                      </div>
                      <div style={{ marginLeft: 50 }}>
                        {phaseName.map((item, index) => (
                          <div>{item}</div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="attribute-title"> Expiration Date</div>
                      <div style={{ textAlign: "right", marginRight: 50 }}>
                        {phaseExpDate}
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ marginLeft: 40, marginRight: 50 }}>
                  <div>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      Reason for suspension{" "}
                      <span style={{ color: "#ff0000" }}>*</span>
                    </div>
                    <textarea
                      className="form-control"
                      placeholder="Type reason for suspension"
                      maxLength="2000"
                      type="text"
                      style={{ height: 150 }}
                      name="suspensionReason"
                      value={suspensionReason}
                      onChange={(e) => {
                        handleChange(e);
                        checkReasonValidation(e);
                      }}
                    />

                    <div className="mendatory-sign">{editorErr}</div>
                    <span
                      style={{
                        display: "inline-block",
                        marginRight: "5px",
                        float: "right",
                        fontSize: "14px",
                        color: "#787979",
                      }}
                    >{`${(suspensionReason || "").length}/2000`}</span>
                  </div>
                </div>

                <div className="bottom-button-container">
                  <div>
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      onClick={() => {}}
                    >
                      Next Decision
                    </Button>
                  </div>
                  <Button
                    type="button"
                    className="previous-button review-preview"
                    onClick={() => {
                      if (
                        formData.decisionLevel == "Entire project" ||
                        formData.decisionLevel == "Entire Project"
                      ) {
                        prevStep3();
                      } else {
                        checkPrevPhaseRedirection(formData);
                      }
                    }}
                  >
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container"></div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import { combineReducers } from "redux";
import { filterReducer } from "./FilterReducer";
import { dialogueReducer } from "./dialogueReducer";
import { styleReducer } from "./styleReducer";
import { typeReducer } from "./typeReducer";
import { addProjectReducer } from "./addProjectReducer";
import { addNewPhaseReducer } from "./addNewPhaseReducer";
import { type1026Reducer } from "./type1026Reducer";
import { rights1026Reducer } from "./rights1026Reducer";
import { stories1026Reducer } from "./stories1026Reducer";
import { structures1026Reducer } from "./structures1026Reducer";
import { phases1026Reducer } from "./phases1026Reducer";
import { constructions1026Reducer } from "./constructions1026Reducer";
import { conversions1026Reducer } from "./conversions1026Reducer";
import { units1026Reducer } from "./units1026Reducer";
import { getUserDetailsReducer } from "./userReducer";


export const rootReducer = combineReducers({
  filterData: filterReducer,
  dialogueReducer: dialogueReducer,
  styleReducer: styleReducer,
  type1026Reducer: type1026Reducer,
  rights1026Reducer: rights1026Reducer,
  stories1026Reducer: stories1026Reducer,
  structures1026Reducer: structures1026Reducer,
  phases1026Reducer: phases1026Reducer,
  constructions1026Reducer: constructions1026Reducer,
  conversions1026Reducer: conversions1026Reducer,
  units1026Reducer: units1026Reducer,
  typeReducer: typeReducer,
  addProjectReducer: addProjectReducer,
  addNewPhaseReducer: addNewPhaseReducer,
  getUserDetailsReducer: getUserDetailsReducer
});

import React from "react";
import IndividualChildPhaseRestriction from "./IndividualChildPhaseRestriction";

const ChildPhaseRestrictions = ({ list, type }) => {
  let restrictionsList = <h3>Loading...</h3>;

  if (list) {
    restrictionsList = list.map((m, i) => (
      <IndividualChildPhaseRestriction key={i} item={m} type={type} />
    ));
  }

  return <div>{restrictionsList}</div>;
};
export default ChildPhaseRestrictions;

import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { Formik, Form, Field } from "formik";

import AddModalRest from "./add-modal-rest";
import FormStepper from "../FormStepper";
import CannedRestrication from "./canned-restriction";
import CannedRestricationPhase from "./canned-restriction-phase";
import AddModalRestPhase from "./add-modal-rest-phase";

export const EnterRestriction = ({
  nextStep,
  nextStep2,
  nextStep3,
  prevStep,
  prevStep2,
  formData,
  setFormData,
  persId,
  projectId,
  step,
}) => {
  const [activeTab, setActiveTab] = useState("pre");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [singleRest, setSingleRest] = useState(false);
  const { addedRestrictions = [], restrictions = [] } = formData;
  const handleAddClose = () => setShowAdd(false);
  const handleAddClick = (newRestriction) => {
    const _formData = {
      ...formData,
      addedRestrictions: [
        ...(formData.addedRestrictions || []),
        { ...newRestriction },
      ],
    };
    setFormData(_formData);
    setShowAdd(false);
  };
  const handleAddShow = () => setShowAdd(true);

  const setRestrictionsData = (_restrictions, name) => {
    const _formData = { ...formData, [name]: [..._restrictions] };
    setFormData(_formData);
  };
  function changeTab(params) {
    setActiveTab(params);
  }

  function checkBackRedirection(data) {
    if (data.persDecisionStatus == "Final Project Approval") {
      prevStep2();
    } else if (data.persDecisionStatus == "Conditional/Available") {
      prevStep();
    } else if (data.persDecisionStatus == "Conditional/Unavailable") {
      prevStep();
    }
  }

  //  Phase logic

  const [phIndex, setPhIndex] = useState();
  const [showAddPhase, setShowAddPhase] = useState(false);

  React.useEffect(() => {
    if (formData.phaseDecisions) {
      console.log(formData.phaseDecisions);
      for (let phase of formData.phaseDecisions) {
        phase.selectedTab = "pre";
      }
    }
  }, []);

  const setRestrictionsPhaseData = (_restrictions, name, i) => {
    if (formData.applySamePhaseDeliveryRestriction) {
      formData.phaseDecisions &&
        formData.phaseDecisions.map((phase, index) => {
          if (
            phase.persDecison == "Conditional/Available" ||
            phase.persDecison == "Final Project Approval"
          ) {
            const _formData = {
              ...phase,
              [name]: [..._restrictions],
            };
            formData.phaseDecisions[index] = _formData;
          }
        });
    } else {
      const _formData = {
        ...formData.phaseDecisions[i],
        [name]: [..._restrictions],
      };
      formData.phaseDecisions[i] = _formData;
    }
    setFormData(formData);
    setSinglePhase();
  };

  const handleAddPhaseClose = () => setShowAddPhase(false);
  const handleAddPhaseShow = (i) => {
    setPhIndex(i);
    setShowAddPhase(true);
  };

  const handleAddPhaseClick = (newRestriction, i) => {
    if (formData.applySamePhaseDeliveryRestriction) {
      formData.phaseDecisions &&
        formData.phaseDecisions.map((phase, index) => {
          if (
            phase.persDecison == "Conditional/Available" ||
            phase.persDecison == "Final Project Approval"
          ) {
            const _formData = {
              ...phase,
              addedRestrictions: [
                ...(phase.addedRestrictions || []),
                { ...newRestriction },
              ],
            };
            formData.phaseDecisions[index] = _formData;
          }
        });
    } else {
      const _formData = {
        ...formData.phaseDecisions[i],
        addedRestrictions: [
          ...(formData.phaseDecisions[i].addedRestrictions || []),
          { ...newRestriction },
        ],
      };
      formData.phaseDecisions[i] = _formData;
    }
    console.log(formData.phaseDecisions);
    setFormData(formData);
    setShowAddPhase(false);
    setSinglePhase();
  };

  function changeTabPhase(params, index) {
    index = formData.applySamePhaseDeliveryRestriction
      ? getSinglePhaseIndex()
      : index;
    formData.phaseDecisions[index].selectedTab = params;
    setFormData((prevExpiry) => ({
      ...prevExpiry,
      formData,
    }));
  }

  function checkPhaseBackRedirection(data) {
    let IsConditionAvail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Available";
    });

    let IsConditionUnavail = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Conditional/Unavailable";
    });

    let IsFinalApproval = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Final Project Approval";
    });

    if (IsConditionAvail) {
      prevStep();
    } else if (IsConditionUnavail) {
      prevStep();
    } else if (IsFinalApproval) {
      prevStep2();
    }
  }

  function checkRedirection(data) {
    if (data.persDecisionStatus == "Ineligible") {
      nextStep();
    } else {
      nextStep3();
    }
  }

  function checkPhaseRedirection(data) {
    let IsIneligible = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Ineligible";
    });

    let IsSuspention = data.phaseDecisions.some(function (el) {
      return el.persDecison === "Suspension of the Application";
    });

    if (IsSuspention) {
      nextStep();
    } else if (IsIneligible) {
      nextStep2();
    } else {
      nextStep3();
    }
  }

  const checkIsEditActive = () => {
    setIsEditable(!isEditable);
  };

  const getCheckedPhaseRestrictions = (phase) => {
    let isSelected;
    if (phase.addedRestrictions) {
      isSelected = phase.addedRestrictions.find((eachCond) => {
        if (eachCond.isEdit) {
          return true;
        }
      });
    }
    if (phase.restrictions && phase.restrictions.length && !isSelected) {
      isSelected = phase.restrictions.find((e) => e.isEdit === true);
    }
    return Boolean(isSelected);
  };

  const validatePhases = () => {
    const formPhaseDes = formData.phaseDecisions;
    const getCond = formPhaseDes.map((phase) => {
      return getCheckedPhaseRestrictions(phase);
    });
    return getCond;
  };

  const validateEntireProject = () => {
    let isSelected;
    if (formData.addedRestrictions) {
      isSelected = formData.addedRestrictions.find((eachRes) => {
        if (eachRes.isEdit) {
          return true;
        }
      });
    }
    if (formData.restrictions.length && !isSelected) {
      isSelected = formData.restrictions.find((e) => e.isEdit === true);
    }
    return Boolean(isSelected);
  };

  const setSingleRestrictionsData = () => {
    console.log(formData);
    setSingleRest(!singleRest);
    formData.applySamePhaseDeliveryRestriction = !singleRest;
    if (formData.applySamePhaseDeliveryRestriction) {
      formData.phaseDecisions &&
        formData.phaseDecisions.map((phase, index) => {
          if (
            phase.persDecison == "Conditional/Available" ||
            phase.persDecison == "Final Project Approval"
          ) {
            const _formData = {
              ...phase,
              addedRestrictions: [],
              restrictions: resetRestrictions(phase.restrictions),
            };
            formData.phaseDecisions[index] = _formData;
          }
        });
    }
    formData.singlePhaseRestriction = getValidRestriction();
    setFormData(formData);
  };

  const resetRestrictions = (restrictions) => {
    for (let restriction of restrictions) {
      restriction.isSelected = false;
    }
    return restrictions;
  };

  const getValidRestriction = () => {
    return formData.phaseDecisions.filter(
      (phase) =>
        phase.persDecison == "Conditional/Available" ||
        phase.persDecison == "Final Project Approval"
    )[0];
  };

  const setSinglePhase = () => {
    formData.singlePhaseRestriction = getValidRestriction();
    setFormData(formData);
  };

  const getSinglePhaseIndex = () => {
    console.log(formData.singlePhaseRestriction);
    let i = formData.phaseDecisions.findIndex(
      (phase) => phase.phaseName === formData.singlePhaseRestriction.phaseName
    );
    console.log(i);
    return i;
  };

  return (
    <div className="parent-form">
      <FormStepper step={step} />
      <Grid container spacing={2} className="dialoguClass">
        <Grid item xs={9}>
          <Grid item xs={12}>
            <div
              className="top-title-container"
              style={{ marginBlock: 30, padding: 0 }}
            >
              <div className="big-title">Add New PERS Decision</div>
              <div className="small-title">For PERS ID: {persId}</div>
            </div>
          </Grid>
          <div className="section">
            <Grid item xs={12}>
              <div className="section-title">
                Step 4 : Enter Delivery Restrictions
                {formData.phaseDecisions?.filter(
                  (phase) =>
                    phase.persDecison == "Conditional/Available" ||
                    phase.persDecison == "Final Project Approval"
                ).length > 1 ? (
                  <>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        float: "right",
                      }}
                    >
                      <input
                        type="checkbox"
                        className={"checkboxfield"}
                        id={"setSingleRest"}
                        name={"setSingleRest"}
                        checked={formData.applySamePhaseDeliveryRestriction}
                        onChange={(event) => {
                          setSingleRestrictionsData();
                        }}
                      />
                      <div style={{ fontSize: 14, marginLeft: 7 }}>
                        Apply same Restriction to all phases
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
            <Formik
              initialValues={formData}
              enableReinitialize
              onSubmit={() => {
                setIsSubmitted(true);
                if (
                  formData.decisionLevel == "Entire project" ||
                  formData.decisionLevel == "Entire Project"
                ) {
                  if (!validateEntireProject()) {
                    let preConSelect = [];
                    if (formData.restrictions) {
                      preConSelect = formData.restrictions.filter(
                        (c) => c.isSelected
                      );
                    }

                    let addedConSelect = [];
                    if (formData.addedRestrictions) {
                      addedConSelect = formData.addedRestrictions.map(
                        ({ restrictionName, restrictionDesc }) => ({
                          restrictionName,
                          restrictionDesc,
                          isAddedCondn: true,
                        })
                      );
                    }

                    checkRedirection(formData);
                  }
                } else {
                  if (!validatePhases().includes(true)) {
                    checkPhaseRedirection(formData);
                  }
                }
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  {formData.decisionLevel == "Entire project" ||
                  formData.decisionLevel == "Entire Project" ? (
                    <Grid item xs={12}>
                      <div
                        className="btn-tab-container"
                        style={{ borderTop: 0 }}
                      >
                        <Grid
                          item
                          xs={12}
                          className="condition-container"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "20px",
                          }}
                        >
                          <div
                            className="title-txt"
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <i className="fa fa-info info-icon"></i>
                            <span className="con-txt">
                              Apply Restrictions
                            </span>{" "}
                            <div style={{ clear: "both" }}></div>
                          </div>
                          <div>
                            <Button onClick={handleAddShow} className="button">
                              <Add
                                style={{
                                  color: "#0F5AF0",
                                }}
                              />{" "}
                              Create custom restriction
                            </Button>
                          </div>
                        </Grid>
                        <div className="tab-view-container">
                          <Button
                            className={
                              activeTab == "pre"
                                ? "tab-title active-tab"
                                : "tab-title"
                            }
                            onClick={() => changeTab("pre")}
                          >
                            Pre-canned ({formData.restrictions.length})
                          </Button>
                          <Button
                            className={
                              activeTab == "add"
                                ? "tab-title active-tab"
                                : "tab-title"
                            }
                            onClick={() => changeTab("add")}
                          >
                            Custom ({addedRestrictions.length})
                          </Button>
                        </div>
                      </div>
                      <div style={{ height: "200px", overflowY: "auto" }}>
                        {activeTab == "pre" ? (
                          <CannedRestrication
                            // checkIsEdit={checkIsEdit}
                            formData={formData}
                            name="restrictions"
                            setRestrictionsData={setRestrictionsData}
                            isPre
                          />
                        ) : (
                          <CannedRestrication
                            // checkIsEdit={checkIsEdit}
                            formData={formData}
                            name="addedRestrictions"
                            setRestrictionsData={setRestrictionsData}
                          />
                        )}
                      </div>

                      <AddModalRest
                        showAdd={showAdd}
                        handleAddClose={handleAddClose}
                        handleAddClick={handleAddClick}
                        formData={formData}
                      />
                      {validateEntireProject() && isSubmitted ? (
                        <p className="text-danger">
                          Please save or cancel the restriction
                        </p>
                      ) : null}
                    </Grid>
                  ) : formData.applySamePhaseDeliveryRestriction ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: 20 }}
                      >
                        <Grid
                          item
                          xs={2}
                          style={{ padding: 10, marginTop: 180 }}
                        >
                          {formData.phaseDecisions.map((phase) =>
                            phase.persDecison == "Conditional/Available" ||
                            phase.persDecison == "Final Project Approval" ? (
                              <>
                                <div>{phase.phaseName}</div>
                              </>
                            ) : (
                              <></>
                            )
                          )}
                        </Grid>
                        <Grid item xs={10}>
                          <div
                            className="btn-tab-container"
                            style={{ borderTop: 0, marginTop: 30 }}
                          >
                            <Grid
                              item
                              xs={12}
                              className="condition-container"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "20px",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                className="title-txt"
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <i className="fa fa-info info-icon"></i>
                                <span
                                  className="con-txt"
                                  style={{ lineHeight: 1, fontSize: 15 }}
                                >
                                  Apply Restrictions
                                </span>{" "}
                                <div style={{ clear: "both" }}></div>
                              </div>
                              <div>
                                <Button
                                  onClick={() =>
                                    handleAddPhaseShow(getSinglePhaseIndex)
                                  }
                                  className="button"
                                  style={{
                                    width: 145,
                                    height: 35,
                                    lineHeight: 1.2,
                                    fontSize: 10,
                                  }}
                                >
                                  <Add
                                    style={{
                                      color: "#0F5AF0",
                                    }}
                                  />{" "}
                                  Create custom restriction
                                </Button>
                              </div>
                            </Grid>
                            <div className="tab-view-container">
                              <Button
                                className={
                                  formData.singlePhaseRestriction.selectedTab ==
                                  "pre"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() =>
                                  changeTabPhase("pre", getSinglePhaseIndex)
                                }
                                style={{
                                  fontSize: 14,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                Pre-canned (
                                {
                                  formData.singlePhaseRestriction.restrictions
                                    .length
                                }
                                )
                              </Button>
                              <Button
                                className={
                                  formData.singlePhaseRestriction.selectedTab ==
                                  "add"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() =>
                                  changeTabPhase("add", getSinglePhaseIndex)
                                }
                              >
                                Custom (
                                {
                                  formData.singlePhaseRestriction
                                    .addedRestrictions.length
                                }
                                )
                              </Button>
                            </div>
                          </div>
                          <div
                            style={{
                              height: "200px",
                              overflowY: "auto",
                              paddingLeft: 10,
                              borderWidth: 1,
                              borderColor: "#000000",
                              borderStyle: "solid",
                            }}
                          >
                            {formData.singlePhaseRestriction.selectedTab ==
                            "pre" ? (
                              <CannedRestricationPhase
                                checkIsEditActive={checkIsEditActive}
                                formData={formData.singlePhaseRestriction}
                                name="restrictions"
                                setRestrictionsPhaseData={
                                  setRestrictionsPhaseData
                                }
                                multiIndex={getSinglePhaseIndex}
                                isPre
                              />
                            ) : (
                              <CannedRestricationPhase
                                formData={formData.singlePhaseRestriction}
                                checkIsEditActive={checkIsEditActive}
                                name="addedRestrictions"
                                setRestrictionsPhaseData={
                                  setRestrictionsPhaseData
                                }
                                multiIndex={getSinglePhaseIndex}
                              />
                            )}
                          </div>

                          <AddModalRestPhase
                            showAddPhase={showAddPhase}
                            handleAddPhaseClose={handleAddPhaseClose}
                            handleAddPhaseClick={handleAddPhaseClick}
                            formData={formData}
                            multiIndex={phIndex}
                          />
                          {getCheckedPhaseRestrictions(
                            formData.singlePhaseRestriction
                          ) && isSubmitted ? (
                            <p className="text-danger">
                              Please save or cancel the restriction
                            </p>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    formData.phaseDecisions &&
                    formData.phaseDecisions.map((phase, index) =>
                      phase.persDecison == "Conditional/Available" ||
                      phase.persDecison == "Final Project Approval" ? (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", marginBottom: 20 }}
                        >
                          <Grid
                            item
                            xs={2}
                            style={{ padding: 10, marginTop: 180 }}
                          >
                            {phase.phaseName}
                          </Grid>
                          <Grid item xs={10}>
                            <div
                              className="btn-tab-container"
                              style={{ borderTop: 0, marginTop: 30 }}
                            >
                              <Grid
                                item
                                xs={12}
                                className="condition-container"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "20px",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  className="title-txt"
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <i className="fa fa-info info-icon"></i>
                                  <span
                                    className="con-txt"
                                    style={{ lineHeight: 1, fontSize: 15 }}
                                  >
                                    Apply Restrictions
                                  </span>{" "}
                                  <div style={{ clear: "both" }}></div>
                                </div>
                                <div>
                                  <Button
                                    onClick={() => handleAddPhaseShow(index)}
                                    className="button"
                                    style={{
                                      width: 145,
                                      height: 35,
                                      lineHeight: 1.2,
                                      fontSize: 10,
                                    }}
                                  >
                                    <Add
                                      style={{
                                        color: "#0F5AF0",
                                      }}
                                    />{" "}
                                    Create custom restriction
                                  </Button>
                                </div>
                              </Grid>
                              <div className="tab-view-container">
                                <Button
                                  className={
                                    phase.selectedTab == "pre"
                                      ? "tab-title active-tab"
                                      : "tab-title"
                                  }
                                  onClick={() => changeTabPhase("pre", index)}
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                  }}
                                >
                                  Pre-canned ({phase.restrictions.length})
                                </Button>
                                <Button
                                  className={
                                    phase.selectedTab == "add"
                                      ? "tab-title active-tab"
                                      : "tab-title"
                                  }
                                  onClick={() => changeTabPhase("add", index)}
                                >
                                  Custom ({phase.addedRestrictions.length})
                                </Button>
                              </div>
                            </div>
                            <div
                              style={{
                                height: "200px",
                                overflowY: "auto",
                                paddingLeft: 10,
                                borderWidth: 1,
                                borderColor: "#000000",
                                borderStyle: "solid",
                              }}
                            >
                              {phase.selectedTab == "pre" ? (
                                <CannedRestricationPhase
                                  checkIsEditActive={checkIsEditActive}
                                  formData={phase}
                                  name="restrictions"
                                  setRestrictionsPhaseData={
                                    setRestrictionsPhaseData
                                  }
                                  multiIndex={index}
                                  isPre
                                />
                              ) : (
                                <CannedRestricationPhase
                                  formData={phase}
                                  checkIsEditActive={checkIsEditActive}
                                  name="addedRestrictions"
                                  setRestrictionsPhaseData={
                                    setRestrictionsPhaseData
                                  }
                                  multiIndex={index}
                                />
                              )}
                            </div>

                            <AddModalRestPhase
                              showAddPhase={showAddPhase}
                              handleAddPhaseClose={handleAddPhaseClose}
                              handleAddPhaseClick={handleAddPhaseClick}
                              formData={formData}
                              multiIndex={phIndex}
                            />
                            {getCheckedPhaseRestrictions(phase) &&
                            isSubmitted ? (
                              <p className="text-danger">
                                Please save or cancel the restriction
                              </p>
                            ) : null}
                          </Grid>
                        </Grid>
                      ) : null
                    )
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px",
                      width: "100%",
                      marginTop: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <Button
                      className="previous-button"
                      onClick={() => {
                        console.log("formData", formData);
                        // prevStep();

                        if (
                          formData.decisionLevel == "Entire project" ||
                          formData.decisionLevel == "Entire Project"
                        ) {
                          checkBackRedirection(formData);
                        } else {
                          checkPhaseBackRedirection(formData);
                        }
                      }}
                    >
                      Previous Section
                    </Button>
                    {/* onClick={incrementCounter} */}
                    <Button type="submit" className="next-button next-btn-save">
                      Next Section
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
        <Grid item xs={3}>
          {/* <button
            className="delete-modal-button"
            style={{
              float: "right",
              border: 0
            }}
            onClick={() => openCancelPopup(true)}
          >
            <FaTimes
              className="edit-profile-icon"
              style={{
                marginRight: 5
              }}
            />{" "}
            Cancel
          </button> */}
        </Grid>
      </Grid>
    </div>
  );
};

import * as React from "react";
import PropTypes from "prop-types";
import "./DatePickerStyles.css";

const DatePicker = ({
  id,
  name,
  label,
  changeHandler,
  value,
  datePickerWidth,
  min,
  max
}) => {
  const [datePickerValue, setDatePickerValue] = React.useState("");
  React.useEffect(() => {
      setDatePickerValue(value);
  }, [value]);

  const onChangeHandler = event => {
    let e = event;
    e.target.value = event.target.value ? event.target.value : null;
    setDatePickerValue(e.target.value);
    changeHandler(e);
  };
  
  return (
    <div className="datePickerContainer">
      {label && (
        <div>
          <label htmlFor={id} className="datePickerLabel">
            {label}
          </label>
        </div>
      )}

      <div>
        <input
          type="date"
          style={{ width: datePickerWidth }}
          id={id}
          className="datePicker"
          name={name}
          value={datePickerValue}
          onChange={onChangeHandler}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  datePickerWidth: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  min: PropTypes.string,
  max: PropTypes.string
};

export default DatePicker;
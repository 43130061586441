import * as React from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import {
  FaChevronLeft,
  FaFilter,
  FaCaretDown,
  FaListUl,
  FaTh,
  FaSort,
} from "react-icons/fa";
import Cards from "../Cards/Cards";
import List from "../List/List";
import SearchFilter from "../SearchFilter/SearchFilter";
import "./SearchResults.css";
import {
  filterReducer,
  initialState,
} from "../../redux/reducers/FilterReducer";
import { filterResults } from "../../redux/actions/filterActions";
import EnhancedTable from "../List/EnhancedTable";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { MdSearch } from "react-icons/md";

const theme = createTheme({
  overrides: {
    MuiNativeSelect: {
      select: {
        padding: "5px 24px 5px 5px",
      },
      icon: {
        color: "#0D6DA9",
      },
    },
    MuiInput: {
      underline: {
        "&&&&:hover:before": {
          borderBottom: "none",
        },
        ":before": {
          borderBottom: "none",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
        "&:before": {
          borderBottom: "none",
        },
      },
    },
  },
});
export const sortBy = [
  { value: "projectName", label: "Condo Project" },
  { value: "street", label: "Address" },
  { value: "city", label: "City" },
  { value: "state", label: "State" },
  { value: "projectId", label: "projectId" },
  { value: "HOA_Tax_ID", label: "HOA Tax ID" },
];

const SearchResults = () => {
  let history = useHistory();
  const [searchResultsState, setSearchResultState] = React.useState({
    list: null,
    total: null,
    gridView: true,
    listView: false,
    error: null,
    originalList: null,
  });
  const [filterStates, setFilterStates] = React.useState([]);
  const [isOpenSearchFilterDialog, setopenSearchFilterDialog] =
    React.useState(false);
  const [sortValue, setSortBy] = React.useState("projectName");

  React.useEffect(() => {
    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/searchByCriteria/`,
        history.location.state
      )
      .then((response) => {
        setSearchResultState({
          ...searchResultsState,
          list: response.data.cpDetails,
          originalList: response.data.cpDetails,
          total: response.data.totalResults,
        });
      })
      .catch((error) => {
        let errList = <h1>No data found</h1>;
        if (error.response.status === 404 || error.response.status === 500) {
          //console.log("404 error log:", error.response)
          //err = error.response;
          //console.log(error)

          errList = error.response;
        }
        setSearchResultState({
          ...searchResultsState,
          error: errList,
        });
      });

    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then((response) => {
        const statesResponse = response.data;
        const states = [];
        for (const state in statesResponse) {
          states.push({
            key: statesResponse[state],
            value: statesResponse[state],
          });
        }
        states.unshift({ key: "", value: "Select State" });
        setFilterStates(states);
      })

      .catch((error) => {
        throw Error(error);
      });
  }, []);

  const handleFilteredData = (value, clear) => {
    setSearchResultState({
      ...searchResultsState,
      list: value,
    });
  };
  const renderProjects = () => {
    console.log(searchResultsState);
    return searchResultsState.total > 0 ? (
      <Cards sort={sortValue} list={searchResultsState.list} />
    ) : searchResultsState.total === 0 ? (
      <div className="no-search-container">
        <MdSearch className="no-search-icon" />
        <p className="no-search-line">
          No results found for{" "}
          <span className="no-search-project">
            {history.location.state.condoProjectName}
          </span>
        </p>
        <p className="no-search-line">
          Please try your search again, or add this project to Condo Go.
        </p>
        <Link to={`/addNewProject`}>
          <button className="no-search-button">Add New Project</button>
        </Link>
      </div>
    ) : (
      <div className="no-search-container">
        <MdSearch className="no-search-icon" />
        <p className="no-search-line">No results found</p>
        <p className="no-search-line">
          Please try your search again, or add a project to Condo Go.
        </p>
        <Link to={`/addNewProject`}>
          <button className="no-search-button">Add New Project</button>
        </Link>
      </div>
    );
  };

  const renderProjectsList = () => {
    return searchResultsState.list ? (
      <List list={searchResultsState.list} />
    ) : (
      <h1>Loading...</h1>
    );
  };

  const toggleGrid = (event) => {
    event.preventDefault();
    setSearchResultState({
      ...searchResultsState,
      gridView: true,
      listView: false,
    });
  };

  const handleFilterDialog = (state) => {
    // dispatch(filterResults({...state}))
    setopenSearchFilterDialog(!isOpenSearchFilterDialog);
  };
  const handleChange = (event) => {
    event.preventDefault();
    setSortBy({ sortValue: event.target.value });
  };
  const toggleList = (event) => {
    event.preventDefault();
    setSearchResultState({
      ...searchResultsState,
      listView: true,
      gridView: false,
    });
  };

  const { gridView, total, listView, list, originalList } = searchResultsState;

  return (
    <div>
      <div className="title-container">
        <button onClick={history.goBack} className="button-no-style">
          <FaChevronLeft className="chevron-left" />
        </button>
        <p className="search-results-text">Search Results</p>
        <p className="search-results-subtext">
          {total} results for "{history.location.state.condoProjectName}
          {history.location.state.condoPhaseId}
          {history.location.state.condoHoaTaxId}"
        </p>
      </div>
      <div className="card-container">
        <div className="filter-container">
          <div className="filter-button" onClick={handleFilterDialog}>
            <FaFilter className="filter-icon" />
            Filter
          </div>
        </div>
        <div className="sorting-container">
          {gridView === true ? (
            <div className="sort-by-text">Sorting By:</div>
          ) : (
            ""
          )}
          {/* {gridView === true ? <div className="sort-by-options">Relevance
                            <FaCaretDown className="sort-by-caret" />
                    </div> : ''} */}
          {gridView === true ? (
            <FormControl className="formControl">
              <MuiThemeProvider theme={theme}>
                <NativeSelect
                  value={sortValue.sortValue}
                  onChange={handleChange}
                  name="Relevance"
                  id="sortbyid"
                  className="sort-by-options"
                  inputProps={{ "aria-label": "Relevance" }}
                >
                  {sortBy.map((item, id) => (
                    <option key={id} label={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </NativeSelect>
              </MuiThemeProvider>
            </FormControl>
          ) : (
            ""
          )}
          <div className="grid-toggle">
            <button className="button-no-style" onClick={toggleList}>
              <FaListUl className="sort-by-list" />
            </button>
            <button className="button-no-style" onClick={toggleGrid}>
              <FaTh className="sort-by-grid" />
            </button>
          </div>
        </div>
        {gridView === true ? (
          <div className="projects-container">{renderProjects()}</div>
        ) : (
          ""
        )}
        {listView === true ? (
          <div className="projects-container">
            {/* <div className="list-filters">
                        <div className="filter-by-condo">Condo Project<FaSort className="sort-icon" /></div>
                        <div className="filter-by-status">Status<FaSort className="sort-icon" /></div>
                        <div className="filter-by-address"><FaSort className="sort-icon" />Address</div>
                        <div className="filter-by-city">City<FaSort className="sort-icon" /></div>
                        <div className="filter-by-state">State<FaSort className="sort-icon" /></div>
                        <div className="filter-by-projectid">Project ID<FaSort className="sort-icon" /></div>
                        <div className="filter-by-hoaid">HOA Tax ID<FaSort className="sort-icon" /></div>
                    </div>
                    {renderProjectsList()} */}
            <EnhancedTable list={searchResultsState.list} />
          </div>
        ) : (
          ""
        )}
        {isOpenSearchFilterDialog && (
          <SearchFilter
            isOpen={isOpenSearchFilterDialog}
            filterStates={filterStates}
            handleFilteredData={handleFilteredData}
            title={"Filter Search Results"}
            handleClose={() => handleFilterDialog()}
            searchResults={originalList}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResults;

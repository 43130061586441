import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';
import { Button, Radio } from '@material-ui/core';
import FormStepper from '../FormStepper';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';



export const Success = ({
  formData,
  step,
  persId
}) => {
  const [direction, setDirection] = useState('back');
  console.log(formData)
  const { projectId } =formData
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Formik
        initialValues={formData}
      >
      {({ values }) => (
        <Form className="parent-form">
            <FormStepper step={step}/><div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">For Condo Project: {values.projectId}</div>
              </div>
          
              <div className="success-lower-container">
                
                <div>
                    <CheckCircleOutlineRoundedIcon className="the-green-check"/>
                    {/* <div className="success-header">PERS Record Created Successfully!</div> */}
                    <div className="sub-success-header">Your attachments have been saved​</div>
                    <Button 
                        className="success-button"
                        href={`/project/pers/${persId}`}
                    >
                        Back to PERS Page​
                    </Button>
                </div>
            </div>
          </div>
      <div className="right-cancel-container"></div>

        </Form>
      )}
      </Formik>
    </>
  );
};

Success.propTypes = {
  formData: PropTypes.object.isRequired
};
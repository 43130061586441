import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field, DatePicker } from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function Section4({
  collapseKey,
  lender,
  states,
  persId, 
  projectId
}) {
  const [isEdit, setEdit] = useState(false);
  const [lenderState, setLenderState] = useState({});
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const fetchAndTransform = async endpointUrl => {
    const { data } = await axios.get(
      `${global.REACT_APP_API_ENDPOINT}${endpointUrl}`
    );
    return Object.keys(data).map(key => ({ key: data[key], value: data[key] }));
  };

  const fetchBuildingTypes = async () => {
    const _buildingTypes = await fetchAndTransform(
      "/condoMgr/lookUpValues/BUILDING-TYPES"
    );
    setBuildingTypes(_buildingTypes);
  };

  useEffect(() => {
    fetchBuildingTypes();
  }, []);

  useEffect(() => {
    setLenderState({ ...lender });
  }, [lender]);


  const handleSaveEdit = async () => {
    try {
      setApiErr("");
      setInternalErr(false);

      const body = {
        persId,
        projectId,
        pers1073: {
          ...lenderState
        }
      };
      const response = await axios.post(
        `${global.REACT_APP_API_ENDPOINT}/pers/updateEstablishedPersProjectAndForms/1073`,
        body
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setLenderState({ ...lender });
    }
  };

  const handleChange = event => {
    const { name, type, value, checked } = event.target;
    let _formData = { ...lenderState };
    _formData[name] = type === "checkbox" ? checked : value;
    setLenderState({ ..._formData });
  };

  return (
    <Accordion defaultActiveKey="1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">Appraisal (1073)</p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => {
                  toggleEdit(false);
                  setApiErr("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                toggleEdit(true);
                setInternalErr(false);
                setApiErr("");
              }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Subject</div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Property Address"
                      name="propertyAddr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="City"
                      name="propertyCity"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="ZIP Code"
                      name="propertyZipCode"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Legal Description"
                      name="legalDesc"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Project Name"
                      name="projectName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="HOA Fees (Cost)"
                      name="hoaFees"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="HOA Fees (Frequency)"
                      name="hoaFeesFreqncy"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={[
                        {
                          key: "Per Year",
                          value: "Per Year"
                        },
                        {
                          key: "Per Month",
                          value: "Per Month"
                        }
                      ]}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Unit #"
                      name="unitNumber"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="State"
                      name="propertyState"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={states}
                    />
                    <Field
                      label="County"
                      name="county"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="R.E. Taxes (Cost)"
                      name="reTaxes"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Special Assessments (Cost)"
                      name="specialAssmnt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Project Site</div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="FEMA Special Flood Hazard Area"
                      name="femaSplFloodHzrdAreaInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="FEMA Map #"
                      name="femaMapNbr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Project Description"
                      name="projectDesc"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={buildingTypes}
                    />
                    {lenderState["projectDesc"] === "Other" && (
                      <Field
                        label="Other"
                        name="projectDescAddtnl"
                        formData={lenderState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    )}
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="FEMA Flood Zone"
                      name="femaFloodZone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="FEMA Map Date"
                      name="femaMapDate"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    General Description
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Number of Stoires"
                      name="nbrStoriesCnt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Effective Age"
                      name="effAge"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Year Built"
                      name="yrBuilt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <div>If Project Completed</div>
                    <Field
                      label="Number of Units"
                      name="nbrUnitsCnt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of Units Rented"
                      name="nbrUnitsRentedCnt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Number of Owner Occupied Units"
                      name="nbrUnitsOwnrOccpdCnt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Was the project created by the conversion of an existing building(s) into a condominium?"
                      name="convExstngBldgInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    {lenderState["convExstngBldgInd"] && (
                      <Field
                        label="Describe the original use and the date of conversion"
                        name="convExstngBldgDesc"
                        formData={lenderState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    )}
                    <Field
                      label="Is there any commercial space in the project?"
                      name="commSpaceInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    {lenderState["commSpaceInd"] && (
                      <Field
                        label="Describe and indicate the overall percentage of the commercial space"
                        name="commSpaceDesc"
                        formData={lenderState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    )}
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Appraiser</div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Address of Property Appraised"
                      name="subjPropAppraisdAddr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Appraised Value of Subject Property"
                      name="subjPropAppraisdVal"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'

export const Page5 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  page3,
  convResult,
  midRuleType,
  setMidRuleType,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";



  function saveApi() {
    formData = page7tMultiSelectOptions(formData)
    let requestObject = certificationFormatter(formData);
    requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
        ? ""
        : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/lndrCertification/saveLenderCertificationFormsPhase`,
        requestObject
      )
      .then((response) => {
        if (response.status == 200) {
          //setDocId(response.data);
          setTimeout(() => {
            if (formData.phaseId === "proj") {
              window.location.href = "/project/" + formData.projectId;
            } else {
              window.location.href = "/project/phase/" + formData.phaseId;
            }
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            console.log(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("certification phase error", error);
      });
  }

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  const saveAndNext = (data) => {
    let values = data;
    // reset subquestions
    if (values.npeMasterAsscInd === "No") {
      values.npePayMnthyAssesLndrRevwPlanInd = "";
    }
    setFormData(values);
    nextStep();
  };

  function getRuleType() {
    if (formData.phaseId === "proj") {
      if (convResult < 90) {
        if (
          formData.piAddnPhsOrAnnxInd === "No" &&
          formData.piCntlHOATurnOverInd === "Yes" &&
          formData.piCommnAreasCompltInd === "Yes" &&
          formData.ppUnitsComplete === formData.ppTotalUnits &&
          formData.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
          formData.ppDevOwnedUnits / formData.ppTotalUnits <= 0.2
        ) {
          setMidRuleType("projectEst");
        } else {
          setMidRuleType("projectNew");
        }
      } else {
        if (
          formData.piAddnPhsOrAnnxInd === "No" &&
          formData.piCntlHOATurnOverInd === "Yes" &&
          formData.piCommnAreasCompltInd === "Yes" &&
          formData.ppUnitsComplete === formData.ppTotalUnits
        ) {
          setMidRuleType("projectEst");
        } else {
          setMidRuleType("projectNew");
        }
      }
    } else {
      setMidRuleType("");
    }
  }

  useEffect(() => {
    getRuleType();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
            (convResult >= 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={step} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={3} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits &&
                values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={step} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={4} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  {values.phaseId === "proj" ? (
                    <div
                      className="info-container"
                      style={{ padding: "0px 0px 0px 134px" }}
                    >
                      <>
                        <div className="type">Project Type</div>
                        <div className="attributes">
                          {status === "est" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectNew" ? (
                                <div>
                                  New
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>Established</div>
                              )}
                            </>
                          ) : status === "new" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectEst" ? (
                                <div>
                                  Established
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>New</div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      <div className="subheader">Section 4 OF 8</div>
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 5 OF 9</div>
                    </>
                  )}
                  <div className="title">New Project Eligibility</div>
                  <div className="subtitle"></div>
                </div>
                <div className="form-body">
                  <div className="question">
                    <div className="number">1.</div>{" "}
                    <div>Is there a master association?</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="npeMasterAsscInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="npeMasterAsscInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.npeMasterAsscInd && touched.npeMasterAsscInd && (
                      <div className="form-error">
                        {errors.npeMasterAsscInd}
                      </div>
                    )}
                  </div>
                  {values.npeMasterAsscInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          If the unit owners are required to pay monthly
                          assessments &gt;$50 to a separate master association,
                          has the lender reviewed the complete development plan
                          for the master, and confirmed the acceptability of the
                          project?(if not applicable, select Yes)
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="npePayMnthyAssesLndrRevwPlanInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="npePayMnthyAssesLndrRevwPlanInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.npePayMnthyAssesLndrRevwPlanInd &&
                          touched.npePayMnthyAssesLndrRevwPlanInd && (
                            <div className="form-error">
                              {errors.npePayMnthyAssesLndrRevwPlanInd}
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="question">
                    <div className="number">2.</div>{" "}
                    <div>
                      Do the project’s legal documents comply with each of the
                      requirements set forth in the
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                      >
                        {" "}
                        Selling Guide
                      </a>
                      ?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="npeLglDocsComplyInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="npeLglDocsComplyInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.npeLglDocsComplyInd &&
                      touched.npeLglDocsComplyInd && (
                        <div className="form-error">
                          {errors.npeLglDocsComplyInd}
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button className="save-exit-btn" onClick={saveApi}>
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>

                  {values.phaseId === "proj" ? (
                    <>
                      <button
                        className="next-button"
                        type="submit"
                        onClick={() => page3()}
                      >
                        <ArrowBackIcon />
                        Previous Section
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="next-button"
                        type="submit"
                        onClick={() => prevStep()}
                      >
                        <ArrowBackIcon />
                        Previous Section
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

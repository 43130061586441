import React, { useState, useRef } from "react";
import "../BannerCreator/BannerCreator.css";
import { Accordion, Card } from "react-bootstrap";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import RadioInput from "../RadioInput/RadioInput";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ReactSelect from "react-select";
import axios from "axios";
import MultiselectTwoSide from "react-multiselect-two-sides";
import {
  makeStyles
} from "@material-ui/core";
export const DuplicatePhaseForm = ({ index, phase, targetPhases, phasedropdownOption,
  setDuplicatePhaseCertification, formData, setFormData, init }) => {
  const [targetPhase, setTargetPhase] = useState(targetPhases);
  const [phaseDropdownOptions, setPhaseDropdownOptions] = useState(phasedropdownOption);
  const [entirePhase, setEntirePhase] = useState(phase.entirePhase);
  const [duplicateCertifications, setDuplicateCertifications] = useState(phase.certificationList);
  const [certificationList, setCertificationList] = useState([]);
  const [collapseKey, setCollapseKey] = useState("1");
  const [selectedCertification, setSelectedCertification] = React.useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [formInitialized, setFormInitialized] = useState(init);

  const radioStyles = makeStyles(theme => ({
    label: {
      fontSize: theme.typography.pxToRem(12),
      letterSpacing: "0.03333em",
      lineHeight: 1.66
    }
  }));
  const radioClasses = radioStyles();

  React.useEffect(() => {
    var phase1 = formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId);
    if (phase1 && phase1 !== null && phase1.length > 0) {
      var selected = formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId)[0].isWholePhase;
    } else {
      console.log('selected : ', null);
    }

    var ep = entirePhase ? entirePhase : formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId) ?
      formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId).isWholePhase === true ?
        'Yes' : 'No' : null

    if (formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId) &&
      formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId).length > 0 &&
      formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId)[0].selectedPhase &&
      formInitialized == "true"
    ) {
      var selectedPhaseInForm = formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId)[0].selectedPhase
      if (selectedPhaseInForm !== null) {
        formData.phaseDetails.forEach((cert) => {
          if ((cert.sourcePhase == Number(phase.phaseId) && cert.isWholePhase == false && cert.destinationPhase == null)) {
            cert.selectedPhase = null;
          }
        })

        setTargetPhase(formData.phasedropdownOption.filter((o) => o.value == selectedPhaseInForm)[0])
        updateCertificationList(formData.phasedropdownOption.filter((o) => o.value == selectedPhaseInForm)[0]);
        setFormInitialized("false");
      }
    }
  })

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const updateCertificationList = async (value) => {
    setSelectedPhase(value.value);

    let certList = [];
    formData.duplicatePhaseCertifications.map(c => {
      if (c.phaseId == phase.phaseId) {
        certList.push({
          "value": c.certfcnId,
          "name": c.lenderName + ' (' + c.certfcnId + ')'
        })
      }
    });
    let curCertList = [];
    formData.currentPhaseCertifications.map(c => {
      if (c.phaseId == value.value) {
        certList.push({
          "value": c.certfcnId,
          "name": c.lenderName + ' (' + c.certfcnId + ')',
          disabled: true
        })
        curCertList.push({
          "value": c.certfcnId,
          "name": c.lenderName + ' (' + c.certfcnId + ')', disabled: true
        })
      }
    });
    setCertificationList(certList);
    setSelectedCertification(curCertList.map(c => c.value));
    formData.phaseDetails.forEach((cert) => {
      if ((cert.sourcePhase == Number(phase.phaseId) && cert.isWholePhase == false && cert.destinationPhase == null)) {
        cert.selectedPhase = value.value;
      }
    })
  }

  const handleCertificationChange = (value) => {
    if (value.length == 0) {
      value.push(...duplicateCertifications);
    }
    let _formData = formData;

    _formData.phaseDetails = formData.phaseDetails;
    value.map(c => {
      let tempList = {
        isWholePhase: false,
        sourcePhase: Number(phase.phaseId),
        destinationPhase: Number(selectedPhase),
        certId: c,
        selectedPhase: Number(selectedPhase)
      }
      _formData.phaseDetails.push(tempList);
    });
    _formData.phaseDetails = _formData.phaseDetails.filter((cert) => (cert.sourcePhase == Number(phase.phaseId) && cert.isWholePhase == true) == false)
    _formData.phaseDetails = _formData.phaseDetails.filter((cert) => (cert.sourcePhase == Number(phase.phaseId) && cert.isWholePhase == false && cert.destinationPhase == null) == false)

    _formData.phaseDetails = _formData.phaseDetails.filter((obj1, i, arr) =>
      arr.findIndex(obj2 =>
        JSON.stringify(obj2) === JSON.stringify(obj1)
      ) === i
    );


    formData = _formData;

    setSelectedCertification(value);

  }


  return (
    <React.Fragment>
      <Accordion defaultActiveKey="1">
        <Card className="accordion-header-card">
          <Card.Header className="accordion-header-space">
            <ContextAwareToggle eventKey={collapseKey}
              onClick={() => { setCollapseKey(collapseKey === "1" ? "0" : "1") }}>
              <p className="accordion-header-title">{phase.phaseNumber}</p>
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={collapseKey}>
            <Card.Body
              style={{
                height: "auto",
                width: "auto",
                backgroundColor: "#ffffff",
                overflow: "auto",
                padding: "0px 0px 20px 0px !important"
              }}>
              <>
                <div className="question">
                  <div
                    className="number"
                    style={{
                      marginLeft: "37px",
                      marginBottom: "10px",
                    }}
                  ></div>{" "}
                  <div style={{ paddingTop: "10px" }}>
                    1. Do you want to move entire phase
                  </div>
                </div>
                <div className="answer" style={{ paddingLeft: "32px" }}>



                  <RadioGroup
                    row
                    value={entirePhase ? entirePhase : (formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId) ?
                      (formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId).length > 0) ?
                        (formData.phaseDetails.filter((p) => p.sourcePhase == phase.phaseId)[0].isWholePhase === true) ?
                          'Yes' :
                          setEntirePhase('No') :
                        null :
                      null)}
                    aria-label="position"
                    name="entirePhase"
                    style={{ fontSize: "14px !important" }}
                    onChange={(event) => {

                      setEntirePhase(event.target.value);
                      {


                        let _formData = formData;


                        if (event.target.value === 'No') {
                          let tempList = {
                            isWholePhase: false,
                            sourcePhase: Number(phase.phaseId),
                            destinationPhase: null,
                            certId: null,
                            selectedPhase: null
                          }
                          _formData.phaseDetails.push(tempList);

                          const phasesNotToBeMoved = _formData.duplicatePhases.filter((p) => p.phaseId == phase.phaseId);
                          phasesNotToBeMoved.map((p) => {
                            _formData.duplicatePhasesForReview.push(p)
                          })
                          _formData.targetPhasesForReview = _formData.targetPhasesForReview.filter((p) => p.phaseId != phase.phaseId)
                        } else {
                          let tempList = {
                            isWholePhase: true,
                            sourcePhase: Number(phase.phaseId),
                            destinationPhase: null,
                            certId: null,
                            selectedPhase: null
                          };
                          const phaseToBeMoved = _formData.duplicatePhases.filter((p) => p.phaseId == phase.phaseId)
                          phaseToBeMoved.map((p) => {
                            _formData.targetPhasesForReview.push(p)
                          })
                          _formData.duplicatePhasesForReview = _formData.duplicatePhasesForReview.filter((p) => p.phaseId != phase.phaseId)

                          _formData.phaseDetails.push(tempList);
                        }

                        _formData.targetPhasesForReview = _formData.targetPhasesForReview.filter((obj1, i, arr) =>
                          arr.findIndex(obj2 =>
                            JSON.stringify(obj2) === JSON.stringify(obj1)
                          ) === i
                        );
                        _formData.duplicatePhasesForReview = _formData.duplicatePhasesForReview.filter((obj1, i, arr) =>
                          arr.findIndex(obj2 =>
                            JSON.stringify(obj2) === JSON.stringify(obj1)
                          ) === i
                        );

                        _formData.phaseDetails = _formData.phaseDetails.filter((cert) => (cert.sourcePhase == Number(phase.phaseId) && cert.isWholePhase == (event.target.value === 'No' ? true : false)) == false)
                        _formData.phaseDetails = _formData.phaseDetails.filter((obj1, i, arr) =>
                          arr.findIndex(obj2 =>
                            JSON.stringify(obj2) === JSON.stringify(obj1)
                          ) === i
                        );
                        console.log('updated formdata phaseDetails', _formData.phaseDetails)

                        setFormData(_formData);
                      }
                    }}
                    size="small"
                    className="splAssmt"
                  >
                    <FormControlLabel
                      key="Yes"
                      style={{ fontSize: "14px !important" }}
                      value="Yes"
                      control={<RadioInput />}
                      label="Yes"
                      labelPlacement="end"
                      className="label"
                      classes={radioClasses}

                    />
                    <FormControlLabel
                      key="No"
                      style={{ fontSize: "14px !important" }}
                      value="No"
                      control={<RadioInput />}
                      label="No"
                      labelPlacement="end"
                      className="label"
                      classes={radioClasses}

                    />
                  </RadioGroup>
                </div>

                {entirePhase === "No" ? <>
                  <div className="question">
                    <div
                      className="number"
                      style={{
                        marginLeft: "37px",
                        marginBottom: "10px",
                      }}
                    ></div>{" "}
                    <div style={{ paddingTop: "10px" }}>
                      2. Select phase you want to move certification into
                    </div>
                  </div>
                  <div className="answer" style={{ paddingLeft: "32px" }}>
                    <ReactSelect
                      name="phaseList"
                      value={targetPhase}
                      options={phaseDropdownOptions}
                      isMultiv={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(value) => {
                        setTargetPhase(value);
                        updateCertificationList(value);
                      }}
                      allowSelectAll={false}
                    />
                  </div>

                  <div className="question">
                    <div
                      className="number"
                      style={{
                        marginLeft: "37px",
                        marginBottom: "10px",
                      }}
                    ></div>{" "}
                    <div style={{ paddingTop: "10px" }}>
                      3. Select individual phase to
                      {phase.certificationList}
                    </div>
                  </div>
                  <div className="answer" style={{ paddingLeft: "32px" }}>
                    <MultiselectTwoSide
                      options={certificationList}
                      value={selectedCertification}
                      className="msts_theme_example"
                      onChange={(e) => {
                        handleCertificationChange(e)
                      }
                      }
                      availableHeader=" Duplicate Phase Certifications"
                      selectedHeader="Current Phase Certifications"
                      labelKey="name"
                      showControls
                    />
                  </div>
                </> : <></>}
              </>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </React.Fragment>
  );
};

export default DuplicatePhaseForm;


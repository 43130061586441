import React from 'react';
import IndividualLender from './IndividualLender';
import './LenderList.css';


const LenderList = ({ list }) => {
  let lenderList = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    lenderList = list.map((m, i) => <IndividualLender key={i} item={m} />);
  }

  return (
    <div className="scrolling-test">
      <div className = "lender-list-body">
        {lenderList}
      </div>
    </div>
  )
}
export default LenderList;
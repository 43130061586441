import React from "react";
import Condition from "./Condition";
import "../Profile/Profile.css";
import "../Project/Project.css";
import WarningIcon from "../../images/icons/Warning@1x.svg";
const ApprovalConditions = ({
  projectlist,
  phaseList,
  childPhaseCondnList,
  type,
}) => {
  let approvalProjectConditionsList = <h3>Loading...</h3>;
  let approvalPhaseConditionsList = <h3>Loading...</h3>;
  let approvalChildPhaseConditionsList = <h3>Loading...</h3>;

  approvalProjectConditionsList =
    projectlist && projectlist?.length > 0 ? (
      projectlist.map((m, i) => <Condition key={i} item={m} type="project" />)
    ) : (
      <></>
    );
  approvalPhaseConditionsList =
    phaseList && phaseList?.length > 0 ? (
      phaseList.map((m, i) => <Condition key={i} item={m} type="phase" />)
    ) : (
      <></>
    );
  approvalChildPhaseConditionsList =
    childPhaseCondnList && childPhaseCondnList?.length > 0 ? (
      childPhaseCondnList.map((m, i) => (
        <Condition key={i} item={m} type={type} />
      ))
    ) : (
      <></>
    );

  return (
    <div className="conditions-restrictions">
      <div className="restrictions-conditions-parent">
        <div className="restrictions-title">
          <img src={WarningIcon} alt="Warning Logo"/>
          &nbsp;&nbsp; Approval Conditions{" "}
        </div>
        <div className="project-phase-restrictions">
          {approvalProjectConditionsList}
          {approvalPhaseConditionsList}
          {approvalChildPhaseConditionsList}
        </div>
      </div>
    </div>
  );
};

export default ApprovalConditions;

import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Modal from "react-bootstrap/Modal";

const ProjectPreRestrictionsModal = ({
  handleAddClose,
  handleAddClick,
  showAdd,
}) => {
  const [restrictionsName, setrestrictionsName] = useState("");
  const [restrictionsDescription, setrestrictionsDescription] = useState("");
  const [restrictionsNameErr, setrestrictionsNameErr] = useState("");
  const [restrictionsDescriptionErr, setrestrictionsDescriptionErr] =
    useState("");

  const handleSubmit = () => {
    if (!restrictionsName) {
      setrestrictionsNameErr("Required");
    } else if (!restrictionsDescription) {
      setrestrictionsDescriptionErr("Required");
    } else {
      let tab = RegExp("\\t|\\n", "g");
      let name = restrictionsName.toString().replace(tab, " ");
      let desc = restrictionsDescription.toString().replace(tab, " ");
      handleAddClick({
        restrictionsName: name,
        restrictionsDescription: desc,
      });

      setrestrictionsName("");
      setrestrictionsDescription("");
    }
  };

  return (
    <Modal
      show={showAdd}
      className="my-modal"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleAddClose}
    >
      <Modal.Header className="the-modal-header" closeButton>
        <Add
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "25px",
            width: "25px",
          }}
        />
        <Modal.Title className="the-modal-title">
          Add Custom Restriction
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="the-modal-body">
        Enter Information below to add a new restriction to your decision
        letter.
        <div className="field">
          <div className="field-label">
            Name of Restriction <span className="mendatory-sign">*</span>
          </div>
          <Grid item xs={12}>
            <input
              required
              aria-label="Name of Restriction"
              type="text"
              className="form-control"
              name="restrictionsName"
              onChange={(event) => setrestrictionsName(event.target.value)}
            />
            <div className="mendatory-sign">{restrictionsNameErr}</div>
          </Grid>
        </div>
        <div className="field">
          <div className="field-label">
            Restriction Content <span className="mendatory-sign">*</span>
          </div>
          <Grid item xs={12}>
            <textarea
              aria-label="Restriction Content"
              style={{
                width: "100%",
                minHeight: "100px",
              }}
              onChange={(event) =>
                setrestrictionsDescription(event.target.value)
              }
            ></textarea>
            <div className="mendatory-sign">{restrictionsDescriptionErr}</div>
          </Grid>
        </div>
      </Modal.Body>
      <Modal.Footer className="the-modal-footer">
        <Button className="go-back-button" onClick={handleAddClose}>
          Cancel
        </Button>
        <Button className="cancel-modal-button" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectPreRestrictionsModal;

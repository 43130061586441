import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field } from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function Section5({
  collapseKey,
  persId,
  projectId,
  pers1051,
  persBudget
}) {
  const [isEdit, setEdit] = useState(false);
  const [pers1051State, setPers1051State] = useState({});
  const [persBudgetState, setPersBudgetState] = useState({});
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const initializaState = () => {
    setPers1051State({ ...pers1051 });
    setPersBudgetState({ ...persBudget });
  };
  useEffect(() => {
    initializaState();
  }, [pers1051, persBudget]);


  const handleSaveEdit = async () => {
    try {
      setApiErr("");
      setInternalErr(false);

      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updateEstablishedPersProjectAndForms/addnDocs`,
        {
          persId,
          projectId,
          pers1051: pers1051State,
          persBudget: persBudgetState
        }
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      initializaState();
    }
  };

  const handleChangeBudget = event => {
    let formData = { ...persBudgetState };
    formData[event.target.name] = event.target.value;
    setPersBudgetState({ ...formData });
  };

  const handleChange1051 = event => {
    let formData = { ...pers1051State };
    formData[event.target.name] = event.target.value;
    setPers1051State({ ...formData });
  };

  return (
    <Accordion defaultActiveKey="1">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">
              Additional Documents (1051, Budget)
            </p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => {
                  toggleEdit(false);
                  setApiErr("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                toggleEdit(true);
                setInternalErr(false);
                setApiErr("");
              }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
          ): null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    1051 Questions (From Analyst)
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Name of Master Association"
                      name="masterAssociationName"
                      formData={pers1051State}
                      handleChange={handleChange1051}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Exceptions"
                      name="exceptions1051"
                      formData={pers1051State}
                      handleChange={handleChange1051}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Budget Questions (From Analyst)
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Income from Assessments"
                      name="incomeFromAssessments"
                      formData={persBudgetState}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Reserves"
                      name="reserves"
                      formData={persBudgetState}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="% of Reserves on Hand"
                      name="percentageOfReservesOnHand"
                      formData={persBudgetState}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Exceptions"
                      name="exceptionsBudget"
                      formData={persBudgetState}
                      handleChange={handleChangeBudget}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

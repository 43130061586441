import React from "react";
import Select from "../SharedComponents/Select/Select";


const UpdatedField = ({
  label,
  name,
  type,
  formData,
  isEdit,
  isError,
  handleChange,
  dropdown,
  disabled,
  dropdownValues,
  dropdownWidth,
  rows,
  isRequired
}) => {
  const isCheckbox = type === "checkbox";
  return (
    <div className={label ? "attri-container" : ""}>
      {label && (
        <div className="attri-title">{label} {
          isRequired ? <span style={{color: '#ff0000'}}>*</span> : ""
        } </div>
      )}
      <div className="attri-value">
        {isEdit ? (
          dropdown ? (
            <Select
              id={name}
              disabled={disabled}
              placeholder={label}
              name={name}
              label={""}
              selectWidth={dropdownWidth || "175px"}
              changeHandler={handleChange}
              options={dropdownValues}
              value={formData[name]}
            />
          ) : type === "textarea" ? (
            <textarea
              required
              className="form-control"
              name={name}
              checked={formData[name]}
              value={formData[name]}
              onChange={handleChange}
              rows={rows}
            />
          ) : (
            <div>
              <input
                required
                type={type || "text"}
                className="form-control"
                name={name}
                checked={isCheckbox ? formData[name] : undefined}
                value={formData[name]}
                onChange={handleChange}
              />
              {isError ? <div style={{color: '#ff0000'}}>{isError}</div> : null}
            </div>
          )
        ) : isCheckbox ? (
          formData[name] ? (
            "Yes"
          ) : (
            "No"
          )
        ) : (
          formData[name] === true || formData[name] === "true" ? (
            "Yes"
          ) : formData[name] === false || formData[name] === "false" ? (
            "No"
          ) : (
            formData[name]
          )
        )}
      </div>
    </div>
  );
};

export default UpdatedField;

import React, { useState, useEffect } from "react";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Field } from "../Pers-atoms";

export default function Table1({
  lender = {},
  isEdit,
  persId,
  projectId,
  update
}) {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const { unitOwnrshp1076DataList = [] } = lender;
    const initialData = [
      ...JSON.parse(JSON.stringify(unitOwnrshp1076DataList))
    ];
    setFormData(initialData);
  }, [lender, isEdit]);

  const handleChange = ({ target: { name, checked, type, value } }, index) => {
    const row = { ...formData[index] };
    row[name] = type === "checkbox" ? checked : value;
    const _formData = formData.map((r, i) => (i === index ? { ...row } : r));
    setFormData(_formData);
    update(_formData, "unitOwnrshp1076DataList");
  };

  const addClickHandler = () => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    const newRow = {
      projectId,
      persId,
      indvName: "",
      devlSpnsrInd: false,
      nbrOfUnitsOwndCnt: null,
      unitsOwndPct: null,
      nbrUnitsLeasedMrktRentCnt: null,
      nbrUnitsLeasedRentCntrlCnt: null,
      createdBy: passData.user,
      dateCreated: null
    };
    const _formData = [...formData, { ...newRow }];
    setFormData(_formData);
    update(_formData, "unitOwnrshp1076DataList");
  };

  const removeHandler = index => {
    const _formData = formData.filter((_, i) => i !== index);
    setFormData(_formData);
    update(_formData, "unitOwnrshp1076DataList");
  };

  return (
    <>
      {isEdit && (
        <div className="table-add-button">
          <button className="add-phase-button" onClick={addClickHandler}>
            <FaPlus className="edit-profile-icon" /> Add
          </button>
        </div>
      )}
      <div className="table-header">
        Complete the following table if more than one unit is owned by the same
        individual or entity.
      </div>
      <div className="list-container">
        <div className="list-header">
          <div className="list-header-name">Individual / Entity Name</div>
          <div className="list-header-name">
            Developer or Sponsor (Yes or No)
          </div>
          <div className="list-header-name">Number of Units Owned</div>
          <div className="list-header-name">
            Percentage Owned of Total Project Units
          </div>
          <div className="list-header-name">Number Leased at Market Rent</div>
          <div className="list-header-name">
            Number Leased under Rent Control
          </div>
          {/* {isEdit && <div className="list-header-name">Actions</div>} */}
        </div>
        <div className="list-body">
          {formData.map((_, index) => (
            <div key={index}>
              <div className="card-row-list">
                <div className="card-name-list">
                  <Field
                    label=""
                    name="indvName"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="devlSpnsrInd"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                    type="checkbox"
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="nbrOfUnitsOwndCnt"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="unitsOwndPct"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="nbrUnitsLeasedMrktRentCnt"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                <div className="card-name-list">
                  <Field
                    label=""
                    name="nbrUnitsLeasedRentCntrlCnt"
                    formData={formData[index]}
                    handleChange={event => handleChange(event, index)}
                    isEdit={isEdit}
                  />
                </div>
                {/* {isEdit && (
                  <div className="card-name-list">
                    <button
                      className="add-phase-button"
                      name={index}
                      onClick={() => removeHandler(index)}
                    >
                      <FaTrash className="edit-profile-icon" />
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import React from 'react';
import IndividualEligibility from './IndividualEligibility';
import './Eligibility.css';


const Eligibility = ({ list }) => {
    let eligibility = <h3>Loading...</h3>;
  
    if (list) {
        eligibility = list.map((m, i) => <IndividualEligibility key={i} item={m} />);
    }
  
    return (
      <div className="scrolling-test">
        {eligibility}
      </div>
    )
  }
  export default Eligibility;


import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import {
  Field as LenderField,
  DatePicker as LenderDatePicker
} from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";



export default function Section1({ collapseKey, lender, states }) {
  const [isEdit, setEdit] = useState(false);
  const [lenderStates, setLenderState] = useState({});
  const [apiErrLenderQuote, setApiErrLenderQuote] = useState("");
  const [internalErrLenderQuote, setInternalErrLenderQuote] = useState(false);

  useEffect(() => { 
    setLenderState({ ...lender });
  }, [lender]);


  const handleSaveEdit = async () => {
    setApiErrLenderQuote("");
    setInternalErrLenderQuote(false);

    try {
      const {
        persId,
        projectId,
        phaseId,
        persStatus,
        persStatusJustification,
        receivedDate,
        lenderName,
        lenderSSN,
        lenderAddress,
        lenderCity,
        lenderState,
        lenderZip,
        requesterName,
        requesterEmail
      } = lenderStates;
      const persLen = {
        persId,
        projectId,
        phaseId,
        persStatus,
        persStatusJustification,
        receivedDate,
        lenderName,
        lenderSSN,
        lenderAddress,
        lenderCity,
        lenderState,
        lenderZip,
        requesterName,
        requesterEmail
      };

      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updateEstablishedPersProjectAndForms/lender`,
        { persLen }
      );

      console.log("ProjectAndForm response => ", response);
        if (response) {
          if (response.status == 200) {
            setEdit(false);
            setApiErrLenderQuote("");
            setInternalErrLenderQuote(false);
            // window.location.reload(false);
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              setApiErrLenderQuote("");
              setEdit(false);
              setInternalErrLenderQuote(true);
              // window.location.href = "/error-page";
            } else {
              setApiErrLenderQuote(response.data.message);
            }
          }
        }
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setLenderState({ ...lender });
    }
  };

  const handleChange = event => {
    let _lender = { ...lenderStates };
    _lender[event.target.name] = event.target.value;
    setLenderState({ ..._lender });
  };

  return (
    <Accordion defaultActiveKey="1" id="section0">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">Lender Questions</p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => { 
                  toggleEdit(false); 
                  setApiErrLenderQuote("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                 toggleEdit(true)
                 setInternalErrLenderQuote(false);
                 setApiErrLenderQuote("");
                }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErrLenderQuote ? apiErrLenderQuote : null}
            </div>
            {internalErrLenderQuote ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Lender Questions
                  </div>
                  <div className="accordion-body-card-left">

                    <LenderField
                      label="Lender"
                      name="lenderName"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender Seller/Service Number"
                      name="lenderSSN"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender Address"
                      name="lenderAddress"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender City"
                      name="lenderCity"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender State"
                      name="lenderState"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={states}
                    />
                    <LenderField
                      label="Lender Zip Code"
                      name="lenderZip"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <LenderField
                      label="Requester"
                      name="requesterName"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Requester Email"
                      name="requesterEmail"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderDatePicker
                      label="Received Date"
                      name="receivedDate"
                      formData={lenderStates}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

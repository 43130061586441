import React, { Component } from "react";
import axios from "axios";
import "./Admin.css";
import { Nav, TabContainer, TabContent, TabPane } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import AuditTrailList from "../AuditTrailList/AuditTrailList";
import DeleteDuplicate from "../DeleteDuplicate/DeleteDuplicate";
import ReasonCodeList from "../ReasonCodeList/ReasonCodeList";

class Admin extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
  }

  getAuditTrail() {
    let list = <h1>Loading...</h1>;
    list = <AuditTrailList />;
    return list;
  }

  getDuplicateProjectPage() {
    let list = <h1>Loading...</h1>;
    list = <DeleteDuplicate />;
    return list;
  }

  getReasonCodeList() {
    let list = <h1>Loading...</h1>;
    list = <ReasonCodeList />;
    return list;
  }

  render() {
    return (
      <div>
        <div className="admins-list-title">Admin</div>
        <div>
          <TabContainer id="admin-list-tab" defaultActiveKey="first">
            <Nav variant="pills" className="admin-list-nav">
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="first">
                  Audit Trail
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="second">
                  Delete Duplicate
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="reason-codes">
                  Reason Codes
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <TabContent className="admins-content">
              <TabPane eventKey="first">{this.getAuditTrail()}</TabPane>
              <TabPane eventKey="second">{this.getDuplicateProjectPage()}</TabPane>
            </TabContent>
            <TabContent className="admins-content">
              <TabPane eventKey="reason-codes">{this.getReasonCodeList()}</TabPane>
            </TabContent>
          </TabContainer>
        </div>
      </div>
    );
  }
}

export default Admin;

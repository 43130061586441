import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";
import { fetchStates } from "../../redux/actions/filterActions";

import {
  Field as LenderField,
  DatePicker as LenderDatePicker
} from "../Pers-atoms";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isProjectRole,
  isLenderRole,
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function LenderAndBuilder({ collapseKey, lender }) {
  const [isEdit, setEdit] = useState(false);
  const [lenderState, setLenderState] = useState({});
  const dispatch = useDispatch();
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const states = useSelector(
    dialogueReducer => dialogueReducer.dialogueReducer.states
  );

  useEffect(() => {
    dispatch(fetchStates());
  }, []);
  useEffect(() => {
    setLenderState({ ...lender });
  }, [lender]);

  const handleSaveEdit = async () => {
    setApiErr("");
    setInternalErr(false);
    try {
      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updatePersProjectAndForms/lender`,
        { persLen: { ...lenderState } }
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setLenderState({ ...lender });
    }
  };

  const handleChange = event => {
    let _lender = { ...lenderState };
    _lender[event.target.name] = event.target.value;
    setLenderState({ ..._lender });
  };

  const handleStateChange = event => {
    let _lender = { ...lenderState };
    _lender["builderOrDeveloperState"] = event.target.value;
    setLenderState({ ..._lender });
  };

  const handleLenderStateChange = event => {
    let _lender = { ...lenderState };
    _lender["lenderState"] = event.target.value;
    setLenderState({ ..._lender });
  };

  return (
    <Accordion defaultActiveKey="1" id="section0">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">Lender and Builder Details</p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? (
            isEdit ? (
              <div>
                <button
                  className="cancel-button"
                  onClick={() => {
                    toggleEdit(false);
                    setApiErr("");
                  }}
                >
                  Cancel
                </button>
                <button
                  //   disabled
                  className="add-phase-button"
                  onClick={handleSaveEdit}
                >
                  <FaSave className="edit-profile-icon" /> Save Edits
                </button>
              </div>
            ) : (
              <button
                //   disabled
                className="add-phase-button"
                onClick={() => {
                  toggleEdit(true);
                  setInternalErr(false);
                  setApiErr("");
                }}
              >
                <FaPen className="edit-profile-icon" /> Edit Section
              </button>
            )
          ) : null}
          {/* </Link> */}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Lender</div>
                  <div className="accordion-body-card-left">
                    <LenderField
                      label="Lender"
                      name="lenderName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender Seller/Service Number"
                      name="lenderSSN"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender Address"
                      name="lenderAddress"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender City"
                      name="lenderCity"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender State"
                      name="lenderState"
                      formData={lenderState}
                      dropdown={true}
                      dropdownValues={states}
                      handleChange={handleLenderStateChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Lender Zip Code"
                      name="lenderZip"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <LenderField
                      label="Requester "
                      name="requesterName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Requester Email"
                      name="requesterEmail"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderDatePicker
                      label="Received Date"
                      name="receivedDate"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">Builder</div>
                  <div className="accordion-body-card-left">
                    <LenderField
                      label="Builder/Developer Name (LLC)"
                      name="builderOrDevelopeName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="Builder/Developer Principals"
                      name="builderOrDeveloperPrincipals"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label=" Builder/Developer Primary Contact "
                      name="builderOrDeveloperPrimaryContact"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="  Contact Phone"
                      name="builderOrDeveloperContactPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <LenderField
                      label="Builder/Developer Address"
                      name="builderOrDeveloperAddress"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <LenderField
                      label="States"
                      name="builderOrDeveloperState"
                      formData={lenderState}
                      dropdown={true}
                      dropdownValues={states}
                      handleChange={handleStateChange}
                      isEdit={isEdit}
                    />

                    <LenderField
                      label="ZIP Code"
                      name="builderOrDeveloperZIPCode"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

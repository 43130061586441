import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../../AddEstablishedPers/AddEstablishedPers.css";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const Form1029 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState("back");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [
    salesSourceInformationData,
    setSalesSourceInformationData
  ] = React.useState("");
  const fetchSalesSourceInformationData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/SOURCE_SALES_INFO_1029`
      )
      .then(response => {
        setSalesSourceInformationData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchSalesSourceInformationData();
  }, [fetchSalesSourceInformationData]);
  const salesSourceInformationResult = Object.keys(
    salesSourceInformationData
  ).map(key => salesSourceInformationData[key]);
  const salesSourceInformationList = salesSourceInformationResult.map(
    salesSourceInformation => {
      return { value: salesSourceInformation, label: salesSourceInformation };
    }
  );

  function validateNumNoStr(value) {
    let error;
    if (value && !/^[1-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 3 of 9 - Warranty for Project Presale (optional)
                </div>
                <div className="attribute-title">
                  Total Number of Units in the Subject Legal/Construction Phase
                </div>
                <Field
                  name="totalUnitsCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={touched.totalUnitsCount && errors.totalUnitsCount}
                  helperText={touched.totalUnitsCount && errors.totalUnitsCount}
                  inputProps ={{
                    'aria-label': 'Total Units Count'
                  }}
                />
                <div className="attribute-title">
                  Number of Units in the Subject Legal/Construction Phase Sold
                  To: Principal Residence Purchasers
                </div>
                <Field
                  name="unitsSoldToPrincipalResidencePurchasersCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.unitsSoldToPrincipalResidencePurchasersCount &&
                    errors.unitsSoldToPrincipalResidencePurchasersCount
                  }
                  helperText={
                    touched.unitsSoldToPrincipalResidencePurchasersCount &&
                    errors.unitsSoldToPrincipalResidencePurchasersCount
                  }
                  inputProps = {{
                    'aria-label': 'Units Sold To Principal Residence Purchasers Count'
                  }}
                />
                <div className="attribute-title">
                  Number of Units in the Subject Legal/Construction Phase Sold
                  To: Second Home Purchasers
                </div>
                <Field
                  name="unitsSoldToSecondHomePurchasersCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.unitsSoldToSecondHomePurchasersCount &&
                    errors.unitsSoldToSecondHomePurchasersCount
                  }
                  helperText={
                    touched.unitsSoldToSecondHomePurchasersCount &&
                    errors.unitsSoldToSecondHomePurchasersCount
                  }
                  inputProps ={{
                    'aria-label': 'Units Sold to Second Home Purchasers Count'
                  }}
                />
                <div className="attribute-title">
                  Number of Units in the Subject Legal/Construction Phase Sold
                  To: Investor Purchasers
                </div>
                <Field
                  name="unitsSoldToInvestorPurchasersCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.unitsSoldToInvestorPurchasersCount &&
                    errors.unitsSoldToInvestorPurchasersCount
                  }
                  helperText={
                    touched.unitsSoldToInvestorPurchasersCount &&
                    errors.unitsSoldToInvestorPurchasersCount
                  }
                  inputProps ={{
                    'aria-label': 'Units Sold to investor purchasers count'
                  }}
                />
                <div className="attribute-title">
                  Total Number of Units In Prior Legal/Construction Phases
                </div>
                <Field
                  name="priorTotalUnitsCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.priorTotalUnitsCount && errors.priorTotalUnitsCount
                  }
                  helperText={
                    touched.priorTotalUnitsCount && errors.priorTotalUnitsCount
                  }
                  inputProps ={{
                    'aria-label': 'Prior Total Units count'
                  }}
                />
                <div className="attribute-title">
                  Number of Units in Prior Legal/Construction Phase(s) Sold To
                  Principal Residence Purchasers
                </div>
                <Field
                  name="priorUnitsSoldToPrincipalResidencePurchasersCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.priorUnitsSoldToPrincipalResidencePurchasersCount &&
                    errors.priorUnitsSoldToPrincipalResidencePurchasersCount
                  }
                  helperText={
                    touched.priorUnitsSoldToPrincipalResidencePurchasersCount &&
                    errors.priorUnitsSoldToPrincipalResidencePurchasersCount
                  }
                  inputProps ={{
                    'aria-label': 'Prior units sold to principal residence purchasers count'
                  }}
                />
                <div className="attribute-title">
                  Number of Units in Prior Legal/Construction Phase(s) Sold To
                  Second Home Purchasers
                </div>
                <Field
                  name="priorUnitsSoldToSecondHomePurchasersCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.priorUnitsSoldToSecondHomePurchasersCount &&
                    errors.priorUnitsSoldToSecondHomePurchasersCount
                  }
                  helperText={
                    touched.priorUnitsSoldToSecondHomePurchasersCount &&
                    errors.priorUnitsSoldToSecondHomePurchasersCount
                  }
                  inputProps ={{
                    'aria-label': 'Prior Units sold to second home purchasers count'
                  }}
                />
                <div className="attribute-title">
                  Number of Units in Prior Legal/Construction Phase(s) Sold To
                  Investor Purchasers
                </div>
                <Field
                  name="priorUnitsSoldToInvestorPurchasersCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.priorUnitsSoldToInvestorPurchasersCount &&
                    errors.priorUnitsSoldToInvestorPurchasersCount
                  }
                  helperText={
                    touched.priorUnitsSoldToInvestorPurchasersCount &&
                    errors.priorUnitsSoldToInvestorPurchasersCount
                  }
                  inputProps ={{
                    'aria-label': 'Prior Units sold to investor purchasers count'
                  }}
                />
                <div className="attribute-title">
                  Indicate the Source of sales information
                </div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="salesSourceInformation"
                    as="select"
                    aria-label='Sales source information'
                  >
                    <option value=" ">Select Source</option>
                    {salesSourceInformationList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">
                  Provide the number of closed sales of units
                </div>
                <Field
                  name="closedSalesUnitsCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.closedSalesUnitsCount &&
                    errors.closedSalesUnitsCount
                  }
                  helperText={
                    touched.closedSalesUnitsCount &&
                    errors.closedSalesUnitsCount
                  }
                  inputProps ={{
                    'aria-label': 'Closed saled units count'
                  }}
                />
                <div className="bottom-button-container">
                  <Button
                    type="submit"
                    className="next-button"
                    onClick={() => setDirection("forward")}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    type="submit"
                    className="previous-button"
                    onClick={() => setDirection("back")}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{fill:"red"}} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

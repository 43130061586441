import * as React from "react";
import PropTypes from "prop-types";
import { Search } from "@material-ui/icons";
import "./AutoCompleteStyles.css";
import { TextField } from "@material-ui/core";
import { Field } from "formik";

const AutoComplete = ({
  changeHandler,
  searchHandler,
  id,
  name,
  value,
  autoCompleteClassName,
  options,
  placeholder,
  autoCompleteContainerClassName,
  autoComplete,
  type
}) => {
  const [autoCompleteValue, setAutoCompleteValue] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [isOpen, setopen] = React.useState(false);
  React.useEffect(() => {
    setAutoCompleteValue(value);
  }, [value]);

  const autoCompleteChangeHandler = event => {
    setAutoCompleteValue(event.target.value);
    changeHandler(false, event);
    setopen(false);
  };
  const AutoDropdownClickHandler = (event, option) => {
    setAutoCompleteValue(event.currentTarget.innerText);
    setSelectedOption(option);
    if (type === "lenderId") {
      searchHandler(event.currentTarget.innerText, option);
    }
    setopen(false);
  };
  const searchClickHandler = (event, option) => {
    console.log("autoCompleteValue....", autoCompleteValue);
    searchHandler(event, option);
  };
  React.useEffect(() => {
    if (autoCompleteValue.length >= 3) {
      setopen(true);
      console.log("UseEffect....", autoCompleteValue.length);
    }
    if (selectedOption != null) {
      setopen(false);
    }
    if (autoCompleteValue.length === 0) {
      setSelectedOption(null);
    }
  }, [options]);
  return (
    <>
      {type === "lenderId" ? (
        <div>
          <Field
            as={TextField}
            color="#FCFCFC"
            variant="outlined"
            size="small"
            className={autoCompleteClassName}
            type="text"
            name="lenderId"
            value={autoCompleteValue}
            onKeyUp={autoCompleteChangeHandler}
            onChange={event => autoCompleteChangeHandler(event)}
            inputProps={{
              'aria-label': "lender name or id",
              'autoComplete': "none"
            }}
          />
          <div className="suggestionWrapper2">
            {isOpen && autoCompleteValue.length >= 3 && options.length > 0 && (
              <ul className="ulContainer2">
                {options.length > 0 &&
                  options.map((item, index) => {
                    return (
                      <li
                        className="liContainer2"
                        key={index}
                        onClick={e => AutoDropdownClickHandler(e, item)}
                      >
                        <div className="autocompleteContainer2">
                          <div className="autocompleteText2">
                            {item.lenderId ? `${item.lenderId} -` : ""}
                            {item.lenderName ? ` ${item.lenderName}` : ""}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      ) : (
        <div className={autoCompleteContainerClassName}>
          <div className="searchButtonWrapper">
            <input
              className={autoCompleteClassName}
              autoComplete={autoComplete ? autoComplete : "off"}
              placeholder={placeholder}
              type="text"
              name={autoCompleteValue}
              value={autoCompleteValue}
              onKeyUp={autoCompleteChangeHandler}
              onChange={event => autoCompleteChangeHandler(event)}
            />
            <button
              className="search-button"
              type="submit"
              onClick={searchClickHandler}
              style={{ display: "none" }}
            ></button>
          </div>

          <div className="suggestionWrapper">
            {isOpen && autoCompleteValue.length >= 3 && options.length > 0 && (
              <ul className="ulContainer">
                {options.length > 0 &&
                  options.map((item, index) => {
                    return (
                      <li
                        className="liContainer"
                        key={index}
                        onClick={e => AutoDropdownClickHandler(e, item)}
                      >
                        <div className={"autoCompleteSuggestionsContainer"}>
                          <div>
                            <Search
                              style={{ color: "#1176B5" }}
                              className="autocomplete-search-icon"
                            />
                          </div>
                          <div className="autocompleteText">
                            {item.projectName ? `${item.projectName},` : ""}
                            {/* {item.phaseId !== ""? ` ${item.phaseId},` : ""} */}
                            {item.phaseId ? ` ${item.phaseId},` : ""}
                            {item.street ? ` ${item.street},` : ""}{" "}
                            {item.city ? ` ${item.city},` : ""}
                            {item.state ? ` ${item.state},` : ""}{" "}
                            {item.zipcode ? ` ${item.zipcode}` : ""}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  );
};

AutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoCompleteContainerClassName: PropTypes.string.isRequired,
  autoCompleteClassName: PropTypes.string.isRequired,
  autoComplete:PropTypes.string,
  value: PropTypes.string,
  changeHandler: PropTypes.func.isRequired
};

export default AutoComplete;

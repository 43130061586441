import React, {useState} from "react";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { FaLongArrowAltLeft, FaLongArrowAltRight, FaTimes } from "react-icons/fa";
import FormStepper from "../stepper";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../../../Profile/ContextAwareToggle";
import CancelModal from "../../../../ProjectStatusFinal/cancel-modal";






export const Page2 = ({ formData, phaseArray, projectArray, projectInfo, setDocId, nextStep, prevStep, step }) => {
    const [direction, setDirection] = useState("back");
    const [showCancel, setShowCancel] = useState(false);


    function openCancelPopup(flag) {
        setShowCancel(flag);
      }
    
      function handleCancelCancel() {
        setShowCancel(false);
      }
    
    const mapConditions = (() => {
        if(projectArray !== null && typeof projectArray.conditions !== "undefined") {
            const mapInside = projectArray.conditions.map((list) =>
            <>
            {list.isAddedCondn === false ? 
            <p>{list.conditionDescription}</p>
            :
            <p>
              {list.conditionName}:&nbsp;{list.conditionDescription}
            </p>
            }
            </>
            );
            return mapInside;
        } else {}      
    })();

    const mapRestrictions = (() => {
        if(projectArray !== null && typeof projectArray.restrictions !== "undefined") {
            const mapInside = projectArray.restrictions.map((list) =>
            <>
            {list.isAddedRestrictions === false ? 
            <p>{list.restrictionsDescription}</p> 
            :
            <p>
              {list.restrictionsName}:&nbsp;{list.restrictionsDescription}
            </p>
            }
            </>
            );
            return mapInside;
        } else {}      
    })();

    var result = formData.checked.map(function (x) { 
        return parseInt(x, 10); 
      });
    let x = result;
    var filtered = phaseArray.filter((el,i)=>x.some(j => i === j));
    console.log(filtered)

    const summaryMap = filtered.map((anObjectMapped, index) => {
        const mapConditions = (() => {
          if(typeof anObjectMapped.conditions !== "undefined") {
            const mapInside = anObjectMapped.conditions.map((list) =>
            <>
            {list.isAddedCondn === false ? 
              <p>{list.conditionDescription}</p>
              :
              <p>
                {list.conditionName}:&nbsp;{list.conditionDescription}
              </p>
              }
              </>
            );
            return mapInside;
          } else {}      
        })();
  
        const mapRestrictions = (() => {
          if(typeof anObjectMapped.restrictions !== "undefined") {
            const mapInside = anObjectMapped.restrictions.map((list) =>
            <>
            {list.isAddedRestrictions === false ?
              <p>{list.restrictionsDescription}</p>
              :
              <p>
                {list.restrictionsName}:&nbsp;{list.restrictionsDescription}
              </p>
              }
              </>
            );
            return mapInside;
          } else {}      
        })();

        return (
          <div className="decision-modal-parent" key={`${index}`}>
              <div className="name">{anObjectMapped.phaseInfo.phaseDisplayDet}</div>
              <div className="decision"><b>PERS Decision:</b>&nbsp;{anObjectMapped.phaseInfo.persDecision}</div>
              <div className="date"><b>Expiration:</b>&nbsp;{anObjectMapped.phaseInfo.expiryDate}</div>
              <div className="restrictions"><b>Delivery Restrictions</b>{anObjectMapped.restrictions.length > 0 ? (<div>{mapRestrictions}</div>):(<div>None</div>)}</div>
              <div className="conditions"><b>Conditions</b>{mapConditions}</div>
          </div>
        );
      })

    const passData = JSON.parse(sessionStorage.getItem("passData"));
    const entireProjectData = {
        ltrGenDtlsProjectPhaseLetterAssc: {
            persId: formData.persId,
            projectId: formData.projectId,
            createdBy: passData.user,
            createdByUserName: passData.firstName + " " + passData.lastName,
        },
        projLndrDetailsAndCRIS: {
            projectAndLenderInfo: projectInfo,
            projectDecision: projectArray
        }
    }
    const phaseDecisionData = {
        ltrGenDtlsProjectPhaseLetterAssc: {
            persId: formData.persId,
            projectId: formData.projectId,
            createdBy: passData.user,
            createdByUserName: passData.firstName + " " + passData.lastName,
        },
        projLndrDetailsAndCRIS: {
            projectAndLenderInfo: projectInfo,
            phaseDecisions: filtered
        }
    }

    const [apiErr, setApiErr] = useState("");

    function submitLetterForm() {
        if(formData.entireProjectInd === "true") {
            axios
            .post(
                global.REACT_APP_API_ENDPOINT +
                `/persLetter/generateConditionalAvailableLetterPdf`,
                entireProjectData
            )
            .then(response => {
                if (response.status == 200) {
                setApiErr("");
                setDocId(response.data);
                setTimeout(() => {
                    nextStep();
                }, 300);
                } else if (response.data && response.data.message) {
                if (response.data.message == "Internal server error") {
                    window.location.href = "/error-page";
                } else {
                    setApiErr(response.data.message);
                }
                }
                // setPersId(response.data);
            })
            .catch(error => {
                console.log("letter error", error);
            });
        } else {
            axios
            .post(
                global.REACT_APP_API_ENDPOINT +
                `/persLetter/generateConditionalAvailableLetterPdf`,
                phaseDecisionData
            )
            .then(response => {
                if (response.status == 200) {
                setApiErr("");
                setDocId(response.data);
                setTimeout(() => {
                    nextStep();
                }, 300);
                } else if (response.data && response.data.message) {
                if (response.data.message == "Internal server error") {
                    window.location.href = "/error-page";
                } else {
                    setApiErr(response.data.message);
                }
                }
                // setPersId(response.data);
            })
            .catch(error => {
                console.log("letter error", error);
            });

        }
    }

    return (
        <>
        <CancelModal
            showCancel={showCancel}
            handleCancelCancel={handleCancelCancel}
            persId={formData.persId}
            persType={formData.persType}
            title={ 'Cancel Conditional/Avialable Letter'}
          />
            <Formik
                initialValues={formData}
                onSubmit={values => {
                //direction === 'back' ? prevStep() : nextStep();
                }}
            >
            {({ values, errors, touched }) => (
            <Form className="parent-form">
                <FormStepper step={step} />
                <div className="parent-field-container">
                <div className="field-container">
                    <div className="top-title-container">
                        <div className="big-title">Generate Letter: Conditional/Available</div>
                        <div className="small-title">
                            PERS ID: {projectInfo.persId}
                        </div>
                    </div>

                    <div className="field-lower-container">
                        <div className="section-title">Step 2: Review Letter Details</div>
                        <>
                        <div className="page-container">
                            <div className="accordion-top-container">
                            <Accordion defaultActiveKey="0" id="section5">
                                <Card className="accordion-header-card">
                                <Card.Header className="accordion-header-space">
                                    <ContextAwareToggle eventKey="1">
                                    <p className="accordion-header-title">
                                        Project Information
                                    </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body className="accordion-body-card">
                                        <div className="letter-accordion-body-container">
                                            <div className="left">
                                                <div className="label">Project Name</div>
                                                <div className="value">{projectInfo.projectName}</div>
                                                <div className="label">Project Address</div>
                                                <div className="value">{projectInfo.projectAddress}</div>
                                                <div className="label">Project City</div>
                                                <div className="value">{projectInfo.projectCity}</div>
                                                <div className="label">Project State</div>
                                                <div className="value">{projectInfo.projectState}</div>
                                                <div className="label">Project Zip Code</div>
                                                <div className="value">{projectInfo.projectZip5}</div>
                                            </div>
                                            <div className="right">
                                                <div className="label">Project ID</div>
                                                <div className="value">{projectInfo.projectId}</div>
                                                <div className="label">Style</div>
                                                <div className="value">{projectInfo.projectStyle}</div>
                                                <div className="label">Date Created</div>
                                                <div className="value">{projectInfo.dtCreated}</div>
                                                <div className="label">Created By</div>
                                                <div className="value">{passData.firstName + " " + passData.lastName}</div>                                            
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <Accordion defaultActiveKey="0" id="section5">
                                <Card className="accordion-header-card">
                                <Card.Header className="accordion-header-space">
                                    <ContextAwareToggle eventKey="1">
                                    <p className="accordion-header-title">
                                        Lender Information
                                    </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body className="accordion-body-card">
                                        <div className="letter-accordion-body-container">
                                            <div className="left">
                                                <div className="label">Lender Name</div>
                                                <div className="value">{projectInfo.lenderName}</div>
                                                <div className="label">Lender Address</div>
                                                <div className="value">{projectInfo.lenderAddress}</div>
                                                <div className="label">Lender City</div>
                                                <div className="value">{projectInfo.lenderCity}</div>
                                                <div className="label">Lender State</div>
                                                <div className="value">{projectInfo.lenderState}</div>
                                                <div className="label">Lender Zip Code</div>
                                                <div className="value">{projectInfo.lenderZip}</div>
                                            </div>
                                            <div className="right">
                                                <div className="label">Requester Name</div>
                                                <div className="value">{projectInfo.requesterName}</div>
                                                <div className="label">Requester Email</div>
                                                <div className="value">{projectInfo.requesterEmail}</div>                                           
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            {values.entireProjectInd === "true" ? (
                            <Accordion defaultActiveKey="1" id="section5">
                                <Card className="accordion-header-card">
                                <Card.Header className="accordion-header-space">
                                    <ContextAwareToggle eventKey="1">
                                    <p className="accordion-header-title">
                                        PERS Decision
                                    </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body className="accordion-body-card">
                                    <div className="decision-modal-parent">
                                        <div className="decision"><b>{projectArray.decisionAndExpiry.persDecision} Assigned to:</b>&nbsp;Entire Project</div>
                                        <div className="date"><b>Expiration Date:</b>&nbsp;{projectArray.decisionAndExpiry.expiryDate}</div>                                    
                                        <div className="restrictions"><b>Delivery Restrictions</b>{projectArray.restrictions.length > 0 ? (<div>{mapRestrictions}</div>):(<div>None</div>)}</div>
                                        <div className="conditions"><b>Conditions</b>{mapConditions}</div>
                                    </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            ) : (
                            <Accordion defaultActiveKey="1" id="section5">
                                <Card className="accordion-header-card">
                                <Card.Header className="accordion-header-space">
                                    <ContextAwareToggle eventKey="1">
                                    <p className="accordion-header-title">
                                        PERS Decision
                                    </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body className="accordion-body-card">
                                        {summaryMap}
                                    </Card.Body>
                                </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            )}
                            </div>
                            <div
                            style={{
                                textAlign: "right",
                                color: "#ff0000",
                                padding: 15
                            }}
                            >
                            {apiErr ? apiErr : null}
                            </div>
                        </div>
                        </>
                        <div className="bottom-button-container">
                            <Button
                                type="submit"
                                className="next-button"
                                onClick={() => {submitLetterForm()}}
                            >
                                Next Section
                                <FaLongArrowAltRight />
                            </Button>
                            <Button
                                type="submit"
                                className="previous-button"
                                onClick={() => prevStep()}
                            >
                                <FaLongArrowAltLeft />
                                Previous Section
                            </Button>
                        </div>
                    </div>
                </div>
                </div>
                    <div className="right-cancel-container">
                        <button
                        className="delete-modal-button"
                        style={{
                            float: 'right',
                            border: 0
                        }}
                        onClick={() => openCancelPopup(true)}
                        >
                        <FaTimes
                            className="edit-profile-icon"
                            style={{
                            marginRight: 5
                            }}
                        />{" "}
                        Cancel 
                        </button>
                    </div>
            </Form>
            )}
            </Formik>
        </>
        )

}
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";
export const Form1091 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState("back");
  console.log(formData);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [stateData, setStateData] = React.useState("");
  const fetchStateData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then(response => {
        setStateData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);
  const stateResult = Object.keys(stateData).map(key => stateData[key]);
  const stateList = stateResult.map(state => {
    return { value: state, label: state };
  });

  function validateEmail(value) {
    let error;
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid Email Address";
    }
    return error;
  }

  function validateNumNoStr(value) {
    let error;
    if (value && !/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (value && !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i.test(value)) {
      error = "Invalid Name";
    }
    return error;
  }

  function validateTax(value) {
    let error;
    if (value && !/[0-9]{2}-[0-9]{7}$/i.test(value)) {
      error = "Invalid Tax ID. Format: XX-XXXXXXX";
    }
    return error;
  }

  function validateNumNoStr5(value) {
    let error;
    if (value && !/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    if (
      value &&
      !/^(?:(?:\\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
        value
      )
    ) {
      error = "Invalid Phone Number. eg((555)-555-5555, 555-555-5555)";
    }
    return error;
  }

  function validateCity(value) {
    let error;
    if (value && !/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value)) {
      error = "Invalid City";
    }
    return error;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add Established PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 3 of 9 - Application for Approval (optional)
                </div>
                <div className="attribute-title">Lender Name</div>
                <Field
                  name="lndrName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Lender Name' }}
                />
                <div className="attribute-title">Seller/Service Number</div>
                <Field
                  name="sellrServcrNbr"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 32, 'aria-label': 'Seller service number' }}
                  validate={validateNumNoStr}
                  variant="outlined"
                  error={touched.sellrServcrNbr && errors.sellrServcrNbr}
                  helperText={touched.sellrServcrNbr && errors.sellrServcrNbr}
                />
                <div className="attribute-title">Purpose of Application</div>
                <Field
                  name="purposeOfApplicn"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'Purpose of application' }}
                  variant="outlined"
                />
                <div className="attribute-title">
                  Expiration Date of Prior Approval (If Applicable)
                </div>
                <Field
                  name="dtExpryPriorApprval"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  inputProps={{ 'aria-label': 'Expiration date of prior approval' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <div className="attribute-title">Project's Legal Name</div>
                <Field
                  name="projectLegalName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'Project legal name' }}
                  variant="outlined"
                />
                <div className="attribute-title">Address</div>
                <Field
                  name="projectAddress"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'Project address' }}
                  variant="outlined"
                />
                <div className="attribute-title">City</div>
                <Field
                  name="projectCity"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128, 'aria-label': 'project city' }}
                  validate={validateCity}
                  variant="outlined"
                  error={touched.projectCity && errors.projectCity}
                  helperText={touched.projectCity && errors.projectCity}
                />
                <div className="attribute-title">State</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="projectState"
                    as="select"
                    aria-label='State'
                  >
                    <option value=" ">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">ZIP Code</div>
                <Field
                  name="projectZipCode"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 5, 'aria-label': 'Zip code' }}
                  validate={validateNumNoStr5}
                  variant="outlined"
                  error={touched.projectZipCode && errors.projectZipCode}
                  helperText={touched.projectZipCode && errors.projectZipCode}
                />
                <div className="attribute-title">
                  Homeowners' Association Federal Tax ID Number
                </div>
                <Field
                  name="hoaFedTaxId"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 12, 'aria-label': 'HOA Tax Id' }}
                  validate={validateTax}
                  variant="outlined"
                  error={touched.hoaFedTaxId && errors.hoaFedTaxId}
                  helperText={touched.hoaFedTaxId && errors.hoaFedTaxId}
                />
                <div className="attribute-title">
                  Why is a PERS being requested? Provide a summary and
                  recommendation for the approval of this project. Include
                  specific information about all project eligibility
                  requirements that are not met. Attach additional pages if
                  needed. Do not leave this section blank. Missing information
                  will cause delays in the review process. If the description is
                  left blank, the form will be returned for completion.{" "}
                </div>
                <Field
                  name="persReqSummary"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  multiline
                  inputProps={{ maxLength: 2000, 'aria-label': 'Pers request summary' }}
                  rows={4}
                  type="text"
                  variant="outlined"
                />
                <div className="attribute-title">
                  Name of lender's authorized representative{" "}
                </div>
                <Field
                  name="authrzdRepName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label': 'Name of authorized representative' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.authrzdRepName && errors.authrzdRepName}
                  helperText={touched.authrzdRepName && errors.authrzdRepName}
                />
                <div className="attribute-title">Title </div>
                <Field
                  name="authrzdRepTitle"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128, 'aria-label': 'Title' }}
                  variant="outlined"
                />
                <div className="attribute-title">Email Address </div>
                <Field
                  name="authrzdRepEmail"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ maxLength: 128, 'aria-label': 'Email address' }}
                  validate={validateEmail}
                  error={touched.authrzdRepEmail && errors.authrzdRepEmail}
                  helperText={touched.authrzdRepEmail && errors.authrzdRepEmail}
                />
                <div className="attribute-title">Phone Number</div>
                <Field
                  name="authrzdRepPhone"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validatePhone}
                  inputProps={{ 'aria-label': 'Phone number' }}
                  error={touched.authrzdRepPhone && errors.authrzdRepPhone}
                  helperText={touched.authrzdRepPhone && errors.authrzdRepPhone}
                />
                <div className="attribute-title">Date of Signature</div>
                <Field
                  name="dtAuthrzdRepSignature"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  inputProps={{ 'aria-label': 'Date of signature' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <div className="section-title">
                  If this submission is completed by someone other than the
                  lender, complete the following:
                </div>
                <div className="attribute-title">
                  Name of lender's authorized representative{" "}
                </div>
                <Field
                  name="preparerName1091"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200, 'aria-label':'Preparer name 1091' }}
                  validate={validateName}
                  variant="outlined"
                  error={touched.preparerName1091 && errors.preparerName1091}
                  helperText={
                    touched.preparerName1091 && errors.preparerName1091
                  }
                />
                <div className="attribute-title">Title </div>
                <Field
                  name="preparerTitle1091"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 128, 'aria-label': 'Preparer title 1091' }}
                  variant="outlined"
                />
                <div className="attribute-title">Email Address </div>
                <Field
                  name="preparerEmail1091"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ maxLength: 128, 'aria-label': 'Preparer email 1091' }}
                  validate={validateEmail}
                  error={touched.preparerEmail1091 && errors.preparerEmail1091}
                  helperText={
                    touched.preparerEmail1091 && errors.preparerEmail1091
                  }
                />
                <div className="attribute-title">Phone Number</div>
                <Field
                  name="preparerPhone1091"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validatePhone}
                  inputProps={{ 'aria-label': 'Preparer phone 1091' }}
                  error={touched.preparerPhone1091 && errors.preparerPhone1091}
                  helperText={
                    touched.preparerPhone1091 && errors.preparerPhone1091
                  }
                />
                <div className="attribute-title">Date of Signature</div>
                <Field
                  name="dtPreparerSignature"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  inputProps={{ 'aria-label': 'Date of preparer signature' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <div className="bottom-button-container">
                  <Button
                    type="submit"
                    className="next-button"
                    onClick={() => setDirection("forward")}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    type="submit"
                    className="previous-button"
                    onClick={() => setDirection("back")}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{fill: "red"}}/>
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

Form1091.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};

import React from 'react';
import SplAssmt from './SplAssmt';
const SplAssmtList = ({ list , updateSpecialAssessmentData , totalUnits, onSave}) => {
  let splAssmtList = <h3>Loading...</h3>;
  if (list) {
    splAssmtList = list.map((data, index) => <SplAssmt id={"spliSplAssmt"+index}  index ={index} assmt={data} updateSpecialAssessmentData={updateSpecialAssessmentData} totalUnits={totalUnits} onSave={onSave}  />);
  }

  return (
    <div>
        {splAssmtList}
    </div>
  )
}
export default SplAssmtList;
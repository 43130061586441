import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import axios from "axios";

import { Field, DatePicker } from "../Pers-atoms";
import Table1 from "./section-2-table-1";
import Table2 from "./section-2-table-2";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import ErrorPage from "../../error-page";
import {
  isReadonlyRole
} from "../../redux/actions/userAction";

export default function Section2({ collapseKey, lender, persId, projectId }) {
  const [isEdit, setEdit] = useState(false);
  const [lenderState, setLenderState] = useState({});
  const [expenseTerms, setExpenseTerms] = useState([]);
  const [floodCoverage, setFloodCoverage] = useState([]);
  const [apiErr, setApiErr] = useState("");
  const [internalErr, setInternalErr] = useState(false);

  const fetchAndTransform = async endpointUrl => {

    const { data } = await axios.get(
      `${global.REACT_APP_API_ENDPOINT}${endpointUrl}`
    );
    return Object.keys(data).map(key => ({ key: data[key], value: data[key] }));
  };

  const fetchExpenseTerms = async () => {
    const _expenseTerms = await fetchAndTransform(
      "/condoMgr/lookUpValues/MRTGE_RSPB_EXPNS_TERM_1076"
    );
    setExpenseTerms(_expenseTerms);
  };

  const fetchFloodCoverage = async () => {
    const _floodCoverage = await fetchAndTransform(
      "/condoMgr/lookUpValues/FLOOD_CVRG_DESC_1076"
    );
    setFloodCoverage(_floodCoverage);
  };

  useEffect(() => {
    fetchExpenseTerms();
    fetchFloodCoverage();
  }, []);

  useEffect(() => {
    setLenderState({ ...lender });
  }, [lender]);


  const handleSaveEdit = async () => {
    try {
      const body = {
        persId,
        projectId,
        pers1076: {
          ...lenderState
        }
      };
      setApiErr("");
      setInternalErr(false);

      const response = await axios.post(
        global.REACT_APP_API_ENDPOINT +
          `/pers/updateEstablishedPersProjectAndForms/1076`,
        body
      );
      if (response) {
        if (response.status == 200) {
          setEdit(false);
          setApiErr("");
          setInternalErr(false);
          // window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            setApiErr("");
            setEdit(false);
            setInternalErr(true);
            // window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      }
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleEdit = flag => {
    setEdit(flag);
    if (!flag) {
      setLenderState({ ...lender });
    }
  };

  const handleChange = event => {
    const { name, type, value, checked } = event.target;
    let _formData = { ...lenderState };
    _formData[name] = type === "checkbox" ? checked : value;
    setLenderState({ ..._formData });
  };

  const updateTable = (tableData, key) => {
    let _formData = { ...lenderState };
    _formData[key] = [...tableData];
    setLenderState({ ..._formData });
  };

  return (
    <Accordion defaultActiveKey="1" id="section4">
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey={collapseKey}>
            <p className="accordion-header-title">HOA Questionnaire (1076)</p>
          </ContextAwareToggle>
          {!isReadonlyRole() ? isEdit ? (
            <div>
              <button
                className="cancel-button"
                onClick={() => {
                  toggleEdit(false);
                  setApiErr("");
                }}
              >
                Cancel
              </button>
              <button className="add-phase-button" onClick={handleSaveEdit}>
                <FaSave className="edit-profile-icon" /> Save Edits
              </button>
            </div>
          ) : (
            <button
              className="add-phase-button"
              onClick={() => {
                toggleEdit(true);
                setInternalErr(false);
                setApiErr("");
              }}
            >
              <FaPen className="edit-profile-icon" /> Edit Section
            </button>
          ) :  null}
        </Card.Header>
        <Accordion.Collapse eventKey={collapseKey}>
          <Card.Body className="accordion-body-card">
            <div
              style={{
                textAlign: "right",
                color: "#ff0000",
                padding: 15
              }}
            >
              {apiErr ? apiErr : null}
            </div>
            {internalErr ? (
              <ErrorPage></ErrorPage>
            ) : (
              <div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Project Information
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Project Legal Name"
                      name="projLegalName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="HOA Management Address"
                      name="hoaMgmtAddr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="HOA Tax ID #"
                      name="hoaTaxId"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Name of Master or Umbrella Association (If Applicable)"
                      name="mstrAssocName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Project Physical Address"
                      name="projPhyAddr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="HOA Name (If different from Project Legal Name)"
                      name="hoaName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="HOA Management Company Tax ID #"
                      name="hoaMgmtCmpnyTaxId"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Does the project contain any of the following (check all
                    that apply):
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Hotel/motel/resort activities, mandatory or voluntary rental- pooling arrangements, or other restrictions on the unitowner’s ability to occupy the unit"
                      name="rentalPoolngArrngmntInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Manufactured homes"
                      name="mfHomeInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Non-incidental income from business operations"
                      name="nonIncdntlIncomeInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Deed or resale restrictions"
                      name="deedResaleRestrctnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Mandatory fee-based memberships for use of project amenities or services"
                      name="mndtryMbrshipInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Supportive or continuing care for seniors or for residents with disabilities"
                      name="supprtCareSeniorsInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Project Completion Information
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Is the project 100% complete, including all construction or renovation of units, common elements, and shared amenities for all project phases?"
                      name="proj100pctCompltnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Is the project legally phased?"
                      name="legallyPhasedInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="How many total phases are legally planned for the project?"
                      name="totalPhasesLegallyPlanned"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Are all planned amenities and common facilities fully complete?"
                      name="plannedAmenitiesCompltnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Is the project subject to additional phasing or annexation?"
                      name="subjAddtnlPhasingInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="How many phases have been completed?"
                      name="noOfPhasesCmpltd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="How many total units are planned for the project?"
                      name="totalUnitsPlanned"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Has the developer transferred control of the HOA to the unit owners?"
                      name="trnsfrControlHoaInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <DatePicker
                      label={
                        lenderState["trnsfrControlHoaInd"]
                          ? "Date Transfered"
                          : "Estimated date the transfer will occur"
                      }
                      name="dtTrnsfrControlHoa"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Newly Converted or Rehabilitated Project Information
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Is the project a conversion within the past 3 years of an existing structure that was used as an apartment, hotel/resort, retail or professional business, industrial or for other non-residential use? If Yes, complete the table below:"
                      name="convrsnOfExistngStructureInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="In what year was the property converted?"
                      name="yrConvrtd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Does the report from the licensed engineer indicate that the project is structurally sound, and that the condition and remaining useful life of the project’s major components are sufficient?"
                      name="structrllySoundInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Are replacement reserves allocated for all capital improvements?"
                      name="replacemntResrvsAlloctnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="In what year was the property built?"
                      name="yrBuilt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Was the conversion a full gut rehabilitation of the existing structure(s), including replacement of all major mechanical components?"
                      name="fullGutRehabConvrsnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Are all repairs affecting safety, soundness, and structural integrity complete?"
                      name="repairsCompltnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Are the project’s reserves sufficient to fund the improvements?"
                      name="resrvsToFundImprovmntsInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Financial Information
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="How many unit owners are 60 or more days delinquent on common expense assessments?"
                      name="unitOwnrsDelinqCommnExpnseCnt"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="In the event a lender acquires a unit due to foreclosure or a deed-in-lieu of foreclosure, is the mortgagee responsible for paying delinquent common expenseassessments?"
                      name="mrtgeeRespnbleDelinqExpnseInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="If Yes, for how long is the mortgagee responsible for paying common expense assessments?"
                      name="mrtgeeRespnbleDelinqExpnseTerm"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={expenseTerms}
                    />
                    <Field
                      label="Is the HOA involved in any active or pending litigation?"
                      name="hoaInvlvdLitigtnInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <div className="">
                      If Yes, attach documentation regarding the litigation from
                      the attorney or the HOA. Provide the attorney’s name and
                      contact information
                    </div>
                    <Field
                      label="Name"
                      name="litigAttrneyName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Phone"
                      name="litigAttrneyPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>

                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Ownership & Other Information
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Total number of units (Entire Project)"
                      name="totUnitsProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed (Entire Project)"
                      name="totUnitsSoldClosedProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units under bona-fide sales contracts (Entire Project)"
                      name="totUnitsBonafidSalesProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed or under contract to owner-occupants (Entire Project)"
                      name="totUnitsOwnrOccpntProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed or under contract to second home owners (Entire Project)"
                      name="totUnitsSecOwnrProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed or under contract to investor owners (Entire Project)"
                      name="totUnitsInvstrOwnrProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units being rented by developer, sponsor, or converter (Entire Project)"
                      name="totUnitsRentedProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units owned by the HOA (Entire Project)"
                      name="totUnitsOwndByHoaProj"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Total number of units (Subject Legal Phase))"
                      name="totUnitsPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed (Subject Legal Phase)"
                      name="totUnitsSoldClosedPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units under bona-fide sales contracts (Subject Legal Phase)"
                      name="totUnitsBonafidSalesPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed or under contract to owner-occupants (Subject Legal Phase)"
                      name="totUnitsOwnrOccpntPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed or under contract to second home owners (Subject Legal Phase)"
                      name="totUnitsSecOwnrPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units sold and closed or under contract to investor owners (Subject Legal Phase)"
                      name="totUnitsInvstrOwnrPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units being rented by developer, sponsor, or converter (Subject Legal Phase)"
                      name="totUnitsRentedPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Total number of units owned by the HOA (Subject Legal Phase)"
                      name="totUnitsOwndByHoaPhase"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>

                <Table1
                  lender={lender}
                  isEdit={isEdit}
                  persId={persId}
                  projectId={projectId}
                  update={updateTable}
                />

                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-left">
                    <Field
                      label="Do the unit owners have sole ownership interest in and the right to use the project amenities and commonareas?"
                      name="soleOwnrshipIntrstAmenitiesInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    {!lenderState["soleOwnrshipIntrstAmenitiesInd"] && (
                      <Field
                        label="If No, explain who has ownership interest in and rights to use the project amenities and common areas"
                        name="soleOwnrshipIntrstAmenitiesExpln"
                        formData={lenderState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    )}
                    <Field
                      label="Are any units in the project used for commercial or non-residential purposes?"
                      name="unitsUsedCommrclPurpseInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                </div>

                {lenderState["unitsUsedCommrclPurpseInd"] && (
                  <Table2
                    lender={lender}
                    isEdit={isEdit}
                    persId={persId}
                    projectId={projectId}
                    update={updateTable}
                  />
                )}

                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-left">
                    <Field
                      label="What is the total square footage of commercial space in the building that is separate from the residentialHOA? Include above and below grade space used for commercial purposes, such as public parking facilities, retail space, apartments, commercial offices, and so on. Total square footage of commercial space"
                      name="totSqFootageCommSpace"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>

                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Insurance Information & Financial Controls
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Are units or common elements located in a flood zone?"
                      name="floodZoneInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="If Yes, flood coverage is in force equaling (select only one option)"
                      name="floodCvrgInForceDesc"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      dropdown
                      dropdownValues={floodCoverage}
                    />
                    {lenderState["floodCvrgInForceDesc"] === "other" && (
                      <Field
                        label="Enter Amount"
                        name="floodCvrgInForceDescOtherAmt"
                        formData={lenderState}
                        handleChange={handleChange}
                        isEdit={isEdit}
                      />
                    )}
                    <div>
                      <strong>Hazard</strong>
                    </div>
                    <Field
                      label="Carrier/Agent Name"
                      name="hzrdInsAgentName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Carrier/Agent Phone Number"
                      name="hzrdInsAgentPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Policy Number"
                      name="hzrdInsPlcyNbr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <div>
                      <strong>Liability</strong>
                    </div>
                    <Field
                      label="Carrier/Agent Name"
                      name="liabInsAgentName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Carrier/Agent Phone Number"
                      name="liabInsAgentPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Policy Number"
                      name="liabInsPlcyNbr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <div>
                      <strong>Fidelity</strong>
                    </div>
                    <Field
                      label="Carrier/Agent Name"
                      name="fidelInsAgentName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Carrier/Agent Phone Number"
                      name="fidelInsAgentPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Policy Number"
                      name="fidelInsPlcyNbr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <div>
                      <strong>Flood</strong>
                    </div>
                    <Field
                      label="Carrier/Agent Name"
                      name="floodInsAgentName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Carrier/Agent Phone Number"
                      name="floodInsAgentPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Policy Number"
                      name="floodInsPlcyNbr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <div>
                      Check all of the following that apply regarding HOA
                      financial accounts?
                    </div>
                    <Field
                      label="HOA maintains separate accounts for operating and reserve funds."
                      name="hoaAcctsSeparateOpratingReserveFundsInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Appropriate access controls are in place for each account."
                      name="hoaAcctsAcessCntrlsInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="The bank sends copies of monthly bank statements directly to the HOA."
                      name="hoaAcctsMnthlyBankStmtsInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="Two members of the HOA Board of Directors are required to sign any check written on the reserve account."
                      name="hoaAcctsCheckSignedReqdMembersInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="The Management Company maintains separate records and bank accounts for each HOA that uses its services."
                      name="hoaAcctsMngmtCmpnyAcctsMaintnanceInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                    <Field
                      label="The Management Company does not have the authority to draw checks on, or transfer funds from, the reserve account of the HOA."
                      name="hoaAcctsMngmtCmpnyAuthorityReqmntsInd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="accordion-body-card-container">
                  <div className="accordion-body-card-title">
                    Contact Information
                  </div>
                  <div className="accordion-body-card-left">
                    <Field
                      label="Name of Preparer"
                      name="preparerName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Title of Preparer"
                      name="preparerTitle"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Preparer’s Company Name"
                      name="preparerCmpnyName"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <DatePicker
                      label="Date Completed"
                      name="dtCmpltd"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                  <div className="accordion-body-card-right">
                    <Field
                      label="Preparer’s Phone"
                      name="preparerPhone"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Preparer’s Email"
                      name="preparerEmail"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                    <Field
                      label="Preparer’s Company Address"
                      name="preparerCmpnyAddr"
                      formData={lenderState}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

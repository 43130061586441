import React from "react";
export const SpecialAssessments = ({ data, index }) => {

  const inArray = (list, key) => {
    console.log(list, key)
    var length = list.length;
    for (var i = 0; i < length; i++) {
      console.log(list);
      if (list[i].toLowerCase() == key.toLowerCase())
        return true;
    }
    return false;
  };

  
  const getIndex = (value) => {
    let index = '';
    switch (value) {
      case 0:
        index = "first";
        break;
      case 1:
        index = 'second';
        break;
      case 2:
        index = 'third';
        break;
      case 3:
        index = 'fourth';
        break;
      case 4:
        index = 'fifth';
        break;
      default:
        index = "";
        break;
    }
    return index;
  };

  return (
    <><>
      <div className="question">
        <div
          className="number"
          style={{
            borderLeft: "1px solid rgba(0,0,0,0.4)",
            borderBottom: "1px solid rgba(0,0,0,0.4)",
            marginLeft: "37px",
            marginBottom: "10px",
          }}
        ></div>{" "}
        <div style={{ paddingTop: "10px" }}>
          What is the original total amount of the{" " + getIndex(index) + " "} special assessment for the entire project (not per unit) when it was issued?
        </div>
      </div>
      <div
        className="summary-answer"
        style={{ paddingLeft: "86px" }}
      >
        <div className="label">Answer:&nbsp;</div>
        <div className="attribute">
          {data.fttlSpAssmts}
        </div>
      </div>
    </><>
        <div className="question">
          <div
            className="number"
            style={{
              borderLeft: "1px solid rgba(0,0,0,0.4)",
              borderBottom: "1px solid rgba(0,0,0,0.4)",
              marginLeft: "37px",
              marginBottom: "10px",
            }}
          ></div>{" "}
          <div style={{ paddingTop: "10px" }}>
            What is the reason for the special assessment?
          </div>
        </div>
        <div
          className="summary-answer"
          style={{ paddingLeft: "86px" }}
        >
          <div className="label">Answer:&nbsp;</div>
          <div className="attribute">
            {data.freasonsSpAssmts.toString()}
          </div>
        </div>
      </>
      {
        inArray(data.freasonsSpAssmts, 'Remediate Critical Repairs') ?
          (<>
            <div className="question">
              <div
                className="number"
                style={{
                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                  borderBottom: "1px solid rgba(0,0,0,0.4)",
                  marginLeft: "70px",
                  marginBottom: "10px",
                }}
              ></div>{" "}
              <div style={{ paddingTop: "10px" }}>
                Have all necessary repairs been fully complete to remediate the critical repair issued?
              </div>
            </div>
            <div
              className="summary-answer"
              style={{ paddingLeft: "86px" }}
            >
              <div className="label">Answer:&nbsp;</div>
              {data.fremediateRepairIndSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.fremediateRepairInd}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.fremediateRepairInd}
                  </div>
                </>
              )}
            </div>
          </>) :
          (<></>)

      }
      {
        inArray(data.freasonsSpAssmts, 'Other') ?
          (<>
            <>
              <div className="question">
                <div
                  className="number"
                  style={{
                    borderLeft: "1px solid rgba(0,0,0,0.4)",
                    borderBottom: "1px solid rgba(0,0,0,0.4)",
                    marginLeft: "70px",
                    marginBottom: "10px",
                  }}
                ></div>{" "}
                <div style={{ paddingTop: "10px" }}>
                  Other Reason?
                </div>
              </div>
              <div
                className="summary-answer"
                style={{ paddingLeft: "86px" }}
              >
                <div className="label">Answer:&nbsp;</div>
                <div className="attribute">
                  {data.fotherReasonSpAssmts}
                </div>
              </div>
            </>
          </>) :
          (<></>)
      }
      <>

      </><>
        <div className="question">
          <div
            className="number"
            style={{
              borderLeft: "1px solid rgba(0,0,0,0.4)",
              borderBottom: "1px solid rgba(0,0,0,0.4)",
              marginLeft: "37px",
              marginBottom: "10px",
            }}
          ></div>{" "}
          <div style={{ paddingTop: "10px" }}>
            How many units are ≥ 60 days past due on their special assessment payment as described in                       <a
              target="_blank"
              href="https://www.fanniemae.com/content/guide/selling/b4/2.2/02.html"
            >
              {" "}
              B4-2.2-02
            </a>?</div>
        </div>
        <div
          className="summary-answer"
          style={{ paddingLeft: "86px" }}
        >
          <div className="label">Answer:&nbsp;</div>
          {data.fnoOfUnitsDelinquentSt === "Fail" ? (
            <>
              <div
                className="attribute"
                style={{ color: "#D0021B" }}
              >
                {data.fnoOfUnitsDelinquent}
              </div>
            </>
          ) : (
            <>
              <div className="attribute">
                {data.fnoOfUnitsDelinquent}
              </div>
            </>
          )}
        </div>
      </><hr style={{ borderTop: "dotted 1px", color: "#2e2e2e" }} /></>
  );
}

import { TYPE1026_RESULTS } from "../actions/ActionType";

export const initialState = {
  types: []
};
export const type1026Reducer = (type = initialState, action) => {
  switch (action.type) {
    case TYPE1026_RESULTS:
      return {
        types1026: action.payload
      };
    default:
      return type;
  }
};

import React, { useState, useEffect } from 'react';
import IndividualChildPhase from './IndividualChildPhase';
import './childphaselist.css';
import { FaSort } from "react-icons/fa";


const ChildPhaseList = ({ list }) => {
  const [sortType, setSortType] = useState({
    phaseName: "asc",
    phaseId: "asc",
    street: "asc",
    unitNumber: "asc",
    unitCount: "asc",
    buildingNumber: "asc",
  });
  
  const [childPhaseList, setChildPhaseList] = useState([]);
  
  useEffect(() => {
    if (list) {
      setChildPhaseList(list);
    }
  }, [list]);
  
  const onSort = (column) => {
    const sortOrder = sortType[column] === "asc" ? "desc" : "asc";
    const obj = { ...sortType, [column]: sortOrder };
    setSortType(JSON.parse(JSON.stringify(obj)));
    sortPhaseList(column);
  };

  const getSortComparer = (column) => {
    const sortable = (value) => {
      if (!value) {
        return value;
      }
      if (!isNaN(+value)) {
        return +value;
      }
      return value.toString().toLowerCase();
    };

    const compare = (a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
      }

      // blanks come before 0
      if (typeof a === 'number' && !b) {
        return 1;
      }
        
      if (typeof b === 'number' && !a) {
        return -1;
      }

      if (typeof a === 'number' && typeof b === 'string') {
        return -1;
      }
      
      if (typeof a === 'string' && typeof b === 'number') {
        return 1;
      }
      
      if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
      }
      
      return a < b
        ? -1
        : a > b
        ? 1
        : 0;
    };

    return (itemA, itemB) => {
      if (sortType[column] !== 'asc') {
        [itemA, itemB] = [itemB, itemA];
      }

      return compare(sortable(itemA[column]), sortable(itemB[column]));
    };
  };
  
  const sortPhaseList = (column) => {
    // Array.sort always places undefined at the end of the array. We don't want this.
    const sortList = [].concat(childPhaseList).map(item => item === undefined ? null : item);
    sortList.sort(getSortComparer(column));
    setChildPhaseList(sortList);
  };

  let childPhase = <h3>Loading...</h3>;

  if (childPhaseList) {
    childPhase = childPhaseList.map((m, i) => <IndividualChildPhase key={i} item={m} />);
  }

  return (
    <div className="pdf-container">
        <div className="pdf-header">
        <div className="pdf-header-name">
          Phase Name
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("phaseName");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Phase ID
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("phaseId");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Address
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("street");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Unit No.
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("unitNumber");
            }}
          />
        </div>
        <div className="pdf-header-name">
          No. of Units
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("unitCount");
            }}
          />
        </div>
        <div className="pdf-header-name">
          Building No.
          <FaSort
            className="sort-icon"
            onClick={() => {
              onSort("buildingNumber");
            }}
          />
        </div>
        </div>
        <div className="pdf-body">
          {childPhase}
        </div>
    </div>
  )
}
export default ChildPhaseList;
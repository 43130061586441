import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./SessionTimeOutModal.css";
import { min } from "moment";

export const SessionTimeOutModal = ({
  showModal,
  handleContinue,
  handleLogout,
}) => {
  // const [counter, setCounter] = useState(0);
  // const [isInitial, setInitial] = useState(true);
  useEffect(() => {
    //   if (counter > 0) {
    //     console.log(counter);
    //     const timer = setInterval(() => setCounter(counter - 1), 60000);
    //     return () => clearInterval(timer);
    //   }
    // }
  });

  return (
    <Modal show={showModal} onHide={handleContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Session Timeout Alert!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="idle-content">
          {" "}
          Your 30 minute session will expire in the next 5 minutes. If you want to keep going or save your work, click Continue.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <>
          {" "}
          <Button className="idle-button" onClick={handleLogout}>
            Logout
          </Button>
          <Button className="idle-button" onClick={handleContinue}>
            Continue
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

import React from 'react';
import IndividualPersLetter from './IndividualPersLetter';
import './PersLetter.css';


const PersLetter = ({ list }) => {
  let letter = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    letter = list.map((m, i) => <IndividualPersLetter key={i} item={m} />);
  }

  return (
    <div className="pdf-container">
        <div className="pdf-header">
            <div className="pdf-header-name">File</div>
            <div className="pdf-header-name">Project or Phase</div>
            <div className="pdf-header-name">Created By</div>
            <div className="pdf-header-name">Date Generated</div>
        </div>
        <div className="pdf-body">
            {letter}
        </div>
    </div>
  )
}
export default PersLetter;
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import { List, ListItem, ListItemText } from "@material-ui/core/";
import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";

export const Review = ({
  formData,
  nextStep,
  nextStep2,
  prevStep,
  step,
  setPersId,
  selectedPhaseSaved,
}) => {
  //   const [direction, setDirection] = useState("back");
  const {
    //perslen
    projectId,
    persStatus,
    persExpiration,
    persStatusJustification,
    receivedDate,
    lenderName,
    assgndRiskAnalyst,
    decisionLevel,
    lenderSSN,
    lenderAddress,
    lenderCity,
    lenderState,
    lenderZip,
    requesterName,
    requesterEmail,
    builderOrDevelopeName,
    builderOrDeveloperPrincipals,
    builderOrDeveloperPrimaryContact,
    builderOrDeveloperContactPhone,
    builderOrDeveloperAddress,
    builderOrDeveloperState,
    builderOrDeveloperZIPCode,

    //pers1026
    projectName,
    projectAddress,
    projectCity,
    projectState,
    projectZipCode,
    legalDescription,
    projectType,
    propertyRights,
    masterHOAIndicator,
    complete1051Indicator,
    projectStoriesCount,
    projectStructureType,
    builderOwnershipIndicator,
    builderOwnershipRetainComments,
    builderRentingUnitsIndicator,
    builderRentingUnitsComments,
    recreationServicesSharedUserAgreementsIndicator,
    unitOwnerSoleOwnershipAmenitiesIndicator,
    unitOwnerSoleOwnershipAmenitiesComments,
    moreThanOnePhaseIndicator,
    phaseType,
    phaseTypeMarketingComments,
    salesStrategy,
    salesStrategyExplaination,
    constructionStatus,
    yearCompleted,
    proposedStartDate,
    constructionStartedDate,
    anticipatedCompletionDate,
    conversionGutRehabNonGutRehabType,
    litigationFiledIndicator,
    environProblemsIndicator,
    environProblemsExplanation,
    resaleRestrictionIndicator,
    resaleRestrictionExplaination,
    healthSafetyIssuesIndicator,
    healthSafetyIssuesExplaination,
    commercialUnitsInclusionIndicator,
    commercialUnitsSoldLeasedRetained,
    commercialUnitsCount,
    commercialSpacePercent,
    residentialSpacePercent,
    commercialSpacePurpose,
    commercialSpaceCompleteIndicator,
    nonIncidentalBusiOperationsOwnedHOAIndicator,
    nonIncidentalBusiOperationsOwnedHOAComments,
    phase1026DataList,

    //pers1029
    totalUnitsCount,
    unitsSoldToPrincipalResidencePurchasersCount,
    unitsSoldToSecondHomePurchasersCount,
    unitsSoldToInvestorPurchasersCount,
    priorTotalUnitsCount,
    priorUnitsSoldToPrincipalResidencePurchasersCount,
    priorUnitsSoldToSecondHomePurchasersCount,
    priorUnitsSoldToInvestorPurchasersCount,
    salesSourceInformation,
    closedSalesUnitsCount,

    //pers1051
    masterAssociationName,
    exceptions1051,
    masterAssociationNotes,

    //pers1054
    submittedComplete1054Indicator,
    includedAttornerOpnionLetterIndicator,
    exceptions1054,

    //pers1071
    masterOrBlanketInsuranceExpirationDate,
    mbFireAndCoverageInsuranceInForceIndicator,
    insuranceEqualsReplacementCostIndicator,
    fireAndExtCoverageInsuranceAmount,
    specialCondominiumEndorsementIndicator,
    standardMortgageClauseIndicator,
    agreedAmountEndorsementIndicator,
    inflationGuardEndorsementIndicator,
    replacementCostEndorsementIndicator,
    otherSpecialEndorsementIndicator,
    constructionCodeChangeIndicator,
    cccExposureProtectedByEndorsementsIndicator,
    condoInsuranceTrusteeIndicator,
    boilerInsPolicyIndicator,
    boilerInsuranceCoverageAmount,
    liabiltyInsPolicyIndicator,
    liabilityInsuranceCoverageAmount,
    floodInsPolicyIndicator,
    floodInsuranceCoverageAmount,
    fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd,
    fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd,
    associationCoverageAmount,
    managementCoverageAmount,
    hoaMaintainInsuranceIndicator,
    exceptions1071,

    //pers1081
    phasesIncluded,
    unitsIncludedCount,
    certificationByName,
    professionalDesignationOrLicenseNumber,
    certficationByRoleEngArchAppr,
    exceptions1081,

    //persBudget
    incomeFromAssessments,
    reserves,
    percentageOfReservesOnHand,
    exceptionsBudget,

    //persPhases
    phaseIds,
    subPhaseIds,
  } = formData;

  const passData = JSON.parse(sessionStorage.getItem("passData"));

  const establishedData = {
    //perslen
    perslen: {
      projectId: projectId,
      persStatus: persStatus, // 'PERS Final Approval', //persStatus,
      persExpiration: persExpiration,
      persStatusJustification: persStatusJustification,
      receivedDate: receivedDate,
      lenderName: lenderName,
      assgndRiskAnalyst: assgndRiskAnalyst,
      decisionLevel: decisionLevel,
      lenderSSN: lenderSSN,
      lenderAddress: lenderAddress,
      lenderCity: lenderCity,
      lenderState: lenderState,
      lenderZip: lenderZip,
      requesterName: requesterName,
      requesterEmail: requesterEmail,
      builderOrDevelopeName: builderOrDevelopeName,
      builderOrDeveloperPrincipals: builderOrDeveloperPrincipals,
      builderOrDeveloperPrimaryContact: builderOrDeveloperPrimaryContact,
      builderOrDeveloperContactPhone: builderOrDeveloperContactPhone,
      builderOrDeveloperAddress: builderOrDeveloperAddress,
      builderOrDeveloperState: builderOrDeveloperState,
      builderOrDeveloperZIPCode: builderOrDeveloperZIPCode,
      createdBy: passData.user,
    },
    pers1026: {
      //pers1026
      projectName: projectName,
      projectAddress: projectAddress,
      projectCity: projectCity,
      projectState: projectState,
      projectZipCode: projectZipCode,
      legalDescription: legalDescription,
      projectType: projectType,
      propertyRights: propertyRights,
      masterHOAIndicator: masterHOAIndicator,
      complete1051Indicator: complete1051Indicator,
      projectStoriesCount: projectStoriesCount,
      projectStructureType: projectStructureType,
      builderOwnershipIndicator: builderOwnershipIndicator,
      builderOwnershipRetainComments: builderOwnershipRetainComments,
      builderRentingUnitsIndicator: builderRentingUnitsIndicator,
      builderRentingUnitsComments: builderRentingUnitsComments,
      recreationServicesSharedUserAgreementsIndicator:
        recreationServicesSharedUserAgreementsIndicator,
      unitOwnerSoleOwnershipAmenitiesIndicator:
        unitOwnerSoleOwnershipAmenitiesIndicator,
      unitOwnerSoleOwnershipAmenitiesComments:
        unitOwnerSoleOwnershipAmenitiesComments,
      moreThanOnePhaseIndicator: moreThanOnePhaseIndicator,
      phaseType: phaseType,
      phaseTypeMarketingComments: phaseTypeMarketingComments,
      salesStrategy: salesStrategy,
      salesStrategyExplaination: salesStrategyExplaination,
      constructionStatus: constructionStatus,
      yearCompleted: yearCompleted,
      proposedStartDate: proposedStartDate,
      constructionStartedDate: constructionStartedDate,
      anticipatedCompletionDate: anticipatedCompletionDate,
      conversionGutRehabNonGutRehabType: conversionGutRehabNonGutRehabType,
      litigationFiledIndicator: litigationFiledIndicator,
      environProblemsIndicator: environProblemsIndicator,
      environProblemsExplanation: environProblemsExplanation,
      resaleRestrictionIndicator: resaleRestrictionIndicator,
      resaleRestrictionExplaination: resaleRestrictionExplaination,
      healthSafetyIssuesIndicator: healthSafetyIssuesIndicator,
      healthSafetyIssuesExplaination: healthSafetyIssuesExplaination,
      commercialUnitsInclusionIndicator: commercialUnitsInclusionIndicator,
      commercialUnitsSoldLeasedRetained: commercialUnitsSoldLeasedRetained,
      commercialUnitsCount: commercialUnitsCount,
      commercialSpacePercent: commercialSpacePercent,
      residentialSpacePercent: residentialSpacePercent,
      commercialSpacePurpose: commercialSpacePurpose,
      commercialSpaceCompleteIndicator: commercialSpaceCompleteIndicator,
      nonIncidentalBusiOperationsOwnedHOAIndicator:
        nonIncidentalBusiOperationsOwnedHOAIndicator,
      nonIncidentalBusiOperationsOwnedHOAComments:
        nonIncidentalBusiOperationsOwnedHOAComments,
      phase1026DataList: phase1026DataList,
      createdBy: passData.user,
    },
    pers1029: {
      //pers1029
      totalUnitsCount: totalUnitsCount,
      unitsSoldToPrincipalResidencePurchasersCount:
        unitsSoldToPrincipalResidencePurchasersCount,
      unitsSoldToSecondHomePurchasersCount:
        unitsSoldToSecondHomePurchasersCount,
      unitsSoldToInvestorPurchasersCount: unitsSoldToInvestorPurchasersCount,
      priorTotalUnitsCount: priorTotalUnitsCount,
      priorUnitsSoldToPrincipalResidencePurchasersCount:
        priorUnitsSoldToPrincipalResidencePurchasersCount,
      priorUnitsSoldToSecondHomePurchasersCount:
        priorUnitsSoldToSecondHomePurchasersCount,
      priorUnitsSoldToInvestorPurchasersCount:
        priorUnitsSoldToInvestorPurchasersCount,
      salesSourceInformation: salesSourceInformation,
      closedSalesUnitsCount: closedSalesUnitsCount,
      createdBy: passData.user,
    },
    pers1051: {
      //pers1051
      masterAssociationName: masterAssociationName,
      exceptions1051: exceptions1051,
      masterAssociationNotes: masterAssociationNotes,
      createdBy: passData.user,
    },
    pers1054: {
      //pers1054
      submittedComplete1054Indicator: submittedComplete1054Indicator,
      includedAttornerOpnionLetterIndicator:
        includedAttornerOpnionLetterIndicator,
      exceptions1054: exceptions1054,
      createdBy: passData.user,
    },
    pers1071: {
      //pers1071
      masterOrBlanketInsuranceExpirationDate:
        masterOrBlanketInsuranceExpirationDate,
      mbFireAndCoverageInsuranceInForceIndicator:
        mbFireAndCoverageInsuranceInForceIndicator,
      insuranceEqualsReplacementCostIndicator:
        insuranceEqualsReplacementCostIndicator,
      fireAndExtCoverageInsuranceAmount: fireAndExtCoverageInsuranceAmount,
      specialCondominiumEndorsementIndicator:
        specialCondominiumEndorsementIndicator,
      standardMortgageClauseIndicator: standardMortgageClauseIndicator,
      agreedAmountEndorsementIndicator: agreedAmountEndorsementIndicator,
      inflationGuardEndorsementIndicator: inflationGuardEndorsementIndicator,
      replacementCostEndorsementIndicator: replacementCostEndorsementIndicator,
      otherSpecialEndorsementIndicator: otherSpecialEndorsementIndicator,
      constructionCodeChangeIndicator: constructionCodeChangeIndicator,
      cccExposureProtectedByEndorsementsIndicator:
        cccExposureProtectedByEndorsementsIndicator,
      condoInsuranceTrusteeIndicator: condoInsuranceTrusteeIndicator,
      boilerInsPolicyIndicator: boilerInsPolicyIndicator,
      boilerInsuranceCoverageAmount: boilerInsuranceCoverageAmount,
      liabiltyInsPolicyIndicator: liabiltyInsPolicyIndicator,
      liabilityInsuranceCoverageAmount: liabilityInsuranceCoverageAmount,
      floodInsPolicyIndicator: floodInsPolicyIndicator,
      floodInsuranceCoverageAmount: floodInsuranceCoverageAmount,
      fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd:
        fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd,
      fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd:
        fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd,
      associationCoverageAmount: associationCoverageAmount,
      managementCoverageAmount: managementCoverageAmount,
      hoaMaintainInsuranceIndicator: hoaMaintainInsuranceIndicator,
      exceptions1071: exceptions1071,
      createdBy: passData.user,
    },
    pers1081: {
      //pers1081
      phasesIncluded: phasesIncluded,
      unitsIncludedCount: unitsIncludedCount,
      certificationByName: certificationByName,
      professionalDesignationOrLicenseNumber:
        professionalDesignationOrLicenseNumber,
      certficationByRoleEngArchAppr: certficationByRoleEngArchAppr,
      exceptions1081: exceptions1081,
      createdBy: passData.user,
    },
    persBudget: {
      //persBudget
      incomeFromAssessments: incomeFromAssessments,
      reserves: reserves,
      percentageOfReservesOnHand: percentageOfReservesOnHand,
      exceptionsBudget: exceptionsBudget,
      createdBy: passData.user,
    },
    persPhase: {
      //persPhase
      phaseIds: selectedPhaseSaved,
      subPhaseIds: [],
      createdBy: passData.user,
    },
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [apiErr, setApiErr] = useState("");

  function sunbmitPersForm() {
    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/pers/savePersProjectAndForms`,
        establishedData
      )
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          setPersId(response.data);
          setTimeout(() => {
            nextStep();
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log("savePersProjectAndForms error", error);
      });
  }

  function sunbmitPersFormDecision() {
    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/pers/savePersProjectAndForms`,
        establishedData
      )
      .then((response) => {
        if (response.status == 200) {
          setApiErr("");
          setPersId(response.data);
          setTimeout(() => {
            nextStep();
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            setApiErr(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("savePersProjectAndForms error", error);
      });
  }

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          //   direction === "back" ? prevStep() : nextStep();
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">Section 6 of 9 - Review</div>
                <>
                  <div className="page-container">
                    <div className="accordion-top-container">
                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Lending and Project Details
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project ID"
                                  secondary={projectId}
                                />
                              </ListItem>
                              {/* <ListItem className="page-select">
                                <ListItemText
                                  primary="PERS Status"
                                  secondary={persStatus}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="PERS Expiration"
                                  secondary={persExpiration}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="PERS Status Justification"
                                  secondary={persStatusJustification}
                                />
                              </ListItem> */}
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Received Date"
                                  secondary={receivedDate}
                                  />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Assigned Risk Analyst"
                                  secondary={assgndRiskAnalyst}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender"
                                  secondary={lenderName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Seller/Service Number"
                                  secondary={lenderSSN}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Address"
                                  secondary={lenderAddress}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender City"
                                  secondary={lenderCity}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender State"
                                  secondary={lenderState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Lender Zip Code"
                                  secondary={lenderZip}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Requester"
                                  secondary={requesterName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Requester Email"
                                  secondary={requesterEmail}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Builder/Developer Name (LLC)"
                                  secondary={builderOrDevelopeName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Builder/Developer Principals"
                                  secondary={builderOrDeveloperPrincipals}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Builder/Developer Primary Contact"
                                  secondary={builderOrDeveloperPrimaryContact}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Contact Phone"
                                  secondary={builderOrDeveloperContactPhone}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Builder/Developer Address"
                                  secondary={builderOrDeveloperAddress}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="State:"
                                  secondary={builderOrDeveloperState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="ZIP Code"
                                  secondary={builderOrDeveloperZIPCode}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>

                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Application for Project Approval (1026)
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Name"
                                  secondary={projectName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Address"
                                  secondary={projectAddress}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="City"
                                  secondary={projectCity}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="State"
                                  secondary={projectState}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Zip Code"
                                  secondary={projectZipCode}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Legal Description"
                                  secondary={legalDescription}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Type"
                                  secondary={projectType}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Project Rights"
                                  secondary={propertyRights}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is there a Master Homeowners Association?"
                                  secondary={masterHOAIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Did the lender include a completed 1051?"
                                  secondary={complete1051Indicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="This Project Has	"
                                  secondary={projectStoriesCount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="This Project Consists of	"
                                  secondary={projectStructureType}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Will the developer retain ownership of any units, common elements or other project facilities (other than during the initial marketing period for the project)?"
                                  secondary={builderOwnershipIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={builderOwnershipRetainComments}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Are any units rented by the developer?	"
                                  secondary={builderRentingUnitsIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={builderRentingUnitsComments}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Are there any shared-use agreements for recreational amenities or services?	"
                                  secondary={
                                    recreationServicesSharedUserAgreementsIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Do the unit owners have sole ownership in and exclusive rights to use amenities?	"
                                  secondary={
                                    unitOwnerSoleOwnershipAmenitiesIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={
                                    unitOwnerSoleOwnershipAmenitiesComments
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Will the project consist of more than one phase ?	"
                                  secondary={moreThanOnePhaseIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="The Phases are"
                                  secondary={phaseType}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments	"
                                  secondary={phaseTypeMarketingComments}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Describe Sales Strategy	"
                                  secondary={salesStrategy}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={salesStrategyExplaination}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Status of Construction	"
                                  secondary={constructionStatus}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Year Completed	"
                                  secondary={yearCompleted}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Proposed Start Date	"
                                  secondary={proposedStartDate}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Date Construction Started	"
                                  secondary={constructionStartedDate}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Anticipated Completion Date	"
                                  secondary={anticipatedCompletionDate}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Conversion, Gut or Non-Gut Rehab?   "
                                  secondary={conversionGutRehabNonGutRehabType}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Are there any mechanic’s liens, complaints, or litigation filed against the property?	"
                                  secondary={litigationFiledIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Has the lender identified or become aware of any environmental problem(s)?	"
                                  secondary={environProblemsIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Has the lender identified or become aware of any environmental problem(s)?	"
                                  secondary={environProblemsExplanation}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is the project subject to resale restrictions?	"
                                  secondary={resaleRestrictionIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={resaleRestrictionExplaination}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Does the project include nonresidential/commercial units?	"
                                  secondary={healthSafetyIssuesIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={healthSafetyIssuesExplaination}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Does the project include nonresidential/commercial units?	"
                                  secondary={commercialUnitsInclusionIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="The units are to be	"
                                  secondary={commercialUnitsSoldLeasedRetained}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of non-residential/commercial units	"
                                  secondary={commercialUnitsCount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Percent of nonresidential/commercial space	"
                                  secondary={commercialSpacePercent}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Percent of residential space	"
                                  secondary={residentialSpacePercent}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Describe intended purpose and use of non-residential/commercial space.	"
                                  secondary={commercialSpacePurpose}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is the commercial space complete?	"
                                  secondary={commercialSpaceCompleteIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Are there any non-incidental business operations owned or operated by the HOA?	"
                                  secondary={
                                    nonIncidentalBusiOperationsOwnedHOAIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Comments"
                                  secondary={
                                    nonIncidentalBusiOperationsOwnedHOAComments
                                  }
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>

                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Warranty for Project Presales (1029)
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total Number of Units in the Subject Legal/Construction Phase	"
                                  secondary={totalUnitsCount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units in the Subject Legal/Construction Phase Sold To: Principal Residence Purchasers	"
                                  secondary={
                                    unitsSoldToPrincipalResidencePurchasersCount
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units in the Subject Legal/Construction Phase Sold To: Second Home Purchasers	"
                                  secondary={
                                    unitsSoldToSecondHomePurchasersCount
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units in the Subject Legal/Construction Phase Sold To: Investor Purchasers	"
                                  secondary={unitsSoldToInvestorPurchasersCount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Total Number of Units In Prior Legal/Construction Phases	"
                                  secondary={priorTotalUnitsCount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units in Prior Legal/Construction Phase(s) Sold To Principal Residence Purchasers	"
                                  secondary={
                                    priorUnitsSoldToPrincipalResidencePurchasersCount
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units in Prior Legal/Construction Phase(s) Sold To Second Home Purchasers	"
                                  secondary={
                                    priorUnitsSoldToSecondHomePurchasersCount
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Number of Units in Prior Legal/Construction Phase(s) Sold To Investor Purchasers	"
                                  secondary={
                                    priorUnitsSoldToInvestorPurchasersCount
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Indicate the Source of sales information	"
                                  secondary={salesSourceInformation}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Provide the number of closed sales of units:	"
                                  secondary={closedSalesUnitsCount}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>

                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Statement of Insurance and Fidelity Coverage
                                (1071)
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Expiration date of master or blanket insurance policy:	"
                                  secondary={
                                    masterOrBlanketInsuranceExpirationDate
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Are the Master or Blanket Type Fire and Extended Coverage Insurance in Force?	"
                                  secondary={
                                    mbFireAndCoverageInsuranceInForceIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is the amount of insurance equal to 100% of the current replacement cost of the insurable improvements including unit interior improvements and betterments (if a condominium project) or 100% of the current replacement cost of the insurable improvements to the common areas (if a PUD)?	"
                                  secondary={
                                    insuranceEqualsReplacementCostIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="The amount of such insurance in force is $	"
                                  secondary={fireAndExtCoverageInsuranceAmount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Special Condominium Endorsement	"
                                  secondary={
                                    specialCondominiumEndorsementIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Standard Mortgage Clause or Equivalent	"
                                  secondary={standardMortgageClauseIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Agreed Amount Endorsement	"
                                  secondary={agreedAmountEndorsementIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Inflation Guard Endorsement	"
                                  secondary={inflationGuardEndorsementIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Replacement Cost Endorsement	"
                                  secondary={
                                    replacementCostEndorsementIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Other Special Endorsement	"
                                  secondary={otherSpecialEndorsementIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is the project subject to a substantial construction code change that would be operative in the event of partial destruction of the project by an insured hazard?	"
                                  secondary={constructionCodeChangeIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="If Yes, is this exposure protected by the appropriate endorsements?	"
                                  secondary={
                                    cccExposureProtectedByEndorsementsIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="If a condominium, is there an insurance trustee?	"
                                  secondary={condoInsuranceTrusteeIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="If the project contains a steam boiler, is a boiler insurance policy in effect?	"
                                  secondary={boilerInsPolicyIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Amount of coverage"
                                  secondary={boilerInsuranceCoverageAmount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is a public liability insurance policy in force?"
                                  secondary={liabiltyInsPolicyIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Amount of coverage"
                                  secondary={liabilityInsuranceCoverageAmount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is flood insurance in force?"
                                  secondary={floodInsPolicyIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Amount of coverage"
                                  secondary={floodInsuranceCoverageAmount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Is Fidelity Coverage in force in an amount at least equal to the estimated maximum amount of funds that will be in the custody of the Homeowners’ Association or its management agent at any time during the term of the coverage?	"
                                  secondary={
                                    fidelityCoverageAmtEqlsMaxAmtsCustodyOfHOAInd
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="If No, are the financial controls that justify a lesser amount among those specified in the Fannie Mae Selling Guide, and is the coverage at least equal to the sum of three months’ assessments on all units in the project?	"
                                  secondary={
                                    fidelityCvrgNoFinControlEqlsThreeMonthAssmtsInd
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Association Coverage	"
                                  secondary={associationCoverageAmount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Management Coverage	"
                                  secondary={managementCoverageAmount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Do the constituent documents of the project require the Homeowners’ Association to maintain the insurance and fidelity coverage required by Fannie Mae?	"
                                  secondary={hoaMaintainInsuranceIndicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions	"
                                  secondary={exceptions1071}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>

                      <Accordion defaultActiveKey="0" id="section5">
                        <Card className="accordion-header-card">
                          <Card.Header className="accordion-header-space">
                            <ContextAwareToggle eventKey="1">
                              <p className="accordion-header-title">
                                Additional Documents
                              </p>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="accordion-body-card">
                              <h1 className="policy-header">Form 1051</h1>

                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Name of Master Association	"
                                  secondary={masterAssociationName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions	"
                                  secondary={exceptions1051}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Notes on Master	"
                                  secondary={masterAssociationNotes}
                                />
                              </ListItem>

                              <h1 className="policy-header">Form 1054</h1>

                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Has the lender submitted a completed 1054?	"
                                  secondary={submittedComplete1054Indicator}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Has the lender included the Attorney Opinion Letter?	"
                                  secondary={
                                    includedAttornerOpnionLetterIndicator
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions	"
                                  secondary={exceptions1054}
                                />
                              </ListItem>

                              <h1 className="policy-header">Form 1081</h1>

                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Phases Included	"
                                  secondary={phasesIncluded}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="# of Units Included	"
                                  secondary={unitsIncludedCount}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Certification Provided By:	"
                                  secondary={certificationByName}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Professional Designation / License Number	"
                                  secondary={
                                    professionalDesignationOrLicenseNumber
                                  }
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Certification Provided By:	"
                                  secondary={certficationByRoleEngArchAppr}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions"
                                  secondary={exceptions1081}
                                />
                              </ListItem>

                              <h1 className="policy-header">Budget</h1>

                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Income from Assessments (HOA dues)	"
                                  secondary={incomeFromAssessments}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Reserves	"
                                  secondary={reserves}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Percentage of Reserves on Hand	"
                                  secondary={percentageOfReservesOnHand}
                                />
                              </ListItem>
                              <ListItem className="page-select">
                                <ListItemText
                                  primary="Exceptions "
                                  secondary={exceptionsBudget}
                                />
                              </ListItem>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </>
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {apiErr ? apiErr : null}
                </div>
                <div className="bottom-button-container">
                  <div>
                    <Button
                      type="submit"
                      className="next-button next-btn-save"
                      disabled={disable}
                      onClick={() => {
                        sunbmitPersForm();
                        setDisable(true);
                      }}
                    >
                      Save
                    </Button>
                    {/* <Button
                      type="submit"
                      className="next-button next-btn-save"
                      onClick={() => {
                        sunbmitPersFormDecision();
                      }}
                      style={{marginLeft: 15}}
                    >
                      Save and Issue PERS Decision
                    </Button> */}
                  </div>
                  <Button
                    type="submit"
                    className="previous-button review-preview"
                    onClick={() => prevStep()}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>

            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{ fill: "red" }} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

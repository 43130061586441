import {
  ADD_PROJECT,
  ADD_CONDO_INFO,
  ADD_MASTER_ASSC_INFO,
  ADD_HOA_ASSC_INFO,
  ADD_MASTER_ASSC_ID,
  ADD_HOA_ASSC_ID,
  ADD_PERS_PROJECT_INFO,
  SUBMISSION_TYPES_RESULT,
  INITIATE_ADD_NEW_PROJECT
} from "../actions/ActionType";

export const initialState = {
  projectId: null,
  masterAsscId: null,
  hoaId: null,
  condoProjectInfo: {
    type: "",
    userName: "",
    phaseName: "",
    alternativeName: "",
    styleComments: "",
    HOAtaxid: "",
    HOAname: "",
    MAname: "",
    streetAddress: "",
    units: "",
    city: "",
    zip: "",
    style: "",
    state: "",
    zip5: "",
    HOAtaxid: "",
    comments: ""
  },
  masterAsscInfo: {
    street: "",
    city: "",
    state: "",
    zip5: "",
    zipPlus4: "",
    assocName: "",
    assocOfficerName: "",
    assocOfficerTitle: "",
    assocTaxId: "",
    assocPhone: "",
    assocEmail: ""
  },
  hoaAsscInfo: {
    street: "",
    city: "",
    state: "",
    zip5: "",
    zipPlus4: "",
    hoaName: "",
    hoaOfficerName: "",
    hoaOfficerTitle: "",
    hoaTaxId: "",
    phone: "",
    email: ""
  },
  
  persProjectInfo: {
    projectId: "",
    cpmId: "",
    projectName: "",
    lenderName: "",
    submissionType: "",
    phasedProjectInd: "",
    totalUnitCount: "",
    submittedUnitCount: "",
    phaseCount: "",
    commercialSpacePercent: "",
    nonGutRehabInd: "",
    masterAssocInd: "",
    subjectToLegalActionId: "",
    specialAssessmentsAmount: "",
    hoaReservesOnHandAmount: "",
    reservesHeldByHoaPercent: "",
    persStatus: "",
    riskAnalyst: ""
  },
  submissionTypes: []
};
export const addProjectReducer = (projectAdded = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT:
      return { ...projectAdded, projectId: action.payload };
    case ADD_MASTER_ASSC_ID:
      return { ...projectAdded, masterAsscId: action.payload };
    case ADD_HOA_ASSC_ID:
      return { ...projectAdded, hoaId: action.payload };
    case ADD_CONDO_INFO:
      return {
        ...projectAdded,
        condoProjectInfo: {
          ...projectAdded.condoProjectInfo,
          ...action.payload
        }
      };
    case ADD_MASTER_ASSC_INFO:
      return {
        ...projectAdded,
        masterAsscInfo: {
          ...projectAdded.masterAsscInfo,
          ...action.payload
        }
      };
    case ADD_HOA_ASSC_INFO:
      return {
        ...projectAdded,
        hoaAsscInfo: {
          ...projectAdded.hoaAsscInfo,
          ...action.payload
        }
      };
    case ADD_PERS_PROJECT_INFO:
      return {
        ...projectAdded,
        persProjectInfo: {
          ...projectAdded.persProjectInfo,
          ...action.payload
        }
      };
    case SUBMISSION_TYPES_RESULT:
      return {
        ...projectAdded,
        submissionTypes: action.payload
      };
    case INITIATE_ADD_NEW_PROJECT:
      projectAdded = initialState;
      return projectAdded;
    default:
      return projectAdded;
  }
};

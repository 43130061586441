import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import { Button, Radio } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../../AddEstablishedPers/AddEstablishedPers.css";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";

export const Form1026 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  step
}) => {
  const [direction, setDirection] = useState("back");
  const [duplicateError, setDuplicateError] = useState(false);
  const passData = JSON.parse(sessionStorage.getItem("passData"));

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [stateData, setStateData] = React.useState("");
  const fetchStateData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then(response => {
        setStateData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);
  const stateResult = Object.keys(stateData).map(key => stateData[key]);
  const stateList = stateResult.map(state => {
    return { value: state, label: state };
  });

  let [projectTypeData, setprojectTypeData] = React.useState("");
  const fetchProjectTypeData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/PROJECT_TYPE_1026`
      )
      .then(response => {
        setprojectTypeData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchProjectTypeData();
  }, [fetchProjectTypeData]);
  const projectTypeResult = Object.keys(projectTypeData).map(
    key => projectTypeData[key]
  );
  const projectTypeList = projectTypeResult.map(projectType => {
    return { value: projectType, label: projectType };
  });

  let [propertyRightsData, setPropertyRightsData] = React.useState("");
  const fetchPropertyRightsData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/PROPERTY_RIGHTS_1026`
      )
      .then(response => {
        setPropertyRightsData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchPropertyRightsData();
  }, [fetchPropertyRightsData]);
  const propertyRightsResult = Object.keys(propertyRightsData).map(
    key => propertyRightsData[key]
  );
  const propertyRightsList = propertyRightsResult.map(propertyRights => {
    return { value: propertyRights, label: propertyRights };
  });

  let [projectStoriesCountData, setProjectStoriesCountData] = React.useState(
    ""
  );
  const fetchProjectStoriesCountData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/PROJECT_STORIES_1026`
      )
      .then(response => {
        setProjectStoriesCountData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchProjectStoriesCountData();
  }, [fetchProjectStoriesCountData]);
  const projectStoriesCountResult = Object.keys(projectStoriesCountData).map(
    key => projectStoriesCountData[key]
  );
  const projectStoriesCountList = projectStoriesCountResult.map(
    projectStoriesCount => {
      return { value: projectStoriesCount, label: projectStoriesCount };
    }
  );

  let [projectStructureTypeData, setProjectStructureTypeData] = React.useState(
    ""
  );
  const fetchProjectStructureTypeData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/PROJECT_STRUCTURE_TYPE_1026`
      )
      .then(response => {
        setProjectStructureTypeData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchProjectStructureTypeData();
  }, [fetchProjectStructureTypeData]);
  const projectStructureTypeResult = Object.keys(projectStructureTypeData).map(
    key => projectStructureTypeData[key]
  );
  const projectStructureTypeList = projectStructureTypeResult.map(
    projectStructureType => {
      return { value: projectStructureType, label: projectStructureType };
    }
  );

  let [phaseTypeData, setPhaseTypeData] = React.useState("");
  const fetchPhaseTypeData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/PHASE_TYPES_1026`
      )
      .then(response => {
        setPhaseTypeData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchPhaseTypeData();
  }, [fetchPhaseTypeData]);
  const phaseTypeResult = Object.keys(phaseTypeData).map(
    key => phaseTypeData[key]
  );
  const phaseTypeList = phaseTypeResult.map(phaseType => {
    return { value: phaseType, label: phaseType };
  });

  let [constructionStatusData, setConstructionStatusData] = React.useState("");
  const fetchConstructionStatusData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/CONSTRUCTION_STATUS_1026`
      )
      .then(response => {
        setConstructionStatusData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchConstructionStatusData();
  }, [fetchConstructionStatusData]);
  const constructionStatusResult = Object.keys(constructionStatusData).map(
    key => constructionStatusData[key]
  );
  const constructionStatusList = constructionStatusResult.map(
    constructionStatus => {
      return { value: constructionStatus, label: constructionStatus };
    }
  );

  let [
    conversionGutRehabNonGutRehabTypeData,
    setConversionGutRehabNonGutRehabTypeData
  ] = React.useState("");
  const fetchConversionGutRehabNonGutRehabTypeData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/CONVERSION_REHAB_TYPE_1026`
      )
      .then(response => {
        setConversionGutRehabNonGutRehabTypeData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchConversionGutRehabNonGutRehabTypeData();
  }, [fetchConversionGutRehabNonGutRehabTypeData]);
  const conversionGutRehabNonGutRehabTypeResult = Object.keys(
    conversionGutRehabNonGutRehabTypeData
  ).map(key => conversionGutRehabNonGutRehabTypeData[key]);
  const conversionGutRehabNonGutRehabTypeList = conversionGutRehabNonGutRehabTypeResult.map(
    conversionGutRehabNonGutRehabType => {
      return {
        value: conversionGutRehabNonGutRehabType,
        label: conversionGutRehabNonGutRehabType
      };
    }
  );

  let [
    commercialUnitsSoldLeasedRetainedData,
    setCommercialUnitsSoldLeasedRetainedData
  ] = React.useState("");
  const fetchCommercialUnitsSoldLeasedRetainedData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/COMMERCIAL_UNITS_RETENT_PLAN_1026`
      )
      .then(response => {
        setCommercialUnitsSoldLeasedRetainedData(response.data);
      })
      .catch(error => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchCommercialUnitsSoldLeasedRetainedData();
  }, [fetchCommercialUnitsSoldLeasedRetainedData]);
  const commercialUnitsSoldLeasedRetainedResult = Object.keys(
    commercialUnitsSoldLeasedRetainedData
  ).map(key => commercialUnitsSoldLeasedRetainedData[key]);
  const commercialUnitsSoldLeasedRetainedList = commercialUnitsSoldLeasedRetainedResult.map(
    commercialUnitsSoldLeasedRetained => {
      return {
        value: commercialUnitsSoldLeasedRetained,
        label: commercialUnitsSoldLeasedRetained
      };
    }
  );

  const complete1051Indicator = () => {
    var complete1051Indicator = document.querySelector(
      'input[name = "masterHOAIndicator"]:checked'
    );
    if (complete1051Indicator != null) {
      console.log(complete1051Indicator.value);
      if (complete1051Indicator.value == "true") {
        return (
          <>
            <div id="radio-group-1" className="attribute-title">
              Did the lender include a completed 1051?
            </div>
            <div
              role="group"
              aria-labelledby="radio-group-1"
              className="radio-box"
            >
              <label>
                <Field
                  type="radio"
                  as={Radio}
                  name="complete1051Indicator"
                  value="true"
                  checked={radio}
                  onClick={radioCheck}
                />
                Yes
              </label>
              <label>
                <Field
                  type="radio"
                  as={Radio}
                  name="complete1051Indicator"
                  value="false"
                  checked={radioNo}
                  onClick={radioCheckNo}
                />
                No
              </label>
            </div>
          </>
        );
      } else if (complete1051Indicator.value != "true") {
        return "";
      }
    }
  };
  const builderOwnershipRetainComments = () => {
    var builderOwnershipRetainComments = document.querySelector(
      'input[name = "builderOwnershipIndicator"]:checked'
    );
    if (builderOwnershipRetainComments != null) {
      console.log(builderOwnershipRetainComments.value);
      if (builderOwnershipRetainComments.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="builderOwnershipRetainComments"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (builderOwnershipRetainComments.value != "true") {
        return "";
      }
    }
  };
  const builderRentingUnitsComments = () => {
    var builderRentingUnitsComments = document.querySelector(
      'input[name = "builderRentingUnitsIndicator"]:checked'
    );
    if (builderRentingUnitsComments != null) {
      console.log(builderRentingUnitsComments.value);
      if (builderRentingUnitsComments.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="builderRentingUnitsComments"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (builderRentingUnitsComments.value != "true") {
        return "";
      }
    }
  };
  const unitOwnerSoleOwnershipAmenitiesComments = () => {
    var unitOwnerSoleOwnershipAmenitiesComments = document.querySelector(
      'input[name = "unitOwnerSoleOwnershipAmenitiesIndicator"]:checked'
    );
    if (unitOwnerSoleOwnershipAmenitiesComments != null) {
      console.log(unitOwnerSoleOwnershipAmenitiesComments.value);
      if (unitOwnerSoleOwnershipAmenitiesComments.value == "false") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="unitOwnerSoleOwnershipAmenitiesComments"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (unitOwnerSoleOwnershipAmenitiesComments.value == "true") {
        return "";
      }
    }
  };
  const phaseType = () => {
    var phaseType = document.querySelector(
      'input[name = "moreThanOnePhaseIndicator"]:checked'
    );
    if (phaseType != null) {
      console.log(phaseType.value);
      if (phaseType.value == "true") {
        return (
          <>
            <div className="attribute-title">The Phases are</div>
            <div className="attribute-select">
              <Field
                className="attribute-select-dropdown"
                name="phaseType"
                as="select"
              >
                <option value=" ">Select Phase Type</option>
                {phaseTypeList.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </div>
          </>
        );
      } else if (phaseType.value != "true") {
        return "";
      }
    }
  };
  const phaseTypeMarketingComments = () => {
    var phaseTypeMarketingComments = document.querySelector(
      'input[name = "moreThanOnePhaseIndicator"]:checked'
    );
    if (phaseTypeMarketingComments != null) {
      console.log(phaseTypeMarketingComments.value);
      if (phaseTypeMarketingComments.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="phaseTypeMarketingComments"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (phaseTypeMarketingComments.value != "true") {
        return "";
      }
    }
  };
  const salesStrategyExplaination = () => {
    var salesStrategyExplaination = document.querySelector(
      'input[name = "salesStrategy"]:checked'
    );
    if (salesStrategyExplaination != null) {
      console.log(salesStrategyExplaination.value);
      if (salesStrategyExplaination.value == "Other") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="salesStrategyExplaination"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (salesStrategyExplaination.value != "Other") {
        return "";
      }
    }
  };
  const environProblemsExplanation = () => {
    var environProblemsExplanation = document.querySelector(
      'input[name = "environProblemsIndicator"]:checked'
    );
    if (environProblemsExplanation != null) {
      console.log(environProblemsExplanation.value);
      if (environProblemsExplanation.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="environProblemsExplanation"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (environProblemsExplanation.value != "true") {
        return "";
      }
    }
  };
  const resaleRestrictionExplaination = () => {
    var resaleRestrictionExplaination = document.querySelector(
      'input[name = "resaleRestrictionIndicator"]:checked'
    );
    if (resaleRestrictionExplaination != null) {
      console.log(resaleRestrictionExplaination.value);
      if (resaleRestrictionExplaination.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="resaleRestrictionExplaination"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (resaleRestrictionExplaination.value != "true") {
        return "";
      }
    }
  };
  const healthSafetyIssuesExplaination = () => {
    var healthSafetyIssuesExplaination = document.querySelector(
      'input[name = "healthSafetyIssuesIndicator"]:checked'
    );
    if (healthSafetyIssuesExplaination != null) {
      console.log(healthSafetyIssuesExplaination.value);
      if (healthSafetyIssuesExplaination.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="healthSafetyIssuesExplaination"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (healthSafetyIssuesExplaination.value != "true") {
        return "";
      }
    }
  };
  const nonIncidentalBusiOperationsOwnedHOAComments = () => {
    var nonIncidentalBusiOperationsOwnedHOAComments = document.querySelector(
      'input[name = "nonIncidentalBusiOperationsOwnedHOAIndicator"]:checked'
    );
    if (nonIncidentalBusiOperationsOwnedHOAComments != null) {
      console.log(nonIncidentalBusiOperationsOwnedHOAComments.value);
      if (nonIncidentalBusiOperationsOwnedHOAComments.value == "true") {
        return (
          <>
            <div className="attribute-title">Comments</div>
            <Field
              name="nonIncidentalBusiOperationsOwnedHOAComments"
              as={TextField}
              className="attribute-input"
              size="small"
              fullWidth={true}
              inputProps={{ maxLength: 2000 }}
              variant="outlined"
            />
          </>
        );
      } else if (nonIncidentalBusiOperationsOwnedHOAComments.value != "true") {
        return "";
      }
    }
  };

  const [radio, setRadio] = React.useState(false);
  const [radioNo, setRadioNo] = React.useState(false);
  function radioCheck(event) {
    if (radio === false) {
      setRadio(true);
      setRadioNo(false);
    } else if (radio === true) {
      setRadio(false);
    }
  }
  function radioCheckNo(event) {
    if (radioNo === false) {
      setRadioNo(true);
      setRadio(false);
    } else if (radioNo === true) {
      setRadioNo(false);
    }
  }

  const [radioDeed, setRadioDeed] = React.useState(false);
  const [radioNoDeed, setRadioNoDeed] = React.useState(false);
  function radioCheckDeed(event) {
    if (radioDeed === false) {
      setRadioDeed(true);
      setRadioNoDeed(false);
    } else if (radioDeed === true) {
      setRadioDeed(false);
    }
  }
  function radioCheckNoDeed(event) {
    if (radioNoDeed === false) {
      setRadioNoDeed(true);
      setRadioDeed(false);
    } else if (radioNoDeed === true) {
      setRadioNoDeed(false);
    }
  }

  const [radiomf, setRadiomf] = React.useState(false);
  const [radioNomf, setRadioNomf] = React.useState(false);
  function radioCheckmf(event) {
    if (radiomf === false) {
      setRadiomf(true);
      setRadioNomf(false);
    } else if (radiomf === true) {
      setRadiomf(false);
    }
  }
  function radioCheckNomf(event) {
    if (radioNomf === false) {
      setRadioNomf(true);
      setRadiomf(false);
    } else if (radioNomf === true) {
      setRadioNomf(false);
    }
  }

  const [radiomnd, setRadiomnd] = React.useState(false);
  const [radioNomnd, setRadioNomnd] = React.useState(false);
  function radioCheckmnd(event) {
    if (radiomnd === false) {
      setRadiomnd(true);
      setRadioNomnd(false);
    } else if (radiomnd === true) {
      setRadiomnd(false);
    }
  }
  function radioCheckNomnd(event) {
    if (radioNomnd === false) {
      setRadioNomnd(true);
      setRadiomnd(false);
    } else if (radioNomnd === true) {
      setRadioNomnd(false);
    }
  }

  const [radioincdnt, setRadioincdnt] = React.useState(false);
  const [radioNoincdnt, setRadioNoincdnt] = React.useState(false);
  function radioCheckincdnt(event) {
    if (radioincdnt === false) {
      setRadioincdnt(true);
      setRadioNoincdnt(false);
    } else if (radioincdnt === true) {
      setRadioincdnt(false);
    }
  }
  function radioCheckNoincdnt(event) {
    if (radioNoincdnt === false) {
      setRadioNoincdnt(true);
      setRadioincdnt(false);
    } else if (radioNoincdnt === true) {
      setRadioNoincdnt(false);
    }
  }

  const [radiosupprt, setRadiosupprt] = React.useState(false);
  const [radioNosupprt, setRadioNosupprt] = React.useState(false);
  function radioChecksupprt(event) {
    if (radiosupprt === false) {
      setRadiosupprt(true);
      setRadioNosupprt(false);
    } else if (radiosupprt === true) {
      setRadiosupprt(false);
    }
  }
  function radioCheckNosupprt(event) {
    if (radioNosupprt === false) {
      setRadioNosupprt(true);
      setRadiosupprt(false);
    } else if (radioNosupprt === true) {
      setRadioNosupprt(false);
    }
  }

  const [radioproj100, setRadioproj100] = React.useState(false);
  const [radioNoproj100, setRadioNoproj100] = React.useState(false);
  function radioCheckproj100(event) {
    if (radioproj100 === false) {
      setRadioproj100(true);
      setRadioNoproj100(false);
    } else if (radioproj100 === true) {
      setRadioproj100(false);
    }
  }
  function radioCheckNoproj100(event) {
    if (radioNoproj100 === false) {
      setRadioNoproj100(true);
      setRadioproj100(false);
    } else if (radioNoproj100 === true) {
      setRadioNoproj100(false);
    }
  }

  const [radiosubj, setRadiosubj] = React.useState(false);
  const [radioNosubj, setRadioNosubj] = React.useState(false);
  function radioChecksubj(event) {
    if (radiosubj === false) {
      setRadiosubj(true);
      setRadioNosubj(false);
    } else if (radiosubj === true) {
      setRadiosubj(false);
    }
  }
  function radioCheckNosubj(event) {
    if (radioNosubj === false) {
      setRadioNosubj(true);
      setRadiosubj(false);
    } else if (radioNosubj === true) {
      setRadioNosubj(false);
    }
  }

  const [radiolegally, setRadiolegally] = React.useState(false);
  const [radioNolegally, setRadioNolegally] = React.useState(false);
  function radioChecklegally(event) {
    if (radiolegally === false) {
      setRadiolegally(true);
      setRadioNolegally(false);
    } else if (radiolegally === true) {
      setRadiolegally(false);
    }
  }
  function radioCheckNolegally(event) {
    if (radioNolegally === false) {
      setRadioNolegally(true);
      setRadiolegally(false);
    } else if (radioNolegally === true) {
      setRadioNolegally(false);
    }
  }

  const [radioplanned, setRadioplanned] = React.useState(false);
  const [radioNoplanned, setRadioNoplanned] = React.useState(false);
  function radioCheckplanned(event) {
    if (radioplanned === false) {
      setRadioplanned(true);
      setRadioNoplanned(false);
    } else if (radioplanned === true) {
      setRadioplanned(false);
    }
  }
  function radioCheckNoplanned(event) {
    if (radioNoplanned === false) {
      setRadioNoplanned(true);
      setRadioplanned(false);
    } else if (radioNoplanned === true) {
      setRadioNoplanned(false);
    }
  }

  const [radiotrnsfr, setRadiotrnsfr] = React.useState(false);
  const [radioNotrnsfr, setRadioNotrnsfr] = React.useState(false);
  function radioChecktrnsfr(event) {
    if (radiotrnsfr === false) {
      setRadiotrnsfr(true);
      setRadioNotrnsfr(false);
    } else if (radiotrnsfr === true) {
      setRadiotrnsfr(false);
    }
  }
  function radioCheckNotrnsfr(event) {
    if (radioNotrnsfr === false) {
      setRadioNotrnsfr(true);
      setRadiotrnsfr(false);
    } else if (radioNotrnsfr === true) {
      setRadioNotrnsfr(false);
    }
  }

  const [radioconvrsn, setRadioconvrsn] = React.useState(false);
  const [radioNoconvrsn, setRadioNoconvrsn] = React.useState(false);
  function radioCheckconvrsn(event) {
    if (radioconvrsn === false) {
      setRadioconvrsn(true);
      setRadioNoconvrsn(false);
    } else if (radioconvrsn === true) {
      setRadioconvrsn(false);
    }
  }
  function radioCheckNoconvrsn(event) {
    if (radioNoconvrsn === false) {
      setRadioNoconvrsn(true);
      setRadioconvrsn(false);
    } else if (radioNoconvrsn === true) {
      setRadioNoconvrsn(false);
    }
  }

  const [radiofullGut, setRadiofullGut] = React.useState(false);
  const [radioNofullGut, setRadioNofullGut] = React.useState(false);
  function radioCheckfullGut(event) {
    if (radiofullGut === false) {
      setRadiofullGut(true);
      setRadioNofullGut(false);
    } else if (radiofullGut === true) {
      setRadiofullGut(false);
    }
  }
  function radioCheckNofullGut(event) {
    if (radioNofullGut === false) {
      setRadioNofullGut(true);
      setRadiofullGut(false);
    } else if (radioNofullGut === true) {
      setRadioNofullGut(false);
    }
  }

  const [radiostructrlly, setRadiostructrlly] = React.useState(false);
  const [radioNostructrlly, setRadioNostructrlly] = React.useState(false);
  function radioCheckstructrlly(event) {
    if (radiostructrlly === false) {
      setRadiostructrlly(true);
      setRadioNostructrlly(false);
    } else if (radiostructrlly === true) {
      setRadiostructrlly(false);
    }
  }
  function radioCheckNostructrlly(event) {
    if (radioNostructrlly === false) {
      setRadioNostructrlly(true);
      setRadiostructrlly(false);
    } else if (radioNostructrlly === true) {
      setRadioNostructrlly(false);
    }
  }

  const [radiorepairs, setRadiorepairs] = React.useState(false);
  const [radioNorepairs, setRadioNorepairs] = React.useState(false);
  function radioCheckrepairs(event) {
    if (radiorepairs === false) {
      setRadiorepairs(true);
      setRadioNorepairs(false);
    } else if (radiorepairs === true) {
      setRadiorepairs(false);
    }
  }
  function radioCheckNorepairs(event) {
    if (radioNorepairs === false) {
      setRadioNorepairs(true);
      setRadiorepairs(false);
    } else if (radioNorepairs === true) {
      setRadioNorepairs(false);
    }
  }

  function updateMinDate() {
    let date = new Date('1776-01-01');
    return date.toISOString().slice(0, 10);
  }

  const minDate = updateMinDate();

  function validateDecimal6(value) {
    let error;
    if (value && !/^\d{0,4}(\.\d{1,2})?$/i.test(value)) {
      error = "Invalid. 4 numbers allowed before decimal and 2 after decimal";
    }
    return error;
  }

  function validateDate(value) {
    let error;
    if (value && +new Date(value) < +new Date("1776-01-01")) {
      error = "Date cannot be before Jan 1, 1776.";
    }
    return error;
  }

  function validateCity(value) {
    let error;
    if (value && !/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value)) {
      error = "Invalid City";
    }
    return error;
  }

  function validateNumNoStr(value) {
    let error;
    if (value && !/^[0-9]\d*$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validateNumNoStr1(value) {
    let error;
    if (value && !/^[1-9]\d*$/i.test(value)) {
      error = "Invalid must be a number greater than 0";
    }
    return error;
  }

  function validateYear(value) {
    let error;
    if (value && !/^((?!(0))[0-9]{4})$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  function validatePercent(value) {
    let error;
    if (
      value &&
      !/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i.test(value)
    ) {
      error = "Invalid Percentage";
    }
    return error;
  }

  function validateNumNoStr5(value) {
    let error;
    if (value && !/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  let getDuplicateValues = data => {
    var valueArr = data.map(function(item) {
      return item.phaseDesignation;
    });
    var duplicateItem = [];
    var isDuplicate = valueArr.some(function(item, idx) {
      // return ;
      if (item != "") {
        if (valueArr.indexOf(item) != idx) {
          data[idx].isError = true;
          duplicateItem.push(idx);
          setDuplicateError(true);
          return true;
        } else {
          data[idx].isError = false;
          setDuplicateError(false);
          return false;
        }
      }
    });

    console.log("isDuplicate", isDuplicate, duplicateItem, data);

    return isDuplicate;
  };

  const styles = {
    input: {
      "&:invalid": {
        border: "red solid 2px"
      }
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          let isDuplicate = getDuplicateValues(values.phase1026DataList);
          console.log("isDuplicate", isDuplicate);

          if (!isDuplicate) {
            setFormData(values);
            direction === "back" ? prevStep() : nextStep();
          }
        }}
      >
        {({ values, errors, touched, classes }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Request</div>
                <div className="small-title">
                  For Condo Project: {values.projectId}
                </div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Section 2 of 9 - Application for Project Approval (optional)
                </div>
                <div className="attribute-title">Project Name</div>
                <Field
                  name="projectName"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{ 
                    'aria-label': "Project Name"
                  }}
                />
                <div className="attribute-title">Project Address</div>
                <Field
                  name="projectAddress"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  inputProps={{ 
                    'aria-label': "Project Address"
                  }}
                />
                <div className="attribute-title">City</div>
                <Field
                  name="projectCity"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateCity}
                  inputProps={{ maxLength: 128 }}
                  error={touched.projectCity && errors.projectCity}
                  helperText={touched.projectCity && errors.projectCity}
                  inputProps={{ 
                    'aria-label': "Project City"
                  }}
                />
                <div className="attribute-title">State</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="projectState"
                    as="select"
                    aria-label="Project State"
                  >
                    <option value=" ">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">Zip Code</div>
                <Field
                  name="projectZipCode"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr5}
                  inputProps={{ maxLength: 5 }}
                  error={touched.projectZipCode && errors.projectZipCode}
                  helperText={touched.projectZipCode && errors.projectZipCode}
                  inputProps={{ 
                    'aria-label': "Project Zip code"
                  }}
                />
                <div className="attribute-title">Legal Description</div>
                <Field
                  name="legalDescription"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ maxLength: 200, 'aria-label':"Legal Description" }}
                />
                <div className="attribute-title">Project Type</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="projectType"
                    as="select"
                    aria-label='Project Type'
                  >
                    <option value=" ">Select Project Type</option>
                    {projectTypeList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">Project Rights</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="propertyRights"
                    as="select"
                    aria-label='Project Rights'
                  >
                    <option value=" ">Select Project Rights</option>
                    {propertyRightsList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div id="radio-group-2" className="attribute-title">
                  Is there a Master Homeowners Association?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-2"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="masterHOAIndicator"
                      value="true"
                      checked={radioDeed}
                      onClick={radioCheckDeed}
                      inputProps={{ 
                        'aria-label': "Master HOA Indicator"
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="masterHOAIndicator"
                      value="false"
                      checked={radioNoDeed}
                      onClick={radioCheckNoDeed}
                      inputProps={{ 
                        'aria-label': "Master HOA indicator"
                      }}
                    />
                    No
                  </label>
                  {complete1051Indicator()}
                </div>
                <div className="attribute-title">This Project Has</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="projectStoriesCount"
                    as="select"
                    aria-label='Project Stories Count'
                  >
                    <option value=" ">Select Project Stories</option>
                    {projectStoriesCountList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">This Project Consists of</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="projectStructureType"
                    as="select"
                    aria-label='Project Structure Type'
                  >
                    <option value=" ">Select Project Structure</option>
                    {projectStructureTypeList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div id="radio-group-3" className="attribute-title">
                  Will the developer retain ownership of any units, common
                  elements or other project facilities (other than during the
                  initial marketing period for the project)?{" "}
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-3"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="builderOwnershipIndicator"
                      value="true"
                      checked={radiomf}
                      onClick={radioCheckmf}
                      inputProps={{ 
                        'aria-label': "Builder Ownership Indicator"
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="builderOwnershipIndicator"
                      value="false"
                      checked={radioNomf}
                      onClick={radioCheckNomf}
                      inputProps={{ 
                        'aria-label': "Builder Ownership Indicator"
                      }}
                    />
                    No
                  </label>
                  {builderOwnershipRetainComments()}
                </div>
                <div id="radio-group-4" className="attribute-title">
                  Are any units rented by the developer?{" "}
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-4"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="builderRentingUnitsIndicator"
                      value="true"
                      checked={radiomnd}
                      onClick={radioCheckmnd}
                      inputProps={{ 
                        'aria-label': "Builder Renting Units Indicator"
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="builderRentingUnitsIndicator"
                      value="false"
                      checked={radioNomnd}
                      onClick={radioCheckNomnd}
                      inputProps={{ 
                        'aria-label': "Builder Renting Units Indicator"
                      }}
                    />
                    No
                  </label>
                  {builderRentingUnitsComments()}
                </div>
                <div id="radio-group-5" className="attribute-title">
                  Are there any shared-use agreements for recreational amenities
                  or services?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-5"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="recreationServicesSharedUserAgreementsIndicator"
                      value="true"
                      checked={radioincdnt}
                      onClick={radioCheckincdnt}
                      inputProps={{ 
                        'aria-label': "Recreation Services Indicator"
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="recreationServicesSharedUserAgreementsIndicator"
                      value="false"
                      checked={radioNoincdnt}
                      onClick={radioCheckNoincdnt}
                      inputProps={{ 
                        'aria-label': "Recreation Services Indicator"
                      }}
                    />
                    No
                  </label>
                </div>
                <div id="radio-group-6" className="attribute-title">
                  Do the unit owners have sole ownership in and exclusive rights
                  to use amenities?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-6"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="unitOwnerSoleOwnershipAmenitiesIndicator"
                      value="true"
                      checked={radiosupprt}
                      onClick={radioChecksupprt}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="unitOwnerSoleOwnershipAmenitiesIndicator"
                      value="false"
                      checked={radioNosupprt}
                      onClick={radioCheckNosupprt}
                    />
                    No
                  </label>
                  {unitOwnerSoleOwnershipAmenitiesComments()}
                </div>
                <div id="radio-group-7" className="attribute-title">
                  Will the project consist of more than one phase?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-7"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="moreThanOnePhaseIndicator"
                      value="true"
                      checked={radioproj100}
                      onClick={radioCheckproj100}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="moreThanOnePhaseIndicator"
                      value="false"
                      checked={radioNoproj100}
                      onClick={radioCheckNoproj100}
                    />
                    No
                  </label>
                  {phaseType()}
                  {phaseTypeMarketingComments()}
                </div>
                <div id="radio-group-8" className="attribute-title">
                  Describe Sales Strategy
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-8"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="salesStrategy"
                      value="Sale of 100% of units"
                      checked={radiosubj}
                      onClick={radioChecksubj}
                    />
                    Sale of 100% of units
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="salesStrategy"
                      value="Other"
                      checked={radioNosubj}
                      onClick={radioCheckNosubj}
                    />
                    Other
                  </label>
                  {salesStrategyExplaination()}
                </div>
                <div className="attribute-title">Status of Construction</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="constructionStatus"
                    as="select"
                    aria-label="Status of construction"
                  >
                    <option value=" ">Select Construction Status</option>
                    {constructionStatusList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="attribute-title">Year Completed</div>
                <Field
                  name="yearCompleted"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateYear}
                  inputProps={{ maxLength: 4 }}
                  error={touched.yearCompleted && errors.yearCompleted}
                  helperText={touched.yearCompleted && errors.yearCompleted}
                  inputProps={{ 
                    'aria-label': "Year completed"
                  }}
                />
                <div className="attribute-title">Proposed Start Date</div>
                <Field
                  name="proposedStartDate"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  validate={validateDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ 
                    'aria-label': "Proposed start date",
                     min: minDate
                  }}
                  error={touched.proposedStartDate && errors.proposedStartDate}
                  helperText={touched.proposedStartDate && errors.proposedStartDate}
                />
                <div className="attribute-title">Date Construction Started</div>
                <Field
                  name="constructionStartedDate"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  validate={validateDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ 
                    'aria-label': "Date construction started",
                    min: minDate
                  }}
                  error={touched.constructionStartedDate && errors.constructionStartedDate}
                  helperText={touched.constructionStartedDate && errors.constructionStartedDate}
                />
                <div className="attribute-title">
                  Anticipated Completion Date
                </div>
                <Field
                  name="anticipatedCompletionDate"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  variant="outlined"
                  type="date"
                  validate={validateDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ 
                    'aria-label': "Anticipated completion date",
                    min: minDate
                  }}
                  error={touched.anticipatedCompletionDate && errors.anticipatedCompletionDate}
                  helperText={touched.anticipatedCompletionDate && errors.anticipatedCompletionDate}
                />
                <div className="attribute-title">
                  Conversion, Gut or Non-Gut Rehab?(If non-gut, indicate
                  additional exhibits in Form 1030)
                </div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="conversionGutRehabNonGutRehabType"
                    as="select"
                    aria-label='Conversion Gut Rehab'
                  >
                    <option value=" ">Select Conversion Type</option>
                    {conversionGutRehabNonGutRehabTypeList.map(
                      (option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      )
                    )}
                  </Field>
                </div>
                <div id="radio-group-9" className="attribute-title">
                  Are there any mechanic’s liens, complaints, or litigation
                  filed against the property?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-9"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="litigationFiledIndicator"
                      value="true"
                      checked={radiolegally}
                      onClick={radioChecklegally}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="litigationFiledIndicator"
                      value="false"
                      checked={radioNolegally}
                      onClick={radioCheckNolegally}
                    />
                    No
                  </label>
                </div>
                <div id="radio-group-10" className="attribute-title">
                  Has the lender identified or become aware of any environmental
                  problem(s)?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-10"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="environProblemsIndicator"
                      value="true"
                      checked={radioplanned}
                      onClick={radioCheckplanned}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="environProblemsIndicator"
                      value="false"
                      checked={radioNoplanned}
                      onClick={radioCheckNoplanned}
                    />
                    No
                  </label>
                  {environProblemsExplanation()}
                </div>
                <div id="radio-group-11" className="attribute-title">
                  Is the project subject to resale restrictions?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-11"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="resaleRestrictionIndicator"
                      value="true"
                      checked={radiotrnsfr}
                      onClick={radioChecktrnsfr}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="resaleRestrictionIndicator"
                      value="false"
                      checked={radioNotrnsfr}
                      onClick={radioCheckNotrnsfr}
                    />
                    No
                  </label>
                  {resaleRestrictionExplaination()}
                </div>
                <div id="radio-group-12" className="attribute-title">
                  Does the project have any health or safety issues?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-12"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="healthSafetyIssuesIndicator"
                      value="true"
                      checked={radioconvrsn}
                      onClick={radioCheckconvrsn}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="healthSafetyIssuesIndicator"
                      value="false"
                      checked={radioNoconvrsn}
                      onClick={radioCheckNoconvrsn}
                    />
                    No
                  </label>
                  {healthSafetyIssuesExplaination()}
                </div>
                <div id="radio-group-13" className="attribute-title">
                  Does the project include nonresidential/commercial units?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-13"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="commercialUnitsInclusionIndicator"
                      value="true"
                      checked={radiofullGut}
                      onClick={radioCheckfullGut}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="commercialUnitsInclusionIndicator"
                      value="false"
                      checked={radioNofullGut}
                      onClick={radioCheckNofullGut}
                    />
                    No
                  </label>
                </div>
                <div className="attribute-title">The units are to be</div>
                <div className="attribute-select">
                  <Field
                    className="attribute-select-dropdown"
                    name="commercialUnitsSoldLeasedRetained"
                    as="select"
                    aria-label='Commercial Units Sold Leased Retained'
                  >
                    <option value=" ">Select Unit Status</option>
                    {commercialUnitsSoldLeasedRetainedList.map(
                      (option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      )
                    )}
                  </Field>
                </div>
                <div className="attribute-title">
                  Number of non-residential/commercial units
                </div>
                <Field
                  name="commercialUnitsCount"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validateNumNoStr1}
                  inputProps={{ maxLength: 9 }}
                  error={
                    touched.commercialUnitsCount && errors.commercialUnitsCount
                  }
                  helperText={
                    touched.commercialUnitsCount && errors.commercialUnitsCount
                  }
                  inputProps={{ 
                    'aria-label': "Commercial units count"
                  }}
                />
                <div className="attribute-title">
                  Percent of nonresidential/commercial space
                </div>
                <Field
                  name="commercialSpacePercent"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validatePercent}
                  inputProps={{ maxLength: 10 }}
                  error={
                    touched.commercialSpacePercent &&
                    errors.commercialSpacePercent
                  }
                  helperText={
                    touched.commercialSpacePercent &&
                    errors.commercialSpacePercent
                  }
                  inputProps={{ 
                    'aria-label': "Commercial space percent"
                  }}
                />
                <div className="attribute-title">
                  Percent of residential space
                </div>
                <Field
                  name="residentialSpacePercent"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  validate={validatePercent}
                  inputProps={{ maxLength: 10 }}
                  error={
                    touched.residentialSpacePercent &&
                    errors.residentialSpacePercent
                  }
                  helperText={
                    touched.residentialSpacePercent &&
                    errors.residentialSpacePercent
                  }
                  inputProps={{ 
                    'aria-label': "Residential Space percent"
                  }}
                />
                <div className="attribute-title">
                  Describe intended purpose and use of
                  non-residential/commercial space
                </div>
                <Field
                  name="commercialSpacePurpose"
                  as={TextField}
                  className="attribute-input"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  inputProps={{ 
                    'aria-label': "Commercial space purpose"
                  }}
                />
                <div id="radio-group-14" className="attribute-title">
                  Is the commercial space complete?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-14"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="commercialSpaceCompleteIndicator"
                      value="true"
                      checked={radiostructrlly}
                      onClick={radioCheckstructrlly}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="commercialSpaceCompleteIndicator"
                      value="false"
                      checked={radioNostructrlly}
                      onClick={radioCheckNostructrlly}
                    />
                    No
                  </label>
                </div>
                <div id="radio-group-15" className="attribute-title">
                  Are there any non-incidental business operations owned or
                  operated by the HOA?
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-group-15"
                  className="radio-box"
                >
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="nonIncidentalBusiOperationsOwnedHOAIndicator"
                      value="true"
                      checked={radiorepairs}
                      onClick={radioCheckrepairs}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      as={Radio}
                      name="nonIncidentalBusiOperationsOwnedHOAIndicator"
                      value="false"
                      checked={radioNorepairs}
                      onClick={radioCheckNorepairs}
                    />
                    No
                  </label>
                </div>
                {nonIncidentalBusiOperationsOwnedHOAComments()}

                {/* <div className="section-title">
                  Complete the following information for each
                  legal/construction/marketing phase in the entire project
                </div>
                <div className="table-header">
                  <div className="table-header-title">
                    Legal/Construction/Marketing Phase Designation
                  </div>
                  <div className="table-header-title">
                    Building Designations
                  </div>
                  <div className="table-header-title">
                    Number of Units in Phase
                  </div>
                  <div className="table-header-title">
                    Included in this Application
                  </div>
                </div>
                <FieldArray name="phase1026DataList">
                  {({ push, remove }) => (
                    <div>
                      {values.phase1026DataList.map((p, index) => {
                        return (
                          <div className="table-attributes">
                            <div className="table-attribute-5">
                              <Field
                                name={`phase1026DataList[${index}].phaseDesignation`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                inputProps={{ maxLength: 200 }}
                                onKeyUp={() => {
                                  getDuplicateValues(values.phase1026DataList);
                                }}
                                onBlur={() => {
                                  getDuplicateValues(values.phase1026DataList);
                                }}
                              />

                              {duplicateError &&
                              values.phase1026DataList[index].isError ? (
                                <div style={{ color: "#ff0000" }}>
                                  Phase Designation{" "}
                                  {
                                    values.phase1026DataList[index]
                                      .phaseDesignation
                                  }{" "}
                                  already exists
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="table-attribute-6">
                              <Field
                                name={`phase1026DataList[${index}].buildingDesignation`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                inputProps={{ maxLength: 200 }}
                              />
                            </div>
                            <div className="table-attribute-7">
                              <Field
                                name={`phase1026DataList[${index}].phaseUnitsCount`}
                                as={TextField}
                                size="small"
                                fullWidth={true}
                                inputProps={{
                                  maxlength: 8,
                                  pattern: "^[0-9]*$"
                                }}
                                helperText="Number Only"
                                variant="outlined"
                              />
                            </div>
                            <div
                              role="group"
                              aria-labelledby="radio-group"
                              className="table-radio-1"
                            >
                              <label>
                                <Field
                                  name={`phase1026DataList[${index}].includedinApplicationIndicator`}
                                  as={Radio}
                                  type="radio"
                                  variant="outlined"
                                  value="true"
                                />
                                Yes
                              </label>
                              <label>
                                <Field
                                  name={`phase1026DataList[${index}].includedinApplicationIndicator`}
                                  as={Radio}
                                  type="radio"
                                  variant="outlined"
                                  value="false"
                                />
                                No
                              </label>
                            </div>
                            <div
                              className="trash-can"
                              onClick={() => remove(index)}
                            >
                              <DeleteForeverOutlinedIcon />
                            </div>
                          </div>
                        );
                      })}
                      <div className="add-table-button">
                        <Button
                          type="button"
                          onClick={() => {
                            let isDuplicate = getDuplicateValues(
                              values.phase1026DataList
                            );

                            if (!isDuplicate) {
                              push({
                                phaseDesignation: "",
                                buildingDesignation: "",
                                phaseUnitsCount: "",
                                includedinApplicationIndicator: "",
                                createdBy: passData.user
                              });
                            }
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray> */}

                <div className="bottom-button-container">
                  <Button
                    type="submit"
                    className="next-button"
                    onClick={() => setDirection("forward")}
                  >
                    Next Section
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    type="submit"
                    className="previous-button"
                    onClick={() => setDirection("back")}
                  >
                    <ArrowBackIcon />
                    Previous Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon style={{fill:"red"}} />
                Cancel PERS Request
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Request
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Request
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Decision";
import { Modal} from "react-bootstrap";
import { isInternal } from "../../redux/actions/userAction";

const IndividualDecision = ({ item, persId, reasonCodes }) => {
  function OpenModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [totalReactPackages, setTotalReactPackages] = useState(null);
    const [packageDate, setPackageDate] = useState("");
    const [reviewedBy, setReviewedBy] = useState("");
    const [summaryArr, setSummaryArr] = useState([{}]);

    useEffect(() => {
      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/decision/getPersDecisionDetailsByPersIdAndDate/` +
            persId +
            `/` +
            item.date
        )
        .then(response => setTotalReactPackages(response.data));
    }, []);

    if (totalReactPackages != null) {
      if (packageDate === "") {
        setPackageDate(totalReactPackages.decisionDate);
        setReviewedBy(totalReactPackages.reviewedBy);
        setSummaryArr(totalReactPackages.projectPhaseDecisionSummary);
      }
    } else {
      return null;
    }

    const getVerbiage = (id) => {
      if (reasonCodes && reasonCodes.length > 0) {
        if (isInternal()) {
          return reasonCodes.find(x => x.reasonId == id) ? reasonCodes.find(x => x.reasonId == id).reasonDefinitionInternal : 'NA'
        } else {
          return reasonCodes.find(x => x.reasonId == id) ? reasonCodes.find(x => x.reasonId == id).reasonDefinitionExternal: 'NA';
        }
      } else {
        return 'No Data'
      }
    }


    const summaryMap = summaryArr.map((anObjectMapped, index) => {
      const mapConditions = (() => {
        if (typeof anObjectMapped.conditions !== "undefined") {
          const mapInside = anObjectMapped.conditions.map(list => (
            <>
            {item.isAddedCondn === false ? 
            <p>
              {list.conditionDescription}
            </p> :
            <p>
              {list.conditionName}:&nbsp;{list.conditionDescription}
            </p>
            }
            </>
          ));
          return mapInside;
        } else {
        }
      })();

      const mapRestrictions = (() => {
        if (typeof anObjectMapped.restrictions !== "undefined") {
          const mapInside = anObjectMapped.restrictions.map(list => (
            <>
            {item.isAddedRestrictions === false ? 
            <p>
              {list.restrictionsDescription}
            </p> :
            <p>
              {
                (list.restrictionsName.toString().includes('Restriction 1'))
              }
              {
                (list.restrictionsName.toString().startsWith('Restriction 1'))
              }
              
                {list.restrictionsName.toString().startsWith('Restriction 1') &&
                  list.restrictionsDescription
                } 
                {list.restrictionsName.toString().startsWith('Restriction 1')==false &&
                 list.restrictionsName + ': ' +  list.restrictionsDescription
                }
              
            </p>
            }
            </>
          ));
          return mapInside;
        } else {
        }
      })();


      const mapReasons = (() => {
        if (typeof anObjectMapped.reasonIds !== "undefined") {
          const mapInside = anObjectMapped.reasonIds.map(id => (
            <>
              <li>
                {getVerbiage(id)}
              </li>
            </>
          ));
          return mapInside;
        } else {
        }
      })();

      return (
        <div
          className="decision-modal-parent"
          key={`${anObjectMapped.persDecision}_{anObjectMapped.expiration}_{anObjectMapped.phaseNameOrProject}_{anObjectMapped.expiration}`}
        >
          <div className="name">{anObjectMapped.phaseNameOrProject}</div>
          <div className="decision">
            <b>PERS Decision:</b>&nbsp;{anObjectMapped.persDecision}
          </div>
          <div className="date">
          {typeof anObjectMapped.ineligibleDetails !== "undefined" ? (
          <>
          {anObjectMapped.ineligibleDetails.ineligibilityExplaination !== null ? (
          <>
            
          </>) : (<><b>Expiration:</b>&nbsp;{anObjectMapped.expiration}</>)}
          </>
          ) : (<></>)}
          </div>
          {typeof anObjectMapped.ineligibleDetails !== "undefined" ? (
          <>
              {anObjectMapped.ineligibleDetails.ineligibilityExplaination !== null ? (
                <>
                  <div className="restrictions">
                    <b>Selling and Servicing Guide Reference:</b>
                    <br></br>
                    {anObjectMapped.ineligibleDetails.sellingGuideReference}
                  </div>
                  <div className="list">
                    <b>List of Reviewed Project Exhibits:</b>
                    <br></br>
                    {anObjectMapped.ineligibleDetails.reviewedProjectExhibits}
                  </div>
                  <div className="conditions">
                    <b>Reason for Ineligibility:</b>
                    <br></br>
                    {anObjectMapped.ineligibleDetails.ineligibilityExplaination}
                  </div>
                </>) : (<>
                  <div className="restrictions">
                    <b>Delivery Restrictions</b>
                    {anObjectMapped.restrictions.length > 0 ? (<div>{mapRestrictions}</div>) : (<div>None</div>)}
                  </div>
                  <div className="conditions">
                    <b>Conditions</b>
                    {anObjectMapped.conditions.length > 0 ? (<div>{mapConditions}</div>) : (<div>None</div>)}
                  </div>
                </>)}
                {anObjectMapped.reasonIds.length > 0 ?
                    (<>
                      <div className="pers-reason">
                        <b>Reason Codes</b>
                        <ul>{mapReasons}</ul>
                      </div>
                    </>) :
                    <></>
                  }
          </>
          ) : (<></>)}
        </div>
      );
    });

    return (
      <>
        <a onClick={handleShow}>
          <div className="indy-pdf-container">
            <div className="pdf-link-date-container">
              {item.decDateFormatted}
            </div>
            <div className="decision-phase-header">
              {item.phaseNamesOrProject}
            </div>
            <div className="decision-reviewed-header">{item.reviewedBy}</div>
          </div>
        </a>

        <Modal
          className="decision-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          animation={false}
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: "1025"
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="decision-title">
                <b>Decision Date:</b>&nbsp;{packageDate}
              </div>
              <div className="decision-title">
                <b>Reviewed By: </b>&nbsp;{reviewedBy}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{summaryMap}</Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <div>
      <OpenModal />
    </div>
  );
};

export default IndividualDecision;

import React, { useState, useEffect } from "react";
import { Search } from "./Search";
import axios from "axios";
import { Input } from "@material-ui/core";

export const Landing = (props) => {
  let storedProjectId;
  let storedProjectName;
  let storedStreet;
  let storedCity;
  let storedState;
  let storedZip;
  let storedCertStatus;
  let storedLenderId;
  let storedFnmaReview;
  storedProjectId = sessionStorage.getItem("searchedProjectId")
    ? sessionStorage.getItem("searchedProjectId")
    : "";
  storedProjectName = sessionStorage.getItem("searchedProjectName")
    ? sessionStorage.getItem("searchedProjectName")
    : "";
  storedStreet = sessionStorage.getItem("searchedStreet")
    ? sessionStorage.getItem("searchedStreet")
    : "";
  storedCity = sessionStorage.getItem("searchedCity")
    ? (storedCity = sessionStorage.getItem("searchedCity"))
    : "";
  storedState = sessionStorage.getItem("searchedState")
    ? sessionStorage.getItem("searchedState")
    : "";
  storedZip = sessionStorage.getItem("searchedZip")
    ? sessionStorage.getItem("searchedZip")
    : "";
  storedCertStatus = sessionStorage.getItem("searchedLenderCert")
    ? sessionStorage.getItem("searchedLenderCert")
    : "";
  storedLenderId = sessionStorage.getItem("searchedLenderId")
    ? sessionStorage.getItem("searchedLenderId")
    : "";
  storedFnmaReview = sessionStorage.getItem("searchedFnmaReview")
    ? sessionStorage.getItem("searchedFnmaReview")
    : "";

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    searchView: "",
    condoProjectId: storedProjectId,
    condoProjectName: storedProjectName,
    condoStreet: storedStreet,
    condoCity: storedCity,
    condoState: storedState,
    condoZip: storedZip,
    lenderCertStatus: storedCertStatus,
    lenderId: storedLenderId,
    fnmaReviewStatus: storedFnmaReview,
  });

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  switch (step) {
    case 0:
      return (
        <Search
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          step={step}
        />
      );
    default:
      return (
        <Search
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          step={step}
        />
      );
  }
};

import React, { useState } from "react";
import "./childphaselist.css";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import SuccessIcon from "../../../images/icons/Success@1x.svg";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import TimerIcon from "../../../images/icons/timer@1x.svg";
import FeedbackIcon from "../../../images/icons/feedback-remove@1x.svg";
import Success1Icon from "../../../images/icons/Success-1@1x.svg";
import Warning1Icon from "../../../images/icons/Warning-1@1x.svg";
import CriticalIcon from "../../../images/Critical@2x.svg";

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
    fontSize: 14,
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);
const IndividualComment = ({ item }) => {
  return (
    <a href={`/project/phase/child-phase/${item.phaseId}`}>
      <div className="card-row-pers">
        <div style={{ display: "flex" }} className="card-project-name-pers">
          <div style={{}}>
            {item.fnmaReviewStatus === "Approved By Fannie Mae" ? (
              <>
                <CustomTooltip title="Approved By Fannie Mae" placement="top">
                  <img
                    alt="Success Check Logo"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={SuccessIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fnmaReviewStatus === "Conditional Approval" ? (
              <>
                <CustomTooltip title="Conditional Approval" placement="top">
                  <img
                    alt="Warning Logo"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={WarningIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fnmaReviewStatus === "Pending PERS Review" ? (
              <>
                <CustomTooltip title="Pending PERS Review" placement="top">
                  <img
                    alt="Timer Logo"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={TimerIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fnmaReviewStatus === "Unavailable" ? (
              <>
                <CustomTooltip title="Unavailable" placement="top">
                  <img
                    alt="Remove Logo"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={FeedbackIcon}
                  />
                </CustomTooltip>
              </>
            ) : item.fnmaReviewStatus === "Fannie Mae Approval Expired" ? (
              <>
                <CustomTooltip
                  title="Fannie Mae Approval Expired"
                  placement="top"
                >
                  <img
                    alt="Alternate Success Logo"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={Success1Icon}
                  />
                </CustomTooltip>
              </>
            ) : item.fmReviewStatus ===
              "Fannie Mae Conditional Approval Expired" ? (
              <>
                <CustomTooltip
                  title="Fannie Mae Conditional Approval Expired"
                  placement="top"
                >
                  <img
                    alt="Alternate Warning Logo"
                    style={{ marginTop: "10px", marginRight: "27px" }}
                    src={Warning1Icon}
                  />
                </CustomTooltip>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div>
              <span className="phase-type-container-1">{item.phaseType}</span>
              {item.delRestr ? (
                <>
                  {" "}
                  <CustomTooltip
                    title="Delivery Restrictions"
                    placement="right"
                  >
                    <span>
                      <img
                        alt="Critical Warning Logo"
                        className="restrictions-icon"
                        src={CriticalIcon}
                      />
                    </span>
                  </CustomTooltip>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="phase-type-name-1">{item.phaseName}</div>
          </div>
        </div>
        <div className="card-project-name-pers">{item.phaseId}</div>
        <div className="card-project-name-pers">
          {item.street}
          <br></br>
          {item.city}, {item.state} {item.zip5}
        </div>
        <div className="card-project-name-pers">{item.unitNumber}</div>
        <div className="card-project-name-pers">{item.unitCount}</div>
        <div className="card-project-name-pers">{item.buildingNumber}</div>
      </div>
    </a>
  );
};

export default IndividualComment;

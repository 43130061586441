import React from "react";
import "./phaserestrictions.css";
import LayersBlack from "../../images/layers_black_24dp@1x.svg";

const PhaseRestrictions = ({ item }) => {
  return (
    <div>
      {typeof item !== "undefined" ? (
        <div className="phase-restrictions-text">
          <div className="phase-restrictions-image">
            <img
              alt="Phase Icon"
              className="restrictions-image"
              src={LayersBlack}
            />
          </div>
          {item.isAddedRestrictions === false ? (
            <div className="text">{item.restrictionsDescription}</div>
          ) : (
            <div className="text">
              {item.restrictionsName}
              :&nbsp;
              {item.restrictionsDescription}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PhaseRestrictions;

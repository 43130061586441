import {
  ADD_PROJECT,
  ADD_CONDO_INFO,
  ADD_MASTER_ASSC_INFO,
  ADD_HOA_ASSC_INFO,
  ADD_MASTER_ASSC_ID,
  ADD_HOA_ASSC_ID,
  ADD_PERS_PROJECT_INFO,
  SUBMISSION_TYPES_RESULT,
  INITIATE_ADD_NEW_PROJECT
} from "./ActionType";
import {
  isProjectRole,
  isLenderRole,
  isReadonlyRole
} from "../actions/userAction";
import axios from "axios";

export const addProjectService = result => ({
  type: ADD_PROJECT,
  payload: result
});

export const addMasterAsscIdService = result => ({
  type: ADD_MASTER_ASSC_ID,
  payload: result
});

export const addHoaIdService = result => ({
  type: ADD_HOA_ASSC_ID,
  payload: result
});

export const addCondoProjectInfoService = result => ({
  type: ADD_CONDO_INFO,
  payload: result
});

export const addMasterAsscInfoService = result => ({
  type: ADD_MASTER_ASSC_INFO,
  payload: result
});

export const addHoaAsscInfoService = result => ({
  type: ADD_HOA_ASSC_INFO,
  payload: result
});

export const addPercProjectInfoService = result => ({
  type: ADD_PERS_PROJECT_INFO,
  payload: result
});

export const submissionTypesService = result => ({
  type: SUBMISSION_TYPES_RESULT,
  payload: result
});

export const initiateAddNewProject = () => ({
  type: INITIATE_ADD_NEW_PROJECT
});

export function addProject(projectInfo, userRole, incrementCounter) {
  return function(dispatch, getState) {
    dispatch(addCondoProjectInfoService(projectInfo));
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    if (isProjectRole()) {
      const formData = {
        projectName: projectInfo.phaseName,
        cpmId: projectInfo.cpmId,
        altProjectName: projectInfo.alternativeName,
        projectStyle: projectInfo.style,
        newProjectType: projectInfo.type,
        styleComments: projectInfo.styleComments,
        unitCount: projectInfo.units,
        street: projectInfo.streetAddress,
        city: projectInfo.city,
        state: projectInfo.state,
        coopInd: projectInfo.coopInd,
        manfdHousngInd: projectInfo.manfdHousngInd,
        unitsType: projectInfo.unitsType,
        zip5: projectInfo.zip5,
        zipPlus4: projectInfo.zip,
        hoaName: projectInfo.HOAname,
        hoaTaxId: projectInfo.HOAtaxid,
        masterAssociationName: projectInfo.MAname,
        userName: passData.firstName + " " + passData.lastName,
        comments: projectInfo.comments,
        createdBy: passData.user
      };

      return axios
        .post(
          global.REACT_APP_API_ENDPOINT +
            `/condoMgr/saveProjectProfile`,
          formData 
        )
        .then(response => {
          const data = response.data;
          console.log("api response.. ", response);
          if (response.status == 200 && response.data && !response.data.totalResults) {
            incrementCounter();
            dispatch(addProjectService(response.data));
            return response.data.projectId;
          } else {
            return data;
          }
        })
        .catch(error => {
          throw Error(error);
        });
    } else {
      const formData = {
        altProjectName: projectInfo.alternativeName,
        cpmId: projectInfo.cpmId,
        city: projectInfo.city,
        hoaName: projectInfo.HOAname,
        hoaTaxId: projectInfo.HOAtaxid,
        masterAssociationName: projectInfo.MAname,
        newProjectType: projectInfo.type,
        projectName: projectInfo.phaseName,
        projectStyle: projectInfo.style,
        state: projectInfo.state,
        coopInd: projectInfo.coopInd,
        manfdHousngInd: projectInfo.manfdHousngInd,
        unitsType: projectInfo.unitsType,
        styleComments: projectInfo.styleComments,
        unitCount: projectInfo.units,
        street: projectInfo.streetAddress,
        zip5: projectInfo.zip5,
        lenderSSNId: passData.user
      };
  

      return axios
        .post(
          global.REACT_APP_API_ENDPOINT +
            `/lender/lenderAddProject`,
          formData
        )
        .then(response => {
          const data = response.data;
          console.log("lenderAddProjectUsingPOST response.. ", response);
          if (response.status == 200) {
            incrementCounter();
            dispatch(addProjectService(data));
            return response.data.projectId;
          } else {
            return data;
          }
        })
        .catch(error => {
          throw Error(error);
        });
    }
  };
}

export function addMasterAsscInfo(masterAsscInfo) {
  return function(dispatch, getState) {
    const { addProjectReducer } = getState();
    const projectId = addProjectReducer.projectId;
    dispatch(addMasterAsscInfoService(masterAsscInfo));
    return axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/saveMstrAssoc/${projectId}`,
        masterAsscInfo
      )
      .then(response => {
        const data = response.data;
        console.log("api response.. ", data);
        dispatch(addMasterAsscIdService(data));
        return data;
      })
      .catch(error => {
        throw Error(error);
      });
  };
}

export function addHoaAsscInfo(hoaAsscInfo) {
  return function(dispatch, getState) {
    const { addProjectReducer } = getState();
    const projectId = addProjectReducer.projectId;
    dispatch(addHoaAsscInfoService(hoaAsscInfo));
    return axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/saveHoa/${projectId}`,
        hoaAsscInfo
      )
      .then(response => {
        const data = response.data;
        console.log("api response.. ", data);
        dispatch(addHoaIdService(data));
        return data;
      })
      .catch(error => {
        throw Error(error);
      });
  };
}

export function addPersProjectInfo(persProjectInfo) {
  return function(dispatch, getState) {
    const { addProjectReducer } = getState();
    const projectId = addProjectReducer.projectId;
    persProjectInfo.projectId = projectId;
    dispatch(addPercProjectInfoService(persProjectInfo));
    const formData = {
      ...persProjectInfo,
      phasedProjectInd: persProjectInfo.phasedProjectInd === "true",
      nonGutRehabInd: persProjectInfo.nonGutRehabInd === "true",
      masterAssocInd: persProjectInfo.masterAssocInd === "true",
      subjectToLegalActionId: persProjectInfo.subjectToLegalActionId === "true"
    };
    return axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/savePersProject`,
        formData
      )
      .then(response => {
        const data = response.data;
        console.log("api response.. ", data);
        return data;
      })
      .catch(error => {
        throw Error(error);
      });
  };
}

export function fetchSubmissionTypes() {
  return function(dispatch) {

    return axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/pers_submission_type`
      )
      .then(response => {
        const typesResponse = response.data;
        const types = [];
        for (const type in typesResponse) {
          types.push({
            key: typesResponse[type],
            value: typesResponse[type]
          });
        }
        types.unshift({ key: "", value: "Select Type" });
        dispatch(submissionTypesService(types));
      })

      .catch(error => {
        throw Error(error);
      });
  };
}

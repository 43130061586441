import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../AddNewPhase.css";

const AddNewPhasePage = ({ incStepCounter, decStepCounter, addPhase }) => {
  window.onscroll = function () {
    effects();
  };
  function effects() {}

  const { project_id } = useParams();
  const getPersDetails = async () => {
    try {
      const { data } = await axios.get(
        `${global.REACT_APP_API_ENDPOINT}/phase/getProjectAddress/${project_id}`
      );
      setPhaseInfo(data);
      setPhaseInfo({
        street: data.street,
        phaseType: "Legal",
        city: data.city,
        state: data.state,
        zip5: data.zip5,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [phaseInfo, setPhaseInfo] = useState({
    phaseName: "",
    phaseType: "",
    buildingNumber: "",
    unitNumber: "",
    unitsPerPhase: "",
    street: "",
    city: "",
    state: "",
    zip5: "",
    comment: "",
  });

  React.useEffect(() => {
    getPersDetails();
  }, []);

  // Error states
  const [errPhaseName, setErrPhaseName] = useState("");
  const [errPhaseType, setErrPhaseType] = useState("");
  const [errBuildingNumber, setErrBuildingNumber] = useState("");
  const [errUnitNumber, setErrUnitNumber] = useState("");
  const [errUnitsPerPhase, setErrUnitsPerPhase] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [commentLength, setCommentLength] = useState(0);

  const phaseNameRef = React.createRef();
  const phaseTypeRef = React.createRef();
  const unitNumberRef = React.createRef();
  const buildingNumberRef = React.createRef();
  const unitsPerPhaseRef = React.createRef();
  const phaseAddressRef = React.createRef();
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();

  const states = useSelector((store) => store.dialogueReducer.states);
  const phaseTypes = useSelector(
    (store) => store.addNewPhaseReducer.phaseTypes
  );

  function handleChange(event) {
    setPhaseInfo({ ...phaseInfo, [event.target.name]: event.target.value });
  }
  async function onSubmitCalled(e) {
    setDisable(true);
    console.log(project_id);
    e.preventDefault();

    if (
      phaseNameRef.current.value != "" &&
      phaseNameRef.current.value.length > 300
    ) {
      setErrPhaseName("Phase Name cannot be larger than 300 characters");
    } else {
      setErrPhaseName(getRequiredErrorMsg(phaseNameRef));
    }

    if (
      buildingNumberRef.current.value != "" &&
      buildingNumberRef.current.value.length > 100
    ) {
      setErrBuildingNumber(
        "Building Number cannot be larger than 100 characters"
      );
    }

    if (
      unitNumberRef.current.value != "" &&
      unitNumberRef.current.value.length > 100
    ) {
      setErrUnitNumber("Unit Number cannot be larger than 100 characters");
    }

    setErrPhaseType(getRequiredErrorMsg(phaseTypeRef));
    setErrAddress(getRequiredErrorMsg(phaseAddressRef));
    if (
      unitsPerPhaseRef.current.value != "" &&
      unitsPerPhaseRef.current.value <= 0
    ) {
      setErrUnitsPerPhase("Number of phases cannot be 0 or negative");
    } else {
      setErrUnitsPerPhase(getRequiredErrorMsg(unitsPerPhaseRef));
    }

    if (
      !(
        isValueEmpty(phaseNameRef) ||
        isValueEmpty(phaseTypeRef) ||
        isValueEmpty(unitsPerPhaseRef) ||
        isValueEmpty(phaseAddressRef) ||
        phaseNameRef.current.value.length > 300 ||
        buildingNumberRef.current.value.length > 100 ||
        unitNumberRef.current.value.length > 100 ||
        unitsPerPhaseRef.current.value <= 0
      )
    ) {
      try {
        const newPhase = await submitNewPhase();
        addPhase(newPhase.data);
        incStepCounter();
      } catch (err) {}
    }
  }

  async function submitNewPhase() {
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    const formData = {
      projectId: project_id,
      userName: passData.user,
      phaseName: phaseInfo.phaseName,
      cpmId: phaseInfo.cpmId,
      phaseType: phaseInfo.phaseType,
      buildingNumber: phaseInfo.buildingNumber,
      unitNumber: phaseInfo.unitNumber,
      unitCount: phaseInfo.unitsPerPhase,
      street: phaseInfo.street,
      city: phaseInfo.city,
      state: phaseInfo.state,
      zip5: phaseInfo.zip5,
      comments: phaseInfo.comment,
    };

    try {
      return await axios.post(
        global.REACT_APP_API_ENDPOINT + `/phase/addNewPhase`,
        formData
      );
    } catch (err) {
      throw Error(err);
    }
  }

  const backButtonClicked = (e) => {
    decStepCounter();
  };

  const isValueEmpty = (ref) => ref.current.value.length === 0;

  const getRequiredErrorMsg = (ref) =>
    isValueEmpty(ref) ? "This field is required" : "";

  return (
    <Grid item xs={12}>
      <form id="addPhaseForm">
        <Grid item xs={12}>
          <h1 style={{ justifyContent: "center" }}>Add New Phase</h1>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Phase Name<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                required
                type="text"
                className="form-control"
                name="phaseName"
                value={phaseInfo.phaseName}
                ref={phaseNameRef}
                onChange={(event) => {
                  handleChange(event);
                  setErrPhaseName(getRequiredErrorMsg(phaseNameRef));
                  if (event.target.value.length > 300) {
                    setErrPhaseName(
                      "Phase Name cannot be larger than 300 characters"
                    );
                  } else {
                    setErrPhaseName("");
                  }
                }}
              />
              {errPhaseName != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errPhaseName}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Legacy CPM ID</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                type="text"
                className="form-control"
                name="cpmId"
                value={phaseInfo.cpmId}
                maxLength="16"
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Phase Type<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <select
                required
                name="phaseType"
                className="form-control"
                ref={phaseTypeRef}
                value={phaseInfo.phaseType}
                onChange={(event) => {
                  handleChange(event);
                  setErrPhaseType(getRequiredErrorMsg(phaseTypeRef));
                }}
              >
                {phaseTypes &&
                  phaseTypes.length > 0 &&
                  phaseTypes.map((item) => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
              </select>
              {errPhaseType != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errPhaseType}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Building Number</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                type="text"
                className="form-control"
                name="buildingNumber"
                ref={buildingNumberRef}
                value={phaseInfo.buildingNumber}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {errBuildingNumber != "" ? (
                <p style={{ color: "red", margin: "0px" }}>
                  {errBuildingNumber}
                </p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Unit Number</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                type="text"
                className="form-control"
                name="unitNumber"
                ref={unitNumberRef}
                value={phaseInfo.unitNumber}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Number of Units in Phase<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                required
                type="number"
                min="1"
                className="form-control"
                name="unitsPerPhase"
                value={phaseInfo.unitsPerPhase}
                ref={unitsPerPhaseRef}
                onChange={(event) => {
                  handleChange(event);
                  setErrUnitsPerPhase(getRequiredErrorMsg(unitsPerPhaseRef));
                  if (event.target.value <= 0) {
                    setErrUnitsPerPhase(
                      "Number of phases cannot be 0 or negative"
                    );
                  } else {
                    setErrUnitsPerPhase("");
                  }
                }}
              />
              {errUnitsPerPhase != "" ? (
                <p style={{ color: "red", margin: "0px" }}>
                  {errUnitsPerPhase}
                </p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Phase Address<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <input
                required
                type="text"
                className="form-control"
                name="street"
                value={phaseInfo.street}
                ref={phaseAddressRef}
                onChange={(event) => {
                  handleChange(event);
                  setErrAddress(getRequiredErrorMsg(phaseAddressRef));
                }}
              />
              {errAddress != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errAddress}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                City<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <p>{phaseInfo.city}</p>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                State<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <p>{phaseInfo.state}</p>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>
                Zip<span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <p>{phaseInfo.zip5}</p>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Comment</div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div>
                <textarea
                  rows="3"
                  type="text"
                  maxLength="5000"
                  className="form-control mt-3"
                  name="comment"
                  placeholder="Enter your Comment"
                  onChange={(e) => {
                    handleChange(e);
                    setCommentLength(e.target.value.length);
                  }}
                ></textarea>
                <span
                  style={{
                    display: "inline-block",
                    marginRight: "5px",
                    float: "right",
                    fontSize: "14px",
                    color: "#787979",
                  }}
                >{`${commentLength}/5000`}</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="dialoguClass">
          <Grid item xs={12} sm={12} md={12} style={{ padding: "8px" }}>
            <Button
              type="submit"
              disabled={disable}
              style={{
                color: "white",
                backgroundColor: "#1176B5",
                borderRadius: "4px",
                boxShadow:
                  "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                height: "40px",
                width: "150px",
                textTransform: "capitalize",
              }}
              onSubmit={(e) => onSubmitCalled(e)}
              onClick={(e) => onSubmitCalled(e)}
              form="addPhaseForm"
            >
              Add
            </Button>
            <Button
              type="submit"
              className="ml-3"
              style={{
                color: "black",
                backgroundColor: "#fcfcfc",
                borderRadius: "4px",
                boxShadow:
                  "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                height: "40px",
                width: "150px",
                textTransform: "capitalize",
              }}
              form="addPhaseForm"
              href={`/project/${project_id}`}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddNewPhasePage;

import React, { useState } from "react";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";
import { Accordion, Card } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import CheckBox from '../SharedComponents/CheckBox/CheckBox';
import LenderCertificationList from "../LenderCertificationList/LenderCertificationList";
const ReviewDuplicates = ({ formData, setFormData, decrementCounter, incrementCounter }) => {
  const [confirmError, setConfirmError] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [apiError, setApiError] = useState("");

  React.useEffect(() => {
    formData.isConfirm = formData.isConfirm && formData.certificationList.length == formData.certifications.length;
    setChecked(formData.isConfirm);
  });

  const fetchCertifications = (projectId) => {
    let tempList = [];
    let list = <></>;
    if (projectId == formData.currentProject) {
      if (formData.certifications) {
        formData.certifications.map(c => {
          const data = formData.certificationList.find((cert) => cert.certfcnId === c);
          if (data) {
            tempList.push(data)
          }
        });
      }
    } else {
      formData.certificationList.filter((cert) => formData.certifications.indexOf(cert.certfcnId) == -1)
        .forEach((data) => tempList.push(data));
    }
    list = (
      <LenderCertificationList list={tempList} />
    );
    return list
  };

  const fetchPhaseCertifications = (phaseId, title) => {
    let tempList = [];
    let list = <></>;

    if (title.startsWith('Modified')) {
      const currentPhaseCerts = formData.phaseDetails.filter((phase) => (phase.isWholePhase == false && phase.destinationPhase == phaseId))
      let duplicatePhaseCertIds = [];
      currentPhaseCerts.map((p) => {
        duplicatePhaseCertIds.push(p.certId)
      })
      tempList = formData.currentPhaseCertifications.filter((cert) => cert.phaseId == phaseId);
      let tempList1 = formData.duplicatePhaseCertifications.filter((cert) => cert.phaseId == phaseId);
      tempList1.map((c) => {
        tempList.push(c);
      })
      const duplicatePhaseCertsToBeAdded = formData.duplicatePhaseCertifications.filter((cert) => duplicatePhaseCertIds.includes(cert.certfcnId));

      duplicatePhaseCertsToBeAdded.map((duplicatePhase) => {
        tempList.push(duplicatePhase);
      })

    } else {
      const phaseCerts = formData.phaseDetails.filter((phase) => (phase.isWholePhase == false && phase.sourcePhase == phaseId))
      let duplicatePhaseCertIds = [];
      phaseCerts.map((p) => {
        duplicatePhaseCertIds.push(p.certId)
      })
      tempList = formData.duplicatePhaseCertifications.filter((cert) => cert.phaseId == phaseId);
      const duplicatePhaseCertsFinal = tempList.filter((cert) => duplicatePhaseCertIds.includes(cert.certfcnId) == false);

      tempList = duplicatePhaseCertsFinal;
    }

    list = (
      <LenderCertificationList list={tempList} />
    );
    return list;

  };

  const projectAccordion = (projectId, projectDetails, title) => {
    return (<Accordion defaultActiveKey="1" style={{ minWidth: "100%" }}>
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey="1">
            <p className="accordion-header-title">
              {title}
            </p>
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="accordion-body-card">
            <div className="letter-accordion-body-container">
              <div className="left">
                <div className="label">Project Name</div>
                <div className="value">
                  {projectDetails.projectName}
                </div>
                <div className="label">Certifications</div>
                <Grid container spacing={2} className="dialoguClass">
                  {fetchCertifications(projectId)}
                </Grid>
              </div>
              <div className="right">
                <div className="label">Project ID</div>
                <div className="value">
                  {projectDetails.projectId}
                </div>
              </div>
            </div>

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>);
  }

  const phaseAccordion = (title, phases) => {
    return (<Accordion defaultActiveKey="1" style={{ minWidth: "100%" }}>
      <Card className="accordion-header-card">
        <Card.Header className="accordion-header-space">
          <ContextAwareToggle eventKey="1">
            <p className="accordion-header-title">
              {title}
            </p>
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="accordion-body-card">

            {
            phases && 
            phases.map((p, index) => (
              <div className="letter-accordion-body-container">
                <div className="left">
                  <div className="label">Phase Name</div>
                  <div className="value">
                    {p.phaseNumber}
                  </div>
                  <div className="label">Phase Certifications </div>
                  <Grid container spacing={2} className="dialoguClass">
                    {fetchPhaseCertifications(p.phaseId, title)}
                  </Grid>
                </div>
                <div className="right">
                  <div className="label">Phase ID</div>
                  <div className="value">
                    {p.phaseId}
                  </div>
                </div>
              </div>

            ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>);
  }

  const onSubmitCalled = async (e) => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let _formData = { ...formData };
    let currentCertfcnIds = _formData.certificationList
      .filter((cert) => cert.projectId == _formData.currentProject)
      .map((cert) => cert.certfcnId);
    _formData.updateBy = passData?.user
    _formData.prevPage = 'ReviewDuplicates';
    _formData.certifications = _formData.certifications.filter((cert) => currentCertfcnIds.indexOf(cert) === -1);
    _formData.phaseDetails = _formData.phaseDetails.filter((p) => (p.isWholePhase==false && p.destinationPhase==null)==false)
    const response = await axios.post(global.REACT_APP_API_ENDPOINT
      + '/condoMgr/moveCertsAndDeleteDuplicateProject', _formData);
    if (response) {
      if (response.status == 200) {
        setTimeout(() => {
          incrementCounter();
        }, 300);
      } else {
        setApiError("Unable to make the change.")
      }
    } else {
      setApiError("Unable to make the change.")
    }
  };

  const handleChange = event => {
    const { name, type, value, checked } = event.target;
    let _formData = formData;
    _formData[name] = type === "checkbox" ? checked : value;
    setFormData(_formData);
  };

  const navToPrevPage = event => {
    formData.prevPage = 'ReviewDuplicates';
    decrementCounter();
  }

  return (
    <>
      <Grid item xs={12}>
        <div className="review-duplicate-page">
          <Grid item xs={12}>
            <div className="section-title">
              Step2: Review & Save
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} className="dialoguClass">
              {projectAccordion(formData.currentProject, formData.projectDetails, 'Modified Project Information', formData.targetPhasesForReview)}
              {phaseAccordion('Modified Phase Information', formData.targetPhasesForReview)}
              {projectAccordion(formData.duplicateProject, formData.deleteProjectDetails, 'Duplicate Project Information', formData.duplicatePhasesForReview)}
              {phaseAccordion('Duplicate Phase Information', formData.duplicatePhasesForReview)}

            </Grid>
            <Grid item xs={12}>
              <CheckBox name='isConfirm' id='isConfirm' class="confirm-checkbox" disabled={formData.certificationList.length != formData.certifications.length}
                label='Do you want to permanently delete this project? You’ll lose all information in the duplicate project and can’t be recovered once deleted.'
                checked={isChecked}
                changeHandler={(value) => {
                  if (event.target.value.length === 0) {
                    setConfirmError("Please Confirm");
                  } else {
                    setConfirmError("");
                  }
                  handleChange(event);
                  setChecked(event.target.checked);
                }} />
              {confirmError != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{confirmError}</p>
              ) : null}
            </Grid>
            <Grid container spacing={2} className="dialoguClass" style={{ paddingRight: '25px' }}>
              {apiError != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{apiError}</p>
              ) : null}
              <Grid item xs={12} sm={12} md={12}>
                <Grid container justify="flex-end">
                  <Button
                    type="button"
                    style={{
                      color: "#fff",
                      backgroundColor: "rgb(17, 118, 181)",
                      borderRadius: "4px",
                      boxShadow:
                        "rgb(0 0 0 / 37%) 0px 0.4px 0.6px 0px, rgb(10 100 157) 0px 0.1px 0px 0.4px",
                      height: "40px",
                      width: "150px",
                      marginRight: "10px",
                      textTransform: "capitalize"
                    }}
                    onClick={e => navToPrevPage(e)}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    style={{
                      color: "#fff",
                      backgroundColor: "rgb(17, 118, 181)",
                      borderRadius: "4px",
                      boxShadow:
                        "rgb(0 0 0 / 37%) 0px 0.4px 0.6px 0px, rgb(10 100 157) 0px 0.1px 0px 0.4px",
                      height: "40px",
                      width: "150px",
                      textTransform: "capitalize"
                    }}
                    onSubmit={e => onSubmitCalled(e)}
                    onClick={e => onSubmitCalled(e)}
                    form="addProjectForm"
                  >
                    Save
                  </Button>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>

    </>
  );
};
export default ReviewDuplicates;
export const Environments = {
    local: "localhost",
    devl: "eitcg-dev-int.cdxms.devl-etss.aws.fanniemae.com", //TODO: Change to eitcg.cdxms.devl-etss.aws.fanniemae.com
    devlExternal: "gfy.eitcg.sfbu.devl.intgfanniemae.com",
    test: "eitcg.cdxms.test-etss.aws.fanniemae.com",
    testExternal: "gfy.eitcg.sfbu.test.intgfanniemae.com",
    acpt: "eitcg-sfbu.cdxms.acpt-etss.aws.fanniemae.com", //TODO: Change to eitcg.cdxms.acpt-etss.aws.fanniemae.com
    acptExternal: "gfy.eitcg.sfbu.acpt.intgfanniemae.com",
    clve: "eitcg.cdxms.clve-etss.aws.fanniemae.com",
    clveExternal: "gfy.eitcg.sfbu.clve.fanniemae.com",
    prod: "eitcg.cdxms.prod-etss.aws.fanniemae.com",
    prodExternal: "condo.fanniemae.com"
}

export const ReportByEnviroment = {
    lowerEnv: {
        psReports: [
            {
                title: "Unavailable Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Unavailable",
            },
            {
                title: "Approved Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Approved",
            },
            {
                title: "All Projects & Phases",
                body: "All projects and phases entered in the system",
            },
            {
                title: "Lender Certification Activity",
                body: "All certification data, including lender details and certification status, on any project or phase with a saved certification in the new CPM 2.0 platform.",
            },
            {
                title: "Lender Certification Details",
                body: "Lender specific responses on saved certifications",
            },
        ],
        lenderReports: [
            {
                title: "Unavailable Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Unavailable",
            },
            {
                title: "Approved Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Approved",
            },
            {
                title: "Lender Certification Activity",
                body: "All certification data, including lender details and certification status, on any project or phase with a saved certification in the new CPM 2.0 platform.",
            },
        ],
        tpoReports: [
            {
                title: "Lender Certification Activity",
                body: "All certification data, including lender details and certification status, on any project or phase with a saved certification in the new CPM 2.0 platform.",
            },
        ]
    },
    higherEnv: {
        psReports: [
            {
                title: "Unavailable Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Unavailable",
            },
            {
                title: "Approved Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Approved",
            },
            {
                title: "All Projects & Phases",
                body: "All projects and phases entered in the system",
            },
            {
                title: "Lender Certification Activity",
                body: "All certification data, including lender details and certification status, on any project or phase with a saved certification in the new CPM 2.0 platform.",
            },
            {
                title: "Lender Certification Details",
                body: "Lender specific responses on saved certifications",
            },
        ],
        lenderReports: [
            {
                title: "Unavailable Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Unavailable",
            },
            {
                title: "Approved Projects & Phases",
                body: "All projects and phases where Fannie Mae has applied a status of  Approved",
            },
            {
                title: "Lender Certification Activity",
                body: "All certification data, including lender details and certification status, on any project or phase with a saved certification in the new CPM 2.0 platform.",
            },
        ],
        tpoReports: [
            {
                title: "Lender Certification Activity",
                body: "All certification data, including lender details and certification status, on any project or phase with a saved certification in the new CPM 2.0 platform.",
            },
        ]
    }
}

export const DynatraceUrl = {
    local: 'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/28de9a92dbc30706_complete.js',
    devl: 'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/28de9a92dbc30706_complete.js',
    test: 'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/b35a1f50-6843-451e-9512-640d11de3507/3d910f6e527b6c6e_complete.js',
    acpt: 'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/811126cee3c17180_complete.js',
    clve: 'https://dynatrace-cf.fanniemae.com:443/jstag/managed/6cea7a23-e8be-46ca-9f02-14f7697bf11c/17f53397ab11f5cb_complete.js',
    prod: 'https://dynatrace-cf.fanniemae.com:443/jstag/managed/97527b6b-c1b9-432c-a34a-66bd79b05625/d1a93d9d4e6825b4_complete.js',
}

import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

import DeleteModal from "./delete-modal";

const CannedSection = ({ formData, name, isPre, setConditionsData }) => {
  const [conditions, setConditions] = useState([]);
  const [previousConditions, setPreviousConditions] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deletedRow, setDeletedRow] = useState(null);

  useEffect(() => {
    setConditions([...(formData[name] || [])]);
    setPreviousConditions(JSON.parse(JSON.stringify(formData[name] || [])));
  }, [formData[name]]);

  const handleRowSelection = (event, index) => {
    const _conditions = conditions.map((condition, i) => {
      return i === index
        ? { ...condition, isSelected: event.target.checked, isEdit: false }
        : condition;
    });

    setConditions(_conditions);
    setConditionsData(_conditions, name);
  };

  const handleRowChange = (event, index) => {
    let tab = RegExp("\\t|\\n", "g");
    let name = event.target.name.toString().replace(tab, " ");
    let value = event.target.value.toString().replace(tab, " ");
    const _conditions = conditions.map((condition, i) => {
      return i === index ? { ...condition, [name]: value } : condition;
    });

    setConditions(_conditions);
  };

  const handleRowSave = (index) => {
    toggleEdit(index, false);
  };

  const handleRowCancel = (index, isEdit) => {
    const _conditions = conditions.map((condition, i) => {
      return i === index ? { ...previousConditions[index], isEdit } : condition;
    });

    setConditions(_conditions);
    setConditionsData([..._conditions], name);
  };

  const toggleEdit = (index, isEdit) => {
    const _conditions = conditions.map((condition, i) =>
      i === index ? { ...condition, isEdit } : condition
    );

    setConditions(_conditions);
    setConditionsData(_conditions, name);
  };

  const handleDelete = (index) => {
    setDeletedRow(index);
    setShowDelete(true);
  };

  const handleDeleteSubmit = () => {
    const _conditions = conditions.filter((_, i) => i !== deletedRow);

    setConditions(_conditions);
    setShowDelete(false);
    setDeletedRow(null);
    setConditionsData(_conditions, name);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
    setDeletedRow(null);
  };

  return (
    <>
      {conditions &&
        conditions.map(
          ({ conditionName, conditionDesc, isSelected, isEdit }, index) => (
            <div>
              <div
                className="checkboxContainer"
                style={{
                  justifyContent: "space-between",
                  marginBottom: "0px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <input
                      type="checkbox"
                      className={"checkboxfield"}
                      id={"input"}
                      name={`${name}_condition`}
                      checked={isSelected}
                      onChange={(event) => handleRowSelection(event, index)}
                    />
                  </div>
                  <div>
                    {(!isEdit || isPre) && (
                      <label className={"checkboxLabel"} htmlFor={"input"}>
                        {conditionName}
                      </label>
                    )}
                    {isEdit && !isPre && isSelected && (
                      <input
                        type="text"
                        name="conditionName"
                        className="form-control"
                        value={conditionName}
                        onChange={(event) => handleRowChange(event, index)}
                      />
                    )}
                  </div>
                </div>
                {isEdit ? (
                  <div>
                    <Button
                      style={{
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleRowSave(index)}
                    >
                      <Save
                        style={{
                          color: "#0F5AF0",
                          marginRight: "5px",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                      Save
                    </Button>
                    <Button
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                      }}
                      className="test"
                      onClick={() => handleRowCancel(index, false)}
                    >
                      <Close
                        style={{
                          color: "#999999",
                          marginRight: "5px",
                          height: "18px",
                          width: "18px",
                        }}
                      />{" "}
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      style={{
                        textTransform: "capitalize",
                      }}
                      onClick={() => isSelected && toggleEdit(index, true)}
                      disabled={!isSelected}
                    >
                      <Edit
                        style={{
                          color: "#0F5AF0",
                          marginRight: "5px",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                      Edit
                    </Button>
                    {!isPre && (
                      <Button
                        style={{
                          marginLeft: "10px",
                          textTransform: "capitalize",
                        }}
                        className="test"
                        disabled={!isSelected}
                        onClick={() => handleDelete(index)}
                      >
                        <Delete
                          style={{
                            color: "#D02F2F",
                            marginRight: "5px",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                        Delete
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div>
                {isSelected && isEdit ? (
                  <textarea
                    name="conditionDesc"
                    value={conditionDesc}
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      padding: "5px",
                    }}
                    onChange={(event) => handleRowChange(event, index)}
                  />
                ) : (
                  <p>{conditionDesc}</p>
                )}
              </div>
            </div>
          )
        )}

      <DeleteModal
        showDelete={showDelete}
        conditionName={
          conditions &&
          conditions[deletedRow] &&
          conditions[deletedRow].conditionName
        }
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteSubmit={handleDeleteSubmit}
      />
    </>
  );
};

export default CannedSection;

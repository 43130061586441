import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Button, Link } from "@material-ui/core";
import "../BannerCreator.css";
import axios from "axios";
import moment from "moment";
import { MdClose } from "react-icons/md";
import Add from "@material-ui/icons/Add";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import RadioInput from "../../RadioInput/RadioInput";

const InitialBannerPage = ({ bannerId }) => {

  const [formData, setFormData] = useState({
    bannerId: "",
    description: "",
    expDateReq: "",
    publishDateReq: "",
    title: "",
    bannerType: "",
    creator: "",
  });

  const [errorTitle, setErrorTitle] = React.useState("");
  const [errorBannerType, setErrorBannerType] = React.useState("");
  const [errorExpiryDate, setErrorExpiryDate] = React.useState("");
  const [errorPublishDate, setErrorPublishDate] = React.useState("");
  const [errorLinkURL, setErrorLinkURL] = React.useState("");
  const [apiErr, setApiErr] = React.useState("");
  const [linkList, setLinkList] = useState([{ linkText: "", linkUrl: "" }]);
  
  React.useEffect(() => {
    if (bannerId !== "new") {      
      getBannerDetails();
    }
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    console.log("Scrolling" + event.target.value);
  };

  const getBannerDetails = async () => {
    console.log('getBannerDetails called...');
    console.log('getBannerDetails selected banner id' + bannerId);
    
    try {
      // getAllBanners gives only the past banners
      const apiToCall = 'getAllActiveAndPastBanners';
     
      const { data: list } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/banner/` + apiToCall
      );
      const currentBanner = list.filter(        
        (x) => x.bannerId.toString() == bannerId
      )[0];
      console.log('getBannerDetails 1 currentBanner[' + currentBanner + ']');

      currentBanner.publishDateReq = convertDateFromUtcToLocal(currentBanner.publishDate);
      currentBanner.expDateReq = convertDateFromUtcToLocal(currentBanner.expDate);

      setFormData(currentBanner);
      setLinkList(currentBanner.links);
    } catch (err) {
      console.log(err);
    }
  };

  const convertDateFromUtcToLocal = (date) => {
      let newDateObj= new Date(date);
      newDateObj = new Date(newDateObj.getTime() - (new Date().getTimezoneOffset() * 60000));
      console.log('convertDateFromUtcToLocal: ' + date + ':' + newDateObj);
      return formatDate(newDateObj);      
  }

  const formatDate = (date) => {
    return `${new Date(date).getUTCFullYear()}-${`${
      new Date(date).getUTCMonth() + 1
    }`.padStart(2, 0)}-${`${new Date(date).getUTCDate()}`.padStart(
      2,
      0
    )}T${`${new Date(date).getUTCHours()}`.padStart(2, 0)}:${`${new Date(
      date
    ).getUTCMinutes()}`.padStart(2, 0)}`;
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...linkList];
    list[index][name] = value;
    setLinkList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...linkList];
    list.splice(index, 1);
    setLinkList(list);
  };

  const handleAddClick = () => {
    setLinkList([...linkList, { linkText: "", linkUrl: "" }]);
  };



  const onSubmitCalled = (e) => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    if (
      formData.title.length != 0 &&
      formData.expDateReq.length != 0 &&
      formData.publishDateReq.length != 0 &&
      errorLinkURL.length === 0
    ) {
      let request = formData;
      request.links = linkList.filter((link) => link.linkUrl !== null);
      request.creator = passData.firstName + " " + passData.lastName;
      var newPublishDateObj= new Date(request.publishDateReq);
      newPublishDateObj = new Date(newPublishDateObj.getTime());
      // newPublishDateObj = new Date(newPublishDateObj.getTime());

      request.publishDateReq = formatDate(newPublishDateObj);
      console.log('new publish date: ' + request.publishDateReq);

      var newExpDateObj= new Date(request.expDateReq);
      newExpDateObj = new Date(newExpDateObj.getTime());
      request.expDateReq = formatDate(newExpDateObj);
      console.log('new expDate: ' + request.expDateReq);

      if (bannerId === "new") {
        axios
          .post(global.REACT_APP_API_ENDPOINT + `/banner/addBanner`, request)
          .then((response) => {
            if (response.status == 200) {
              setApiErr("");
              returnHome();
            } else {
              setApiErr(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post(global.REACT_APP_API_ENDPOINT + `/banner/editBanner`, request)
          .then((response) => {
            if (response.status == 200) {
              setApiErr("");
              returnHome();
            } else {
              setApiErr(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (formData.title.length === 0) {
        setErrorTitle("This field is required");
      } else {
        setErrorTitle("");
      }

      if (formData.expDateReq.length === 0) {
        setErrorExpiryDate("This field is required");
      } else {
        if (moment(formData.publishDateReq) > moment(formData.expDateReq)) {
          setErrorExpiryDate("Invalid Expiry Date");
        } else {
          setErrorExpiryDate("");
        }
      }

      if (formData.publishDateReq.length === 0) {
        setErrorPublishDate("This field is required");
      } else {
        setErrorPublishDate("");
      }
    }
  };


  const returnHome = () => {
    window.location.href = "/";
  };

  return (
    <Grid item xs={12}>
      <div className="banner-page">
        <h1> {bannerId === "new" ? "Add New Banner" : "Edit Banner"}</h1>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Banner Type*</div>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <RadioGroup
              row
              aria-label="position"
              name="bannerType"
              value={formData.bannerType}
              onChange={(event) => {
                if (event.target.value.length === 0) {
                  setErrorBannerType("This field is required");
                } else {
                  setErrorBannerType("");
                }
                handleChange(event);
              }}
            >
              <FormControlLabel
                key="FA"
                style={{ fontWeight: "normal" }}
                value="FA"
                control={<RadioInput />}
                label="FM Announcements"
                labelPlacement="end"
              />
              <FormControlLabel
                key="SM"
                style={{ fontWeight: "normal" }}
                value="SM"
                control={<RadioInput />}
                label="Site Maintenance"
                labelPlacement="end"
              />
            </RadioGroup>

            {errorTitle != "" ? (
              <p style={{ color: "red", margin: "0px" }}>{errorBannerType}</p>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Title*</div>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <input
              type="text"
              id="title"
              name="title"
              className="form-control"
              maxLength="40"
              value={formData.title}
              onChange={(event) => {
                if (event.target.value.length === 0) {
                  setErrorTitle("This field is required");
                } else {
                  setErrorTitle("");
                }
                handleChange(event);
              }}
            />
            {errorTitle != "" ? (
              <p style={{ color: "red", margin: "0px" }}>{errorTitle}</p>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Description</div>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <input
              id="description"
              name="description"
              className="form-control"
              maxLength="250"
              value={formData.description}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Links</div>
          </Grid>
        </Grid>
        {linkList.map((x, i) => {
          return (
            <Grid container spacing={2} className="dialoguClass">
              <Grid container item xs={3} direction="column">
                <input
                  id="linkText"
                  name="linkText"
                  className="form-control"
                  maxLength="40"
                  value={x.linkText}
                  onChange={(e) => handleInputChange(e, i)}
                  placeholder="Text"
                />
              </Grid>
              <Grid container item xs={4} direction="column">
                {" "}
                <input
                  type="url"
                  id="linkUrl"
                  name="linkUrl"
                  className="form-control"
                  value={x.linkUrl}
                  onChange={(e) => handleInputChange(e, i)}
                  placeholder="Url"
                />
              </Grid>
              {linkList.length !== 1 && (
                <Grid container item xs={1} direction="column">
                  <MdClose
                    className="close"
                    onClick={() => handleRemoveClick(i)}
                  />
                </Grid>
              )}
              {linkList.length - 1 === i && linkList.length < 4 && (
                <Grid container item xs={1} direction="column">
                  <Add
                    className={
                      linkList.length === 1 ? "intial-add-icon" : "add-icon"
                    }
                    onClick={() => handleAddClick(i)}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
        {linkList.length === 4 ? (
          <p style={{ marginLeft: "19px", color: "grey" }}>
            Note : Can add max up to 4 links
          </p>
        ) : null}
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Publish Date*</div>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <input
              type="datetime-local"
              id="publishDate"
              name="publishDateReq"
              className="form-control"
              value={(convertDateFromUtcToLocal(formData.publishDateReq) || "").toString().substring(0, 16)}
              onChange={(event) => {
                if (event.target.value.length === 0) {
                  setErrorPublishDate("This field is required");
                } else {
                  setErrorPublishDate("");
                }
                handleChange(event);
              }}
            />
            {errorPublishDate != "" ? (
              <p style={{ color: "red", margin: "0px" }}>{errorPublishDate}</p>
            ) : null}
          </Grid>
        </Grid>        
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Expiry Date*</div>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <input
              type="datetime-local"
              id="expDate"
              name="expDateReq"
              className="form-control"
              value={(convertDateFromUtcToLocal(formData.expDateReq) || "").toString().substring(0, 16)}
              onChange={(event) => {
                if (event.target.value.length === 0) {
                  setErrorExpiryDate("This field is required");
                } else {
                  if (
                    moment(formData.publishDateReq) > moment(event.target.value)
                  ) {
                    setErrorExpiryDate("Invalid Expiry Date");
                  } else {
                    setErrorExpiryDate("");
                  }
                }
                handleChange(event);
              }}
            />
            {errorExpiryDate != "" ? (
              <p style={{ color: "red", margin: "0px" }}>{errorExpiryDate}</p>
            ) : null}
          </Grid>
          <div
            style={{
              color: "#ff0000",
            }}
          >
            {apiErr ? apiErr : null}
          </div>
          <Grid container className="dialoguClass">
            <Grid item xs={12} sm={12} md={12} style={{ padding: "8px" }}>
              <Button
                type="button"
                style={{
                  type: "button",
                  color: "white",
                  backgroundColor: "#1176B5",
                  borderRadius: "4px",
                  boxShadow:
                    "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                  height: "40px",
                  width: "190px",
                  textTransform: "capitalize",
                }}
                
                onClick={
                  (e) => {
                    if(errorExpiryDate==""){
                      onSubmitCalled(e)}
                    }
                  }
                  
                  
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default InitialBannerPage;

import React from "react";
import "./LenderCertificationList.css";
import { isLenderRole, isTPORole } from "../../redux/actions/userAction";

const IndividualLenderCertification = ({ item }) => {
  return (
    <div className="card-row-pers">
      <div className="card-certification-name-col">
        <div className="certification-list-values">
          {isLenderRole() || isTPORole() ? (
            item.phaseName || item.projectName ? (
              <>
                {item.phaseId == null ? (
                  <a
                    href={`/print-certification/${item.certfcnId}/${item.projectId}/proj`}
                  >
                    {" "}
                    {item.phaseName
                      ? item.projectName + " / " + item.phaseName
                      : item.projectName}{" "}
                  </a>
                ) : (
                  <a
                    href={`/print-certification/${item.certfcnId}/${item.projectId}/${item.phaseId}`}
                  >
                    {" "}
                    {item.phaseName
                      ? item.projectName + " / " + item.phaseName
                      : item.projectName}{" "}
                  </a>
                )}
              </>
            ) : (
              "_"
            )
          ) : item.lenderName ? (
            <>
              {item.phaseId == null ? (
                <a
                  href={`/print-certification/${item.certfcnId}/${item.projectId}/proj`}
                >
                  {item.lenderName}
                </a>
              ) : (
                <a
                  href={`/print-certification/${item.certfcnId}/${item.projectId}/${item.phaseId}`}
                >
                  {item.lenderName}
                </a>
              )}
            </>
          ) : (
            "_"
          )}
        </div>
      </div>
      <div className="card-certification-name-col">
        <div className="certification-list-values">
          {item.lenderCertDate ? item.lenderCertDate : "_"}
        </div>
      </div>
      <div className="card-certification-name-col">
        <div className="certification-list-values">
          {item.lenderCertExpiryDate ? item.lenderCertExpiryDate : "_"}
        </div>
      </div>
      <div className="card-certification-name-col">
        <div className="certification-list-values">
          {item.lenderCertificationStatus
            ? item.lenderCertificationStatus
            : "_"}
        </div>
      </div>
    </div>
  );
};

export default IndividualLenderCertification;

import React, {useState, useEffect} from "react";
import axios from "axios";
import { Radio, Button, TextField } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";
import FormStepper from "../stepper";
import CancelModal from "../../ProjectStatusFinal/cancel-modal";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';






export const Page1 = ({ formData, formState, streetInfo, setStreetInfo, setFormData, nextStep, step }) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [showCancel, setShowCancel] = useState(false);

    function handleChange(event) {
        setStreetInfo({ ...streetInfo, [event.target.name]: event.target.value });
      }

    function openCancelPopup(flag) {
        setShowCancel(flag);
      }
    
      function handleCancelCancel() {
        setShowCancel(false);
      }

      function validateText(value) {
        let error;
        if (!value) {
          error = "Required";
        }
        return error;
      }

      function validateType(value) {
        let error;
        if (value === "" || !value) {
          error = "Required";
        }
        return error;
      }

      function validateNumNoStrReq(value) {
        let error;
        if (!value) {
          error = "Required";
        }
        return error;
      }

    return (
        <>
        <CancelModal
            showCancel={showCancel}
            handleCancelCancel={handleCancelCancel}
            title={ 'Cancel Child Phase'}
          />
            <Formik
                initialValues={formData}
                onSubmit={values => {
                    setFormData(values);
                    nextStep();
                }}
            >
            {({ values, errors, touched }) => (
            <Form className="parent-form">
                <FormStepper step={step} />
                <div className="parent-field-container">
                    <div className="field-container">
                        <div className="top-title-container">
                            <div className="big-title">Add Child Phase</div>
                            <div className="small-title">
                            Phase ID: {formData.phaseId} 
                            </div>
                          <button className="add-phase-button"
                            style={{ float: 'right', marginTop: '-70px' }}
                            onClick={() => openCancelPopup(true)}
                          >
                            <FaTimes
                              style={{
                                marginRight: 5,
                                marginBottom: 2,
                                color: "#d0021b"
                              }}
                            />{" "}Cancel
                            </button>
                        </div>
                        <div className="field-lower-container">
                            <div className="section-title">
                            Step 1: Add Child Phase Details
                            </div>
                            <div>
                                <div>
                                    <div className="attribute-title">
                                        Phase Name<span style={{ color: "red" }}>*</span>
                                    </div>
                                    <Field
                                        name="phaseName"
                                        as={TextField}
                                        className="attribute-input"
                                        size="small"
                                        fullWidth={true}
                                        variant="outlined"
                                        validate={validateText}
                                        inputProps={{ maxLength: 200 }}
                                        error={touched.phaseName && errors.phaseName}
                                        helperText={touched.phaseName && errors.phaseName}
                                    />   
                                    <div className="attribute-title">
                                        Phase Type<span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className="attribute-select">
                                    <Field
                                        className="attribute-select-dropdown"
                                        name="phaseType"
                                        as="select"
                                        validate={validateType}
                                    >
                                        <option value=" ">Select Phase Type</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Marketing">Marketing</option>
                                    </Field>
                                        {errors.phaseType && touched.phaseType ? (
                                            <div
                                            className="pers-error-message"
                                            style={{ color: "red" }}
                                            >
                                            {errors.phaseType}
                                            </div>
                                        ) : null}
                                    </div>  
                                    <div className="attribute-title">
                                        Building Number
                                    </div>
                                    <Field
                                        name="buildingNumber"
                                        as={TextField}
                                        className="attribute-input"
                                        size="small"
                                        fullWidth={true}
                                        inputProps={{ maxLength: 100 }}
                                        variant="outlined"
                                    />   
                                    <div className="attribute-title">
                                        Unit Number
                                    </div>
                                    <Field
                                        name="unitNumber"
                                        as={TextField}
                                        className="attribute-input"
                                        size="small"
                                        fullWidth={true}
                                        inputProps={{ maxLength: 100 }}
                                        variant="outlined"
                                    />   
                                    <div className="attribute-title">
                                        Number of Units in Phase<span style={{ color: "red" }}>*</span>
                                    </div>
                                    <Field
                                        name="unitCount"
                                        as={TextField}
                                        className="attribute-input"
                                        size="small"
                                        fullWidth={true}
                                        variant="outlined"
                                        validate={validateNumNoStrReq}
                                        inputProps={{ maxLength: 32 }}
                                        error={touched.unitCount && errors.unitCount}
                                        helperText={touched.unitCount && errors.unitCount}
                                    />  
                                    <div className="attribute-title">
                                        Phase Address<span style={{ color: "red" }}>*</span>
                                    </div>
                                    <Field
                                        name="street"
                                        required
                                        as={TextField}
                                        value={streetInfo.street}
                                        className="attribute-input"
                                        size="small"
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={{ maxLength: 200 }}
                                        error={streetInfo.street !== null ? streetInfo.street.length === 0 ? true : false : ""}
                                        helperText={streetInfo.street !== null ? streetInfo.street.length === 0 ? "Required": "" : ""}
                                        onChange={event => {
                                            handleChange(event);
                                        }}
                                    />
                                    <div className="attribute-title">
                                        City<span style={{ color: "red" }}>*</span>
                                    </div> 
                                    <div className="attribute-input">
                                        {formState.city}
                                    </div>  
                                    <div className="attribute-title">
                                        State<span style={{ color: "red" }}>*</span>
                                    </div> 
                                    <div className="attribute-input">
                                        {formState.state}
                                    </div> 
                                    <div className="attribute-title">
                                        Zip Code<span style={{ color: "red" }}>*</span>
                                    </div> 
                                    <div className="attribute-input">
                                        {formState.zip5}
                                    </div>  
                                    <div className="attribute-title">
                                        Comments
                                    </div>
                                    <Field
                                        name="comments"
                                        as={TextField}
                                        className="attribute-input"
                                        size="small"
                                        multiline                      
                                        rows={4}
                                        placeholder="Enter your comments"
                                        fullWidth={true}
                                        inputProps={{ maxLength: 5000 }}
                                        variant="outlined"
                                        helperText={`${values.comments.length}/5000`}
                                    />                                 
                                </div>
                                <div className="bottom-button-container">
                                    <Button 
                                        type="submit" 
                                        className="next-button"
                                        >
                                        Next Section
                                        <ArrowForwardIcon />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="right-cancel-container">
                        {/* <button
                        className="delete-modal-button"
                        style={{
                            float: 'right',
                            border: 0
                        }}
                        onClick={() => openCancelPopup(true)}
                        >
                        <FaTimes
                            className="edit-profile-icon"
                            style={{
                            marginRight: 5
                            }}
                        />{" "}
                        Cancel 
                        </button> */}
                    </div>
            </Form>
            )}
            </Formik>
        </>
    )

}
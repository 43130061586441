import React from "react";
export const ProjectPresaleAndInvestorConcentration = ({ data, convResult }) => {
    return (
        <div style={{ padding: "18px 24px" }}>
        <div className="question">
          <div className="number">1.</div>
          <div>Total # of Units</div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt.ppTotalUnitsSt ===
              "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppTotalUnits}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppTotalUnits}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="question">
          <div className="number">2.</div>
          <div># of Owner-Occupied Units</div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt
                .ppOwnerOccupiedUnitsSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppOwnerOccupiedUnits}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppOwnerOccupiedUnits}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="question">
          <div className="number">3.</div>
          <div>
            # of Second Homes(if unknown, enter zero and
            combine primary and second homes in the # of
            Owner-Occupied Units)
          </div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt.ppSecondHomesSt ===
              "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppSecondHomes}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppSecondHomes}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="question">
          <div className="number">4.</div>
          <div># of Units Rented (Investor)</div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt.ppUnitsRentedSt ===
              "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppUnitsRented}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppUnitsRented}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="question">
          <div className="number">5.</div>
          <div># of Units Complete</div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt.ppUnitsCompleteSt ===
              "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppUnitsComplete}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppUnitsComplete}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="question">
          <div className="number">6.</div>
          <div>
            # of Units Conveyed/Closed (if this is the first
            sale in the project, enter 1)
          </div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt.ppUnitsConveyedSt ===
              "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppUnitsConveyed}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppUnitsConveyed}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        {data.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd !== "" ? (
          <>
            <div className="question">
              <div
                className="number"
                style={{
                  borderLeft: "1px solid rgba(0,0,0,0.4)",
                  borderBottom: "1px solid rgba(0,0,0,0.4)",
                  marginLeft: "37px",
                  marginBottom: "10px",
                }}
              ></div>{" "}
              <div style={{ paddingTop: "10px" }}>
                Are there developer-owned units that meet
                the rental hold-back requirements as defined
                in the
                <a
                  target="_blank"
                  href="https://www.fanniemae.com/content/guide/selling/b4/2.1/01.html"
                >
                  {" "}
                  Selling Guide
                </a>
                ?
              </div>
            </div>
            <div
              className="summary-answer"
              style={{ paddingLeft: "86px" }}
            >
              <div className="label">Answer:&nbsp;</div>
              {typeof data.projectPresaleSt !==
              "undefined" ? (
                <>
                  {data.projectPresaleSt
                    .ppDevOwndUntsRentalHoldBackIndSt ===
                  "Fail" ? (
                    <>
                      <div
                        className="attribute"
                        style={{ color: "#D0021B" }}
                      >
                        {
                          data.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="attribute">
                        {
                          data.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd
                        }
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {data.projectPresaleSt.ppDevOwndUntsRentalHoldBackInd ===
            "Yes" ? (
              <>
                <div className="question">
                  <div
                    className="number"
                    style={{
                      borderLeft:
                        "1px solid rgba(0,0,0,0.4)",
                      borderBottom:
                        "1px solid rgba(0,0,0,0.4)",
                      marginLeft: "37px",
                      marginBottom: "10px",
                    }}
                  ></div>{" "}
                  <div style={{ paddingTop: "10px" }}>
                    How many developer-owned units are held?
                  </div>
                </div>
                <div
                  className="summary-answer"
                  style={{ paddingLeft: "86px" }}
                >
                  <div className="label">Answer:&nbsp;</div>
                  {typeof data.projectPresaleSt !==
                  "undefined" ? (
                    <>
                      {data.projectPresaleSt
                        .ppDevOwnedUnitsSt === "Fail" ? (
                        <>
                          <div
                            className="attribute"
                            style={{ color: "#D0021B" }}
                          >
                            {data.projectPresaleSt.ppDevOwnedUnits}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="attribute">
                            {data.projectPresaleSt.ppDevOwnedUnits}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="question">
                  <div
                    className="number"
                    style={{
                      borderLeft:
                        "1px solid rgba(0,0,0,0.4)",
                      borderBottom:
                        "1px solid rgba(0,0,0,0.4)",
                      marginLeft: "37px",
                      marginBottom: "10px",
                    }}
                  ></div>{" "}
                  <div style={{ paddingTop: "10px" }}>
                    Are the maintenance fees for
                    developer-owned units current?
                  </div>
                </div>
                <div
                  className="summary-answer"
                  style={{ paddingLeft: "86px" }}
                >
                  <div className="label">Answer:&nbsp;</div>
                  {typeof data.projectPresaleSt !==
                  "undefined" ? (
                    <>
                      {data.projectPresaleSt
                        .ppMaintFeesDevOwndCurrentIndSt ===
                      "Fail" ? (
                        <>
                          <div
                            className="attribute"
                            style={{ color: "#D0021B" }}
                          >
                            {
                              data.projectPresaleSt.ppMaintFeesDevOwndCurrentInd
                            }
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="attribute">
                            {
                              data.projectPresaleSt.ppMaintFeesDevOwndCurrentInd
                            }
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="question">
                  <div
                    className="number"
                    style={{
                      borderLeft:
                        "1px solid rgba(0,0,0,0.4)",
                      borderBottom:
                        "1px solid rgba(0,0,0,0.4)",
                      marginLeft: "37px",
                      marginBottom: "10px",
                    }}
                  ></div>{" "}
                  <div style={{ paddingTop: "10px" }}>
                    Are there any active or pending special
                    assessments?
                  </div>
                </div>
                <div
                  className="summary-answer"
                  style={{ paddingLeft: "86px" }}
                >
                  <div className="label">Answer:&nbsp;</div>
                  {typeof data.projectPresaleSt !==
                  "undefined" ? (
                    <>
                      {data.projectPresaleSt
                        .ppActivePendingSpAssmntsIndSt ===
                      "Fail" ? (
                        <>
                          <div
                            className="attribute"
                            style={{ color: "#D0021B" }}
                          >
                            {
                              data.projectPresaleSt.ppActivePendingSpAssmntsInd
                            }
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="attribute">
                            {
                              data.projectPresaleSt.ppActivePendingSpAssmntsInd
                            }
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="question">
          <div className="number">7.</div>
          <div>Presale Conveyed Percentage:</div>
        </div>
        <div className="summary-answer">
          <div className="label">Answer:&nbsp;</div>
          {typeof data.projectPresaleSt !== "undefined" ? (
            <>
              {data.projectPresaleSt
                .ppPreSaleConveyPercntgeSt === "Fail" ? (
                <>
                  <div
                    className="attribute"
                    style={{ color: "#D0021B" }}
                  >
                    {data.projectPresaleSt.ppPreSaleConveyPercntge}
                  </div>
                </>
              ) : (
                <>
                  <div className="attribute">
                    {data.projectPresaleSt.ppPreSaleConveyPercntge}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}
